import React, { useState } from 'react';
import { Field } from 'react-final-form';
import GenericGrid from '../../../common/components/GenericGrid';

const GridUsers = ({ onSelectionChange }) => {
  return (
    <Field
      name={'users'}
      columns={[
        {
          name: 'key',
          //   title: <IntlMessages id="portal.usrP_CODIGO" />,
          title: 'Usuario',
        },

        {
          name: 'email',
          title: 'email',
        },
     
        {
          name: 'nombres', 
          title: 'Nombres',
        },
        {
          name: 'apellidos',
          title: 'Apellidos',
        },
        // {
        //   name: 'telefono1',
        //   title: 'Teléfono',
        // },
        {
          name: 'estado',
          title: 'Estado',
        },
        {
          name: 'activo',
          title: 'Activo',
        },
      ]}
      booleanColumns={['activo']}
      component={GenericGrid}
      disableAdd
      disableEdit
      defaultCurrentPage={0}
      pageSize={30}
      enableSelection
      onSelectionChange={onSelectionChange}
      defaultFilters={[]}
      enableColumnResizing={true}
      // defaultColumnWidths={columnWidth}
    />
  );
};
export default GridUsers;
