import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../../layouts/layouts";
import CrearProductoSinSeguro from "../../container/CrearProductoSinSeguro";

const CrearProductoCliente = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <>
      <h2 className="titulosPrincipales-container">Siniestros - Sin Seguro</h2>
      <CrearProductoSinSeguro />
    </>
  );
};

export default CrearProductoCliente;
