import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Card, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { MaskID } from "app/modules/shared/mask/mascarasInputs";

import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";
import {
  StyleInputs,
  StylesCardBuscador,
} from "app/modules/common/styles/themes";

const BuscadorPorIdentificador = ({ traerIdCliente }) => {
  const [idCliente, setidCliente] = useState();

  const navigate = useHistory();
  const handleId = (e) => {
    setidCliente(e.target.value);
  };

  const redirectHomePage = () => {
    navigate.push("/initialHome");
  };
  return (
    <div className="buscador-siniestros-container">
      <Card sx={StylesCardBuscador()}>
        <header className="header-colaborador-header">
          <div className="buscador-siniestros-titulo">
            <h2>Consultar información del cliente</h2>
          </div>

          <div className="buscador-movimientos-opciones">
            <div className="buscador-movimientos-opciones__inputs">
              <FormControl
                sx={StyleInputs(60, 100)}
                size="small"
                variant="outlined"
              >
                <InputLabel htmlFor="buscador">Identificación</InputLabel>
                <OutlinedInput
                  id="buscador"
                  value={idCliente}
                  label="Identificación"
                  inputComponent={MaskID}
                  onChange={handleId}
                />
              </FormControl>
            </div>

            <div className="buscador-movimientos-opciones__buttons">
              <SCSGenericButton
                type="submit"
                size={45}
                onClick={traerIdCliente(idCliente)}
                titulo="Buscar"
              />

              <SCSGenericButton
                type="button"
                size={45}
                titulo="Cancelar"
                onClick={redirectHomePage}
              />
            </div>
          </div>
        </header>
      </Card>
    </div>
  );
};

export default BuscadorPorIdentificador;
