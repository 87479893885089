import React, { useState } from "react";

import BuscadorPorIdentificacionServimax from "../../containers/BuscadorPorIdentificacionServimax";
import DetalleReporteCliente from "../../containers/DetalleReporteCliente";
import BuscadorPorIdentificacionGenerico from "../../containers/BuscadorPorIdentificacionGenerico";

const ConsultarCliente = () => {
  const [datosBusqueda, setDatosBusqueda] = useState({
    identificacion: "",
    mostrarBuscador: true,
    idGrupo: Number(localStorage.getItem("idGrupoStorage")),
  });

  if (datosBusqueda.mostrarBuscador) {
    return (
      <>
        {datosBusqueda.idGrupo === 4 ? (
          <div className="gestion-siniestros-container">
            <BuscadorPorIdentificacionServimax
              setDatosBusqueda={setDatosBusqueda}
              titulo="Consultar información del cliente"
            />
          </div>
        ) : (
          <div className="gestion-siniestros-container">
            <BuscadorPorIdentificacionGenerico
              setDatosBusqueda={setDatosBusqueda}
              titulo="Consultar información del cliente"
            />
          </div>
        )}
      </>
    );
  }

  return <DetalleReporteCliente datosBusqueda={datosBusqueda} />;
};

export default ConsultarCliente;
