import React from "react";
import FormGestionAsignacion from "../components/FormGestionAsignacion";
import { useEffect } from "react";
import { useState } from "react";
import { consultaAsignacionXUsuario } from "../services/cobranzaServices";

const DetalleGestionUsuarios = ({ usuario }) => {
  const [asignacionMaxima, setAsignacionMaxima] = useState(0);
  const [loading, setLoading] = useState(true);

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    console.log({ usuario });

    const getAsignacionXUsuario = async () => {
      try {
        const respuestaAsignacion = await consultaAsignacionXUsuario(
          usuario.idUsuario
        );

        console.log({ respuestaAsignacion });

        if (respuestaAsignacion.state) {
          setAsignacionMaxima(respuestaAsignacion.data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getAsignacionXUsuario();
  }, [update]);

  if (loading) {
    return (
      <>
        <h2>Cargando!!!</h2>
      </>
    );
  }

  return (
    <div>
      <h3>
        {`El usuario ${usuario.nombreCompleto} posee una asignación máxima de ${asignacionMaxima.asignacionMaxima}`}
      </h3>
      <FormGestionAsignacion
        usuario={usuario}
        asignacionMaxima={asignacionMaxima}
        setUpdate={setUpdate}
        update={update}
      />
    </div>
  );
};

export default DetalleGestionUsuarios;
