import React, { useState, useEffect } from "react";
import { Card, CardContent, Checkbox, FormControlLabel, IconButton, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
import { auth } from "@jumbo/services/auth/firebase/firebase";
import * as yup from 'yup';
import { Form, Formik } from "formik";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector, useDispatch } from "react-redux";
import { changeMenu } from "../redux/actions/changeMenu";
import { changeUsuario } from "../redux/actions/changeUsuario";
import { changeJwtSesion } from "../redux/actions/changeJwtSesion";
import { changePerfil } from "../redux/actions/changePerfil";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useOnLogin, useOnConnect } from '../hook/index'
import Button from '@mui/material/Button';
import ContentLoader from '../../../../@jumbo/components/ContentLoader/index';
import { onLogin, onConnect } from "../../app-auth/services/index"
import { updateCodeUser } from '../../../redux/actions/Auth';
import { onlyTexto } from "../../../utils/normalizers";
import { useHistory } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';



const validationSchema = yup.object({
    email: yup
        .string('Ingrese código de usuario')
        /*.matches(onlyTexto, 'Ingrese solo texto')*/
        .max(32, 'Excede longitud máxima')
        .required('Código es requerido'),
    password: yup
        .string('Ingrese su password')
        .max(32, 'Excede longitud máxima')
        .required('Password is requerido'),
});

const Login = () => {

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [userForgot, setUserForgot] = useState('');
    const [password, setPassword] = useState('');
    const [rol, setrol] = useState('');
    const [send, setSend] = useState(false);
    const [sendConnect, setSendConnect] = useState(false);
    const { loader, roles, authUser } = useSelector((s) => s.auth);
    const [activeStep, setActiveStep] = useState(0);
    const [localRoles, setLocalRoles] = useState([]);
    const history = useHistory();
    useOnLogin(email, password, send, setSend);
    useOnConnect(rol, sendConnect, setSendConnect);
    useEffect(() => {
        if (roles.length > 0) {

            setActiveStep(activeStep + 1);
        }
    }, [roles]);


    const mutation = useAuthSignInWithEmailAndPassword(auth, {
    
        onError(error) {
            console.log("login erorororro",error);
        },
        onSuccess(data) {
           
            history.push("/");
        }
    });


    useEffect(() => {
        if (activeStep === 1) setLocalRoles(roles);
        else if (activeStep === 0) {
            setLocalRoles([]);
            setrol("");
        }
    }, [activeStep]);

    useEffect(() => {
        if (authUser !== null) {
            history.push("/");
        }
    }, [authUser]);


    // const handleNext = async () => {
    //     console.log("entro a click ")
    //     debugger
    //     if (activeStep === 0) {
    //         setSend(true);
    //     } else if (activeStep === 1) {
    //         setSendConnect(true);
    //     }
    // };

    // const handleBack = () => {
     
    //     sessionStorage.removeItem('user');
    //     sessionStorage.removeItem('roles');
    //     sessionStorage.removeItem('jwtToken');
    //     sessionStorage.removeItem('menu');
    //     dispatch(updateCodeUser(null));
    //     setActiveStep(activeStep - 1);
    // };

    const handleForgot = () => {

        let session = {
            userCode: 'usercode'
        };
        dispatch(updateCodeUser(session));
        history.push("/forgot-password");
    }
    const selectOfficeAndProfile = () => {
        return (
            <div>
                <div >
                    <Box mb={2} style={{paddingBlockStart:'15px'}}>
                        <FormControl fullWidth variant="outlined" >
                            <InputLabel id="select-perfil">Perfil</InputLabel>
                            <Select
                                labelId="select-perfil"
                                label="petfil"
                                fullWidth
                                value={rol}
                                onChange={(event) => {
                                    setrol(event.target.value);
                                }}
                                autoWidth={false}
                                disabled={loader}
                                //className={classes.texttFieldRoot}
                            >
                                <MenuItem selected disabled value="">
                                    <em>Perfiles</em>
                                </MenuItem>
                                {localRoles.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.idRol}>
                                            {item.descripcion}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </div>
            </div>
        );
    };

    const getUserNameAndPassword = () => {
        return (
            <div>
                <Box mb={2}>
                    <TextField
                        label="Usuario"
                        fullWidth
                        onChange={event => setEmail(event.target.value)}
                        defaultValue={email}
                        margin="normal"
                        variant="outlined"
                    //className={classes.textFieldRoot}
                    />
                </Box>
                <Box mb={2}>
                    <TextField
                        type="password"
                        label="Contraseña"
                        fullWidth
                        onChange={event => setPassword(event.target.value)}
                        defaultValue={password}
                        margin="normal"
                        variant="outlined"
                    //className={classes.textFieldRoot}
                    />
                </Box>

                <Box>
                    <div>
                        <Typography>
                            <span className={'ml-2'}>
                                    <Link onClick={() => handleForgot()}>
                                        Restablecer contraseña
                                    </Link>
                            </span>
                        </Typography>
                    </div>
                </Box>
            </div>
        );
    };

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return getUserNameAndPassword();
            case 1:
                return selectOfficeAndProfile();
            default:
                return "Error";
        }
    };


    const steps = 2;


    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: theme => theme.spacing(4),
        }}>

            <Card sx={{ maxWidth: '100%', width: 360, mb: 4 }}>
                <Div sx={{ position: 'relative', height: '200px' }}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="200"
                        image={`${ASSET_IMAGES}/logo-login.jpg`}
                    />
                    <Div
                        sx={{
                            flex: 1,
                            inset: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: theme => alpha(theme.palette.common.black, .5),
                            p: theme => theme.spacing(3),
                        }}
                    >
                        <Typography
                            variant={"h2"}
                            sx={{
                                color: 'common.white',
                                fontSize: '1.5rem',
                                mb: 0
                            }}
                        >
                            Login
                        </Typography>
                    </Div>
                </Div>
                <CardContent sx={{ pt: 0 }}>

                <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => {
                            setSubmitting(true);
                            debugger;
                            setEmail(data.email);
                            setPassword(data.password);
                            //onSignIn(data.email, data.password);
                            setSend(true);

                            setSubmitting(false);
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                                <Avatar
                                    alt="Usuario" 
                                    sx={{
                                        width: 56,
                                        height: 56,
                                        marginLeft: 'auto',
                                        boxShadow: shadows[3],
                                        transform: 'translateY(-50%)',
                                    }}
                                />
                                {mutation.isError && <p>{mutation.error.message}</p>}
                                <Div sx={{mb: 3, mt: 1}}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label="Usuario"
                                    />
                                </Div>
                                <Div sx={{mb: 2, mt: 1}}>
                                {/* <FormControl sx={{ m: 1, width: '38ch' }} variant="outlined"> */}
                                 {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                         name="password"
                                    /> */}

                                     
                                    {/* <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                    /> */}
                                      {/* </FormControl> */}
                                    
                                     <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="Mostrar/ocultar contraseña"
                                                  onClick={handleClickShowPassword}
                                                  onMouseDown={handleMouseDownPassword}
                                                >
                                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }}
                                    />
                                      
                                </Div>
                                <Typography
                                    textAlign={"right"}
                                    variant={"body1"}
                                >
                                    <Link onClick={() => handleForgot()} underline="none" href="#">Reestablecer password? </Link>
                                </Typography>
                               
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting || mutation.isLoading}
                                >Login</LoadingButton>
                            </Form>
                        )}
                    </Formik>
                    <ContentLoader />
                </CardContent>
            </Card>


        </Div>
    );
};

export default Login;
