import React, { useEffect } from "react";
import PageContainer from '../../common/components/Layouts/PageContainer';
import ContainerChangePassword from "./ProfileContainers/ChangePasswordContainer";

const ChangePassword = () => {


	return (
		<PageContainer heading={'Cambio de Contraseña'}>
			<ContainerChangePassword />
		</PageContainer>
	);
};

export default ChangePassword;
