import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import ActionButton from '../../../common/components/TransactionalButtons/ActionButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import PopUp from '../RolesComponents/Popup';
import { Form, Field } from 'react-final-form';
import GridRoles from '../RolesComponents/GridRoles';
import { useRoles } from '../../hooks/hookRoles';
import { updateRol, addRol } from '../../services/index'
import { insertItem, updateItem } from '../../../../utils/common';
// import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions/Common';
import { useDispatch } from 'react-redux';
import PopUpAlert from '../../../common/components/PopUpAlert';
import { useSelector } from 'react-redux';

const MasterRoles = () => {
  const dispatch = useDispatch();
  // const { authUser, code } = useSelector(({ auth }) => auth);
  const [selectedRol, setSelectedRol] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [selection, setSelection] = useState();
  const [flagEdit, setFlagEdit] = useState(false);
  const [openWindow, setOpenWindow] = useState(false);
  const [openPopUpAlert, setOpenPopUpAlert] = useState(false);
  const [openPopUpSuccess, setOpenPopUpSuccess] = useState(false);
  const [openPopUpInfo, setOpenPopUpInfo] = useState(false);
  const [mensaje, setMensaje] = useState(null);
  const { roles, setRoles, isLoadingRoles, error } = useRoles(process.env.REACT_APP_INSTITUCION);
console.log("roles",roles)
  const handleClickNew = () => {
    setFlagEdit(false);
    setOpenWindow(true);
  };
  const handleClickEdit = () => {
    setFlagEdit(true);
    setOpenWindow(true);
  };
  const handlePopUpSubmit = async values => {
    if (flagEdit) {
      const result = await updateRol(values);
      if (result.state) {
        setRoles(
          updateItem(roles, {
            index: selectedIndex,
            item: values
          })
        );
        setOpenWindow(false);
        setMensaje('Edición de rol exitoso')
        setOpenPopUpSuccess(true)
        // dispatch(fetchSuccess('Se creo el rol exitosamente'));
      } else {
        // dispatch(fetchError('Error al insertar rol : \n' + result.data.message));
        setOpenPopUpAlert(true)

        //dispatch(showMessageAndHide("Error: " + result.message));
      }
    } else {

      const result = await addRol(values);
      if (result.state) {
        setRoles(
          insertItem(roles, {
            index: roles.length,
            item: { ...values, id: roles.length }
          })
        );
        setOpenWindow(false);
        setMensaje('Rol ingresado con exito')
        setOpenPopUpSuccess(true)
      } else {
        setMensaje('Error: ' + result.message)
        setOpenPopUpAlert(true)
      }
    }
  };
  const handleOnClose = () => {
    setOpenWindow(false);
  };

  const handleSelectionRol = (value) => {
    if (value.length > 0) {
      setSelectedRol(value[0].codigo);
      setSelectedIndex(value[0].id)
    }
    else {
      setSelectedRol(undefined);
      setSelectedIndex(undefined)
    }
    setSelection(value);
  };

  if (isLoadingRoles) {
    return <div></div>;
  }
  console.log("roles", roles)
  // console.log("options",options)
  return (
    <Paper style={{ position: 'relative', padding: '20px' }} elevation={0}>
      <div >

        <ActionButton
          title={'Añadir Rol'}
          text={'Añadir Rol'}
          icon={<AddBoxIcon />}
          onClick={() => handleClickNew()}
          disabled={false}
        />

        <ActionButton
          title={'Editar Rol'}
          text={'Editar Rol'}
          icon={<EditIcon />}
          onClick={() => handleClickEdit()}
          disabled={!(selectedIndex != undefined)}
        />

        <Form
          initialValues={{
            roles: roles,
          }}
          onSubmit={() => { }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ padding: '10px' }}>
              <GridRoles onSelectionChange={handleSelectionRol} />
            </form>
          )}
        />
        <PopUp
          flagEdit={flagEdit}
          open={openWindow}
          onClose={handleOnClose}
          handlePopUpSubmit={handlePopUpSubmit}
          showProgressBar={false}
          title={'Administracion roles'}
          size="xl"
          rolCode={selectedRol}
        />
      </div>

      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-1 col-xl-1">

        <PopUpAlert
          type={"success"}
          show={openPopUpSuccess}
          title={"Título"}
          message={mensaje}
          onConfirm={() => setOpenPopUpSuccess(false)}
          onCancel={() => setOpenPopUpSuccess(false)}
        />
        <PopUpAlert
          type={"error"}
          show={openPopUpAlert}
          title={"Título"}
          message={mensaje}
          onConfirm={() => setOpenPopUpAlert(false)}
          onCancel={() => setOpenPopUpAlert(false)}
        />
        <PopUpAlert
          type={"info"}
          show={openPopUpInfo}
          title={"Título"}
          message={mensaje}
          onConfirm={() => setOpenPopUpInfo(false)}
          onCancel={() => setOpenPopUpInfo(false)}
        />

      </div>

    </Paper>

  );
};

export default MasterRoles;
