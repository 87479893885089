import React from "react";
import { Skeleton } from "@mui/material";

const SkeletonForm = () => {
  return (
    <div /* style={{ padding: "0 1rem" }} */>
      <Skeleton
        variant="rectangular"
        width="100%"
        height="5vh"
        sx={{ borderRadius: "32px" }}
      />
      <br />
      <Skeleton
        variant="rectangular"
        width="100%"
        height="25vh"
        sx={{ borderRadius: "32px" }}
      />
      <br />
      <Skeleton
        variant="rectangular"
        width="35%"
        height="5vh"
        sx={{ borderRadius: "32px" }}
      />
    </div>
  );
};

export default SkeletonForm;
