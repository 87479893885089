import React, { useEffect, useState } from "react";
import { consultarGrupoGenerico } from "../service/homeService";
import { useSelector } from "react-redux";
import useClienteLocalStorage from "app/modules/app-cliente/hook/useClienteLocalStorage";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const InitialPage = () => {
  const { authUser } = useSelector(({ auth }) => auth);

  const [isLoading, setIsLoading] = useState(true);

  const [codigoGrupo, setCodigoGrupo] = useClienteLocalStorage(
    "idGrupoStorage",
    null
  );

  useEffect(() => {
    const getGrupo = async () => {
      try {
        const respuestaGrupo = await consultarGrupoGenerico(authUser.key, 2);

        console.log(respuestaGrupo);

        if (respuestaGrupo.state) {
          console.log(respuestaGrupo.data);
          setCodigoGrupo(Number(respuestaGrupo.data[0].codigo));

          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    getGrupo();
  }, [localStorage.getItem("idGrupoStorage")]);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  return (
    <div className="initialPage-container">
      {/* <header>
        <h1 className="initialPage-header">Servimax-SCS</h1>
      </header>
      <main className="initialPage-main"> */}
      {/* <img src={"/images/logo/logo-servimax.jpg"} alt="boton firma digital" /> */}
      {/* <img src={"/images/logo/fondo_Servimax.jpg"} alt="boton firma digital" /> */}
      {/* </main>
      <footer>
        <h2>Bienvenido</h2>
      </footer> */}
    </div>
  );
};

export default InitialPage;
