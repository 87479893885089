import React, { useState, useEffect, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import {
  EditingState,
  SortingState,
  PagingState,
  IntegratedSorting,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  SelectionState,
  IntegratedSelection,
  CustomPaging,
  DataTypeProvider,
  GroupingState,
  IntegratedGrouping,
} from "@devexpress/dx-react-grid";

import {
  Plugin,
  Template,
  TemplatePlaceholder,
} from "@devexpress/dx-react-core";

import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  TableFixedColumns,
  TableFilterRow,
  PagingPanel,
  TableInlineCellEditing,
  TableSelection,
  TableColumnResizing,
  TableGroupRow,
  GroupingPanel,
  Toolbar,
  DragDropProvider,
} from "@devexpress/dx-react-grid-material-ui";
import Paper from "@mui/material/Paper";

import {
  BooleanFormatterComponent,
  BooleanEditor,
  BooleanTypeProvider,
  NumberTypeProvider,
  StringTypeProvider,
  SelectTypeProvider,
  DateTypeProvider,
} from "../GridUtils/CommonTypeProvider";

import { Command, Cell, EditCell } from "../GridUtils/GridCommandCellButtons";

import { FilterCell } from "../GridUtils/FilterCell";
import {
  tableMessages,
  sortMessages,
  groupingPanelMessages,
  pagingPanelMessages,
  otherMessages,
} from "../../constants/GridMessages";

import { getRowId } from "../GridUtils/GridCommandCellButtons";

const CustomTableRow = ({ row, ...restProps }) => {
  return (
    <Table.Row
      {...restProps}
      style={{
        height: "20px",
      }}
    />
  );
};

const CustomTableHeaderRow = ({ row, ...restProps }) => {
  return (
    <Table.Row
      {...restProps}
      style={{
        //backgroundColor: "#07345C",
        margin: "25px",
      }}
    />
  );
};

const CustomToolbarMarkup = () => (
  <Plugin name="customToolbarMarkup">
    <Template name="toolbarContent">
      <span style={{ width: "110px", fontWeight: "bold", fontSize: "12px" }}>
        {otherMessages.groupBy}
      </span>
      <TemplatePlaceholder />
    </Template>
  </Plugin>
);

let GenericGrid = (props) => {
  const {
    columns,
    tableColumnExtensions,
    stringColumns,
    numberColumns,
    booleanColumns,
    focusableBooleanColumns,
    selectColumns,
    dateColumns,
    input,
    meta,
    emptyItem,
    errorMessage,
    gridValidate,
    commitValidate,
    catalogs,
    disableAdd,
    disableEdit,
    title,
    editingStateColumnExtensions,
    defaultSorting,
    defaultFilters,
    onFiltersChange,
    defaultCurrentPage,
    pageSize,
    enableInlineEditing,
    startEditAction,
    selectTextOnEditStart,
    enableSelection,
    onSelectionChange,
    cellComponent,
    isMultiSelect,
    clearSelection,
    enableDelete,
    enableColumnResizing,
    defaultColumnWidths,
    enableRemotePaging,
    totalCount,
    currentPage,
    onCurrentPageChange,
    customProviders,
    enableGrouping,
    grouping,
    onGroupingChange,
    enableDragDrop,
    enableGroupingPanel,
    enableToolbar,
    integratedFilteringColumnExtensions,
    currentSelection,
  } = props;
  /*   console.log('fechas', dateColumns); */
  const rows = input.value
    ? typeof input.value === "string" || input.value instanceof String
      ? JSON.parse(input.value)
      : Array.isArray(input.value)
      ? input.value
      : []
    : [];

  const { error } = meta;

  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);
  const [rowChanges, setRowChanges] = useState({});
  const [errors, setErrors] = useState({});
  const [leftFixedColumns] = useState([TableEditColumn.COLUMN_TYPE]);

  const [selection, setSelection] = useState([]);

  useEffect(() => {
    setSelection([]);
  }, [clearSelection]);

  useEffect(() => {
    if (currentSelection) setSelection(currentSelection);
  }, [currentSelection]);

  const changeAddedRows = (value) => {
    const temp = value.map((row) => {
      if (Object.keys(row).length) return row;
      else {
        const row = {
          ...emptyItem,
          ...value[0],
        };
        return row;
      }
    });

    const errors = temp.length
      ? gridValidate === undefined
        ? {}
        : gridValidate(temp[0])
      : {};
    setErrors(errors);
    setAddedRows(temp);
  };

  const changeRowChanges = (value) => {
    if (Object.entries(value).length === 0 && value.constructor === Object) {
      setErrors({});
      return;
    }

    const id = editingRowIds[0];

    const row = {
      ...rows[id],
      ...value[id],
    };

    const errors = gridValidate === undefined ? {} : gridValidate(row);
    setErrors(errors);

    setRowChanges(value);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    let newRows;

    if (added) {
      const startingAddedId =
        rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
      newRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
    }
    if (changed) {
      newRows = rows.map((row) =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row
      );
    }

    if (deleted) {
      const deletedSet = new Set(deleted);
      newRows = rows.filter((row) => !deletedSet.has(row.id));
    }

    if (commitValidate === undefined || !commitValidate(newRows)) {
      input.onChange(newRows);
    } else {
      setRowChanges({});
    }
  };

  const BooleanFormatter = useCallback(
    (props) => (
      <BooleanFormatterComponent {...props} commitChanges={commitChanges} />
    ),
    [commitChanges]
  );

  const showCommandFlag = !editingRowIds.length && !addedRows.length;

  return (
    <Fragment>
      {title && <Fragment>{title}</Fragment>}
      <Paper>
        <Grid
          rows={rows}
          columns={columns.map((item) => ({
            ...item,
            error: errors[item.name],
          }))}
          getRowId={getRowId}
        >
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={setEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={changeRowChanges}
            addedRows={addedRows}
            onAddedRowsChange={changeAddedRows}
            onCommitChanges={commitChanges}
            columnExtensions={editingStateColumnExtensions}
          />
          <SortingState defaultSorting={defaultSorting} />
          {stringColumns && <StringTypeProvider for={stringColumns} />}
          {booleanColumns && <BooleanTypeProvider for={booleanColumns} />}
          {enableInlineEditing && focusableBooleanColumns && (
            <DataTypeProvider
              for={focusableBooleanColumns}
              formatterComponent={BooleanFormatter}
              editorComponent={BooleanEditor}
            />
          )}
          {numberColumns && <NumberTypeProvider for={numberColumns} />}
          {dateColumns && <DateTypeProvider for={dateColumns} />}
          {selectColumns && (
            <SelectTypeProvider
              for={selectColumns}
              availableValues={catalogs}
              rowChanges={rowChanges}
            />
          )}
          {customProviders}
          <IntegratedSorting />
          <FilteringState
            defaultFilters={defaultFilters}
            onFiltersChange={onFiltersChange}
          />
          <IntegratedFiltering
            columnExtensions={integratedFilteringColumnExtensions}
          />
          {enableSelection && (
            <SelectionState
              selection={selection}
              onSelectionChange={(value) => {
                if (!isMultiSelect) {
                  const lastSelected = value.find(
                    (selected) => selection.indexOf(selected) === -1
                  );

                  if (lastSelected !== undefined) {
                    setSelection([lastSelected]);
                    if (onSelectionChange)
                      onSelectionChange(
                        rows.filter((i) => i.id === lastSelected)
                      );
                  } else {
                    setSelection([]);
                    if (onSelectionChange) onSelectionChange([]);
                  }
                } else {
                  setSelection(value);
                  if (onSelectionChange)
                    onSelectionChange(rows.filter((i) => value.includes(i.id)));
                }
              }}
            />
          )}
          {enableDragDrop && <DragDropProvider />}
          {enableGrouping && (
            <GroupingState
              grouping={grouping}
              onGroupingChange={onGroupingChange}
            />
          )}
          {enableGrouping && <IntegratedGrouping />}
          {enableSelection && <IntegratedSelection />}
          <PagingState
            defaultCurrentPage={defaultCurrentPage}
            pageSize={pageSize}
            currentPage={currentPage}
            onCurrentPageChange={onCurrentPageChange}
          />
          {!enableRemotePaging && <IntegratedPaging />}
          {enableRemotePaging && <CustomPaging totalCount={totalCount} />}
          <Table
            columnExtensions={tableColumnExtensions}
            cellComponent={cellComponent ? cellComponent : Cell}
            rowComponent={CustomTableRow}
            messages={tableMessages}
          />
          {enableColumnResizing && (
            <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
          )}
          <TableHeaderRow
            rowComponent={CustomTableHeaderRow}
            showSortingControls={defaultSorting ? true : false}
            messages={sortMessages}
          />
          {enableSelection && (
            <TableSelection
              showSelectAll={isMultiSelect}
              selectByRowClick={!isMultiSelect}
              highlightRow={!isMultiSelect}
              showSelectionColumn={isMultiSelect ? true : false}
            />
          )}
          {enableGrouping && <TableGroupRow />}
          {(enableGroupingPanel || enableToolbar) && <Toolbar />}
          {enableGroupingPanel && (
            <GroupingPanel messages={groupingPanelMessages} />
          )}
          {enableGroupingPanel && grouping && grouping.length > 0 && (
            <CustomToolbarMarkup />
          )}
          {pageSize > 0 && <PagingPanel messages={pagingPanelMessages} />}
          <TableEditRow cellComponent={EditCell} />
          {defaultFilters && <TableFilterRow cellComponent={FilterCell} />}
          {!disableEdit && (
            <TableEditColumn
              width={130}
              showAddCommand={!disableAdd && showCommandFlag}
              showEditCommand={showCommandFlag}
              showDeleteCommand={enableDelete}
              commandComponent={(props) => (
                <Command {...props} errors={errors} />
              )}
            />
          )}
          <TableFixedColumns leftColumns={leftFixedColumns} />
          {enableInlineEditing && (
            <TableInlineCellEditing
              startEditAction={startEditAction}
              selectTextOnEditStart={selectTextOnEditStart}
            />
          )}
        </Grid>
      </Paper>
      {error && <small style={{ color: "#fec03e" }}>{errorMessage}</small>}
    </Fragment>
  );
};

GenericGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  tableColumnExtensions: PropTypes.array,
  emptyItem: PropTypes.object,
  stringColumns: PropTypes.array,
  numberColumns: PropTypes.array,
  booleanColumns: PropTypes.array,
  selectColumns: PropTypes.array,
  dateColumns: PropTypes.array,
  errorMessage: PropTypes.string,
  commitValidate: PropTypes.func,
  gridValidate: PropTypes.func,
  enablePlaceholder: PropTypes.bool,
  catalogs: PropTypes.object,
  disabledAddEdit: PropTypes.bool,
};

export default GenericGrid;
