import React, { useEffect, useState } from "react";

import { Paper, useMediaQuery } from "@mui/material";

import DetalleTransaccionesCliente from "./DetalleTransaccionesCliente";
import DetalleValoresPorPagar from "./DetalleValoresPorPagar";
import DetalleMovimientosCliente from "./DetalleMovimientosCliente";
import DetalleOpcionGenerica from "./DetalleOpcionGenerica";
import DetalleCabeceraTransaccional from "./DetalleCabeceraTransaccional";
import DetalleEstadoCredito from "./DetalleEstadoCredito";
import DetallePagosTransaccional from "./DetallePagosTransaccional";
import DetalleDatosSeguros from "./DetalleDatosSeguros";

import { consultarCabeceraTsx } from "../services/transaccionalService";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import DetalleDatosSolicitud from "./DetalleDatosSolicitud";
import { LISTA_OPCIONES } from "../constants/constants";
import { useResolutionScreen } from "../hooks/useResolutionScreen";
import DetalleSiniestros from "./DetalleSiniestros";
import DetalleQuejas from "./DetalleQuejas";
import CabeceraCuentaCredito from "../components/CabeceraCuentaCredito";

const DetalleTransaccional = ({ datosBusqueda, setDatosBusqueda }) => {
  const [cabenceraTransaccional, setCabenceraTransaccional] = useState({
    idCuenta: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isColapse, setIsColapse] = useState(false);

  const { alturaResolucion } = useResolutionScreen();

  const showDropdownSearch = useMediaQuery("(max-width:1181px)");

  useEffect(() => {
    const getCabeceraTsx = async () => {
      try {
        const respuestaCabeceraTsx = await consultarCabeceraTsx(
          datosBusqueda.identificacion,
          datosBusqueda.idGrupo
        );

        console.log(respuestaCabeceraTsx);

        if (respuestaCabeceraTsx.state) {
          setCabenceraTransaccional(respuestaCabeceraTsx.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getCabeceraTsx();
  }, []);

  useEffect(() => {
    const actualizarTamañoPantalla = () => {
      if (showDropdownSearch) {
        setIsColapse(true);
      } else {
        setIsColapse(false);
      }
    };
    actualizarTamañoPantalla();
  }, [showDropdownSearch]);

  const handleColapse = () => {
    setIsColapse(!isColapse);
  };

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  if (
    cabenceraTransaccional.idCuenta <= 0 ||
    cabenceraTransaccional.idCuenta === null
  ) {
    return (
      <Paper
        sx={{ width: "70%", padding: "2rem", borderRadius: "25px" }}
        elevation={3}
      >
        Cliente no posee cuenta.!!
      </Paper>
    );
  }

  return (
    <div
      className={`${
        isColapse
          ? "movimientoCliente-container-colapse"
          : "movimientoCliente-container"
      }`}
    >
      <DetalleTransaccionesCliente
        isColapse={isColapse}
        datosBusqueda={datosBusqueda}
        setDatosBusqueda={setDatosBusqueda}
        handleColapse={handleColapse}
        alturaResolucion={alturaResolucion}
      />

      <section className="movimientoCliente-detalle">
        <CabeceraCuentaCredito
          cabenceraTransaccional={cabenceraTransaccional}
          datosBusqueda={datosBusqueda}
        />

        <div className="movimientoCliente-detalle-informacionContainer">
          {datosBusqueda.idMovimiento === 0 && (
            <section className="movimientoCliente-informacion">
              <DetalleOpcionGenerica
                alturaResolucion={alturaResolucion}
                mensaje={"Elije una opción para consultar tus datos."}
              />
            </section>
          )}

          {/* ESTADOS CUENTA */}
          {datosBusqueda.idMovimiento === LISTA_OPCIONES.ESTADO_CUENTA && (
            <section className="movimientoCliente-informacion">
              <DetalleValoresPorPagar
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}
          {/* MOV.A LA FECHA */}
          {datosBusqueda.idMovimiento === LISTA_OPCIONES.MOV_FECHA && (
            <section className="movimientoCliente-informacion">
              <DetalleMovimientosCliente
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}

          {/* ESTADO CREDITO*/}
          {datosBusqueda.idMovimiento === LISTA_OPCIONES.ESTADO_CREDITO && (
            <section className="movimientoCliente-informacion">
              <DetalleEstadoCredito
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}

          {datosBusqueda.idMovimiento === LISTA_OPCIONES.ESTADO_SOLICITUD && (
            <section className="movimientoCliente-informacion">
              <DetalleDatosSolicitud
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}
          {/* PAGOS */}
          {datosBusqueda.idMovimiento === LISTA_OPCIONES.PAGOS && (
            <section className="movimientoCliente-informacion">
              <DetallePagosTransaccional
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}
          {/* DATOS SEGURO */}
          {datosBusqueda.idMovimiento === LISTA_OPCIONES.DATOS_SEGURO && (
            <section className="movimientoCliente-informacion">
              <DetalleDatosSeguros
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}
          {/* SINIESTROS */}
          {datosBusqueda.idMovimiento === LISTA_OPCIONES.SINIESTROS && (
            <section className="movimientoCliente-informacion">
              <DetalleSiniestros
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}
          {/* QUEJAS */}
          {datosBusqueda.idMovimiento === LISTA_OPCIONES.QUEJAS && (
            <section className="movimientoCliente-informacion">
              <DetalleQuejas
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}
        </div>
      </section>
    </div>
  );
};

export default DetalleTransaccional;
