import React, { memo, useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import Container from "@mui/material/Container";

import "../../styles/main.css";

import { obtenerConsulmax } from "app/modules/app-evaluacion/services/evaluacionServices";
import handleDownlPdf from "app/utils/document/documentosService";
import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";
import { obtenerSolicitiudGestionar } from "../../services/creditoServices";
import { Grid } from "@mui/material";
import BuscadorIdentificacion from "../../containers/BuscadorIdentificacion";

function GestionSolicitudContent() {

  const [mostrarAprobado, setMostrarAprobado] = useState(false);
  const [mostrarNegado, setMostrarNegado] = useState(false);
  const [identificacionCliente, setIdentificacionCliente] = useState("");
  const [archivoDescargado, setArchivoDescargado] =
    useState(false);
  const [solicitudExistente, setSolicitudExistente] = useState([]);
  const [mensajeCliente, setMensajeCiente] = useState("");
  const [estadoSolicitud, setEstadoSolicitud] = useState([]);
  const [flujoSol, setflujoSol] = useState("Revision");
  const history = useHistory();


  const traerIdentificacion = (id) => async () => {
    
    setIdentificacionCliente(id);
    console.log(id)
    obtenerSolicitud(id)

  };

  const routeChange = (idRow) => {
    const path = "/credito/revisionAnalista";
    history.push(path, { state: { id: idRow, flujo: flujoSol } });
    //navigate(path , { state: { id: idRow , flujo : flujoSol} });
  };

  const obtenerSolicitud = async (identificacion)=>{
    try
    {
      const resp = await obtenerSolicitiudGestionar(identificacion);
      if(resp.state)
      {
        //console.log(resp.data);
        routeChange(resp.data.idSolicitud);
      }
      else
      {
        alert(resp.message);
      }

    }
    catch(e)
    {
      alert(e.message);
    }
  }


  const obtenerReporte = async (identificacion)=>{

   await obtenerConsulmax(identificacion)
    .then((response)=>{
      if(response.state)
      {
        handleDownlPdf(response.data, `Consulmax${identificacion}`);
        setArchivoDescargado(true);

      }

      setTimeout(() => {
        setArchivoDescargado(false);
      }, 10000);
      
    })
    .catch()

    console.log(identificacion);

  };

  return (
    <>
      <Container maxWidth="md">
        <Grid container spacing={3} justifyContent="center">
          <Grid item md={8} sm={8} xs={8}>
              <BuscadorIdentificacion traerIdentificacion={traerIdentificacion}/>
          </Grid>   
{/* 
          <Grid lg={12}>
          {archivoDescargado && (
                <Alert severity="success" variant="filled">
                  Se ha Descargado el Archivo.
                </Alert>
              )}
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}

export default memo(GestionSolicitudContent);
