import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import {
  Alert,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
  Accordion,
  AccordionSummary,
  Grid,
  AccordionDetails,
} from "@mui/material";

import InputLabel from "@mui/material/InputLabel";

import Select from "@mui/material/Select";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import {
  crearColaborador,
  listarLocales,
} from "../services/configuracionServices";

import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import ModalAlertaNotificacionEstandar from "app/modules/app-servicios/components/ModalAlertaNotificacionEstandar";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const FormImformacionColaborador = ({
  idColaborador, //-1
  colaborador, //{}
}) => {
  const [idColaboradorAux, setIdColaboradorAux] = useState(idColaborador);
  const [localesInformacion, setLocalesInformacion] = useState([]);
  const [validarExisteLocal, setValidarExisteLocal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [openLocal, setOpenLocal] = useState(false);

  useEffect(() => {
    console.log({ colaborador });
    console.log({ idColaborador });
  }, [localesInformacion]);

  const SelectItemsLocal = ({
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
    nombreLocal,
  }) => {
    const [local, setLocal] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await listarLocales();
          console.log(data);
          if (state) {
            setLocal(data);

            const arrayRespuesta = data.filter(
              (local) => nombreLocal === local.idLocal
            );

            if (arrayRespuesta.length === 0) {
              setValidarExisteLocal(true);
              setOpenLocal(true);
            } else {
              setValidarExisteLocal(false);
              setOpenLocal(false);
            }
          } else {
            setLocal([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, [nombreLocal]);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {local.map((local, index) => (
            <MenuItem key={index} value={local.idLocal}>
              {`${local.idLocal} - ${local.nombreLocal}`}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const informacionColaborador = {
      idColaborador: Number(`${idColaboradorAux < 0 ? 0 : idColaboradorAux}`),
      idVendedor: Number(valores.idVendedor),
      identificacion: Number(valores.identificacion),
      idLocal: Number(valores.idLocal),
      primerNombre: valores.primerNombre.toUpperCase(),
      segundoNombre: valores.segundoNombre.toUpperCase(),
      primerApellido: valores.primerApellido.toUpperCase(),
      segundoApellido: valores.segundoApellido.toUpperCase(),
      esActivo: valores.esActivo,
    };
    console.log("VALORES", informacionColaborador);

    try {
      const respuestaColaborador = await crearColaborador(
        informacionColaborador
      );
      if (respuestaColaborador.state) {
        setDatosEnviadosCorrectamente(true);
        setIdColaboradorAux(respuestaColaborador.data);

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
        }, 2000);
      } else {
        alert(`${respuestaColaborador.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <>
      <div className="accordion-container">
        {idColaboradorAux < 0 && (
          <Alert
            color="warning"
            severity="warning"
            sx={{ padding: 1, margin: 1 }}
          >
            Colaborador NO existe.!! Complete y guarde la información.
          </Alert>
        )}

        {validarExisteLocal == true && idColaboradorAux > 0 && (
          <ModalAlertaNotificacionEstandar
            setOpen={setOpenLocal}
            open={openLocal}
            titulo="No existe Local"
            mensaje={`No se encuentra creado el Local del Colaborador, debes crear primero el local !! Código Local : ${idColaboradorAux}`}
          />

          ////NO BORRAR, opción 2 mensaje crear local

          // <Alert
          //   color="warning"
          //   severity="warning"
          //   sx={{ padding: 1, margin: 1 }}
          // >
          //   Local NO existe.!! Crea primero el Local.
          // </Alert>
        )}

        <Formik
          initialValues={{
            idVendedor: colaborador.idVendedor ?? "",
            identificacion: colaborador.identificacion ?? "",
            idLocal: colaborador.idLocal ?? "",
            primerNombre: colaborador.primerNombre ?? "",
            segundoNombre: colaborador.segundoNombre ?? "",
            primerApellido: colaborador.primerApellido ?? "",
            segundoApellido: colaborador.segundoApellido ?? "",
            esActivo: colaborador.esActivo ?? true,
          }}
          // validate={(values) => {
          //   const errors = {};

          //   if (validarExisteLocal) {
          //     errors.idLocal = "Local requerido";
          //   }
          //   return errors;
          // }}
          validationSchema={Yup.object({
            idVendedor: Yup.string().required("Código Colaborador requerido"),
            identificacion: Yup.string().required("Identificación requerido"),
            idLocal: Yup.string().required("Local requerido"),
            primerNombre: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Primer Nombre requerido"),
            primerApellido: Yup.string()
              .max(15, "Debería tener máximo 15 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Primer Apellido requerido"),
            esActivo: Yup.string().required("Estado requerido"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            // console.log(values);
            configuracionDatosEnvio(values);
          }}
        >
          {({ values, handleChange, touched, errors, handleBlur }) => (
            <Form>
              <div className="accordion-container">
                <div>
                  <Accordion
                    defaultExpanded="true"
                    sx={{ boxShadow: 3, lineHeight: 0 }}
                  >
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      className="accordion-summary"
                      sx={{ margin: 0 }}
                    >
                      <h2 className="formulario-cabecera">Colaborador</h2>
                    </AccordionSummary>

                    <AccordionDetails sx={{ marginTop: "2rem" }}>
                      <Grid container spacing={1} rowSpacing={1}>
                        <Grid item md={12}>
                          <Grid container spacing={1}>
                            <Grid item md={12}>
                              <div className="cliente-informacion-seccion">
                                <TextField
                                  id="idVendedor"
                                  name="idVendedor"
                                  type="number"
                                  label="Codigo Vendedor"
                                  sx={{ width: "20%" }}
                                  value={values.idVendedor}
                                  onChange={handleChange}
                                  size="small"
                                  error={
                                    touched.idVendedor &&
                                    Boolean(errors.idVendedor)
                                  }
                                  helperText={
                                    touched.idVendedor && errors.idVendedor
                                  }
                                  onBlur={handleBlur}
                                />

                                <TextField
                                  id="identificacion"
                                  name="identificacion"
                                  type="number"
                                  label="Identificación"
                                  sx={{ width: "25%" }}
                                  value={values.identificacion}
                                  onChange={handleChange}
                                  size="small"
                                  error={
                                    touched.identificacion &&
                                    Boolean(errors.identificacion)
                                  }
                                  helperText={
                                    touched.identificacion &&
                                    errors.identificacion
                                  }
                                  onBlur={handleBlur}
                                />

                                <TextField
                                  id="idLocal"
                                  name="idLocal"
                                  type="number"
                                  label="Código Local"
                                  sx={{ width: "12%" }}
                                  value={values.idLocal}
                                  onChange={handleChange}
                                  size="small"
                                  error={
                                    touched.idLocal && Boolean(errors.idLocal)
                                  }
                                  helperText={touched.idLocal && errors.idLocal}
                                  onBlur={handleBlur}
                                  disabled="true"
                                />

                                <SelectItemsLocal
                                  name="idLocal"
                                  labelName="Local"
                                  componentSize="30%"
                                  handleBlur={handleBlur}
                                  touched={touched}
                                  errors={errors}
                                  onBlur={handleBlur}
                                  nombreLocal={values.idLocal}
                                />

                                {/* {validarLocalExistente(values.idLocal)} */}
                              </div>

                              <div className="cliente-informacion-seccion">
                                <TextField
                                  id="primerApellido"
                                  name="primerApellido"
                                  type="text"
                                  label="Primer Apellido"
                                  sx={{ width: "23%" }}
                                  value={values.primerApellido}
                                  onChange={handleChange}
                                  size="small"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  error={
                                    touched.primerApellido &&
                                    Boolean(errors.primerApellido)
                                  }
                                  helperText={
                                    touched.primerApellido &&
                                    errors.primerApellido
                                  }
                                  onBlur={handleBlur}
                                />

                                <TextField
                                  id="segundoApellido"
                                  name="segundoApellido"
                                  type="text"
                                  label="Segundo Apellido"
                                  sx={{ width: "23%" }}
                                  value={values.segundoApellido}
                                  onChange={handleChange}
                                  size="small"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  error={
                                    touched.segundoApellido &&
                                    Boolean(errors.segundoApellido)
                                  }
                                  helperText={
                                    touched.segundoApellido &&
                                    errors.segundoApellido
                                  }
                                  onBlur={handleBlur}
                                />

                                <TextField
                                  id={"primerNombre"}
                                  name="primerNombre"
                                  type="text"
                                  label="Primer Nombre"
                                  sx={{ width: "23%" }}
                                  value={values.primerNombre}
                                  onChange={handleChange}
                                  size="small"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  error={
                                    touched.primerNombre &&
                                    Boolean(errors.primerNombre)
                                  }
                                  helperText={
                                    touched.primerNombre && errors.primerNombre
                                  }
                                  onBlur={handleBlur}
                                />

                                <TextField
                                  id="segundoNombre"
                                  name="segundoNombre"
                                  type="text"
                                  label="Segundo Nombre"
                                  sx={{ width: "23%" }}
                                  value={values.segundoNombre}
                                  onChange={handleChange}
                                  size="small"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                  error={
                                    touched.segundoNombre &&
                                    Boolean(errors.segundoNombre)
                                  }
                                  helperText={
                                    touched.segundoNombre &&
                                    errors.segundoNombre
                                  }
                                  onBlur={handleBlur}
                                />
                              </div>

                              <div className="cliente-informacion-seccion">
                                <FormGroup
                                  error={
                                    touched.esActivo && Boolean(errors.esActivo)
                                  }
                                  helperText={
                                    touched.esActivo && errors.esActivo
                                  }
                                  onBlur={handleBlur}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.esActivo}
                                        onChange={handleChange}
                                        name="esActivo"
                                      />
                                    }
                                    label="Activo"
                                  />
                                  <ErrorMessage
                                    name="esActivo"
                                    component={() => (
                                      <FormHelperText>
                                        {errors.esActivo}
                                      </FormHelperText>
                                    )}
                                  />
                                </FormGroup>
                              </div>

                              <div className="confirmar-estados-servicios">
                                <LoadingButton
                                  sx={{ width: "20%" }}
                                  type="submit"
                                  size="small"
                                  loading={loading}
                                  loadingPosition="end"
                                  variant="contained"
                                >
                                  Guardar
                                </LoadingButton>
                                {datosEnviadosCorrectamente && (
                                  <AcceptServiceresponse />
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default FormImformacionColaborador;
