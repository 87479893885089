import http from "../../../../comunication/http-common";

export const consultaUbiGeografica1 = async (
  especial,
  catalogo,
  codPadre,
  grupo
) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/obtenerSP?especial=${especial}&catalogo=${catalogo}&codPadre=${codPadre}&grupo=${grupo}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const consultaCatalogosSinPadre = async (especial, catalogo, grupo) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/obtenerSP?especial=${especial}&catalogo=${catalogo}&grupo=${grupo}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const consultaCatalogosConPadre = async (especial, catalogo, codPadre, grupo) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/obtenerSP?especial=${especial}&catalogo=${catalogo}&codPadre=${codPadre}&grupo=${grupo}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
