import React, { useEffect, useState } from "react";
import { Route, useNavigate, useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Alert, Button, Grid } from "@mui/material";

import {
  cambiarEstadoSolicitud,
  obtenerDocumento,
  obtenerSolicitud,
} from "../services/creditoServices";
import { consultaCliente } from "app/modules/app-cliente/services/clienteService";
import CabeceraSolicitud from "../components/CabeceraSolicitud";

import "../styles/main.css";
import InformacionCliente from "../../app-cliente/containers/InformacionCliente";
import FormClienteActividadEconomica from "../../app-cliente/components/FormClienteActividadEconomica";
import FormClienteReferencias from "../../app-cliente/components/FormClienteReferencias";
import { useSelector } from "react-redux";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import handleDownlPdf from "app/utils/document/documentosService";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function DigitacionSolicitud() {
  const { authUser } = useSelector(({ auth }) => auth);
  const history = useHistory();

  const [value, setValue] = useState(0);
  const [idSolicitud, setIdSolicitud] = useState(
    history.location.state.state.id
  );
  const [solicitud, setSolicitud] = useState({});
  const [cliente, setCliente] = useState({});
  const [idCliente, setIdCliente] = useState(-1);
  const [mostrarInfo, setMostrarInfo] = useState(false);
  const [actividadEconomica, setActividadEconomica] = useState(null);
  const [archivoDescargado, setArchivoDescargado] = useState(false);

  useEffect(() => {
    cambiarEstadoSolicitud(idSolicitud, 11, authUser.key, authUser.estacion);
    getSolicitud(idSolicitud);
  }, []);

  useEffect(() => {
    //console.log(solicitud.idCliente);
    getCliente(solicitud.identificacion);
  }, [solicitud]);

  useEffect(() => {}, [cliente]);

  const getSolicitud = (id) => {
    obtenerSolicitud(id)
      .then((response) => {
        if (response.state) {
          console.log("RESPUESTA", response);
          setSolicitud(response.data);
        } else {
          console.log(response.message);
        }
      })
      .catch((_errors) => {
        alert(_errors);
      });
  };

  const getCliente = (id) => {
    consultaCliente(id).then((response) => {
      if (response.state) {
        setCliente(response.data);
        setIdCliente(response.data.idCliente);
      } else console.log(response.message);
    });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const obtenerDoc = async () => {
    //console.log("Intentando descargar", identificacion);
    await obtenerDocumento(idSolicitud)
      .then((response) => {
        if (response.state) {
          handleDownlPdf(response.data, `Solicitud_Credito_${idSolicitud}`);
          setArchivoDescargado(true);
        }

        setTimeout(() => {
          setArchivoDescargado(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <CabeceraSolicitud idSolic={idSolicitud} />
      </Box>
      {archivoDescargado && (
        <Alert severity="success" variant="filled">
          Se ha Descargado el Archivo.
        </Alert>
      )}
      <Button
        className="buscador-botones-preev"
        variant="contained"
        onClick={obtenerDoc}
      >
        Visualizar documentacion
      </Button>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} marginTop="30px">
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="success"
        >
          <Tab
            label="Información Cliente"
            {...a11yProps(0)}
            className="tabsDigitacion"
          />
          <Tab
            label="Información Económica"
            {...a11yProps(1)}
            className="tabsDigitacion"
          />
          <Tab
            label="Información Referencias"
            {...a11yProps(2)}
            className="tabsDigitacion"
          />
        </Tabs>
      </Box>
      {idCliente > 0 ? (
        <div>
          <TabPanel value={value} index={0}>
            <InformacionCliente cliente={cliente} idCliente={idCliente} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FormClienteActividadEconomica
              cliente={cliente}
              idCliente={idCliente}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <FormClienteReferencias cliente={cliente} idCliente={idCliente} />
          </TabPanel>
        </div>
      ) : (
        <SpinnerLoadSrx />
      )}
    </Box>
  );
}

export default DigitacionSolicitud;
