import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";

import TaskIcon from "@mui/icons-material/Task";
import downloadExcel from "app/utils/document/documentoDescargaExel";
import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";
import { consultarReporteRequerimiento } from "../services/ReporteService";
import { formatoFecha } from "app/modules/shared/validations/validaciones";

const BotonConsultaGestionRequerimiento = () => {
  const [reporteDiario, setReporteDiario] = useState([]);
  const [loading, setLoading] = useState(false);

  const obtenerReporteRequerimientos = async () => {
    try {
      const respuestaGestionRequerimiento =
        await consultarReporteRequerimiento();

      if (respuestaGestionRequerimiento.state) {
        const respuestaSiniestroDiarioAux =
          respuestaGestionRequerimiento.data.map((requerimiento, index) => {
            return {
              ...requerimiento,
              fechaIngreso: formatoFecha(requerimiento.fechaIngreso),
              ID: index,
            };
          });

        setReporteDiario(respuestaSiniestroDiarioAux);

        downloadExcel(
          respuestaGestionRequerimiento.data,
          `REPREQUERIMIENTOS_${fechasDocumentosSiniestro()}`
        );
        setLoading(false);
      } else {
        setReporteDiario([]);
        setLoading(false);
        alert("Error: No se ha podido gererar el reporte !!");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Error: No se ha podido gererar el reporte !!");
    }
  };

  return (
    <div className="tipoSiniestro-tipos">
      <LoadingButton
        size="large"
        loading={loading}
        variant="contained"
        loadingPosition="end"
        endIcon={<TaskIcon />}
        onClick={obtenerReporteRequerimientos}
      >
        Reporte Balcón de Servicios
      </LoadingButton>
    </div>
  );
};

export default BotonConsultaGestionRequerimiento;
