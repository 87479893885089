import React, { useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import ProgressBar from "../../../common/components/ProgressBar";
import ActionButton from "../../../common/components/TransactionalButtons/ActionButton";
import PopUp from "../UsersComponents/Popup";
import { Form, Field } from "react-final-form";
import GridUsers from "../UsersComponents/GridUsuarios";

import useUsers from "../../hooks/hookUsuarios";

import { addUser } from "../../services/index";
import { insertItem, updateItem } from "../../../../utils/common";
import { useDispatch } from "react-redux";
//import { user } from '@fake-db/apps/profile';
import { useRoles } from "../../hooks/hookRoles";

import PopUpAlert from "../../../common/components/PopUpAlert";

const MasterUsers = () => {
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedIndexUser, setSelectedIndexUser] = useState();
  const [flagEdit, setFlagEdit] = useState(false);
  const [openWindow, setOpenWindow] = useState(false);
  const { users, setUsers, isLoadingUsers, error } = useUsers();
  const { roles, setRoles, isLoadingRoles, errorRoles } = useRoles(
    process.env.REACT_APP_SISTEMA
  );
  const [openPopUpAlert, setOpenPopUpAlert] = useState(false);
  const [openPopUpSuccess, setOpenPopUpSuccess] = useState(false);
  const [openPopUpInfo, setOpenPopUpInfo] = useState(false);
  const [mensaje, setMensaje] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleClickNew = () => {
    setFlagEdit(false);
    setOpenWindow(true);
  };
  const handleClickEdit = () => {
    setFlagEdit(true);
    setOpenWindow(true);
  };
  const handleClickDesbloquear = () => {};
  const handlePopUpSubmit = async (values) => {
    setLoading(true);
    debugger;
    let userSave = {};
    userSave.key = values.key;
    userSave.email = values.email;
    userSave.sistema = process.env.REACT_APP_SISTEMA;
    //userSave.rol = '';
    userSave.tipoIdentificacion = values.tipoIdentificacion;
    userSave.identificacion = values.identificacion;
    userSave.nombres = values.nombres;
    userSave.apellidos = values.apellidos;
    userSave.telefono1 = values.telefono1;
    //userSave.telefono2 = values.telefono2;
    //userSave.direccion = values.direccion;
    userSave.activo = values.activo;
    userSave.ubicacionGeografica2 = values.ubicacionGeografica2;
    userSave.oficina = values.oficina;
    // userSave.token = '';
    // userSave.imei = '';
    // userSave.so = '';
    // userSave.version = '';
    // userSave.registros = [];
    // userSave.usuarioSistemas = [];
    userSave.usuarioRol = values.usuarioRol;
    var response = await addUser(userSave);

    if (response.state) {
      if (!flagEdit) {
        setUsers(
          insertItem(users, {
            index: users.length,
            item: { ...userSave, id: users.length },
          })
        );
      } else {
        setUsers(
          updateItem(users, {
            index: selectedIndexUser,
            item: userSave,
          })
        );
      }
      setOpenWindow(false);
      flagEdit
        ? setMensaje("Usuario actualizado con exito")
        : setMensaje("Usuario ingresado con exito");
      setOpenPopUpSuccess(true);
    } else {
      setMensaje("Error: " + response.message);
      setOpenPopUpAlert(true);
    }
    setLoading(false);
  };
  const handleOnClose = () => {
    setOpenWindow(false);
  };

  const handleSelectionUser = (value) => {
    if (value.length > 0) {
      setSelectedIndexUser(value[0].id);
      setSelectedUser(value[0]);
    } else {
      setSelectedIndexUser(undefined);
      setSelectedUser(undefined);
    }
  };

  if (isLoadingUsers || loading || isLoadingRoles) {
    //return <ProgressBar />;
  }

/*   console.log("roles", roles);
  console.log("users", users); */

  return (
    <Paper style={{ position: "relative", padding: "20px" }} elevation={0}>
      <div>
        <ActionButton
          title={"Añadir Usuario"}
          text={"Añadir Usuario"}
          icon={<AddBoxIcon />}
          onClick={() => handleClickNew()}
          disabled={false}
        />

        <ActionButton
          title={"Editar Usuario"}
          text={"Editar Usuario"}
          icon={<EditIcon />}
          onClick={() => handleClickEdit()}
          disabled={!(selectedIndexUser != undefined)}
        />
        {/* <ActionButton
          title={'Desbloquer'}
          text={'Desbloquer'}
          icon={<EditIcon />}
          onClick={() => handleClickDesbloquear()}
          disabled={!(selectedIndexUser != undefined && selectedUser.estado == 'CONECTADO')}
        /> */}

        <Form
          initialValues={{
            users: users,
          }}
          onSubmit={() => {}}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} style={{ padding: "10px" }}>
              <GridUsers onSelectionChange={handleSelectionUser} />
            </form>
          )}
        />
        <PopUp
          flagEdit={flagEdit}
          open={openWindow}
          onClose={handleOnClose}
          handlePopUpSubmit={handlePopUpSubmit}
          showProgressBar={false}
          title={"Administracion usuarios"}
          size="xl"
          user={selectedUser}
          roles={roles}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-1 col-xl-1">
        <PopUpAlert
          type={"success"}
          show={openPopUpSuccess}
          title={"Título"}
          message={mensaje}
          onConfirm={() => setOpenPopUpSuccess(false)}
          onCancel={() => setOpenPopUpSuccess(false)}
        />
        <PopUpAlert
          type={"error"}
          show={openPopUpAlert}
          title={"Título"}
          message={mensaje}
          onConfirm={() => setOpenPopUpAlert(false)}
          onCancel={() => setOpenPopUpAlert(false)}
        />
        <PopUpAlert
          type={"info"}
          show={openPopUpInfo}
          title={"Título"}
          message={mensaje}
          onConfirm={() => setOpenPopUpInfo(false)}
          onCancel={() => setOpenPopUpInfo(false)}
        />
      </div>
    </Paper>
  );
};

export default MasterUsers;
