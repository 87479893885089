import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import BuscadorPorFechas from "../../containers/BuscadorPorFechas";
import DetalleReporteQueja from "../../containers/DetalleReporteQueja";

const ReporteQuejas = () => {
  const [mostrarBuscador, setMostrarBuscador] = useState(true);
  const [datosBusqueda, setDatosBusqueda] = useState({
    fechaInicio: "",
    fechaFin: "",
  });

  useEffect(() => {
    console.log(datosBusqueda);
  }, [datosBusqueda]);

  if (mostrarBuscador) {
    return (
      <>
        <BuscadorPorFechas
          setDatosBusqueda={setDatosBusqueda}
          setMostrarBuscador={setMostrarBuscador}
        />
      </>
    );
  }

  return (
    <>
      <Container maxWidth="mx-auto">
        <Grid container spacing={3}>
          <Grid item md={12} sm={12} xs={12}>
            <DetalleReporteQueja datosBusqueda={datosBusqueda} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ReporteQuejas;
