import React from "react";
import { useState } from "react";
import DocumentoSoporteDevolucion from "../components/DocumentoSoporteDevolucion";
import FormDevolucionProductos from "../components/FormDevolucionProductos";

const DevolucionProductos = ({ idCliente, idLocal }) => {
  const [idRequerimiento, setIdRequerimiento] = useState(null);

  return (
    <div className="accordion-container">
      <FormDevolucionProductos
        idCliente={idCliente}
        setIdRequerimiento={setIdRequerimiento}
        idLocal={idLocal}
      />
      {idRequerimiento && (
        <DocumentoSoporteDevolucion idRequerimiento={idRequerimiento} />
      )}
    </div>
  );
};

export default DevolucionProductos;
