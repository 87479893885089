import React, { useEffect } from "react";

import Card from "@mui/material/Card";

const CabeceraClienteTransaccional = ({ cabenceraTransaccional }) => {
  useEffect(() => {}, [cabenceraTransaccional]);

  return (
    <Card sx={{ minHeight: "10vh", maxHeight: "auto", borderRadius: "25px" }}>
      <header className="header-client-information">
        <div>
          <h3>Identificación</h3>
          <h4>
            {cabenceraTransaccional.identificacion
              ? cabenceraTransaccional.identificacion
              : ""}
          </h4>
        </div>
        <div>
          <h3>Nombre</h3>
          <h4>
            {cabenceraTransaccional.nombreCliente
              ? cabenceraTransaccional.nombreCliente
              : ""}
          </h4>
        </div>
        <div>
          <h3>Grupo</h3>
          <h4>
            {cabenceraTransaccional.grupo ? cabenceraTransaccional.grupo : ""}
          </h4>
        </div>

        <div>
          <h3>N° Tarjeta</h3>
          <h4>
            {cabenceraTransaccional.numeroTarjeta
              ? cabenceraTransaccional.numeroTarjeta
              : ""}
          </h4>
        </div>
      </header>
    </Card>
  );
};

export default CabeceraClienteTransaccional;
