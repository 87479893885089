import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ActionButton from "../TransactionalButtons/ActionButton";


//const colorAzul = window.app.env.REACT_APP_BASE_RGB_LABEL
//const colorNaranja = window.app.env.REACT_APP_BASE_RGB_NARANJA

const PopUpAlert = ({
  show,
  title,
  onConfirm,
  onCancel,
  cancelBtnText,
  confirmBtnText,
  message,
  type,
  ...otherProps
}) => {
  const newTitle =
    type === "info"
      ? title :
      type === "success" ? "Transacción Exitosa" :
        type === "error" ? "Error" :
          type === "warning" ? "Advertencia" :
            type === "input" ? "Confirmar Transacción" :
              type === "question" ? title != undefined
                ? title
                : "Atención"
                : "Atención";

  return (
    <SweetAlert
      show={show}
      // type={type}
      type={type === "info" ? type : type === "input" ? type : "custom"}
      title={<span style={{ color: "#434343" }}>{newTitle}</span>}
      showCancel={type === "question" || type === "input"}
      reverseButtons
      confirmBtnCssClass="cap_confirmBtnCssClass"
      cancelBtnCssClass="cap_cancelBtnCssClass"

      onConfirm={onConfirm}
      onCancel={onCancel}
      style={{ width: "36em", borderRadius: "15px" }}
      customIcon={
        <div style={{ textAlign: "center", margin: "15px" }}>
          <img
            height={"96px"}
            width={"96px"}
            alt={""}
            src={type === "success"
              ? "/images/icons/TRANSEXITOSA-128.png"
              : type === "question"
                ? "/images/icons/CONFIRTRANSAC-128.png"
                : type === "input"
                  ? "/images/icons/CONFIRTRANSAC-128.png"
                  : type === "error"
                    ? "/images/icons/ERROR-128.png"
                    : type === "warning"
                      ? "/images/icons/Advertencia128.png"
                      : type === "info"
                        ? "/images/icons/ADVERTENCIA-128.png"
                        : "/images/icons/ADVERTENCIA-128.png"}
          />



        </div>
      }
      cancelBtnText={
        "Cancelar"
        // <ActionButton
        //   title={""}
        //   text={"Cancelar"}
        //   // icon={
        //   //   <img
        //   //     style={{ color: 'white' }}
        //   //     alt={""}
        //   //     src={require("assets/images/icons/CANCELAR-32.png")}
        //   //   />
        //   // }
        //   style={{
        //     width: '120px',
        //     height: '42px', borderRadius: '24px', color: 'white', backgroundColor: '#FF8C00'
        //   }}
        // />
      }
      confirmBtnText={
        type === "success" || type === "question" || type === "info" ?
        "Aceptar"
          // <ActionButton
          //   title={""}
          //   text={"Aceptar"}
          //   // icon={
          //   //   <img style={{ color: 'white' }} alt={""}
          //   //     src={require("assets/images/icons/ACEPTAR-32.png")} />
          //   // }
          //   variant="contained"
          //   color={"default"}
          //   style={{
          //     width: '120px',
          //     height: '42px', borderRadius: '24px', color: 'white', backgroundColor: '#0000FF'
          //   }}
          // /> 
          : 
          "Aceptar"
          // <ActionButton
          //   title={""}
          //   text={"Aceptar"}
          //   // icon={
          //   //   <img style={{ color: 'white' }} alt={""}
          //   //     src={require("assets/images/icons/ACEPTAR-32.png")} />
          //   // }
          //   variant="contained"
          //   color={"default"}
          //   style={{
          //     width: '120px',
          //     height: '42px', borderRadius: '24px', color: 'white', backgroundColor: '#FF8C00'
          //   }}
          // />
      }
      closeOnClickOutside={false}
      {...otherProps}
    >
      {message && (
        <p style={{ fontSize: "20px", marginTop: "10px", color: "black" }}>
          {message}
        </p>
      )}
    </SweetAlert>
  );
};

export default PopUpAlert;
