import React, { useEffect, useState } from "react";
import { useFormikContext, Formik, Form, Field, useField } from "formik";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  Alert,
  Box,
  Card,
  CardMedia,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AodOutlinedIcon from "@mui/icons-material/AodOutlined";
import CircularProgress from "@mui/material/CircularProgress";

import { obtenerCabeceraCliente } from "../services/creditoServices";

import "../styles/main.css";
import TabFirmaFotoCliente from "../containers/tabFirmaFotoCliente";
import {
  SCSGenericButton,
  SCSLoadingButton,
} from "app/modules/common/components/Buttons/SCSButtons";
import { obtenerDocumentoConfiguracion } from "app/modules/app-configuracion/services/configuracionServices";
import { red } from "@mui/material/colors";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";

const BoxDocumentosCliente = ({
  idCli,
  cliente,
  identifCliente,
  idSolicitudChanger,
  setInfoGrabada,
  registroFirmaFoto,
  setArchivoFirmaFoto,
}) => {
  const { authUser } = useSelector(({ auth }) => auth);

  const [clienteInfo, setClienteInfo] = useState(null);
  const [idSolicitudActual, setIdSolicitudActual] = useState(null);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [alertaGuardar, setAlertaGuardar] = useState(false);
  const [mensajeError, setMensajeError] = useState("");

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firmaBase64, setFirmaBase64] = useState(null);
  const [fotoBase64, setFotoBase64] = useState(null);
  const [cedulaBase64, setCedulaBase64] = useState(null);

  useEffect(() => {
    console.log("USUARIO EN FIRMA", identifCliente);
    console.log("AQUI", authUser);
    console.log("data", registroFirmaFoto);
    setFirmaBase64(registroFirmaFoto.firma1);
    obtenerArchivoBucket("foto").then((resp) => {
      setFotoBase64(resp);
    });
    obtenerArchivoBucket("cedula").then((resp) => {
      setCedulaBase64(resp);
    });
    if (setInfoGrabada) {
      setInfoGrabada((args) => ({
        ...args,
        firma: true,
      }));
    }

    //getArchivosFirmaFoto();
  }, []);

  const obtenerArchivoBucket = async (tipo) => {
    console.log("Intentando descargar", tipo);
    try {
      let nombreArchivo = "";

      switch (tipo) {
        case "cedula":
          nombreArchivo = registroFirmaFoto.cedula;
          break;
        case "foto":
          nombreArchivo = registroFirmaFoto.foto;
          break;
        default:
          console.log("No existe el tipo de archivo solicitado" + tipo);
      }

      const response = await obtenerDocumentoConfiguracion(
        nombreArchivo,
        "archivos"
      );
      if (response.state) {
        //console.log(nombreArchivo);
        //console.log(response.data);
        return response.data;
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  if (idCli < 0) {
    return <h2>Debe crear primero un cliente !!</h2>;
  }
  return (
    <>
      <SCSAccordion tituloCabecera="Documentos Digitales">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Firma</TableCell>
                <TableCell align="center">Foto</TableCell>
                <TableCell align="center">Cedula</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" width="30%">
                  {firmaBase64 ? (
                    <img
                      height={120}
                      width={170}
                      src={`data:image/jpeg;base64, ${firmaBase64}`}
                    />
                  ) : (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress />
                    </Box>
                  )}
                </TableCell>
                <TableCell align="center" width="30%">
                  {fotoBase64 ? (
                    <img
                      height={120}
                      width={170}
                      src={`data:image/jpeg;base64, ${fotoBase64}`}
                    />
                  ) : (
                    <Box>
                      <CircularProgress color="error" />
                    </Box>
                  )}
                </TableCell>
                <TableCell align="center" width="40%">
                  {cedulaBase64 ? (
                    <img
                      height={250}
                      width={170}
                      src={`data:image/jpeg;base64, ${cedulaBase64}`}
                    />
                  ) : (
                    <Box>
                      <CircularProgress color="error" />
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {setArchivoFirmaFoto && (
          <Stack alignItems="center" marginTop={3}>
            <SCSGenericButton
              titulo="Recapturar archivos"
              onClick={() => {
                setArchivoFirmaFoto(false);
                if (setInfoGrabada) {
                  setInfoGrabada((args) => ({
                    ...args,
                    firma: false,
                    financiero: false,
                  }));
                }
              }}
            />
          </Stack>
        )}
      </SCSAccordion>
    </>
  );
};

export default BoxDocumentosCliente;
