import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import SiniestroPagos from "./SiniestroPagos/SiniestroPagos";

const PagosSiniestro = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);

  return (
    <>
      <h2 className="titulosPrincipales-container">Siniestro - Pagos</h2>
      <SiniestroPagos />
    </>
  );
};

export default PagosSiniestro;
