import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";

import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";

import {
  consultarDocumentosSoporte,
  crearSoporteRequerimiento,
} from "../services/solicitudesService";
import { LoadingButton } from "@mui/lab";
import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/shared/mask/stylesTables";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { diccionarioExtenciones } from "app/modules/shared/dictionary/extensionDocumentos";

const DocumentoSoporteDevolucion = ({ idRequerimiento }) => {
  const [documentos, setDocumentos] = useState([]);

  const [extensionDocumento, setExtensionDocumento] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [file, setFile] = useState("");

  const [fileIMG, setFileIMG] = useState(null);
  const [idDocumentoAux, setIdDocumentoAux] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      let respuesta;
      try {
        respuesta = await consultarDocumentosSoporte(3, true);
        setIsLoading(false);

        if (respuesta.state) {
          setDocumentos(respuesta.data);
          console.log(respuesta);
        } else {
          setDocumentos([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const cargarPDF = ({ target }) => {
    if (target.file < 1 || !target.validity.valid) {
      return;
    }

    base64Transform(target.files[0], (err, result) => {
      if (result) {
        const documentSplit = result.split(",");
        /* setFile(result); */
        setFileIMG(result);
        setFile(documentSplit[1]);
        /* setFile(documentSplit[0]) */
        /* console.log(documentSplit[0]); */
        /*   console.log(documentSplit[1]);
        console.log(result); */
        setIsSelected(true);
      }
    });
  };

  const base64Transform = (archivo, cb) => {
    const reader = new FileReader();

    reader.readAsDataURL(archivo);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const handleUpload = (event) => {
    /*  console.log(event); */
    cargarPDF(event);

    /*     carga de documentos - sacar el tipo de documento a subir   */

    const fileAux = event.target.files[0].type.split("/");
    /* console.log(fileAux[1]); */
    setExtensionDocumento(fileAux[1]);
    /* console.log(event.target.files[0].type); */
    setSelectedFile(event.target.files[0]);
  };

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const configuracionDatosEnvioDocumento = async (idTipoRequerimiento) => {
    setLoading(true);

    //validacion para control de carga de documentos
    if (file.length === 0) {
      setOpen(true);
      setLoading(false);
      return;
    }
    /* validacion que permita un solo documento por devolucion-productos*/
    const informacionSoporteDocumentos = {
      idSoporteRequerimiento: 0,
      idRequerimiento: Number(idRequerimiento),
      idSoporte: Number(idTipoRequerimiento),
      archivoBase64: file,
      nombreArchivo: `REQ_DEVPRO_${idRequerimiento}_${idTipoRequerimiento}_${fechasDocumentosSiniestro()}`,
      extension: diccionarioExtenciones(extensionDocumento),
      esActivo: true,
    };

    /* console.log(informacionSoporteDocumentos); */

    try {
      const respuestaSoporteDocumentos = await crearSoporteRequerimiento(
        informacionSoporteDocumentos
      );
      console.log(respuestaSoporteDocumentos);

      if (respuestaSoporteDocumentos.state) {
        setLoading(false);
        alert("Documento Guardado Con Exito!!");
      } else {
        setLoading(false);
        alert(`${respuestaSoporteDocumentos.message}`);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Error: Servicio No Disponible");
    }

    setFile("");
    setSelectedFile(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Documento</StyledTableCell>
              <StyledTableCell align="center">Detalle</StyledTableCell>

              <StyledTableCell align="center">Cargar</StyledTableCell>
              <StyledTableCell align="center">Guardar</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentos.map((documento, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {documento.descripcion}
                </StyledTableCell>

                <StyledTableCell component="th" scope="row" align="center">
                  {isSelected &&
                  idDocumentoAux === documento.idTipoRequerimiento ? (
                    <div>
                      <p>Nombre: {selectedFile.name}</p>
                      {/*  <p>Tipo: {selectedFile.type}</p> */}
                    </div>
                  ) : (
                    <p>Archivo!! </p>
                  )}
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Button
                    variant="outlined"
                    component="label"
                    size="small"
                    sx={{ width: "70%" }}
                    startIcon={<FileUploadIcon />}
                    onClick={() => {
                      setIdDocumentoAux(documento.idTipoRequerimiento);
                    }}
                  >
                    <input
                      hidden
                      accept=".png, .jpg, .jpeg, .pdf"
                      type="file"
                      onChange={handleUpload}
                    />
                    Cargar
                  </Button>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <LoadingButton
                    sx={{ width: "70%" }}
                    type="submit"
                    size="small"
                    loading={loading}
                    loadingPosition="end"
                    variant="outlined"
                    onClick={() =>
                      configuracionDatosEnvioDocumento(
                        documento.idTipoRequerimiento
                      )
                    }
                  >
                    Guardar
                  </LoadingButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
        /* key={("bottom", "center")} */
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Cargue Primero un Documento !!
        </Alert>
      </Snackbar>
    </>
  );
};

export default DocumentoSoporteDevolucion;
