import React, { memo, useState, useEffect } from "react";
import Container from "@mui/material/Container";

import "../../styles/main.css";

import { obtenerConsulmax } from "app/modules/app-evaluacion/services/evaluacionServices";
import handleDownlPdf from "app/utils/document/documentosService";
import BuscadorIdentificacion from "../../containers/BuscadorIdentificacion";
import { Alert, Grid } from "@mui/material";

function ConsulmaxContent() {

  const [identificacionCliente, setIdentificacionCliente] = useState("");
  const [archivoDescargado, setArchivoDescargado] =
    useState(false);


  const traerIdentificacion = (id) => async () => {
    
    setIdentificacionCliente(id);
    console.log(identificacionCliente)
    obtenerReporte(id)

  };

  const obtenerReporte = async (identificacion)=>{

   await obtenerConsulmax(identificacion)
    .then((response)=>{
      if(response.state)
      {
        handleDownlPdf(response.data, `Consulmax${identificacion}`);
        setArchivoDescargado(true);

      }

      setTimeout(() => {
        setArchivoDescargado(false);
      }, 10000);
      
    })
    .catch()

    console.log(identificacion);

  };

  return (
    <>
      <Container maxWidth="md">
        <Grid container spacing={3} justifyContent="center">
          <Grid item md={8} sm={8} xs={8}>
              <BuscadorIdentificacion traerIdentificacion={traerIdentificacion}/>
          </Grid>   

          <Grid lg={12}>
          {archivoDescargado && (
                <Alert severity="success" variant="filled">
                  Se ha Descargado el Archivo.
                </Alert>
              )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default memo(ConsulmaxContent);
