import React, { useEffect, useState } from "react";

import SendIcon from "@mui/icons-material/Send";

import HeaderInformacionCliente from "../../shared/containers/HeaderInformacionCliente";

import { formatoFecha } from "../../shared/validations/validaciones";

import { consultaSiniestros } from "../Services/siniestrosService";
import BotonSalirLimpiarStorage from "./BotonSalirLimpiarStorage";
import {
  SCSTableData,
  SCSTableDataCell,
  SCSTableDataRow,
} from "app/modules/common/components/TableData/SCSTableData";

import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";

const ListaSiniestrosCliente = ({ idCliente, setDatosBusqueda }) => {
  const [clienteSiniestros, setClienteSiniestros] = useState([]);
  const [existenSiniestros, setExistenSiniestros] = useState(false);

  useEffect(() => {
    const getSiniestros = async () => {
      try {
        const respuestaSiniestros = await consultaSiniestros(idCliente);

        if (respuestaSiniestros.state) {
          console.log(respuestaSiniestros);
          setExistenSiniestros(true);
          setClienteSiniestros(respuestaSiniestros.data);
        } else {
          setExistenSiniestros(true);
          setClienteSiniestros([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (idCliente > 0) {
      getSiniestros();
    }
  }, []);

  const actualizarDatosBusqueda = (siniestro) => {
    setDatosBusqueda({
      identificacion: siniestro.numeroSiniestro.toString(),
      opcionBusqueda: "codigo",
      idGrupo: siniestro.idGrupo,
    });
  };

  const columnsData = [
    { id: 1, name: "Grupo" },
    { id: 2, name: "N° Siniestro" },
    { id: 3, name: "Flujo" },
    { id: 4, name: "Articulo" },
    { id: 5, name: "Fecha Siniestro" },
    { id: 6, name: "Fecha de Notificación" },
    { id: 7, name: "Estado" },
    { id: 8, name: "Gestionar" },
  ];

  return (
    <>
      <BotonSalirLimpiarStorage />

      <HeaderInformacionCliente idCliente={idCliente} />
      <h2 className="listaSiniestros_titulo">Siniestros del cliente</h2>
      {existenSiniestros ? (
        <SCSTableData columnsData={columnsData} heightTable={35}>
          {clienteSiniestros.map((siniestro) => (
            <SCSTableDataRow key={siniestro.idSiniestro}>
              <SCSTableDataCell component="th" scope="row">
                {siniestro.grupo}
              </SCSTableDataCell>
              <SCSTableDataCell component="th" scope="row">
                {siniestro.numeroSiniestro}
              </SCSTableDataCell>
              <SCSTableDataCell align="center">
                {siniestro.flujo}
              </SCSTableDataCell>
              <SCSTableDataCell align="center">
                {siniestro.articulo}
              </SCSTableDataCell>
              <SCSTableDataCell align="center">
                {formatoFecha(siniestro.fechaSiniestro)}
              </SCSTableDataCell>
              <SCSTableDataCell align="center">
                {formatoFecha(siniestro.fechaNotificacion)}
              </SCSTableDataCell>
              <SCSTableDataCell align="center">
                {siniestro.estado}
              </SCSTableDataCell>

              <SCSTableDataCell align="center">
                <SCSGenericButton
                  size={100}
                  titulo="Gestionar"
                  endIcon={<SendIcon />}
                  onClick={() => actualizarDatosBusqueda(siniestro)}
                />
              </SCSTableDataCell>
            </SCSTableDataRow>
          ))}
        </SCSTableData>
      ) : (
        <h1>No existen siniestros para este usuario</h1>
      )}
    </>
  );
};

export default ListaSiniestrosCliente;
