import React from 'react';
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUT_NAMES} from "../../../layouts/layouts";
import ConsulmaxContent from './Consulmax/ConsulmaxContent';


const ConsulmaxPage = () => {
  const {setActiveLayout} = useJumboApp();

  React.useEffect(() => {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
      <div>
        <h2>CONSULMAX</h2>
        <ConsulmaxContent></ConsulmaxContent>
      </div>
  );
};

export default ConsulmaxPage;