import React from "react";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: 800,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalGestionUsuarios = ({
  children,
  open,
  setOpen,
  titulo,
  mensaje,
  solicitud,
}) => {
  return (
    <BootstrapDialog
      onClose={() => setOpen((args) => ({ ...args, state: false }))}
      aria-labelledby="customized-dialog-title"
      open={open.state}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => setOpen((args) => ({ ...args, state: false }))}
      >
        {titulo}
      </BootstrapDialogTitle>
      {/* <DialogContent dividers>
        <Typography gutterBottom>{mensaje}</Typography>
      </DialogContent> */}

      {children && <DialogContent dividers>{children}</DialogContent>}

      <DialogActions>
        <Button
          autoFocus
          onClick={() => setOpen((args) => ({ ...args, state: false }))}
        >
          Cerrar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ModalGestionUsuarios;
