import React, { useEffect, useState } from "react";

import FormSiniestroGestion from "./GestionSiniestro/FormSiniestroGestion";

import HeaderInformacionCliente from "../../shared/containers/HeaderInformacionCliente";
import FormProductoGestion from "./GestionSiniestro/FormProductoGestion";
import FormNotificacionGestion from "./GestionSiniestro/FormNotificacionGestion";
import BotonSalirLimpiarStorage from "./BotonSalirLimpiarStorage";

import { consultaSiniestroXIdSiniestro } from "../Services/siniestrosService";
import FormInformacionAdicional from "../components/FormInformacionAdicional";

const DetalleSiniestroDetalleGestion = ({
  idCliente,
  idSiniestro,
  idContrato,
  idGrupo,
}) => {
  const [verificarEsActivoSiniestro, setVerificarEsActivoSiniestro] =
    useState(true);

  const [idFlujoAux, setIdFlujoAux] = useState(null);

  useEffect(() => {
    console.log({ idGrupo });
    const verificarEsActivo = async () => {
      try {
        const respuestaSiniestroXId = await consultaSiniestroXIdSiniestro(
          idSiniestro
        );
        console.log(respuestaSiniestroXId);

        if (respuestaSiniestroXId.data.esActivo) {
          setVerificarEsActivoSiniestro(false);
          setIdFlujoAux(respuestaSiniestroXId.data.idFlujo);
        }
      } catch (error) {
        console.log(error);
      }
    };

    verificarEsActivo();
  }, []);

  if (verificarEsActivoSiniestro) {
    return (
      <>
        <BotonSalirLimpiarStorage />
        <h1>El siniestro se encuentra inactivo</h1>;
      </>
    );
  }

  return (
    <div className="accordion-container">
      <HeaderInformacionCliente idCliente={idCliente} />

      <FormSiniestroGestion idSiniestro={idSiniestro} idGrupo={idGrupo} />

      {idFlujoAux === 5 || idFlujoAux === 6 ? (
        <FormInformacionAdicional
          idSiniestro={idSiniestro}
          idFlujo={idFlujoAux}
          idGrupo={idGrupo}
        />
      ) : (
        <FormProductoGestion
          idSiniestro={idSiniestro}
          idContrato={idContrato}
          idGrupo={idGrupo}
        />
      )}

      <FormNotificacionGestion
        idSiniestro={idSiniestro}
        idCliente={idCliente}
      />
    </div>
  );
};

export default DetalleSiniestroDetalleGestion;
