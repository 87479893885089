import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import SiniestroInformeTecnico from "./SiniestroInformeTecnico/SiniestroInformeTecnico";

const InformeTecnicoSiniestro = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <>
      <h2 className="titulosPrincipales-container">
        Siniestro - Informe Técnico
      </h2>
      <SiniestroInformeTecnico />
    </>
  );
};

export default InformeTecnicoSiniestro;
