import { saveAs } from "file-saver";

function handleDownlPdf(file, nombreDocumento) {
  /* console.log("Transformar a documento"); */
  /* text/plain;charset=utf-8 */

  const binary = atob(file.replace(/\s/g, ""));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i += 1) {
    view[i] = binary.charCodeAt(i);
  }

  const blod = new Blob([view], { type: "application/pdf" });
  saveAs(blod, `${nombreDocumento}.pdf`);
}

export default handleDownlPdf;
