import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../../layouts/layouts";

import UsuarioDesbloqueo from "./UsuarioDesbloqueo/UsuarioDesbloqueo";
//import InformacionUsuario from "./InformacionUsuario/InformacionUsuario";

const DesbloqueoUsuario = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Desbloqueo - Usuario </h2>
      <UsuarioDesbloqueo />
    </div>
  );
};

export default DesbloqueoUsuario;
