import axios from "axios";

function generate_uuidv4() {
  var dt = new Date().getTime();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var rnd = Math.random() * 16; //random number in range 0 to 16
    rnd = (dt + rnd) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? rnd : (rnd & 0x3) | 0x8).toString(16);
  });
}

export const onLogin = async (email, password) => {
 
  const result = await axios.get("https://api.ipify.org");
  let ipAdress = result.data;

  //let ipAdress = generate_uuidv4();

  const body = {
    Key: email,
    Password: password,
    Sistema: process.env.REACT_APP_SISTEMA,
    Estacion: ipAdress,
    Grupo:   localStorage.getItem('origen')
  };
  const url =`${process.env.REACT_APP_API_AUTH}identity/sign-in`;
  const coreResponse = await axios.post(url, body);


  return coreResponse.data;

};

export const onConnect = async (rol, user) => {

  const result = await axios.get("https://api.ipify.org");
  let ipAdress = result.data;

  //let ipAdress = generate_uuidv4();

  const body = {
    Key: user.key,
    Password: "",
    Sistema: process.env.REACT_APP_SISTEMA,
    Estacion: ipAdress,
    Grupo:   localStorage.getItem('origen'),
    UsuarioRol: [
      {
        idRol: rol,
      },
    ],
  };

  const url =`${process.env.REACT_APP_API_AUTH}identity/connect`;
  const coreResponse = await axios.post(url, body);
  return coreResponse.data;
};

export const onChangePassword = async (payload) => {
 

  let body = {
    Key: payload.userCode,
    Sistema: process.env.REACT_APP_SISTEMA,
    Password: payload.password,
    NewPassword: payload.newPassword,
  };
 
  const url =`${process.env.REACT_APP_API_AUTH}identity/changepassword`;
  let coreResponse = await axios.post(url, body);
  return coreResponse.data;
};

export const onLogout = async (userCode) => {

  let body = {
    Key: userCode,
    Sistema: process.env.REACT_APP_SISTEMA,
  };

  const url =`${process.env.REACT_APP_API_AUTH}identity/signout`;
  let coreResponse = await axios.post(url, body);
  return coreResponse.data;
};


export const onForgotPassword = async (userCode) => {
  let body = {
    Key: userCode,
    Sistema: process.env.REACT_APP_SISTEMA,
  };
  
  const url =`${process.env.REACT_APP_API_AUTH}identity/forgetpassword`;
  let coreResponse = await axios.post(url, body);
  return coreResponse.data;
};

export const onValidarOtp = async (payload) => {
 

  let body = {
    Key: payload.userCode,
    Sistema: process.env.REACT_APP_SISTEMA,
    Password: payload.password,
    NewPassword: "",
  };
 
  const url =`${process.env.REACT_APP_API_AUTH}identity/validarotp`;
  let coreResponse = await axios.post(url, body);
  return coreResponse.data;
};

export const onActualizarOtp = async (payload) => {
 

  let body = {
    Key: payload.userCode,
    Sistema: process.env.REACT_APP_SISTEMA,
    Password: "",
    NewPassword: "",
  };
 
  const url =`${process.env.REACT_APP_API_AUTH}identity/actualizarotp`;
  let coreResponse = await axios.post(url, body);
  return coreResponse.data;
};