import http from "../../../comunication/http-common";

/* eslint-disable camelcase */

//10.10.0.79:5022/Cobranza/ObtenerlistaCobranza?idUsuario=1247
export const consultarListaCobranzaXUsuario = async (idUsuario) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_COBRANZA}Cobranza/ObtenerlistaCobranza?idUsuario=${idUsuario}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//10.10.0.79:4002/Identity/obtenertodosXRol?sistema=SCS&rol=GESTORCBR
export const consultarListaUsuariosCobranza = async (idUsuario) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_AUTH}Identity/obtenertodosXRol?sistema=SCS&rol=GESTORCBR`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//10.10.0.79:5022/Cobranza/ObtenerEstado
export const consultaEstadoCobranza = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_COBRANZA}Cobranza/ObtenerEstado`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//10.10.0.79:5022 /Cobranza/ObtenerSubEstado?idEstado=2
export const consultaSubestadoCobranza = async (idEstado) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_COBRANZA}Cobranza/ObtenerSubEstado?idEstado=${idEstado}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//10.10.0.79:5022 /Cobranza/ObtenerAsignacionXIdUsuario?IdUsuario=1247
export const consultaAsignacionXUsuario = async (idUsuario) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_COBRANZA}Cobranza/ObtenerAsignacionXIdUsuario?IdUsuario=${idUsuario}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//10.10.0.79:5000/Credito/ObtenerCabeceraSolicitudXIdentificacion?identificacion=1713158101
export const consultaCabeceraSolicitud = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}Solicitud/ObtenerCabeceraSolicitudXIdCliente?idCliente=${idCliente}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//10.10.0.79:5022/Cobranza/ObtenerPermisoXIdUsuario?idUsuario=1263
export const consultaListaPermisos = async (idUsuario) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_COBRANZA}Cobranza/ObtenerPermisoXIdUsuario?idUsuario=${idUsuario}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//10.10.0.79:5022/Cobranza/ObtenerParametroXIdPeriodo?idPeriodo=1
export const consultaParametroPeriodo = async (idPeriodo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_COBRANZA}Cobranza/ObtenerParametroXIdPeriodo?idPeriodo=${idPeriodo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//10.10.0.79:5022/Cobranza/ObtenerPerfilXIdPeriodo?idPeriodo=1
export const consultaPerfilPeriodo = async (idPeriodo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_COBRANZA}Cobranza/ObtenerPerfilXIdPeriodo?idPeriodo=${idPeriodo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5022/Cobranza/ObtenerlistaCobranzaHis?idCliente=197780
export const consultarListaHistoricoCobranza = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_COBRANZA}Cobranza/ObtenerlistaCobranzaHis?idCliente=${idCliente}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5016/comunicacion/RealizarLlamada?idUsuario=1277&Telefono=0960624698
export const consultarLlamadaUsuario = async (idUsuario, telefono) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}comunicacion/RealizarLlamada?idUsuario=${idUsuario}&Telefono=${telefono}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5022/Cobranza/ObtenerRegistroCobranza?idUsuario=1277
export const consultarSiguienteLlamada = async (idUsuario) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_COBRANZA}Cobranza/ObtenerRegistroCobranza?idUsuario=${idUsuario}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//10.10.0.79:5022/Cobranza/ActualizarCobranza
export const crearCobranza = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_COBRANZA}Cobranza/ActualizarCobranza`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//10.10.0.79:5022/Cobranza/ActualizarCobranza
export const crearPermisos = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_COBRANZA}Cobranza/ActualizarCobranza`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//10.10.0.79:5022/Cobranza/GuardarAsignacion
export const crearAsignacion = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_COBRANZA}Cobranza/GuardarAsignacion`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//10.10.0.79:5022/Cobranza/GuardarParametro
export const crearParametro = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_COBRANZA}Cobranza/GuardarParametro`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
