import React, { useState } from "react";
import { Form, Field, FormSpy } from 'react-final-form'
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
//import  renderTextField  from '../RenderFields/index';
import { RenderTextField } from "../RenderFields";
import {
    required,
    minLength,
    maxLength,
    passwordsMustMatch,
    composeValidators
} from "../RenderFields/validations";



const ChangePassword = ({ onSubmit, form }) => {
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={form}
            validate={values => {
                const errors = {}
                if (values.newPassword !== values.confirmNewPassword) {
                    errors.confirmNewPassword = "Las contraseñas no coinciden "
                }
                return errors
            }}
            render={({ handleSubmit, reset, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} noValidate>

                    <div className="row">
                        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-6 col-xl-6">
                            <Field
                                name="currentPassword"
                                component={RenderTextField}
                                label="Contraseña actual"
                                placeholder="Contraseña actual"
                                validate={composeValidators(required)}
                                type="password"
                                autoComplete="new-password"
                                autoFocus
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-6 col-xl-6">
                            <Field
                                name="newPassword"
                                component={RenderTextField}
                                label="Nueva contraseña"
                                placeholder="Nueva contraseña"
                                validate={composeValidators(required, minLength(5), maxLength(15))}
                                type="password"
                                autoComplete="new-password"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-6 col-xl-6">
                            <Field
                                name="confirmNewPassword"
                                component={RenderTextField}
                                label="Confirmar nueva contraseña"
                                placeholder="Confirmar nueva contraseña"
                                validate={composeValidators(required, minLength(5), maxLength(15))}
                                type="password"
                                autoComplete="new-password"
                            />
                        </div>
                    </div>

                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitting || pristine}
                    >
                        Cambiar contraseña
                    </Button>
                    {/* <Alert /> */}

                </form>
            )}
        />




    );
};

export default ChangePassword
