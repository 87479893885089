import React,{useState} from "react";
import { IconButton, Popover, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InfoPopoverButton = ({nombre, texto, children}) => {
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? `info${nombre}` : undefined;

  return (
    <>
      <IconButton onClick={handleClick}>
        <InfoOutlinedIcon color="success" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ sx: { backgroundColor:  "#B1B1B1", color: "#191B19" } }}
      >
        <div dangerouslySetInnerHTML={{ __html:texto }}/>
        {children}
      </Popover>
    </>
  );
};

export default InfoPopoverButton;
