import React, { useEffect, useState } from "react";

import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { consultarProductosServimax } from "../services/citasMedicasService";
import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ListaProductosCliente = ({
  idCliente,
  identificacionCliente,
  setIdProductoAux,

  /* setIdQuejaStorage, */
}) => {
  const [existenQuejas, setExistenQuejas] = useState(true);
  const [clienteInfo, setClienteInfo] = useState(null);
  const [clienteProductos, setClienteProductos] = useState([]);

  useEffect(() => {
    const getSiniestros = async () => {
      if (idCliente < 0) {
        setExistenQuejas(false);
      } else {
        try {
          const respuestaProductosSeguros = await consultarProductosServimax(
            identificacionCliente
          );

          /* console.log(respuestaProductosSeguros);
           */
          if (
            respuestaProductosSeguros.state &&
            respuestaProductosSeguros.data
          ) {
            setClienteProductos(respuestaProductosSeguros.data);
            setExistenQuejas(false);
            /*  setClienteInfo(cliente); */
          } else {
            setExistenQuejas(false);
            setClienteProductos([]);
          }
        } catch (error) {
          setExistenQuejas(false);
          console.log(error);
        }
      }
    };
    getSiniestros();
  }, []);

  if (existenQuejas) {
    return <SpinnerLoadSrx />;
  }

  const escogerIdQueja = (idQueja) => {
    setIdProductoAux(idQueja);
    /* setIdQuejaStorage(idQueja); */
  };

  return (
    <>
      <HeaderInformacionCliente idCliente={idCliente} />
      <div className="tipoSiniestro-container">
        <div className="tipoSiniestro-siniestros">
          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Nombre Producto</StyledTableCell>
                  <StyledTableCell>Fecha Inicio</StyledTableCell>
                  <StyledTableCell>Fecha Vigencia</StyledTableCell>
                  <StyledTableCell>Estado</StyledTableCell>
                  <StyledTableCell>Generar</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clienteProductos.map((clienteProducto) => (
                  <StyledTableRow key={clienteProducto.idQueja}>
                    <StyledTableCell>
                      {clienteProducto.nombreProducto}
                    </StyledTableCell>
                    <StyledTableCell>
                      {clienteProducto.fechaInicio}
                    </StyledTableCell>

                    <StyledTableCell component="th" scope="row">
                      {clienteProducto.fechaVigencia}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {clienteProducto.estado}
                    </StyledTableCell>

                    <StyledTableCell>
                      <Button
                        variant="contained"
                        onClick={() =>
                          escogerIdQueja(clienteProducto.idProducto)
                        }
                        endIcon={<SendIcon />}
                      >
                        Gestionar
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default ListaProductosCliente;
