import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";
import { crearAsignacion } from "../services/cobranzaServices";
import { MaskAsignacion } from "app/modules/shared/mask/mascarasInputs";

const FormGestionAsignacion = ({
  usuario,
  asignacionMaxima,
  setUpdate,
  update,
}) => {
  const [loading, setLoading] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const configuraionEnvioDatos = async (valores) => {
    console.log(valores);

    const informacionAsignacion = {
      idAsignacion: asignacionMaxima.idAsignacion,
      idUsuario: usuario.idUsuario,
      asignacionMaxima: Number(valores.asignacionMaxima),
      esActivo: true,

      /* fechaCreacion: "2023-04-10T00:00:00",
      usuarioCreacion: "procManual",
      estacionCreacion: "1.1.1.1",
      fechaModificacion: null,
      usuarioModificacion: null,
      estacionModificacion: null, */
    };

    console.log({ informacionAsignacion });

    try {
      const respuestaCrearAsignacion = await crearAsignacion(
        informacionAsignacion
      );

      console.log(respuestaCrearAsignacion);

      if (respuestaCrearAsignacion.state) {
        setDatosEnviadosCorrectamente(true);
        setUpdate(!update);
        /* setOpenQueja(true); */
        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
        }, 2000);
      } else {
        setLoading(false);
        alert(`${respuestaCrearAsignacion.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <>
      <h4>Actualizar asignación máxima: </h4>
      <Formik
        initialValues={{
          asignacionMaxima: "",
        }}
        validationSchema={Yup.object({
          asignacionMaxima: Yup.string().required(
            "Asignación Máxima requerida"
          ),
        })}
        onSubmit={(valores, { resetForm }) => {
          configuraionEnvioDatos(valores);
          setTimeout(() => {
            resetForm({ values: "" });
          }, 2000);
        }}
      >
        {({ values, handleChange, touched, errors, handleBlur }) => (
          <Form>
            <div className="accordion-container">
              <div>
                <Grid container spacing={1} rowSpacing={1}>
                  <Grid item md={12}>
                    <Grid container spacing={1}>
                      <Grid item md={12}>
                        <div className="cliente-informacion-seccion">
                          {/* Cambiar input tipo number */}

                          <FormControl
                            sx={{ width: "30%" }}
                            size="small"
                            variant="outlined"
                            error={
                              touched.asignacionMaxima &&
                              Boolean(errors.asignacionMaxima)
                            }
                            helperText={
                              touched.asignacionMaxima &&
                              errors.asignacionMaxima
                            }
                            onBlur={handleBlur}
                          >
                            <InputLabel htmlFor="asignacionMaxima">
                              Asignacion Maxima
                            </InputLabel>
                            <OutlinedInput
                              value={values.asignacionMaxima}
                              onChange={handleChange}
                              name="asignacionMaxima"
                              id="asignacionMaxima"
                              inputComponent={MaskAsignacion}
                              /* label es el tama;o que maneja el label */
                              label="Asignación Máxima"
                            />
                            <ErrorMessage
                              name="asignacionMaxima"
                              component={() => (
                                <FormHelperText>
                                  {errors.asignacionMaxima}
                                </FormHelperText>
                              )}
                            />
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                    <div className="confirmar-estados-servicios">
                      <LoadingButton
                        sx={{ width: "20%" }}
                        type="submit"
                        size="small"
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        /* startIcon={<AttachMoneyIcon />} */
                      >
                        Guardar
                      </LoadingButton>

                      {datosEnviadosCorrectamente && <AcceptServiceresponse />}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormGestionAsignacion;
