import React from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Search from "@mui/icons-material/Search";

const ActionButton = ({ text, title, icon, disabled, ...otherProps }) => {
  const button = (
    <Button
      variant="contained"
      size="medium"
      //color="primary"
      //startIcon={icon ? icon : <Search />}
      disabled={disabled}
      style={{ margin: "15px" }}
      {...otherProps}
    >
      {text}
    </Button>
  );

  return disabled ? (
    button
  ) : (
    <Tooltip title={title} placement={"bottom"}>
      {button}
    </Tooltip>
  );
};

export default ActionButton;
