import React from 'react'
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import InformacionColaborador from './InformacionColaborador/InformacionColaborador';


const ColaboradorInformacion = () => {
  const { setActiveLayout } = useJumboApp()
  
  React.useEffect( () => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  },[]);
    return (
    <div>
        <h2>Colaborador - Información</h2>
        <InformacionColaborador />
    </div>
  )
}

export default ColaboradorInformacion;