import React, { useEffect, useState } from "react";

import BuscadorPorIdentificador from "app/modules/app-cliente/containers/BuscadorPorIdentificador";
import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";

import { consultaCliente } from "../../../app-cliente/services/clienteService";
import DetalleCancelacionSeguro from "../../containers/DetalleCancelacionSeguro";

import ListaSegurosCliente from "../../containers/ListaSegurosCliente";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import ListaBienesAsegurados from "../../containers/ListaBienesAsegurados";
import DetalleActualizacionImei from "../../containers/DetalleActualizacionImei";

const ActualizarSeguro = () => {
  const [cliente, setCliente] = useState(null);
  const [idCliente, setIdCliente] = useState("");
  const [mostrarDetalle, setMostrarDetalle] = useState(true);
  const [idContratoAux, setIdContratoAux] = useState(null);

  /*  const [idQuejaStorage, setIdQuejaStorage] = useQuejasLocalStorage(
    "idQuejaStorage",
    null
  ); */

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  /*  useEffect(() => {
    console.log(idQuejaStorage);
    if (idQuejaStorage) {
      setIdQuejaAux(idQuejaStorage);
      setMostrarDetalle(false);
    }
  }, [idQuejaAux, setIdQuejaAux]); */

  const buscarExistenciaCliente = async (identificador) => {
    try {
      const respuestaCliente = await consultaCliente(identificador);

      console.log(respuestaCliente);
      if (respuestaCliente.state) {
        setIdCliente(respuestaCliente.data.idCliente);

        setCliente(respuestaCliente.data);
        setMostrarDetalle(false);
      } else {
        setCliente(nuevoCliente);
        setIdCliente(nuevoIdCliente);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdCliente = (id) => () => {
    console.log(id);
    buscarExistenciaCliente(id);
  };

  if (idCliente < 0) {
    return (
      <>
        <BotonGerericoSalir />
        <h2>No se ha encontrado el cliente !!</h2>
      </>
    );
  }

  if (mostrarDetalle) {
    return <BuscadorPorIdentificador traerIdCliente={traerIdCliente} />;
  }

  return (
    <div>
      <BotonGerericoSalir />

      <HeaderInformacionCliente idCliente={idCliente} />

      {idContratoAux ? (
        <DetalleActualizacionImei
          idCliente={idCliente}
          idContrato={idContratoAux}
        />
      ) : (
        <ListaBienesAsegurados
          cliente={cliente}
          idCliente={idCliente}
          setIdContratoAux={setIdContratoAux}
        />
      )}
    </div>
  );
};

export default ActualizarSeguro;
