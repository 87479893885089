import React, { useContext, useEffect, useState } from 'react';
import JumboLayoutProvider from "@jumbo/components/JumboLayout/JumboLayoutProvider";
import JumboContentLayoutProvider from "@jumbo/components/JumboContentLayout/JumboContentLayoutProvider";
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUTS} from "./utils/constants/layouts";
import {config} from "./config/main";
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import {  updateLoadUser } from '../app/redux/actions/Auth';
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';

const AppLayout = (props) => {
    const {activeLayout} = useJumboApp();
    const [isTemplateLoaded, setTemplateLoading] = useState(false);
    const { loadUser } = useSelector(({ auth }) => auth);
    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
        dispatch(updateLoadUser(true));
        setTemplateLoading(true);
      }, []);

    if (!activeLayout) {
        throw Error("AppLayout > No activeLayout is set.");
    }

    const LayoutComponent = React.useMemo(() => {
        const layoutIndex = LAYOUTS.findIndex(layout => layout.name === activeLayout);

        if (layoutIndex >=0 ) {
            return LAYOUTS[layoutIndex].component;
        }

        throw Error("No activeLayout is set yet.");
    }, [activeLayout]);

    
  if (!isTemplateLoaded || !loadUser) {
    return (
    //   <Box className={classes.circularProgressRoot}>
    <Box >
        <CircularProgress />
      </Box>
    );
  }

  if (location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/password') {
    return (
      <Box display="flex" width={1} style={{ height: '100vh' }}>
        {props.children}
      </Box>
    );
  }

  if (['/signin', '/signup', '/forgot-password', 'password'].includes(location.pathname)) {
    return <div style={{ minHeight: '100vh', width: '100%', display: 'flex' }}>{props.children}</div>;
  }

    return (
        <JumboLayoutProvider>
            <LayoutComponent>
                <JumboContentLayoutProvider
                    layoutOptions={config.defaultContentLayout}
                >
                    {props.children}
                </JumboContentLayoutProvider>
            </LayoutComponent>
        </JumboLayoutProvider>
    );
};

export default AppLayout;
