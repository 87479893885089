import http from "../../../comunication/http-common";

//http://10.10.0.79:5000/catalogo/ObtenerGrupo?dato=rommel.pumisacho&tipo=2

export const consultarGrupoGenerico = async (usuario, tipo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ObtenerGrupo?dato=${usuario}&tipo=${tipo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
