import React from "react";

import { obtenerHoraDeFecha } from "app/modules/shared/validations/validaciones";

const MensajeSideController = ({ message, sender, dateTimeMessage }) => {
  const isUser = sender === true;

  const chatMessageStyles = {
    with: "100%",
    display: "flex",
    justifyContent: isUser ? "flex-end" : "flex-start",
    alignItems: "end",
    marginBottom: "9px",
    gap: "7px",
  };

  const messageStyles = {
    maxWidth: "100%",
    padding: "8px",
    borderRadius: "8px",
    backgroundColor: isUser ? "#2196F3" : "#e0e0e0",
    color: isUser ? "white" : "black",
    fontSize: "11px",
  };

  const timeStyles = {
    justifyContent: isUser ? "flex-end" : "flex-start",
    color: "#8696a0",
    fontSize: "10px",
  };

  if (isUser) {
    messageStyles.borderTopLeftRadius = "0";
  } else {
    messageStyles.borderTopRightRadius = "0";
  }

  return (
    <div style={chatMessageStyles}>
      {sender && (
        <div style={timeStyles}>{obtenerHoraDeFecha(dateTimeMessage)}</div>
      )}
      <div style={messageStyles}>{message}</div>
      {!sender && (
        <div style={timeStyles}>{obtenerHoraDeFecha(dateTimeMessage)}</div>
      )}
    </div>
  );
};

export default MensajeSideController;
