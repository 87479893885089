import React from "react";

import FormReferenciasCliente from "../components/FormReferenciasCliente";

const DetalleRefereciasPersonales = ({ idCliente, setInfoClienteGrabada }) => {
  return (
    <div className="accordion-container">
      <FormReferenciasCliente
        idCliente={idCliente}
        setInfoClienteGrabada={setInfoClienteGrabada}
      />
    </div>
  );
};

export default DetalleRefereciasPersonales;
