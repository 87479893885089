import React from 'react';
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUT_NAMES} from "../../../layouts/layouts";
import TrackingSolicitudContent from './TrackingSolicitud/TrackingSolicitudContent';


const TrackingSolicitudPage = () => {
  const {setActiveLayout} = useJumboApp();

  React.useEffect(() => {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
      <div>
        <h2>Tracking de Solicitud</h2>
        <TrackingSolicitudContent></TrackingSolicitudContent>
      </div>
  );
};

export default TrackingSolicitudPage;