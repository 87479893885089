import React, { useEffect, useState } from "react";

import { Card, CardActions, CardContent, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { crearCertificadoNotificacion } from "../services/solicitudesService";
import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";
import handleDownlPdf from "app/utils/document/documentosService";
import ModalAlertaNotificacionEstandar from "./ModalAlertaNotificacionEstandar";
import { formatoFecha } from "app/modules/shared/validations/validaciones";
import { padding } from "@mui/system";

const NotificacionPrecancelacion = ({ informacionSolicitud, solicitud }) => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = useState(false);

  const configuracionEnvioDatos = async () => {
    /* console.log({ valores }); */
    /*  console.log({ solicitud });
    const informacionPrecancelacion = {
      idRequerimiento: informacionSolicitud.idRequerimiento,
      datosRequerimiento: {
        idRequerimiento: informacionSolicitud.idRequerimiento,
        idDatosRequerimiento:
          informacionSolicitud.datosRequerimientos[0].idDatosRequerimiento,

        referencia: informacionSolicitud.datosRequerimientos[0].referencia,
        fecha: informacionSolicitud.datosRequerimientos[0].fecha,

        factura: informacionSolicitud.datosRequerimientos[0].factura,
        operacion: informacionSolicitud.datosRequerimientos[0].operacion,
        plazo: informacionSolicitud.datosRequerimientos[0].plazo,
        detalle: informacionSolicitud.datosRequerimientos[0].detalle,

        esActivo: true,
      },
    };

    console.log(informacionPrecancelacion); */
    setLoading(true);
    try {
      const respuestaPrecancelacion = await crearCertificadoNotificacion(
        "",
        solicitud.idRequerimiento
      );

      if (respuestaPrecancelacion.state) {
        handleDownlPdf(
          respuestaPrecancelacion.data,
          `CRT_PRECNC_${
            solicitud.idRequerimiento
          }_${fechasDocumentosSiniestro()}`
        );
        setOpen(true);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } else {
        alert(`${respuestaPrecancelacion.message}`);
      }
    } catch (error) {
      alert(`${error.response.data.message}`);
      console.log(error.response);
      console.log(error.response.data.message);
    }
  };

  return (
    <>
      <Card sx={{ width: "80%", paddingBottom: "0" }}>
        <CardContent className="card_cancelacion_container">
          <Typography
            gutterBottom
            variant="h3"
            component="div"
            style={{
              backgroundColor: "#000000",
              color: "#ffffff",
              padding: "0.3rem",
            }}
          >
            Informacion Precancelación
          </Typography>

          <Typography
            variant="h4"
            component="div"
            color="text.secondary"
            style={{
              padding: "0.5rem",
            }}
          >
            {/*  Información de la precancelación:
            <br /> */}

            {`Tipo:   ${solicitud.requerimiento}`}
            <br />
            {`Nombre:   ${solicitud.nombre}`}
            <br />
            {`Email:   ${solicitud.email}`}
            <br />
            {`Celular:   ${solicitud.celular}`}
            <br />

            <br />
            {`Fecha de Credito:  ${formatoFecha(
              informacionSolicitud.datosRequerimientos[0].fecha
            )}`}
            <br />
            {`Valor Compra: $ ${informacionSolicitud.datosRequerimientos[0].factura}`}
            <br />
            {`Valor Pagado:  $ ${informacionSolicitud.datosRequerimientos[0].valor}`}
          </Typography>
        </CardContent>
        <CardActions>
          <LoadingButton
            sx={{ width: "50%" }}
            type="buton"
            size="small"
            loading={loading}
            loadingPosition="end"
            variant="contained"
            onClick={() => configuracionEnvioDatos()}
          >
            Notificar
          </LoadingButton>
        </CardActions>
      </Card>

      <ModalAlertaNotificacionEstandar
        open={open}
        setOpen={setOpen}
        titulo="Notificación Precancelación"
        mensaje="Solicitud enviada con exito!!"
      />
    </>
  );
};

export default NotificacionPrecancelacion;
