import React, { useEffect, useState } from "react";

import BuscadorPorIdentificador from "../../containers/BuscadorPorIdentificador";
import DetalleInformacionColaborador from "../../containers/DetalleInformacionColaborador";

import {
  ObtenerColaborador
} from "../../services/configuracionServices";

const InformacionColaborador = () => {
  const [colaborador, setColaborador] = useState(null);
  const [idColaborador, setIdColaborador] = useState(null);
  const [mostrarDetalle, setMostrarDetalle] = useState(false);
  const [identificacion, setIdentificacion] = useState(0);

  const nuevoColaborador = {};
  const nuevoIdColaborador = -1;

  useEffect(() => {
    console.log(colaborador);
  }, []);

  const buscarExistenciaColaborador = async (identificador) => {
    console.log(identificador);
    
    try {
      const respuestaColaborador = await ObtenerColaborador(identificador);
      console.log(respuestaColaborador);

      if (respuestaColaborador.data != null) {
        console.log(respuestaColaborador);
        setIdColaborador(respuestaColaborador.data.idColaborador);
        setColaborador(respuestaColaborador.data);
        setMostrarDetalle(false);
      } else {
        setColaborador({ identificacion: identificador });
        setIdColaborador(nuevoIdColaborador);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdColaborador = (identificacion) => () => {
    console.log(identificacion);
    setIdentificacion(identificacion);
    buscarExistenciaColaborador(identificacion);
  };

  return (
    <div>
      {idColaborador && colaborador ? (
        <DetalleInformacionColaborador
          idColaborador={idColaborador}
          colaborador={colaborador}
          identificacion={identificacion}
        />
      ) : (
        <BuscadorPorIdentificador traerIdColaborador={traerIdColaborador} />
      )}
    </div>
  );
};

export default InformacionColaborador;
