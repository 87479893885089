import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  Alert,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import { MaskCelulares } from "app/modules/shared/mask/mascarasInputs";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SendIcon from "@mui/icons-material/Send";

import { validarCelular } from "../../shared/validations/validaciones";
import {
  fechasDocumentosSiniestro,
  obtenerFechaActualFormulario,
} from "app/modules/shared/commonValues/obtenerFecha";
import {
  consultarDocumentosSoporte,
  crearCertificadoNotificacion,
  crearRequerimientoSolicitud,
} from "../services/solicitudesService";

import handleDownlPdf from "app/utils/document/documentosService";
import ModalAlertaNotificacionEstandar from "./ModalAlertaNotificacionEstandar";

const FormCertificadoCredito = ({ idCliente, idLocal }) => {
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingNotificacion, setLoadingNotificacion] = useState(false);
  const [openSinDeuda, setOpenSinDeuda] = useState(false);
  const [isEstadodeuda, setIsEstadodeuda] = useState({
    idRequerimiento: 0,
    stateRequerimiento: false,
  });

  const [validarSinDeuda, setValidarSinDeuda] = useState(false);

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsSubTipo = ({
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [subtipos, setSubtipos] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const respuestaEspecialidad = await consultarDocumentosSoporte(
            1,
            false
          );
          /*  console.log(respuestaEspecialidad); */

          if (respuestaEspecialidad.state) {
            setSubtipos(respuestaEspecialidad.data);
            /* console.log(eespuestaEspecialdidad); */
          } else {
            setSubtipos([]);
          }
        } catch (error) {
          setSubtipos([]);
          console.log(error);
        }
      };
      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {subtipos.map((subtipo) => (
            <MenuItem
              key={subtipo.idTipoRequerimiento}
              value={subtipo.idTipoRequerimiento}
            >
              {subtipo.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const configuraionEnvioDatos = async (valores) => {
    /* console.log(values); */

    setLoading(true);

    const informacionRequerimientoCredito = {
      idRequerimiento: 0,
      idCliente: Number(idCliente),

      celular: valores.celular,
      email: valores.email,

      idTipoRequerimiento: 1,
      idSubTipoRequerimento: Number(valores.idSubTipoRequerimento),

      estado: "ESTREQSOL",
      idLocal: idLocal,
      fechaSolicitud: obtenerFechaActualFormulario(),
      fechaResuelto: "",
      esActivo: true,
    };

    /*   console.log({ informacionRequerimientoCredito });
     */
    try {
      const respuestaSolicitud = await crearRequerimientoSolicitud(
        informacionRequerimientoCredito
      );

      /* console.log(respuestaSolicitud); */

      if (respuestaSolicitud.state) {
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);

          if (informacionRequerimientoCredito.idSubTipoRequerimento === 6) {
            setIsEstadodeuda((state) => ({
              ...state,
              idRequerimiento: respuestaSolicitud.data,
              stateRequerimiento: true,
            }));
          }
        }, 200);
      } else {
        setLoading(false);
        alert(`${respuestaSolicitud.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      alert(`${error.response.data.message}`);
    }
  };

  const procesarCertificadoSinDeuda = async () => {
    setLoadingNotificacion(true);

    try {
      const respuestaCertificadoSinDeuda = await crearCertificadoNotificacion(
        "",
        isEstadodeuda.idRequerimiento
      );

      if (respuestaCertificadoSinDeuda.state) {
        handleDownlPdf(
          respuestaCertificadoSinDeuda.data,
          `CRT_SINDEUDA_${
            isEstadodeuda.idRequerimiento
          }_${fechasDocumentosSiniestro()}`
        );

        setOpenSinDeuda(true);
        setLoadingNotificacion(false);
      } else {
        setLoadingNotificacion(false);
        alert(`${respuestaCertificadoSinDeuda.message}`);
      }
    } catch (error) {
      setLoadingNotificacion(false);
      /* console.log(error); */
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <>
      <div className="accordion-container">
        <Formik
          initialValues={{
            celular: "",
            email: "",
            idSubTipoRequerimento: "",
          }}
          validate={(values) => {
            const errors = {};

            if (validarCelular(values.celular)) {
              errors.celular = "Primer digito debería ser '09'";
            }

            return errors;
          }}
          validationSchema={Yup.object({
            celular: Yup.string()
              .max(10, "Debería tener 10 números")
              .min(10, "Debería tener 10 números")
              .required("Celular requerido"),
            email: Yup.string()
              .email("Email no tiene formato")
              .required("Email requerido"),
            idSubTipoRequerimento: Yup.string().required(
              "Tipo Requerimiento requerido"
            ),
          })}
          onSubmit={(valores, { resetForm }) => {
            configuraionEnvioDatos(valores);

            if (Number(valores.idSubTipoRequerimento) !== 6) {
              setTimeout(() => {
                resetForm({ values: "" });
              }, 1000);
            } else {
              setValidarSinDeuda(true);
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form>
              <div className="accordion-container">
                <div>
                  <Accordion
                    defaultExpanded="true"
                    sx={{ boxShadow: 3, lineHeight: 0 }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      className="accordion-summary"
                      sx={{ margin: 0 }}
                    >
                      <h2 className="formulario-cabecera">
                        Certificado de Credito
                      </h2>
                    </AccordionSummary>
                    <AccordionDetails sx={{ marginTop: "2rem" }}>
                      <Grid container spacing={1} rowSpacing={1}>
                        <Grid item md={12}>
                          <Grid container spacing={1}>
                            <Grid item md={12}>
                              <div className="cliente-informacion-seccion">
                                <FormControl
                                  sx={{ width: "15%" }}
                                  size="small"
                                  variant="outlined"
                                  error={
                                    touched.celular && Boolean(errors.celular)
                                  }
                                  helperText={touched.celular && errors.celular}
                                  onBlur={handleBlur}
                                >
                                  <InputLabel htmlFor="celular">
                                    Celular
                                  </InputLabel>
                                  <OutlinedInput
                                    value={values.celular}
                                    onChange={handleChange}
                                    name="celular"
                                    id="celular"
                                    inputComponent={MaskCelulares}
                                    label="celular"
                                  />
                                  <ErrorMessage
                                    name="celular"
                                    component={() => (
                                      <FormHelperText>
                                        {errors.celular}
                                      </FormHelperText>
                                    )}
                                  />
                                </FormControl>

                                <TextField
                                  id="email"
                                  name="email"
                                  type="text"
                                  label="Email"
                                  sx={{ width: "33%" }}
                                  value={values.email}
                                  onChange={handleChange}
                                  size="small"
                                  error={touched.email && Boolean(errors.email)}
                                  helperText={touched.email && errors.email}
                                  onBlur={handleBlur}
                                />

                                <SelectItemsSubTipo
                                  name="idSubTipoRequerimento"
                                  labelName="Tipo de Certificado"
                                  componentSize="32%"
                                  handleBlur={handleBlur}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>

                              {datosEnviadosCorrectamente && (
                                <Alert severity="success" variant="filled">
                                  Los datos han sido enviados correctamente.
                                </Alert>
                              )}

                              <div className="cliente-informacion-seccion">
                                <LoadingButton
                                  disabled={validarSinDeuda}
                                  sx={{ width: "25ch" }}
                                  type="submit"
                                  size="small"
                                  endIcon={<SendIcon />}
                                  loading={loading}
                                  loadingPosition="end"
                                  variant="contained"
                                >
                                  Enviar
                                </LoadingButton>

                                {isEstadodeuda.stateRequerimiento && (
                                  <LoadingButton
                                    sx={{ width: "25ch" }}
                                    type="button"
                                    size="small"
                                    loading={loadingNotificacion}
                                    loadingPosition="end"
                                    variant="contained"
                                    endIcon={<NotificationsActiveIcon />}
                                    onClick={procesarCertificadoSinDeuda}
                                  >
                                    Notificar
                                  </LoadingButton>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <ModalAlertaNotificacionEstandar
        setOpen={setOpenSinDeuda}
        open={openSinDeuda}
        titulo="Solicitud Sin Deuda"
        mensaje="La solicitud ha sido generada con exito!! Revise su correo."
      />
    </>
  );
};

export default FormCertificadoCredito;
