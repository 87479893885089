import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, fade } from '@mui/material';
import ContentLoader from '../../../../@jumbo/components/ContentLoader';
import ChangePassword from '../../../modules/common/components/changePassword'
import { useOnChangePassword } from '../hook/index'
import { Card, CardContent, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { alpha } from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";

const validationSchema = yup.object({
  password: yup
      .string('Ingrese password actual')
      .max(32, 'Excede longitud máxima')
      .required('Password es requerido'),
  newpassword: yup
      .string('Ingrese nuevo password')
      .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "La contraseña debe contener al menos 8 caracteres, una mayúscula, un número y un carácter especial"
      )
      .max(32, 'Excede longitud máxima')
      .required('Nuevo password es requerido'),
  repassword: yup
      .string()
      .required("Confirme nuevo password")
      .oneOf([yup.ref('newpassword'), null], "Confirmación incorrecta")
  
});

const ChangePasswordExternal = () => {

  const dispatch = useDispatch();
  const { code } = useSelector((state) => state.auth);
  const [jsonPayload, setJsonPayload] = useState({});
  const [sendChangePassword, setSendChangePassword] = useState(false);
  const [form, setForm] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  })
  const [validateConfirmPassword, setValidateConfirmPassword] = useState(true)
  useOnChangePassword(jsonPayload, sendChangePassword, setSendChangePassword);


  // const onSignIn = async () => {
  //   console.log("values", form)
  //   const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  //   await sleep(300);
  //   const payload = {
  //     userCode: code.userCode,
  //     password: form.currentPassword,
  //     newPassword: form.newPassword,
  //   };
  //   setJsonPayload(payload);
  //   setSendChangePassword(true);
  // };

  
  const onSignIn = async   (password,newpassword) => {
   
    const payload = {
      userCode: code.userCode,
      password: password,
      newPassword: newpassword,
    };
    setJsonPayload(payload);
    setSendChangePassword(true);
  };
  
  // const onChangePasswordvalues = (e) => {
  //   setForm({
  //     ...form,
  //     [e.target.name]: e.target.value,
  //   });
  // };


  return (
    <Div sx={{
      flex: 1,
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      p: theme => theme.spacing(4),
    }}>

      <Card sx={{ maxWidth: '100%', width: 360, mb: 4 }}>
        <Div sx={{ position: 'relative', height: '200px' }}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="200"
            image={`${ASSET_IMAGES}/logo-login.jpg`}
          />
          <Div
            sx={{
              flex: 1,
              inset: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: theme => alpha(theme.palette.common.black, .5),
              p: theme => theme.spacing(3),
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                color: 'common.white',
                fontSize: '1.5rem',
                mb: 0
              }}
            >
              Cambiar Contraseña
            </Typography>
          </Div>
        </Div>
        <CardContent sx={{ pt: 0 }}>
          <Formik
                validateOnChange={true}
                initialValues={{
                    password: '',
                    newpassword: '',
                    repassword: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(data, {setSubmitting}) => {

                    setSubmitting(true);
                    onSignIn(data.password, data.newpassword);
                    setSubmitting(false);
                }}
            >
                {({isSubmitting}) => (
                    <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                        {/* <Avatar
                            alt="Usuario" 
                            sx={{
                                width: 56,
                                height: 56,
                                marginLeft: 'auto',
                                boxShadow: shadows[3],
                                transform: 'translateY(-50%)',
                            }}
                        />
                        {mutation.isError && <p>{mutation.error.message}</p>} */}
                        <Div sx={{mb: 3, mt: 1}}>
                            <JumboTextField
                                fullWidth
                                name="password"
                                label="Password actual"
                                type="password"
                            />
                        </Div>
                        <Div sx={{mb: 2, mt: 1}}>
                            <JumboTextField
                                fullWidth
                                name="newpassword"
                                label="Nuevo Password"
                                type="password"
                            />
                        </Div>
                        <Div sx={{mb: 2, mt: 1}}>
                            <JumboTextField
                                fullWidth
                                name="repassword"
                                label="Confirmación"
                                type="password"
                            />
                        </Div>
                        
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{mb: 3}}
                            loading={isSubmitting }
                        >Cambio de password</LoadingButton>
                        
                      
                    </Form>
                )}
            </Formik>
            <ContentLoader />
        </CardContent>
      </Card>

    </Div>
  );
};

export default ChangePasswordExternal;
