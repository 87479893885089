import React from "react";
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";

const BuscadorPorIdentificacion = ({ traerIdUsuario }) => {
  const [idUsuario, setidUsuario] = useState();

  const navigate = useHistory();

  const redirectHomePage = () => {
    navigate.push("/initialHome");
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9]+$/;
    if (regex.test(inputValue) || inputValue === "") {
      setidUsuario(inputValue.slice(0, 15));
    }
  };

  return (
    <>
      <div div className="buscador-movimientos-container">
        <Card
          sx={{
            marginTop: "3%",
            width: 600,
            height: 250,
          }}
        >
          <header className="buscador-movimientos-header">
            <div className="buscador-movimientos-titulo">
              <h2>Consultar información del promotor</h2>
            </div>

            <div className="buscador-movimientos-opciones">
              <div className="buscador-movimientos-opciones__inputs">
                <FormControl
                  sx={{ width: "60%" }}
                  size="small"
                  variant="outlined"
                >
                  <InputLabel htmlFor="buscador">Identificación</InputLabel>
                  <OutlinedInput
                    id="buscador"
                    value={idUsuario}
                    label="key"
                    required="false"
                    onChange={handleInputChange}
                    maxLength={10}
                  />
                </FormControl>
              </div>
              <div className="buscador-movimientos-opciones__buttons">
                <SCSGenericButton
                  type="submit"
                  size={45}
                  titulo="Buscar"
                  onClick={traerIdUsuario(idUsuario)}
                />

                <SCSGenericButton
                  type="button"
                  size={45}
                  titulo="Cancelar"
                  onClick={redirectHomePage}
                />
              </div>
            </div>
          </header>
        </Card>
      </div>
    </>
  );
};

export default BuscadorPorIdentificacion;
