import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
import { auth } from "@jumbo/services/auth/firebase/firebase";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_AVATARS, ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { Redirect } from "react-router";
import { useDispatch } from "react-redux";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../../../redux/actions/Common";
//import { useOnLoginRegistro } from '../hook/index';

import {
  onActualizarOtp,
  onValidarOtp,
  onConsultarCI,
  onEnviarOtpSms
} from "../services/index";
import { useOnLoginRegistro } from "../hook";
import HouseIcon from "@mui/icons-material/House";
import { consultarGrupoGenerico } from "app/modules/app-configuracion/services/configuracionServices";

const validationSchema = yup.object({
  email: yup
    .string("Ingrese Clave Temporal")
    // .email('Enter a valid email')
    .required("Clave es obligatorio"),
});

const OtpRegistro = () => {
  const dispatch = useDispatch();
  const [intentos, setintentos]= useState(0);
  const tipocliente = localStorage.getItem("tipocliente");
  //const origen = localStorage.getItem("origen");
  const [origen, setOrigen] = useState(localStorage.getItem("origen"));

  const history = useHistory();
  const { setActiveLayout } = useJumboApp();
  const [color, setColor] = useState("");
  const [url, setUrl] = useState("");

  const [send, setSend] = useState();
  useOnLoginRegistro(send, setSend);

  React.useEffect(() => {
    debugger;
    if (localStorage.getItem("tipocliente") === "EXISTE");
    {
      setSend(true);
    }
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    switch (origen) {
      case "tvtas":
        setColor("#FF9201");
        setUrl("https://www.tventas.com/content/55-creditventas");
        break;
      case "corpfav":
        setColor("#e1251b");
        setUrl("https://www.creditofavorito.com/");
        break;
      default:
        setColor("#e1251b");
        setUrl("https://www.creditofavorito.com/");
        break;
    }
    const obtenerGrupo = async () => {
      const resp = await consultarGrupoGenerico(origen, 1);
      if (resp.state) {
        localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
      } else {
        console.log(resp.message);
      }
    };
    obtenerGrupo();
  }, []);

  // const obtenerGrupo = async () => {
  //   const resp = await consultarGrupoGenerico(origen, 1);
  //   if (resp.state) {
  //     localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
  //   } else {
  //     console.log(resp.message);
  //   }
  // };

  const mutation = useAuthSignInWithEmailAndPassword(auth, {
    onError(error) {
      console.log(error);
    },
    onSuccess(data) {
      //history.push("/");
    },
  });

  const onSignIn = async (email) => {
    const cedula = localStorage.getItem("cedula");
    debugger;
    let response = await onValidarOtp(cedula, email);
    if (response.state) {
      //history.push("/auth-pages/datos");
      debugger;
      if (tipocliente === "EXISTE") {
        //setSend(true);
        const path = "/credito/flujop";
        history.push(path, { state: { identificacion: cedula } });
      } else {
        //debugger;
        history.push("/auth-pages/datos");
      }
    } else {
      dispatch(fetchError(response.message));
    }
  };

  const onCallForm = async () => {
    debugger;
    setintentos(intentos +1);
    if (intentos>2)
    {
      dispatch(fetchError("Ha superado el número de reenvios"));
      //alert("Información", "Ha superado el número de reenvios");
      return;
    }

    const cedula = localStorage.getItem("cedula");
    const email = localStorage.getItem("email");
    const celular = localStorage.getItem("celular");
    
    let response = await onActualizarOtp(email, cedula);
    if (response.state) 
    {
      let nombres =
      localStorage.getItem("ap") + " " + localStorage.getItem("nm");
      onEnviarOtpSms(nombres, response.data, celular, cedula);
      dispatch(fetchError("Se ha enviado clave de acceso!!"));
      
    }
  };
  return (
    <Div
      sx={{
        flex: 1,
        marginTop: 20,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(4),
      }}
    >
      <Box
        component="img"
        src={`${process.env.REACT_APP_IMAGES_PATH}/logo-${origen}.png`}
        sx={{
          justifyContent: "center",
          width: 350,
          height: 140,
        }}
      />
      <Card sx={{ maxWidth: "100%", width: 360, mb: 4 }}>
        <Div sx={{ position: "relative", height: "200px" }}>
          <CardMedia
            component="img"
            height="200"
            sx={{ backgroundColor: color }}
          />
          <Div
            sx={{
              flex: 1,
              inset: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              p: (theme) => theme.spacing(3),
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                color: "common.white",
                fontSize: "1.5rem",
                mb: 0,
              }}
            >
              Clave de validación
            </Typography>
          </Div>
        </Div>
        <CardContent sx={{ pt: 0 }}>
          <Formik
            validateOnChange={true}
            initialValues={{
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              onSignIn(data.email);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                {/* <Avatar
                  sx={{
                    width: 56,
                    height: 56,
                    marginLeft: "auto",
                    boxShadow: shadows[3],
                    transform: "translateY(-50%)",
                  }}
                /> */}
                <Typography
                  textAlign={"left"}
                  variant={"h3"}
                  mb={1}
                  marginTop={5}
                >
                  Ingresa la clave enviada a tu celular y correo electrónico.
                </Typography>
                {mutation.isError && <p>{mutation.error.message}</p>}
                <Div sx={{ mb: 3, mt: 1, marginTop: 8 }}>
                  <JumboTextField
                    fullWidth
                    name="email"
                    label="Clave única temporal"
                  />
                </Div>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting || mutation.isLoading}
                >
                  Continuar
                </LoadingButton>
                <Typography textAlign={"center"} variant={"body1"} mb={1}>
                  ¿No te llegó el email?
                  <Link
                    underline="none"
                    href="#"
                    onClick={() => {
                      onCallForm();
                    }}
                  >
                    {" "}
                    Reenviar clave
                  </Link>
                </Typography>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
      <Button
        size="lg"
        variant="outlined"
        startIcon={<HouseIcon />}
        onClick={() => {
          window.open(url, "_self");
        }}
      >
        <Typography> Regresar a la pagina Principal</Typography>
      </Button>
    </Div>
  );
};

export default OtpRegistro;
