import React from "react";

import HistorialSeguimiento from "./HistorialSeguimiento";

import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";

const FormSiniestroHistorialSeguimiento = ({ idSiniestro }) => {
  return (
    <SCSAccordion tituloCabecera="Historial Seguimiento">
      <HistorialSeguimiento idSiniestro={idSiniestro} />
    </SCSAccordion>
  );
};

export default FormSiniestroHistorialSeguimiento;
