import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  FormControl,
  MenuItem,
  TextField,
  OutlinedInput,
  FormHelperText,
  InputLabel,
  Select,
} from "@mui/material";

import {
  MaskCelulares,
  MaskTelefonos2,
} from "../../shared/mask/mascarasInputs";

import { useGetConyugue } from "../hook/useGetConyugue";

import {
  validarCelular,
  validarFalsaData,
} from "../../shared/validations/validaciones";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";

import { crearClienteConyugue } from "../services/clienteService";
import { consultaCatalogosSinPadre } from "../services/Catalogos/catalogosService";
import { StyleInputs } from "../styles/themes";
import SkeletonForm from "app/modules/common/components/Skeleton/SkeletonForm";
import { ERROR_VALIDACION_CONTACTO } from "app/modules/shared/constants/constantsMessanges";

const FormInformacionConyugue = ({ idCliente }) => {
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const { isLoading, conyugue, idConyugueAux, setIdConyugueAux } =
    useGetConyugue({
      idCliente,
    });

  const [controlExpand, setControlExpand] = useState({
    controlExpanded: true,
    controlSave: false,
  });

  if (isLoading) {
    return <SkeletonForm />;
  }

  if (idCliente <= 0) {
    return <h2>Cliente NO existe. Primero cree el Cliente.!!</h2>;
  }

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        //sx={{ width: `${componentSize}` }}
        sx={StyleInputs(componentSize.pcWidth, componentSize.phoneWidth)}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const informacionConyugue = {
      idCliente: idCliente,
      idConyuge: idConyugueAux,
      tipoIdentificacion: valores.conyuguetipoIdentificacion,
      identificacion: valores.conyugueidentificacion,
      primerNombre: valores.conyugueprimerNombre.toUpperCase(),
      segundoNombre: valores.conyuguesegundoNombre.toUpperCase(),
      primerApellido: valores.conyugueprimerApellido.toUpperCase(),
      segundoApellido: valores.conyuguesegundoApellido.toUpperCase(),
      celular: valores.conyuguecelular,
      telefono: valores.conyuguetelefono,
    };

    /* console.log("Tabla Informacion Conyugue");
    console.log(informacionConyugue); */

    try {
      const respuestaClienteConyugue = await crearClienteConyugue({
        ...informacionConyugue,
        idCliente: idCliente,
      });

      /* console.log(respuestaClienteConyugue); */

      if (respuestaClienteConyugue.state) {
        setDatosEnviadosCorrectamente(true);
        setIdConyugueAux(respuestaClienteConyugue.data);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);

          handleExpand();
          setControlExpand((args) => ({
            ...args,
            controlSave: true,
          }));
        }, 1000);
      } else {
        setLoading(false);
        alert(`${respuestaClienteConyugue.data.message}`);
      }
    } catch (error) {
      setLoading(false);
      alert(`${error.response.data.message}`);
      console.log(error);
    }
  };

  const handleExpand = () => {
    setControlExpand((args) => ({
      ...args,
      controlExpanded: !args.controlExpanded,
    }));
  };

  return (
    <Formik
      initialValues={{
        conyuguetipoIdentificacion: conyugue.tipoIdentificacion || "",
        conyugueidentificacion: conyugue.identificacion || "",
        conyugueprimerApellido: conyugue.primerApellido || "",
        conyuguesegundoApellido: conyugue.segundoApellido || "",
        conyugueprimerNombre: conyugue.primerNombre || "",
        conyuguesegundoNombre: conyugue.segundoNombre || "",
        conyuguetelefono: conyugue.telefono || "",
        conyuguecelular: conyugue.celular || "",
      }}
      validate={async (values) => {
        const errors = {};

        if (!/^[0-9]{0,11}$/.test(values.conyuguecelular)) {
          errors.conyuguecelular = "Solo se aceptan números";
        }

        if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.conyugueprimerApellido)) {
          errors.conyugueprimerApellido =
            "El nombre solo puede contener caracteres sin espacios";
        }
        if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.conyuguesegundoApellido)) {
          errors.conyuguesegundoApellido =
            "El nombre solo puede contener caracteres sin espacios";
        }
        if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.conyugueprimerNombre)) {
          errors.conyugueprimerNombre =
            "El nombre solo puede contener caracteres sin espacios";
        }
        if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.conyuguesegundoNombre)) {
          errors.conyuguesegundoNombre =
            "El nombre solo puede contener caracteres sin espacios";
        }

        if (await validarFalsaData(values.conyuguecelular, "CEL")) {
          errors.conyuguecelular = ERROR_VALIDACION_CONTACTO;
        }

        if (await validarFalsaData(values.conyuguetelefono, "CON")) {
          errors.conyuguetelefono = ERROR_VALIDACION_CONTACTO;
        }

        if (validarCelular(values.conyuguecelular)) {
          errors.conyuguecelular = "Primer digito debería ser '09'";
        }
        return errors;
      }}
      validationSchema={Yup.object({
        conyuguetipoIdentificacion: Yup.string().required(
          "Tipo Identificación requerido"
        ),
        conyugueidentificacion: Yup.string().required(
          "Identificación requerida"
        ),
        conyugueprimerNombre: Yup.string().required("Nombre requerido"),
        conyugueprimerApellido: Yup.string().required("Apellido requerida"),
        conyuguecelular: Yup.string()
          .min(10, "Debería tener 10 números")
          .max(10, "Debería tener 10 números")
          .required("Celular requerido"),

        conyuguetelefono: Yup.string()
          .min(9, "Debería tener 9 números")
          .max(9, "Debería tener 9 números"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        configuracionDatosEnvio(values);
      }}
    >
      {({ values, handleChange, touched, errors, handleBlur }) => (
        <Form>
          <SCSAccordion
            tituloCabecera="Información Conyugue"
            controlExpand={controlExpand}
            handleExpand={handleExpand}
          >
            <div>
              <h3>Perfil</h3>
            </div>

            <div className="cliente-informacion-seccion">
              <SelectItemsCatalogoSinPadre
                pais="ECU"
                especial="false"
                catalogos="TIPOIDEN"
                grupo={1}
                name="conyuguetipoIdentificacion"
                labelName="Tipo ID"
                //componentSize="16%"
                componentSize={{ pcWidth: 16, phoneWidth: 45 }}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              />

              <TextField
                id="conyugueidentificacion"
                name="conyugueidentificacion"
                type="text"
                label="Identificación"
                value={values.conyugueidentificacion}
                onChange={handleChange}
                sx={StyleInputs(14, 45)}
                //sx={{ width: "14%" }}
                size="small"
                error={
                  touched.conyugueidentificacion &&
                  Boolean(errors.conyugueidentificacion)
                }
                helperText={
                  touched.conyugueidentificacion &&
                  errors.conyugueidentificacion
                }
                onBlur={handleBlur}
              />

              <TextField
                id="conyugueprimerApellido"
                name="conyugueprimerApellido"
                type="text"
                label="Primer Apellido"
                sx={StyleInputs(14, 45)}
                //sx={{ width: "14%" }}
                value={values.conyugueprimerApellido}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={
                  touched.conyugueprimerApellido &&
                  Boolean(errors.conyugueprimerApellido)
                }
                helperText={
                  touched.conyugueprimerApellido &&
                  errors.conyugueprimerApellido
                }
                onBlur={handleBlur}
              />

              <TextField
                id="conyuguesegundoApellido"
                name="conyuguesegundoApellido"
                type="text"
                label="Segundo Apellido"
                sx={StyleInputs(14, 45)}
                //sx={{ width: "14%" }}
                value={values.conyuguesegundoApellido}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={
                  touched.conyuguesegundoApellido &&
                  Boolean(errors.conyuguesegundoApellido)
                }
                helperText={
                  touched.conyuguesegundoApellido &&
                  errors.conyuguesegundoApellido
                }
                onBlur={handleBlur}
              />

              <TextField
                id="conyugueprimerNombre"
                name="conyugueprimerNombre"
                type="text"
                label="Primer Nombre"
                sx={StyleInputs(14, 45)}
                //sx={{ width: "14%" }}
                value={values.conyugueprimerNombre}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={
                  touched.conyugueprimerNombre &&
                  Boolean(errors.conyugueprimerNombre)
                }
                helperText={
                  touched.conyugueprimerNombre && errors.conyugueprimerNombre
                }
                onBlur={handleBlur}
              />
              <TextField
                id="conyuguesegundoNombre"
                name="conyuguesegundoNombre"
                type="text"
                label="Segundo Nombre"
                sx={StyleInputs(14, 45)}
                //sx={{ width: "14%" }}
                value={values.conyuguesegundoNombre}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={
                  touched.conyuguesegundoNombre &&
                  Boolean(errors.conyuguesegundoNombre)
                }
                helperText={
                  touched.conyuguesegundoNombre && errors.conyuguesegundoNombre
                }
                onBlur={handleBlur}
              />
            </div>

            <div>
              <h3>Contacto</h3>
            </div>

            <div className="cliente-informacion-seccion">
              <FormControl
                //sx={{ width: "15%" }}
                sx={StyleInputs(15, 45)}
                size="small"
                variant="outlined"
                error={
                  touched.conyuguecelular && Boolean(errors.conyuguecelular)
                }
                helperText={touched.conyuguecelular && errors.conyuguecelular}
                onBlur={handleBlur}
              >
                <InputLabel htmlFor="conyuguecelular">Celular</InputLabel>
                <OutlinedInput
                  value={values.conyuguecelular}
                  onChange={handleChange}
                  name="conyuguecelular"
                  id="conyuguecelular"
                  inputComponent={MaskCelulares}
                  label="conyuguecelular"
                />
                <ErrorMessage
                  name="conyuguecelular"
                  component={() => (
                    <FormHelperText>{errors.conyuguecelular}</FormHelperText>
                  )}
                />
              </FormControl>

              <FormControl
                //sx={{ width: "20%" }}
                sx={StyleInputs(20, 45)}
                size="small"
                variant="outlined"
                error={
                  touched.conyuguetelefono && Boolean(errors.conyuguetelefono)
                }
                helperText={touched.conyuguetelefono && errors.conyuguetelefono}
                onBlur={handleBlur}
              >
                <InputLabel htmlFor="conyuguetelefono">Télefono</InputLabel>
                <OutlinedInput
                  value={values.conyuguetelefono}
                  onChange={handleChange}
                  name="conyuguetelefono"
                  id="conyuguetelefono"
                  inputComponent={MaskTelefonos2}
                  label="conyuguetelefono"
                />
                <ErrorMessage
                  name="conyuguetelefono"
                  component={() => (
                    <FormHelperText>{errors.conyuguetelefono}</FormHelperText>
                  )}
                />
              </FormControl>
            </div>

            <div className="confirmar-estados-servicios">
              <SCSLoadingButton loading={loading} titulo="GUARDAR" />

              {datosEnviadosCorrectamente && <AcceptServiceresponse />}
            </div>
          </SCSAccordion>
        </Form>
      )}
    </Formik>
  );
};

export default FormInformacionConyugue;
