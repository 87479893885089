import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import SeguroVender from "./VenderSeguro/SeguroVender";

const VenderSeguro = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Venta - Seguro</h2>
      <SeguroVender />
    </div>
  );
};

export default VenderSeguro;
