import React, { useState, useEffect } from "react";

import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import DetalleMenuMobile from "./DetalleMenuMobile";
import DetalleValoresPorPagar from "./DetalleValoresPorPagar";
import DetalleMovimientosCliente from "./DetalleMovimientosCliente";
import DetalleOpcionGenerica from "./DetalleOpcionGenerica";
import DetalleEstadoCredito from "./DetalleEstadoCredito";

import DetallePagosTransaccional from "./DetallePagosTransaccional";
import DetalleDatosSeguros from "./DetalleDatosSeguros";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { consultarCabeceraTsx } from "../services/transaccionalService";
import { LISTA_OPCIONES } from "../constants/constants";
import DetalleDatosSolicitud from "./DetalleDatosSolicitud";
import { useResolutionScreen } from "../hooks/useResolutionScreen";
import DetalleSiniestros from "./DetalleSiniestros";
import DetalleQuejas from "./DetalleQuejas";

const useStylesButtonColapse = makeStyles((theme) => ({
  buttonColapse: {
    /*  position: "absolute",
    top: "-22px",
    left: "50%",
    transform: "translateX(-50%)", */
    margin: "0.5rem",
    background: "white",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      background: "white",
    },
  },
}));

const DetalleTransaccionalMobile = ({ datosBusqueda, setDatosBusqueda }) => {
  const [cabenceraTransaccional, setCabenceraTransaccional] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const styleButtonColapse = useStylesButtonColapse();

  const { alturaResolucion } = useResolutionScreen();

  useEffect(() => {
    const getCabeceraTsx = async () => {
      try {
        const respuestaCabeceraTsx = await consultarCabeceraTsx(
          datosBusqueda.identificacion,
          datosBusqueda.idGrupo
        );

        console.log(respuestaCabeceraTsx);

        if (respuestaCabeceraTsx.state) {
          setCabenceraTransaccional(respuestaCabeceraTsx.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    getCabeceraTsx();
  }, []);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const handleReturnMenu = () => {
    setDatosBusqueda((args) => ({
      ...args,
      idMovimiento: 0,
      menuMobile: true,
    }));
  };

  return (
    <>
      {!datosBusqueda.menuMobile && (
        <div>
          <IconButton
            className={styleButtonColapse.buttonColapse}
            aria-label="calapse-right"
            size="large"
            onClick={handleReturnMenu}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        </div>
      )}
      {datosBusqueda.menuMobile ? (
        <DetalleMenuMobile
          datosBusqueda={datosBusqueda}
          setDatosBusqueda={setDatosBusqueda}
        />
      ) : (
        <div className="movimientoClienteMobile-detalle-items">
          {datosBusqueda.idMovimiento === 0 && (
            <section className="movimientoCliente-informacion">
              <DetalleOpcionGenerica alturaResolucion={alturaResolucion} />
            </section>
          )}

          {datosBusqueda.idMovimiento === LISTA_OPCIONES.ESTADO_CUENTA && (
            <section className="movimientoCliente-informacion">
              <h2 style={{ display: "flex", justifyContent: "center" }}>
                <strong>Estado de Cuenta</strong>
              </h2>
              <DetalleValoresPorPagar
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}

          {datosBusqueda.idMovimiento === LISTA_OPCIONES.MOV_FECHA && (
            <section className="movimientoCliente-informacion">
              <h2 style={{ display: "flex", justifyContent: "center" }}>
                <strong>Mov. a la fecha</strong>
              </h2>
              <DetalleMovimientosCliente
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}

          {datosBusqueda.idMovimiento === LISTA_OPCIONES.ESTADO_CREDITO && (
            <section className="movimientoCliente-informacion">
              <h2 style={{ display: "flex", justifyContent: "center" }}>
                <strong>Estado Crédito</strong>
              </h2>
              <DetalleEstadoCredito
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}

          {datosBusqueda.idMovimiento === LISTA_OPCIONES.ESTADO_SOLICITUD && (
            <section className="movimientoCliente-informacion">
              {/*   <h2 style={{ display: "flex", justifyContent: "center" }}>
                <strong>Estado Solicitud</strong>
              </h2> */}
              <DetalleDatosSolicitud
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}

          {datosBusqueda.idMovimiento === LISTA_OPCIONES.PAGOS && (
            <section className="movimientoCliente-informacion">
              <h2 style={{ display: "flex", justifyContent: "center" }}>
                <strong>Pagos</strong>
              </h2>
              <DetallePagosTransaccional
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}
          {datosBusqueda.idMovimiento === LISTA_OPCIONES.DATOS_SEGURO && (
            <section className="movimientoCliente-informacion">
              <h2 style={{ display: "flex", justifyContent: "center" }}>
                <strong>Datos Seguro</strong>
              </h2>
              <DetalleDatosSeguros
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}
          {/* SINIESTROS */}
          {datosBusqueda.idMovimiento === LISTA_OPCIONES.SINIESTROS && (
            <section className="movimientoCliente-informacion">
              <h2 style={{ display: "flex", justifyContent: "center" }}>
                <strong>Siniestros</strong>
              </h2>
              <DetalleSiniestros
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}
          {/* QUEJAS */}
          {datosBusqueda.idMovimiento === LISTA_OPCIONES.QUEJAS && (
            <section className="movimientoCliente-informacion">
              <h2 style={{ display: "flex", justifyContent: "center" }}>
                <strong>Quejas</strong>
              </h2>
              <DetalleQuejas
                datosBusqueda={datosBusqueda}
                cabenceraTransaccional={cabenceraTransaccional}
                alturaResolucion={alturaResolucion}
              />
            </section>
          )}
        </div>
      )}
    </>
  );
};

export default DetalleTransaccionalMobile;
