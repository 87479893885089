import React, { useState } from "react";
import { Formik, Form } from "formik";

import * as Yup from "yup";

import { Alert, Button, Grid, Stack, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ComentariosTabla from "./ComentariosTabla";

import { crearComentario } from "../Services/siniestrosService";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

const FormComentarios = ({ idSiniestro }) => {
  const [loading, setLoading] = useState(false);

  const [isUpdating, setIsUpdating] = useState(true);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const configuraionEnvioDatos = async (valores) => {
    setLoading(false);

    const informacionComentario = {
      idSiniestro: Number(idSiniestro),
      comentario1: valores.comentario.toUpperCase(),
      esActivo: true,
    };
    /*   console.log(informacionComentario); */

    try {
      const respuestaComentario = await crearComentario(informacionComentario);

      setIsUpdating(!isUpdating);
      setDatosEnviadosCorrectamente(true);

      setTimeout(() => {
        setDatosEnviadosCorrectamente(false);
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={{
        comentario: "",
      }}
      validate={(values) => {
        const errors = {};

        return errors;
      }}
      validationSchema={Yup.object({
        comentario: Yup.string()
          .max(255, "Debería tener máximo 255 caracteres")
          .min(5, "Debería tener al menos 5 caracteres")
          .required("Comentario requerido"),
      })}
      onSubmit={(valores, { resetForm }) => {
        configuraionEnvioDatos(valores);

        setTimeout(() => {
          resetForm({ values: "" });
        }, 400);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => (
        <Form>
          <SCSAccordion tituloCabecera="Comentarios">
            <Grid container spacing={1} rowSpacing={1}>
              <Grid item md={12}>
                <h3>Comentarios</h3>
              </Grid>

              <Grid item md={12}>
                <TextField
                  id="comentario"
                  name="comentario"
                  type="text"
                  label="Comentario"
                  sx={{ width: "75%" }}
                  value={values.comentario}
                  onChange={handleChange}
                  size="small"
                  inputProps={{
                    style: { textTransform: "uppercase" },
                  }}
                  error={touched.comentario && Boolean(errors.comentario)}
                  helperText={touched.comentario && errors.comentario}
                  onBlur={handleBlur}
                />

                <div className="confirmar-estados-servicios">
                  <SCSLoadingButton loading={loading} titulo="GUARDAR" />

                  {datosEnviadosCorrectamente && <AcceptServiceresponse />}
                </div>
              </Grid>
            </Grid>

            <div className="comentario-lista-comentarios">
              <ComentariosTabla
                idSiniestro={idSiniestro}
                isUpdating={isUpdating}
              />
            </div>
          </SCSAccordion>
        </Form>
      )}
    </Formik>
  );
};

export default FormComentarios;
