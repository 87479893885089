import React, { useEffect } from "react";
import ProfileCard from "./ProfileContainers/ProfileContainer";
import PageContainer from '../../common/components/Layouts/PageContainer';
const Profile = () => {


	return (
		<PageContainer heading={'Perfil de Usuario'}>
			<ProfileCard />
		</PageContainer>
	);
};

export default Profile;
