import React from "react";

import DataGrid, {
  Column,
  Export,
  Selection,
} from "devextreme-react/data-grid";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";

import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { useGetQuejasPendientes } from "../../hooks/useGetQuejasPendientes";

const QuejasPendientes = () => {
  const { isLoading, quejasPendientes } = useGetQuejasPendientes();

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    const fechaActual = new Date();
    const detalleFecha = fechaActual.toISOString().split("T")[0];

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `QuejasPendientes_${detalleFecha}.xlsx`
        );
      });
    });
    e.cancel = true;
  };

  return (
    <>
      <BotonGerericoSalir />
      <DataGrid
        id="gridContainer"
        dataSource={quejasPendientes}
        keyExpr="ID"
        showBorders="true"
        onExporting={onExporting}
      >
        <Selection />
        {/*  <GroupPanel visible="true" />
        <Grouping autoExpandAll="true" /> */}

        <Column dataField="fechaIngreso" width={100} />
        <Column dataField="identificacionTitular" width={120} />
        <Column dataField="nombreTitular" width={250} />
        <Column dataField="canalIngreso" width={180} />
        <Column dataField="tipoQueja" width={200} />
        <Column dataField="asignacionResponsable" width={150} />
        <Column dataField="prestador" width={150} />
        <Column dataField="solucion" width={180} />

        <Export enabled="true" allowExportSelectedData="true" />
      </DataGrid>
    </>
  );
};

export default QuejasPendientes;
