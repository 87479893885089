import React, { useState } from "react";

import FormCancelacion from "app/modules/app-seguro/components/FormCancelacion";
import BotonNotificacionCancelacionSeguro from "../../components/BotonNotificacionCancelacionSeguro";
import ListaCancelacionSeguros from "../../containers/ListaCancelacionSeguros";
import { useEffect } from "react";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import DetalleCancelacionSeguro from "../../containers/DetalleCancelacionSeguro";

const SolicitudCancelarSeguro = () => {
  const [solicitud, setSolicitud] = useState(null);

  useEffect(() => {
    console.log({ solicitud });
  }, [solicitud]);

  return (
    <>
      <BotonGerericoSalir />

      <div className="accordion-container">
        <ListaCancelacionSeguros setSolicitud={setSolicitud} />

        {solicitud && <DetalleCancelacionSeguro solicitud={solicitud} />}
      </div>
    </>
  );
};

export default SolicitudCancelarSeguro;
