import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import FormInfoSolicitud from "../components/FormInfoSolicitud";
import FormInformacionCliente from "../../app-cliente/components/FormInformacionCliente";
import FormInformacionUbicabilidad from "../../app-cliente/components/FormInformacionUbicabilidad";

import "../styles/main.css";
import { useSelector } from "react-redux";

import FormClienteReferencias from "app/modules/app-cliente/components/FormClienteReferencias";
import {
  LooksOneOutlined,
  LooksTwoOutlined,
  Looks3Outlined,
  Looks4Outlined,
} from "@mui/icons-material";

import TabFirmaFotoCliente from "./tabFirmaFotoCliente";
import BoxGenerarLinkFirma from "../components/BoxGenerarLinkFirma";
import { obtenerParametro } from "app/modules/app-configuracion/services/configuracionServices";
import { Alert, Stack } from "@mui/material";
import FormClienteActividadEconomica from "app/modules/app-cliente/components/FormClienteActividadEconomica";
import FormInformacionConyugue from "app/modules/app-cliente/components/FormInformacionConyugue";
import BoxDocumentosCliente from "../components/BoxDocumentosCliente";
import {
  obtenerRegistroArchivo,
  obtenerRegistroFirma,
} from "app/modules/app-cliente/services/clienteService";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";
import { red } from "@mui/material/colors";
import {
  grabarRegistroCLiente,
  obtenerRegistroCliente,
} from "app/modules/app-authcli/services";
import FormDebitoCuenta from "../components/FormDebitoCuenta";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StepInformacionCredito = ({
  identificacion,
  idCli,
  clienteConsultado,
}) => {
  const [value, setValue] = useState(0);
  const [valueCli, setValueCli] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  /* const [idCliente, setIdCliente] = useState(idCli); */
  const [generaLink, setGeneraLink] = useState(false);
  const [idSolicitud, setIdSolicitud] = useState(-1);
  const [actividadEconomica, setActividadEconomica] = useState(null);

  const [validarConyugue, setValidarConyugue] = useState(false);

  const [idClienteInformacion, setIdClienteInformacion] = useState(-1);
  const [idClienteAux, setIdClienteAux] = useState(null);
  const { authUser } = useSelector(({ auth }) => auth);
  const [archivoFirmaFoto, setArchivoFirmaFoto] = useState(null);
  const [registroFirmaFoto, setRegistroFirmaFoto] = useState(null);
  const [habilitarSiguiente, setHabilitarSiguiente] = useState(false);
  const [esRegistro, setEsRegistro] = useState(false);

  const [infoGrabada, setInfoGrabada] = useState({
    cliente: false,
    firma: false,
    financiero: false,
    solicitud: false,
  });

  //****DEBE ESTAR ORDENADO SEGUN LAS TABS PARA VALIDAR BOTON CONTINUAR */
  const [infoClienteGrabada, setInfoClienteGrabada] = useState({
    infoCliente: false,
    ubicabilidad: false,
    referencias: false,
    firma: false,
    financiero: false,
  });

  useEffect(() => {
    //console.log();
    //console.log(idCli);
    setIdClienteAux(idCli);
    getRolTablet();
    //getArchivosFirmaFoto(idCli);
  }, []);

  useEffect(async () => {
    //console.log(infoClienteGrabada);
    const indice = Object.values(infoClienteGrabada).lastIndexOf(true);
    //console.log(indice);
    if (indice >= 0) grabarInfoIngresada(indice);

    if (
      infoClienteGrabada.infoCliente &&
      infoClienteGrabada.ubicabilidad &&
      infoClienteGrabada.referencias
    ) {
      //console.log("Entra a cambiar estado CLIENTE");
      setInfoGrabada((args) => ({
        ...args,
        cliente: true,
      }));
      //await validarPasoGrabado(valueCli)
      //setHabilitarSiguiente(true);
    }

    if (infoClienteGrabada.firma) {
      //console.log("Entra a cambiar estado FIRMA");
      setInfoGrabada((args) => ({
        ...args,
        firma: true,
      }));
    }

    if (infoClienteGrabada.financiero) {
      //console.log("Entra a cambiar estado FINANCIERO");
      setInfoGrabada((args) => ({
        ...args,
        financiero: true,
      }));
    }
    
    if(!infoGrabada.firma)
    {
      setInfoGrabada((args) => ({
        ...args,
        financiero: false,
      }));
    }
  }, [infoClienteGrabada]);

  useEffect(() => {
    console.log(infoGrabada);
    console.log(infoClienteGrabada);
    validarPasoGrabado(valueCli);
  }, [valueCli, infoGrabada]);

  useEffect(async () => {
    if (infoGrabada.solicitud) {
      try {
        const today = new Date().toLocaleString("en-US");
        const srvRegistroCliente = await obtenerRegistroCliente(
          identificacion,
          authUser.usuarioFlujos[0].idGrupo
        );
        if (srvRegistroCliente.state) {
          let registro = srvRegistroCliente.data;

          //debugger;
          registro.secuencia = 6;
          registro.procesado = true;
          registro.usuarioModificacion = authUser.key;
          registro.usuario = registro.usuario.replace("registro", "cliente");
          registro.estacionModificacion = authUser.estacion;
          registro.fechaModificacion = today;

          try {
            var srvGrabarRegistro = await grabarRegistroCLiente(registro);
          } catch (e) {
            console.log(e);
          }
        } else {
          //No existe registro de Prospeccion
          setEsRegistro(false);
          console.log(srvRegistroCliente.message);
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  }, [infoGrabada.solicitud]);

  const grabarInfoIngresada = async (paso) => {
    //debugger;
    const arrayEstados = Object.values(infoClienteGrabada);
    if (arrayEstados[paso]) {
      try {
        const today = new Date().toLocaleString("en-US");
        const srvRegistroCliente = await obtenerRegistroCliente(
          identificacion,
          authUser.usuarioFlujos[0].idGrupo
        );
        if (srvRegistroCliente.state) {
          setEsRegistro(true);
          let registro = srvRegistroCliente.data;
          if (paso + 1 > registro.secuencia) {
            registro.secuencia = paso + 1;
          }
          registro.usuarioModificacion = authUser.key;
          registro.estacionModificacion = authUser.estacion;
          registro.fechaModificacion = today;
          registro.idCliente = idClienteInformacion;
          registro.idFlujo = authUser.usuarioFlujos[0].idFlujo;
          try {
            var srvGrabarRegistro = await grabarRegistroCLiente(registro);
          } catch (e) {
            //console.log(e);
          }
        } else {
          //no existe registro de prospeccion
          //console.log(srvRegistroCliente.message);
        }
      } catch (e) {
        //console.log(e.message);
      }
    }
  };

  const validarPasoGrabado = async (paso) => {
    //console.log(valueCli);
    const arrayDatosGrabados = Object.values(infoGrabada);
    //console.log(paso);
    setHabilitarSiguiente(arrayDatosGrabados[paso]);
  };

  const getRolTablet = async () => {
    try {
      const response = await obtenerParametro("ROLTAB");
      if (response.state) {
        const idRol = authUser.usuarioRol[0].idRol;
        idRol === response.data ? setGeneraLink(false) : setGeneraLink(true);

        //console.log(response.data);
      } else {
        console.log("Error al obtener rol que genera link de firma", response);
      }
    } catch (_errors) {
      console.log(_errors);
    }
  };

  const getArchivosFirmaFoto = async (idCliente) => {
    setArchivoFirmaFoto(false);
    let infoArchivos;
    try {
      const fotos = await obtenerRegistroArchivo(idCliente);
      if (fotos.state) {
        infoArchivos = fotos.data;
        //setArchivoFirmaFoto(true);
        console.log("Si existe registro de Foto");
        //console.log(fotos.data);
      } else {
        console.log("No existe registro de Foto");
        //setArchivoFirmaFoto(null);
      }
    } catch (_errors) {
      console.log(_errors);
    }

    let infofirma;
    try {
      const firma = await obtenerRegistroFirma(idCliente);
      if (firma.state) {
        infofirma = firma.data;
        //setArchivoFirmaFoto(true);
        console.log("Si existe registro de firma");
        //console.log(solicit.firma);
      } else {
        console.log("No existe registro de firma");
        //setArchivoFirmaFoto(null);
      }
    } catch (_errors) {
      console.log(_errors);
    }

    if (infoArchivos.idArchivo && infofirma.idFirma) {
      const res = {
        ...infoArchivos,
        ...infofirma,
      };

      setRegistroFirmaFoto(res);
      setArchivoFirmaFoto(true);
    } else {
      setRegistroFirmaFoto(null);
      setArchivoFirmaFoto(false);
      //setMostrarMensajeDocs(true);
    }
  };

  useEffect(() => {
    //console.log(idClienteInformacion);

    setIdClienteInformacion(idClienteAux);
    if (idClienteInformacion > 0) {
      setIdClienteInformacion(idClienteInformacion);
      setIdClienteAux(idClienteInformacion);
    }
  }, [idClienteInformacion]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeTabCli = (event, newValue) => {
    setValueCli(newValue);
  };

  const handleSeeDocs = async () => {
    try {
      await getArchivosFirmaFoto(idClienteInformacion);
    } catch (e) {
      setInfoGrabada((args) => ({
        ...args,
        firma: false,
        financiero: false
      }));
      setInfoClienteGrabada((args) => ({
        ...args,
        firma: false,
        financiero: false
      }));
      alert("No existen completos los documentos de firma y foto");
    }
  };

  return (
    <>
      {idClienteAux && (
        <div>
          <Stack direction="row" justifyContent="flex-end">
            <SCSGenericButton
              size={18}
              titulo="Siguiente -->>"
              onClick={() => {
                setValueCli(valueCli + 1);
                console.log("OK");
              }}
              //  hidden={valueCli===5?true:false}
              disabled={!habilitarSiguiente}
            />
          </Stack>
          <Stack direction="row" justifyContent="center">
            <Tabs
              //variant="fullWidth"

              value={valueCli}
              onChange={handleChangeTabCli}
              //aria-label="icon label tabs example"
              textColor="error"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                icon={<LooksOneOutlined />}
                iconPosition="start"
                label="Datos Personales"
                className="tabsAnalista"
              />
              <Tab
                icon={<LooksTwoOutlined />}
                iconPosition="start"
                label="Documentos"
                className="tabsAnalista"
              />
              <Tab
                icon={<Looks3Outlined />}
                iconPosition="start"
                label="Informacion Financiera"
                className="tabsAnalista"
              />
              <Tab
                icon={<Looks4Outlined />}
                iconPosition="start"
                label="Solicitud"
                className="tabsAnalista"
              />
            </Tabs>
          </Stack>
          <TabPanel value={valueCli} index={0}>
            <FormInformacionCliente
              idCliente={idClienteAux}
              identificacion={identificacion}
              setIdClienteInformacion={setIdClienteInformacion}
              setValidarConyugue={setValidarConyugue}
              setInfoClienteGrabada={setInfoClienteGrabada}
            />
            {validarConyugue ? (
              <FormInformacionConyugue idCliente={idClienteAux} />
            ) : (
              <h2></h2>
            )}
            {infoClienteGrabada.infoCliente ? (
              <FormInformacionUbicabilidad
                idCliente={idClienteAux}
                setInfoClienteGrabada={setInfoClienteGrabada}
              />
            ) : (
              <Alert severity="warning">
                <Typography>
                  Para continuar ingresa los datos del Cliente.
                </Typography>
              </Alert>
            )}

            {infoClienteGrabada.ubicabilidad ? (
              <FormClienteReferencias
                idCliente={idClienteAux}
                setInfoClienteGrabada={setInfoClienteGrabada}
              />
            ) : (
              <Alert severity="warning">
                <Typography>
                  Para continuar ingresa los datos de dirección del Cliente.
                </Typography>
              </Alert>
            )}
          </TabPanel>
          <TabPanel value={valueCli} index={1}>
            <Alert severity="info">
              <Typography
                sx={{
                  fontSize: "1.2rem",
                }}
              >
                En esta sección recuerda tener a la mano tu celular y tu cédula.
              </Typography>
            </Alert>
            {infoGrabada.cliente ? (
              archivoFirmaFoto ? (
                <BoxDocumentosCliente
                  idCli={idClienteAux}
                  cliente={clienteConsultado}
                  identifCliente={identificacion}
                  idSolicitudChanger={setIdSolicitud}
                  setInfoGrabada={setInfoGrabada}
                  registroFirmaFoto={registroFirmaFoto}
                  setArchivoFirmaFoto={setArchivoFirmaFoto}
                />
              ) : generaLink ? (
                <>
                  <BoxGenerarLinkFirma
                    idCli={idClienteAux}
                    cliente={clienteConsultado}
                    identifCliente={identificacion}
                    idSolicitudChanger={setIdSolicitud}
                    setInfoGrabada={setInfoClienteGrabada}
                    infoGrabada={infoGrabada}
                  />
                  {infoGrabada.firma && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <SCSGenericButton
                        titulo={"Vista previa documentos"}
                        onClick={handleSeeDocs}
                      />
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <TabFirmaFotoCliente
                    idCli={idClienteAux}
                    cliente={clienteConsultado}
                    identifCliente={identificacion}
                    idSolicitudChanger={setIdSolicitud}
                    setInfoGrabada={setInfoClienteGrabada}
                    infoGrabada={infoGrabada}
                    inhabilitar={false}
                  />
                  {infoGrabada.firma && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <SCSGenericButton
                        titulo={"Vista previa documentos"}
                        onClick={handleSeeDocs}
                      />
                    </Box>
                  )}
                </>
              )
            ) : (
              <Alert severity="error">
                <Typography>
                  Para continuar debe ingresar los datos de Cliente (Paso 1)
                </Typography>
              </Alert>
            )}
          </TabPanel>
          <TabPanel value={valueCli} index={2}>
            {infoGrabada.firma ? (
              <FormClienteActividadEconomica
                idCliente={idClienteAux}
                setInfoClienteGrabada={setInfoClienteGrabada}
              />
            ) : (
              <Alert severity="error">
                <Typography>
                  Para continuar debe registrar firma y fotografías (Paso 2)
                </Typography>
              </Alert>
            )}
          </TabPanel>
          <TabPanel value={valueCli} index={3}>
            {infoGrabada.financiero ? (
              <>
                <FormDebitoCuenta
                  idCliente={idClienteInformacion}
                  idGrupo={authUser.usuarioFlujos[0].idGrupo}
                  idCuenta={null}
                  notificar={false}
                />
                <FormInfoSolicitud
                  idCli={idClienteInformacion}
                  cliente={clienteConsultado}
                  identifCliente={identificacion}
                  idSolicitudChanger={setIdSolicitud}
                  setInfoGrabada={setInfoGrabada}
                  esRegistro={esRegistro}
                />
              </>
            ) : (
              <Alert severity="error">
                <Typography>Para continuar debe completar</Typography>
                {!infoClienteGrabada.infoCliente && (
                  <Typography>-Información de Cliente</Typography>
                )}
                {!infoClienteGrabada.ubicabilidad && (
                  <Typography>-Dirección del Cliente</Typography>
                )}
                {!infoClienteGrabada.referencias && (
                  <Typography>-Referencias</Typography>
                )}
                {!infoGrabada.firma && (
                  <Typography>-Firma y Fotografías</Typography>
                )}
                {!infoGrabada.financiero && (
                  <Typography>-Informacion Financiera</Typography>
                )}
              </Alert>
            )}
          </TabPanel>
          <Stack direction="row" justifyContent="center">
            <SCSGenericButton
              size={18}
              titulo="Siguiente -->>"
              onClick={() => {
                setValueCli(valueCli + 1);
                //console.log("OK");
              }}
              //  hidden={valueCli===5?true:false}
              disabled={!habilitarSiguiente}
            />
          </Stack>
        </div>
      )}
    </>
  );
};

export default StepInformacionCredito;
