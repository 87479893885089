import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
//import {authUser} from "./fake-db";
import {ListItemIcon, ListItemText, ThemeProvider, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
//import {useNavigate} from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import {useJumboTheme} from "@jumbo/hooks";
import { useOnLogout } from '../../../modules/app-auth/hook/index';
import { useSelector } from "react-redux";
import { history } from '../../../redux/store';

const AuthUserDropdown = () => {
    const userRedux = useSelector((state) => state.userReducer);
    const [sendLogout, setSendLogout] = useState(false)

    var data = JSON.parse(sessionStorage.getItem('user'));
    const userCode = data ? data.key : "";
    useOnLogout(userCode, sendLogout, setSendLogout);

    const {theme} = useJumboTheme();

    const onLogout = () => {
        setSendLogout(true);
    };
    
    const onPerfil = () => {
        //navigate("/perfil", {replace: true});
        history.push("/signin");
        //mutation.mutate();
    };
    
    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <Avatar
                        //src={authUser.profile_pic}
                        sizes={"small"}
                        sx={{boxShadow: 25, cursor: 'pointer'}}
                    />
                }
            >
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(2.5),
                }}>
                    
                     {/* <Avatar  alt={data.email } sx={{width: 60, height: 60, mb: 2}}/> */}
                    {/* <Typography variant={"h5"}>{data.nombres +  " " +data.apellidos}</Typography> */}
                    {/* <Typography variant={"body1"} color="text.secondary">{data.email}</Typography>  */}
                </Div>
                <Divider/>
                <nav>
                    <List disablePadding sx={{pb: 1}}>
                        <ListItemButton onClick={onPerfil}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <PersonOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Profile" sx={{my: 0}}/>
                        </ListItemButton>
                        {/* <ListItemButton>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <EditOutlinedIcon/>
                            </ListItemIcon>
                           
                            <ListItemText onClick={() => navigate("/login")} primary="Switch User"
                                          sx={{my: 0}}/>

                        </ListItemButton>
                        <ListItemButton>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <RepeatOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText onClick={() => navigate("/profile")} primary="Switch User"
                                          sx={{my: 0}}/>
                        </ListItemButton> */}
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Salir" sx={{my: 0}}/>
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
