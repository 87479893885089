/**
 * Esta función suma dos números.
 * @param {number} pcWidth - Width para tamaños de PC.
 * @param {number} phoneWidth - Width para tamaños de phones.
 * @returns {number} Estilos media querys.
 */

const StyleInputs = (pcWidth, phoneWidth) => {
  return {
    width: `${pcWidth}%`,
    "@media (max-width: 600px)": {
      width: `${phoneWidth}%`,
    },
  };
};

export { StyleInputs };
