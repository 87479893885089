import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BotonSalirLimiarStorageUsuario from "./BotonSalirLimpiarStorageUsuario";
import FormImformacionUsuario from "../Components/FormInformacionUsuario";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DetalleInformacionUsuario = ({ idUsuario, usuario, identificacion }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log({ idUsuario });
    console.log({ usuario });
    console.log({ identificacion });
  }, []);

  if (usuario.rol !== "VENTAEXT" && usuario.rol !== "VENTAEXTSERVIMAX") {
    return (
      <>
        <BotonGerericoSalir />
        <h2>Identificación no pertenece a un promotor!!</h2>
      </>
    );
  }

  return (
    <>
      <BotonSalirLimiarStorageUsuario />
      <FormImformacionUsuario idUsuario={idUsuario} usuario={usuario} />
    </>
  );
};

export default DetalleInformacionUsuario;
