import  SolicitudesContent  from './SolicitudesList/SolicitudesContent';

import React from 'react';
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUT_NAMES} from "../../../layouts/layouts";


const SolicitudesList = () => {
  const {setActiveLayout} = useJumboApp();

  React.useEffect(() => {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
      <div>
        <h2>Gestion de solicitudes</h2>
            <SolicitudesContent/>
      </div>
  );
};

export default SolicitudesList;