import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";

import { formatoFecha } from "../../shared/validations/validaciones";

import { consultaCabeceraProducto } from "../Services/siniestrosService";

const HeaderInformacionProducto = ({ idSiniestro }) => {
  const [datosBien, setDatosBien] = useState(null);

  useEffect(() => {
    /* console.log(typeof idContrato); */
    const getData = async () => {
      /*  console.log(idContrato); */

      try {
        const { state, data } = await consultaCabeceraProducto(idSiniestro);

        if (state) {
          setDatosBien(data);
        } else {
          setDatosBien({});
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  return (
    <>
      {datosBien && (
        <div className="header-client-container">
          <Card>
            <header className="header-client-information">
              <div>
                <h2>Producto</h2>
              </div>
              <div>
                <h4>N° Contrato</h4>
                <h4>
                  {datosBien.numeroContrato ? datosBien.numeroContrato : ""}
                </h4>
              </div>
              <div>
                <h4>Producto</h4>
                <h4>{datosBien.producto ? datosBien.producto : ""}</h4>
              </div>
              <div>
                <h4>Fecha inicio</h4>
                <h4>
                  {datosBien.fechaInicio
                    ? formatoFecha(datosBien.fechaInicio)
                    : ""}
                </h4>
              </div>
              <div>
                <h4>Articulo</h4>
                <h4>
                  {datosBien.descripcionBien ? datosBien.descripcionBien : ""}
                </h4>
              </div>
              <div>
                <h4>Valor Bien</h4>
                <h4>{datosBien.valorBien ? datosBien.valorBien : ""} $</h4>
              </div>
            </header>
          </Card>
        </div>
      )}
    </>
  );
};

export default HeaderInformacionProducto;
