import React ,{ useState }from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from '@mui/material/Paper';
import  ChangePassword  from "../../../common/components/changePassword/index";
import { useOnChangePassword } from '../../../app-auth/hook/index'

const ContainerProfile = () => {
	const dispatch = useDispatch();
	const { authUser } = useSelector((state) => state.auth);
	const [sendChangePassword, setSendChangePassword] = useState(false);
	const [jsonPayload, setJsonPayload] = useState({});
	const [form, setForm] = useState({
		currentPassword: "",
		newPassword: "",
		confirmNewPassword: "",
	})
	useOnChangePassword(jsonPayload,sendChangePassword, setSendChangePassword);
	
	const onSubmit =  async values => {
        const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
		await sleep(300);
		const payload = {
			userCode: authUser.key,
			password: values.currentPassword,
			newPassword: values.newPassword,
		};
		setJsonPayload(payload);
		setForm(values);
		setSendChangePassword(true);
	};

	return (
		<Paper style={{ width: "50%", padding: "20px" }}>
			<ChangePassword onSubmit={onSubmit} form={form} />
		</Paper>
	);
};

export default ContainerProfile;