import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import PagosActualizar from "./PagosActualizar/PagosActualizar";
/* import SolicitudServicios from "./SolicitudServicios/SolicitudServicios"; */

const ActualizarPagos = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Actualizar Pagos</h2>
      <PagosActualizar />
    </div>
  );
};

export default ActualizarPagos;
