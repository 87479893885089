import React, { useState } from "react";

import BotonSalirLimpiarStorage from "../../container/BotonSalirLimpiarStorage";
import BuscadorPorIdentificacionServimax from "app/modules/app-cliente/containers/BuscadorPorIdentificacionServimax";
import BuscadorPorIdentificacionGenerico from "app/modules/app-cliente/containers/BuscadorPorIdentificacionGenerico";
import DetalleCrearSiniestro from "../../container/DetalleCrearSiniestro";

const SiniestrosCrear = () => {
  const [datosBusqueda, setDatosBusqueda] = useState({
    identificacion: "",
    mostrarBuscador: true,
    idGrupo: Number(localStorage.getItem("idGrupoStorage")),
  });

  if (datosBusqueda.mostrarBuscador) {
    return (
      <>
        {datosBusqueda.idGrupo === 4 ? (
          <div className="gestion-siniestros-container">
            <BuscadorPorIdentificacionServimax
              setDatosBusqueda={setDatosBusqueda}
              titulo="Consultar información del cliente"
            />
          </div>
        ) : (
          <div className="gestion-siniestros-container">
            <BuscadorPorIdentificacionGenerico
              setDatosBusqueda={setDatosBusqueda}
              titulo="Consultar información del cliente"
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <BotonSalirLimpiarStorage />

      <DetalleCrearSiniestro datosBusqueda={datosBusqueda} />
    </>
  );
};

export default SiniestrosCrear;
