import React from "react";
import { Formik, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { MaskID } from "app/modules/shared/mask/mascarasInputs";

const BuscadorColaboradorIdentificador = ({ setIdentificacion }) => {
  const enviarDatosBusqueda = (valores) => {
    console.log(valores);
    setIdentificacion((args) => ({
      ...args,
      state: true,
      data: valores.identificador,
    }));
  };

  const resetBusqueda = (handleReset) => {
    handleReset();
    setIdentificacion((args) => ({
      ...args,
      state: false,
      data: "",
    }));
  };

  return (
    <div className="header-reporte-container">
      <Card sx={{ width: 600 }}>
        <header className="header-colaborador-container">
          <div className="header-reporte-detalle">
            <h3>Consultar Colaborador</h3>
          </div>

          <Formik
            initialValues={{
              identificador: "",
            }}
            validationSchema={Yup.object({
              identificador: Yup.string().required("Identificación requerida"),
            })}
            onSubmit={(valores, { resetForm }) => {
              enviarDatosBusqueda(valores);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleReset,
            }) => (
              <Form>
                <div className="buscador-listaNegra-opciones">
                  <FormControl
                    sx={{ width: "35%" }}
                    size="small"
                    variant="outlined"
                    error={
                      touched.identificador && Boolean(errors.identificador)
                    }
                    helperText={touched.identificador && errors.identificador}
                    onBlur={handleBlur}
                  >
                    <InputLabel htmlFor="identificador">
                      Identificación
                    </InputLabel>
                    <OutlinedInput
                      id="identificador"
                      name="identificador"
                      value={values.identificador}
                      label="Identificación"
                      inputComponent={MaskID}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="identificador"
                      component={() => (
                        <FormHelperText>{errors.identificador}</FormHelperText>
                      )}
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    sx={{ width: "25ch", height: "6ch" }}
                  >
                    Buscar
                  </Button>

                  <br />

                  <Button
                    type="button"
                    variant="contained"
                    size="small"
                    sx={{ width: "25ch", height: "6ch" }}
                    onClick={() => resetBusqueda(handleReset)}
                  >
                    Limpiar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </header>
      </Card>
    </div>
  );
};

export default BuscadorColaboradorIdentificador;
