import React from "react";

import { useHistory } from "react-router";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    /*  hight: 2000 */
    minWidth: 800,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    /* width: "50%", */
  },
  /* "& .MuiDialogActions-root": {
      with: 30,
    }, */
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalAlertDesestimar = ({
  children,
  open,
  setOpen,
  titulo,
  mensaje,
  solicitud,
}) => {
  const diccionarioSolicitudes = (tipo) => {
    const tipoSolicitud = {
      1: "CERTIFICADO",
      2: "CANCELACION SEGURO",
      3: "DEVOLUCION PRODUCTOS",
      4: "PRECANCELACION",
      17: "CAMBIO ESTADO",
    };

    return tipoSolicitud[tipo];
  };

  const diccionarioSubTipo = (subtipo) => {
    const subtipoSolicitud = {
      5: "ESTADO DEUDA",
      6: "SIN DEUDA",
      7: "ESPECIAL",
    };

    return subtipoSolicitud[subtipo];
  };

  return (
    <BootstrapDialog
      /* aria-describedby="alert-dialog-slide-description"
      style={{ height: "30%" }} */

      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => setOpen(false)}
      >
        {solicitud.idTipo !== 1
          ? diccionarioSolicitudes(solicitud.idTipo)
          : `${diccionarioSolicitudes(solicitud.idTipo)}  ${diccionarioSubTipo(
              solicitud.idSubtipo
            )}`}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>{mensaje}</Typography>
      </DialogContent>

      {children && <DialogContent dividers>{children}</DialogContent>}

      <DialogActions>
        <Button autoFocus onClick={() => setOpen(false)}>
          Cerrar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ModalAlertDesestimar;
