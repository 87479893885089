import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import DetalleCrearQueja from "../containers/DetalleCrearQueja";

const CrearQuejas = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Quejas - Crear</h2>
      <DetalleCrearQueja />
    </div>
  );
};

export default CrearQuejas;
