import React, { useEffect, useState } from "react";

import { Alert, Button, Grid, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "../styles/main.css";

import { obtenerDocumento } from "../services/creditoServices";

import handleDownlPdf from "app/utils/document/documentosService";
import { obtenerDocumentoConfiguracion } from "app/modules/app-configuracion/services/configuracionServices";
import {
  obtenerRegistroArchivo,
  obtenerRegistroFirma,
} from "app/modules/app-cliente/services/clienteService";
import descargaImage from "app/utils/document/documentoPng";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";

const BoxDescargarSolicitud = ({
  idSolic,
  idCliente,
  identificacion,
  solic,
}) => {
  const [archivoDescargado, setArchivoDescargado] = useState(false);
  const [registroArchivo, setRegistroArchivo] = useState({});

  useEffect(() => {
    console.log(identificacion, solic);
    obtenerRegArchivo(solic.idCliente);
  }, []);

  const obtenerDoc = async () => {
    console.log("Intentando descargar", identificacion);
    await obtenerDocumento(idSolic)
      .then((response) => {
        if (response.state) {
          handleDownlPdf(response.data, `Solicitud_Credito_${identificacion}`);
          setArchivoDescargado(true);
        } else {
          alert("No existe documentacion subida");
        }

        setTimeout(() => {
          setArchivoDescargado(false);
        }, 3000);
      })
      .catch((error) => {
        alert("No existe documentacion subida");
      });
  };

  const obtenerRegArchivo = async (idCliente) => {
    try {
      const registroArchivo = await obtenerRegistroArchivo(idCliente);
      if (registroArchivo.state) {
        console.log(registroArchivo.data);
        setRegistroArchivo(registroArchivo.data);
      } else {
        console.log(registroArchivo.message);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const obtenerArchivoBucket = async (tipo) => {
    console.log("Intentando descargar", tipo);
    try {
      let nombreArchivo = "";

      switch (tipo) {
        case "cedula":
          nombreArchivo = registroArchivo.cedula;
          break;
        case "foto":
          nombreArchivo = registroArchivo.foto;
          break;
        default:
          console.log("No existe el tipo de archivo solicitado" + tipo);
      }

      const response = await obtenerDocumentoConfiguracion(
        nombreArchivo,
        "archivos"
      );
      if (response.state) {
        const stringPDF = `data:image/jpeg;base64,${response.data}`;
        descargaImage(response.data, `${tipo}-${registroArchivo.idCliente}`);
        setArchivoDescargado(true);
      } else {
        console.log(response.message);
      }

      setTimeout(() => {
        setArchivoDescargado(false);
      }, 3000);
    } catch (error) {
      console.log(error.message);
    }
  };

  const obtenerFirma = async () => {
    console.log("Intentando descargar firma");
    try {
      const response = await obtenerRegistroFirma(solic.idCliente);
      if (response.state) {
        const stringPDF = `data:image/png;base64,${response.data.firma1}`;
        descargaImage(response.data.firma1, `Firma-${solic.idCliente}`);
        setArchivoDescargado(true);
      } else {
        alert("No existe firma registrada");
      }

      setTimeout(() => {
        setArchivoDescargado(false);
      }, 3000);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <SCSAccordion tituloCabecera="Descarga de Documentos Digitalizados">
        <Stack
          //sx={{marginTop:3}}
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={{ xs: 2, sm: 3, md: 3 }}
          justifyContent={"center"}
        >
          <SCSGenericButton
            //sx={{ width: 250 , height: 60 }}
            //className="buscador-botones-centro"
            variant="contained"
            onClick={obtenerDoc}
            size={20}
            titulo={"Visualizar documentacion"}
          />
          <SCSGenericButton
            //sx={{ width: 250, height: 60 }}
            //className="buscador-botones-centro"
            variant="contained"
            onClick={() => {
              obtenerArchivoBucket("cedula");
            }}
            size={20}
            titulo={"Visualizar Cedula"}
          />
          <SCSGenericButton
            //sx={{ width: 250, height: 60 }}
            //className="buscador-botones-centro"
            variant="contained"
            onClick={() => {
              obtenerArchivoBucket("foto");
            }}
            size={20}
            titulo={"Visualizar Fotografia"}
          />

          <SCSGenericButton
            sx={{ width: 250, height: 60 }}
            className="buscador-botones-centro"
            variant="contained"
            onClick={() => {
              obtenerFirma();
            }}
            size={20}
            titulo={"Visualizar Firma"}
          />
        </Stack>
        {archivoDescargado && (
          <Alert severity="success" variant="filled">
            Se ha Descargado el Archivo.
          </Alert>
        )}
      </SCSAccordion>
    </>
  );
};

export default BoxDescargarSolicitud;
