import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import ReportesSolicitud from "./ReportesSolicitud/ReportesSolicitud";
import "../styles/main.css";

const SolicitudReportes = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Reportes - Solicitud</h2>
      <ReportesSolicitud />
    </div>
  );
};

export default SolicitudReportes;
