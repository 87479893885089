import React, { useState } from "react";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";

import HeaderReporte from "../../containers/HeaderReporte";
import BotonConsultaGestionRequerimiento from "../../components/BotonConsultaGestionRequerimiento";
import BotonConsultaSegurosPolizas from "../../components/BotonConsultaSegurosPolizas";
import BotonConsultaSegurosCancelados from "../../components/BotonConsultaSegurosCancelados";

const ReporteSegurosPolisas = () => {
  return (
    <>
      <BotonGerericoSalir />
      <div className="header-reporte-detalle">
        <HeaderReporte title="Reporte Seguros" />
      </div>

      <div className="reportes-container">
        <BotonConsultaSegurosPolizas />

        <BotonConsultaSegurosCancelados />
      </div>
    </>
  );
};

export default ReporteSegurosPolisas;
