import React, { useContext, useEffect } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ChatSideContext from "../context/ChatSideContext";

const DetalleCoversacionesPorUsuario = ({ conversaccion }) => {
  const { informacionChatSide, setInformacionChatSide } =
    useContext(ChatSideContext);

  useEffect(() => {}, [conversaccion]);

  const cargarCliente = (clienteMensajeria) => {
    setInformacionChatSide((args) => ({
      ...args,
      mostrarCabecera: true,
      clienteSeleccionado: clienteMensajeria,
      changeSection: !args.changeSection,
    }));
  };

  return (
    <List style={{ marginTop: "1rem" }}>
      {conversaccion.map((clienteMensajeria) => (
        <>
          <ListItem disablePadding>
            <ListItemButton onClick={() => cargarCliente(clienteMensajeria)}>
              <ListItemText
                primary={
                  informacionChatSide.isCallCenter ? (
                    <h4
                      style={{
                        margin: "0",
                        fontWeight: "600",
                        letterSpacing: "-0.5px",
                      }}
                    >
                      {clienteMensajeria.nombreEmisor}
                    </h4>
                  ) : (
                    <h4
                      style={{
                        margin: "0",
                        fontWeight: "600",
                        letterSpacing: "-0.5px",
                      }}
                    >
                      {clienteMensajeria.nombreReceptor}
                    </h4>
                  )
                }
              />
              {!informacionChatSide.isCallCenter
                ? clienteMensajeria?.numPendientesEmisor !== 0 && (
                    <ListItemText
                      sx={{ width: "2em", textAlign: "center" }}
                      secondary={<div className="unread-message-count" />}
                    />
                  )
                : clienteMensajeria?.numPendientesReceptor !== 0 && (
                    <ListItemText
                      sx={{ width: "2em", textAlign: "center" }}
                      secondary={<div className="unread-message-count" />}
                    />
                  )}
            </ListItemButton>
          </ListItem>
          <Divider />
        </>
      ))}
    </List>
  );
};

export default DetalleCoversacionesPorUsuario;
