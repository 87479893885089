import React, { useState } from "react";

import BuscadorPorIdentificador from "app/modules/app-cliente/containers/BuscadorPorIdentificador";
import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";

import { consultaCliente } from "../../../app-cliente/services/clienteService";

import ListaSegurosCliente from "../../containers/ListaSegurosCliente";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import DetalleMedioPago from "../../containers/DetalleMedioPago";
import CabeceraSeguroService from "app/modules/app-servicios/containers/CabeceraSeguroService";

const ActualizarMedioPago = () => {
  const [cliente, setCliente] = useState(null);
  const [idCliente, setIdCliente] = useState("");
  const [mostrarDetalle, setMostrarDetalle] = useState(true);
  const [idContratoAux, setIdContratoAux] = useState(null);

  /*  const [idQuejaStorage, setIdQuejaStorage] = useQuejasLocalStorage(
    "idQuejaStorage",
    null
  ); */

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  /*  useEffect(() => {
    console.log(idQuejaStorage);
    if (idQuejaStorage) {
      setIdQuejaAux(idQuejaStorage);
      setMostrarDetalle(false);
    }
  }, [idQuejaAux, setIdQuejaAux]); */

  const buscarExistenciaCliente = async (identificador) => {
    try {
      const respuestaCliente = await consultaCliente(identificador);

      if (respuestaCliente.state) {
        setIdCliente(respuestaCliente.data.idCliente);

        setCliente(respuestaCliente.data);
        setMostrarDetalle(false);
      } else {
        setCliente(nuevoCliente);
        setIdCliente(nuevoIdCliente);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdCliente = (id) => () => {
    /*   console.log(id); */
    buscarExistenciaCliente(id);
  };

  if (mostrarDetalle) {
    return <BuscadorPorIdentificador traerIdCliente={traerIdCliente} />;
  }

  if (idCliente < 0) {
    return (
      <>
        <BotonGerericoSalir />
        <h2>No existe información del cliente!!</h2>
      </>
    );
  }

  const validarEsRecurrencia = (idContrato) => {
    if (!idContrato) {
      return false;
    }

    /*     console.log({ idContrato });
    console.log(idContrato.tipoPago);
 */
    const includeRecurrencia = idContrato.tipoPago.split(" ");
    return includeRecurrencia.includes("RECURRENCIA") ? true : false;
  };

  return (
    <div>
      <BotonGerericoSalir />

      <HeaderInformacionCliente idCliente={idCliente} />

      {idContratoAux && (
        <CabeceraSeguroService idContrato={idContratoAux.idContrato} />
      )}

      <ListaSegurosCliente
        cliente={cliente}
        idCliente={idCliente}
        setIdContratoAux={setIdContratoAux}
        isCancel={false}
      />

      {idContratoAux && (
        <>
          {validarEsRecurrencia(idContratoAux) ? (
            <DetalleMedioPago
              idCliente={idCliente}
              idContrato={idContratoAux}
            />
          ) : (
            <h2>Este seguro no admite pagos recurrentes.</h2>
          )}
        </>
      )}
    </div>
  );
};

export default ActualizarMedioPago;
