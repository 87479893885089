import React, { useState } from "react";
import DocumentosSoportePrecancelacion from "../components/DocumentosSoportePrecancelacion";

import FormPrecancelacion from "../components/FormPrecancelacion";

const Precancelacion = ({ idCliente, idLocal, identificacion }) => {
  //usar la constante para activar la carga de documentos
  const [idRequerimiento, setIdRequerimiento] = useState(null);

  return (
    <div className="accordion-container">
      <FormPrecancelacion
        setIdRequerimiento={setIdRequerimiento}
        idCliente={idCliente}
        idLocal={idLocal}
        identificacion={identificacion}
      />

      {idRequerimiento && (
        <DocumentosSoportePrecancelacion idRequerimiento={idRequerimiento} />
      )}
    </div>
  );
};

export default Precancelacion;
