import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

import { consultarListaHistoricoCobranza } from "../services/cobranzaServices";

import { Toolbar, Typography } from "@mui/material";
import { formatoFecha } from "app/modules/shared/validations/validaciones";

const ListaCobranzaHistorico = ({ idCliente }) => {
  const [listaHistorico, setListaHistorico] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    /*  console.log(idCliente); */
    const getHistoricoCobranza = async () => {
      try {
        const respuestaListaHistorico = await consultarListaHistoricoCobranza(
          idCliente
        );

        console.log(respuestaListaHistorico);

        if (respuestaListaHistorico.state && respuestaListaHistorico.data) {
          setListaHistorico(respuestaListaHistorico.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        alert();
      }
    };

    getHistoricoCobranza();
  }, [idCliente]);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Toolbar>
          {/* <Typography variant="h4">Histórico Cobranzas</Typography> */}
          <h4>Histórico Cobranzas</h4>
        </Toolbar>

        <Table
          size="small"
          sx={{ minWidth: 650, padding: 5 }} /*  aria-label="simple table" */
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">N°</TableCell>
              <TableCell align="center">Fecha Modificación</TableCell>
              <TableCell align="center">Fecha Corte</TableCell>
              <TableCell align="center">Valor Vencido</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Subestado</TableCell>
              <TableCell align="center">Fecha Compromiso</TableCell>
              <TableCell align="center">Observación</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaHistorico.map((historico, index) => (
              <TableRow key={index}>
                <TableCell align="center">{index + 1}</TableCell>

                <TableCell align="center">
                  {historico.fechaModificacion}
                </TableCell>

                <TableCell align="center">
                  {formatoFecha(historico.fechaCorte)}
                </TableCell>

                <TableCell align="center">{`$ ${historico.valorVencido}`}</TableCell>

                <TableCell align="center">{historico.estado}</TableCell>

                <TableCell align="center">{historico.subEstado}</TableCell>

                <TableCell align="center">
                  {formatoFecha(historico.fechaCompromiso)}
                </TableCell>

                <TableCell align="center">{historico.observacion}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ListaCobranzaHistorico;
