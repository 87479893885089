import React from "react";

import CabeceraSeguroService from "app/modules/app-servicios/containers/CabeceraSeguroService";
import TablasDetalleCuota from "../components/TablasDetalleCuota";

const DetalleCuotasSeguro = ({ idContrato }) => {
  return (
    <div>
      <CabeceraSeguroService
        idContrato={idContrato.idContrato}
        esPagoRecurrencias={true}
      />

      <TablasDetalleCuota idContrato={idContrato.idContrato} />
    </div>
  );
};

export default DetalleCuotasSeguro;
