import React, { memo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import {
  Card,
  CardContent,
  Grid,
  CardActions,
  TableRow,
  TableCell,
  Alert,
  Box,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import "../../styles/main.css";
import BuscadorPreevaluacion from "../../containers/BuscadorPreevaluacion";
import { preevaluacionCliente } from "../../services/creditoServices";
import {
  obtenerPersonaCf,
  validarDocumentosCF,
} from "app/modules/app-cliente/services/clienteService";
import {
  grabarAceptacion,
  obtenerAceptacion,
} from "app/modules/app-evaluacion/services/evaluacionServices";
import PoliticsDetails from "../../components/PoliticsDetails";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";

function PreevaluacionContent() {
  const [mostrarAprobado, setMostrarAprobado] = useState(false);
  const [mostrarNegado, setMostrarNegado] = useState(false);
  const [identificacionCliente, setIdentificacionCliente] = useState("");
  const [mensajeCliente, setMensajeCiente] = useState("");
  const [terminosYCondiciones, setTerminosYCondiciones] = useState("");
  const [politicasPrivacidad, setpoliticasPrivacidad] = useState("");
  const [aceptadoTyC, setAceptadoTyC] = useState(false);
  const [aceptadoPPR, setAceptadoPPR] = useState(false);
  const [datosCli, setDatosCli] = useState({});
  const { authUser } = useSelector(({ auth }) => auth);
  const [openTyC, setOpenTyC] = useState(false);
  const [openPPR, setOpenPPR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPreevaluar, setLoadingPreevaluar] = useState(false);
  const history = useHistory();

  const [statesLegalDocuments, setStatesLegalDocuments] = useState({
    stateTYC: false,
    statePPR: false,
  });

  const [openPoliticsDetails, setOpenPoliticsDetails] = useState(false);
  const [clientInformation, setClientInformation] = useState({});
  const [emailVerificacion, setEmailVerificacion] = useState({});

  const traerIdentificacion = (id) => async () => {
    if (!id) {
      alert("Ingrese un numero de cedula");
    } else {
      if (id.length === 10) {
        setAceptadoTyC(false);
        setAceptadoPPR(false);
        setMostrarAprobado(false);
        setMostrarNegado(false);
        setIdentificacionCliente(id);
        validacionInicialCliente(id);
      } else {
        alert("Ingrese un numero de cedula valido");
      }
    }
  };

  const validarIdentificacionCF = (id) => async () => {
    if (!id) {
      alert("Ingrese un numero de cedula");
    } else {
      if (id.length === 10) {
        setMostrarAprobado(false);
        setMostrarNegado(false);
        setAceptadoTyC(false);
        setAceptadoPPR(false);
        validacionTyC(id);
      } else {
        alert("Ingrese un numero de cedula valido");
      }
    }
  };

  // const validacionTyC = async (identificacion) => {
  //   const today = new Date().toLocaleString("en-US");

  //   const respInfoAceptacion = await obtenerAceptacion(identificacion);
  //   if (respInfoAceptacion.state && respInfoAceptacion.data.enviaDatos) {
  //     alert("Cliente FIRMADOS los documentos de TyC, puede continuar");
  //     setAceptadoPPR(true);
  //     setAceptadoTyC(true);
  //   } else {
  //     const cli = {
  //       identificacion: identificacion,
  //       fechaConsulta: today,
  //       usuario: authUser.key,
  //       estacion: authUser.estacion,
  //       enviaDatos: false,
  //       tcu: false,
  //       ppr: false,
  //     };
  //     try {
  //       const respObetenerPersona = await obtenerPersonaCf(identificacion);
  //       if (respObetenerPersona.state) {
  //         //setDatosCli({ cliente: respObetenerPersona.data.statePerson});
  //         cli.esCliente = respObetenerPersona.data.statePerson;
  //       } else {
  //         console.log(respObetenerPersona.message);
  //       }

  //       if (cli.esCliente) {
  //         const respValidarDocumentos = await validarDocumentosCF(
  //           identificacion
  //         );
  //         if (respValidarDocumentos.state) {
  //           const boolString = "true";
  //           //setDatosCli({ documentosFirmados: boolString === respValidarDocumentos.data.stateDocuments});
  //           cli.documentosFirmados =
  //             boolString === respValidarDocumentos.data.stateDocuments;

  //           if (cli.documentosFirmados) {
  //             alert("Cliente FIRMADOS los documentos de TyC, puede continuar");
  //             setAceptadoPPR(true);
  //             setAceptadoTyC(true);
  //           } else {
  //             alert("Cliente debe Firmar documentos de TyC");
  //             const documentos = respValidarDocumentos.data.documents;
  //             console.log(documentos);
  //             if (documentos === null) {
  //               const respDocTcu = await obtenerDocumentoLegal("TCD");
  //               if (respDocTcu.state) {
  //                 setTerminosYCondiciones(respDocTcu.data);
  //               } else {
  //                 alert(respDocTcu.message);
  //               }

  //               const respDocPpr = await obtenerDocumentoLegal("PPR");
  //               if (respDocPpr.state) {
  //                 setpoliticasPrivacidad(respDocPpr.data);
  //               } else {
  //                 alert(respDocPpr.message);
  //               }

  //               setOpenTyC(true);
  //               setOpenPPR(true);
  //             } else {
  //               if (documentos.length < 2) {
  //                 const tieneTCU = documentos.some(
  //                   (doc) => doc.contentType === "TCD"
  //                 );
  //                 const tienePPR = documentos.some(
  //                   (doc) => doc.contentType === "PPR"
  //                 );
  //                 if (!tieneTCU) setAceptadoTyC(true);

  //                 if (!tienePPR) setAceptadoPPR(true);
  //               }
  //               documentos.map((doc) => {
  //                 if (doc.contentType === "TCD") {
  //                   setTerminosYCondiciones(doc.content);
  //                   setOpenTyC(true);
  //                 }
  //                 if (doc.contentType === "PPR") {
  //                   setpoliticasPrivacidad(doc.content);
  //                   setOpenPPR(true);
  //                 }
  //               });
  //             }
  //           }
  //         } else {
  //           alert(respValidarDocumentos.message);
  //         }
  //       } else {
  //         alert("No es cliente CF debe Firmar Documentos");
  //         cli.documentosFirmados = false;
  //         const respDocTcu = await obtenerDocumentoLegal("TCD");
  //         if (respDocTcu.state) {
  //           setTerminosYCondiciones(respDocTcu.data);
  //         } else {
  //           alert(respDocTcu.message);
  //         }

  //         const respDocPpr = await obtenerDocumentoLegal("PPR");
  //         if (respDocPpr.state) {
  //           setpoliticasPrivacidad(respDocPpr.data);
  //         } else {
  //           alert(respDocPpr.message);
  //         }

  //         setOpenTyC(true);
  //         setOpenPPR(true);
  //       }
  //     } catch (e) {
  //       alert(e);
  //     }
  //     setDatosCli({ ...cli });
  //   }
  // };

  const validacionTyC = async (identificacion) => {
    setLoading(true);
    const today = new Date().toLocaleString("en-US");

    try {
      const respInfoAceptacion = await obtenerAceptacion(identificacion);

      if (respInfoAceptacion.state && respInfoAceptacion.data.enviaDatos) {
        alert(
          "Cliente ya tiene firmado los documentos de Términos y Condiciones. Puede continuar"
        );

        setOpenPoliticsDetails(false);
        //setModalClient((args) => ({ ...args, state: false }));
        setStatesLegalDocuments((args) => ({
          ...args,
          statePPR: true,
          stateTYC: true,
        }));

        setAceptadoPPR(true);
        setAceptadoTyC(true);
        setLoading(false);

        return;
      }
    } catch (error) {
      console.log(error);
    }

    const cli = {
      identificacion: identificacion,
      fechaConsulta: today,
      idGrupo: authUser.usuarioFlujos[0].idGrupo,
      usuario: authUser.key,
      estacion: authUser.estacion,
      enviaDatos: false,
      tcu: false,
      ppr: false,
      bur: true,
      //esCliente:false
    };

    try {
      if (authUser.usuarioFlujos[0].idGrupo === 1) {
        const respObetenerPersona = await obtenerPersonaCf(identificacion);
        if (respObetenerPersona.state) {
          //setDatosCli({ cliente: respObetenerPersona.data.statePerson});
          cli.esCliente = respObetenerPersona.data.statePerson;
        } else {
          console.log(respObetenerPersona.message);
        }

        if (!cli.esCliente) {
          alert(
            "Cliente tiene pendiente la aceptacion de Términos y Condiciones"
          );

          /* permite activar los modales de los documentos legales e impide 
        la llamada a los servicio cuando no es necesario */
          setAceptadoTyC(true);
          setAceptadoPPR(true);

          /* cierra el modal de identificacion */
          // setModalClient((args) => ({ ...args, state: false }));

          /* settea los estados de los documentos */
          setStatesLegalDocuments((args) => ({
            ...args,
            statePPR: false,
            stateTYC: false,
          }));

          /* permite abrir el modal PoliticsDetails cuado es necesario firmar los documentos */
          setOpenPoliticsDetails(true);

          //return;
        }

        if (cli.esCliente) {
          const respValidarDocumentos = await validarDocumentosCF(
            identificacion
          );

          if (respValidarDocumentos.state) {
            const boolString = "true";

            cli.documentosFirmados =
              boolString === respValidarDocumentos.data.stateDocuments;

            if (cli.documentosFirmados) {
              alert(
                "Cliente ya tiene firmado los documentos de Términos y Condiciones. Puede continuar"
              );

              setOpenPoliticsDetails(false);
              setLoading(false);

              //setModalClient((args) => ({ ...args, state: false }));
              setStatesLegalDocuments((args) => ({
                ...args,
                statePPR: true,
                stateTYC: true,
              }));

              setAceptadoPPR(true);
              setAceptadoTyC(true);
            } else {
              alert(
                "Cliente tiene pendiente la aceptacion de Términos y Condiciones"
              );
              const documentos = respValidarDocumentos.data.documents;

              /* para habilitar los modales de politicas documents */
              setAceptadoPPR(true);
              setAceptadoTyC(true);

              if (documentos === null) {
                /* cierra el modal de identificacion */
                //setModalClient((args) => ({ ...args, state: false }));

                /* permite abrir el modal PoliticsDetails cuado es necesario firmar los documentos */
                setOpenPoliticsDetails(true);

                /* settea los estados de los documentos */
                setStatesLegalDocuments((args) => ({
                  ...args,
                  statePPR: false,
                  stateTYC: false,
                }));
              } else {
                if (documentos.length < 2) {
                  const tieneTCU = documentos.some(
                    (doc) => doc.contentType === "TCD"
                  );
                  const tienePPR = documentos.some(
                    (doc) => doc.contentType === "PPR"
                  );
                  if (!tieneTCU) {
                    setStatesLegalDocuments((args) => ({
                      ...args,
                      statePPR: false,
                      stateTYC: true,
                    }));
                  }

                  if (!tienePPR) {
                    setStatesLegalDocuments((args) => ({
                      ...args,
                      statePPR: true,
                      stateTYC: false,
                    }));
                  }

                  /* cierra el modal de identificacion */
                  //setModalClient((args) => ({ ...args, state: false }));

                  /* permite abrir el modal PoliticsDetails cuado es necesario firmar los documentos */
                  setOpenPoliticsDetails(true);
                } else {
                  setStatesLegalDocuments((args) => ({
                    ...args,
                    statePPR: false,
                    stateTYC: false,
                  }));

                  /* cierra el modal de identificacion */
                  //setModalClient((args) => ({ ...args, state: false }));

                  /* permite abrir el modal PoliticsDetails cuado es necesario firmar los documentos */
                  setOpenPoliticsDetails(true);
                }
              }
            }
          } else {
            alert(respValidarDocumentos.message);
          }
        }
      } 
      else {

        cli.esCliente =false;
        cli.enviaDatos = true;
        alert(
          "Cliente tiene pendiente la aceptacion de Términos y Condiciones"
        );

        setAceptadoTyC(true);
        setAceptadoPPR(true);

        /* settea los estados de los documentos */
        setStatesLegalDocuments((args) => ({
          ...args,
          statePPR: false,
          stateTYC: false,
        }));

        setOpenPoliticsDetails(true);
      }
    } catch (e) {
      alert(e);
    }

    setClientInformation(cli);
  };

  const grabarDatosAceptacion = async () => {
    try {
      const srvGrabarAceptacion = await grabarAceptacion(datosCli);
      if (srvGrabarAceptacion.state) {
        console.log(srvGrabarAceptacion.data);
      } else {
        alert(srvGrabarAceptacion.message);
      }
    } catch (e) {
      alert(e);
    }
  };

  const validacionInicialCliente = async (identificacion) => {
    setLoadingPreevaluar(true);
    const validarBuro = true;
    try {
      var resp = await preevaluacionCliente(
        identificacion,
        authUser.key,
        authUser.estacion,
        validarBuro
      );
      if (resp.state) {
        console.log(resp);
        if (resp.state) {
          if (resp.data.validacion) {
            //console.log(resp.data.mensaje);
            setMensajeCiente(resp.data.mensaje);
            setMostrarAprobado(true);
            setLoadingPreevaluar(false);
          } else {
            setMensajeCiente(resp.data.mensaje);
            setMostrarNegado(true);
            setLoadingPreevaluar(false);
          }
        } else {
          console.log(resp.message);
          setLoadingPreevaluar(false);
        }
      }
    } catch (e) {
      console.log(e);
      setLoadingPreevaluar(false);
    }
  };

  const routeChange = () => {
    const path = "/credito/flujop";
    history.push(path, { state: { identificacion: identificacionCliente } });
  };

  const handleAceptarPPR = (valor) => async () => {
    console.log("ppr");
    setAceptadoPPR(valor);
    setDatosCli({ ...datosCli, ppr: valor });
    setOpenPPR(false);
  };

  const handleAceptarTyC = (valor) => async () => {
    console.log("tyc");
    setAceptadoTyC(valor);
    setDatosCli({ ...datosCli, tcu: valor });
    setOpenTyC(false);
  };

  useEffect(() => {
    if (aceptadoPPR & aceptadoTyC) {
      grabarDatosAceptacion();
      console.log(datosCli);
    }
  }, [datosCli]);

  const limpiar = (valor) => async () => {
    console.log(valor);
    //setLimpiar(valor);
    //setOpenTyC(false);
  };

  if (!authUser.usuarioFlujos[0]) {
    return (
      <Alert severity="error">
        <Typography>
          Estimado Usuario, usted no puede crear solicitudes de credito, para
          mayor informacion comunicarse con Soporte Técnico - Servimax.
        </Typography>
      </Alert>
    );
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Grid
        rowSpacing={4}
        spacing={5}
        justifyContent="center"
        alignItems={"center"}
        width={{ xs: 1, md: "80%", lg: "60%", xl: "50%" }}
      >
        <Grid item md={7} sm={7} xs={7}>
          <BuscadorPreevaluacion
            traerIdentificacion={traerIdentificacion}
            validarIdentificacionCF={validarIdentificacionCF}
            validado={aceptadoPPR & aceptadoTyC}
            limpiar={limpiar}
            loading={loading}
            loadingPreevaluar={loadingPreevaluar}
          />
        </Grid>
        <Grid hidden={!mostrarAprobado} item md={7} sm={7} xs={7} marginTop={5}>
          <Card>
            <CardContent>
              <Typography variant="body2">
                {mensajeCliente.split("\n").map((row) => (
                  <TableRow id={row} sx={{ "& td": { border: 0 } }}>
                    <TableCell sx={{ padding: 0.7 }}>{row}</TableCell>
                  </TableRow>
                ))}
              </Typography>
            </CardContent>
            <CardActions>
              <SCSGenericButton
                variant="contained"
                onClick={routeChange}
                titulo={"Continuar"}
              />
            </CardActions>
          </Card>
        </Grid>
        <Grid hidden={!mostrarNegado} item md={8} sm={8} xs={8} marginTop={5}>
          <Card>
            <CardContent>
              <Typography variant="body2">{mensajeCliente}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <PoliticsDetails
        open={openPoliticsDetails}
        setOpenPoliticsDetails={setOpenPoliticsDetails}
        titulo={"Politicas de Credito Directo"}
        aceptadoTyC={aceptadoTyC}
        aceptadoPPR={aceptadoPPR}
        statesLegalDocuments={statesLegalDocuments}
        clientInformation={clientInformation}
        setLoadButtonTYC={setLoading}
      />
    </Box>
  );
}

export default memo(PreevaluacionContent);
