import React from 'react';


import AbcIcon from '@mui/icons-material/Abc';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import AccessibleIcon from '@mui/icons-material/Accessible';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import AdbIcon from '@mui/icons-material/Adb';
import AddIcon from '@mui/icons-material/Add';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AddAlarmIcon from '@mui/icons-material/AddAlarm';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AddCardIcon from '@mui/icons-material/AddCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCommentIcon from '@mui/icons-material/AddComment';
//import AddHomeIcon from '@mui/icons-material/AddHome';
//import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import AddLinkIcon from '@mui/icons-material/AddLink';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AddTaskIcon from '@mui/icons-material/AddTask';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import AddchartIcon from '@mui/icons-material/Addchart';
import AdfScannerIcon from '@mui/icons-material/AdfScanner';
import AdjustIcon from '@mui/icons-material/Adjust';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import AirIcon from '@mui/icons-material/Air';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import AirlineSeatFlatAngledIcon from '@mui/icons-material/AirlineSeatFlatAngled';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import AirlineSeatLegroomExtraIcon from '@mui/icons-material/AirlineSeatLegroomExtra';
import AirlineSeatLegroomNormalIcon from '@mui/icons-material/AirlineSeatLegroomNormal';
import AirlineSeatLegroomReducedIcon from '@mui/icons-material/AirlineSeatLegroomReduced';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import AirlinesIcon from '@mui/icons-material/Airlines';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import AirplayIcon from '@mui/icons-material/Airplay';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import AlarmIcon from '@mui/icons-material/Alarm';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AlbumIcon from '@mui/icons-material/Album';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AllOutIcon from '@mui/icons-material/AllOut';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AnchorIcon from '@mui/icons-material/Anchor';
import AndroidIcon from '@mui/icons-material/Android';
import AnimationIcon from '@mui/icons-material/Animation';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import AodIcon from '@mui/icons-material/Aod';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ApiIcon from '@mui/icons-material/Api';
import AppBlockingIcon from '@mui/icons-material/AppBlocking';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import AppleIcon from '@mui/icons-material/Apple';
import ApprovalIcon from '@mui/icons-material/Approval';
import AppsIcon from '@mui/icons-material/Apps';
import AppsOutageIcon from '@mui/icons-material/AppsOutage';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import ArchiveIcon from '@mui/icons-material/Archive';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
//import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import ArticleIcon from '@mui/icons-material/Article';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
//import AssistWalkerIcon from '@mui/icons-material/AssistWalker';
import AssistantIcon from '@mui/icons-material/Assistant';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import AtmIcon from '@mui/icons-material/Atm';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AttachmentIcon from '@mui/icons-material/Attachment';
import AttractionsIcon from '@mui/icons-material/Attractions';
import AttributionIcon from '@mui/icons-material/Attribution';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AutofpsSelectIcon from '@mui/icons-material/AutofpsSelect';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation';
import BackpackIcon from '@mui/icons-material/Backpack';
import BackspaceIcon from '@mui/icons-material/Backspace';
import BackupIcon from '@mui/icons-material/Backup';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import BadgeIcon from '@mui/icons-material/Badge';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import BalanceIcon from '@mui/icons-material/Balance';
import BalconyIcon from '@mui/icons-material/Balcony';
import BallotIcon from '@mui/icons-material/Ballot';
import BarChartIcon from '@mui/icons-material/BarChart';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import BathroomIcon from '@mui/icons-material/Bathroom';
import BathtubIcon from '@mui/icons-material/Bathtub';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery20Icon from '@mui/icons-material/Battery20';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery60Icon from '@mui/icons-material/Battery60';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery90Icon from '@mui/icons-material/Battery90';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import BatteryCharging30Icon from '@mui/icons-material/BatteryCharging30';
import BatteryCharging50Icon from '@mui/icons-material/BatteryCharging50';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import BatteryCharging80Icon from '@mui/icons-material/BatteryCharging80';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatterySaverIcon from '@mui/icons-material/BatterySaver';
import BatteryStdIcon from '@mui/icons-material/BatteryStd';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import BedIcon from '@mui/icons-material/Bed';
import BedroomBabyIcon from '@mui/icons-material/BedroomBaby';
import BedroomChildIcon from '@mui/icons-material/BedroomChild';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import BedtimeOffIcon from '@mui/icons-material/BedtimeOff';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import BentoIcon from '@mui/icons-material/Bento';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';
import BiotechIcon from '@mui/icons-material/Biotech';
import BlenderIcon from '@mui/icons-material/Blender';
//import BlindIcon from '@mui/icons-material/Blind';
//import BlindsIcon from '@mui/icons-material/Blinds';
import BlindsClosedIcon from '@mui/icons-material/BlindsClosed';
import BlockIcon from '@mui/icons-material/Block';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import BluetoothAudioIcon from '@mui/icons-material/BluetoothAudio';
import BluetoothConnectedIcon from '@mui/icons-material/BluetoothConnected';
import BluetoothDisabledIcon from '@mui/icons-material/BluetoothDisabled';
import BluetoothDriveIcon from '@mui/icons-material/BluetoothDrive';
import BluetoothSearchingIcon from '@mui/icons-material/BluetoothSearching';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import BoltIcon from '@mui/icons-material/Bolt';
import BookIcon from '@mui/icons-material/Book';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import BorderBottomIcon from '@mui/icons-material/BorderBottom';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import BorderHorizontalIcon from '@mui/icons-material/BorderHorizontal';
import BorderInnerIcon from '@mui/icons-material/BorderInner';
import BorderLeftIcon from '@mui/icons-material/BorderLeft';
import BorderOuterIcon from '@mui/icons-material/BorderOuter';
import BorderRightIcon from '@mui/icons-material/BorderRight';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import BorderTopIcon from '@mui/icons-material/BorderTop';
import BorderVerticalIcon from '@mui/icons-material/BorderVertical';
import BoyIcon from '@mui/icons-material/Boy';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import BreakfastDiningIcon from '@mui/icons-material/BreakfastDining';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import Brightness6Icon from '@mui/icons-material/Brightness6';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import BrightnessLowIcon from '@mui/icons-material/BrightnessLow';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';
//import BroadcastOnHomeIcon from '@mui/icons-material/BroadcastOnHome';
//import BroadcastOnPersonalIcon from '@mui/icons-material/BroadcastOnPersonal';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import BrunchDiningIcon from '@mui/icons-material/BrunchDining';
import BrushIcon from '@mui/icons-material/Brush';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import BugReportIcon from '@mui/icons-material/BugReport';
import BuildIcon from '@mui/icons-material/Build';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import BungalowIcon from '@mui/icons-material/Bungalow';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import BusAlertIcon from '@mui/icons-material/BusAlert';
import BusinessIcon from '@mui/icons-material/Business';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CabinIcon from '@mui/icons-material/Cabin';
import CableIcon from '@mui/icons-material/Cable';
import CachedIcon from '@mui/icons-material/Cached';
import CakeIcon from '@mui/icons-material/Cake';
import CalculateIcon from '@mui/icons-material/Calculate';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import CameraIcon from '@mui/icons-material/Camera';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import CameraFrontIcon from '@mui/icons-material/CameraFront';
import CameraIndoorIcon from '@mui/icons-material/CameraIndoor';
import CameraOutdoorIcon from '@mui/icons-material/CameraOutdoor';
import CameraRearIcon from '@mui/icons-material/CameraRear';
import CameraRollIcon from '@mui/icons-material/CameraRoll';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import CampaignIcon from '@mui/icons-material/Campaign';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import CarRentalIcon from '@mui/icons-material/CarRental';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import CarpenterIcon from '@mui/icons-material/Carpenter';
import CasesIcon from '@mui/icons-material/Cases';
import CasinoIcon from '@mui/icons-material/Casino';
import CastIcon from '@mui/icons-material/Cast';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import CastleIcon from '@mui/icons-material/Castle';
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import CategoryIcon from '@mui/icons-material/Category';
import CelebrationIcon from '@mui/icons-material/Celebration';
import CellTowerIcon from '@mui/icons-material/CellTower';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import ChairIcon from '@mui/icons-material/Chair';
import ChairAltIcon from '@mui/icons-material/ChairAlt';
import ChaletIcon from '@mui/icons-material/Chalet';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import ChargingStationIcon from '@mui/icons-material/ChargingStation';
import ChatIcon from '@mui/icons-material/Chat';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import ChurchIcon from '@mui/icons-material/Church';
import CircleIcon from '@mui/icons-material/Circle';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import ClassIcon from '@mui/icons-material/Class';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ClearIcon from '@mui/icons-material/Clear';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import ClosedCaptionDisabledIcon from '@mui/icons-material/ClosedCaptionDisabled';
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Co2Icon from '@mui/icons-material/Co2';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import CodeIcon from '@mui/icons-material/Code';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import CoffeeIcon from '@mui/icons-material/Coffee';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
import CollectionsIcon from '@mui/icons-material/Collections';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import ColorizeIcon from '@mui/icons-material/Colorize';
import CommentIcon from '@mui/icons-material/Comment';
import CommentBankIcon from '@mui/icons-material/CommentBank';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import CommitIcon from '@mui/icons-material/Commit';
import CommuteIcon from '@mui/icons-material/Commute';
import CompareIcon from '@mui/icons-material/Compare';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CompassCalibrationIcon from '@mui/icons-material/CompassCalibration';
import CompressIcon from '@mui/icons-material/Compress';
import ComputerIcon from '@mui/icons-material/Computer';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import ConstructionIcon from '@mui/icons-material/Construction';
//import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ContactlessIcon from '@mui/icons-material/Contactless';
import ContactsIcon from '@mui/icons-material/Contacts';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ContrastIcon from '@mui/icons-material/Contrast';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import CookieIcon from '@mui/icons-material/Cookie';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CopyrightIcon from '@mui/icons-material/Copyright';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CottageIcon from '@mui/icons-material/Cottage';
import CountertopsIcon from '@mui/icons-material/Countertops';
import CreateIcon from '@mui/icons-material/Create';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import CribIcon from '@mui/icons-material/Crib';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import CropIcon from '@mui/icons-material/Crop';
import Crop169Icon from '@mui/icons-material/Crop169';
import Crop32Icon from '@mui/icons-material/Crop32';
import Crop54Icon from '@mui/icons-material/Crop54';
import Crop75Icon from '@mui/icons-material/Crop75';
import CropDinIcon from '@mui/icons-material/CropDin';
import CropFreeIcon from '@mui/icons-material/CropFree';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import CssIcon from '@mui/icons-material/Css';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CurrencyFrancIcon from '@mui/icons-material/CurrencyFranc';
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen';
import CurrencyYuanIcon from '@mui/icons-material/CurrencyYuan';
import CurtainsIcon from '@mui/icons-material/Curtains';
import CurtainsClosedIcon from '@mui/icons-material/CurtainsClosed';
import CycloneIcon from '@mui/icons-material/Cyclone';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import DataArrayIcon from '@mui/icons-material/DataArray';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import DataUsageIcon from '@mui/icons-material/DataUsage';
//import DatasetIcon from '@mui/icons-material/Dataset';
//import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeblurIcon from '@mui/icons-material/Deblur';
import DeckIcon from '@mui/icons-material/Deck';
import DehazeIcon from '@mui/icons-material/Dehaze';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import DescriptionIcon from '@mui/icons-material/Description';
import DeselectIcon from '@mui/icons-material/Deselect';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
//mport DeskIcon from '@mui/icons-material/Desk';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import DetailsIcon from '@mui/icons-material/Details';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import DeveloperBoardOffIcon from '@mui/icons-material/DeveloperBoardOff';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import DevicesIcon from '@mui/icons-material/Devices';
import DevicesFoldIcon from '@mui/icons-material/DevicesFold';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import DialerSipIcon from '@mui/icons-material/DialerSip';
import DialpadIcon from '@mui/icons-material/Dialpad';
import DiamondIcon from '@mui/icons-material/Diamond';
import DifferenceIcon from '@mui/icons-material/Difference';
import DiningIcon from '@mui/icons-material/Dining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import DirectionsIcon from '@mui/icons-material/Directions';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import DirectionsOffIcon from '@mui/icons-material/DirectionsOff';
import DirectionsRailwayIcon from '@mui/icons-material/DirectionsRailway';
import DirectionsRailwayFilledIcon from '@mui/icons-material/DirectionsRailwayFilled';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DirectionsSubwayIcon from '@mui/icons-material/DirectionsSubway';
import DirectionsSubwayFilledIcon from '@mui/icons-material/DirectionsSubwayFilled';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import DirectionsTransitFilledIcon from '@mui/icons-material/DirectionsTransitFilled';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirtyLensIcon from '@mui/icons-material/DirtyLens';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import DiscFullIcon from '@mui/icons-material/DiscFull';
import DiscountIcon from '@mui/icons-material/Discount';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
// import Diversity1Icon from '@mui/icons-material/Diversity1';
// import Diversity2Icon from '@mui/icons-material/Diversity2';
// import Diversity3Icon from '@mui/icons-material/Diversity3';
import DnsIcon from '@mui/icons-material/Dns';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import DoNotDisturbOffIcon from '@mui/icons-material/DoNotDisturbOff';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import DoNotDisturbOnTotalSilenceIcon from '@mui/icons-material/DoNotDisturbOnTotalSilence';
import DoNotStepIcon from '@mui/icons-material/DoNotStep';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import DockIcon from '@mui/icons-material/Dock';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DomainIcon from '@mui/icons-material/Domain';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import DoorSlidingIcon from '@mui/icons-material/DoorSliding';
import DoorbellIcon from '@mui/icons-material/Doorbell';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DownloadingIcon from '@mui/icons-material/Downloading';
import DraftsIcon from '@mui/icons-material/Drafts';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DryIcon from '@mui/icons-material/Dry';
import DryCleaningIcon from '@mui/icons-material/DryCleaning';
import DuoIcon from '@mui/icons-material/Duo';
import DvrIcon from '@mui/icons-material/Dvr';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import EMobiledataIcon from '@mui/icons-material/EMobiledata';
import EarbudsIcon from '@mui/icons-material/Earbuds';
import EarbudsBatteryIcon from '@mui/icons-material/EarbudsBattery';
import EastIcon from '@mui/icons-material/East';
import EdgesensorHighIcon from '@mui/icons-material/EdgesensorHigh';
import EdgesensorLowIcon from '@mui/icons-material/EdgesensorLow';
import EditIcon from '@mui/icons-material/Edit';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import EditOffIcon from '@mui/icons-material/EditOff';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import EggIcon from '@mui/icons-material/Egg';
import EggAltIcon from '@mui/icons-material/EggAlt';
import EightKIcon from '@mui/icons-material/EightK';
import EightKPlusIcon from '@mui/icons-material/EightKPlus';
import EightMpIcon from '@mui/icons-material/EightMp';
import EighteenMpIcon from '@mui/icons-material/EighteenMp';
//import EighteenUpRatingIcon from '@mui/icons-material/EighteenUpRating';
import EightteenMpIcon from '@mui/icons-material/EightteenMp';
import EjectIcon from '@mui/icons-material/Eject';
import ElderlyIcon from '@mui/icons-material/Elderly';
import ElderlyWomanIcon from '@mui/icons-material/ElderlyWoman';
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import ElectricMopedIcon from '@mui/icons-material/ElectricMoped';
import ElectricRickshawIcon from '@mui/icons-material/ElectricRickshaw';
import ElectricScooterIcon from '@mui/icons-material/ElectricScooter';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import ElevatorIcon from '@mui/icons-material/Elevator';
import ElevenMpIcon from '@mui/icons-material/ElevenMp';
import EmailIcon from '@mui/icons-material/Email';
import EmergencyRecordingIcon from '@mui/icons-material/EmergencyRecording';
import EmergencyShareIcon from '@mui/icons-material/EmergencyShare';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import EmojiSymbolsIcon from '@mui/icons-material/EmojiSymbols';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import EngineeringIcon from '@mui/icons-material/Engineering';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EscalatorIcon from '@mui/icons-material/Escalator';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import EuroIcon from '@mui/icons-material/Euro';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import EvStationIcon from '@mui/icons-material/EvStation';
import EventIcon from '@mui/icons-material/Event';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandIcon from '@mui/icons-material/Expand';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExplicitIcon from '@mui/icons-material/Explicit';
import ExploreIcon from '@mui/icons-material/Explore';
import ExploreOffIcon from '@mui/icons-material/ExploreOff';
import ExposureIcon from '@mui/icons-material/Exposure';
import ExtensionIcon from '@mui/icons-material/Extension';
import ExtensionOffIcon from '@mui/icons-material/ExtensionOff';
// import FaceIcon from '@mui/icons-material/Face';
// import Face2Icon from '@mui/icons-material/Face2';
// import Face3Icon from '@mui/icons-material/Face3';
// import Face4Icon from '@mui/icons-material/Face4';
// import Face5Icon from '@mui/icons-material/Face5';
// import Face6Icon from '@mui/icons-material/Face6';
// import Face6Icon from '@mui/icons-material/Face6';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff';
import FacebookIcon from '@mui/icons-material/Facebook';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FactoryIcon from '@mui/icons-material/Factory';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FaxIcon from '@mui/icons-material/Fax';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import FeedIcon from '@mui/icons-material/Feed';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FemaleIcon from '@mui/icons-material/Female';
import FenceIcon from '@mui/icons-material/Fence';
import FestivalIcon from '@mui/icons-material/Festival';
import FiberDvrIcon from '@mui/icons-material/FiberDvr';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import FiberPinIcon from '@mui/icons-material/FiberPin';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import FifteenMpIcon from '@mui/icons-material/FifteenMp';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FilterIcon from '@mui/icons-material/Filter';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';
import Filter7Icon from '@mui/icons-material/Filter7';
import Filter8Icon from '@mui/icons-material/Filter8';
import Filter9Icon from '@mui/icons-material/Filter9';
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterBAndWIcon from '@mui/icons-material/FilterBAndW';
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import FilterHdrIcon from '@mui/icons-material/FilterHdr';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import FilterTiltShiftIcon from '@mui/icons-material/FilterTiltShift';
import FilterVintageIcon from '@mui/icons-material/FilterVintage';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import FireExtinguisherIcon from '@mui/icons-material/FireExtinguisher';
//import FireHydrantAltIcon from '@mui/icons-material/FireHydrantAlt';
//import FireTruckIcon from '@mui/icons-material/FireTruck';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import FitbitIcon from '@mui/icons-material/Fitbit';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import FiveGIcon from '@mui/icons-material/FiveG';
import FiveKIcon from '@mui/icons-material/FiveK';
import FiveKPlusIcon from '@mui/icons-material/FiveKPlus';
import FiveMpIcon from '@mui/icons-material/FiveMp';
import FivteenMpIcon from '@mui/icons-material/FivteenMp';
import FlagIcon from '@mui/icons-material/Flag';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import FlakyIcon from '@mui/icons-material/Flaky';
import FlareIcon from '@mui/icons-material/Flare';
import FlashAutoIcon from '@mui/icons-material/FlashAuto';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FlashlightOffIcon from '@mui/icons-material/FlashlightOff';
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn';
import FlatwareIcon from '@mui/icons-material/Flatware';
import FlightIcon from '@mui/icons-material/Flight';
import FlightClassIcon from '@mui/icons-material/FlightClass';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlipIcon from '@mui/icons-material/Flip';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import FloodIcon from '@mui/icons-material/Flood';
//import FluorescentIcon from '@mui/icons-material/Fluorescent';
import FlutterDashIcon from '@mui/icons-material/FlutterDash';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import FolderIcon from '@mui/icons-material/Folder';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import FontDownloadOffIcon from '@mui/icons-material/FontDownloadOff';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import ForestIcon from '@mui/icons-material/Forest';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import FormatOverlineIcon from '@mui/icons-material/FormatOverline';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatTextdirectionLToRIcon from '@mui/icons-material/FormatTextdirectionLToR';
import FormatTextdirectionRToLIcon from '@mui/icons-material/FormatTextdirectionRToL';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FortIcon from '@mui/icons-material/Fort';
import ForumIcon from '@mui/icons-material/Forum';
import ForwardIcon from '@mui/icons-material/Forward';
import Forward10Icon from '@mui/icons-material/Forward10';
import Forward30Icon from '@mui/icons-material/Forward30';
import Forward5Icon from '@mui/icons-material/Forward5';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import FoundationIcon from '@mui/icons-material/Foundation';
import FourGMobiledataIcon from '@mui/icons-material/FourGMobiledata';
import FourGPlusMobiledataIcon from '@mui/icons-material/FourGPlusMobiledata';
import FourKIcon from '@mui/icons-material/FourK';
import FourKPlusIcon from '@mui/icons-material/FourKPlus';
import FourMpIcon from '@mui/icons-material/FourMp';
import FourteenMpIcon from '@mui/icons-material/FourteenMp';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FunctionsIcon from '@mui/icons-material/Functions';
import GMobiledataIcon from '@mui/icons-material/GMobiledata';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import GamepadIcon from '@mui/icons-material/Gamepad';
import GamesIcon from '@mui/icons-material/Games';
import GarageIcon from '@mui/icons-material/Garage';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import GavelIcon from '@mui/icons-material/Gavel';
import GestureIcon from '@mui/icons-material/Gesture';
import GetAppIcon from '@mui/icons-material/GetApp';
import GifIcon from '@mui/icons-material/Gif';
import GifBoxIcon from '@mui/icons-material/GifBox';
import GirlIcon from '@mui/icons-material/Girl';
import GitHubIcon from '@mui/icons-material/GitHub';
import GiteIcon from '@mui/icons-material/Gite';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import GoogleIcon from '@mui/icons-material/Google';
import GppBadIcon from '@mui/icons-material/GppBad';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import GpsOffIcon from '@mui/icons-material/GpsOff';
import GradeIcon from '@mui/icons-material/Grade';
import GradientIcon from '@mui/icons-material/Gradient';
import GradingIcon from '@mui/icons-material/Grading';
import GrainIcon from '@mui/icons-material/Grain';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import GrassIcon from '@mui/icons-material/Grass';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import GridGoldenratioIcon from '@mui/icons-material/GridGoldenratio';
import GridOffIcon from '@mui/icons-material/GridOff';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridViewIcon from '@mui/icons-material/GridView';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import GroupsIcon from '@mui/icons-material/Groups';
// import Groups2Icon from '@mui/icons-material/Groups2';
// import Groups3Icon from '@mui/icons-material/Groups3';
import HMobiledataIcon from '@mui/icons-material/HMobiledata';
import HPlusMobiledataIcon from '@mui/icons-material/HPlusMobiledata';
import HailIcon from '@mui/icons-material/Hail';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HandymanIcon from '@mui/icons-material/Handyman';
import HardwareIcon from '@mui/icons-material/Hardware';
import HdIcon from '@mui/icons-material/Hd';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';
import HdrAutoSelectIcon from '@mui/icons-material/HdrAutoSelect';
import HdrEnhancedSelectIcon from '@mui/icons-material/HdrEnhancedSelect';
import HdrOffIcon from '@mui/icons-material/HdrOff';
import HdrOffSelectIcon from '@mui/icons-material/HdrOffSelect';
import HdrOnIcon from '@mui/icons-material/HdrOn';
import HdrOnSelectIcon from '@mui/icons-material/HdrOnSelect';
import HdrPlusIcon from '@mui/icons-material/HdrPlus';
import HdrStrongIcon from '@mui/icons-material/HdrStrong';
import HdrWeakIcon from '@mui/icons-material/HdrWeak';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import HeadphonesBatteryIcon from '@mui/icons-material/HeadphonesBattery';
import HeadsetIcon from '@mui/icons-material/Headset';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import HeadsetOffIcon from '@mui/icons-material/HeadsetOff';
import HealingIcon from '@mui/icons-material/Healing';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import HearingIcon from '@mui/icons-material/Hearing';
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import HeightIcon from '@mui/icons-material/Height';
import HelpIcon from '@mui/icons-material/Help';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HevcIcon from '@mui/icons-material/Hevc';
import HexagonIcon from '@mui/icons-material/Hexagon';
import HideImageIcon from '@mui/icons-material/HideImage';
import HideSourceIcon from '@mui/icons-material/HideSource';
import HighQualityIcon from '@mui/icons-material/HighQuality';
import HighlightIcon from '@mui/icons-material/Highlight';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HikingIcon from '@mui/icons-material/Hiking';
import HistoryIcon from '@mui/icons-material/History';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import HiveIcon from '@mui/icons-material/Hive';
import HlsIcon from '@mui/icons-material/Hls';
import HlsOffIcon from '@mui/icons-material/HlsOff';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import HomeIcon from '@mui/icons-material/Home';
import HomeMaxIcon from '@mui/icons-material/HomeMax';
import HomeMiniIcon from '@mui/icons-material/HomeMini';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import HotTubIcon from '@mui/icons-material/HotTub';
import HotelIcon from '@mui/icons-material/Hotel';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import HouseIcon from '@mui/icons-material/House';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import HouseboatIcon from '@mui/icons-material/Houseboat';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import HtmlIcon from '@mui/icons-material/Html';
import HttpIcon from '@mui/icons-material/Http';
import HttpsIcon from '@mui/icons-material/Https';
import HubIcon from '@mui/icons-material/Hub';
import HvacIcon from '@mui/icons-material/Hvac';
import IceSkatingIcon from '@mui/icons-material/IceSkating';
import IcecreamIcon from '@mui/icons-material/Icecream';
import ImageIcon from '@mui/icons-material/Image';
import ImageAspectRatioIcon from '@mui/icons-material/ImageAspectRatio';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ImagesearchRollerIcon from '@mui/icons-material/ImagesearchRoller';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import InboxIcon from '@mui/icons-material/Inbox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import InfoIcon from '@mui/icons-material/Info';
import InputIcon from '@mui/icons-material/Input';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import InsightsIcon from '@mui/icons-material/Insights';
import InstagramIcon from '@mui/icons-material/Instagram';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import InterestsIcon from '@mui/icons-material/Interests';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import InventoryIcon from '@mui/icons-material/Inventory';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import InvertColorsOffIcon from '@mui/icons-material/InvertColorsOff';
import IosShareIcon from '@mui/icons-material/IosShare';
import IronIcon from '@mui/icons-material/Iron';
import IsoIcon from '@mui/icons-material/Iso';
import JavascriptIcon from '@mui/icons-material/Javascript';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import JoinRightIcon from '@mui/icons-material/JoinRight';
import KayakingIcon from '@mui/icons-material/Kayaking';
import KebabDiningIcon from '@mui/icons-material/KebabDining';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import KeyboardAltIcon from '@mui/icons-material/KeyboardAlt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardHideIcon from '@mui/icons-material/KeyboardHide';
import KeyboardOptionKeyIcon from '@mui/icons-material/KeyboardOptionKey';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import KingBedIcon from '@mui/icons-material/KingBed';
import KitchenIcon from '@mui/icons-material/Kitchen';
import KitesurfingIcon from '@mui/icons-material/Kitesurfing';
import LabelIcon from '@mui/icons-material/Label';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import LabelOffIcon from '@mui/icons-material/LabelOff';
import LanIcon from '@mui/icons-material/Lan';
import LandscapeIcon from '@mui/icons-material/Landscape';
import LandslideIcon from '@mui/icons-material/Landslide';
import LanguageIcon from '@mui/icons-material/Language';
import LaptopIcon from '@mui/icons-material/Laptop';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import LastPageIcon from '@mui/icons-material/LastPage';
import LaunchIcon from '@mui/icons-material/Launch';
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import LeakRemoveIcon from '@mui/icons-material/LeakRemove';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import LensIcon from '@mui/icons-material/Lens';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import LightIcon from '@mui/icons-material/Light';
import LightModeIcon from '@mui/icons-material/LightMode';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LiquorIcon from '@mui/icons-material/Liquor';
import ListIcon from '@mui/icons-material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import LivingIcon from '@mui/icons-material/Living';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import LocalPlayIcon from '@mui/icons-material/LocalPlay';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import LocalSeeIcon from '@mui/icons-material/LocalSee';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import LockIcon from '@mui/icons-material/Lock';
import LockClockIcon from '@mui/icons-material/LockClock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
//import LockPersonIcon from '@mui/icons-material/LockPerson';
import LockResetIcon from '@mui/icons-material/LockReset';
import LoginIcon from '@mui/icons-material/Login';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import LogoutIcon from '@mui/icons-material/Logout';
import LooksIcon from '@mui/icons-material/Looks';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import LoopIcon from '@mui/icons-material/Loop';
import LoupeIcon from '@mui/icons-material/Loupe';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import LteMobiledataIcon from '@mui/icons-material/LteMobiledata';
import LtePlusMobiledataIcon from '@mui/icons-material/LtePlusMobiledata';
import LuggageIcon from '@mui/icons-material/Luggage';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import LyricsIcon from '@mui/icons-material/Lyrics';
//import MacroOffIcon from '@mui/icons-material/MacroOff';
import MailIcon from '@mui/icons-material/Mail';
import MailLockIcon from '@mui/icons-material/MailLock';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MaleIcon from '@mui/icons-material/Male';
import ManIcon from '@mui/icons-material/Man';
// import Man2Icon from '@mui/icons-material/Man2';
// import Man3Icon from '@mui/icons-material/Man3';
// import Man4Icon from '@mui/icons-material/Man4';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MapIcon from '@mui/icons-material/Map';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import MarginIcon from '@mui/icons-material/Margin';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import MasksIcon from '@mui/icons-material/Masks';
import MaximizeIcon from '@mui/icons-material/Maximize';
import MediaBluetoothOffIcon from '@mui/icons-material/MediaBluetoothOff';
import MediaBluetoothOnIcon from '@mui/icons-material/MediaBluetoothOn';
import MediationIcon from '@mui/icons-material/Mediation';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import MedicationIcon from '@mui/icons-material/Medication';
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MemoryIcon from '@mui/icons-material/Memory';
import MenuIcon from '@mui/icons-material/Menu';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MergeIcon from '@mui/icons-material/Merge';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import MessageIcon from '@mui/icons-material/Message';
import MicIcon from '@mui/icons-material/Mic';
import MicExternalOffIcon from '@mui/icons-material/MicExternalOff';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';
import MicNoneIcon from '@mui/icons-material/MicNone';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
import MmsIcon from '@mui/icons-material/Mms';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import MobileOffIcon from '@mui/icons-material/MobileOff';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import ModeIcon from '@mui/icons-material/Mode';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import ModeFanOffIcon from '@mui/icons-material/ModeFanOff';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MoneyIcon from '@mui/icons-material/Money';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import MoneyOffCsredIcon from '@mui/icons-material/MoneyOffCsred';
import MonitorIcon from '@mui/icons-material/Monitor';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import MonochromePhotosIcon from '@mui/icons-material/MonochromePhotos';
import MoodIcon from '@mui/icons-material/Mood';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import MopedIcon from '@mui/icons-material/Moped';
import MoreIcon from '@mui/icons-material/More';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MosqueIcon from '@mui/icons-material/Mosque';
import MotionPhotosAutoIcon from '@mui/icons-material/MotionPhotosAuto';
import MotionPhotosOffIcon from '@mui/icons-material/MotionPhotosOff';
import MouseIcon from '@mui/icons-material/Mouse';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import MovieIcon from '@mui/icons-material/Movie';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import MovingIcon from '@mui/icons-material/Moving';
import MpIcon from '@mui/icons-material/Mp';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import MuseumIcon from '@mui/icons-material/Museum';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MusicOffIcon from '@mui/icons-material/MusicOff';
import MusicVideoIcon from '@mui/icons-material/MusicVideo';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import NatIcon from '@mui/icons-material/Nat';
import NatureIcon from '@mui/icons-material/Nature';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigationIcon from '@mui/icons-material/Navigation';
import NearMeIcon from '@mui/icons-material/NearMe';
import NearMeDisabledIcon from '@mui/icons-material/NearMeDisabled';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import NearbyOffIcon from '@mui/icons-material/NearbyOff';
import NestCamWiredStandIcon from '@mui/icons-material/NestCamWiredStand';
import NetworkCellIcon from '@mui/icons-material/NetworkCell';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import NetworkLockedIcon from '@mui/icons-material/NetworkLocked';
import NetworkPingIcon from '@mui/icons-material/NetworkPing';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import NetworkWifi1BarIcon from '@mui/icons-material/NetworkWifi1Bar';
import NetworkWifi2BarIcon from '@mui/icons-material/NetworkWifi2Bar';
import NetworkWifi3BarIcon from '@mui/icons-material/NetworkWifi3Bar';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import NfcIcon from '@mui/icons-material/Nfc';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import NightlifeIcon from '@mui/icons-material/Nightlife';
import NightlightIcon from '@mui/icons-material/Nightlight';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import NineKIcon from '@mui/icons-material/NineK';
import NineKPlusIcon from '@mui/icons-material/NineKPlus';
import NineMpIcon from '@mui/icons-material/NineMp';
import NineteenMpIcon from '@mui/icons-material/NineteenMp';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
//import NoAdultContentIcon from '@mui/icons-material/NoAdultContent';
import NoBackpackIcon from '@mui/icons-material/NoBackpack';
import NoCellIcon from '@mui/icons-material/NoCell';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import NoDrinksIcon from '@mui/icons-material/NoDrinks';
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import NoFlashIcon from '@mui/icons-material/NoFlash';
import NoFoodIcon from '@mui/icons-material/NoFood';
import NoLuggageIcon from '@mui/icons-material/NoLuggage';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import NoMeetingRoomIcon from '@mui/icons-material/NoMeetingRoom';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import NoSimIcon from '@mui/icons-material/NoSim';
import NoStrollerIcon from '@mui/icons-material/NoStroller';
import NoTransferIcon from '@mui/icons-material/NoTransfer';
import NoiseAwareIcon from '@mui/icons-material/NoiseAware';
import NoiseControlOffIcon from '@mui/icons-material/NoiseControlOff';
import NordicWalkingIcon from '@mui/icons-material/NordicWalking';
import NorthIcon from '@mui/icons-material/North';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import NorthWestIcon from '@mui/icons-material/NorthWest';
import NotAccessibleIcon from '@mui/icons-material/NotAccessible';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import NoteIcon from '@mui/icons-material/Note';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import NotesIcon from '@mui/icons-material/Notes';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import NumbersIcon from '@mui/icons-material/Numbers';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import OfflineShareIcon from '@mui/icons-material/OfflineShare';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import OneKIcon from '@mui/icons-material/OneK';
import OneKPlusIcon from '@mui/icons-material/OneKPlus';
import OneKkIcon from '@mui/icons-material/OneKk';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import OpacityIcon from '@mui/icons-material/Opacity';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import OutboundIcon from '@mui/icons-material/Outbound';
import OutboxIcon from '@mui/icons-material/Outbox';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import OutletIcon from '@mui/icons-material/Outlet';
import OutputIcon from '@mui/icons-material/Output';
import PaddingIcon from '@mui/icons-material/Padding';
import PagesIcon from '@mui/icons-material/Pages';
import PageviewIcon from '@mui/icons-material/Pageview';
import PaidIcon from '@mui/icons-material/Paid';
import PaletteIcon from '@mui/icons-material/Palette';
import PanToolIcon from '@mui/icons-material/PanTool';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import PanoramaIcon from '@mui/icons-material/Panorama';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import PanoramaHorizontalIcon from '@mui/icons-material/PanoramaHorizontal';
import PanoramaHorizontalSelectIcon from '@mui/icons-material/PanoramaHorizontalSelect';
import PanoramaPhotosphereIcon from '@mui/icons-material/PanoramaPhotosphere';
import PanoramaPhotosphereSelectIcon from '@mui/icons-material/PanoramaPhotosphereSelect';
import PanoramaVerticalIcon from '@mui/icons-material/PanoramaVertical';
import PanoramaVerticalSelectIcon from '@mui/icons-material/PanoramaVerticalSelect';
import PanoramaWideAngleIcon from '@mui/icons-material/PanoramaWideAngle';
import PanoramaWideAngleSelectIcon from '@mui/icons-material/PanoramaWideAngleSelect';
import ParaglidingIcon from '@mui/icons-material/Paragliding';
import ParkIcon from '@mui/icons-material/Park';
import PartyModeIcon from '@mui/icons-material/PartyMode';
import PasswordIcon from '@mui/icons-material/Password';
import PatternIcon from '@mui/icons-material/Pattern';
import PauseIcon from '@mui/icons-material/Pause';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PausePresentationIcon from '@mui/icons-material/PausePresentation';
import PaymentIcon from '@mui/icons-material/Payment';
import PaymentsIcon from '@mui/icons-material/Payments';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import PendingIcon from '@mui/icons-material/Pending';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PentagonIcon from '@mui/icons-material/Pentagon';
import PeopleIcon from '@mui/icons-material/People';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PercentIcon from '@mui/icons-material/Percent';
import PermCameraMicIcon from '@mui/icons-material/PermCameraMic';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PermScanWifiIcon from '@mui/icons-material/PermScanWifi';
import PersonIcon from '@mui/icons-material/Person';
// import Person2Icon from '@mui/icons-material/Person2';
// import Person3Icon from '@mui/icons-material/Person3';
// import Person4Icon from '@mui/icons-material/Person4';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import PestControlIcon from '@mui/icons-material/PestControl';
import PestControlRodentIcon from '@mui/icons-material/PestControlRodent';
import PetsIcon from '@mui/icons-material/Pets';
import PhishingIcon from '@mui/icons-material/Phishing';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneBluetoothSpeakerIcon from '@mui/icons-material/PhoneBluetoothSpeaker';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PhoneLockedIcon from '@mui/icons-material/PhoneLocked';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import PhotoIcon from '@mui/icons-material/Photo';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import PhotoCameraFrontIcon from '@mui/icons-material/PhotoCameraFront';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';
import PhpIcon from '@mui/icons-material/Php';
import PianoIcon from '@mui/icons-material/Piano';
import PianoOffIcon from '@mui/icons-material/PianoOff';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import PieChartIcon from '@mui/icons-material/PieChart';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import PinIcon from '@mui/icons-material/Pin';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PinchIcon from '@mui/icons-material/Pinch';
import PinterestIcon from '@mui/icons-material/Pinterest';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import PixIcon from '@mui/icons-material/Pix';
import PlaceIcon from '@mui/icons-material/Place';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PlayDisabledIcon from '@mui/icons-material/PlayDisabled';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import PlayLessonIcon from '@mui/icons-material/PlayLesson';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PolicyIcon from '@mui/icons-material/Policy';
import PollIcon from '@mui/icons-material/Poll';
import PolylineIcon from '@mui/icons-material/Polyline';
import PoolIcon from '@mui/icons-material/Pool';
import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';
import PortraitIcon from '@mui/icons-material/Portrait';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PowerIcon from '@mui/icons-material/Power';
import PowerInputIcon from '@mui/icons-material/PowerInput';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import PreviewIcon from '@mui/icons-material/Preview';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PrintIcon from '@mui/icons-material/Print';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import PropaneIcon from '@mui/icons-material/Propane';
import PropaneTankIcon from '@mui/icons-material/PropaneTank';
import PsychologyIcon from '@mui/icons-material/Psychology';
//import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import PublishIcon from '@mui/icons-material/Publish';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import PushPinIcon from '@mui/icons-material/PushPin';
import QrCodeIcon from '@mui/icons-material/QrCode';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import QueueIcon from '@mui/icons-material/Queue';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import QuizIcon from '@mui/icons-material/Quiz';
import RMobiledataIcon from '@mui/icons-material/RMobiledata';
import RadarIcon from '@mui/icons-material/Radar';
import RadioIcon from '@mui/icons-material/Radio';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RailwayAlertIcon from '@mui/icons-material/RailwayAlert';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import RampLeftIcon from '@mui/icons-material/RampLeft';
import RampRightIcon from '@mui/icons-material/RampRight';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RawOffIcon from '@mui/icons-material/RawOff';
import RawOnIcon from '@mui/icons-material/RawOn';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import RecommendIcon from '@mui/icons-material/Recommend';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import RectangleIcon from '@mui/icons-material/Rectangle';
import RedditIcon from '@mui/icons-material/Reddit';
import RedeemIcon from '@mui/icons-material/Redeem';
import RedoIcon from '@mui/icons-material/Redo';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import RefreshIcon from '@mui/icons-material/Refresh';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import RemoveIcon from '@mui/icons-material/Remove';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import RemoveFromQueueIcon from '@mui/icons-material/RemoveFromQueue';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import ReorderIcon from '@mui/icons-material/Reorder';
//import RepartitionIcon from '@mui/icons-material/Repartition';
import RepeatIcon from '@mui/icons-material/Repeat';
import RepeatOnIcon from '@mui/icons-material/RepeatOn';
import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import RepeatOneOnIcon from '@mui/icons-material/RepeatOneOn';
import ReplayIcon from '@mui/icons-material/Replay';
import Replay10Icon from '@mui/icons-material/Replay10';
import Replay30Icon from '@mui/icons-material/Replay30';
import Replay5Icon from '@mui/icons-material/Replay5';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import ReportIcon from '@mui/icons-material/Report';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ReportOffIcon from '@mui/icons-material/ReportOff';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ResetTvIcon from '@mui/icons-material/ResetTv';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import RestoreIcon from '@mui/icons-material/Restore';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import ReviewsIcon from '@mui/icons-material/Reviews';
import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import RocketIcon from '@mui/icons-material/Rocket';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import RollerShadesIcon from '@mui/icons-material/RollerShades';
import RollerShadesClosedIcon from '@mui/icons-material/RollerShadesClosed';
import RollerSkatingIcon from '@mui/icons-material/RollerSkating';
import RoofingIcon from '@mui/icons-material/Roofing';
import RoomIcon from '@mui/icons-material/Room';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RoundaboutLeftIcon from '@mui/icons-material/RoundaboutLeft';
import RoundaboutRightIcon from '@mui/icons-material/RoundaboutRight';
import RouteIcon from '@mui/icons-material/Route';
import RouterIcon from '@mui/icons-material/Router';
import RowingIcon from '@mui/icons-material/Rowing';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import RsvpIcon from '@mui/icons-material/Rsvp';
import RttIcon from '@mui/icons-material/Rtt';
import RuleIcon from '@mui/icons-material/Rule';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import SailingIcon from '@mui/icons-material/Sailing';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import SatelliteIcon from '@mui/icons-material/Satellite';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import SaveIcon from '@mui/icons-material/Save';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import SavingsIcon from '@mui/icons-material/Savings';
import ScaleIcon from '@mui/icons-material/Scale';
import ScannerIcon from '@mui/icons-material/Scanner';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SchemaIcon from '@mui/icons-material/Schema';
import SchoolIcon from '@mui/icons-material/School';
import ScienceIcon from '@mui/icons-material/Science';
import ScoreIcon from '@mui/icons-material/Score';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import ScreenLockLandscapeIcon from '@mui/icons-material/ScreenLockLandscape';
import ScreenLockPortraitIcon from '@mui/icons-material/ScreenLockPortrait';
import ScreenLockRotationIcon from '@mui/icons-material/ScreenLockRotation';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import ScreenRotationAltIcon from '@mui/icons-material/ScreenRotationAlt';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import ScreenshotIcon from '@mui/icons-material/Screenshot';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import ScubaDivingIcon from '@mui/icons-material/ScubaDiving';
import SdIcon from '@mui/icons-material/Sd';
import SdCardIcon from '@mui/icons-material/SdCard';
import SdCardAlertIcon from '@mui/icons-material/SdCardAlert';
import SdStorageIcon from '@mui/icons-material/SdStorage';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import SecurityIcon from '@mui/icons-material/Security';
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';
import SecurityUpdateGoodIcon from '@mui/icons-material/SecurityUpdateGood';
import SecurityUpdateWarningIcon from '@mui/icons-material/SecurityUpdateWarning';
import SegmentIcon from '@mui/icons-material/Segment';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import SellIcon from '@mui/icons-material/Sell';
import SendIcon from '@mui/icons-material/Send';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import SensorDoorIcon from '@mui/icons-material/SensorDoor';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import SensorWindowIcon from '@mui/icons-material/SensorWindow';
import SensorsIcon from '@mui/icons-material/Sensors';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SetMealIcon from '@mui/icons-material/SetMeal';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import SettingsBluetoothIcon from '@mui/icons-material/SettingsBluetooth';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import SettingsCellIcon from '@mui/icons-material/SettingsCell';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';
import SettingsInputHdmiIcon from '@mui/icons-material/SettingsInputHdmi';
import SettingsInputSvideoIcon from '@mui/icons-material/SettingsInputSvideo';
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import SevenKIcon from '@mui/icons-material/SevenK';
import SevenKPlusIcon from '@mui/icons-material/SevenKPlus';
import SevenMpIcon from '@mui/icons-material/SevenMp';
import SeventeenMpIcon from '@mui/icons-material/SeventeenMp';
import SevereColdIcon from '@mui/icons-material/SevereCold';
//import ShapeLineIcon from '@mui/icons-material/ShapeLine';
import ShareIcon from '@mui/icons-material/Share';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import ShieldIcon from '@mui/icons-material/Shield';
import ShieldMoonIcon from '@mui/icons-material/ShieldMoon';
import ShopIcon from '@mui/icons-material/Shop';
import Shop2Icon from '@mui/icons-material/Shop2';
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ShortTextIcon from '@mui/icons-material/ShortText';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ShowerIcon from '@mui/icons-material/Shower';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import ShuffleOnIcon from '@mui/icons-material/ShuffleOn';
import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';
import SickIcon from '@mui/icons-material/Sick';
import SignLanguageIcon from '@mui/icons-material/SignLanguage';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import SignalCellularAlt1BarIcon from '@mui/icons-material/SignalCellularAlt1Bar';
import SignalCellularAlt2BarIcon from '@mui/icons-material/SignalCellularAlt2Bar';
import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';
import SignalCellularConnectedNoInternet1BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet1Bar';
import SignalCellularConnectedNoInternet2BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet2Bar';
import SignalCellularConnectedNoInternet3BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet3Bar';
import SignalCellularConnectedNoInternet4BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet4Bar';
import SignalCellularNoSimIcon from '@mui/icons-material/SignalCellularNoSim';
import SignalCellularNodataIcon from '@mui/icons-material/SignalCellularNodata';
import SignalCellularNullIcon from '@mui/icons-material/SignalCellularNull';
import SignalCellularOffIcon from '@mui/icons-material/SignalCellularOff';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';
import SignalWifi1BarIcon from '@mui/icons-material/SignalWifi1Bar';
import SignalWifi1BarLockIcon from '@mui/icons-material/SignalWifi1BarLock';
import SignalWifi2BarIcon from '@mui/icons-material/SignalWifi2Bar';
import SignalWifi2BarLockIcon from '@mui/icons-material/SignalWifi2BarLock';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';
import SignalWifi3BarLockIcon from '@mui/icons-material/SignalWifi3BarLock';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import SignalWifi4BarLockIcon from '@mui/icons-material/SignalWifi4BarLock';
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';
import SignalWifiStatusbarNullIcon from '@mui/icons-material/SignalWifiStatusbarNull';
import SignpostIcon from '@mui/icons-material/Signpost';
import SimCardIcon from '@mui/icons-material/SimCard';
import SimCardAlertIcon from '@mui/icons-material/SimCardAlert';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import SipIcon from '@mui/icons-material/Sip';
import SixKIcon from '@mui/icons-material/SixK';
import SixKPlusIcon from '@mui/icons-material/SixKPlus';
import SixMpIcon from '@mui/icons-material/SixMp';
import SixteenMpIcon from '@mui/icons-material/SixteenMp';
import SixtyFpsIcon from '@mui/icons-material/SixtyFps';
import SixtyFpsSelectIcon from '@mui/icons-material/SixtyFpsSelect';
import SkateboardingIcon from '@mui/icons-material/Skateboarding';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SleddingIcon from '@mui/icons-material/Sledding';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import SmartScreenIcon from '@mui/icons-material/SmartScreen';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import SmokeFreeIcon from '@mui/icons-material/SmokeFree';
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';
import SmsIcon from '@mui/icons-material/Sms';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import SnoozeIcon from '@mui/icons-material/Snooze';
import SnowboardingIcon from '@mui/icons-material/Snowboarding';
import SnowmobileIcon from '@mui/icons-material/Snowmobile';
import SnowshoeingIcon from '@mui/icons-material/Snowshoeing';
import SoapIcon from '@mui/icons-material/Soap';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import SortIcon from '@mui/icons-material/Sort';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import SosIcon from '@mui/icons-material/Sos';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import SourceIcon from '@mui/icons-material/Source';
import SouthIcon from '@mui/icons-material/South';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import SpaIcon from '@mui/icons-material/Spa';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import SpatialAudioIcon from '@mui/icons-material/SpatialAudio';
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';
import SpeakerIcon from '@mui/icons-material/Speaker';
import SpeakerGroupIcon from '@mui/icons-material/SpeakerGroup';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import SpeakerPhoneIcon from '@mui/icons-material/SpeakerPhone';
import SpeedIcon from '@mui/icons-material/Speed';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import SpokeIcon from '@mui/icons-material/Spoke';
import SportsIcon from '@mui/icons-material/Sports';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsGolfIcon from '@mui/icons-material/SportsGolf';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import SportsRugbyIcon from '@mui/icons-material/SportsRugby';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import SquareIcon from '@mui/icons-material/Square';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import StadiumIcon from '@mui/icons-material/Stadium';
import StairsIcon from '@mui/icons-material/Stairs';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import StarRateIcon from '@mui/icons-material/StarRate';
import StarsIcon from '@mui/icons-material/Stars';
import StartIcon from '@mui/icons-material/Start';
import StayCurrentLandscapeIcon from '@mui/icons-material/StayCurrentLandscape';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import StayPrimaryLandscapeIcon from '@mui/icons-material/StayPrimaryLandscape';
import StayPrimaryPortraitIcon from '@mui/icons-material/StayPrimaryPortrait';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import StopIcon from '@mui/icons-material/Stop';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import StorageIcon from '@mui/icons-material/Storage';
import StoreIcon from '@mui/icons-material/Store';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StormIcon from '@mui/icons-material/Storm';
import StraightIcon from '@mui/icons-material/Straight';
import StraightenIcon from '@mui/icons-material/Straighten';
import StreamIcon from '@mui/icons-material/Stream';
import StreetviewIcon from '@mui/icons-material/Streetview';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import StrollerIcon from '@mui/icons-material/Stroller';
import StyleIcon from '@mui/icons-material/Style';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import SubjectIcon from '@mui/icons-material/Subject';
import SubscriptIcon from '@mui/icons-material/Subscript';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff';
import SubwayIcon from '@mui/icons-material/Subway';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SupportIcon from '@mui/icons-material/Support';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SurfingIcon from '@mui/icons-material/Surfing';
import SurroundSoundIcon from '@mui/icons-material/SurroundSound';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import SwipeIcon from '@mui/icons-material/Swipe';
import SwipeDownIcon from '@mui/icons-material/SwipeDown';
import SwipeDownAltIcon from '@mui/icons-material/SwipeDownAlt';
import SwipeLeftIcon from '@mui/icons-material/SwipeLeft';
import SwipeLeftAltIcon from '@mui/icons-material/SwipeLeftAlt';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import SwipeRightAltIcon from '@mui/icons-material/SwipeRightAlt';
import SwipeUpIcon from '@mui/icons-material/SwipeUp';
import SwipeUpAltIcon from '@mui/icons-material/SwipeUpAlt';
import SwipeVerticalIcon from '@mui/icons-material/SwipeVertical';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import SwitchCameraIcon from '@mui/icons-material/SwitchCamera';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';
import SwitchVideoIcon from '@mui/icons-material/SwitchVideo';
import SynagogueIcon from '@mui/icons-material/Synagogue';
import SyncIcon from '@mui/icons-material/Sync';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import SystemSecurityUpdateIcon from '@mui/icons-material/SystemSecurityUpdate';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import SystemSecurityUpdateWarningIcon from '@mui/icons-material/SystemSecurityUpdateWarning';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import TabIcon from '@mui/icons-material/Tab';
import TabUnselectedIcon from '@mui/icons-material/TabUnselected';
import TableBarIcon from '@mui/icons-material/TableBar';
import TableChartIcon from '@mui/icons-material/TableChart';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import TableRowsIcon from '@mui/icons-material/TableRows';
import TableViewIcon from '@mui/icons-material/TableView';
import TabletIcon from '@mui/icons-material/Tablet';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import TagIcon from '@mui/icons-material/Tag';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import TapasIcon from '@mui/icons-material/Tapas';
import TaskIcon from '@mui/icons-material/Task';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import TelegramIcon from '@mui/icons-material/Telegram';
import TempleBuddhistIcon from '@mui/icons-material/TempleBuddhist';
import TempleHinduIcon from '@mui/icons-material/TempleHindu';
import TenMpIcon from '@mui/icons-material/TenMp';
import TerminalIcon from '@mui/icons-material/Terminal';
import TerrainIcon from '@mui/icons-material/Terrain';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import TextRotateUpIcon from '@mui/icons-material/TextRotateUp';
import TextRotateVerticalIcon from '@mui/icons-material/TextRotateVertical';
import TextRotationAngledownIcon from '@mui/icons-material/TextRotationAngledown';
import TextRotationAngleupIcon from '@mui/icons-material/TextRotationAngleup';
import TextRotationDownIcon from '@mui/icons-material/TextRotationDown';
import TextRotationNoneIcon from '@mui/icons-material/TextRotationNone';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TextsmsIcon from '@mui/icons-material/Textsms';
import TextureIcon from '@mui/icons-material/Texture';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import TheatersIcon from '@mui/icons-material/Theaters';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import ThermostatAutoIcon from '@mui/icons-material/ThermostatAuto';
import ThirteenMpIcon from '@mui/icons-material/ThirteenMp';
import ThirtyFpsIcon from '@mui/icons-material/ThirtyFps';
import ThirtyFpsSelectIcon from '@mui/icons-material/ThirtyFpsSelect';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import ThreeGMobiledataIcon from '@mui/icons-material/ThreeGMobiledata';
import ThreeKIcon from '@mui/icons-material/ThreeK';
import ThreeKPlusIcon from '@mui/icons-material/ThreeKPlus';
import ThreeMpIcon from '@mui/icons-material/ThreeMp';
import ThreePIcon from '@mui/icons-material/ThreeP';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import TimelineIcon from '@mui/icons-material/Timeline';
import TimerIcon from '@mui/icons-material/Timer';
import Timer10Icon from '@mui/icons-material/Timer10';
import Timer10SelectIcon from '@mui/icons-material/Timer10Select';
import Timer3Icon from '@mui/icons-material/Timer3';
import Timer3SelectIcon from '@mui/icons-material/Timer3Select';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import TimesOneMobiledataIcon from '@mui/icons-material/TimesOneMobiledata';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import TitleIcon from '@mui/icons-material/Title';
import TocIcon from '@mui/icons-material/Toc';
import TodayIcon from '@mui/icons-material/Today';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import TokenIcon from '@mui/icons-material/Token';
import TollIcon from '@mui/icons-material/Toll';
import TonalityIcon from '@mui/icons-material/Tonality';
import TopicIcon from '@mui/icons-material/Topic';
import TornadoIcon from '@mui/icons-material/Tornado';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import TourIcon from '@mui/icons-material/Tour';
import ToysIcon from '@mui/icons-material/Toys';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import TrafficIcon from '@mui/icons-material/Traffic';
import TrainIcon from '@mui/icons-material/Train';
import TramIcon from '@mui/icons-material/Tram';
//import TranscribeIcon from '@mui/icons-material/Transcribe';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import TransformIcon from '@mui/icons-material/Transform';
import TransgenderIcon from '@mui/icons-material/Transgender';
import TransitEnterexitIcon from '@mui/icons-material/TransitEnterexit';
import TranslateIcon from '@mui/icons-material/Translate';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
//import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import TryIcon from '@mui/icons-material/Try';
import TsunamiIcon from '@mui/icons-material/Tsunami';
import TtyIcon from '@mui/icons-material/Tty';
import TuneIcon from '@mui/icons-material/Tune';
import TungstenIcon from '@mui/icons-material/Tungsten';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import TurnSlightLeftIcon from '@mui/icons-material/TurnSlightLeft';
import TurnSlightRightIcon from '@mui/icons-material/TurnSlightRight';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TvIcon from '@mui/icons-material/Tv';
import TvOffIcon from '@mui/icons-material/TvOff';
import TwelveMpIcon from '@mui/icons-material/TwelveMp';
import TwentyFourMpIcon from '@mui/icons-material/TwentyFourMp';
import TwentyOneMpIcon from '@mui/icons-material/TwentyOneMp';
import TwentyThreeMpIcon from '@mui/icons-material/TwentyThreeMp';
import TwentyTwoMpIcon from '@mui/icons-material/TwentyTwoMp';
import TwentyZeroMpIcon from '@mui/icons-material/TwentyZeroMp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TwoKIcon from '@mui/icons-material/TwoK';
import TwoKPlusIcon from '@mui/icons-material/TwoKPlus';
import TwoMpIcon from '@mui/icons-material/TwoMp';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
//import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import UTurnRightIcon from '@mui/icons-material/UTurnRight';
import UmbrellaIcon from '@mui/icons-material/Umbrella';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import UndoIcon from '@mui/icons-material/Undo';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
//import UnfoldLessDoubleIcon from '@mui/icons-material/UnfoldLessDouble';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
//import UnfoldMoreDoubleIcon from '@mui/icons-material/UnfoldMoreDouble';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import UpdateIcon from '@mui/icons-material/Update';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import UploadIcon from '@mui/icons-material/Upload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UsbIcon from '@mui/icons-material/Usb';
import UsbOffIcon from '@mui/icons-material/UsbOff';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import VapeFreeIcon from '@mui/icons-material/VapeFree';
import VapingRoomsIcon from '@mui/icons-material/VapingRooms';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalShadesIcon from '@mui/icons-material/VerticalShades';
import VerticalShadesClosedIcon from '@mui/icons-material/VerticalShadesClosed';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import VibrationIcon from '@mui/icons-material/Vibration';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
//import VideoChatIcon from '@mui/icons-material/VideoChat';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import VideoStableIcon from '@mui/icons-material/VideoStable';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import VideogameAssetOffIcon from '@mui/icons-material/VideogameAssetOff';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import ViewArrayIcon from '@mui/icons-material/ViewArray';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ViewCompactAltIcon from '@mui/icons-material/ViewCompactAlt';
//import ViewCozyIcon from '@mui/icons-material/Vie*wCozy';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import VignetteIcon from '@mui/icons-material/Vignette';
import VillaIcon from '@mui/icons-material/Villa';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import VolcanoIcon from '@mui/icons-material/Volcano';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import VrpanoIcon from '@mui/icons-material/Vrpano';
//import WalletIcon from '@mui/icons-material/Wallet';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WashIcon from '@mui/icons-material/Wash';
import WatchIcon from '@mui/icons-material/Watch';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WatchOffIcon from '@mui/icons-material/WatchOff';
import WaterIcon from '@mui/icons-material/Water';
import WaterDamageIcon from '@mui/icons-material/WaterDamage';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import WavesIcon from '@mui/icons-material/Waves';
import WbAutoIcon from '@mui/icons-material/WbAuto';
import WbCloudyIcon from '@mui/icons-material/WbCloudy';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import WbIridescentIcon from '@mui/icons-material/WbIridescent';
import WbShadeIcon from '@mui/icons-material/WbShade';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import WcIcon from '@mui/icons-material/Wc';
import WebIcon from '@mui/icons-material/Web';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';
//import WebStoriesIcon from '@mui/icons-material/WebStories';
import WebhookIcon from '@mui/icons-material/Webhook';
import WeekendIcon from '@mui/icons-material/Weekend';
import WestIcon from '@mui/icons-material/West';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import WidgetsIcon from '@mui/icons-material/Widgets';
//import WidthFullIcon from '@mui/icons-material/WidthFull';
//import WidthNormalIcon from '@mui/icons-material/WidthNormal';
//import WidthWideIcon from '@mui/icons-material/WidthWide';
import WifiIcon from '@mui/icons-material/Wifi';
import Wifi1BarIcon from '@mui/icons-material/Wifi1Bar';
import Wifi2BarIcon from '@mui/icons-material/Wifi2Bar';
import WifiCallingIcon from '@mui/icons-material/WifiCalling';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import WifiChannelIcon from '@mui/icons-material/WifiChannel';
import WifiFindIcon from '@mui/icons-material/WifiFind';
import WifiLockIcon from '@mui/icons-material/WifiLock';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WifiPasswordIcon from '@mui/icons-material/WifiPassword';
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import WifiTetheringErrorIcon from '@mui/icons-material/WifiTetheringError';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import WindPowerIcon from '@mui/icons-material/WindPower';
import WindowIcon from '@mui/icons-material/Window';
import WineBarIcon from '@mui/icons-material/WineBar';
import WomanIcon from '@mui/icons-material/Woman';
//import Woman2Icon from '@mui/icons-material/Woman2';
import WorkIcon from '@mui/icons-material/Work';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import WrapTextIcon from '@mui/icons-material/WrapText';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import YardIcon from '@mui/icons-material/Yard';
import YouTubeIcon from '@mui/icons-material/YouTube';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';



const ItemIcono = (icono) => {

     
        switch (icono.icono) {                

            case 'Abc' : return (<AbcIcon  sx={{fontSize: 20}}/> )
            case 'AcUnit' : return (<AcUnitIcon  sx={{fontSize: 20}}/> )
            case 'AccessAlarm' : return (<AccessAlarmIcon  sx={{fontSize: 20}}/> )
            case 'AccessAlarms' : return (<AccessAlarmsIcon  sx={{fontSize: 20}}/> )
            case 'AccessTime' : return (<AccessTimeIcon  sx={{fontSize: 20}}/> )
            case 'AccessTimeFilled' : return (<AccessTimeFilledIcon  sx={{fontSize: 20}}/> )
            case 'Accessibility' : return (<AccessibilityIcon  sx={{fontSize: 20}}/> )
            case 'AccessibilityNew' : return (<AccessibilityNewIcon  sx={{fontSize: 20}}/> )
            case 'Accessible' : return (<AccessibleIcon  sx={{fontSize: 20}}/> )
            case 'AccessibleForward' : return (<AccessibleForwardIcon  sx={{fontSize: 20}}/> )
            case 'AccountBalance' : return (<AccountBalanceIcon  sx={{fontSize: 20}}/> )
            case 'AccountBalanceWallet' : return (<AccountBalanceWalletIcon  sx={{fontSize: 20}}/> )
            case 'AccountBox' : return (<AccountBoxIcon  sx={{fontSize: 20}}/> )
            case 'AccountCircle' : return (<AccountCircleIcon  sx={{fontSize: 20}}/> )
            case 'AccountTree' : return (<AccountTreeIcon  sx={{fontSize: 20}}/> )
            case 'AdUnits' : return (<AdUnitsIcon  sx={{fontSize: 20}}/> )
            case 'Adb' : return (<AdbIcon  sx={{fontSize: 20}}/> )
            case 'Add' : return (<AddIcon  sx={{fontSize: 20}}/> )
            case 'AddAPhoto' : return (<AddAPhotoIcon  sx={{fontSize: 20}}/> )
            case 'AddAlarm' : return (<AddAlarmIcon  sx={{fontSize: 20}}/> )
            case 'AddAlert' : return (<AddAlertIcon  sx={{fontSize: 20}}/> )
            case 'AddBox' : return (<AddBoxIcon  sx={{fontSize: 20}}/> )
            case 'AddBusiness' : return (<AddBusinessIcon  sx={{fontSize: 20}}/> )
            case 'AddCard' : return (<AddCardIcon  sx={{fontSize: 20}}/> )
            case 'AddCircle' : return (<AddCircleIcon  sx={{fontSize: 20}}/> )
            case 'AddCircleOutline' : return (<AddCircleOutlineIcon  sx={{fontSize: 20}}/> )
            case 'AddComment' : return (<AddCommentIcon  sx={{fontSize: 20}}/> )
            //case 'AddHome' : return (<AddHomeIcon  sx={{fontSize: 20}}/> )
            //case 'AddHomeWork' : return (<AddHomeWorkIcon  sx={{fontSize: 20}}/> )
            case 'AddIcCall' : return (<AddIcCallIcon  sx={{fontSize: 20}}/> )
            case 'AddLink' : return (<AddLinkIcon  sx={{fontSize: 20}}/> )
            case 'AddLocation' : return (<AddLocationIcon  sx={{fontSize: 20}}/> )
            case 'AddLocationAlt' : return (<AddLocationAltIcon  sx={{fontSize: 20}}/> )
            case 'AddModerator' : return (<AddModeratorIcon  sx={{fontSize: 20}}/> )
            case 'AddPhotoAlternate' : return (<AddPhotoAlternateIcon  sx={{fontSize: 20}}/> )
            case 'AddReaction' : return (<AddReactionIcon  sx={{fontSize: 20}}/> )
            case 'AddRoad' : return (<AddRoadIcon  sx={{fontSize: 20}}/> )
            case 'AddShoppingCart' : return (<AddShoppingCartIcon  sx={{fontSize: 20}}/> )
            case 'AddTask' : return (<AddTaskIcon  sx={{fontSize: 20}}/> )
            case 'AddToDrive' : return (<AddToDriveIcon  sx={{fontSize: 20}}/> )
            case 'AddToHomeScreen' : return (<AddToHomeScreenIcon  sx={{fontSize: 20}}/> )
            case 'AddToPhotos' : return (<AddToPhotosIcon  sx={{fontSize: 20}}/> )
            case 'AddToQueue' : return (<AddToQueueIcon  sx={{fontSize: 20}}/> )
            case 'Addchart' : return (<AddchartIcon  sx={{fontSize: 20}}/> )
            case 'AdfScanner' : return (<AdfScannerIcon  sx={{fontSize: 20}}/> )
            case 'Adjust' : return (<AdjustIcon  sx={{fontSize: 20}}/> )
            case 'AdminPanelSettings' : return (<AdminPanelSettingsIcon  sx={{fontSize: 20}}/> )
            case 'Agriculture' : return (<AgricultureIcon  sx={{fontSize: 20}}/> )
            case 'Air' : return (<AirIcon  sx={{fontSize: 20}}/> )
            case 'AirlineSeatFlat' : return (<AirlineSeatFlatIcon  sx={{fontSize: 20}}/> )
            case 'AirlineSeatFlatAngled' : return (<AirlineSeatFlatAngledIcon  sx={{fontSize: 20}}/> )
            case 'AirlineSeatIndividualSuite' : return (<AirlineSeatIndividualSuiteIcon  sx={{fontSize: 20}}/> )
            case 'AirlineSeatLegroomExtra' : return (<AirlineSeatLegroomExtraIcon  sx={{fontSize: 20}}/> )
            case 'AirlineSeatLegroomNormal' : return (<AirlineSeatLegroomNormalIcon  sx={{fontSize: 20}}/> )
            case 'AirlineSeatLegroomReduced' : return (<AirlineSeatLegroomReducedIcon  sx={{fontSize: 20}}/> )
            case 'AirlineSeatReclineExtra' : return (<AirlineSeatReclineExtraIcon  sx={{fontSize: 20}}/> )
            case 'AirlineSeatReclineNormal' : return (<AirlineSeatReclineNormalIcon  sx={{fontSize: 20}}/> )
            case 'AirlineStops' : return (<AirlineStopsIcon  sx={{fontSize: 20}}/> )
            case 'Airlines' : return (<AirlinesIcon  sx={{fontSize: 20}}/> )
            case 'AirplaneTicket' : return (<AirplaneTicketIcon  sx={{fontSize: 20}}/> )
            case 'AirplanemodeActive' : return (<AirplanemodeActiveIcon  sx={{fontSize: 20}}/> )
            case 'AirplanemodeInactive' : return (<AirplanemodeInactiveIcon  sx={{fontSize: 20}}/> )
            case 'Airplay' : return (<AirplayIcon  sx={{fontSize: 20}}/> )
            case 'AirportShuttle' : return (<AirportShuttleIcon  sx={{fontSize: 20}}/> )
            case 'Alarm' : return (<AlarmIcon  sx={{fontSize: 20}}/> )
            case 'AlarmAdd' : return (<AlarmAddIcon  sx={{fontSize: 20}}/> )
            case 'AlarmOff' : return (<AlarmOffIcon  sx={{fontSize: 20}}/> )
            case 'AlarmOn' : return (<AlarmOnIcon  sx={{fontSize: 20}}/> )
            case 'Album' : return (<AlbumIcon  sx={{fontSize: 20}}/> )
            case 'AlignHorizontalCenter' : return (<AlignHorizontalCenterIcon  sx={{fontSize: 20}}/> )
            case 'AlignHorizontalLeft' : return (<AlignHorizontalLeftIcon  sx={{fontSize: 20}}/> )
            case 'AlignHorizontalRight' : return (<AlignHorizontalRightIcon  sx={{fontSize: 20}}/> )
            case 'AlignVerticalBottom' : return (<AlignVerticalBottomIcon  sx={{fontSize: 20}}/> )
            case 'AlignVerticalCenter' : return (<AlignVerticalCenterIcon  sx={{fontSize: 20}}/> )
            case 'AlignVerticalTop' : return (<AlignVerticalTopIcon  sx={{fontSize: 20}}/> )
            case 'AllInbox' : return (<AllInboxIcon  sx={{fontSize: 20}}/> )
            case 'AllInclusive' : return (<AllInclusiveIcon  sx={{fontSize: 20}}/> )
            case 'AllOut' : return (<AllOutIcon  sx={{fontSize: 20}}/> )
            case 'AltRoute' : return (<AltRouteIcon  sx={{fontSize: 20}}/> )
            case 'AlternateEmail' : return (<AlternateEmailIcon  sx={{fontSize: 20}}/> )
            case 'Analytics' : return (<AnalyticsIcon  sx={{fontSize: 20}}/> )
            case 'Anchor' : return (<AnchorIcon  sx={{fontSize: 20}}/> )
            case 'Android' : return (<AndroidIcon  sx={{fontSize: 20}}/> )
            case 'Animation' : return (<AnimationIcon  sx={{fontSize: 20}}/> )
            case 'Announcement' : return (<AnnouncementIcon  sx={{fontSize: 20}}/> )
            case 'Aod' : return (<AodIcon  sx={{fontSize: 20}}/> )
            case 'Apartment' : return (<ApartmentIcon  sx={{fontSize: 20}}/> )
            case 'Api' : return (<ApiIcon  sx={{fontSize: 20}}/> )
            case 'AppBlocking' : return (<AppBlockingIcon  sx={{fontSize: 20}}/> )
            case 'AppRegistration' : return (<AppRegistrationIcon  sx={{fontSize: 20}}/> )
            case 'AppSettingsAlt' : return (<AppSettingsAltIcon  sx={{fontSize: 20}}/> )
            case 'AppShortcut' : return (<AppShortcutIcon  sx={{fontSize: 20}}/> )
            case 'Apple' : return (<AppleIcon  sx={{fontSize: 20}}/> )
            case 'Approval' : return (<ApprovalIcon  sx={{fontSize: 20}}/> )
            case 'Apps' : return (<AppsIcon  sx={{fontSize: 20}}/> )
            case 'AppsOutage' : return (<AppsOutageIcon  sx={{fontSize: 20}}/> )
            case 'Architecture' : return (<ArchitectureIcon  sx={{fontSize: 20}}/> )
            case 'Archive' : return (<ArchiveIcon  sx={{fontSize: 20}}/> )
            case 'ArrowBack' : return (<ArrowBackIcon  sx={{fontSize: 20}}/> )
            case 'ArrowBackIos' : return (<ArrowBackIosIcon  sx={{fontSize: 20}}/> )
            case 'ArrowBackIosNew' : return (<ArrowBackIosNewIcon  sx={{fontSize: 20}}/> )
            case 'ArrowCircleDown' : return (<ArrowCircleDownIcon  sx={{fontSize: 20}}/> )
            case 'ArrowCircleLeft' : return (<ArrowCircleLeftIcon  sx={{fontSize: 20}}/> )
            case 'ArrowCircleRight' : return (<ArrowCircleRightIcon  sx={{fontSize: 20}}/> )
            case 'ArrowCircleUp' : return (<ArrowCircleUpIcon  sx={{fontSize: 20}}/> )
            case 'ArrowDownward' : return (<ArrowDownwardIcon  sx={{fontSize: 20}}/> )
            case 'ArrowDropDown' : return (<ArrowDropDownIcon  sx={{fontSize: 20}}/> )
            case 'ArrowDropDownCircle' : return (<ArrowDropDownCircleIcon  sx={{fontSize: 20}}/> )
            case 'ArrowDropUp' : return (<ArrowDropUpIcon  sx={{fontSize: 20}}/> )
            case 'ArrowForward' : return (<ArrowForwardIcon  sx={{fontSize: 20}}/> )
            case 'ArrowForwardIos' : return (<ArrowForwardIosIcon  sx={{fontSize: 20}}/> )
            case 'ArrowLeft' : return (<ArrowLeftIcon  sx={{fontSize: 20}}/> )
            //case 'ArrowOutward' : return (<ArrowOutwardIcon  sx={{fontSize: 20}}/> )
            case 'ArrowRight' : return (<ArrowRightIcon  sx={{fontSize: 20}}/> )
            case 'ArrowRightAlt' : return (<ArrowRightAltIcon  sx={{fontSize: 20}}/> )
            case 'ArrowUpward' : return (<ArrowUpwardIcon  sx={{fontSize: 20}}/> )
            case 'ArtTrack' : return (<ArtTrackIcon  sx={{fontSize: 20}}/> )
            case 'Article' : return (<ArticleIcon  sx={{fontSize: 20}}/> )
            case 'AspectRatio' : return (<AspectRatioIcon  sx={{fontSize: 20}}/> )
            case 'Assessment' : return (<AssessmentIcon  sx={{fontSize: 20}}/> )
            case 'Assignment' : return (<AssignmentIcon  sx={{fontSize: 20}}/> )
            case 'AssignmentInd' : return (<AssignmentIndIcon  sx={{fontSize: 20}}/> )
            case 'AssignmentLate' : return (<AssignmentLateIcon  sx={{fontSize: 20}}/> )
            case 'AssignmentReturn' : return (<AssignmentReturnIcon  sx={{fontSize: 20}}/> )
            case 'AssignmentReturned' : return (<AssignmentReturnedIcon  sx={{fontSize: 20}}/> )
            case 'AssignmentTurnedIn' : return (<AssignmentTurnedInIcon  sx={{fontSize: 20}}/> )
            //case 'AssistWalker' : return (<AssistWalkerIcon  sx={{fontSize: 20}}/> )
            case 'Assistant' : return (<AssistantIcon  sx={{fontSize: 20}}/> )
            case 'AssistantDirection' : return (<AssistantDirectionIcon  sx={{fontSize: 20}}/> )
            case 'AssistantPhoto' : return (<AssistantPhotoIcon  sx={{fontSize: 20}}/> )
            case 'AssuredWorkload' : return (<AssuredWorkloadIcon  sx={{fontSize: 20}}/> )
            case 'Atm' : return (<AtmIcon  sx={{fontSize: 20}}/> )
            case 'AttachEmail' : return (<AttachEmailIcon  sx={{fontSize: 20}}/> )
            case 'AttachFile' : return (<AttachFileIcon  sx={{fontSize: 20}}/> )
            case 'AttachMoney' : return (<AttachMoneyIcon  sx={{fontSize: 20}}/> )
            case 'Attachment' : return (<AttachmentIcon  sx={{fontSize: 20}}/> )
            case 'Attractions' : return (<AttractionsIcon  sx={{fontSize: 20}}/> )
            case 'Attribution' : return (<AttributionIcon  sx={{fontSize: 20}}/> )
            case 'AudioFile' : return (<AudioFileIcon  sx={{fontSize: 20}}/> )
            case 'Audiotrack' : return (<AudiotrackIcon  sx={{fontSize: 20}}/> )
            case 'AutoAwesome' : return (<AutoAwesomeIcon  sx={{fontSize: 20}}/> )
            case 'AutoAwesomeMosaic' : return (<AutoAwesomeMosaicIcon  sx={{fontSize: 20}}/> )
            case 'AutoAwesomeMotion' : return (<AutoAwesomeMotionIcon  sx={{fontSize: 20}}/> )
            case 'AutoDelete' : return (<AutoDeleteIcon  sx={{fontSize: 20}}/> )
            case 'AutoFixHigh' : return (<AutoFixHighIcon  sx={{fontSize: 20}}/> )
            case 'AutoFixNormal' : return (<AutoFixNormalIcon  sx={{fontSize: 20}}/> )
            case 'AutoFixOff' : return (<AutoFixOffIcon  sx={{fontSize: 20}}/> )
            case 'AutoGraph' : return (<AutoGraphIcon  sx={{fontSize: 20}}/> )
            case 'AutoMode' : return (<AutoModeIcon  sx={{fontSize: 20}}/> )
            case 'AutoStories' : return (<AutoStoriesIcon  sx={{fontSize: 20}}/> )
            case 'AutofpsSelect' : return (<AutofpsSelectIcon  sx={{fontSize: 20}}/> )
            case 'Autorenew' : return (<AutorenewIcon  sx={{fontSize: 20}}/> )
            case 'AvTimer' : return (<AvTimerIcon  sx={{fontSize: 20}}/> )
            case 'BabyChangingStation' : return (<BabyChangingStationIcon  sx={{fontSize: 20}}/> )
            case 'Backpack' : return (<BackpackIcon  sx={{fontSize: 20}}/> )
            case 'Backspace' : return (<BackspaceIcon  sx={{fontSize: 20}}/> )
            case 'Backup' : return (<BackupIcon  sx={{fontSize: 20}}/> )
            case 'BackupTable' : return (<BackupTableIcon  sx={{fontSize: 20}}/> )
            case 'Badge' : return (<BadgeIcon  sx={{fontSize: 20}}/> )
            case 'BakeryDining' : return (<BakeryDiningIcon  sx={{fontSize: 20}}/> )
            case 'Balance' : return (<BalanceIcon  sx={{fontSize: 20}}/> )
            case 'Balcony' : return (<BalconyIcon  sx={{fontSize: 20}}/> )
            case 'Ballot' : return (<BallotIcon  sx={{fontSize: 20}}/> )
            case 'BarChart' : return (<BarChartIcon  sx={{fontSize: 20}}/> )
            case 'BatchPrediction' : return (<BatchPredictionIcon  sx={{fontSize: 20}}/> )
            case 'Bathroom' : return (<BathroomIcon  sx={{fontSize: 20}}/> )
            case 'Bathtub' : return (<BathtubIcon  sx={{fontSize: 20}}/> )
            case 'Battery0Bar' : return (<Battery0BarIcon  sx={{fontSize: 20}}/> )
            case 'Battery1Bar' : return (<Battery1BarIcon  sx={{fontSize: 20}}/> )
            case 'Battery20' : return (<Battery20Icon  sx={{fontSize: 20}}/> )
            case 'Battery2Bar' : return (<Battery2BarIcon  sx={{fontSize: 20}}/> )
            case 'Battery30' : return (<Battery30Icon  sx={{fontSize: 20}}/> )
            case 'Battery3Bar' : return (<Battery3BarIcon  sx={{fontSize: 20}}/> )
            case 'Battery4Bar' : return (<Battery4BarIcon  sx={{fontSize: 20}}/> )
            case 'Battery50' : return (<Battery50Icon  sx={{fontSize: 20}}/> )
            case 'Battery5Bar' : return (<Battery5BarIcon  sx={{fontSize: 20}}/> )
            case 'Battery60' : return (<Battery60Icon  sx={{fontSize: 20}}/> )
            case 'Battery6Bar' : return (<Battery6BarIcon  sx={{fontSize: 20}}/> )
            case 'Battery80' : return (<Battery80Icon  sx={{fontSize: 20}}/> )
            case 'Battery90' : return (<Battery90Icon  sx={{fontSize: 20}}/> )
            case 'BatteryAlert' : return (<BatteryAlertIcon  sx={{fontSize: 20}}/> )
            case 'BatteryCharging20' : return (<BatteryCharging20Icon  sx={{fontSize: 20}}/> )
            case 'BatteryCharging30' : return (<BatteryCharging30Icon  sx={{fontSize: 20}}/> )
            case 'BatteryCharging50' : return (<BatteryCharging50Icon  sx={{fontSize: 20}}/> )
            case 'BatteryCharging60' : return (<BatteryCharging60Icon  sx={{fontSize: 20}}/> )
            case 'BatteryCharging80' : return (<BatteryCharging80Icon  sx={{fontSize: 20}}/> )
            case 'BatteryCharging90' : return (<BatteryCharging90Icon  sx={{fontSize: 20}}/> )
            case 'BatteryChargingFull' : return (<BatteryChargingFullIcon  sx={{fontSize: 20}}/> )
            case 'BatteryFull' : return (<BatteryFullIcon  sx={{fontSize: 20}}/> )
            case 'BatterySaver' : return (<BatterySaverIcon  sx={{fontSize: 20}}/> )
            case 'BatteryStd' : return (<BatteryStdIcon  sx={{fontSize: 20}}/> )
            case 'BatteryUnknown' : return (<BatteryUnknownIcon  sx={{fontSize: 20}}/> )
            case 'BeachAccess' : return (<BeachAccessIcon  sx={{fontSize: 20}}/> )
            case 'Bed' : return (<BedIcon  sx={{fontSize: 20}}/> )
            case 'BedroomBaby' : return (<BedroomBabyIcon  sx={{fontSize: 20}}/> )
            case 'BedroomChild' : return (<BedroomChildIcon  sx={{fontSize: 20}}/> )
            case 'BedroomParent' : return (<BedroomParentIcon  sx={{fontSize: 20}}/> )
            case 'Bedtime' : return (<BedtimeIcon  sx={{fontSize: 20}}/> )
            case 'BedtimeOff' : return (<BedtimeOffIcon  sx={{fontSize: 20}}/> )
            case 'Beenhere' : return (<BeenhereIcon  sx={{fontSize: 20}}/> )
            case 'Bento' : return (<BentoIcon  sx={{fontSize: 20}}/> )
            case 'BikeScooter' : return (<BikeScooterIcon  sx={{fontSize: 20}}/> )
            case 'Biotech' : return (<BiotechIcon  sx={{fontSize: 20}}/> )
            case 'Blender' : return (<BlenderIcon  sx={{fontSize: 20}}/> )
            //case 'Blind' : return (<BlindIcon  sx={{fontSize: 20}}/> )
            //case 'Blinds' : return (<BlindsIcon  sx={{fontSize: 20}}/> )
            case 'BlindsClosed' : return (<BlindsClosedIcon  sx={{fontSize: 20}}/> )
            case 'Block' : return (<BlockIcon  sx={{fontSize: 20}}/> )
            case 'Bloodtype' : return (<BloodtypeIcon  sx={{fontSize: 20}}/> )
            case 'Bluetooth' : return (<BluetoothIcon  sx={{fontSize: 20}}/> )
            case 'BluetoothAudio' : return (<BluetoothAudioIcon  sx={{fontSize: 20}}/> )
            case 'BluetoothConnected' : return (<BluetoothConnectedIcon  sx={{fontSize: 20}}/> )
            case 'BluetoothDisabled' : return (<BluetoothDisabledIcon  sx={{fontSize: 20}}/> )
            case 'BluetoothDrive' : return (<BluetoothDriveIcon  sx={{fontSize: 20}}/> )
            case 'BluetoothSearching' : return (<BluetoothSearchingIcon  sx={{fontSize: 20}}/> )
            case 'BlurCircular' : return (<BlurCircularIcon  sx={{fontSize: 20}}/> )
            case 'BlurLinear' : return (<BlurLinearIcon  sx={{fontSize: 20}}/> )
            case 'BlurOff' : return (<BlurOffIcon  sx={{fontSize: 20}}/> )
            case 'BlurOn' : return (<BlurOnIcon  sx={{fontSize: 20}}/> )
            case 'Bolt' : return (<BoltIcon  sx={{fontSize: 20}}/> )
            case 'Book' : return (<BookIcon  sx={{fontSize: 20}}/> )
            case 'BookOnline' : return (<BookOnlineIcon  sx={{fontSize: 20}}/> )
            case 'Bookmark' : return (<BookmarkIcon  sx={{fontSize: 20}}/> )
            case 'BookmarkAdd' : return (<BookmarkAddIcon  sx={{fontSize: 20}}/> )
            case 'BookmarkAdded' : return (<BookmarkAddedIcon  sx={{fontSize: 20}}/> )
            case 'BookmarkBorder' : return (<BookmarkBorderIcon  sx={{fontSize: 20}}/> )
            case 'BookmarkRemove' : return (<BookmarkRemoveIcon  sx={{fontSize: 20}}/> )
            case 'Bookmarks' : return (<BookmarksIcon  sx={{fontSize: 20}}/> )
            case 'BorderAll' : return (<BorderAllIcon  sx={{fontSize: 20}}/> )
            case 'BorderBottom' : return (<BorderBottomIcon  sx={{fontSize: 20}}/> )
            case 'BorderClear' : return (<BorderClearIcon  sx={{fontSize: 20}}/> )
            case 'BorderColor' : return (<BorderColorIcon  sx={{fontSize: 20}}/> )
            case 'BorderHorizontal' : return (<BorderHorizontalIcon  sx={{fontSize: 20}}/> )
            case 'BorderInner' : return (<BorderInnerIcon  sx={{fontSize: 20}}/> )
            case 'BorderLeft' : return (<BorderLeftIcon  sx={{fontSize: 20}}/> )
            case 'BorderOuter' : return (<BorderOuterIcon  sx={{fontSize: 20}}/> )
            case 'BorderRight' : return (<BorderRightIcon  sx={{fontSize: 20}}/> )
            case 'BorderStyle' : return (<BorderStyleIcon  sx={{fontSize: 20}}/> )
            case 'BorderTop' : return (<BorderTopIcon  sx={{fontSize: 20}}/> )
            case 'BorderVertical' : return (<BorderVerticalIcon  sx={{fontSize: 20}}/> )
            case 'Boy' : return (<BoyIcon  sx={{fontSize: 20}}/> )
            case 'BrandingWatermark' : return (<BrandingWatermarkIcon  sx={{fontSize: 20}}/> )
            case 'BreakfastDining' : return (<BreakfastDiningIcon  sx={{fontSize: 20}}/> )
            case 'Brightness1' : return (<Brightness1Icon  sx={{fontSize: 20}}/> )
            case 'Brightness2' : return (<Brightness2Icon  sx={{fontSize: 20}}/> )
            case 'Brightness3' : return (<Brightness3Icon  sx={{fontSize: 20}}/> )
            case 'Brightness4' : return (<Brightness4Icon  sx={{fontSize: 20}}/> )
            case 'Brightness5' : return (<Brightness5Icon  sx={{fontSize: 20}}/> )
            case 'Brightness6' : return (<Brightness6Icon  sx={{fontSize: 20}}/> )
            case 'Brightness7' : return (<Brightness7Icon  sx={{fontSize: 20}}/> )
            case 'BrightnessAuto' : return (<BrightnessAutoIcon  sx={{fontSize: 20}}/> )
            case 'BrightnessHigh' : return (<BrightnessHighIcon  sx={{fontSize: 20}}/> )
            case 'BrightnessLow' : return (<BrightnessLowIcon  sx={{fontSize: 20}}/> )
            case 'BrightnessMedium' : return (<BrightnessMediumIcon  sx={{fontSize: 20}}/> )
            //case 'BroadcastOnHome' : return (<BroadcastOnHomeIcon  sx={{fontSize: 20}}/> )
            //case 'BroadcastOnPersonal' : return (<BroadcastOnPersonalIcon  sx={{fontSize: 20}}/> )
            case 'BrokenImage' : return (<BrokenImageIcon  sx={{fontSize: 20}}/> )
            case 'BrowseGallery' : return (<BrowseGalleryIcon  sx={{fontSize: 20}}/> )
            case 'BrowserNotSupported' : return (<BrowserNotSupportedIcon  sx={{fontSize: 20}}/> )
            case 'BrowserUpdated' : return (<BrowserUpdatedIcon  sx={{fontSize: 20}}/> )
            case 'BrunchDining' : return (<BrunchDiningIcon  sx={{fontSize: 20}}/> )
            case 'Brush' : return (<BrushIcon  sx={{fontSize: 20}}/> )
            case 'BubbleChart' : return (<BubbleChartIcon  sx={{fontSize: 20}}/> )
            case 'BugReport' : return (<BugReportIcon  sx={{fontSize: 20}}/> )
            case 'Build' : return (<BuildIcon  sx={{fontSize: 20}}/> )
            case 'BuildCircle' : return (<BuildCircleIcon  sx={{fontSize: 20}}/> )
            case 'Bungalow' : return (<BungalowIcon  sx={{fontSize: 20}}/> )
            case 'BurstMode' : return (<BurstModeIcon  sx={{fontSize: 20}}/> )
            case 'BusAlert' : return (<BusAlertIcon  sx={{fontSize: 20}}/> )
            case 'Business' : return (<BusinessIcon  sx={{fontSize: 20}}/> )
            case 'BusinessCenter' : return (<BusinessCenterIcon  sx={{fontSize: 20}}/> )
            case 'Cabin' : return (<CabinIcon  sx={{fontSize: 20}}/> )
            case 'Cable' : return (<CableIcon  sx={{fontSize: 20}}/> )
            case 'Cached' : return (<CachedIcon  sx={{fontSize: 20}}/> )
            case 'Cake' : return (<CakeIcon  sx={{fontSize: 20}}/> )
            case 'Calculate' : return (<CalculateIcon  sx={{fontSize: 20}}/> )
            case 'CalendarMonth' : return (<CalendarMonthIcon  sx={{fontSize: 20}}/> )
            case 'CalendarToday' : return (<CalendarTodayIcon  sx={{fontSize: 20}}/> )
            case 'CalendarViewDay' : return (<CalendarViewDayIcon  sx={{fontSize: 20}}/> )
            case 'CalendarViewMonth' : return (<CalendarViewMonthIcon  sx={{fontSize: 20}}/> )
            case 'CalendarViewWeek' : return (<CalendarViewWeekIcon  sx={{fontSize: 20}}/> )
            case 'Call' : return (<CallIcon  sx={{fontSize: 20}}/> )
            case 'CallEnd' : return (<CallEndIcon  sx={{fontSize: 20}}/> )
            case 'CallMade' : return (<CallMadeIcon  sx={{fontSize: 20}}/> )
            case 'CallMerge' : return (<CallMergeIcon  sx={{fontSize: 20}}/> )
            case 'CallMissed' : return (<CallMissedIcon  sx={{fontSize: 20}}/> )
            case 'CallMissedOutgoing' : return (<CallMissedOutgoingIcon  sx={{fontSize: 20}}/> )
            case 'CallReceived' : return (<CallReceivedIcon  sx={{fontSize: 20}}/> )
            case 'CallSplit' : return (<CallSplitIcon  sx={{fontSize: 20}}/> )
            case 'CallToAction' : return (<CallToActionIcon  sx={{fontSize: 20}}/> )
            case 'Camera' : return (<CameraIcon  sx={{fontSize: 20}}/> )
            case 'CameraAlt' : return (<CameraAltIcon  sx={{fontSize: 20}}/> )
            case 'CameraEnhance' : return (<CameraEnhanceIcon  sx={{fontSize: 20}}/> )
            case 'CameraFront' : return (<CameraFrontIcon  sx={{fontSize: 20}}/> )
            case 'CameraIndoor' : return (<CameraIndoorIcon  sx={{fontSize: 20}}/> )
            case 'CameraOutdoor' : return (<CameraOutdoorIcon  sx={{fontSize: 20}}/> )
            case 'CameraRear' : return (<CameraRearIcon  sx={{fontSize: 20}}/> )
            case 'CameraRoll' : return (<CameraRollIcon  sx={{fontSize: 20}}/> )
            case 'Cameraswitch' : return (<CameraswitchIcon  sx={{fontSize: 20}}/> )
            case 'Campaign' : return (<CampaignIcon  sx={{fontSize: 20}}/> )
            case 'Cancel' : return (<CancelIcon  sx={{fontSize: 20}}/> )
            case 'CancelPresentation' : return (<CancelPresentationIcon  sx={{fontSize: 20}}/> )
            case 'CancelScheduleSend' : return (<CancelScheduleSendIcon  sx={{fontSize: 20}}/> )
            case 'CandlestickChart' : return (<CandlestickChartIcon  sx={{fontSize: 20}}/> )
            case 'CarCrash' : return (<CarCrashIcon  sx={{fontSize: 20}}/> )
            case 'CarRental' : return (<CarRentalIcon  sx={{fontSize: 20}}/> )
            case 'CarRepair' : return (<CarRepairIcon  sx={{fontSize: 20}}/> )
            case 'CardGiftcard' : return (<CardGiftcardIcon  sx={{fontSize: 20}}/> )
            case 'CardMembership' : return (<CardMembershipIcon  sx={{fontSize: 20}}/> )
            case 'CardTravel' : return (<CardTravelIcon  sx={{fontSize: 20}}/> )
            case 'Carpenter' : return (<CarpenterIcon  sx={{fontSize: 20}}/> )
            case 'Cases' : return (<CasesIcon  sx={{fontSize: 20}}/> )
            case 'Casino' : return (<CasinoIcon  sx={{fontSize: 20}}/> )
            case 'Cast' : return (<CastIcon  sx={{fontSize: 20}}/> )
            case 'CastConnected' : return (<CastConnectedIcon  sx={{fontSize: 20}}/> )
            case 'CastForEducation' : return (<CastForEducationIcon  sx={{fontSize: 20}}/> )
            case 'Castle' : return (<CastleIcon  sx={{fontSize: 20}}/> )
            case 'CatchingPokemon' : return (<CatchingPokemonIcon  sx={{fontSize: 20}}/> )
            case 'Category' : return (<CategoryIcon  sx={{fontSize: 20}}/> )
            case 'Celebration' : return (<CelebrationIcon  sx={{fontSize: 20}}/> )
            case 'CellTower' : return (<CellTowerIcon  sx={{fontSize: 20}}/> )
            case 'CellWifi' : return (<CellWifiIcon  sx={{fontSize: 20}}/> )
            case 'CenterFocusStrong' : return (<CenterFocusStrongIcon  sx={{fontSize: 20}}/> )
            case 'CenterFocusWeak' : return (<CenterFocusWeakIcon  sx={{fontSize: 20}}/> )
            case 'Chair' : return (<ChairIcon  sx={{fontSize: 20}}/> )
            case 'ChairAlt' : return (<ChairAltIcon  sx={{fontSize: 20}}/> )
            case 'Chalet' : return (<ChaletIcon  sx={{fontSize: 20}}/> )
            case 'ChangeCircle' : return (<ChangeCircleIcon  sx={{fontSize: 20}}/> )
            case 'ChangeHistory' : return (<ChangeHistoryIcon  sx={{fontSize: 20}}/> )
            case 'ChargingStation' : return (<ChargingStationIcon  sx={{fontSize: 20}}/> )
            case 'Chat' : return (<ChatIcon  sx={{fontSize: 20}}/> )
            case 'ChatBubble' : return (<ChatBubbleIcon  sx={{fontSize: 20}}/> )
            case 'ChatBubbleOutline' : return (<ChatBubbleOutlineIcon  sx={{fontSize: 20}}/> )
            case 'Check' : return (<CheckIcon  sx={{fontSize: 20}}/> )
            case 'CheckBox' : return (<CheckBoxIcon  sx={{fontSize: 20}}/> )
            case 'CheckBoxOutlineBlank' : return (<CheckBoxOutlineBlankIcon  sx={{fontSize: 20}}/> )
            case 'CheckCircle' : return (<CheckCircleIcon  sx={{fontSize: 20}}/> )
            case 'CheckCircleOutline' : return (<CheckCircleOutlineIcon  sx={{fontSize: 20}}/> )
            case 'Checkroom' : return (<CheckroomIcon  sx={{fontSize: 20}}/> )
            case 'ChevronLeft' : return (<ChevronLeftIcon  sx={{fontSize: 20}}/> )
            case 'ChevronRight' : return (<ChevronRightIcon  sx={{fontSize: 20}}/> )
            case 'ChildCare' : return (<ChildCareIcon  sx={{fontSize: 20}}/> )
            case 'ChildFriendly' : return (<ChildFriendlyIcon  sx={{fontSize: 20}}/> )
            case 'ChromeReaderMode' : return (<ChromeReaderModeIcon  sx={{fontSize: 20}}/> )
            case 'Church' : return (<ChurchIcon  sx={{fontSize: 20}}/> )
            case 'Circle' : return (<CircleIcon  sx={{fontSize: 20}}/> )
            case 'CircleNotifications' : return (<CircleNotificationsIcon  sx={{fontSize: 20}}/> )
            case 'Class' : return (<ClassIcon  sx={{fontSize: 20}}/> )
            case 'CleanHands' : return (<CleanHandsIcon  sx={{fontSize: 20}}/> )
            case 'CleaningServices' : return (<CleaningServicesIcon  sx={{fontSize: 20}}/> )
            case 'Clear' : return (<ClearIcon  sx={{fontSize: 20}}/> )
            case 'ClearAll' : return (<ClearAllIcon  sx={{fontSize: 20}}/> )
            case 'Close' : return (<CloseIcon  sx={{fontSize: 20}}/> )
            case 'CloseFullscreen' : return (<CloseFullscreenIcon  sx={{fontSize: 20}}/> )
            case 'ClosedCaption' : return (<ClosedCaptionIcon  sx={{fontSize: 20}}/> )
            case 'ClosedCaptionDisabled' : return (<ClosedCaptionDisabledIcon  sx={{fontSize: 20}}/> )
            case 'ClosedCaptionOff' : return (<ClosedCaptionOffIcon  sx={{fontSize: 20}}/> )
            case 'Cloud' : return (<CloudIcon  sx={{fontSize: 20}}/> )
            case 'CloudCircle' : return (<CloudCircleIcon  sx={{fontSize: 20}}/> )
            case 'CloudDone' : return (<CloudDoneIcon  sx={{fontSize: 20}}/> )
            case 'CloudDownload' : return (<CloudDownloadIcon  sx={{fontSize: 20}}/> )
            case 'CloudOff' : return (<CloudOffIcon  sx={{fontSize: 20}}/> )
            case 'CloudQueue' : return (<CloudQueueIcon  sx={{fontSize: 20}}/> )
            case 'CloudSync' : return (<CloudSyncIcon  sx={{fontSize: 20}}/> )
            case 'CloudUpload' : return (<CloudUploadIcon  sx={{fontSize: 20}}/> )
            case 'Co2' : return (<Co2Icon  sx={{fontSize: 20}}/> )
            case 'CoPresent' : return (<CoPresentIcon  sx={{fontSize: 20}}/> )
            case 'Code' : return (<CodeIcon  sx={{fontSize: 20}}/> )
            case 'CodeOff' : return (<CodeOffIcon  sx={{fontSize: 20}}/> )
            case 'Coffee' : return (<CoffeeIcon  sx={{fontSize: 20}}/> )
            case 'CoffeeMaker' : return (<CoffeeMakerIcon  sx={{fontSize: 20}}/> )
            case 'Collections' : return (<CollectionsIcon  sx={{fontSize: 20}}/> )
            case 'CollectionsBookmark' : return (<CollectionsBookmarkIcon  sx={{fontSize: 20}}/> )
            case 'ColorLens' : return (<ColorLensIcon  sx={{fontSize: 20}}/> )
            case 'Colorize' : return (<ColorizeIcon  sx={{fontSize: 20}}/> )
            case 'Comment' : return (<CommentIcon  sx={{fontSize: 20}}/> )
            case 'CommentBank' : return (<CommentBankIcon  sx={{fontSize: 20}}/> )
            case 'CommentsDisabled' : return (<CommentsDisabledIcon  sx={{fontSize: 20}}/> )
            case 'Commit' : return (<CommitIcon  sx={{fontSize: 20}}/> )
            case 'Commute' : return (<CommuteIcon  sx={{fontSize: 20}}/> )
            case 'Compare' : return (<CompareIcon  sx={{fontSize: 20}}/> )
            case 'CompareArrows' : return (<CompareArrowsIcon  sx={{fontSize: 20}}/> )
            case 'CompassCalibration' : return (<CompassCalibrationIcon  sx={{fontSize: 20}}/> )
            case 'Compress' : return (<CompressIcon  sx={{fontSize: 20}}/> )
            case 'Computer' : return (<ComputerIcon  sx={{fontSize: 20}}/> )
            case 'ConfirmationNumber' : return (<ConfirmationNumberIcon  sx={{fontSize: 20}}/> )
            case 'ConnectWithoutContact' : return (<ConnectWithoutContactIcon  sx={{fontSize: 20}}/> )
            case 'ConnectedTv' : return (<ConnectedTvIcon  sx={{fontSize: 20}}/> )
            case 'ConnectingAirports' : return (<ConnectingAirportsIcon  sx={{fontSize: 20}}/> )
            case 'Construction' : return (<ConstructionIcon  sx={{fontSize: 20}}/> )
            //case 'ContactEmergency' : return (<ContactEmergencyIcon  sx={{fontSize: 20}}/> )
            case 'ContactMail' : return (<ContactMailIcon  sx={{fontSize: 20}}/> )
            case 'ContactPage' : return (<ContactPageIcon  sx={{fontSize: 20}}/> )
            case 'ContactPhone' : return (<ContactPhoneIcon  sx={{fontSize: 20}}/> )
            case 'ContactSupport' : return (<ContactSupportIcon  sx={{fontSize: 20}}/> )
            case 'Contactless' : return (<ContactlessIcon  sx={{fontSize: 20}}/> )
            case 'Contacts' : return (<ContactsIcon  sx={{fontSize: 20}}/> )
            case 'ContentCopy' : return (<ContentCopyIcon  sx={{fontSize: 20}}/> )
            case 'ContentCut' : return (<ContentCutIcon  sx={{fontSize: 20}}/> )
            case 'ContentPaste' : return (<ContentPasteIcon  sx={{fontSize: 20}}/> )
            case 'ContentPasteGo' : return (<ContentPasteGoIcon  sx={{fontSize: 20}}/> )
            case 'ContentPasteOff' : return (<ContentPasteOffIcon  sx={{fontSize: 20}}/> )
            case 'ContentPasteSearch' : return (<ContentPasteSearchIcon  sx={{fontSize: 20}}/> )
            case 'Contrast' : return (<ContrastIcon  sx={{fontSize: 20}}/> )
            case 'ControlCamera' : return (<ControlCameraIcon  sx={{fontSize: 20}}/> )
            case 'ControlPoint' : return (<ControlPointIcon  sx={{fontSize: 20}}/> )
            case 'ControlPointDuplicate' : return (<ControlPointDuplicateIcon  sx={{fontSize: 20}}/> )
            case 'Cookie' : return (<CookieIcon  sx={{fontSize: 20}}/> )
            case 'CopyAll' : return (<CopyAllIcon  sx={{fontSize: 20}}/> )
            case 'Copyright' : return (<CopyrightIcon  sx={{fontSize: 20}}/> )
            case 'Coronavirus' : return (<CoronavirusIcon  sx={{fontSize: 20}}/> )
            case 'CorporateFare' : return (<CorporateFareIcon  sx={{fontSize: 20}}/> )
            case 'Cottage' : return (<CottageIcon  sx={{fontSize: 20}}/> )
            case 'Countertops' : return (<CountertopsIcon  sx={{fontSize: 20}}/> )
            case 'Create' : return (<CreateIcon  sx={{fontSize: 20}}/> )
            case 'CreateNewFolder' : return (<CreateNewFolderIcon  sx={{fontSize: 20}}/> )
            case 'CreditCard' : return (<CreditCardIcon  sx={{fontSize: 20}}/> )
            case 'CreditCardOff' : return (<CreditCardOffIcon  sx={{fontSize: 20}}/> )
            case 'CreditScore' : return (<CreditScoreIcon  sx={{fontSize: 20}}/> )
            case 'Crib' : return (<CribIcon  sx={{fontSize: 20}}/> )
            case 'CrisisAlert' : return (<CrisisAlertIcon  sx={{fontSize: 20}}/> )
            case 'Crop' : return (<CropIcon  sx={{fontSize: 20}}/> )
            case 'Crop169' : return (<Crop169Icon  sx={{fontSize: 20}}/> )
            case 'Crop32' : return (<Crop32Icon  sx={{fontSize: 20}}/> )
            case 'Crop54' : return (<Crop54Icon  sx={{fontSize: 20}}/> )
            case 'Crop75' : return (<Crop75Icon  sx={{fontSize: 20}}/> )
            case 'CropDin' : return (<CropDinIcon  sx={{fontSize: 20}}/> )
            case 'CropFree' : return (<CropFreeIcon  sx={{fontSize: 20}}/> )
            case 'CropLandscape' : return (<CropLandscapeIcon  sx={{fontSize: 20}}/> )
            case 'CropOriginal' : return (<CropOriginalIcon  sx={{fontSize: 20}}/> )
            case 'CropPortrait' : return (<CropPortraitIcon  sx={{fontSize: 20}}/> )
            case 'CropRotate' : return (<CropRotateIcon  sx={{fontSize: 20}}/> )
            case 'CropSquare' : return (<CropSquareIcon  sx={{fontSize: 20}}/> )
            case 'Css' : return (<CssIcon  sx={{fontSize: 20}}/> )
            case 'CurrencyBitcoin' : return (<CurrencyBitcoinIcon  sx={{fontSize: 20}}/> )
            case 'CurrencyExchange' : return (<CurrencyExchangeIcon  sx={{fontSize: 20}}/> )
            case 'CurrencyFranc' : return (<CurrencyFrancIcon  sx={{fontSize: 20}}/> )
            case 'CurrencyLira' : return (<CurrencyLiraIcon  sx={{fontSize: 20}}/> )
            case 'CurrencyPound' : return (<CurrencyPoundIcon  sx={{fontSize: 20}}/> )
            case 'CurrencyRuble' : return (<CurrencyRubleIcon  sx={{fontSize: 20}}/> )
            case 'CurrencyRupee' : return (<CurrencyRupeeIcon  sx={{fontSize: 20}}/> )
            case 'CurrencyYen' : return (<CurrencyYenIcon  sx={{fontSize: 20}}/> )
            case 'CurrencyYuan' : return (<CurrencyYuanIcon  sx={{fontSize: 20}}/> )
            case 'Curtains' : return (<CurtainsIcon  sx={{fontSize: 20}}/> )
            case 'CurtainsClosed' : return (<CurtainsClosedIcon  sx={{fontSize: 20}}/> )
            case 'Cyclone' : return (<CycloneIcon  sx={{fontSize: 20}}/> )
            case 'Dangerous' : return (<DangerousIcon  sx={{fontSize: 20}}/> )
            case 'DarkMode' : return (<DarkModeIcon  sx={{fontSize: 20}}/> )
            case 'Dashboard' : return (<DashboardIcon  sx={{fontSize: 20}}/> )
            case 'DashboardCustomize' : return (<DashboardCustomizeIcon  sx={{fontSize: 20}}/> )
            case 'DataArray' : return (<DataArrayIcon  sx={{fontSize: 20}}/> )
            case 'DataObject' : return (<DataObjectIcon  sx={{fontSize: 20}}/> )
            case 'DataSaverOff' : return (<DataSaverOffIcon  sx={{fontSize: 20}}/> )
            case 'DataSaverOn' : return (<DataSaverOnIcon  sx={{fontSize: 20}}/> )
            case 'DataThresholding' : return (<DataThresholdingIcon  sx={{fontSize: 20}}/> )
            case 'DataUsage' : return (<DataUsageIcon  sx={{fontSize: 20}}/> )
            //case 'Dataset' : return (<DatasetIcon  sx={{fontSize: 20}}/> )
            //case 'DatasetLinked' : return (<DatasetLinkedIcon  sx={{fontSize: 20}}/> )
            case 'DateRange' : return (<DateRangeIcon  sx={{fontSize: 20}}/> )
            case 'Deblur' : return (<DeblurIcon  sx={{fontSize: 20}}/> )
            case 'Deck' : return (<DeckIcon  sx={{fontSize: 20}}/> )
            case 'Dehaze' : return (<DehazeIcon  sx={{fontSize: 20}}/> )
            case 'Delete' : return (<DeleteIcon  sx={{fontSize: 20}}/> )
            case 'DeleteForever' : return (<DeleteForeverIcon  sx={{fontSize: 20}}/> )
            case 'DeleteOutline' : return (<DeleteOutlineIcon  sx={{fontSize: 20}}/> )
            case 'DeleteSweep' : return (<DeleteSweepIcon  sx={{fontSize: 20}}/> )
            case 'DeliveryDining' : return (<DeliveryDiningIcon  sx={{fontSize: 20}}/> )
            case 'DensityLarge' : return (<DensityLargeIcon  sx={{fontSize: 20}}/> )
            case 'DensityMedium' : return (<DensityMediumIcon  sx={{fontSize: 20}}/> )
            case 'DensitySmall' : return (<DensitySmallIcon  sx={{fontSize: 20}}/> )
            case 'DepartureBoard' : return (<DepartureBoardIcon  sx={{fontSize: 20}}/> )
            case 'Description' : return (<DescriptionIcon  sx={{fontSize: 20}}/> )
            case 'Deselect' : return (<DeselectIcon  sx={{fontSize: 20}}/> )
            case 'DesignServices' : return (<DesignServicesIcon  sx={{fontSize: 20}}/> )
            //case 'Desk' : return (<DeskIcon  sx={{fontSize: 20}}/> )
            case 'DesktopAccessDisabled' : return (<DesktopAccessDisabledIcon  sx={{fontSize: 20}}/> )
            case 'DesktopMac' : return (<DesktopMacIcon  sx={{fontSize: 20}}/> )
            case 'DesktopWindows' : return (<DesktopWindowsIcon  sx={{fontSize: 20}}/> )
            case 'Details' : return (<DetailsIcon  sx={{fontSize: 20}}/> )
            case 'DeveloperBoard' : return (<DeveloperBoardIcon  sx={{fontSize: 20}}/> )
            case 'DeveloperBoardOff' : return (<DeveloperBoardOffIcon  sx={{fontSize: 20}}/> )
            case 'DeveloperMode' : return (<DeveloperModeIcon  sx={{fontSize: 20}}/> )
            case 'DeviceHub' : return (<DeviceHubIcon  sx={{fontSize: 20}}/> )
            case 'DeviceThermostat' : return (<DeviceThermostatIcon  sx={{fontSize: 20}}/> )
            case 'DeviceUnknown' : return (<DeviceUnknownIcon  sx={{fontSize: 20}}/> )
            case 'Devices' : return (<DevicesIcon  sx={{fontSize: 20}}/> )
            case 'DevicesFold' : return (<DevicesFoldIcon  sx={{fontSize: 20}}/> )
            case 'DevicesOther' : return (<DevicesOtherIcon  sx={{fontSize: 20}}/> )
            case 'DialerSip' : return (<DialerSipIcon  sx={{fontSize: 20}}/> )
            case 'Dialpad' : return (<DialpadIcon  sx={{fontSize: 20}}/> )
            case 'Diamond' : return (<DiamondIcon  sx={{fontSize: 20}}/> )
            case 'Difference' : return (<DifferenceIcon  sx={{fontSize: 20}}/> )
            case 'Dining' : return (<DiningIcon  sx={{fontSize: 20}}/> )
            case 'DinnerDining' : return (<DinnerDiningIcon  sx={{fontSize: 20}}/> )
            case 'Directions' : return (<DirectionsIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsBike' : return (<DirectionsBikeIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsBoat' : return (<DirectionsBoatIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsBoatFilled' : return (<DirectionsBoatFilledIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsBus' : return (<DirectionsBusIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsBusFilled' : return (<DirectionsBusFilledIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsCar' : return (<DirectionsCarIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsCarFilled' : return (<DirectionsCarFilledIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsOff' : return (<DirectionsOffIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsRailway' : return (<DirectionsRailwayIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsRailwayFilled' : return (<DirectionsRailwayFilledIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsRun' : return (<DirectionsRunIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsSubway' : return (<DirectionsSubwayIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsSubwayFilled' : return (<DirectionsSubwayFilledIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsTransit' : return (<DirectionsTransitIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsTransitFilled' : return (<DirectionsTransitFilledIcon  sx={{fontSize: 20}}/> )
            case 'DirectionsWalk' : return (<DirectionsWalkIcon  sx={{fontSize: 20}}/> )
            case 'DirtyLens' : return (<DirtyLensIcon  sx={{fontSize: 20}}/> )
            case 'DisabledByDefault' : return (<DisabledByDefaultIcon  sx={{fontSize: 20}}/> )
            case 'DiscFull' : return (<DiscFullIcon  sx={{fontSize: 20}}/> )
            case 'Discount' : return (<DiscountIcon  sx={{fontSize: 20}}/> )
            case 'DisplaySettings' : return (<DisplaySettingsIcon  sx={{fontSize: 20}}/> )
            // case 'Diversity1' : return (<Diversity1Icon  sx={{fontSize: 20}}/> )
            // case 'Diversity2' : return (<Diversity2Icon  sx={{fontSize: 20}}/> )
            // case 'Diversity3' : return (<Diversity3Icon  sx={{fontSize: 20}}/> )
            case 'Dns' : return (<DnsIcon  sx={{fontSize: 20}}/> )
            case 'DoDisturb' : return (<DoDisturbIcon  sx={{fontSize: 20}}/> )
            case 'DoDisturbAlt' : return (<DoDisturbAltIcon  sx={{fontSize: 20}}/> )
            case 'DoDisturbOff' : return (<DoDisturbOffIcon  sx={{fontSize: 20}}/> )
            case 'DoDisturbOn' : return (<DoDisturbOnIcon  sx={{fontSize: 20}}/> )
            case 'DoNotDisturb' : return (<DoNotDisturbIcon  sx={{fontSize: 20}}/> )
            case 'DoNotDisturbAlt' : return (<DoNotDisturbAltIcon  sx={{fontSize: 20}}/> )
            case 'DoNotDisturbOff' : return (<DoNotDisturbOffIcon  sx={{fontSize: 20}}/> )
            case 'DoNotDisturbOn' : return (<DoNotDisturbOnIcon  sx={{fontSize: 20}}/> )
            case 'DoNotDisturbOnTotalSilence' : return (<DoNotDisturbOnTotalSilenceIcon  sx={{fontSize: 20}}/> )
            case 'DoNotStep' : return (<DoNotStepIcon  sx={{fontSize: 20}}/> )
            case 'DoNotTouch' : return (<DoNotTouchIcon  sx={{fontSize: 20}}/> )
            case 'Dock' : return (<DockIcon  sx={{fontSize: 20}}/> )
            case 'DocumentScanner' : return (<DocumentScannerIcon  sx={{fontSize: 20}}/> )
            case 'Domain' : return (<DomainIcon  sx={{fontSize: 20}}/> )
            case 'DomainAdd' : return (<DomainAddIcon  sx={{fontSize: 20}}/> )
            case 'DomainDisabled' : return (<DomainDisabledIcon  sx={{fontSize: 20}}/> )
            case 'DomainVerification' : return (<DomainVerificationIcon  sx={{fontSize: 20}}/> )
            case 'Done' : return (<DoneIcon  sx={{fontSize: 20}}/> )
            case 'DoneAll' : return (<DoneAllIcon  sx={{fontSize: 20}}/> )
            case 'DoneOutline' : return (<DoneOutlineIcon  sx={{fontSize: 20}}/> )
            case 'DonutLarge' : return (<DonutLargeIcon  sx={{fontSize: 20}}/> )
            case 'DonutSmall' : return (<DonutSmallIcon  sx={{fontSize: 20}}/> )
            case 'DoorBack' : return (<DoorBackIcon  sx={{fontSize: 20}}/> )
            case 'DoorFront' : return (<DoorFrontIcon  sx={{fontSize: 20}}/> )
            case 'DoorSliding' : return (<DoorSlidingIcon  sx={{fontSize: 20}}/> )
            case 'Doorbell' : return (<DoorbellIcon  sx={{fontSize: 20}}/> )
            case 'DoubleArrow' : return (<DoubleArrowIcon  sx={{fontSize: 20}}/> )
            case 'DownhillSkiing' : return (<DownhillSkiingIcon  sx={{fontSize: 20}}/> )
            case 'Download' : return (<DownloadIcon  sx={{fontSize: 20}}/> )
            case 'DownloadDone' : return (<DownloadDoneIcon  sx={{fontSize: 20}}/> )
            case 'DownloadForOffline' : return (<DownloadForOfflineIcon  sx={{fontSize: 20}}/> )
            case 'Downloading' : return (<DownloadingIcon  sx={{fontSize: 20}}/> )
            case 'Drafts' : return (<DraftsIcon  sx={{fontSize: 20}}/> )
            case 'DragHandle' : return (<DragHandleIcon  sx={{fontSize: 20}}/> )
            case 'DragIndicator' : return (<DragIndicatorIcon  sx={{fontSize: 20}}/> )
            case 'DriveEta' : return (<DriveEtaIcon  sx={{fontSize: 20}}/> )
            case 'DriveFileMove' : return (<DriveFileMoveIcon  sx={{fontSize: 20}}/> )
            case 'DriveFileRenameOutline' : return (<DriveFileRenameOutlineIcon  sx={{fontSize: 20}}/> )
            case 'DriveFolderUpload' : return (<DriveFolderUploadIcon  sx={{fontSize: 20}}/> )
            case 'Dry' : return (<DryIcon  sx={{fontSize: 20}}/> )
            case 'DryCleaning' : return (<DryCleaningIcon  sx={{fontSize: 20}}/> )
            case 'Duo' : return (<DuoIcon  sx={{fontSize: 20}}/> )
            case 'Dvr' : return (<DvrIcon  sx={{fontSize: 20}}/> )
            case 'DynamicFeed' : return (<DynamicFeedIcon  sx={{fontSize: 20}}/> )
            case 'DynamicForm' : return (<DynamicFormIcon  sx={{fontSize: 20}}/> )
            case 'EMobiledata' : return (<EMobiledataIcon  sx={{fontSize: 20}}/> )
            case 'Earbuds' : return (<EarbudsIcon  sx={{fontSize: 20}}/> )
            case 'EarbudsBattery' : return (<EarbudsBatteryIcon  sx={{fontSize: 20}}/> )
            case 'East' : return (<EastIcon  sx={{fontSize: 20}}/> )
            case 'EdgesensorHigh' : return (<EdgesensorHighIcon  sx={{fontSize: 20}}/> )
            case 'EdgesensorLow' : return (<EdgesensorLowIcon  sx={{fontSize: 20}}/> )
            case 'Edit' : return (<EditIcon  sx={{fontSize: 20}}/> )
            case 'EditAttributes' : return (<EditAttributesIcon  sx={{fontSize: 20}}/> )
            case 'EditLocation' : return (<EditLocationIcon  sx={{fontSize: 20}}/> )
            case 'EditLocationAlt' : return (<EditLocationAltIcon  sx={{fontSize: 20}}/> )
            case 'EditNotifications' : return (<EditNotificationsIcon  sx={{fontSize: 20}}/> )
            case 'EditOff' : return (<EditOffIcon  sx={{fontSize: 20}}/> )
            case 'EditRoad' : return (<EditRoadIcon  sx={{fontSize: 20}}/> )
            case 'Egg' : return (<EggIcon  sx={{fontSize: 20}}/> )
            case 'EggAlt' : return (<EggAltIcon  sx={{fontSize: 20}}/> )
            case 'EightK' : return (<EightKIcon  sx={{fontSize: 20}}/> )
            case 'EightKPlus' : return (<EightKPlusIcon  sx={{fontSize: 20}}/> )
            case 'EightMp' : return (<EightMpIcon  sx={{fontSize: 20}}/> )
            case 'EighteenMp' : return (<EighteenMpIcon  sx={{fontSize: 20}}/> )
            //case 'EighteenUpRating' : return (<EighteenUpRatingIcon  sx={{fontSize: 20}}/> )
            case 'EightteenMp' : return (<EightteenMpIcon  sx={{fontSize: 20}}/> )
            case 'Eject' : return (<EjectIcon  sx={{fontSize: 20}}/> )
            case 'Elderly' : return (<ElderlyIcon  sx={{fontSize: 20}}/> )
            case 'ElderlyWoman' : return (<ElderlyWomanIcon  sx={{fontSize: 20}}/> )
            case 'ElectricBike' : return (<ElectricBikeIcon  sx={{fontSize: 20}}/> )
            case 'ElectricBolt' : return (<ElectricBoltIcon  sx={{fontSize: 20}}/> )
            case 'ElectricCar' : return (<ElectricCarIcon  sx={{fontSize: 20}}/> )
            case 'ElectricMeter' : return (<ElectricMeterIcon  sx={{fontSize: 20}}/> )
            case 'ElectricMoped' : return (<ElectricMopedIcon  sx={{fontSize: 20}}/> )
            case 'ElectricRickshaw' : return (<ElectricRickshawIcon  sx={{fontSize: 20}}/> )
            case 'ElectricScooter' : return (<ElectricScooterIcon  sx={{fontSize: 20}}/> )
            case 'ElectricalServices' : return (<ElectricalServicesIcon  sx={{fontSize: 20}}/> )
            case 'Elevator' : return (<ElevatorIcon  sx={{fontSize: 20}}/> )
            case 'ElevenMp' : return (<ElevenMpIcon  sx={{fontSize: 20}}/> )
            case 'Email' : return (<EmailIcon  sx={{fontSize: 20}}/> )
            case 'EmergencyRecording' : return (<EmergencyRecordingIcon  sx={{fontSize: 20}}/> )
            case 'EmergencyShare' : return (<EmergencyShareIcon  sx={{fontSize: 20}}/> )
            case 'EmojiEmotions' : return (<EmojiEmotionsIcon  sx={{fontSize: 20}}/> )
            case 'EmojiEvents' : return (<EmojiEventsIcon  sx={{fontSize: 20}}/> )
            case 'EmojiFlags' : return (<EmojiFlagsIcon  sx={{fontSize: 20}}/> )
            case 'EmojiFoodBeverage' : return (<EmojiFoodBeverageIcon  sx={{fontSize: 20}}/> )
            case 'EmojiNature' : return (<EmojiNatureIcon  sx={{fontSize: 20}}/> )
            case 'EmojiObjects' : return (<EmojiObjectsIcon  sx={{fontSize: 20}}/> )
            case 'EmojiPeople' : return (<EmojiPeopleIcon  sx={{fontSize: 20}}/> )
            case 'EmojiSymbols' : return (<EmojiSymbolsIcon  sx={{fontSize: 20}}/> )
            case 'EmojiTransportation' : return (<EmojiTransportationIcon  sx={{fontSize: 20}}/> )
            case 'EnergySavingsLeaf' : return (<EnergySavingsLeafIcon  sx={{fontSize: 20}}/> )
            case 'Engineering' : return (<EngineeringIcon  sx={{fontSize: 20}}/> )
            case 'EnhancedEncryption' : return (<EnhancedEncryptionIcon  sx={{fontSize: 20}}/> )
            case 'Equalizer' : return (<EqualizerIcon  sx={{fontSize: 20}}/> )
            case 'Error' : return (<ErrorIcon  sx={{fontSize: 20}}/> )
            case 'ErrorOutline' : return (<ErrorOutlineIcon  sx={{fontSize: 20}}/> )
            case 'Escalator' : return (<EscalatorIcon  sx={{fontSize: 20}}/> )
            case 'EscalatorWarning' : return (<EscalatorWarningIcon  sx={{fontSize: 20}}/> )
            case 'Euro' : return (<EuroIcon  sx={{fontSize: 20}}/> )
            case 'EuroSymbol' : return (<EuroSymbolIcon  sx={{fontSize: 20}}/> )
            case 'EvStation' : return (<EvStationIcon  sx={{fontSize: 20}}/> )
            case 'Event' : return (<EventIcon  sx={{fontSize: 20}}/> )
            case 'EventAvailable' : return (<EventAvailableIcon  sx={{fontSize: 20}}/> )
            case 'EventBusy' : return (<EventBusyIcon  sx={{fontSize: 20}}/> )
            case 'EventNote' : return (<EventNoteIcon  sx={{fontSize: 20}}/> )
            case 'EventRepeat' : return (<EventRepeatIcon  sx={{fontSize: 20}}/> )
            case 'EventSeat' : return (<EventSeatIcon  sx={{fontSize: 20}}/> )
            case 'ExitToApp' : return (<ExitToAppIcon  sx={{fontSize: 20}}/> )
            case 'Expand' : return (<ExpandIcon  sx={{fontSize: 20}}/> )
            case 'ExpandCircleDown' : return (<ExpandCircleDownIcon  sx={{fontSize: 20}}/> )
            case 'ExpandLess' : return (<ExpandLessIcon  sx={{fontSize: 20}}/> )
            case 'ExpandMore' : return (<ExpandMoreIcon  sx={{fontSize: 20}}/> )
            case 'Explicit' : return (<ExplicitIcon  sx={{fontSize: 20}}/> )
            case 'Explore' : return (<ExploreIcon  sx={{fontSize: 20}}/> )
            case 'ExploreOff' : return (<ExploreOffIcon  sx={{fontSize: 20}}/> )
            case 'Exposure' : return (<ExposureIcon  sx={{fontSize: 20}}/> )
            case 'Extension' : return (<ExtensionIcon  sx={{fontSize: 20}}/> )
            case 'ExtensionOff' : return (<ExtensionOffIcon  sx={{fontSize: 20}}/> )
            // case 'Face' : return (<FaceIcon  sx={{fontSize: 20}}/> )
            // case 'Face2' : return (<Face2Icon  sx={{fontSize: 20}}/> )
            // case 'Face3' : return (<Face3Icon  sx={{fontSize: 20}}/> )
            // case 'Face4' : return (<Face4Icon  sx={{fontSize: 20}}/> )
            // case 'Face5' : return (<Face5Icon  sx={{fontSize: 20}}/> )
            // case 'Face6' : return (<Face6Icon  sx={{fontSize: 20}}/> )
            case 'FaceRetouchingNatural' : return (<FaceRetouchingNaturalIcon  sx={{fontSize: 20}}/> )
            case 'FaceRetouchingOff' : return (<FaceRetouchingOffIcon  sx={{fontSize: 20}}/> )
            case 'Facebook' : return (<FacebookIcon  sx={{fontSize: 20}}/> )
            case 'FactCheck' : return (<FactCheckIcon  sx={{fontSize: 20}}/> )
            case 'Factory' : return (<FactoryIcon  sx={{fontSize: 20}}/> )
            case 'FamilyRestroom' : return (<FamilyRestroomIcon  sx={{fontSize: 20}}/> )
            case 'FastForward' : return (<FastForwardIcon  sx={{fontSize: 20}}/> )
            case 'FastRewind' : return (<FastRewindIcon  sx={{fontSize: 20}}/> )
            case 'Fastfood' : return (<FastfoodIcon  sx={{fontSize: 20}}/> )
            case 'Favorite' : return (<FavoriteIcon  sx={{fontSize: 20}}/> )
            case 'FavoriteBorder' : return (<FavoriteBorderIcon  sx={{fontSize: 20}}/> )
            case 'Fax' : return (<FaxIcon  sx={{fontSize: 20}}/> )
            case 'FeaturedPlayList' : return (<FeaturedPlayListIcon  sx={{fontSize: 20}}/> )
            case 'FeaturedVideo' : return (<FeaturedVideoIcon  sx={{fontSize: 20}}/> )
            case 'Feed' : return (<FeedIcon  sx={{fontSize: 20}}/> )
            case 'Feedback' : return (<FeedbackIcon  sx={{fontSize: 20}}/> )
            case 'Female' : return (<FemaleIcon  sx={{fontSize: 20}}/> )
            case 'Fence' : return (<FenceIcon  sx={{fontSize: 20}}/> )
            case 'Festival' : return (<FestivalIcon  sx={{fontSize: 20}}/> )
            case 'FiberDvr' : return (<FiberDvrIcon  sx={{fontSize: 20}}/> )
            case 'FiberManualRecord' : return (<FiberManualRecordIcon  sx={{fontSize: 20}}/> )
            case 'FiberNew' : return (<FiberNewIcon  sx={{fontSize: 20}}/> )
            case 'FiberPin' : return (<FiberPinIcon  sx={{fontSize: 20}}/> )
            case 'FiberSmartRecord' : return (<FiberSmartRecordIcon  sx={{fontSize: 20}}/> )
            case 'FifteenMp' : return (<FifteenMpIcon  sx={{fontSize: 20}}/> )
            case 'FileCopy' : return (<FileCopyIcon  sx={{fontSize: 20}}/> )
            case 'FileDownload' : return (<FileDownloadIcon  sx={{fontSize: 20}}/> )
            case 'FileDownloadDone' : return (<FileDownloadDoneIcon  sx={{fontSize: 20}}/> )
            case 'FileDownloadOff' : return (<FileDownloadOffIcon  sx={{fontSize: 20}}/> )
            case 'FileOpen' : return (<FileOpenIcon  sx={{fontSize: 20}}/> )
            case 'FilePresent' : return (<FilePresentIcon  sx={{fontSize: 20}}/> )
            case 'FileUpload' : return (<FileUploadIcon  sx={{fontSize: 20}}/> )
            case 'Filter' : return (<FilterIcon  sx={{fontSize: 20}}/> )
            case 'Filter1' : return (<Filter1Icon  sx={{fontSize: 20}}/> )
            case 'Filter2' : return (<Filter2Icon  sx={{fontSize: 20}}/> )
            case 'Filter3' : return (<Filter3Icon  sx={{fontSize: 20}}/> )
            case 'Filter4' : return (<Filter4Icon  sx={{fontSize: 20}}/> )
            case 'Filter5' : return (<Filter5Icon  sx={{fontSize: 20}}/> )
            case 'Filter6' : return (<Filter6Icon  sx={{fontSize: 20}}/> )
            case 'Filter7' : return (<Filter7Icon  sx={{fontSize: 20}}/> )
            case 'Filter8' : return (<Filter8Icon  sx={{fontSize: 20}}/> )
            case 'Filter9' : return (<Filter9Icon  sx={{fontSize: 20}}/> )
            case 'Filter9Plus' : return (<Filter9PlusIcon  sx={{fontSize: 20}}/> )
            case 'FilterAlt' : return (<FilterAltIcon  sx={{fontSize: 20}}/> )
            case 'FilterAltOff' : return (<FilterAltOffIcon  sx={{fontSize: 20}}/> )
            case 'FilterBAndW' : return (<FilterBAndWIcon  sx={{fontSize: 20}}/> )
            case 'FilterCenterFocus' : return (<FilterCenterFocusIcon  sx={{fontSize: 20}}/> )
            case 'FilterDrama' : return (<FilterDramaIcon  sx={{fontSize: 20}}/> )
            case 'FilterFrames' : return (<FilterFramesIcon  sx={{fontSize: 20}}/> )
            case 'FilterHdr' : return (<FilterHdrIcon  sx={{fontSize: 20}}/> )
            case 'FilterList' : return (<FilterListIcon  sx={{fontSize: 20}}/> )
            case 'FilterListOff' : return (<FilterListOffIcon  sx={{fontSize: 20}}/> )
            case 'FilterNone' : return (<FilterNoneIcon  sx={{fontSize: 20}}/> )
            case 'FilterTiltShift' : return (<FilterTiltShiftIcon  sx={{fontSize: 20}}/> )
            case 'FilterVintage' : return (<FilterVintageIcon  sx={{fontSize: 20}}/> )
            case 'FindInPage' : return (<FindInPageIcon  sx={{fontSize: 20}}/> )
            case 'FindReplace' : return (<FindReplaceIcon  sx={{fontSize: 20}}/> )
            case 'Fingerprint' : return (<FingerprintIcon  sx={{fontSize: 20}}/> )
            case 'FireExtinguisher' : return (<FireExtinguisherIcon  sx={{fontSize: 20}}/> )
            // case 'FireHydrantAlt' : return (<FireHydrantAltIcon  sx={{fontSize: 20}}/> )
            // case 'FireTruck' : return (<FireTruckIcon  sx={{fontSize: 20}}/> )
            case 'Fireplace' : return (<FireplaceIcon  sx={{fontSize: 20}}/> )
            case 'FirstPage' : return (<FirstPageIcon  sx={{fontSize: 20}}/> )
            case 'FitScreen' : return (<FitScreenIcon  sx={{fontSize: 20}}/> )
            case 'Fitbit' : return (<FitbitIcon  sx={{fontSize: 20}}/> )
            case 'FitnessCenter' : return (<FitnessCenterIcon  sx={{fontSize: 20}}/> )
            case 'FiveG' : return (<FiveGIcon  sx={{fontSize: 20}}/> )
            case 'FiveK' : return (<FiveKIcon  sx={{fontSize: 20}}/> )
            case 'FiveKPlus' : return (<FiveKPlusIcon  sx={{fontSize: 20}}/> )
            case 'FiveMp' : return (<FiveMpIcon  sx={{fontSize: 20}}/> )
            case 'FivteenMp' : return (<FivteenMpIcon  sx={{fontSize: 20}}/> )
            case 'Flag' : return (<FlagIcon  sx={{fontSize: 20}}/> )
            case 'FlagCircle' : return (<FlagCircleIcon  sx={{fontSize: 20}}/> )
            case 'Flaky' : return (<FlakyIcon  sx={{fontSize: 20}}/> )
            case 'Flare' : return (<FlareIcon  sx={{fontSize: 20}}/> )
            case 'FlashAuto' : return (<FlashAutoIcon  sx={{fontSize: 20}}/> )
            case 'FlashOff' : return (<FlashOffIcon  sx={{fontSize: 20}}/> )
            case 'FlashOn' : return (<FlashOnIcon  sx={{fontSize: 20}}/> )
            case 'FlashlightOff' : return (<FlashlightOffIcon  sx={{fontSize: 20}}/> )
            case 'FlashlightOn' : return (<FlashlightOnIcon  sx={{fontSize: 20}}/> )
            case 'Flatware' : return (<FlatwareIcon  sx={{fontSize: 20}}/> )
            case 'Flight' : return (<FlightIcon  sx={{fontSize: 20}}/> )
            case 'FlightClass' : return (<FlightClassIcon  sx={{fontSize: 20}}/> )
            case 'FlightLand' : return (<FlightLandIcon  sx={{fontSize: 20}}/> )
            case 'FlightTakeoff' : return (<FlightTakeoffIcon  sx={{fontSize: 20}}/> )
            case 'Flip' : return (<FlipIcon  sx={{fontSize: 20}}/> )
            case 'FlipCameraAndroid' : return (<FlipCameraAndroidIcon  sx={{fontSize: 20}}/> )
            case 'FlipCameraIos' : return (<FlipCameraIosIcon  sx={{fontSize: 20}}/> )
            case 'FlipToBack' : return (<FlipToBackIcon  sx={{fontSize: 20}}/> )
            case 'FlipToFront' : return (<FlipToFrontIcon  sx={{fontSize: 20}}/> )
            case 'Flood' : return (<FloodIcon  sx={{fontSize: 20}}/> )
            //case 'Fluorescent' : return (<FluorescentIcon  sx={{fontSize: 20}}/> )
            case 'FlutterDash' : return (<FlutterDashIcon  sx={{fontSize: 20}}/> )
            case 'FmdBad' : return (<FmdBadIcon  sx={{fontSize: 20}}/> )
            case 'FmdGood' : return (<FmdGoodIcon  sx={{fontSize: 20}}/> )
            case 'Folder' : return (<FolderIcon  sx={{fontSize: 20}}/> )
            case 'FolderCopy' : return (<FolderCopyIcon  sx={{fontSize: 20}}/> )
            case 'FolderDelete' : return (<FolderDeleteIcon  sx={{fontSize: 20}}/> )
            case 'FolderOff' : return (<FolderOffIcon  sx={{fontSize: 20}}/> )
            case 'FolderOpen' : return (<FolderOpenIcon  sx={{fontSize: 20}}/> )
            case 'FolderShared' : return (<FolderSharedIcon  sx={{fontSize: 20}}/> )
            case 'FolderSpecial' : return (<FolderSpecialIcon  sx={{fontSize: 20}}/> )
            case 'FolderZip' : return (<FolderZipIcon  sx={{fontSize: 20}}/> )
            case 'FollowTheSigns' : return (<FollowTheSignsIcon  sx={{fontSize: 20}}/> )
            case 'FontDownload' : return (<FontDownloadIcon  sx={{fontSize: 20}}/> )
            case 'FontDownloadOff' : return (<FontDownloadOffIcon  sx={{fontSize: 20}}/> )
            case 'FoodBank' : return (<FoodBankIcon  sx={{fontSize: 20}}/> )
            case 'Forest' : return (<ForestIcon  sx={{fontSize: 20}}/> )
            case 'ForkLeft' : return (<ForkLeftIcon  sx={{fontSize: 20}}/> )
            case 'ForkRight' : return (<ForkRightIcon  sx={{fontSize: 20}}/> )
            case 'FormatAlignCenter' : return (<FormatAlignCenterIcon  sx={{fontSize: 20}}/> )
            case 'FormatAlignJustify' : return (<FormatAlignJustifyIcon  sx={{fontSize: 20}}/> )
            case 'FormatAlignLeft' : return (<FormatAlignLeftIcon  sx={{fontSize: 20}}/> )
            case 'FormatAlignRight' : return (<FormatAlignRightIcon  sx={{fontSize: 20}}/> )
            case 'FormatBold' : return (<FormatBoldIcon  sx={{fontSize: 20}}/> )
            case 'FormatClear' : return (<FormatClearIcon  sx={{fontSize: 20}}/> )
            case 'FormatColorFill' : return (<FormatColorFillIcon  sx={{fontSize: 20}}/> )
            case 'FormatColorReset' : return (<FormatColorResetIcon  sx={{fontSize: 20}}/> )
            case 'FormatColorText' : return (<FormatColorTextIcon  sx={{fontSize: 20}}/> )
            case 'FormatIndentDecrease' : return (<FormatIndentDecreaseIcon  sx={{fontSize: 20}}/> )
            case 'FormatIndentIncrease' : return (<FormatIndentIncreaseIcon  sx={{fontSize: 20}}/> )
            case 'FormatItalic' : return (<FormatItalicIcon  sx={{fontSize: 20}}/> )
            case 'FormatLineSpacing' : return (<FormatLineSpacingIcon  sx={{fontSize: 20}}/> )
            case 'FormatListBulleted' : return (<FormatListBulletedIcon  sx={{fontSize: 20}}/> )
            case 'FormatListNumbered' : return (<FormatListNumberedIcon  sx={{fontSize: 20}}/> )
            case 'FormatListNumberedRtl' : return (<FormatListNumberedRtlIcon  sx={{fontSize: 20}}/> )
            case 'FormatOverline' : return (<FormatOverlineIcon  sx={{fontSize: 20}}/> )
            case 'FormatPaint' : return (<FormatPaintIcon  sx={{fontSize: 20}}/> )
            case 'FormatQuote' : return (<FormatQuoteIcon  sx={{fontSize: 20}}/> )
            case 'FormatShapes' : return (<FormatShapesIcon  sx={{fontSize: 20}}/> )
            case 'FormatSize' : return (<FormatSizeIcon  sx={{fontSize: 20}}/> )
            case 'FormatStrikethrough' : return (<FormatStrikethroughIcon  sx={{fontSize: 20}}/> )
            case 'FormatTextdirectionLToR' : return (<FormatTextdirectionLToRIcon  sx={{fontSize: 20}}/> )
            case 'FormatTextdirectionRToL' : return (<FormatTextdirectionRToLIcon  sx={{fontSize: 20}}/> )
            case 'FormatUnderlined' : return (<FormatUnderlinedIcon  sx={{fontSize: 20}}/> )
            case 'Fort' : return (<FortIcon  sx={{fontSize: 20}}/> )
            case 'Forum' : return (<ForumIcon  sx={{fontSize: 20}}/> )
            case 'Forward' : return (<ForwardIcon  sx={{fontSize: 20}}/> )
            case 'Forward10' : return (<Forward10Icon  sx={{fontSize: 20}}/> )
            case 'Forward30' : return (<Forward30Icon  sx={{fontSize: 20}}/> )
            case 'Forward5' : return (<Forward5Icon  sx={{fontSize: 20}}/> )
            case 'ForwardToInbox' : return (<ForwardToInboxIcon  sx={{fontSize: 20}}/> )
            case 'Foundation' : return (<FoundationIcon  sx={{fontSize: 20}}/> )
            case 'FourGMobiledata' : return (<FourGMobiledataIcon  sx={{fontSize: 20}}/> )
            case 'FourGPlusMobiledata' : return (<FourGPlusMobiledataIcon  sx={{fontSize: 20}}/> )
            case 'FourK' : return (<FourKIcon  sx={{fontSize: 20}}/> )
            case 'FourKPlus' : return (<FourKPlusIcon  sx={{fontSize: 20}}/> )
            case 'FourMp' : return (<FourMpIcon  sx={{fontSize: 20}}/> )
            case 'FourteenMp' : return (<FourteenMpIcon  sx={{fontSize: 20}}/> )
            case 'FreeBreakfast' : return (<FreeBreakfastIcon  sx={{fontSize: 20}}/> )
            case 'Fullscreen' : return (<FullscreenIcon  sx={{fontSize: 20}}/> )
            case 'FullscreenExit' : return (<FullscreenExitIcon  sx={{fontSize: 20}}/> )
            case 'Functions' : return (<FunctionsIcon  sx={{fontSize: 20}}/> )
            case 'GMobiledata' : return (<GMobiledataIcon  sx={{fontSize: 20}}/> )
            case 'GTranslate' : return (<GTranslateIcon  sx={{fontSize: 20}}/> )
            case 'Gamepad' : return (<GamepadIcon  sx={{fontSize: 20}}/> )
            case 'Games' : return (<GamesIcon  sx={{fontSize: 20}}/> )
            case 'Garage' : return (<GarageIcon  sx={{fontSize: 20}}/> )
            case 'GasMeter' : return (<GasMeterIcon  sx={{fontSize: 20}}/> )
            case 'Gavel' : return (<GavelIcon  sx={{fontSize: 20}}/> )
            case 'Gesture' : return (<GestureIcon  sx={{fontSize: 20}}/> )
            case 'GetApp' : return (<GetAppIcon  sx={{fontSize: 20}}/> )
            case 'Gif' : return (<GifIcon  sx={{fontSize: 20}}/> )
            case 'GifBox' : return (<GifBoxIcon  sx={{fontSize: 20}}/> )
            case 'Girl' : return (<GirlIcon  sx={{fontSize: 20}}/> )
            case 'GitHub' : return (<GitHubIcon  sx={{fontSize: 20}}/> )
            case 'Gite' : return (<GiteIcon  sx={{fontSize: 20}}/> )
            case 'GolfCourse' : return (<GolfCourseIcon  sx={{fontSize: 20}}/> )
            case 'Google' : return (<GoogleIcon  sx={{fontSize: 20}}/> )
            case 'GppBad' : return (<GppBadIcon  sx={{fontSize: 20}}/> )
            case 'GppGood' : return (<GppGoodIcon  sx={{fontSize: 20}}/> )
            case 'GppMaybe' : return (<GppMaybeIcon  sx={{fontSize: 20}}/> )
            case 'GpsFixed' : return (<GpsFixedIcon  sx={{fontSize: 20}}/> )
            case 'GpsNotFixed' : return (<GpsNotFixedIcon  sx={{fontSize: 20}}/> )
            case 'GpsOff' : return (<GpsOffIcon  sx={{fontSize: 20}}/> )
            case 'Grade' : return (<GradeIcon  sx={{fontSize: 20}}/> )
            case 'Gradient' : return (<GradientIcon  sx={{fontSize: 20}}/> )
            case 'Grading' : return (<GradingIcon  sx={{fontSize: 20}}/> )
            case 'Grain' : return (<GrainIcon  sx={{fontSize: 20}}/> )
            case 'GraphicEq' : return (<GraphicEqIcon  sx={{fontSize: 20}}/> )
            case 'Grass' : return (<GrassIcon  sx={{fontSize: 20}}/> )
            case 'Grid3x3' : return (<Grid3x3Icon  sx={{fontSize: 20}}/> )
            case 'Grid4x4' : return (<Grid4x4Icon  sx={{fontSize: 20}}/> )
            case 'GridGoldenratio' : return (<GridGoldenratioIcon  sx={{fontSize: 20}}/> )
            case 'GridOff' : return (<GridOffIcon  sx={{fontSize: 20}}/> )
            case 'GridOn' : return (<GridOnIcon  sx={{fontSize: 20}}/> )
            case 'GridView' : return (<GridViewIcon  sx={{fontSize: 20}}/> )
            case 'Group' : return (<GroupIcon  sx={{fontSize: 20}}/> )
            case 'GroupAdd' : return (<GroupAddIcon  sx={{fontSize: 20}}/> )
            case 'GroupRemove' : return (<GroupRemoveIcon  sx={{fontSize: 20}}/> )
            case 'GroupWork' : return (<GroupWorkIcon  sx={{fontSize: 20}}/> )
            case 'Groups' : return (<GroupsIcon  sx={{fontSize: 20}}/> )
            // case 'Groups2' : return (<Groups2Icon  sx={{fontSize: 20}}/> )
            // case 'Groups3' : return (<Groups3Icon  sx={{fontSize: 20}}/> )
            case 'HMobiledata' : return (<HMobiledataIcon  sx={{fontSize: 20}}/> )
            case 'HPlusMobiledata' : return (<HPlusMobiledataIcon  sx={{fontSize: 20}}/> )
            case 'Hail' : return (<HailIcon  sx={{fontSize: 20}}/> )
            case 'Handshake' : return (<HandshakeIcon  sx={{fontSize: 20}}/> )
            case 'Handyman' : return (<HandymanIcon  sx={{fontSize: 20}}/> )
            case 'Hardware' : return (<HardwareIcon  sx={{fontSize: 20}}/> )
            case 'Hd' : return (<HdIcon  sx={{fontSize: 20}}/> )
            case 'HdrAuto' : return (<HdrAutoIcon  sx={{fontSize: 20}}/> )
            case 'HdrAutoSelect' : return (<HdrAutoSelectIcon  sx={{fontSize: 20}}/> )
            case 'HdrEnhancedSelect' : return (<HdrEnhancedSelectIcon  sx={{fontSize: 20}}/> )
            case 'HdrOff' : return (<HdrOffIcon  sx={{fontSize: 20}}/> )
            case 'HdrOffSelect' : return (<HdrOffSelectIcon  sx={{fontSize: 20}}/> )
            case 'HdrOn' : return (<HdrOnIcon  sx={{fontSize: 20}}/> )
            case 'HdrOnSelect' : return (<HdrOnSelectIcon  sx={{fontSize: 20}}/> )
            case 'HdrPlus' : return (<HdrPlusIcon  sx={{fontSize: 20}}/> )
            case 'HdrStrong' : return (<HdrStrongIcon  sx={{fontSize: 20}}/> )
            case 'HdrWeak' : return (<HdrWeakIcon  sx={{fontSize: 20}}/> )
            case 'Headphones' : return (<HeadphonesIcon  sx={{fontSize: 20}}/> )
            case 'HeadphonesBattery' : return (<HeadphonesBatteryIcon  sx={{fontSize: 20}}/> )
            case 'Headset' : return (<HeadsetIcon  sx={{fontSize: 20}}/> )
            case 'HeadsetMic' : return (<HeadsetMicIcon  sx={{fontSize: 20}}/> )
            case 'HeadsetOff' : return (<HeadsetOffIcon  sx={{fontSize: 20}}/> )
            case 'Healing' : return (<HealingIcon  sx={{fontSize: 20}}/> )
            case 'HealthAndSafety' : return (<HealthAndSafetyIcon  sx={{fontSize: 20}}/> )
            case 'Hearing' : return (<HearingIcon  sx={{fontSize: 20}}/> )
            case 'HearingDisabled' : return (<HearingDisabledIcon  sx={{fontSize: 20}}/> )
            case 'HeartBroken' : return (<HeartBrokenIcon  sx={{fontSize: 20}}/> )
            case 'HeatPump' : return (<HeatPumpIcon  sx={{fontSize: 20}}/> )
            case 'Height' : return (<HeightIcon  sx={{fontSize: 20}}/> )
            case 'Help' : return (<HelpIcon  sx={{fontSize: 20}}/> )
            case 'HelpCenter' : return (<HelpCenterIcon  sx={{fontSize: 20}}/> )
            case 'HelpOutline' : return (<HelpOutlineIcon  sx={{fontSize: 20}}/> )
            case 'Hevc' : return (<HevcIcon  sx={{fontSize: 20}}/> )
            case 'Hexagon' : return (<HexagonIcon  sx={{fontSize: 20}}/> )
            case 'HideImage' : return (<HideImageIcon  sx={{fontSize: 20}}/> )
            case 'HideSource' : return (<HideSourceIcon  sx={{fontSize: 20}}/> )
            case 'HighQuality' : return (<HighQualityIcon  sx={{fontSize: 20}}/> )
            case 'Highlight' : return (<HighlightIcon  sx={{fontSize: 20}}/> )
            case 'HighlightAlt' : return (<HighlightAltIcon  sx={{fontSize: 20}}/> )
            case 'HighlightOff' : return (<HighlightOffIcon  sx={{fontSize: 20}}/> )
            case 'Hiking' : return (<HikingIcon  sx={{fontSize: 20}}/> )
            case 'History' : return (<HistoryIcon  sx={{fontSize: 20}}/> )
            case 'HistoryEdu' : return (<HistoryEduIcon  sx={{fontSize: 20}}/> )
            case 'HistoryToggleOff' : return (<HistoryToggleOffIcon  sx={{fontSize: 20}}/> )
            case 'Hive' : return (<HiveIcon  sx={{fontSize: 20}}/> )
            case 'Hls' : return (<HlsIcon  sx={{fontSize: 20}}/> )
            case 'HlsOff' : return (<HlsOffIcon  sx={{fontSize: 20}}/> )
            case 'HolidayVillage' : return (<HolidayVillageIcon  sx={{fontSize: 20}}/> )
            case 'Home' : return (<HomeIcon  sx={{fontSize: 20}}/> )
            case 'HomeMax' : return (<HomeMaxIcon  sx={{fontSize: 20}}/> )
            case 'HomeMini' : return (<HomeMiniIcon  sx={{fontSize: 20}}/> )
            case 'HomeRepairService' : return (<HomeRepairServiceIcon  sx={{fontSize: 20}}/> )
            case 'HomeWork' : return (<HomeWorkIcon  sx={{fontSize: 20}}/> )
            case 'HorizontalRule' : return (<HorizontalRuleIcon  sx={{fontSize: 20}}/> )
            case 'HorizontalSplit' : return (<HorizontalSplitIcon  sx={{fontSize: 20}}/> )
            case 'HotTub' : return (<HotTubIcon  sx={{fontSize: 20}}/> )
            case 'Hotel' : return (<HotelIcon  sx={{fontSize: 20}}/> )
            case 'HourglassBottom' : return (<HourglassBottomIcon  sx={{fontSize: 20}}/> )
            case 'HourglassDisabled' : return (<HourglassDisabledIcon  sx={{fontSize: 20}}/> )
            case 'HourglassEmpty' : return (<HourglassEmptyIcon  sx={{fontSize: 20}}/> )
            case 'HourglassFull' : return (<HourglassFullIcon  sx={{fontSize: 20}}/> )
            case 'HourglassTop' : return (<HourglassTopIcon  sx={{fontSize: 20}}/> )
            case 'House' : return (<HouseIcon  sx={{fontSize: 20}}/> )
            case 'HouseSiding' : return (<HouseSidingIcon  sx={{fontSize: 20}}/> )
            case 'Houseboat' : return (<HouseboatIcon  sx={{fontSize: 20}}/> )
            case 'HowToReg' : return (<HowToRegIcon  sx={{fontSize: 20}}/> )
            case 'HowToVote' : return (<HowToVoteIcon  sx={{fontSize: 20}}/> )
            case 'Html' : return (<HtmlIcon  sx={{fontSize: 20}}/> )
            case 'Http' : return (<HttpIcon  sx={{fontSize: 20}}/> )
            case 'Https' : return (<HttpsIcon  sx={{fontSize: 20}}/> )
            case 'Hub' : return (<HubIcon  sx={{fontSize: 20}}/> )
            case 'Hvac' : return (<HvacIcon  sx={{fontSize: 20}}/> )
            case 'IceSkating' : return (<IceSkatingIcon  sx={{fontSize: 20}}/> )
            case 'Icecream' : return (<IcecreamIcon  sx={{fontSize: 20}}/> )
            case 'Image' : return (<ImageIcon  sx={{fontSize: 20}}/> )
            case 'ImageAspectRatio' : return (<ImageAspectRatioIcon  sx={{fontSize: 20}}/> )
            case 'ImageNotSupported' : return (<ImageNotSupportedIcon  sx={{fontSize: 20}}/> )
            case 'ImageSearch' : return (<ImageSearchIcon  sx={{fontSize: 20}}/> )
            case 'ImagesearchRoller' : return (<ImagesearchRollerIcon  sx={{fontSize: 20}}/> )
            case 'ImportContacts' : return (<ImportContactsIcon  sx={{fontSize: 20}}/> )
            case 'ImportExport' : return (<ImportExportIcon  sx={{fontSize: 20}}/> )
            case 'ImportantDevices' : return (<ImportantDevicesIcon  sx={{fontSize: 20}}/> )
            case 'Inbox' : return (<InboxIcon  sx={{fontSize: 20}}/> )
            case 'IndeterminateCheckBox' : return (<IndeterminateCheckBoxIcon  sx={{fontSize: 20}}/> )
            case 'Info' : return (<InfoIcon  sx={{fontSize: 20}}/> )
            case 'Input' : return (<InputIcon  sx={{fontSize: 20}}/> )
            case 'InsertChart' : return (<InsertChartIcon  sx={{fontSize: 20}}/> )
            case 'InsertComment' : return (<InsertCommentIcon  sx={{fontSize: 20}}/> )
            case 'InsertDriveFile' : return (<InsertDriveFileIcon  sx={{fontSize: 20}}/> )
            case 'InsertEmoticon' : return (<InsertEmoticonIcon  sx={{fontSize: 20}}/> )
            case 'InsertInvitation' : return (<InsertInvitationIcon  sx={{fontSize: 20}}/> )
            case 'InsertLink' : return (<InsertLinkIcon  sx={{fontSize: 20}}/> )
            case 'InsertPageBreak' : return (<InsertPageBreakIcon  sx={{fontSize: 20}}/> )
            case 'InsertPhoto' : return (<InsertPhotoIcon  sx={{fontSize: 20}}/> )
            case 'Insights' : return (<InsightsIcon  sx={{fontSize: 20}}/> )
            case 'Instagram' : return (<InstagramIcon  sx={{fontSize: 20}}/> )
            case 'InstallDesktop' : return (<InstallDesktopIcon  sx={{fontSize: 20}}/> )
            case 'InstallMobile' : return (<InstallMobileIcon  sx={{fontSize: 20}}/> )
            case 'IntegrationInstructions' : return (<IntegrationInstructionsIcon  sx={{fontSize: 20}}/> )
            case 'Interests' : return (<InterestsIcon  sx={{fontSize: 20}}/> )
            case 'InterpreterMode' : return (<InterpreterModeIcon  sx={{fontSize: 20}}/> )
            case 'Inventory' : return (<InventoryIcon  sx={{fontSize: 20}}/> )
            case 'Inventory2' : return (<Inventory2Icon  sx={{fontSize: 20}}/> )
            case 'InvertColors' : return (<InvertColorsIcon  sx={{fontSize: 20}}/> )
            case 'InvertColorsOff' : return (<InvertColorsOffIcon  sx={{fontSize: 20}}/> )
            case 'IosShare' : return (<IosShareIcon  sx={{fontSize: 20}}/> )
            case 'Iron' : return (<IronIcon  sx={{fontSize: 20}}/> )
            case 'Iso' : return (<IsoIcon  sx={{fontSize: 20}}/> )
            case 'Javascript' : return (<JavascriptIcon  sx={{fontSize: 20}}/> )
            case 'JoinFull' : return (<JoinFullIcon  sx={{fontSize: 20}}/> )
            case 'JoinInner' : return (<JoinInnerIcon  sx={{fontSize: 20}}/> )
            case 'JoinLeft' : return (<JoinLeftIcon  sx={{fontSize: 20}}/> )
            case 'JoinRight' : return (<JoinRightIcon  sx={{fontSize: 20}}/> )
            case 'Kayaking' : return (<KayakingIcon  sx={{fontSize: 20}}/> )
            case 'KebabDining' : return (<KebabDiningIcon  sx={{fontSize: 20}}/> )
            case 'Key' : return (<KeyIcon  sx={{fontSize: 20}}/> )
            case 'KeyOff' : return (<KeyOffIcon  sx={{fontSize: 20}}/> )
            case 'Keyboard' : return (<KeyboardIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardAlt' : return (<KeyboardAltIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardArrowDown' : return (<KeyboardArrowDownIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardArrowLeft' : return (<KeyboardArrowLeftIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardArrowRight' : return (<KeyboardArrowRightIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardArrowUp' : return (<KeyboardArrowUpIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardBackspace' : return (<KeyboardBackspaceIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardCapslock' : return (<KeyboardCapslockIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardCommandKey' : return (<KeyboardCommandKeyIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardControlKey' : return (<KeyboardControlKeyIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardDoubleArrowDown' : return (<KeyboardDoubleArrowDownIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardDoubleArrowLeft' : return (<KeyboardDoubleArrowLeftIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardDoubleArrowRight' : return (<KeyboardDoubleArrowRightIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardDoubleArrowUp' : return (<KeyboardDoubleArrowUpIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardHide' : return (<KeyboardHideIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardOptionKey' : return (<KeyboardOptionKeyIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardReturn' : return (<KeyboardReturnIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardTab' : return (<KeyboardTabIcon  sx={{fontSize: 20}}/> )
            case 'KeyboardVoice' : return (<KeyboardVoiceIcon  sx={{fontSize: 20}}/> )
            case 'KingBed' : return (<KingBedIcon  sx={{fontSize: 20}}/> )
            case 'Kitchen' : return (<KitchenIcon  sx={{fontSize: 20}}/> )
            case 'Kitesurfing' : return (<KitesurfingIcon  sx={{fontSize: 20}}/> )
            case 'Label' : return (<LabelIcon  sx={{fontSize: 20}}/> )
            case 'LabelImportant' : return (<LabelImportantIcon  sx={{fontSize: 20}}/> )
            case 'LabelOff' : return (<LabelOffIcon  sx={{fontSize: 20}}/> )
            case 'Lan' : return (<LanIcon  sx={{fontSize: 20}}/> )
            case 'Landscape' : return (<LandscapeIcon  sx={{fontSize: 20}}/> )
            case 'Landslide' : return (<LandslideIcon  sx={{fontSize: 20}}/> )
            case 'Language' : return (<LanguageIcon  sx={{fontSize: 20}}/> )
            case 'Laptop' : return (<LaptopIcon  sx={{fontSize: 20}}/> )
            case 'LaptopChromebook' : return (<LaptopChromebookIcon  sx={{fontSize: 20}}/> )
            case 'LaptopMac' : return (<LaptopMacIcon  sx={{fontSize: 20}}/> )
            case 'LaptopWindows' : return (<LaptopWindowsIcon  sx={{fontSize: 20}}/> )
            case 'LastPage' : return (<LastPageIcon  sx={{fontSize: 20}}/> )
            case 'Launch' : return (<LaunchIcon  sx={{fontSize: 20}}/> )
            case 'Layers' : return (<LayersIcon  sx={{fontSize: 20}}/> )
            case 'LayersClear' : return (<LayersClearIcon  sx={{fontSize: 20}}/> )
            case 'Leaderboard' : return (<LeaderboardIcon  sx={{fontSize: 20}}/> )
            case 'LeakAdd' : return (<LeakAddIcon  sx={{fontSize: 20}}/> )
            case 'LeakRemove' : return (<LeakRemoveIcon  sx={{fontSize: 20}}/> )
            case 'LegendToggle' : return (<LegendToggleIcon  sx={{fontSize: 20}}/> )
            case 'Lens' : return (<LensIcon  sx={{fontSize: 20}}/> )
            case 'LensBlur' : return (<LensBlurIcon  sx={{fontSize: 20}}/> )
            case 'LibraryAdd' : return (<LibraryAddIcon  sx={{fontSize: 20}}/> )
            case 'LibraryAddCheck' : return (<LibraryAddCheckIcon  sx={{fontSize: 20}}/> )
            case 'LibraryBooks' : return (<LibraryBooksIcon  sx={{fontSize: 20}}/> )
            case 'LibraryMusic' : return (<LibraryMusicIcon  sx={{fontSize: 20}}/> )
            case 'Light' : return (<LightIcon  sx={{fontSize: 20}}/> )
            case 'LightMode' : return (<LightModeIcon  sx={{fontSize: 20}}/> )
            case 'Lightbulb' : return (<LightbulbIcon  sx={{fontSize: 20}}/> )
            case 'LightbulbCircle' : return (<LightbulbCircleIcon  sx={{fontSize: 20}}/> )
            case 'LineAxis' : return (<LineAxisIcon  sx={{fontSize: 20}}/> )
            case 'LineStyle' : return (<LineStyleIcon  sx={{fontSize: 20}}/> )
            case 'LineWeight' : return (<LineWeightIcon  sx={{fontSize: 20}}/> )
            case 'LinearScale' : return (<LinearScaleIcon  sx={{fontSize: 20}}/> )
            case 'Link' : return (<LinkIcon  sx={{fontSize: 20}}/> )
            case 'LinkOff' : return (<LinkOffIcon  sx={{fontSize: 20}}/> )
            case 'LinkedCamera' : return (<LinkedCameraIcon  sx={{fontSize: 20}}/> )
            case 'LinkedIn' : return (<LinkedInIcon  sx={{fontSize: 20}}/> )
            case 'Liquor' : return (<LiquorIcon  sx={{fontSize: 20}}/> )
            case 'List' : return (<ListIcon  sx={{fontSize: 20}}/> )
            case 'ListAlt' : return (<ListAltIcon  sx={{fontSize: 20}}/> )
            case 'LiveHelp' : return (<LiveHelpIcon  sx={{fontSize: 20}}/> )
            case 'LiveTv' : return (<LiveTvIcon  sx={{fontSize: 20}}/> )
            case 'Living' : return (<LivingIcon  sx={{fontSize: 20}}/> )
            case 'LocalActivity' : return (<LocalActivityIcon  sx={{fontSize: 20}}/> )
            case 'LocalAirport' : return (<LocalAirportIcon  sx={{fontSize: 20}}/> )
            case 'LocalAtm' : return (<LocalAtmIcon  sx={{fontSize: 20}}/> )
            case 'LocalBar' : return (<LocalBarIcon  sx={{fontSize: 20}}/> )
            case 'LocalCafe' : return (<LocalCafeIcon  sx={{fontSize: 20}}/> )
            case 'LocalCarWash' : return (<LocalCarWashIcon  sx={{fontSize: 20}}/> )
            case 'LocalConvenienceStore' : return (<LocalConvenienceStoreIcon  sx={{fontSize: 20}}/> )
            case 'LocalDining' : return (<LocalDiningIcon  sx={{fontSize: 20}}/> )
            case 'LocalDrink' : return (<LocalDrinkIcon  sx={{fontSize: 20}}/> )
            case 'LocalFireDepartment' : return (<LocalFireDepartmentIcon  sx={{fontSize: 20}}/> )
            case 'LocalFlorist' : return (<LocalFloristIcon  sx={{fontSize: 20}}/> )
            case 'LocalGasStation' : return (<LocalGasStationIcon  sx={{fontSize: 20}}/> )
            case 'LocalGroceryStore' : return (<LocalGroceryStoreIcon  sx={{fontSize: 20}}/> )
            case 'LocalHospital' : return (<LocalHospitalIcon  sx={{fontSize: 20}}/> )
            case 'LocalHotel' : return (<LocalHotelIcon  sx={{fontSize: 20}}/> )
            case 'LocalLaundryService' : return (<LocalLaundryServiceIcon  sx={{fontSize: 20}}/> )
            case 'LocalLibrary' : return (<LocalLibraryIcon  sx={{fontSize: 20}}/> )
            case 'LocalMall' : return (<LocalMallIcon  sx={{fontSize: 20}}/> )
            case 'LocalMovies' : return (<LocalMoviesIcon  sx={{fontSize: 20}}/> )
            case 'LocalOffer' : return (<LocalOfferIcon  sx={{fontSize: 20}}/> )
            case 'LocalParking' : return (<LocalParkingIcon  sx={{fontSize: 20}}/> )
            case 'LocalPharmacy' : return (<LocalPharmacyIcon  sx={{fontSize: 20}}/> )
            case 'LocalPhone' : return (<LocalPhoneIcon  sx={{fontSize: 20}}/> )
            case 'LocalPizza' : return (<LocalPizzaIcon  sx={{fontSize: 20}}/> )
            case 'LocalPlay' : return (<LocalPlayIcon  sx={{fontSize: 20}}/> )
            case 'LocalPolice' : return (<LocalPoliceIcon  sx={{fontSize: 20}}/> )
            case 'LocalPostOffice' : return (<LocalPostOfficeIcon  sx={{fontSize: 20}}/> )
            case 'LocalPrintshop' : return (<LocalPrintshopIcon  sx={{fontSize: 20}}/> )
            case 'LocalSee' : return (<LocalSeeIcon  sx={{fontSize: 20}}/> )
            case 'LocalShipping' : return (<LocalShippingIcon  sx={{fontSize: 20}}/> )
            case 'LocalTaxi' : return (<LocalTaxiIcon  sx={{fontSize: 20}}/> )
            case 'LocationCity' : return (<LocationCityIcon  sx={{fontSize: 20}}/> )
            case 'LocationDisabled' : return (<LocationDisabledIcon  sx={{fontSize: 20}}/> )
            case 'LocationOff' : return (<LocationOffIcon  sx={{fontSize: 20}}/> )
            case 'LocationOn' : return (<LocationOnIcon  sx={{fontSize: 20}}/> )
            case 'LocationSearching' : return (<LocationSearchingIcon  sx={{fontSize: 20}}/> )
            case 'Lock' : return (<LockIcon  sx={{fontSize: 20}}/> )
            case 'LockClock' : return (<LockClockIcon  sx={{fontSize: 20}}/> )
            case 'LockOpen' : return (<LockOpenIcon  sx={{fontSize: 20}}/> )
            //case 'LockPerson' : return (<LockPersonIcon  sx={{fontSize: 20}}/> )
            case 'LockReset' : return (<LockResetIcon  sx={{fontSize: 20}}/> )
            case 'Login' : return (<LoginIcon  sx={{fontSize: 20}}/> )
            case 'LogoDev' : return (<LogoDevIcon  sx={{fontSize: 20}}/> )
            case 'Logout' : return (<LogoutIcon  sx={{fontSize: 20}}/> )
            case 'Looks' : return (<LooksIcon  sx={{fontSize: 20}}/> )
            case 'Looks3' : return (<Looks3Icon  sx={{fontSize: 20}}/> )
            case 'Looks4' : return (<Looks4Icon  sx={{fontSize: 20}}/> )
            case 'Looks5' : return (<Looks5Icon  sx={{fontSize: 20}}/> )
            case 'Looks6' : return (<Looks6Icon  sx={{fontSize: 20}}/> )
            case 'LooksOne' : return (<LooksOneIcon  sx={{fontSize: 20}}/> )
            case 'LooksTwo' : return (<LooksTwoIcon  sx={{fontSize: 20}}/> )
            case 'Loop' : return (<LoopIcon  sx={{fontSize: 20}}/> )
            case 'Loupe' : return (<LoupeIcon  sx={{fontSize: 20}}/> )
            case 'LowPriority' : return (<LowPriorityIcon  sx={{fontSize: 20}}/> )
            case 'Loyalty' : return (<LoyaltyIcon  sx={{fontSize: 20}}/> )
            case 'LteMobiledata' : return (<LteMobiledataIcon  sx={{fontSize: 20}}/> )
            case 'LtePlusMobiledata' : return (<LtePlusMobiledataIcon  sx={{fontSize: 20}}/> )
            case 'Luggage' : return (<LuggageIcon  sx={{fontSize: 20}}/> )
            case 'LunchDining' : return (<LunchDiningIcon  sx={{fontSize: 20}}/> )
            case 'Lyrics' : return (<LyricsIcon  sx={{fontSize: 20}}/> )
            //case 'MacroOff' : return (<MacroOffIcon  sx={{fontSize: 20}}/> )
            case 'Mail' : return (<MailIcon  sx={{fontSize: 20}}/> )
            case 'MailLock' : return (<MailLockIcon  sx={{fontSize: 20}}/> )
            case 'MailOutline' : return (<MailOutlineIcon  sx={{fontSize: 20}}/> )
            case 'Male' : return (<MaleIcon  sx={{fontSize: 20}}/> )
            case 'Man' : return (<ManIcon  sx={{fontSize: 20}}/> )
            // case 'Man2' : return (<Man2Icon  sx={{fontSize: 20}}/> )
            // case 'Man3' : return (<Man3Icon  sx={{fontSize: 20}}/> )
            // case 'Man4' : return (<Man4Icon  sx={{fontSize: 20}}/> )
            case 'ManageAccounts' : return (<ManageAccountsIcon  sx={{fontSize: 20}}/> )
            case 'ManageHistory' : return (<ManageHistoryIcon  sx={{fontSize: 20}}/> )
            case 'ManageSearch' : return (<ManageSearchIcon  sx={{fontSize: 20}}/> )
            case 'Map' : return (<MapIcon  sx={{fontSize: 20}}/> )
            case 'MapsHomeWork' : return (<MapsHomeWorkIcon  sx={{fontSize: 20}}/> )
            case 'MapsUgc' : return (<MapsUgcIcon  sx={{fontSize: 20}}/> )
            case 'Margin' : return (<MarginIcon  sx={{fontSize: 20}}/> )
            case 'MarkAsUnread' : return (<MarkAsUnreadIcon  sx={{fontSize: 20}}/> )
            case 'MarkChatRead' : return (<MarkChatReadIcon  sx={{fontSize: 20}}/> )
            case 'MarkChatUnread' : return (<MarkChatUnreadIcon  sx={{fontSize: 20}}/> )
            case 'MarkEmailRead' : return (<MarkEmailReadIcon  sx={{fontSize: 20}}/> )
            case 'MarkEmailUnread' : return (<MarkEmailUnreadIcon  sx={{fontSize: 20}}/> )
            case 'MarkUnreadChatAlt' : return (<MarkUnreadChatAltIcon  sx={{fontSize: 20}}/> )
            case 'Markunread' : return (<MarkunreadIcon  sx={{fontSize: 20}}/> )
            case 'MarkunreadMailbox' : return (<MarkunreadMailboxIcon  sx={{fontSize: 20}}/> )
            case 'Masks' : return (<MasksIcon  sx={{fontSize: 20}}/> )
            case 'Maximize' : return (<MaximizeIcon  sx={{fontSize: 20}}/> )
            case 'MediaBluetoothOff' : return (<MediaBluetoothOffIcon  sx={{fontSize: 20}}/> )
            case 'MediaBluetoothOn' : return (<MediaBluetoothOnIcon  sx={{fontSize: 20}}/> )
            case 'Mediation' : return (<MediationIcon  sx={{fontSize: 20}}/> )
            case 'MedicalInformation' : return (<MedicalInformationIcon  sx={{fontSize: 20}}/> )
            case 'MedicalServices' : return (<MedicalServicesIcon  sx={{fontSize: 20}}/> )
            case 'Medication' : return (<MedicationIcon  sx={{fontSize: 20}}/> )
            case 'MedicationLiquid' : return (<MedicationLiquidIcon  sx={{fontSize: 20}}/> )
            case 'MeetingRoom' : return (<MeetingRoomIcon  sx={{fontSize: 20}}/> )
            case 'Memory' : return (<MemoryIcon  sx={{fontSize: 20}}/> )
            case 'Menu' : return (<MenuIcon  sx={{fontSize: 20}}/> )
            case 'MenuBook' : return (<MenuBookIcon  sx={{fontSize: 20}}/> )
            case 'MenuOpen' : return (<MenuOpenIcon  sx={{fontSize: 20}}/> )
            case 'Merge' : return (<MergeIcon  sx={{fontSize: 20}}/> )
            case 'MergeType' : return (<MergeTypeIcon  sx={{fontSize: 20}}/> )
            case 'Message' : return (<MessageIcon  sx={{fontSize: 20}}/> )
            case 'Mic' : return (<MicIcon  sx={{fontSize: 20}}/> )
            case 'MicExternalOff' : return (<MicExternalOffIcon  sx={{fontSize: 20}}/> )
            case 'MicExternalOn' : return (<MicExternalOnIcon  sx={{fontSize: 20}}/> )
            case 'MicNone' : return (<MicNoneIcon  sx={{fontSize: 20}}/> )
            case 'MicOff' : return (<MicOffIcon  sx={{fontSize: 20}}/> )
            case 'Microwave' : return (<MicrowaveIcon  sx={{fontSize: 20}}/> )
            case 'MilitaryTech' : return (<MilitaryTechIcon  sx={{fontSize: 20}}/> )
            case 'Minimize' : return (<MinimizeIcon  sx={{fontSize: 20}}/> )
            case 'MinorCrash' : return (<MinorCrashIcon  sx={{fontSize: 20}}/> )
            case 'MiscellaneousServices' : return (<MiscellaneousServicesIcon  sx={{fontSize: 20}}/> )
            case 'MissedVideoCall' : return (<MissedVideoCallIcon  sx={{fontSize: 20}}/> )
            case 'Mms' : return (<MmsIcon  sx={{fontSize: 20}}/> )
            case 'MobileFriendly' : return (<MobileFriendlyIcon  sx={{fontSize: 20}}/> )
            case 'MobileOff' : return (<MobileOffIcon  sx={{fontSize: 20}}/> )
            case 'MobileScreenShare' : return (<MobileScreenShareIcon  sx={{fontSize: 20}}/> )
            case 'MobiledataOff' : return (<MobiledataOffIcon  sx={{fontSize: 20}}/> )
            case 'Mode' : return (<ModeIcon  sx={{fontSize: 20}}/> )
            case 'ModeComment' : return (<ModeCommentIcon  sx={{fontSize: 20}}/> )
            case 'ModeEdit' : return (<ModeEditIcon  sx={{fontSize: 20}}/> )
            case 'ModeEditOutline' : return (<ModeEditOutlineIcon  sx={{fontSize: 20}}/> )
            case 'ModeFanOff' : return (<ModeFanOffIcon  sx={{fontSize: 20}}/> )
            case 'ModeNight' : return (<ModeNightIcon  sx={{fontSize: 20}}/> )
            case 'ModeOfTravel' : return (<ModeOfTravelIcon  sx={{fontSize: 20}}/> )
            case 'ModeStandby' : return (<ModeStandbyIcon  sx={{fontSize: 20}}/> )
            case 'ModelTraining' : return (<ModelTrainingIcon  sx={{fontSize: 20}}/> )
            case 'MonetizationOn' : return (<MonetizationOnIcon  sx={{fontSize: 20}}/> )
            case 'Money' : return (<MoneyIcon  sx={{fontSize: 20}}/> )
            case 'MoneyOff' : return (<MoneyOffIcon  sx={{fontSize: 20}}/> )
            case 'MoneyOffCsred' : return (<MoneyOffCsredIcon  sx={{fontSize: 20}}/> )
            case 'Monitor' : return (<MonitorIcon  sx={{fontSize: 20}}/> )
            case 'MonitorHeart' : return (<MonitorHeartIcon  sx={{fontSize: 20}}/> )
            case 'MonitorWeight' : return (<MonitorWeightIcon  sx={{fontSize: 20}}/> )
            case 'MonochromePhotos' : return (<MonochromePhotosIcon  sx={{fontSize: 20}}/> )
            case 'Mood' : return (<MoodIcon  sx={{fontSize: 20}}/> )
            case 'MoodBad' : return (<MoodBadIcon  sx={{fontSize: 20}}/> )
            case 'Moped' : return (<MopedIcon  sx={{fontSize: 20}}/> )
            case 'More' : return (<MoreIcon  sx={{fontSize: 20}}/> )
            case 'MoreHoriz' : return (<MoreHorizIcon  sx={{fontSize: 20}}/> )
            case 'MoreTime' : return (<MoreTimeIcon  sx={{fontSize: 20}}/> )
            case 'MoreVert' : return (<MoreVertIcon  sx={{fontSize: 20}}/> )
            case 'Mosque' : return (<MosqueIcon  sx={{fontSize: 20}}/> )
            case 'MotionPhotosAuto' : return (<MotionPhotosAutoIcon  sx={{fontSize: 20}}/> )
            case 'MotionPhotosOff' : return (<MotionPhotosOffIcon  sx={{fontSize: 20}}/> )
            case 'Mouse' : return (<MouseIcon  sx={{fontSize: 20}}/> )
            case 'MoveDown' : return (<MoveDownIcon  sx={{fontSize: 20}}/> )
            case 'MoveToInbox' : return (<MoveToInboxIcon  sx={{fontSize: 20}}/> )
            case 'MoveUp' : return (<MoveUpIcon  sx={{fontSize: 20}}/> )
            case 'Movie' : return (<MovieIcon  sx={{fontSize: 20}}/> )
            case 'MovieCreation' : return (<MovieCreationIcon  sx={{fontSize: 20}}/> )
            case 'MovieFilter' : return (<MovieFilterIcon  sx={{fontSize: 20}}/> )
            case 'Moving' : return (<MovingIcon  sx={{fontSize: 20}}/> )
            case 'Mp' : return (<MpIcon  sx={{fontSize: 20}}/> )
            case 'MultilineChart' : return (<MultilineChartIcon  sx={{fontSize: 20}}/> )
            case 'MultipleStop' : return (<MultipleStopIcon  sx={{fontSize: 20}}/> )
            case 'Museum' : return (<MuseumIcon  sx={{fontSize: 20}}/> )
            case 'MusicNote' : return (<MusicNoteIcon  sx={{fontSize: 20}}/> )
            case 'MusicOff' : return (<MusicOffIcon  sx={{fontSize: 20}}/> )
            case 'MusicVideo' : return (<MusicVideoIcon  sx={{fontSize: 20}}/> )
            case 'MyLocation' : return (<MyLocationIcon  sx={{fontSize: 20}}/> )
            case 'Nat' : return (<NatIcon  sx={{fontSize: 20}}/> )
            case 'Nature' : return (<NatureIcon  sx={{fontSize: 20}}/> )
            case 'NaturePeople' : return (<NaturePeopleIcon  sx={{fontSize: 20}}/> )
            case 'NavigateBefore' : return (<NavigateBeforeIcon  sx={{fontSize: 20}}/> )
            case 'NavigateNext' : return (<NavigateNextIcon  sx={{fontSize: 20}}/> )
            case 'Navigation' : return (<NavigationIcon  sx={{fontSize: 20}}/> )
            case 'NearMe' : return (<NearMeIcon  sx={{fontSize: 20}}/> )
            case 'NearMeDisabled' : return (<NearMeDisabledIcon  sx={{fontSize: 20}}/> )
            case 'NearbyError' : return (<NearbyErrorIcon  sx={{fontSize: 20}}/> )
            case 'NearbyOff' : return (<NearbyOffIcon  sx={{fontSize: 20}}/> )
            case 'NestCamWiredStand' : return (<NestCamWiredStandIcon  sx={{fontSize: 20}}/> )
            case 'NetworkCell' : return (<NetworkCellIcon  sx={{fontSize: 20}}/> )
            case 'NetworkCheck' : return (<NetworkCheckIcon  sx={{fontSize: 20}}/> )
            case 'NetworkLocked' : return (<NetworkLockedIcon  sx={{fontSize: 20}}/> )
            case 'NetworkPing' : return (<NetworkPingIcon  sx={{fontSize: 20}}/> )
            case 'NetworkWifi' : return (<NetworkWifiIcon  sx={{fontSize: 20}}/> )
            case 'NetworkWifi1Bar' : return (<NetworkWifi1BarIcon  sx={{fontSize: 20}}/> )
            case 'NetworkWifi2Bar' : return (<NetworkWifi2BarIcon  sx={{fontSize: 20}}/> )
            case 'NetworkWifi3Bar' : return (<NetworkWifi3BarIcon  sx={{fontSize: 20}}/> )
            case 'NewReleases' : return (<NewReleasesIcon  sx={{fontSize: 20}}/> )
            case 'Newspaper' : return (<NewspaperIcon  sx={{fontSize: 20}}/> )
            case 'NextPlan' : return (<NextPlanIcon  sx={{fontSize: 20}}/> )
            case 'NextWeek' : return (<NextWeekIcon  sx={{fontSize: 20}}/> )
            case 'Nfc' : return (<NfcIcon  sx={{fontSize: 20}}/> )
            case 'NightShelter' : return (<NightShelterIcon  sx={{fontSize: 20}}/> )
            case 'Nightlife' : return (<NightlifeIcon  sx={{fontSize: 20}}/> )
            case 'Nightlight' : return (<NightlightIcon  sx={{fontSize: 20}}/> )
            case 'NightlightRound' : return (<NightlightRoundIcon  sx={{fontSize: 20}}/> )
            case 'NightsStay' : return (<NightsStayIcon  sx={{fontSize: 20}}/> )
            case 'NineK' : return (<NineKIcon  sx={{fontSize: 20}}/> )
            case 'NineKPlus' : return (<NineKPlusIcon  sx={{fontSize: 20}}/> )
            case 'NineMp' : return (<NineMpIcon  sx={{fontSize: 20}}/> )
            case 'NineteenMp' : return (<NineteenMpIcon  sx={{fontSize: 20}}/> )
            case 'NoAccounts' : return (<NoAccountsIcon  sx={{fontSize: 20}}/> )
            //case 'NoAdultContent' : return (<NoAdultContentIcon  sx={{fontSize: 20}}/> )
            case 'NoBackpack' : return (<NoBackpackIcon  sx={{fontSize: 20}}/> )
            case 'NoCell' : return (<NoCellIcon  sx={{fontSize: 20}}/> )
            case 'NoCrash' : return (<NoCrashIcon  sx={{fontSize: 20}}/> )
            case 'NoDrinks' : return (<NoDrinksIcon  sx={{fontSize: 20}}/> )
            case 'NoEncryption' : return (<NoEncryptionIcon  sx={{fontSize: 20}}/> )
            case 'NoEncryptionGmailerrorred' : return (<NoEncryptionGmailerrorredIcon  sx={{fontSize: 20}}/> )
            case 'NoFlash' : return (<NoFlashIcon  sx={{fontSize: 20}}/> )
            case 'NoFood' : return (<NoFoodIcon  sx={{fontSize: 20}}/> )
            case 'NoLuggage' : return (<NoLuggageIcon  sx={{fontSize: 20}}/> )
            case 'NoMeals' : return (<NoMealsIcon  sx={{fontSize: 20}}/> )
            case 'NoMeetingRoom' : return (<NoMeetingRoomIcon  sx={{fontSize: 20}}/> )
            case 'NoPhotography' : return (<NoPhotographyIcon  sx={{fontSize: 20}}/> )
            case 'NoSim' : return (<NoSimIcon  sx={{fontSize: 20}}/> )
            case 'NoStroller' : return (<NoStrollerIcon  sx={{fontSize: 20}}/> )
            case 'NoTransfer' : return (<NoTransferIcon  sx={{fontSize: 20}}/> )
            case 'NoiseAware' : return (<NoiseAwareIcon  sx={{fontSize: 20}}/> )
            case 'NoiseControlOff' : return (<NoiseControlOffIcon  sx={{fontSize: 20}}/> )
            case 'NordicWalking' : return (<NordicWalkingIcon  sx={{fontSize: 20}}/> )
            case 'North' : return (<NorthIcon  sx={{fontSize: 20}}/> )
            case 'NorthEast' : return (<NorthEastIcon  sx={{fontSize: 20}}/> )
            case 'NorthWest' : return (<NorthWestIcon  sx={{fontSize: 20}}/> )
            case 'NotAccessible' : return (<NotAccessibleIcon  sx={{fontSize: 20}}/> )
            case 'NotInterested' : return (<NotInterestedIcon  sx={{fontSize: 20}}/> )
            case 'NotListedLocation' : return (<NotListedLocationIcon  sx={{fontSize: 20}}/> )
            case 'NotStarted' : return (<NotStartedIcon  sx={{fontSize: 20}}/> )
            case 'Note' : return (<NoteIcon  sx={{fontSize: 20}}/> )
            case 'NoteAdd' : return (<NoteAddIcon  sx={{fontSize: 20}}/> )
            case 'NoteAlt' : return (<NoteAltIcon  sx={{fontSize: 20}}/> )
            case 'Notes' : return (<NotesIcon  sx={{fontSize: 20}}/> )
            case 'NotificationAdd' : return (<NotificationAddIcon  sx={{fontSize: 20}}/> )
            case 'NotificationImportant' : return (<NotificationImportantIcon  sx={{fontSize: 20}}/> )
            case 'Notifications' : return (<NotificationsIcon  sx={{fontSize: 20}}/> )
            case 'NotificationsActive' : return (<NotificationsActiveIcon  sx={{fontSize: 20}}/> )
            case 'NotificationsNone' : return (<NotificationsNoneIcon  sx={{fontSize: 20}}/> )
            case 'NotificationsOff' : return (<NotificationsOffIcon  sx={{fontSize: 20}}/> )
            case 'NotificationsPaused' : return (<NotificationsPausedIcon  sx={{fontSize: 20}}/> )
            case 'Numbers' : return (<NumbersIcon  sx={{fontSize: 20}}/> )
            case 'OfflineBolt' : return (<OfflineBoltIcon  sx={{fontSize: 20}}/> )
            case 'OfflinePin' : return (<OfflinePinIcon  sx={{fontSize: 20}}/> )
            case 'OfflineShare' : return (<OfflineShareIcon  sx={{fontSize: 20}}/> )
            case 'OilBarrel' : return (<OilBarrelIcon  sx={{fontSize: 20}}/> )
            case 'OnDeviceTraining' : return (<OnDeviceTrainingIcon  sx={{fontSize: 20}}/> )
            case 'OndemandVideo' : return (<OndemandVideoIcon  sx={{fontSize: 20}}/> )
            case 'OneK' : return (<OneKIcon  sx={{fontSize: 20}}/> )
            case 'OneKPlus' : return (<OneKPlusIcon  sx={{fontSize: 20}}/> )
            case 'OneKk' : return (<OneKkIcon  sx={{fontSize: 20}}/> )
            case 'OnlinePrediction' : return (<OnlinePredictionIcon  sx={{fontSize: 20}}/> )
            case 'Opacity' : return (<OpacityIcon  sx={{fontSize: 20}}/> )
            case 'OpenInBrowser' : return (<OpenInBrowserIcon  sx={{fontSize: 20}}/> )
            case 'OpenInFull' : return (<OpenInFullIcon  sx={{fontSize: 20}}/> )
            case 'OpenInNew' : return (<OpenInNewIcon  sx={{fontSize: 20}}/> )
            case 'OpenInNewOff' : return (<OpenInNewOffIcon  sx={{fontSize: 20}}/> )
            case 'OpenWith' : return (<OpenWithIcon  sx={{fontSize: 20}}/> )
            case 'OtherHouses' : return (<OtherHousesIcon  sx={{fontSize: 20}}/> )
            case 'Outbound' : return (<OutboundIcon  sx={{fontSize: 20}}/> )
            case 'Outbox' : return (<OutboxIcon  sx={{fontSize: 20}}/> )
            case 'OutdoorGrill' : return (<OutdoorGrillIcon  sx={{fontSize: 20}}/> )
            case 'Outlet' : return (<OutletIcon  sx={{fontSize: 20}}/> )
            case 'Output' : return (<OutputIcon  sx={{fontSize: 20}}/> )
            case 'Padding' : return (<PaddingIcon  sx={{fontSize: 20}}/> )
            case 'Pages' : return (<PagesIcon  sx={{fontSize: 20}}/> )
            case 'Pageview' : return (<PageviewIcon  sx={{fontSize: 20}}/> )
            case 'Paid' : return (<PaidIcon  sx={{fontSize: 20}}/> )
            case 'Palette' : return (<PaletteIcon  sx={{fontSize: 20}}/> )
            case 'PanTool' : return (<PanToolIcon  sx={{fontSize: 20}}/> )
            case 'PanToolAlt' : return (<PanToolAltIcon  sx={{fontSize: 20}}/> )
            case 'Panorama' : return (<PanoramaIcon  sx={{fontSize: 20}}/> )
            case 'PanoramaFishEye' : return (<PanoramaFishEyeIcon  sx={{fontSize: 20}}/> )
            case 'PanoramaHorizontal' : return (<PanoramaHorizontalIcon  sx={{fontSize: 20}}/> )
            case 'PanoramaHorizontalSelect' : return (<PanoramaHorizontalSelectIcon  sx={{fontSize: 20}}/> )
            case 'PanoramaPhotosphere' : return (<PanoramaPhotosphereIcon  sx={{fontSize: 20}}/> )
            case 'PanoramaPhotosphereSelect' : return (<PanoramaPhotosphereSelectIcon  sx={{fontSize: 20}}/> )
            case 'PanoramaVertical' : return (<PanoramaVerticalIcon  sx={{fontSize: 20}}/> )
            case 'PanoramaVerticalSelect' : return (<PanoramaVerticalSelectIcon  sx={{fontSize: 20}}/> )
            case 'PanoramaWideAngle' : return (<PanoramaWideAngleIcon  sx={{fontSize: 20}}/> )
            case 'PanoramaWideAngleSelect' : return (<PanoramaWideAngleSelectIcon  sx={{fontSize: 20}}/> )
            case 'Paragliding' : return (<ParaglidingIcon  sx={{fontSize: 20}}/> )
            case 'Park' : return (<ParkIcon  sx={{fontSize: 20}}/> )
            case 'PartyMode' : return (<PartyModeIcon  sx={{fontSize: 20}}/> )
            case 'Password' : return (<PasswordIcon  sx={{fontSize: 20}}/> )
            case 'Pattern' : return (<PatternIcon  sx={{fontSize: 20}}/> )
            case 'Pause' : return (<PauseIcon  sx={{fontSize: 20}}/> )
            case 'PauseCircle' : return (<PauseCircleIcon  sx={{fontSize: 20}}/> )
            case 'PauseCircleFilled' : return (<PauseCircleFilledIcon  sx={{fontSize: 20}}/> )
            case 'PauseCircleOutline' : return (<PauseCircleOutlineIcon  sx={{fontSize: 20}}/> )
            case 'PausePresentation' : return (<PausePresentationIcon  sx={{fontSize: 20}}/> )
            case 'Payment' : return (<PaymentIcon  sx={{fontSize: 20}}/> )
            case 'Payments' : return (<PaymentsIcon  sx={{fontSize: 20}}/> )
            case 'PedalBike' : return (<PedalBikeIcon  sx={{fontSize: 20}}/> )
            case 'Pending' : return (<PendingIcon  sx={{fontSize: 20}}/> )
            case 'PendingActions' : return (<PendingActionsIcon  sx={{fontSize: 20}}/> )
            case 'Pentagon' : return (<PentagonIcon  sx={{fontSize: 20}}/> )
            case 'People' : return (<PeopleIcon  sx={{fontSize: 20}}/> )
            case 'PeopleAlt' : return (<PeopleAltIcon  sx={{fontSize: 20}}/> )
            case 'PeopleOutline' : return (<PeopleOutlineIcon  sx={{fontSize: 20}}/> )
            case 'Percent' : return (<PercentIcon  sx={{fontSize: 20}}/> )
            case 'PermCameraMic' : return (<PermCameraMicIcon  sx={{fontSize: 20}}/> )
            case 'PermContactCalendar' : return (<PermContactCalendarIcon  sx={{fontSize: 20}}/> )
            case 'PermDataSetting' : return (<PermDataSettingIcon  sx={{fontSize: 20}}/> )
            case 'PermDeviceInformation' : return (<PermDeviceInformationIcon  sx={{fontSize: 20}}/> )
            case 'PermIdentity' : return (<PermIdentityIcon  sx={{fontSize: 20}}/> )
            case 'PermMedia' : return (<PermMediaIcon  sx={{fontSize: 20}}/> )
            case 'PermPhoneMsg' : return (<PermPhoneMsgIcon  sx={{fontSize: 20}}/> )
            case 'PermScanWifi' : return (<PermScanWifiIcon  sx={{fontSize: 20}}/> )
            case 'Person' : return (<PersonIcon  sx={{fontSize: 20}}/> )
            // case 'Person2' : return (<Person2Icon  sx={{fontSize: 20}}/> )
            // case 'Person3' : return (<Person3Icon  sx={{fontSize: 20}}/> )
            // case 'Person4' : return (<Person4Icon  sx={{fontSize: 20}}/> )
            case 'PersonAdd' : return (<PersonAddIcon  sx={{fontSize: 20}}/> )
            case 'PersonAddAlt' : return (<PersonAddAltIcon  sx={{fontSize: 20}}/> )
            case 'PersonAddAlt1' : return (<PersonAddAlt1Icon  sx={{fontSize: 20}}/> )
            case 'PersonAddDisabled' : return (<PersonAddDisabledIcon  sx={{fontSize: 20}}/> )
            case 'PersonOff' : return (<PersonOffIcon  sx={{fontSize: 20}}/> )
            case 'PersonOutline' : return (<PersonOutlineIcon  sx={{fontSize: 20}}/> )
            case 'PersonPin' : return (<PersonPinIcon  sx={{fontSize: 20}}/> )
            case 'PersonPinCircle' : return (<PersonPinCircleIcon  sx={{fontSize: 20}}/> )
            case 'PersonRemove' : return (<PersonRemoveIcon  sx={{fontSize: 20}}/> )
            case 'PersonRemoveAlt1' : return (<PersonRemoveAlt1Icon  sx={{fontSize: 20}}/> )
            case 'PersonSearch' : return (<PersonSearchIcon  sx={{fontSize: 20}}/> )
            case 'PersonalVideo' : return (<PersonalVideoIcon  sx={{fontSize: 20}}/> )
            case 'PestControl' : return (<PestControlIcon  sx={{fontSize: 20}}/> )
            case 'PestControlRodent' : return (<PestControlRodentIcon  sx={{fontSize: 20}}/> )
            case 'Pets' : return (<PetsIcon  sx={{fontSize: 20}}/> )
            case 'Phishing' : return (<PhishingIcon  sx={{fontSize: 20}}/> )
            case 'Phone' : return (<PhoneIcon  sx={{fontSize: 20}}/> )
            case 'PhoneAndroid' : return (<PhoneAndroidIcon  sx={{fontSize: 20}}/> )
            case 'PhoneBluetoothSpeaker' : return (<PhoneBluetoothSpeakerIcon  sx={{fontSize: 20}}/> )
            case 'PhoneCallback' : return (<PhoneCallbackIcon  sx={{fontSize: 20}}/> )
            case 'PhoneDisabled' : return (<PhoneDisabledIcon  sx={{fontSize: 20}}/> )
            case 'PhoneEnabled' : return (<PhoneEnabledIcon  sx={{fontSize: 20}}/> )
            case 'PhoneForwarded' : return (<PhoneForwardedIcon  sx={{fontSize: 20}}/> )
            case 'PhoneInTalk' : return (<PhoneInTalkIcon  sx={{fontSize: 20}}/> )
            case 'PhoneIphone' : return (<PhoneIphoneIcon  sx={{fontSize: 20}}/> )
            case 'PhoneLocked' : return (<PhoneLockedIcon  sx={{fontSize: 20}}/> )
            case 'PhoneMissed' : return (<PhoneMissedIcon  sx={{fontSize: 20}}/> )
            case 'PhonePaused' : return (<PhonePausedIcon  sx={{fontSize: 20}}/> )
            case 'Phonelink' : return (<PhonelinkIcon  sx={{fontSize: 20}}/> )
            case 'PhonelinkErase' : return (<PhonelinkEraseIcon  sx={{fontSize: 20}}/> )
            case 'PhonelinkLock' : return (<PhonelinkLockIcon  sx={{fontSize: 20}}/> )
            case 'PhonelinkOff' : return (<PhonelinkOffIcon  sx={{fontSize: 20}}/> )
            case 'PhonelinkRing' : return (<PhonelinkRingIcon  sx={{fontSize: 20}}/> )
            case 'PhonelinkSetup' : return (<PhonelinkSetupIcon  sx={{fontSize: 20}}/> )
            case 'Photo' : return (<PhotoIcon  sx={{fontSize: 20}}/> )
            case 'PhotoAlbum' : return (<PhotoAlbumIcon  sx={{fontSize: 20}}/> )
            case 'PhotoCamera' : return (<PhotoCameraIcon  sx={{fontSize: 20}}/> )
            case 'PhotoCameraBack' : return (<PhotoCameraBackIcon  sx={{fontSize: 20}}/> )
            case 'PhotoCameraFront' : return (<PhotoCameraFrontIcon  sx={{fontSize: 20}}/> )
            case 'PhotoFilter' : return (<PhotoFilterIcon  sx={{fontSize: 20}}/> )
            case 'PhotoLibrary' : return (<PhotoLibraryIcon  sx={{fontSize: 20}}/> )
            case 'PhotoSizeSelectActual' : return (<PhotoSizeSelectActualIcon  sx={{fontSize: 20}}/> )
            case 'PhotoSizeSelectLarge' : return (<PhotoSizeSelectLargeIcon  sx={{fontSize: 20}}/> )
            case 'PhotoSizeSelectSmall' : return (<PhotoSizeSelectSmallIcon  sx={{fontSize: 20}}/> )
            case 'Php' : return (<PhpIcon  sx={{fontSize: 20}}/> )
            case 'Piano' : return (<PianoIcon  sx={{fontSize: 20}}/> )
            case 'PianoOff' : return (<PianoOffIcon  sx={{fontSize: 20}}/> )
            case 'PictureAsPdf' : return (<PictureAsPdfIcon  sx={{fontSize: 20}}/> )
            case 'PictureInPicture' : return (<PictureInPictureIcon  sx={{fontSize: 20}}/> )
            case 'PictureInPictureAlt' : return (<PictureInPictureAltIcon  sx={{fontSize: 20}}/> )
            case 'PieChart' : return (<PieChartIcon  sx={{fontSize: 20}}/> )
            case 'PieChartOutline' : return (<PieChartOutlineIcon  sx={{fontSize: 20}}/> )
            case 'Pin' : return (<PinIcon  sx={{fontSize: 20}}/> )
            case 'PinDrop' : return (<PinDropIcon  sx={{fontSize: 20}}/> )
            case 'Pinch' : return (<PinchIcon  sx={{fontSize: 20}}/> )
            case 'Pinterest' : return (<PinterestIcon  sx={{fontSize: 20}}/> )
            case 'PivotTableChart' : return (<PivotTableChartIcon  sx={{fontSize: 20}}/> )
            case 'Pix' : return (<PixIcon  sx={{fontSize: 20}}/> )
            case 'Place' : return (<PlaceIcon  sx={{fontSize: 20}}/> )
            case 'Plagiarism' : return (<PlagiarismIcon  sx={{fontSize: 20}}/> )
            case 'PlayArrow' : return (<PlayArrowIcon  sx={{fontSize: 20}}/> )
            case 'PlayCircle' : return (<PlayCircleIcon  sx={{fontSize: 20}}/> )
            case 'PlayCircleFilled' : return (<PlayCircleFilledIcon  sx={{fontSize: 20}}/> )
            case 'PlayCircleFilledWhite' : return (<PlayCircleFilledWhiteIcon  sx={{fontSize: 20}}/> )
            case 'PlayCircleOutline' : return (<PlayCircleOutlineIcon  sx={{fontSize: 20}}/> )
            case 'PlayDisabled' : return (<PlayDisabledIcon  sx={{fontSize: 20}}/> )
            case 'PlayForWork' : return (<PlayForWorkIcon  sx={{fontSize: 20}}/> )
            case 'PlayLesson' : return (<PlayLessonIcon  sx={{fontSize: 20}}/> )
            case 'PlaylistAdd' : return (<PlaylistAddIcon  sx={{fontSize: 20}}/> )
            case 'PlaylistAddCheck' : return (<PlaylistAddCheckIcon  sx={{fontSize: 20}}/> )
            case 'PlaylistAddCheckCircle' : return (<PlaylistAddCheckCircleIcon  sx={{fontSize: 20}}/> )
            case 'PlaylistAddCircle' : return (<PlaylistAddCircleIcon  sx={{fontSize: 20}}/> )
            case 'PlaylistPlay' : return (<PlaylistPlayIcon  sx={{fontSize: 20}}/> )
            case 'PlaylistRemove' : return (<PlaylistRemoveIcon  sx={{fontSize: 20}}/> )
            case 'Plumbing' : return (<PlumbingIcon  sx={{fontSize: 20}}/> )
            case 'PlusOne' : return (<PlusOneIcon  sx={{fontSize: 20}}/> )
            case 'Podcasts' : return (<PodcastsIcon  sx={{fontSize: 20}}/> )
            case 'PointOfSale' : return (<PointOfSaleIcon  sx={{fontSize: 20}}/> )
            case 'Policy' : return (<PolicyIcon  sx={{fontSize: 20}}/> )
            case 'Poll' : return (<PollIcon  sx={{fontSize: 20}}/> )
            case 'Polyline' : return (<PolylineIcon  sx={{fontSize: 20}}/> )
            case 'Pool' : return (<PoolIcon  sx={{fontSize: 20}}/> )
            case 'PortableWifiOff' : return (<PortableWifiOffIcon  sx={{fontSize: 20}}/> )
            case 'Portrait' : return (<PortraitIcon  sx={{fontSize: 20}}/> )
            case 'PostAdd' : return (<PostAddIcon  sx={{fontSize: 20}}/> )
            case 'Power' : return (<PowerIcon  sx={{fontSize: 20}}/> )
            case 'PowerInput' : return (<PowerInputIcon  sx={{fontSize: 20}}/> )
            case 'PowerOff' : return (<PowerOffIcon  sx={{fontSize: 20}}/> )
            case 'PowerSettingsNew' : return (<PowerSettingsNewIcon  sx={{fontSize: 20}}/> )
            case 'PrecisionManufacturing' : return (<PrecisionManufacturingIcon  sx={{fontSize: 20}}/> )
            case 'PregnantWoman' : return (<PregnantWomanIcon  sx={{fontSize: 20}}/> )
            case 'PresentToAll' : return (<PresentToAllIcon  sx={{fontSize: 20}}/> )
            case 'Preview' : return (<PreviewIcon  sx={{fontSize: 20}}/> )
            case 'PriceChange' : return (<PriceChangeIcon  sx={{fontSize: 20}}/> )
            case 'PriceCheck' : return (<PriceCheckIcon  sx={{fontSize: 20}}/> )
            case 'Print' : return (<PrintIcon  sx={{fontSize: 20}}/> )
            case 'PrintDisabled' : return (<PrintDisabledIcon  sx={{fontSize: 20}}/> )
            case 'PriorityHigh' : return (<PriorityHighIcon  sx={{fontSize: 20}}/> )
            case 'PrivacyTip' : return (<PrivacyTipIcon  sx={{fontSize: 20}}/> )
            case 'ProductionQuantityLimits' : return (<ProductionQuantityLimitsIcon  sx={{fontSize: 20}}/> )
            case 'Propane' : return (<PropaneIcon  sx={{fontSize: 20}}/> )
            case 'PropaneTank' : return (<PropaneTankIcon  sx={{fontSize: 20}}/> )
            case 'Psychology' : return (<PsychologyIcon  sx={{fontSize: 20}}/> )
            //case 'PsychologyAlt' : return (<PsychologyAltIcon  sx={{fontSize: 20}}/> )
            case 'Public' : return (<PublicIcon  sx={{fontSize: 20}}/> )
            case 'PublicOff' : return (<PublicOffIcon  sx={{fontSize: 20}}/> )
            case 'Publish' : return (<PublishIcon  sx={{fontSize: 20}}/> )
            case 'PublishedWithChanges' : return (<PublishedWithChangesIcon  sx={{fontSize: 20}}/> )
            case 'PunchClock' : return (<PunchClockIcon  sx={{fontSize: 20}}/> )
            case 'PushPin' : return (<PushPinIcon  sx={{fontSize: 20}}/> )
            case 'QrCode' : return (<QrCodeIcon  sx={{fontSize: 20}}/> )
            case 'QrCode2' : return (<QrCode2Icon  sx={{fontSize: 20}}/> )
            case 'QrCodeScanner' : return (<QrCodeScannerIcon  sx={{fontSize: 20}}/> )
            case 'QueryBuilder' : return (<QueryBuilderIcon  sx={{fontSize: 20}}/> )
            case 'QueryStats' : return (<QueryStatsIcon  sx={{fontSize: 20}}/> )
            case 'QuestionAnswer' : return (<QuestionAnswerIcon  sx={{fontSize: 20}}/> )
            case 'QuestionMark' : return (<QuestionMarkIcon  sx={{fontSize: 20}}/> )
            case 'Queue' : return (<QueueIcon  sx={{fontSize: 20}}/> )
            case 'QueueMusic' : return (<QueueMusicIcon  sx={{fontSize: 20}}/> )
            case 'QueuePlayNext' : return (<QueuePlayNextIcon  sx={{fontSize: 20}}/> )
            case 'Quickreply' : return (<QuickreplyIcon  sx={{fontSize: 20}}/> )
            case 'Quiz' : return (<QuizIcon  sx={{fontSize: 20}}/> )
            case 'RMobiledata' : return (<RMobiledataIcon  sx={{fontSize: 20}}/> )
            case 'Radar' : return (<RadarIcon  sx={{fontSize: 20}}/> )
            case 'Radio' : return (<RadioIcon  sx={{fontSize: 20}}/> )
            case 'RadioButtonChecked' : return (<RadioButtonCheckedIcon  sx={{fontSize: 20}}/> )
            case 'RadioButtonUnchecked' : return (<RadioButtonUncheckedIcon  sx={{fontSize: 20}}/> )
            case 'RailwayAlert' : return (<RailwayAlertIcon  sx={{fontSize: 20}}/> )
            case 'RamenDining' : return (<RamenDiningIcon  sx={{fontSize: 20}}/> )
            case 'RampLeft' : return (<RampLeftIcon  sx={{fontSize: 20}}/> )
            case 'RampRight' : return (<RampRightIcon  sx={{fontSize: 20}}/> )
            case 'RateReview' : return (<RateReviewIcon  sx={{fontSize: 20}}/> )
            case 'RawOff' : return (<RawOffIcon  sx={{fontSize: 20}}/> )
            case 'RawOn' : return (<RawOnIcon  sx={{fontSize: 20}}/> )
            case 'ReadMore' : return (<ReadMoreIcon  sx={{fontSize: 20}}/> )
            case 'Receipt' : return (<ReceiptIcon  sx={{fontSize: 20}}/> )
            case 'ReceiptLong' : return (<ReceiptLongIcon  sx={{fontSize: 20}}/> )
            case 'RecentActors' : return (<RecentActorsIcon  sx={{fontSize: 20}}/> )
            case 'Recommend' : return (<RecommendIcon  sx={{fontSize: 20}}/> )
            case 'RecordVoiceOver' : return (<RecordVoiceOverIcon  sx={{fontSize: 20}}/> )
            case 'Rectangle' : return (<RectangleIcon  sx={{fontSize: 20}}/> )
            case 'Reddit' : return (<RedditIcon  sx={{fontSize: 20}}/> )
            case 'Redeem' : return (<RedeemIcon  sx={{fontSize: 20}}/> )
            case 'Redo' : return (<RedoIcon  sx={{fontSize: 20}}/> )
            case 'ReduceCapacity' : return (<ReduceCapacityIcon  sx={{fontSize: 20}}/> )
            case 'Refresh' : return (<RefreshIcon  sx={{fontSize: 20}}/> )
            case 'RememberMe' : return (<RememberMeIcon  sx={{fontSize: 20}}/> )
            case 'Remove' : return (<RemoveIcon  sx={{fontSize: 20}}/> )
            case 'RemoveCircle' : return (<RemoveCircleIcon  sx={{fontSize: 20}}/> )
            case 'RemoveCircleOutline' : return (<RemoveCircleOutlineIcon  sx={{fontSize: 20}}/> )
            case 'RemoveDone' : return (<RemoveDoneIcon  sx={{fontSize: 20}}/> )
            case 'RemoveFromQueue' : return (<RemoveFromQueueIcon  sx={{fontSize: 20}}/> )
            case 'RemoveModerator' : return (<RemoveModeratorIcon  sx={{fontSize: 20}}/> )
            case 'RemoveRedEye' : return (<RemoveRedEyeIcon  sx={{fontSize: 20}}/> )
            case 'RemoveRoad' : return (<RemoveRoadIcon  sx={{fontSize: 20}}/> )
            case 'RemoveShoppingCart' : return (<RemoveShoppingCartIcon  sx={{fontSize: 20}}/> )
            case 'Reorder' : return (<ReorderIcon  sx={{fontSize: 20}}/> )
            //case 'Repartition' : return (<RepartitionIcon  sx={{fontSize: 20}}/> )
            case 'Repeat' : return (<RepeatIcon  sx={{fontSize: 20}}/> )
            case 'RepeatOn' : return (<RepeatOnIcon  sx={{fontSize: 20}}/> )
            case 'RepeatOne' : return (<RepeatOneIcon  sx={{fontSize: 20}}/> )
            case 'RepeatOneOn' : return (<RepeatOneOnIcon  sx={{fontSize: 20}}/> )
            case 'Replay' : return (<ReplayIcon  sx={{fontSize: 20}}/> )
            case 'Replay10' : return (<Replay10Icon  sx={{fontSize: 20}}/> )
            case 'Replay30' : return (<Replay30Icon  sx={{fontSize: 20}}/> )
            case 'Replay5' : return (<Replay5Icon  sx={{fontSize: 20}}/> )
            case 'ReplayCircleFilled' : return (<ReplayCircleFilledIcon  sx={{fontSize: 20}}/> )
            case 'Reply' : return (<ReplyIcon  sx={{fontSize: 20}}/> )
            case 'ReplyAll' : return (<ReplyAllIcon  sx={{fontSize: 20}}/> )
            case 'Report' : return (<ReportIcon  sx={{fontSize: 20}}/> )
            case 'ReportGmailerrorred' : return (<ReportGmailerrorredIcon  sx={{fontSize: 20}}/> )
            case 'ReportOff' : return (<ReportOffIcon  sx={{fontSize: 20}}/> )
            case 'ReportProblem' : return (<ReportProblemIcon  sx={{fontSize: 20}}/> )
            case 'RequestPage' : return (<RequestPageIcon  sx={{fontSize: 20}}/> )
            case 'RequestQuote' : return (<RequestQuoteIcon  sx={{fontSize: 20}}/> )
            case 'ResetTv' : return (<ResetTvIcon  sx={{fontSize: 20}}/> )
            case 'RestartAlt' : return (<RestartAltIcon  sx={{fontSize: 20}}/> )
            case 'Restaurant' : return (<RestaurantIcon  sx={{fontSize: 20}}/> )
            case 'RestaurantMenu' : return (<RestaurantMenuIcon  sx={{fontSize: 20}}/> )
            case 'Restore' : return (<RestoreIcon  sx={{fontSize: 20}}/> )
            case 'RestoreFromTrash' : return (<RestoreFromTrashIcon  sx={{fontSize: 20}}/> )
            case 'RestorePage' : return (<RestorePageIcon  sx={{fontSize: 20}}/> )
            case 'Reviews' : return (<ReviewsIcon  sx={{fontSize: 20}}/> )
            case 'RiceBowl' : return (<RiceBowlIcon  sx={{fontSize: 20}}/> )
            case 'RingVolume' : return (<RingVolumeIcon  sx={{fontSize: 20}}/> )
            case 'Rocket' : return (<RocketIcon  sx={{fontSize: 20}}/> )
            case 'RocketLaunch' : return (<RocketLaunchIcon  sx={{fontSize: 20}}/> )
            case 'RollerShades' : return (<RollerShadesIcon  sx={{fontSize: 20}}/> )
            case 'RollerShadesClosed' : return (<RollerShadesClosedIcon  sx={{fontSize: 20}}/> )
            case 'RollerSkating' : return (<RollerSkatingIcon  sx={{fontSize: 20}}/> )
            case 'Roofing' : return (<RoofingIcon  sx={{fontSize: 20}}/> )
            case 'Room' : return (<RoomIcon  sx={{fontSize: 20}}/> )
            case 'RoomPreferences' : return (<RoomPreferencesIcon  sx={{fontSize: 20}}/> )
            case 'RoomService' : return (<RoomServiceIcon  sx={{fontSize: 20}}/> )
            case 'Rotate90DegreesCcw' : return (<Rotate90DegreesCcwIcon  sx={{fontSize: 20}}/> )
            case 'Rotate90DegreesCw' : return (<Rotate90DegreesCwIcon  sx={{fontSize: 20}}/> )
            case 'RotateLeft' : return (<RotateLeftIcon  sx={{fontSize: 20}}/> )
            case 'RotateRight' : return (<RotateRightIcon  sx={{fontSize: 20}}/> )
            case 'RoundaboutLeft' : return (<RoundaboutLeftIcon  sx={{fontSize: 20}}/> )
            case 'RoundaboutRight' : return (<RoundaboutRightIcon  sx={{fontSize: 20}}/> )
            case 'Route' : return (<RouteIcon  sx={{fontSize: 20}}/> )
            case 'Router' : return (<RouterIcon  sx={{fontSize: 20}}/> )
            case 'Rowing' : return (<RowingIcon  sx={{fontSize: 20}}/> )
            case 'RssFeed' : return (<RssFeedIcon  sx={{fontSize: 20}}/> )
            case 'Rsvp' : return (<RsvpIcon  sx={{fontSize: 20}}/> )
            case 'Rtt' : return (<RttIcon  sx={{fontSize: 20}}/> )
            case 'Rule' : return (<RuleIcon  sx={{fontSize: 20}}/> )
            case 'RuleFolder' : return (<RuleFolderIcon  sx={{fontSize: 20}}/> )
            case 'RunCircle' : return (<RunCircleIcon  sx={{fontSize: 20}}/> )
            case 'RunningWithErrors' : return (<RunningWithErrorsIcon  sx={{fontSize: 20}}/> )
            case 'RvHookup' : return (<RvHookupIcon  sx={{fontSize: 20}}/> )
            case 'SafetyCheck' : return (<SafetyCheckIcon  sx={{fontSize: 20}}/> )
            case 'SafetyDivider' : return (<SafetyDividerIcon  sx={{fontSize: 20}}/> )
            case 'Sailing' : return (<SailingIcon  sx={{fontSize: 20}}/> )
            case 'Sanitizer' : return (<SanitizerIcon  sx={{fontSize: 20}}/> )
            case 'Satellite' : return (<SatelliteIcon  sx={{fontSize: 20}}/> )
            case 'SatelliteAlt' : return (<SatelliteAltIcon  sx={{fontSize: 20}}/> )
            case 'Save' : return (<SaveIcon  sx={{fontSize: 20}}/> )
            case 'SaveAlt' : return (<SaveAltIcon  sx={{fontSize: 20}}/> )
            case 'SaveAs' : return (<SaveAsIcon  sx={{fontSize: 20}}/> )
            case 'SavedSearch' : return (<SavedSearchIcon  sx={{fontSize: 20}}/> )
            case 'Savings' : return (<SavingsIcon  sx={{fontSize: 20}}/> )
            case 'Scale' : return (<ScaleIcon  sx={{fontSize: 20}}/> )
            case 'Scanner' : return (<ScannerIcon  sx={{fontSize: 20}}/> )
            case 'ScatterPlot' : return (<ScatterPlotIcon  sx={{fontSize: 20}}/> )
            case 'Schedule' : return (<ScheduleIcon  sx={{fontSize: 20}}/> )
            case 'ScheduleSend' : return (<ScheduleSendIcon  sx={{fontSize: 20}}/> )
            case 'Schema' : return (<SchemaIcon  sx={{fontSize: 20}}/> )
            case 'School' : return (<SchoolIcon  sx={{fontSize: 20}}/> )
            case 'Science' : return (<ScienceIcon  sx={{fontSize: 20}}/> )
            case 'Score' : return (<ScoreIcon  sx={{fontSize: 20}}/> )
            case 'Scoreboard' : return (<ScoreboardIcon  sx={{fontSize: 20}}/> )
            case 'ScreenLockLandscape' : return (<ScreenLockLandscapeIcon  sx={{fontSize: 20}}/> )
            case 'ScreenLockPortrait' : return (<ScreenLockPortraitIcon  sx={{fontSize: 20}}/> )
            case 'ScreenLockRotation' : return (<ScreenLockRotationIcon  sx={{fontSize: 20}}/> )
            case 'ScreenRotation' : return (<ScreenRotationIcon  sx={{fontSize: 20}}/> )
            case 'ScreenRotationAlt' : return (<ScreenRotationAltIcon  sx={{fontSize: 20}}/> )
            case 'ScreenSearchDesktop' : return (<ScreenSearchDesktopIcon  sx={{fontSize: 20}}/> )
            case 'ScreenShare' : return (<ScreenShareIcon  sx={{fontSize: 20}}/> )
            case 'Screenshot' : return (<ScreenshotIcon  sx={{fontSize: 20}}/> )
            case 'ScreenshotMonitor' : return (<ScreenshotMonitorIcon  sx={{fontSize: 20}}/> )
            case 'ScubaDiving' : return (<ScubaDivingIcon  sx={{fontSize: 20}}/> )
            case 'Sd' : return (<SdIcon  sx={{fontSize: 20}}/> )
            case 'SdCard' : return (<SdCardIcon  sx={{fontSize: 20}}/> )
            case 'SdCardAlert' : return (<SdCardAlertIcon  sx={{fontSize: 20}}/> )
            case 'SdStorage' : return (<SdStorageIcon  sx={{fontSize: 20}}/> )
            case 'Search' : return (<SearchIcon  sx={{fontSize: 20}}/> )
            case 'SearchOff' : return (<SearchOffIcon  sx={{fontSize: 20}}/> )
            case 'Security' : return (<SecurityIcon  sx={{fontSize: 20}}/> )
            case 'SecurityUpdate' : return (<SecurityUpdateIcon  sx={{fontSize: 20}}/> )
            case 'SecurityUpdateGood' : return (<SecurityUpdateGoodIcon  sx={{fontSize: 20}}/> )
            case 'SecurityUpdateWarning' : return (<SecurityUpdateWarningIcon  sx={{fontSize: 20}}/> )
            case 'Segment' : return (<SegmentIcon  sx={{fontSize: 20}}/> )
            case 'SelectAll' : return (<SelectAllIcon  sx={{fontSize: 20}}/> )
            case 'SelfImprovement' : return (<SelfImprovementIcon  sx={{fontSize: 20}}/> )
            case 'Sell' : return (<SellIcon  sx={{fontSize: 20}}/> )
            case 'Send' : return (<SendIcon  sx={{fontSize: 20}}/> )
            case 'SendAndArchive' : return (<SendAndArchiveIcon  sx={{fontSize: 20}}/> )
            case 'SendTimeExtension' : return (<SendTimeExtensionIcon  sx={{fontSize: 20}}/> )
            case 'SendToMobile' : return (<SendToMobileIcon  sx={{fontSize: 20}}/> )
            case 'SensorDoor' : return (<SensorDoorIcon  sx={{fontSize: 20}}/> )
            case 'SensorOccupied' : return (<SensorOccupiedIcon  sx={{fontSize: 20}}/> )
            case 'SensorWindow' : return (<SensorWindowIcon  sx={{fontSize: 20}}/> )
            case 'Sensors' : return (<SensorsIcon  sx={{fontSize: 20}}/> )
            case 'SensorsOff' : return (<SensorsOffIcon  sx={{fontSize: 20}}/> )
            case 'SentimentDissatisfied' : return (<SentimentDissatisfiedIcon  sx={{fontSize: 20}}/> )
            case 'SentimentNeutral' : return (<SentimentNeutralIcon  sx={{fontSize: 20}}/> )
            case 'SentimentSatisfied' : return (<SentimentSatisfiedIcon  sx={{fontSize: 20}}/> )
            case 'SentimentSatisfiedAlt' : return (<SentimentSatisfiedAltIcon  sx={{fontSize: 20}}/> )
            case 'SentimentVeryDissatisfied' : return (<SentimentVeryDissatisfiedIcon  sx={{fontSize: 20}}/> )
            case 'SentimentVerySatisfied' : return (<SentimentVerySatisfiedIcon  sx={{fontSize: 20}}/> )
            case 'SetMeal' : return (<SetMealIcon  sx={{fontSize: 20}}/> )
            case 'Settings' : return (<SettingsIcon  sx={{fontSize: 20}}/> )
            case 'SettingsAccessibility' : return (<SettingsAccessibilityIcon  sx={{fontSize: 20}}/> )
            case 'SettingsApplications' : return (<SettingsApplicationsIcon  sx={{fontSize: 20}}/> )
            case 'SettingsBackupRestore' : return (<SettingsBackupRestoreIcon  sx={{fontSize: 20}}/> )
            case 'SettingsBluetooth' : return (<SettingsBluetoothIcon  sx={{fontSize: 20}}/> )
            case 'SettingsBrightness' : return (<SettingsBrightnessIcon  sx={{fontSize: 20}}/> )
            case 'SettingsCell' : return (<SettingsCellIcon  sx={{fontSize: 20}}/> )
            case 'SettingsEthernet' : return (<SettingsEthernetIcon  sx={{fontSize: 20}}/> )
            case 'SettingsInputAntenna' : return (<SettingsInputAntennaIcon  sx={{fontSize: 20}}/> )
            case 'SettingsInputComponent' : return (<SettingsInputComponentIcon  sx={{fontSize: 20}}/> )
            case 'SettingsInputComposite' : return (<SettingsInputCompositeIcon  sx={{fontSize: 20}}/> )
            case 'SettingsInputHdmi' : return (<SettingsInputHdmiIcon  sx={{fontSize: 20}}/> )
            case 'SettingsInputSvideo' : return (<SettingsInputSvideoIcon  sx={{fontSize: 20}}/> )
            case 'SettingsOverscan' : return (<SettingsOverscanIcon  sx={{fontSize: 20}}/> )
            case 'SettingsPhone' : return (<SettingsPhoneIcon  sx={{fontSize: 20}}/> )
            case 'SettingsPower' : return (<SettingsPowerIcon  sx={{fontSize: 20}}/> )
            case 'SettingsRemote' : return (<SettingsRemoteIcon  sx={{fontSize: 20}}/> )
            case 'SettingsSuggest' : return (<SettingsSuggestIcon  sx={{fontSize: 20}}/> )
            case 'SettingsSystemDaydream' : return (<SettingsSystemDaydreamIcon  sx={{fontSize: 20}}/> )
            case 'SettingsVoice' : return (<SettingsVoiceIcon  sx={{fontSize: 20}}/> )
            case 'SevenK' : return (<SevenKIcon  sx={{fontSize: 20}}/> )
            case 'SevenKPlus' : return (<SevenKPlusIcon  sx={{fontSize: 20}}/> )
            case 'SevenMp' : return (<SevenMpIcon  sx={{fontSize: 20}}/> )
            case 'SeventeenMp' : return (<SeventeenMpIcon  sx={{fontSize: 20}}/> )
            case 'SevereCold' : return (<SevereColdIcon  sx={{fontSize: 20}}/> )
           // case 'ShapeLine' : return (<ShapeLineIcon  sx={{fontSize: 20}}/> )
            case 'Share' : return (<ShareIcon  sx={{fontSize: 20}}/> )
            case 'ShareLocation' : return (<ShareLocationIcon  sx={{fontSize: 20}}/> )
            case 'Shield' : return (<ShieldIcon  sx={{fontSize: 20}}/> )
            case 'ShieldMoon' : return (<ShieldMoonIcon  sx={{fontSize: 20}}/> )
            case 'Shop' : return (<ShopIcon  sx={{fontSize: 20}}/> )
            case 'Shop2' : return (<Shop2Icon  sx={{fontSize: 20}}/> )
            case 'ShopTwo' : return (<ShopTwoIcon  sx={{fontSize: 20}}/> )
            case 'ShoppingBag' : return (<ShoppingBagIcon  sx={{fontSize: 20}}/> )
            case 'ShoppingBasket' : return (<ShoppingBasketIcon  sx={{fontSize: 20}}/> )
            case 'ShoppingCart' : return (<ShoppingCartIcon  sx={{fontSize: 20}}/> )
            case 'ShoppingCartCheckout' : return (<ShoppingCartCheckoutIcon  sx={{fontSize: 20}}/> )
            case 'ShortText' : return (<ShortTextIcon  sx={{fontSize: 20}}/> )
            case 'Shortcut' : return (<ShortcutIcon  sx={{fontSize: 20}}/> )
            case 'ShowChart' : return (<ShowChartIcon  sx={{fontSize: 20}}/> )
            case 'Shower' : return (<ShowerIcon  sx={{fontSize: 20}}/> )
            case 'Shuffle' : return (<ShuffleIcon  sx={{fontSize: 20}}/> )
            case 'ShuffleOn' : return (<ShuffleOnIcon  sx={{fontSize: 20}}/> )
            case 'ShutterSpeed' : return (<ShutterSpeedIcon  sx={{fontSize: 20}}/> )
            case 'Sick' : return (<SickIcon  sx={{fontSize: 20}}/> )
            case 'SignLanguage' : return (<SignLanguageIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellular0Bar' : return (<SignalCellular0BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellular1Bar' : return (<SignalCellular1BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellular2Bar' : return (<SignalCellular2BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellular3Bar' : return (<SignalCellular3BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellular4Bar' : return (<SignalCellular4BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellularAlt' : return (<SignalCellularAltIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellularAlt1Bar' : return (<SignalCellularAlt1BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellularAlt2Bar' : return (<SignalCellularAlt2BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellularConnectedNoInternet0Bar' : return (<SignalCellularConnectedNoInternet0BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellularConnectedNoInternet1Bar' : return (<SignalCellularConnectedNoInternet1BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellularConnectedNoInternet2Bar' : return (<SignalCellularConnectedNoInternet2BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellularConnectedNoInternet3Bar' : return (<SignalCellularConnectedNoInternet3BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellularConnectedNoInternet4Bar' : return (<SignalCellularConnectedNoInternet4BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellularNoSim' : return (<SignalCellularNoSimIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellularNodata' : return (<SignalCellularNodataIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellularNull' : return (<SignalCellularNullIcon  sx={{fontSize: 20}}/> )
            case 'SignalCellularOff' : return (<SignalCellularOffIcon  sx={{fontSize: 20}}/> )
            case 'SignalWifi0Bar' : return (<SignalWifi0BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalWifi1Bar' : return (<SignalWifi1BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalWifi1BarLock' : return (<SignalWifi1BarLockIcon  sx={{fontSize: 20}}/> )
            case 'SignalWifi2Bar' : return (<SignalWifi2BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalWifi2BarLock' : return (<SignalWifi2BarLockIcon  sx={{fontSize: 20}}/> )
            case 'SignalWifi3Bar' : return (<SignalWifi3BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalWifi3BarLock' : return (<SignalWifi3BarLockIcon  sx={{fontSize: 20}}/> )
            case 'SignalWifi4Bar' : return (<SignalWifi4BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalWifi4BarLock' : return (<SignalWifi4BarLockIcon  sx={{fontSize: 20}}/> )
            case 'SignalWifiBad' : return (<SignalWifiBadIcon  sx={{fontSize: 20}}/> )
            case 'SignalWifiConnectedNoInternet4' : return (<SignalWifiConnectedNoInternet4Icon  sx={{fontSize: 20}}/> )
            case 'SignalWifiOff' : return (<SignalWifiOffIcon  sx={{fontSize: 20}}/> )
            case 'SignalWifiStatusbar4Bar' : return (<SignalWifiStatusbar4BarIcon  sx={{fontSize: 20}}/> )
            case 'SignalWifiStatusbarConnectedNoInternet4' : return (<SignalWifiStatusbarConnectedNoInternet4Icon  sx={{fontSize: 20}}/> )
            case 'SignalWifiStatusbarNull' : return (<SignalWifiStatusbarNullIcon  sx={{fontSize: 20}}/> )
            case 'Signpost' : return (<SignpostIcon  sx={{fontSize: 20}}/> )
            case 'SimCard' : return (<SimCardIcon  sx={{fontSize: 20}}/> )
            case 'SimCardAlert' : return (<SimCardAlertIcon  sx={{fontSize: 20}}/> )
            case 'SimCardDownload' : return (<SimCardDownloadIcon  sx={{fontSize: 20}}/> )
            case 'SingleBed' : return (<SingleBedIcon  sx={{fontSize: 20}}/> )
            case 'Sip' : return (<SipIcon  sx={{fontSize: 20}}/> )
            case 'SixK' : return (<SixKIcon  sx={{fontSize: 20}}/> )
            case 'SixKPlus' : return (<SixKPlusIcon  sx={{fontSize: 20}}/> )
            case 'SixMp' : return (<SixMpIcon  sx={{fontSize: 20}}/> )
            case 'SixteenMp' : return (<SixteenMpIcon  sx={{fontSize: 20}}/> )
            case 'SixtyFps' : return (<SixtyFpsIcon  sx={{fontSize: 20}}/> )
            case 'SixtyFpsSelect' : return (<SixtyFpsSelectIcon  sx={{fontSize: 20}}/> )
            case 'Skateboarding' : return (<SkateboardingIcon  sx={{fontSize: 20}}/> )
            case 'SkipNext' : return (<SkipNextIcon  sx={{fontSize: 20}}/> )
            case 'SkipPrevious' : return (<SkipPreviousIcon  sx={{fontSize: 20}}/> )
            case 'Sledding' : return (<SleddingIcon  sx={{fontSize: 20}}/> )
            case 'Slideshow' : return (<SlideshowIcon  sx={{fontSize: 20}}/> )
            case 'SlowMotionVideo' : return (<SlowMotionVideoIcon  sx={{fontSize: 20}}/> )
            case 'SmartButton' : return (<SmartButtonIcon  sx={{fontSize: 20}}/> )
            case 'SmartDisplay' : return (<SmartDisplayIcon  sx={{fontSize: 20}}/> )
            case 'SmartScreen' : return (<SmartScreenIcon  sx={{fontSize: 20}}/> )
            case 'SmartToy' : return (<SmartToyIcon  sx={{fontSize: 20}}/> )
            case 'Smartphone' : return (<SmartphoneIcon  sx={{fontSize: 20}}/> )
            case 'SmokeFree' : return (<SmokeFreeIcon  sx={{fontSize: 20}}/> )
            case 'SmokingRooms' : return (<SmokingRoomsIcon  sx={{fontSize: 20}}/> )
            case 'Sms' : return (<SmsIcon  sx={{fontSize: 20}}/> )
            case 'SmsFailed' : return (<SmsFailedIcon  sx={{fontSize: 20}}/> )
            case 'SnippetFolder' : return (<SnippetFolderIcon  sx={{fontSize: 20}}/> )
            case 'Snooze' : return (<SnoozeIcon  sx={{fontSize: 20}}/> )
            case 'Snowboarding' : return (<SnowboardingIcon  sx={{fontSize: 20}}/> )
            case 'Snowmobile' : return (<SnowmobileIcon  sx={{fontSize: 20}}/> )
            case 'Snowshoeing' : return (<SnowshoeingIcon  sx={{fontSize: 20}}/> )
            case 'Soap' : return (<SoapIcon  sx={{fontSize: 20}}/> )
            case 'SocialDistance' : return (<SocialDistanceIcon  sx={{fontSize: 20}}/> )
            case 'SolarPower' : return (<SolarPowerIcon  sx={{fontSize: 20}}/> )
            case 'Sort' : return (<SortIcon  sx={{fontSize: 20}}/> )
            case 'SortByAlpha' : return (<SortByAlphaIcon  sx={{fontSize: 20}}/> )
            case 'Sos' : return (<SosIcon  sx={{fontSize: 20}}/> )
            case 'SoupKitchen' : return (<SoupKitchenIcon  sx={{fontSize: 20}}/> )
            case 'Source' : return (<SourceIcon  sx={{fontSize: 20}}/> )
            case 'South' : return (<SouthIcon  sx={{fontSize: 20}}/> )
            case 'SouthAmerica' : return (<SouthAmericaIcon  sx={{fontSize: 20}}/> )
            case 'SouthEast' : return (<SouthEastIcon  sx={{fontSize: 20}}/> )
            case 'SouthWest' : return (<SouthWestIcon  sx={{fontSize: 20}}/> )
            case 'Spa' : return (<SpaIcon  sx={{fontSize: 20}}/> )
            case 'SpaceBar' : return (<SpaceBarIcon  sx={{fontSize: 20}}/> )
            case 'SpatialAudio' : return (<SpatialAudioIcon  sx={{fontSize: 20}}/> )
            case 'SpatialAudioOff' : return (<SpatialAudioOffIcon  sx={{fontSize: 20}}/> )
            case 'SpatialTracking' : return (<SpatialTrackingIcon  sx={{fontSize: 20}}/> )
            case 'Speaker' : return (<SpeakerIcon  sx={{fontSize: 20}}/> )
            case 'SpeakerGroup' : return (<SpeakerGroupIcon  sx={{fontSize: 20}}/> )
            case 'SpeakerNotes' : return (<SpeakerNotesIcon  sx={{fontSize: 20}}/> )
            case 'SpeakerNotesOff' : return (<SpeakerNotesOffIcon  sx={{fontSize: 20}}/> )
            case 'SpeakerPhone' : return (<SpeakerPhoneIcon  sx={{fontSize: 20}}/> )
            case 'Speed' : return (<SpeedIcon  sx={{fontSize: 20}}/> )
            case 'Spellcheck' : return (<SpellcheckIcon  sx={{fontSize: 20}}/> )
            case 'Splitscreen' : return (<SplitscreenIcon  sx={{fontSize: 20}}/> )
            case 'Spoke' : return (<SpokeIcon  sx={{fontSize: 20}}/> )
            case 'Sports' : return (<SportsIcon  sx={{fontSize: 20}}/> )
            case 'SportsBar' : return (<SportsBarIcon  sx={{fontSize: 20}}/> )
            case 'SportsBaseball' : return (<SportsBaseballIcon  sx={{fontSize: 20}}/> )
            case 'SportsBasketball' : return (<SportsBasketballIcon  sx={{fontSize: 20}}/> )
            case 'SportsCricket' : return (<SportsCricketIcon  sx={{fontSize: 20}}/> )
            case 'SportsEsports' : return (<SportsEsportsIcon  sx={{fontSize: 20}}/> )
            case 'SportsFootball' : return (<SportsFootballIcon  sx={{fontSize: 20}}/> )
            case 'SportsGolf' : return (<SportsGolfIcon  sx={{fontSize: 20}}/> )
            case 'SportsGymnastics' : return (<SportsGymnasticsIcon  sx={{fontSize: 20}}/> )
            case 'SportsHandball' : return (<SportsHandballIcon  sx={{fontSize: 20}}/> )
            case 'SportsHockey' : return (<SportsHockeyIcon  sx={{fontSize: 20}}/> )
            case 'SportsKabaddi' : return (<SportsKabaddiIcon  sx={{fontSize: 20}}/> )
            case 'SportsMartialArts' : return (<SportsMartialArtsIcon  sx={{fontSize: 20}}/> )
            case 'SportsMma' : return (<SportsMmaIcon  sx={{fontSize: 20}}/> )
            case 'SportsMotorsports' : return (<SportsMotorsportsIcon  sx={{fontSize: 20}}/> )
            case 'SportsRugby' : return (<SportsRugbyIcon  sx={{fontSize: 20}}/> )
            case 'SportsScore' : return (<SportsScoreIcon  sx={{fontSize: 20}}/> )
            case 'SportsSoccer' : return (<SportsSoccerIcon  sx={{fontSize: 20}}/> )
            case 'SportsTennis' : return (<SportsTennisIcon  sx={{fontSize: 20}}/> )
            case 'SportsVolleyball' : return (<SportsVolleyballIcon  sx={{fontSize: 20}}/> )
            case 'Square' : return (<SquareIcon  sx={{fontSize: 20}}/> )
            case 'SquareFoot' : return (<SquareFootIcon  sx={{fontSize: 20}}/> )
            case 'SsidChart' : return (<SsidChartIcon  sx={{fontSize: 20}}/> )
            case 'StackedBarChart' : return (<StackedBarChartIcon  sx={{fontSize: 20}}/> )
            case 'StackedLineChart' : return (<StackedLineChartIcon  sx={{fontSize: 20}}/> )
            case 'Stadium' : return (<StadiumIcon  sx={{fontSize: 20}}/> )
            case 'Stairs' : return (<StairsIcon  sx={{fontSize: 20}}/> )
            case 'Star' : return (<StarIcon  sx={{fontSize: 20}}/> )
            case 'StarBorder' : return (<StarBorderIcon  sx={{fontSize: 20}}/> )
            case 'StarBorderPurple500' : return (<StarBorderPurple500Icon  sx={{fontSize: 20}}/> )
            case 'StarHalf' : return (<StarHalfIcon  sx={{fontSize: 20}}/> )
            case 'StarOutline' : return (<StarOutlineIcon  sx={{fontSize: 20}}/> )
            case 'StarPurple500' : return (<StarPurple500Icon  sx={{fontSize: 20}}/> )
            case 'StarRate' : return (<StarRateIcon  sx={{fontSize: 20}}/> )
            case 'Stars' : return (<StarsIcon  sx={{fontSize: 20}}/> )
            case 'Start' : return (<StartIcon  sx={{fontSize: 20}}/> )
            case 'StayCurrentLandscape' : return (<StayCurrentLandscapeIcon  sx={{fontSize: 20}}/> )
            case 'StayCurrentPortrait' : return (<StayCurrentPortraitIcon  sx={{fontSize: 20}}/> )
            case 'StayPrimaryLandscape' : return (<StayPrimaryLandscapeIcon  sx={{fontSize: 20}}/> )
            case 'StayPrimaryPortrait' : return (<StayPrimaryPortraitIcon  sx={{fontSize: 20}}/> )
            case 'StickyNote2' : return (<StickyNote2Icon  sx={{fontSize: 20}}/> )
            case 'Stop' : return (<StopIcon  sx={{fontSize: 20}}/> )
            case 'StopCircle' : return (<StopCircleIcon  sx={{fontSize: 20}}/> )
            case 'StopScreenShare' : return (<StopScreenShareIcon  sx={{fontSize: 20}}/> )
            case 'Storage' : return (<StorageIcon  sx={{fontSize: 20}}/> )
            case 'Store' : return (<StoreIcon  sx={{fontSize: 20}}/> )
            case 'StoreMallDirectory' : return (<StoreMallDirectoryIcon  sx={{fontSize: 20}}/> )
            case 'Storefront' : return (<StorefrontIcon  sx={{fontSize: 20}}/> )
            case 'Storm' : return (<StormIcon  sx={{fontSize: 20}}/> )
            case 'Straight' : return (<StraightIcon  sx={{fontSize: 20}}/> )
            case 'Straighten' : return (<StraightenIcon  sx={{fontSize: 20}}/> )
            case 'Stream' : return (<StreamIcon  sx={{fontSize: 20}}/> )
            case 'Streetview' : return (<StreetviewIcon  sx={{fontSize: 20}}/> )
            case 'StrikethroughS' : return (<StrikethroughSIcon  sx={{fontSize: 20}}/> )
            case 'Stroller' : return (<StrollerIcon  sx={{fontSize: 20}}/> )
            case 'Style' : return (<StyleIcon  sx={{fontSize: 20}}/> )
            case 'SubdirectoryArrowLeft' : return (<SubdirectoryArrowLeftIcon  sx={{fontSize: 20}}/> )
            case 'SubdirectoryArrowRight' : return (<SubdirectoryArrowRightIcon  sx={{fontSize: 20}}/> )
            case 'Subject' : return (<SubjectIcon  sx={{fontSize: 20}}/> )
            case 'Subscript' : return (<SubscriptIcon  sx={{fontSize: 20}}/> )
            case 'Subscriptions' : return (<SubscriptionsIcon  sx={{fontSize: 20}}/> )
            case 'Subtitles' : return (<SubtitlesIcon  sx={{fontSize: 20}}/> )
            case 'SubtitlesOff' : return (<SubtitlesOffIcon  sx={{fontSize: 20}}/> )
            case 'Subway' : return (<SubwayIcon  sx={{fontSize: 20}}/> )
            case 'Summarize' : return (<SummarizeIcon  sx={{fontSize: 20}}/> )
            case 'Superscript' : return (<SuperscriptIcon  sx={{fontSize: 20}}/> )
            case 'SupervisedUserCircle' : return (<SupervisedUserCircleIcon  sx={{fontSize: 20}}/> )
            case 'SupervisorAccount' : return (<SupervisorAccountIcon  sx={{fontSize: 20}}/> )
            case 'Support' : return (<SupportIcon  sx={{fontSize: 20}}/> )
            case 'SupportAgent' : return (<SupportAgentIcon  sx={{fontSize: 20}}/> )
            case 'Surfing' : return (<SurfingIcon  sx={{fontSize: 20}}/> )
            case 'SurroundSound' : return (<SurroundSoundIcon  sx={{fontSize: 20}}/> )
            case 'SwapCalls' : return (<SwapCallsIcon  sx={{fontSize: 20}}/> )
            case 'SwapHoriz' : return (<SwapHorizIcon  sx={{fontSize: 20}}/> )
            case 'SwapHorizontalCircle' : return (<SwapHorizontalCircleIcon  sx={{fontSize: 20}}/> )
            case 'SwapVert' : return (<SwapVertIcon  sx={{fontSize: 20}}/> )
            case 'SwapVerticalCircle' : return (<SwapVerticalCircleIcon  sx={{fontSize: 20}}/> )
            case 'Swipe' : return (<SwipeIcon  sx={{fontSize: 20}}/> )
            case 'SwipeDown' : return (<SwipeDownIcon  sx={{fontSize: 20}}/> )
            case 'SwipeDownAlt' : return (<SwipeDownAltIcon  sx={{fontSize: 20}}/> )
            case 'SwipeLeft' : return (<SwipeLeftIcon  sx={{fontSize: 20}}/> )
            case 'SwipeLeftAlt' : return (<SwipeLeftAltIcon  sx={{fontSize: 20}}/> )
            case 'SwipeRight' : return (<SwipeRightIcon  sx={{fontSize: 20}}/> )
            case 'SwipeRightAlt' : return (<SwipeRightAltIcon  sx={{fontSize: 20}}/> )
            case 'SwipeUp' : return (<SwipeUpIcon  sx={{fontSize: 20}}/> )
            case 'SwipeUpAlt' : return (<SwipeUpAltIcon  sx={{fontSize: 20}}/> )
            case 'SwipeVertical' : return (<SwipeVerticalIcon  sx={{fontSize: 20}}/> )
            case 'SwitchAccessShortcut' : return (<SwitchAccessShortcutIcon  sx={{fontSize: 20}}/> )
            case 'SwitchAccessShortcutAdd' : return (<SwitchAccessShortcutAddIcon  sx={{fontSize: 20}}/> )
            case 'SwitchAccount' : return (<SwitchAccountIcon  sx={{fontSize: 20}}/> )
            case 'SwitchCamera' : return (<SwitchCameraIcon  sx={{fontSize: 20}}/> )
            case 'SwitchLeft' : return (<SwitchLeftIcon  sx={{fontSize: 20}}/> )
            case 'SwitchRight' : return (<SwitchRightIcon  sx={{fontSize: 20}}/> )
            case 'SwitchVideo' : return (<SwitchVideoIcon  sx={{fontSize: 20}}/> )
            case 'Synagogue' : return (<SynagogueIcon  sx={{fontSize: 20}}/> )
            case 'Sync' : return (<SyncIcon  sx={{fontSize: 20}}/> )
            case 'SyncAlt' : return (<SyncAltIcon  sx={{fontSize: 20}}/> )
            case 'SyncDisabled' : return (<SyncDisabledIcon  sx={{fontSize: 20}}/> )
            case 'SyncLock' : return (<SyncLockIcon  sx={{fontSize: 20}}/> )
            case 'SyncProblem' : return (<SyncProblemIcon  sx={{fontSize: 20}}/> )
            case 'SystemSecurityUpdate' : return (<SystemSecurityUpdateIcon  sx={{fontSize: 20}}/> )
            case 'SystemSecurityUpdateGood' : return (<SystemSecurityUpdateGoodIcon  sx={{fontSize: 20}}/> )
            case 'SystemSecurityUpdateWarning' : return (<SystemSecurityUpdateWarningIcon  sx={{fontSize: 20}}/> )
            case 'SystemUpdate' : return (<SystemUpdateIcon  sx={{fontSize: 20}}/> )
            case 'SystemUpdateAlt' : return (<SystemUpdateAltIcon  sx={{fontSize: 20}}/> )
            case 'Tab' : return (<TabIcon  sx={{fontSize: 20}}/> )
            case 'TabUnselected' : return (<TabUnselectedIcon  sx={{fontSize: 20}}/> )
            case 'TableBar' : return (<TableBarIcon  sx={{fontSize: 20}}/> )
            case 'TableChart' : return (<TableChartIcon  sx={{fontSize: 20}}/> )
            case 'TableRestaurant' : return (<TableRestaurantIcon  sx={{fontSize: 20}}/> )
            case 'TableRows' : return (<TableRowsIcon  sx={{fontSize: 20}}/> )
            case 'TableView' : return (<TableViewIcon  sx={{fontSize: 20}}/> )
            case 'Tablet' : return (<TabletIcon  sx={{fontSize: 20}}/> )
            case 'TabletAndroid' : return (<TabletAndroidIcon  sx={{fontSize: 20}}/> )
            case 'TabletMac' : return (<TabletMacIcon  sx={{fontSize: 20}}/> )
            case 'Tag' : return (<TagIcon  sx={{fontSize: 20}}/> )
            case 'TagFaces' : return (<TagFacesIcon  sx={{fontSize: 20}}/> )
            case 'TakeoutDining' : return (<TakeoutDiningIcon  sx={{fontSize: 20}}/> )
            case 'TapAndPlay' : return (<TapAndPlayIcon  sx={{fontSize: 20}}/> )
            case 'Tapas' : return (<TapasIcon  sx={{fontSize: 20}}/> )
            case 'Task' : return (<TaskIcon  sx={{fontSize: 20}}/> )
            case 'TaskAlt' : return (<TaskAltIcon  sx={{fontSize: 20}}/> )
            case 'TaxiAlert' : return (<TaxiAlertIcon  sx={{fontSize: 20}}/> )
            case 'Telegram' : return (<TelegramIcon  sx={{fontSize: 20}}/> )
            case 'TempleBuddhist' : return (<TempleBuddhistIcon  sx={{fontSize: 20}}/> )
            case 'TempleHindu' : return (<TempleHinduIcon  sx={{fontSize: 20}}/> )
            case 'TenMp' : return (<TenMpIcon  sx={{fontSize: 20}}/> )
            case 'Terminal' : return (<TerminalIcon  sx={{fontSize: 20}}/> )
            case 'Terrain' : return (<TerrainIcon  sx={{fontSize: 20}}/> )
            case 'TextDecrease' : return (<TextDecreaseIcon  sx={{fontSize: 20}}/> )
            case 'TextFields' : return (<TextFieldsIcon  sx={{fontSize: 20}}/> )
            case 'TextFormat' : return (<TextFormatIcon  sx={{fontSize: 20}}/> )
            case 'TextIncrease' : return (<TextIncreaseIcon  sx={{fontSize: 20}}/> )
            case 'TextRotateUp' : return (<TextRotateUpIcon  sx={{fontSize: 20}}/> )
            case 'TextRotateVertical' : return (<TextRotateVerticalIcon  sx={{fontSize: 20}}/> )
            case 'TextRotationAngledown' : return (<TextRotationAngledownIcon  sx={{fontSize: 20}}/> )
            case 'TextRotationAngleup' : return (<TextRotationAngleupIcon  sx={{fontSize: 20}}/> )
            case 'TextRotationDown' : return (<TextRotationDownIcon  sx={{fontSize: 20}}/> )
            case 'TextRotationNone' : return (<TextRotationNoneIcon  sx={{fontSize: 20}}/> )
            case 'TextSnippet' : return (<TextSnippetIcon  sx={{fontSize: 20}}/> )
            case 'Textsms' : return (<TextsmsIcon  sx={{fontSize: 20}}/> )
            case 'Texture' : return (<TextureIcon  sx={{fontSize: 20}}/> )
            case 'TheaterComedy' : return (<TheaterComedyIcon  sx={{fontSize: 20}}/> )
            case 'Theaters' : return (<TheatersIcon  sx={{fontSize: 20}}/> )
            case 'Thermostat' : return (<ThermostatIcon  sx={{fontSize: 20}}/> )
            case 'ThermostatAuto' : return (<ThermostatAutoIcon  sx={{fontSize: 20}}/> )
            case 'ThirteenMp' : return (<ThirteenMpIcon  sx={{fontSize: 20}}/> )
            case 'ThirtyFps' : return (<ThirtyFpsIcon  sx={{fontSize: 20}}/> )
            case 'ThirtyFpsSelect' : return (<ThirtyFpsSelectIcon  sx={{fontSize: 20}}/> )
            case 'ThreeDRotation' : return (<ThreeDRotationIcon  sx={{fontSize: 20}}/> )
            case 'ThreeGMobiledata' : return (<ThreeGMobiledataIcon  sx={{fontSize: 20}}/> )
            case 'ThreeK' : return (<ThreeKIcon  sx={{fontSize: 20}}/> )
            case 'ThreeKPlus' : return (<ThreeKPlusIcon  sx={{fontSize: 20}}/> )
            case 'ThreeMp' : return (<ThreeMpIcon  sx={{fontSize: 20}}/> )
            case 'ThreeP' : return (<ThreePIcon  sx={{fontSize: 20}}/> )
            case 'ThreeSixty' : return (<ThreeSixtyIcon  sx={{fontSize: 20}}/> )
            case 'ThumbDown' : return (<ThumbDownIcon  sx={{fontSize: 20}}/> )
            case 'ThumbDownAlt' : return (<ThumbDownAltIcon  sx={{fontSize: 20}}/> )
            case 'ThumbDownOffAlt' : return (<ThumbDownOffAltIcon  sx={{fontSize: 20}}/> )
            case 'ThumbUp' : return (<ThumbUpIcon  sx={{fontSize: 20}}/> )
            case 'ThumbUpAlt' : return (<ThumbUpAltIcon  sx={{fontSize: 20}}/> )
            case 'ThumbUpOffAlt' : return (<ThumbUpOffAltIcon  sx={{fontSize: 20}}/> )
            case 'ThumbsUpDown' : return (<ThumbsUpDownIcon  sx={{fontSize: 20}}/> )
            case 'Thunderstorm' : return (<ThunderstormIcon  sx={{fontSize: 20}}/> )
            case 'TimeToLeave' : return (<TimeToLeaveIcon  sx={{fontSize: 20}}/> )
            case 'Timelapse' : return (<TimelapseIcon  sx={{fontSize: 20}}/> )
            case 'Timeline' : return (<TimelineIcon  sx={{fontSize: 20}}/> )
            case 'Timer' : return (<TimerIcon  sx={{fontSize: 20}}/> )
            case 'Timer10' : return (<Timer10Icon  sx={{fontSize: 20}}/> )
            case 'Timer10Select' : return (<Timer10SelectIcon  sx={{fontSize: 20}}/> )
            case 'Timer3' : return (<Timer3Icon  sx={{fontSize: 20}}/> )
            case 'Timer3Select' : return (<Timer3SelectIcon  sx={{fontSize: 20}}/> )
            case 'TimerOff' : return (<TimerOffIcon  sx={{fontSize: 20}}/> )
            case 'TimesOneMobiledata' : return (<TimesOneMobiledataIcon  sx={{fontSize: 20}}/> )
            case 'TipsAndUpdates' : return (<TipsAndUpdatesIcon  sx={{fontSize: 20}}/> )
            case 'TireRepair' : return (<TireRepairIcon  sx={{fontSize: 20}}/> )
            case 'Title' : return (<TitleIcon  sx={{fontSize: 20}}/> )
            case 'Toc' : return (<TocIcon  sx={{fontSize: 20}}/> )
            case 'Today' : return (<TodayIcon  sx={{fontSize: 20}}/> )
            case 'ToggleOff' : return (<ToggleOffIcon  sx={{fontSize: 20}}/> )
            case 'ToggleOn' : return (<ToggleOnIcon  sx={{fontSize: 20}}/> )
            case 'Token' : return (<TokenIcon  sx={{fontSize: 20}}/> )
            case 'Toll' : return (<TollIcon  sx={{fontSize: 20}}/> )
            case 'Tonality' : return (<TonalityIcon  sx={{fontSize: 20}}/> )
            case 'Topic' : return (<TopicIcon  sx={{fontSize: 20}}/> )
            case 'Tornado' : return (<TornadoIcon  sx={{fontSize: 20}}/> )
            case 'TouchApp' : return (<TouchAppIcon  sx={{fontSize: 20}}/> )
            case 'Tour' : return (<TourIcon  sx={{fontSize: 20}}/> )
            case 'Toys' : return (<ToysIcon  sx={{fontSize: 20}}/> )
            case 'TrackChanges' : return (<TrackChangesIcon  sx={{fontSize: 20}}/> )
            case 'Traffic' : return (<TrafficIcon  sx={{fontSize: 20}}/> )
            case 'Train' : return (<TrainIcon  sx={{fontSize: 20}}/> )
            case 'Tram' : return (<TramIcon  sx={{fontSize: 20}}/> )
            //case 'Transcribe' : return (<TranscribeIcon  sx={{fontSize: 20}}/> )
            case 'TransferWithinAStation' : return (<TransferWithinAStationIcon  sx={{fontSize: 20}}/> )
            case 'Transform' : return (<TransformIcon  sx={{fontSize: 20}}/> )
            case 'Transgender' : return (<TransgenderIcon  sx={{fontSize: 20}}/> )
            case 'TransitEnterexit' : return (<TransitEnterexitIcon  sx={{fontSize: 20}}/> )
            case 'Translate' : return (<TranslateIcon  sx={{fontSize: 20}}/> )
            case 'TravelExplore' : return (<TravelExploreIcon  sx={{fontSize: 20}}/> )
            case 'TrendingDown' : return (<TrendingDownIcon  sx={{fontSize: 20}}/> )
            case 'TrendingFlat' : return (<TrendingFlatIcon  sx={{fontSize: 20}}/> )
            case 'TrendingUp' : return (<TrendingUpIcon  sx={{fontSize: 20}}/> )
            case 'TripOrigin' : return (<TripOriginIcon  sx={{fontSize: 20}}/> )
           // case 'Troubleshoot' : return (<TroubleshootIcon  sx={{fontSize: 20}}/> )
            case 'Try' : return (<TryIcon  sx={{fontSize: 20}}/> )
            case 'Tsunami' : return (<TsunamiIcon  sx={{fontSize: 20}}/> )
            case 'Tty' : return (<TtyIcon  sx={{fontSize: 20}}/> )
            case 'Tune' : return (<TuneIcon  sx={{fontSize: 20}}/> )
            case 'Tungsten' : return (<TungstenIcon  sx={{fontSize: 20}}/> )
            case 'TurnLeft' : return (<TurnLeftIcon  sx={{fontSize: 20}}/> )
            case 'TurnRight' : return (<TurnRightIcon  sx={{fontSize: 20}}/> )
            case 'TurnSlightLeft' : return (<TurnSlightLeftIcon  sx={{fontSize: 20}}/> )
            case 'TurnSlightRight' : return (<TurnSlightRightIcon  sx={{fontSize: 20}}/> )
            case 'TurnedIn' : return (<TurnedInIcon  sx={{fontSize: 20}}/> )
            case 'TurnedInNot' : return (<TurnedInNotIcon  sx={{fontSize: 20}}/> )
            case 'Tv' : return (<TvIcon  sx={{fontSize: 20}}/> )
            case 'TvOff' : return (<TvOffIcon  sx={{fontSize: 20}}/> )
            case 'TwelveMp' : return (<TwelveMpIcon  sx={{fontSize: 20}}/> )
            case 'TwentyFourMp' : return (<TwentyFourMpIcon  sx={{fontSize: 20}}/> )
            case 'TwentyOneMp' : return (<TwentyOneMpIcon  sx={{fontSize: 20}}/> )
            case 'TwentyThreeMp' : return (<TwentyThreeMpIcon  sx={{fontSize: 20}}/> )
            case 'TwentyTwoMp' : return (<TwentyTwoMpIcon  sx={{fontSize: 20}}/> )
            case 'TwentyZeroMp' : return (<TwentyZeroMpIcon  sx={{fontSize: 20}}/> )
            case 'Twitter' : return (<TwitterIcon  sx={{fontSize: 20}}/> )
            case 'TwoK' : return (<TwoKIcon  sx={{fontSize: 20}}/> )
            case 'TwoKPlus' : return (<TwoKPlusIcon  sx={{fontSize: 20}}/> )
            case 'TwoMp' : return (<TwoMpIcon  sx={{fontSize: 20}}/> )
            case 'TwoWheeler' : return (<TwoWheelerIcon  sx={{fontSize: 20}}/> )
            //case 'TypeSpecimen' : return (<TypeSpecimenIcon  sx={{fontSize: 20}}/> )
            case 'UTurnLeft' : return (<UTurnLeftIcon  sx={{fontSize: 20}}/> )
            case 'UTurnRight' : return (<UTurnRightIcon  sx={{fontSize: 20}}/> )
            case 'Umbrella' : return (<UmbrellaIcon  sx={{fontSize: 20}}/> )
            case 'Unarchive' : return (<UnarchiveIcon  sx={{fontSize: 20}}/> )
            case 'Undo' : return (<UndoIcon  sx={{fontSize: 20}}/> )
            case 'UnfoldLess' : return (<UnfoldLessIcon  sx={{fontSize: 20}}/> )
            //case 'UnfoldLessDouble' : return (<UnfoldLessDoubleIcon  sx={{fontSize: 20}}/> )
            case 'UnfoldMore' : return (<UnfoldMoreIcon  sx={{fontSize: 20}}/> )
            //case 'UnfoldMoreDouble' : return (<UnfoldMoreDoubleIcon  sx={{fontSize: 20}}/> )
            case 'Unpublished' : return (<UnpublishedIcon  sx={{fontSize: 20}}/> )
            case 'Unsubscribe' : return (<UnsubscribeIcon  sx={{fontSize: 20}}/> )
            case 'Upcoming' : return (<UpcomingIcon  sx={{fontSize: 20}}/> )
            case 'Update' : return (<UpdateIcon  sx={{fontSize: 20}}/> )
            case 'UpdateDisabled' : return (<UpdateDisabledIcon  sx={{fontSize: 20}}/> )
            case 'Upgrade' : return (<UpgradeIcon  sx={{fontSize: 20}}/> )
            case 'Upload' : return (<UploadIcon  sx={{fontSize: 20}}/> )
            case 'UploadFile' : return (<UploadFileIcon  sx={{fontSize: 20}}/> )
            case 'Usb' : return (<UsbIcon  sx={{fontSize: 20}}/> )
            case 'UsbOff' : return (<UsbOffIcon  sx={{fontSize: 20}}/> )
            case 'Vaccines' : return (<VaccinesIcon  sx={{fontSize: 20}}/> )
            case 'VapeFree' : return (<VapeFreeIcon  sx={{fontSize: 20}}/> )
            case 'VapingRooms' : return (<VapingRoomsIcon  sx={{fontSize: 20}}/> )
            case 'Verified' : return (<VerifiedIcon  sx={{fontSize: 20}}/> )
            case 'VerifiedUser' : return (<VerifiedUserIcon  sx={{fontSize: 20}}/> )
            case 'VerticalAlignBottom' : return (<VerticalAlignBottomIcon  sx={{fontSize: 20}}/> )
            case 'VerticalAlignCenter' : return (<VerticalAlignCenterIcon  sx={{fontSize: 20}}/> )
            case 'VerticalAlignTop' : return (<VerticalAlignTopIcon  sx={{fontSize: 20}}/> )
            case 'VerticalShades' : return (<VerticalShadesIcon  sx={{fontSize: 20}}/> )
            case 'VerticalShadesClosed' : return (<VerticalShadesClosedIcon  sx={{fontSize: 20}}/> )
            case 'VerticalSplit' : return (<VerticalSplitIcon  sx={{fontSize: 20}}/> )
            case 'Vibration' : return (<VibrationIcon  sx={{fontSize: 20}}/> )
            case 'VideoCall' : return (<VideoCallIcon  sx={{fontSize: 20}}/> )
            case 'VideoCameraBack' : return (<VideoCameraBackIcon  sx={{fontSize: 20}}/> )
            case 'VideoCameraFront' : return (<VideoCameraFrontIcon  sx={{fontSize: 20}}/> )
            //case 'VideoChat' : return (<VideoChatIcon  sx={{fontSize: 20}}/> )
            case 'VideoFile' : return (<VideoFileIcon  sx={{fontSize: 20}}/> )
            case 'VideoLabel' : return (<VideoLabelIcon  sx={{fontSize: 20}}/> )
            case 'VideoLibrary' : return (<VideoLibraryIcon  sx={{fontSize: 20}}/> )
            case 'VideoSettings' : return (<VideoSettingsIcon  sx={{fontSize: 20}}/> )
            case 'VideoStable' : return (<VideoStableIcon  sx={{fontSize: 20}}/> )
            case 'Videocam' : return (<VideocamIcon  sx={{fontSize: 20}}/> )
            case 'VideocamOff' : return (<VideocamOffIcon  sx={{fontSize: 20}}/> )
            case 'VideogameAsset' : return (<VideogameAssetIcon  sx={{fontSize: 20}}/> )
            case 'VideogameAssetOff' : return (<VideogameAssetOffIcon  sx={{fontSize: 20}}/> )
            case 'ViewAgenda' : return (<ViewAgendaIcon  sx={{fontSize: 20}}/> )
            case 'ViewArray' : return (<ViewArrayIcon  sx={{fontSize: 20}}/> )
            case 'ViewCarousel' : return (<ViewCarouselIcon  sx={{fontSize: 20}}/> )
            case 'ViewColumn' : return (<ViewColumnIcon  sx={{fontSize: 20}}/> )
            case 'ViewComfy' : return (<ViewComfyIcon  sx={{fontSize: 20}}/> )
            case 'ViewComfyAlt' : return (<ViewComfyAltIcon  sx={{fontSize: 20}}/> )
            case 'ViewCompact' : return (<ViewCompactIcon  sx={{fontSize: 20}}/> )
            case 'ViewCompactAlt' : return (<ViewCompactAltIcon  sx={{fontSize: 20}}/> )
            //case 'ViewCozy' : return (<ViewCozyIcon  sx={{fontSize: 20}}/> )
            case 'ViewDay' : return (<ViewDayIcon  sx={{fontSize: 20}}/> )
            case 'ViewHeadline' : return (<ViewHeadlineIcon  sx={{fontSize: 20}}/> )
            case 'ViewInAr' : return (<ViewInArIcon  sx={{fontSize: 20}}/> )
            case 'ViewKanban' : return (<ViewKanbanIcon  sx={{fontSize: 20}}/> )
            case 'ViewList' : return (<ViewListIcon  sx={{fontSize: 20}}/> )
            case 'ViewModule' : return (<ViewModuleIcon  sx={{fontSize: 20}}/> )
            case 'ViewQuilt' : return (<ViewQuiltIcon  sx={{fontSize: 20}}/> )
            case 'ViewSidebar' : return (<ViewSidebarIcon  sx={{fontSize: 20}}/> )
            case 'ViewStream' : return (<ViewStreamIcon  sx={{fontSize: 20}}/> )
            case 'ViewTimeline' : return (<ViewTimelineIcon  sx={{fontSize: 20}}/> )
            case 'ViewWeek' : return (<ViewWeekIcon  sx={{fontSize: 20}}/> )
            case 'Vignette' : return (<VignetteIcon  sx={{fontSize: 20}}/> )
            case 'Villa' : return (<VillaIcon  sx={{fontSize: 20}}/> )
            case 'Visibility' : return (<VisibilityIcon  sx={{fontSize: 20}}/> )
            case 'VisibilityOff' : return (<VisibilityOffIcon  sx={{fontSize: 20}}/> )
            case 'VoiceChat' : return (<VoiceChatIcon  sx={{fontSize: 20}}/> )
            case 'VoiceOverOff' : return (<VoiceOverOffIcon  sx={{fontSize: 20}}/> )
            case 'Voicemail' : return (<VoicemailIcon  sx={{fontSize: 20}}/> )
            case 'Volcano' : return (<VolcanoIcon  sx={{fontSize: 20}}/> )
            case 'VolumeDown' : return (<VolumeDownIcon  sx={{fontSize: 20}}/> )
            case 'VolumeMute' : return (<VolumeMuteIcon  sx={{fontSize: 20}}/> )
            case 'VolumeOff' : return (<VolumeOffIcon  sx={{fontSize: 20}}/> )
            case 'VolumeUp' : return (<VolumeUpIcon  sx={{fontSize: 20}}/> )
            case 'VolunteerActivism' : return (<VolunteerActivismIcon  sx={{fontSize: 20}}/> )
            case 'VpnKey' : return (<VpnKeyIcon  sx={{fontSize: 20}}/> )
            case 'VpnKeyOff' : return (<VpnKeyOffIcon  sx={{fontSize: 20}}/> )
            case 'VpnLock' : return (<VpnLockIcon  sx={{fontSize: 20}}/> )
            case 'Vrpano' : return (<VrpanoIcon  sx={{fontSize: 20}}/> )
            //case 'Wallet' : return (<WalletIcon  sx={{fontSize: 20}}/> )
            case 'Wallpaper' : return (<WallpaperIcon  sx={{fontSize: 20}}/> )
            case 'Warehouse' : return (<WarehouseIcon  sx={{fontSize: 20}}/> )
            case 'Warning' : return (<WarningIcon  sx={{fontSize: 20}}/> )
            case 'WarningAmber' : return (<WarningAmberIcon  sx={{fontSize: 20}}/> )
            case 'Wash' : return (<WashIcon  sx={{fontSize: 20}}/> )
            case 'Watch' : return (<WatchIcon  sx={{fontSize: 20}}/> )
            case 'WatchLater' : return (<WatchLaterIcon  sx={{fontSize: 20}}/> )
            case 'WatchOff' : return (<WatchOffIcon  sx={{fontSize: 20}}/> )
            case 'Water' : return (<WaterIcon  sx={{fontSize: 20}}/> )
            case 'WaterDamage' : return (<WaterDamageIcon  sx={{fontSize: 20}}/> )
            case 'WaterfallChart' : return (<WaterfallChartIcon  sx={{fontSize: 20}}/> )
            case 'Waves' : return (<WavesIcon  sx={{fontSize: 20}}/> )
            case 'WbAuto' : return (<WbAutoIcon  sx={{fontSize: 20}}/> )
            case 'WbCloudy' : return (<WbCloudyIcon  sx={{fontSize: 20}}/> )
            case 'WbIncandescent' : return (<WbIncandescentIcon  sx={{fontSize: 20}}/> )
            case 'WbIridescent' : return (<WbIridescentIcon  sx={{fontSize: 20}}/> )
            case 'WbShade' : return (<WbShadeIcon  sx={{fontSize: 20}}/> )
            case 'WbSunny' : return (<WbSunnyIcon  sx={{fontSize: 20}}/> )
            case 'WbTwilight' : return (<WbTwilightIcon  sx={{fontSize: 20}}/> )
            case 'Wc' : return (<WcIcon  sx={{fontSize: 20}}/> )
            case 'Web' : return (<WebIcon  sx={{fontSize: 20}}/> )
            case 'WebAsset' : return (<WebAssetIcon  sx={{fontSize: 20}}/> )
            case 'WebAssetOff' : return (<WebAssetOffIcon  sx={{fontSize: 20}}/> )
           // case 'WebStories' : return (<WebStoriesIcon  sx={{fontSize: 20}}/> )
            case 'Webhook' : return (<WebhookIcon  sx={{fontSize: 20}}/> )
            case 'Weekend' : return (<WeekendIcon  sx={{fontSize: 20}}/> )
            case 'West' : return (<WestIcon  sx={{fontSize: 20}}/> )
            case 'WhatsApp' : return (<WhatsAppIcon  sx={{fontSize: 20}}/> )
            case 'Whatshot' : return (<WhatshotIcon  sx={{fontSize: 20}}/> )
            case 'WheelchairPickup' : return (<WheelchairPickupIcon  sx={{fontSize: 20}}/> )
            case 'WhereToVote' : return (<WhereToVoteIcon  sx={{fontSize: 20}}/> )
            case 'Widgets' : return (<WidgetsIcon  sx={{fontSize: 20}}/> )
            // case 'WidthFull' : return (<WidthFullIcon  sx={{fontSize: 20}}/> )
            // case 'WidthNormal' : return (<WidthNormalIcon  sx={{fontSize: 20}}/> )
            // case 'WidthWide' : return (<WidthWideIcon  sx={{fontSize: 20}}/> )
            case 'Wifi' : return (<WifiIcon  sx={{fontSize: 20}}/> )
            case 'Wifi1Bar' : return (<Wifi1BarIcon  sx={{fontSize: 20}}/> )
            case 'Wifi2Bar' : return (<Wifi2BarIcon  sx={{fontSize: 20}}/> )
            case 'WifiCalling' : return (<WifiCallingIcon  sx={{fontSize: 20}}/> )
            case 'WifiCalling3' : return (<WifiCalling3Icon  sx={{fontSize: 20}}/> )
            case 'WifiChannel' : return (<WifiChannelIcon  sx={{fontSize: 20}}/> )
            case 'WifiFind' : return (<WifiFindIcon  sx={{fontSize: 20}}/> )
            case 'WifiLock' : return (<WifiLockIcon  sx={{fontSize: 20}}/> )
            case 'WifiOff' : return (<WifiOffIcon  sx={{fontSize: 20}}/> )
            case 'WifiPassword' : return (<WifiPasswordIcon  sx={{fontSize: 20}}/> )
            case 'WifiProtectedSetup' : return (<WifiProtectedSetupIcon  sx={{fontSize: 20}}/> )
            case 'WifiTethering' : return (<WifiTetheringIcon  sx={{fontSize: 20}}/> )
            case 'WifiTetheringError' : return (<WifiTetheringErrorIcon  sx={{fontSize: 20}}/> )
            case 'WifiTetheringOff' : return (<WifiTetheringOffIcon  sx={{fontSize: 20}}/> )
            case 'WindPower' : return (<WindPowerIcon  sx={{fontSize: 20}}/> )
            case 'Window' : return (<WindowIcon  sx={{fontSize: 20}}/> )
            case 'WineBar' : return (<WineBarIcon  sx={{fontSize: 20}}/> )
            case 'Woman' : return (<WomanIcon  sx={{fontSize: 20}}/> )
            //case 'Woman2' : return (<Woman2Icon  sx={{fontSize: 20}}/> )
            case 'Work' : return (<WorkIcon  sx={{fontSize: 20}}/> )
            case 'WorkHistory' : return (<WorkHistoryIcon  sx={{fontSize: 20}}/> )
            case 'WorkOff' : return (<WorkOffIcon  sx={{fontSize: 20}}/> )
            case 'WorkOutline' : return (<WorkOutlineIcon  sx={{fontSize: 20}}/> )
            case 'WorkspacePremium' : return (<WorkspacePremiumIcon  sx={{fontSize: 20}}/> )
            case 'Workspaces' : return (<WorkspacesIcon  sx={{fontSize: 20}}/> )
            case 'WrapText' : return (<WrapTextIcon  sx={{fontSize: 20}}/> )
            case 'WrongLocation' : return (<WrongLocationIcon  sx={{fontSize: 20}}/> )
            case 'Wysiwyg' : return (<WysiwygIcon  sx={{fontSize: 20}}/> )
            case 'Yard' : return (<YardIcon  sx={{fontSize: 20}}/> )
            case 'YouTube' : return (<YouTubeIcon  sx={{fontSize: 20}}/> )
            case 'YoutubeSearchedFor' : return (<YoutubeSearchedForIcon  sx={{fontSize: 20}}/> )
            case 'ZoomIn' : return (<ZoomInIcon  sx={{fontSize: 20}}/> )
            case 'ZoomInMap' : return (<ZoomInMapIcon  sx={{fontSize: 20}}/> )
            case 'ZoomOut' : return (<ZoomOutIcon  sx={{fontSize: 20}}/> )
            case 'ZoomOutMap' : return (<ZoomOutMapIcon  sx={{fontSize: 20}}/> )

        
            default:
                 return (<AbcIcon  sx={{fontSize: 20}}/> )

        }
    
};

export default ItemIcono;
