import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";

import * as Yup from "yup";

import { Grid, Stack, TextField } from "@mui/material";

import { formatoFecha } from "../../shared/validations/validaciones";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";

import {
  consultaActiIndependiente,
  crearClienteActiIndependiente,
} from "../services/clienteService";

import "../styles/main.css";
import { StyleInputs } from "../styles/themes";

const FormClienteActividadIndependiente = ({
  idCliente,
  setInfoClienteGrabada,
}) => {
  const [clienteInfo, setClienteInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [idActiIndependienteAux, setIdActiIndependienteAux] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const informacionActiIndependiente = await consultaActiIndependiente(
          idCliente
        );

        console.log({ informacionActiIndependiente });

        if (informacionActiIndependiente.state) {
          setIdActiIndependienteAux(
            informacionActiIndependiente.data.idActividadIndependiente
          );
          setClienteInfo(informacionActiIndependiente.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setClienteInfo({});
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const informacionActiIndependiente = {
      idCliente: idCliente,
      idActividadIndependiente: idActiIndependienteAux,
      rucEmpresa: valores.rucEmpresa,
      nombreEmpresa: valores.nombreEmpresa.toUpperCase(),
      profesion: valores.profesion.toUpperCase(),
      fechaInicio: valores.fechaInicio,
      esActivo: true,
    };

    console.log({ informacionActiIndependiente });

    try {
      const respuestaActiIndependiente = await crearClienteActiIndependiente(
        informacionActiIndependiente
      );
      console.log(respuestaActiIndependiente);

      if (respuestaActiIndependiente.state) {
        setDatosEnviadosCorrectamente(true);
        setIdActiIndependienteAux(respuestaActiIndependiente.data);

        if (setInfoClienteGrabada) {
          setInfoClienteGrabada((args) => ({
            ...args,
            financiero: true,
          }));
        }

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
        }, 1000);
      } else {
        setLoading(false);
        alert(`${respuestaActiIndependiente.data.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <Formik
      initialValues={{
        rucEmpresa: clienteInfo.rucEmpresa ?? "",
        nombreEmpresa: clienteInfo.nombreEmpresa ?? "",
        profesion: clienteInfo.profesion ?? "",
        fechaInicio: clienteInfo.fechaInicio ?? "",
      }}
      validationSchema={Yup.object({
        rucEmpresa: Yup.string().required("RUC requerido"),
        nombreEmpresa: Yup.string().required("Nombre Empresa requerido"),
      })}
      onSubmit={(values) => {
        configuracionDatosEnvio(values);
      }}
    >
      {({ values, handleChange, touched, errors, handleBlur }) => (
        <Form>
          <SCSAccordion tituloCabecera="Actividad Independiente">
            <div style={{ marginRight: 80 }}>
              <h3>Detalle Empresa</h3>
            </div>

            <div className="cliente-informacion-seccion">
              <TextField
                id="rucEmpresa"
                name="rucEmpresa"
                type="text"
                label="RUC"
                sx={StyleInputs(15, 45)}
                //sx={{ width: "15%" }}
                value={values.rucEmpresa}
                onChange={handleChange}
                size="small"
                inputProps={{ min: 0 }}
                error={touched.rucEmpresa && Boolean(errors.rucEmpresa)}
                helperText={touched.rucEmpresa && errors.rucEmpresa}
                onBlur={handleBlur}
              />

              <TextField
                id="nombreEmpresa"
                name="nombreEmpresa"
                type="text"
                label="Nombre Empresa"
                value={values.nombreEmpresa}
                onChange={handleChange}
                sx={StyleInputs(22, 45)}
                //sx={{ width: "22%" }}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={touched.nombreEmpresa && Boolean(errors.nombreEmpresa)}
                helperText={touched.nombreEmpresa && errors.nombreEmpresa}
                onBlur={handleBlur}
              />

              <TextField
                id="profesion"
                name="profesion"
                type="text"
                label="Profesión"
                sx={StyleInputs(20, 45)}
                //sx={{ width: "20%" }}
                value={values.profesion}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={touched.profesion && Boolean(errors.profesion)}
                helperText={touched.profesion && errors.profesion}
                onBlur={handleBlur}
              />

              <TextField
                id="fechaInicio"
                label="Fecha de Inicio"
                type="date"
                size="small"
                sx={StyleInputs(18, 45)}
                //sx={{ width: "18%" }}
                onChange={handleChange}
                value={formatoFecha(values.fechaInicio)}
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 0 }}
                error={touched.fechaInicio && Boolean(errors.fechaInicio)}
                helperText={touched.fechaInicio && errors.fechaInicio}
                onBlur={handleBlur}
              />
            </div>

            <div className="confirmar-estados-servicios">
              <SCSLoadingButton loading={loading} titulo="GUARDAR" />

              {datosEnviadosCorrectamente && <AcceptServiceresponse />}
            </div>
          </SCSAccordion>
        </Form>
      )}
    </Formik>
  );
};

export default FormClienteActividadIndependiente;
