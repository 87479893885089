
import React from 'react';
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUT_NAMES} from "../../../layouts/layouts";
import DocumentosCrediwebContent from './DocumentosCrediweb/DocumentosCrediwebContent';


const CrediwebDocsPage = () => {
  const {setActiveLayout} = useJumboApp();

  React.useEffect(() => {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
      <div>
        <h2>Gestion de Documentos para flujo Crediweb</h2>
        <DocumentosCrediwebContent/>
      </div>
  );
};

export default CrediwebDocsPage;