import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import SiniestroResolucion from "./SiniestroResolucion/SiniestroResolucion";

const ResolucionSiniestros = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);

  return (
    <>
      <h2 className="titulosPrincipales-container">Siniestro - Resolución</h2>
      <SiniestroResolucion />
    </>
  );
};

export default ResolucionSiniestros;
