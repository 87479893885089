import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

import { FormControl, Grid, OutlinedInput, TextField } from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputLabel from "@mui/material/InputLabel";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";

import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";
import { crearParametro } from "../services/cobranzaServices";
import { MaskAsignacion } from "app/modules/shared/mask/mascarasInputs";

const FormGestionParametros = ({ detallePeriodo }) => {
  const [clienteContacto, setClienteContacto] = useState({});
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const configuraionEnvioDatos = async (valores) => {
    setLoading(true);

    const informacionParametro = {
      idParametro: detallePeriodo.idParametro,
      periodo: detallePeriodo.periodo,

      montoDesde: Number(valores.montoDesde),
      montoHasta: Number(valores.montoHasta),
      scoreDesde: Number(valores.scoreDesde),
      scoreHasta: Number(valores.scoreHasta),
      vencidosDesde: Number(valores.vencidosDesde),
      vencidosHasta: Number(valores.vencidosHasta),

      esActivo: true,

      /*  fechaCreacion: "2023-04-10T00:00:00",
      usuarioCreacion: "procManual",
      estacionCreacion: "1.1.1.1",
      fechaModificacion: null,
      usuarioModificacion: null,
      estacionModificacion: null, */
    };

    console.log(informacionParametro);

    try {
      const respuestaCrearQueja = await crearParametro(informacionParametro);

      console.log(respuestaCrearQueja);

      if (respuestaCrearQueja.state) {
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
        }, 2000);
      } else {
        setLoading(false);
        alert(`${respuestaCrearQueja.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <div className="accordion-container">
      <Formik
        initialValues={{
          montoDesde: detallePeriodo.montoDesde ?? "",
          montoHasta: detallePeriodo.montoHasta ?? "",
          scoreDesde:
            detallePeriodo.scoreDesde >= 0 && detallePeriodo.scoreDesde !== null
              ? detallePeriodo.scoreDesde.toString()
              : "",
          scoreHasta:
            detallePeriodo.scoreHasta >= 0 && detallePeriodo.scoreHasta !== null
              ? detallePeriodo.scoreHasta.toString()
              : "",
          vencidosDesde:
            detallePeriodo.vencidosDesde >= 0 &&
            detallePeriodo.vencidosDesde !== null
              ? detallePeriodo.vencidosDesde.toString()
              : "",
          vencidosHasta:
            detallePeriodo.vencidosHasta >= 0 &&
            detallePeriodo.vencidosHasta !== null
              ? detallePeriodo.vencidosHasta.toString()
              : "",
        }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          montoDesde: Yup.string().required("Monto requerido"),
          montoHasta: Yup.string().required("Monto requerido"),
          scoreDesde: Yup.string().required("Score requerido"),
          scoreHasta: Yup.string().required("Score requerido"),
          vencidosDesde: Yup.string().required("Vencido requerido"),
          vencidosHasta: Yup.string().required("Vencido requerido"),
        })}
        onSubmit={(valores, { resetForm }) => {
          configuraionEnvioDatos(valores);
        }}
      >
        {({ values, handleChange, touched, errors, handleBlur }) => (
          <Form>
            <div className="accordion-container">
              <div>
                <Accordion
                  defaultExpanded="true"
                  sx={{ boxShadow: 3, lineHeight: 0 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className="accordion-summary"
                    sx={{ margin: 0 }}
                  >
                    <h2 className="formulario-cabecera">
                      Parametros del periodo
                    </h2>
                  </AccordionSummary>

                  <AccordionDetails sx={{ marginTop: "2rem" }}>
                    <Grid container spacing={1} rowSpacing={1}>
                      <Grid item md={12}>
                        <Grid container spacing={1}>
                          <Grid item md={12}>
                            <div className="cliente-informacion-seccion">
                              <TextField
                                id="montoDesde"
                                name="montoDesde"
                                label="Monto Desde"
                                sx={{ width: "23%" }}
                                value={values.montoDesde}
                                onChange={handleChange}
                                size="small"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                error={
                                  touched.montoDesde &&
                                  Boolean(errors.montoDesde)
                                }
                                helperText={
                                  touched.montoDesde && errors.montoDesde
                                }
                                onBlur={handleBlur}
                              />

                              <TextField
                                id="montoHasta"
                                name="montoHasta"
                                label="Monto Hasta"
                                sx={{ width: "23%" }}
                                value={values.montoHasta}
                                onChange={handleChange}
                                size="small"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                error={
                                  touched.montoHasta &&
                                  Boolean(errors.montoHasta)
                                }
                                helperText={
                                  touched.montoHasta && errors.montoHasta
                                }
                                onBlur={handleBlur}
                              />

                              <FormControl
                                sx={{ width: "23%" }}
                                size="small"
                                variant="outlined"
                                error={
                                  touched.scoreDesde &&
                                  Boolean(errors.scoreDesde)
                                }
                                helperText={
                                  touched.scoreDesde && errors.scoreDesde
                                }
                                onBlur={handleBlur}
                              >
                                <InputLabel htmlFor="scoreDesde">
                                  Score Desde
                                </InputLabel>
                                <OutlinedInput
                                  value={values.scoreDesde}
                                  onChange={handleChange}
                                  name="scoreDesde"
                                  id="scoreDesde"
                                  inputComponent={MaskAsignacion}
                                  label="Score Desde"
                                />
                                <ErrorMessage
                                  name="scoreDesde"
                                  component={() => (
                                    <FormHelperText>
                                      {errors.scoreDesde}
                                    </FormHelperText>
                                  )}
                                />
                              </FormControl>

                              <FormControl
                                sx={{ width: "23%" }}
                                size="small"
                                variant="outlined"
                                error={
                                  touched.scoreHasta &&
                                  Boolean(errors.scoreHasta)
                                }
                                helperText={
                                  touched.scoreHasta && errors.scoreHasta
                                }
                                onBlur={handleBlur}
                              >
                                <InputLabel htmlFor="scoreHasta">
                                  Score Hasta
                                </InputLabel>
                                <OutlinedInput
                                  value={values.scoreHasta}
                                  onChange={handleChange}
                                  name="scoreHasta"
                                  id="scoreHasta"
                                  inputComponent={MaskAsignacion}
                                  label="Score Hasta"
                                />
                                <ErrorMessage
                                  name="scoreHasta"
                                  component={() => (
                                    <FormHelperText>
                                      {errors.scoreHasta}
                                    </FormHelperText>
                                  )}
                                />
                              </FormControl>

                              <FormControl
                                sx={{ width: "23%" }}
                                size="small"
                                variant="outlined"
                                error={
                                  touched.vencidosDesde &&
                                  Boolean(errors.vencidosDesde)
                                }
                                helperText={
                                  touched.vencidosDesde && errors.vencidosDesde
                                }
                                onBlur={handleBlur}
                              >
                                <InputLabel htmlFor="vencidosDesde">
                                  Vencidos Desde
                                </InputLabel>
                                <OutlinedInput
                                  value={values.vencidosDesde}
                                  onChange={handleChange}
                                  name="vencidosDesde"
                                  id="vencidosDesde"
                                  inputComponent={MaskAsignacion}
                                  label="Vencidos Desde"
                                />
                                <ErrorMessage
                                  name="vencidosDesde"
                                  component={() => (
                                    <FormHelperText>
                                      {errors.vencidosDesde}
                                    </FormHelperText>
                                  )}
                                />
                              </FormControl>

                              <FormControl
                                sx={{ width: "23%" }}
                                size="small"
                                variant="outlined"
                                error={
                                  touched.vencidosHasta &&
                                  Boolean(errors.vencidosHasta)
                                }
                                helperText={
                                  touched.vencidosHasta && errors.vencidosHasta
                                }
                                onBlur={handleBlur}
                              >
                                <InputLabel htmlFor="vencidosHasta">
                                  Vencidos Hasta
                                </InputLabel>
                                <OutlinedInput
                                  value={values.vencidosHasta}
                                  onChange={handleChange}
                                  name="vencidosHasta"
                                  id="vencidosHasta"
                                  inputComponent={MaskAsignacion}
                                  label="Vencidos Hasta"
                                />
                                <ErrorMessage
                                  name="vencidosHasta"
                                  component={() => (
                                    <FormHelperText>
                                      {errors.vencidosHasta}
                                    </FormHelperText>
                                  )}
                                />
                              </FormControl>
                            </div>
                          </Grid>
                        </Grid>
                        <div className="confirmar-estados-servicios">
                          <LoadingButton
                            sx={{ width: "20%" }}
                            type="submit"
                            size="small"
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            /* startIcon={<AttachMoneyIcon />} */
                          >
                            Guardar
                          </LoadingButton>

                          {datosEnviadosCorrectamente && (
                            <AcceptServiceresponse />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormGestionParametros;
