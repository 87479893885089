import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";

import TaskIcon from "@mui/icons-material/Task";
import downloadExcel from "app/utils/document/documentoDescargaExel";
import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";
import { consultarSiniestroDiario } from "../services/ReporteService";
import { formatoFecha } from "app/modules/shared/validations/validaciones";

const BotonConsultaSiniestroReporteDiario = () => {
  const [reporteDiario, setReporteDiario] = useState([]);
  const [loading, setLoading] = useState(false);

  const obtenerReporteSiniestosDiario = async () => {
    setLoading(true);

    try {
      const respuestaSiniestroDiario = await consultarSiniestroDiario();

      console.log(respuestaSiniestroDiario);

      if (respuestaSiniestroDiario.state) {
        const respuestaSiniestroDiarioAux = respuestaSiniestroDiario.data.map(
          (diario, index) => {
            return {
              ...diario,
              fechaIngreso: formatoFecha(diario.fechaIngreso),
              ID: index,
            };
          }
        );

        setReporteDiario(respuestaSiniestroDiarioAux);

        downloadExcel(
          respuestaSiniestroDiario.data,
          `REPDIARIO_${fechasDocumentosSiniestro()}`
        );
        setLoading(false);
      } else {
        setReporteDiario([]);
        setLoading(false);
        alert("Error: No se ha podido gererar el reporte !!");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Error: No se ha podido gererar el reporte !!");
    }
  };

  return (
    <div className="tipoSiniestro-tipos">
      <LoadingButton
        size="large"
        loading={loading}
        variant="contained"
        loadingPosition="end"
        endIcon={<TaskIcon />}
        onClick={obtenerReporteSiniestosDiario}
      >
        Reporte Diario
      </LoadingButton>
    </div>
  );
};

export default BotonConsultaSiniestroReporteDiario;
