import React, { memo, useState, useEffect } from "react";
//import { Routes, Route, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import {
  DataGrid,
  GridActionsCellItem,
  GridRowsProp,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import {
  anexarDocumentoCorreo,
  archivarCorreoBase,
  listarCorreos,
  listarSolicitudesCrediwebSinDoc,
  obtenerCorreos,
  obtenetSolicitudesXAnexar,
} from "../../services/creditoServices";

import "../../styles/main.css";
import FormSubirDocumentos from "../../components/FormSubirDocumentosCrediweb";
import { obtenerDocumentoConfiguracion } from "app/modules/app-configuracion/services/configuracionServices";
import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import { Field } from "formik";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import handleDownlPdf from "app/utils/document/documentosService";
import { useSelector } from "react-redux";

function AnexarPdfContent() {
  const [correos, setCorreos] = useState([]);
  const { authUser } = useSelector(({ auth }) => auth);
  const [solicitudes, setSolicitudes] = useState();
  const [flag, setFlag] = useState(false);
  const [strDoc, setStrDoc] = useState(null);
  const [archivoDescargado, setArchivoDescargado] = useState(false);
  const [idCorreoArchivar, setIdCorreoArchivar] = useState(0);

  const handleSave = async () => {
    setOpenConfirmacion(false);
    setFlag(!flag);
  };

  const history = useHistory();

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    getInfo();
  }, [flag]);

  const getInfo = () => {
    try {
      listarCorreos()
        .then((response) => {
          if (response.state) {
            //console.log(response.data);
            setCorreos(response.data);
          }
        })
        .catch((_errors) => {
          console.log(_errors);
        });
    } catch (e) {
      console.log(e);
    }

    try {
      obtenetSolicitudesXAnexar()
        .then((response) => {
          if (response.state) {
            if (response.data.length === 0) setSolicitudes([]);
            else setSolicitudes(response.data);
          }
        })
        .catch((_errors) => {
          console.log(_errors);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const formatearFecha = (fecha) => {
    if (!fecha) {
      return "";
    }
    const fechaModificada = fecha.split("T");
    return fechaModificada[0] + " / "+fechaModificada[1];
  };

  const obtenerDocumento = async (nombreDocumento) => {
    try {
      var resp = await obtenerDocumentoConfiguracion(
        nombreDocumento,
        "solicitud"
      );
      if (resp.state) {
        //console.log(resp.data);
        handleDownlPdf(resp.data, `${nombreDocumento}`);
        setArchivoDescargado(true);
        setTimeout(() => {
          setArchivoDescargado(false);
        }, 1000);
      } else {
        console.log(resp.message);
      }
      handleClickOpen();
    } catch (e) {
      console.log(e);
    }
  };

  const anexarDoc = async (identificacion, idCorreo) => {
    const solSeleccionada = solicitudes.find(
      (element) => element.identificacion === identificacion
    );
    const idSolicitudSeleccionada = solSeleccionada.idSolicitud;

    const datos = {
      idSolicitud: idSolicitudSeleccionada,
      idCorreo: idCorreo,
      usuario: authUser.key,
      host: authUser.estacion,
    };

    try {
      var resp = await anexarDocumentoCorreo(datos);
      if (resp.state) {
        //console.log(resp.data);
        setOpenConfirmacion(resp.data);
      } else {
        console.log(resp.message);
      }
      //handleClickOpen();
    } catch (e) {
      console.log(e);
    }
  };

  const archivarCorreo = async () =>{

    const datos = {
      idCorreo : idCorreoArchivar,
      usuario : authUser.key,
      host: authUser.estacion
    };
    try
    {
      const resp = await archivarCorreoBase(datos);
      if(resp.state)
        setFlag(!flag);
      else
        console.log(resp.message);
    }
    catch(e)
    {
      console.log(e);
    }
    handleClose();

  };

  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => {
            //console.log(params);
            if (
              solicitudes.some(
                (sol) => sol.identificacion === params.row.idSolicitud
              )
            )
              anexarDoc(params.row.idSolicitud, params.id);
            else alert("No existe una solicitud con esa identificacion");
          }}
        >
          Anexar
        </Button>
      </strong>
    );
  };

  const renderDetailsButtonDescargar = (params) => {
    return (
      <>
        <Grid container spacing={0} justifyContent="center">
          <Grid item xs={12}>
            {params.formattedValue}
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() => {
                obtenerDocumento(params.formattedValue);
              }}
            >
              Ver Documento
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderDetailsButtonArchivar = (params) => {
    return (
      <>
        <Grid container spacing={0} justifyContent="center">
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="error"
              size="small"
              //style={{ marginLeft: 16 }}
              onClick={() => {
                //console.log(params.id);
                setIdCorreoArchivar(params.id)
                setOpenArchivar(true);
              }}
            >
              Archivar
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const columns = [
    {
      field: "idCorreo",
      hide: true,
      headerName: "ID",
      headerAlign: "center",
      width: 80,
      headerClassName: "header-grid-solicitud",
    },
    {
      field: "archivar",
      headerName: "Accion",
      headerAlign: "center",
      width: 110,
      renderCell: renderDetailsButtonArchivar,
      headerClassName: "header-grid-solicitud",
      editable: false,
    },
    {
      field: "de",
      headerName: "De",
      headerAlign: "center",
      headerClassName: "header-grid-solicitud",
      width: 200,
      editable: false,
    },
    {
      field: "asunto",
      headerName: "Asunto",
      headerAlign: "center",
      headerClassName: "header-grid-solicitud",
      width: 160,
      editable: false,
    },
    {
      field: "fechaEnvio",
      headerName: "Fecha Envio",
      headerAlign: "center",
      align: "center",
      headerClassName: "header-grid-solicitud",
      width: 170,
      editable: false,
      valueFormatter: (params) => {
        return formatearFecha(params.value);
      },
    },
    {
      field: "nombreDocumento",
      headerName: "Documento PDF",
      headerAlign: "center",
      align: "center",
      headerClassName: "header-grid-solicitud",
      width: 220,
      editable: false,
      renderCell: renderDetailsButtonDescargar,
      disableClickEventBubbling: false,
    },
    {
      field: "idSolicitud",
      headerName: "Solicitud",
      headerAlign: "center",
      align: "center",
      type: "text",
      headerClassName: "header-grid-solicitud",
      width: 200,
      editable: true,
      // valueOptions: ({ row }) => {
      //   let options = ["Sin Solicitudes"];
      //   options = solicitudes.map(function (i) {
      //     return i.identificacion;
      //   });
      //   return options;
      // },
    },
    {
      field: "accion",
      headerName: "Accion",
      headerAlign: "center",
      headerClassName: "header-grid-solicitud",
      width: 100,
      editable: false,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
    },
  ];

  const rows = correos;

  const [open, setOpen] = useState(false);
  const [openConfirmacion, setOpenConfirmacion] = useState(false);
  const [mostrarEspera, setMostrarEspera] = useState(false);
  const [openArchivar, setOpenArchivar] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpenArchivar(false);
  };

  const handleCloseEspera = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      //console.log(reason);
      setMostrarEspera(true);
    } else {
      setMostrarEspera(false);
    }
  };

  return (
    <div>
      <Button
        className="buscador-botones-centro"
        variant="contained"
        onClick={async () => {
          setMostrarEspera(true);
          try {
            var resp = await obtenerCorreos();
            if (resp.state) {
              setMostrarEspera(!resp.data);
              setFlag(!flag);
            } else {
              console.log(resp.message);
            }
          } catch (e) {
            console.log(e);
          }
        }}
      >
        Obtener Correos
      </Button>
      {archivoDescargado && (
        <Alert severity="success" variant="filled">
          Se ha Descargado el Archivo.
        </Alert>
      )}
      {solicitudes ? (
        <Box sx={{ height: 800, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row.idCorreo}
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            rowHeight={70}
          />
        </Box>
      ) : (
        <SpinnerLoadSrx />
      )}

      <Dialog
        onClose={handleSave}
        aria-labelledby="customized-dialog-title"
        open={openConfirmacion}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleSave}>
          Anexar Documentos
        </DialogTitle>
        <DialogContent dividers>
          <h3>Se ha asociado exitosamente el documento.</h3>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSave}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleCloseEspera}
        aria-labelledby="customized-dialog-title"
        open={mostrarEspera}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleSave}>
          Obteniendo Informacion
        </DialogTitle>
        <DialogContent dividers>
          <h3>
            Por favor espere mientras se obtienen los correos, el proceso puede
            tardar varios segundos.
          </h3>
        </DialogContent>
        <DialogActions> </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openArchivar}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Archivar Correo
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h2">¿Esta seguro de archivar el correo?.</Typography>
          <Typography variant="h4">Esta accion no se puede deshacer.</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" onClick={archivarCorreo}>
            Si
          </Button>
          <Button autoFocus color="error" onClick={handleClose} variant="outlined">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default memo(AnexarPdfContent);
