import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../../layouts/layouts";
import UsuarioFlujo from "./UsuarioFlujo/UsuarioFlujo";
//import LocalAsignarUsuario from "./LocalAsignarUsuario/LocalAsignarUsuario";

const FlujoUsuario = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Flujo - Usuario </h2>
      <UsuarioFlujo />
    </div>
  );
};

export default FlujoUsuario;
