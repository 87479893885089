// import { SEND_FORGET_PASSWORD_EMAIL, UPDATE_AUTH_USER, UPDATE_LOAD_USER } from '../../@jumbo/constants/ActionTypes';

// const INIT_STATE = {
//   authUser: null,
//   loadUser: false,
//   send_forget_password_email: false,
// };

// export default (state = INIT_STATE, action) => {
//   switch (action.type) {
//     case UPDATE_AUTH_USER: {
//       return {
//         ...state,
//         authUser: action.payload,
//         loadUser: true,
//       };
//     }
//     case UPDATE_LOAD_USER: {
//       return {
//         ...state,
//         loadUser: action.payload,
//       };
//     }
//     case SEND_FORGET_PASSWORD_EMAIL: {
//       return {
//         ...state,
//         send_forget_password_email: action.payload,
//       };
//     }
//     default:
//       return state;
//   }
// };
import {
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  UPDATE_CODE_USER,
} from '../../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  initURL: '',
  authUser: null,
  roles: [],
  dtoInit: null,
  code: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload,
        loadUser: true,
        
      };
    }

    case UPDATE_CODE_USER: {
      return {
        ...state,
        code: action.payload,
        loadUser: true,
        loader: false,       
      };
    }

    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload,
      };
    }

    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        roles: action.payload.usuarioRol,
      };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        initURL: '',
        authUser: null,
        roles: [],
        dtoInit: null,
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }

    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    default:
      return state;
  }
};
