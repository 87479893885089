import React from "react";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { obtenerDocumentoConfiguracion } from "app/modules/app-configuracion/services/configuracionServices";
import { useEffect } from "react";
import { useState } from "react";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: 800,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    /* width: "50%", */
  },
  /* "& .MuiDialogActions-root": {
      with: 30,
    }, */
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const VisualizadorDocumentos = ({ open, setOpen, nombreDocumentoAux }) => {
  const [documentoAux, setDocumentoAux] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaDocumento = await obtenerDocumentoConfiguracion(
          nombreDocumentoAux,
          "REQUERIMIENTOS"
        );
        console.log(respuestaDocumento);

        if (respuestaDocumento.state) {
          console.log(nombreDocumentoAux.split(".")[1]);
          if (nombreDocumentoAux.split(".")[1] === "pdf") {
            const stringPDF = `data:application/pdf;base64,${respuestaDocumento.data}`;
            console.log({ stringPDF });
            setDocumentoAux(stringPDF);
          } else {
            const stringPNG = `data:image/png;base64,${respuestaDocumento.data}`;
            console.log({ stringPNG });
            setDocumentoAux(stringPNG);
          }
          /* setDocumentoAux(respuestaDocumento.data); */
          setIsLoading(false);
        } else {
          setIsLoading(false);
          alert("Documento No Encontrado");
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    getData();
  }, [nombreDocumentoAux]);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  return (
    <div>
      <BootstrapDialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <iframe
            id="frame_docs"
            src={documentoAux}
            title="resumeClient"
            /* width="100vw"
            height="15vh" */
          />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default VisualizadorDocumentos;
