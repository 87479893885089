
const inititalState = { name: "", age: 0, usuario:"",jwtsesion:"",menu:"" ,perfil:""};
const addUserReducer = (state = inititalState, action) => {
  switch (action.type) {
    case "CHANGEUSERNAME": {
      return { ...state, name: action.payload };
    }
    case "CHANGEAGE": {
      return { ...state, age: action.payload };
    }
    case "CHANGEUSUARIO": {
      return { ...state, usuario: action.payload };
    }
    case "CHANGEJWTSESION": {
      return { ...state, jwtsesion: action.payload };
    }
    case "CHANGEMENU": {
      return { ...state, menu: action.payload };
    }
    case "CHANGEPERFIL": {
      return { ...state, perfil: action.payload };
    }
  }
  return state;
};
export default addUserReducer;
