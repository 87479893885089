import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";

import { consultarCabeceraSiniestro } from "../Services/siniestrosService";

const HeaderInformacionSiniestro = ({ idCliente, idSiniestro }) => {
  const [siniestro, setSiniestro] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data, state } = await consultarCabeceraSiniestro(idSiniestro);

        if (state) {
          /* console.log(respuestaCabeceraCliente); */
          setSiniestro(data);
          setLoading(!loading);
        } else {
          setSiniestro({});
          setLoading(!loading);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (idCliente > 0) {
      getData();
    }
  }, []);

  if (loading) {
    return <h1>No se encuantran datos del Siniestro</h1>;
  }

  const formatoFecha = (fecha) => {
    const fechaModificada = fecha.split("T");
    return fechaModificada[0];
  };

  return (
    <div className="header-client-container">
      <Card>
        <header className="header-client-information">
          <div>
            <h2>Siniestro</h2>
          </div>
          <div>
            <h4>N° Siniestro</h4>
            <h4>
              {siniestro.numeroSiniestro ? siniestro.numeroSiniestro : ""}
            </h4>
          </div>
          <div>
            <h4>Flujo</h4>
            <h4>{siniestro.flujo}</h4>
          </div>

          <div>
            <h4>Artículo</h4>
            <h4>{siniestro.articulo ? siniestro.articulo : ""}</h4>
          </div>
          <div>
            <h4>Estado</h4>
            <h4>{siniestro.estado ? siniestro.estado : ""}</h4>
          </div>
          <div>
            <h4>Fecha Siniestro</h4>
            <h4>
              {siniestro.fechaSiniestro
                ? formatoFecha(siniestro.fechaSiniestro)
                : ""}
            </h4>
          </div>
          <div>
            <h4>Fecha Notificación</h4>
            <h4>
              {siniestro.fechaNotificacion
                ? formatoFecha(siniestro.fechaNotificacion)
                : ""}
            </h4>
          </div>
        </header>
      </Card>
    </div>
  );
};

export default HeaderInformacionSiniestro;
