import React from "react";
import { Card } from "@mui/material";
import { formatoFecha } from "app/modules/shared/validations/validaciones";
import { width } from "@mui/system";
import {
  obtenerFechaActual,
  obtenerFechaActualFormulario,
} from "app/modules/shared/commonValues/obtenerFecha";

const HeaderReporte = ({ title }) => {
  return (
    <div className="header-reporte-container">
      <Card sx={{ width: 600 }}>
        <header>
          <div className="header-reporte-detalle">
            <h3>{title}</h3>

            <div className="header-reporte-information">
              {/* <div className="header-reporte-fechas">
                <h4>Fecha Proceso: </h4>
                <h4>2023-01-10</h4>
              </div> */}

              <div className="header-reporte-fechas">
                <h4>Fecha Consulta: </h4>
                <h4>{obtenerFechaActualFormulario()}</h4>
              </div>
            </div>
          </div>
        </header>
      </Card>
    </div>
  );
};

export default HeaderReporte;
