import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import contactsApp from "./contactsApp";
import Common from './Common';
import authReducer from './Auth';

//reducer auth
import userReducer from '../../modules/app-auth/redux/reducers/userReducer'

const exportReducers = history => {
    return combineReducers({
        router: connectRouter(history),
        contactsApp: contactsApp,
        common: Common,
        auth: authReducer,
        userReducer: userReducer  // autorizacion
    });
};

export default exportReducers;

