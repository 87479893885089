import React, { useEffect, useState } from "react";

import BuscadorPorIdentificacion from "../../Containers/BuscadorPorIdentificacion";
// import DetalleInformacionUsuario from "../../Containers/DetalleInformacionUsuario";

import { ObtenerUsuarioXidentificacion } from "../../Service/usuarioService";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import DetalleInformacionUsuario from "../../Containers/DetalleInformacionUsuario";

const LocalAsignarUsuario = () => {
  const [usuario, setUsuario] = useState(null);
  const [idUsuario, setIdUsuario] = useState(null);
  const [mostrarDetalle, setMostrarDetalle] = useState(false);
  //   const [keyUsuario, setKeyUsuario] = useState(0);
  const [identificacion, setIdentificacion] = useState(0);

  const nuevoUsuario = {};
  const nuevoIdUsuario = -1;

  useEffect(() => {
    console.log(usuario);
  }, []);

  if (idUsuario < 0) {
    return (
      <>
        <BotonGerericoSalir />
        <h2>No existe información del Usuario!!</h2>
      </>
    );
  }

  const buscarExistenciaUsuario = async (identificador) => {
    console.log(identificador);

    try {
      const respuestaUsuario = await ObtenerUsuarioXidentificacion(
        identificador
      );

      if (respuestaUsuario.data != null) {
        console.log(respuestaUsuario);
        setIdUsuario(respuestaUsuario.data.idUsuario);
        setUsuario(respuestaUsuario.data);
        setMostrarDetalle(false);
      } else {
        setUsuario({ identificacion: identificador });
        setIdUsuario(nuevoIdUsuario);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdUsuario = (identificacion) => () => {
    setIdentificacion(identificacion);
    buscarExistenciaUsuario(identificacion);
  };

  return (
    <div>
      {idUsuario && usuario ? (
        <DetalleInformacionUsuario
          idUsuario={idUsuario}
          usuario={usuario}
          identificacion={identificacion}
        />
      ) : (
        <BuscadorPorIdentificacion traerIdUsuario={traerIdUsuario} />
      )}
    </div>
  );
};

export default LocalAsignarUsuario;
