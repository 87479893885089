import http from "../../../comunication/http-common";

//http://10.10.0.79:5000/comunicacion/ObtenerMensajeXId?idmensaje=2
export const consultaMensajes = async (idmensaje) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}comunicacion/ObtenerMensajeXId?idmensaje=${idmensaje}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5000/Comunicacion/ObtenerMensajeXIdUsuario?CodigoUsuario=1340&esEmisor=true
export const consultarClientesPorUsuario = async (idUsuario, emisor) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}comunicacion/ObtenerMensajeXIdUsuario?CodigoUsuario=${idUsuario}&esEmisor=${emisor}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/cliente/obtenerIdCliente?identificacion=1725786931
export const consultarClientesPorIdentificacion = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CLIENTE}cliente/obtenerIdCliente?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5000/Comunicacion/ObtenerMensajeHistoricoXIdEmisor?codigoEmisor=1340&EsExterno=false
export const consultarComunicacionHistorico = async (idEmisor, externo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}comunicacion/ObtenerMensajeHistoricoXIdEmisor?codigoEmisor=${idEmisor}&EsExterno=${externo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/comunicacion/ObtenerMensajesXIdMensaje?IdMensaje=2
//http://10.10.0.179:5000/Comunicacion/ObtenerMensajesXIdMensaje?IdMensaje=20&esEmisor=true
export const consultarMensajesConversacion = async (IdMensaje, esEmisor) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}comunicacion/ObtenerMensajesXIdMensaje?IdMensaje=${IdMensaje}&esEmisor=${esEmisor}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:4000/identity/ObtenerUsuarioXkey?key=eduardo.puga
export const consultarUsuarioXNombre = async (keyUsuario) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };

    const url = `http://10.10.0.179:4002/identity/ObtenerUsuarioXkey?key=${keyUsuario}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/Comunicacion/EncontrarUsuarioxIdMensaje?IdMensaje=0
export const consultarConversacionesXUsuario = async (IdMensaje) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };

    const url = `${process.env.REACT_APP_API_GESTION}Comunicacion/EncontrarUsuarioxIdMensaje?IdMensaje=${IdMensaje}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/comunicacion/ObtenerCabeceraXIdUsuario?IdUsuario=1340&esExterno=true
export const consultarCabeceraChat = async (idUsuario, esExterno) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };

    const url = `${process.env.REACT_APP_API_GESTION}Comunicacion/ObtenerCabeceraXIdUsuario?IdUsuario=${idUsuario}&esExterno=${esExterno}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/comunicacion/ObtenerPreguntas
export const consultarPreguntasPredeterminadas = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };

    const url = `${process.env.REACT_APP_API_GESTION}Comunicacion/ObtenerPreguntas`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/comunicacion/ObtenerPreguntaFinal
export const consultarPreguntaFinal = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };

    const url = `${process.env.REACT_APP_API_GESTION}Comunicacion/ObtenerPreguntaFinal`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/catalogo/obtenerSP?especial=false&catalogo=TIPOMSJE&grupo=1
export const consultarTipoMensaje = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };

    const url = `${process.env.REACT_APP_API_GESTION}catalogo/obtenerSP?especial=false&catalogo=TIPOMSJE&grupo=1`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://10.10.0.179:5000/catalogo/ObtenerParametro?codigoParametro=rolChat
export const consultarUsuariosChat = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };

    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ObtenerParametro?codigoParametro=rolChat`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://localhost:5016/comunicacion/NotificarCampaniaMasivos
export const notificarCampaniaPush = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}comunicacion/NotificarCampaniaPush`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);
      });
  });
};

//http://10.10.0.79:5000/comunicacion/GuardarGestionMensaje
export const crearGestionMensaje = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}comunicacion/GuardarGestionMensaje`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/comunicacion/GuardarMensaje
export const crearConversacion = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}comunicacion/GuardarMensaje`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://localhost:5016/comunicacion/CargarCampaniaPush
export const cargarCampaniaPush = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}comunicacion/CargarCampaniaPush`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
