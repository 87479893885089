import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const BackdroLlamadaUsuario = ({ isLoadingLlamada }) => {
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingLlamada}
        /*  onClick={handleClose} */
      >
        <div className="pasarela-pagos-loading">
          <CircularProgress
            color="inherit"
            className="pasarela-pagos-loading-spiner"
          />
          <h2 className="pasarela-pagos-loading-mensaje">Llamando....</h2>
        </div>
      </Backdrop>
    </div>
  );
};

export default BackdroLlamadaUsuario;
