import React, { useEffect, useState } from "react";

import ListaSegurosCliente from "app/modules/app-seguro/containers/ListaSegurosCliente";

import FormCancelacionSeguros from "../components/FormCancelacionSeguros";
import CabeceraSeguro from "app/modules/app-seguro/containers/CabeceraSeguro";

function CancelacionSeguros({ idCliente, idLocal }) {
  const [idSeguroCancelar, setIdSeguroCancelar] = useState(null);

  useEffect(() => {}, [idSeguroCancelar]);

  return (
    <div className="accordion-container">
      <ListaSegurosCliente
        idCliente={idCliente}
        setIdContratoAux={setIdSeguroCancelar}
      />

      {idSeguroCancelar && (
        <>
          <CabeceraSeguro seguro={idSeguroCancelar} />
          <FormCancelacionSeguros
            idCliente={idCliente}
            contrato={idSeguroCancelar}
            idLocal={idLocal}
          />
        </>
      )}
    </div>
  );
}

export default CancelacionSeguros;
