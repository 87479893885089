import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { consultaContacto } from "app/modules/app-cliente/services/clienteService";
import { Button } from "@mui/material";
import IconoComprobacionCambioEstado from "../components/IconoComprobacionCambioEstado";
import {
  consultarCambioEstadoSISCRE,
  crearCambioEstadoRequerimiento,
  crearCertificadoNotificacion,
} from "../services/solicitudesService";
import ModalAlertDesestimar from "../components/ModalAlertDesestimar";
import FormDesestimarRequerimiento from "../components/FormDesestimarRequerimiento";
import ModalAlertaNotificacionEstandar from "../components/ModalAlertaNotificacionEstandar";
import { LoadingButton } from "@mui/lab";

const DetalleCambioEstado = ({ solicitud }) => {
  const [datosComprobacion, setdatosComprobacion] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openDesestimar, setOpenDesestimar] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getDatosCompracion = async () => {
      try {
        const respuestaCompracion = await consultarCambioEstadoSISCRE(
          solicitud.idRequerimiento
        );
        if (respuestaCompracion.state) {
          setdatosComprobacion(respuestaCompracion.data);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    getDatosCompracion();
  }, []);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const procesarDesestimar = () => {
    setOpenDesestimar(true);
  };

  const procesarCambioEstado = async () => {
    setLoading(true);

    try {
      const respuestaCambioEstado = await crearCambioEstadoRequerimiento(
        solicitud.idRequerimiento,
        "ESTREQRES"
      );

      if (respuestaCambioEstado.state) {
        setOpen(true);
        setLoading(false);
      } else {
        alert(`${respuestaCambioEstado.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      alert(`${error.response.data.message}`);
      setLoading(false);
    }
  };

  const stringMensaje = `Datos de la solicitud 
  | Nombre: ${solicitud.nombre} 
  | Identificación: ${solicitud.identificacion} 
  | Email: ${solicitud.email}
  | Fecha: ${solicitud.fechaSolicitud}`;

  return (
    <div className="cambioEstado-container">
      <div className="cambioEstado-informacion-tabla">
        <TableContainer>
          <Table
            size="small" /*  aria-label="simple table" */
            /*  sx={{ maxWidth: 400, padding: 5 }} */
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Fuente</TableCell>
                <TableCell align="center">Fecha Expedición</TableCell>
                <TableCell align="center">Codigo Dactilar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Requerimiento
                </TableCell>
                <TableCell>
                  {datosComprobacion?.fechaExpRequerimiento}
                </TableCell>
                <TableCell align="center">
                  {datosComprobacion?.dactilarRequerimiento}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Cliente
                </TableCell>
                <TableCell>{datosComprobacion?.fechaExpCliente}</TableCell>
                <TableCell align="center">
                  {datosComprobacion?.dactilarCliente}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <IconoComprobacionCambioEstado
        estado={
          datosComprobacion.validaDactilar && datosComprobacion.validaFechaExp
        }
      />

      <section className="cambioEstado-seccion-botones">
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => procesarDesestimar()}
        >
          Desestimar
        </Button>

        {/*   <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => procesarCambioEstado()}
        >
          Cambio Estado
        </Button> */}

        <LoadingButton
          sx={{ width: "25ch" }}
          size="small"
          loading={loading}
          loadingPosition="end"
          variant="contained"
          onClick={() => procesarCambioEstado()}
        >
          Cambio Estado
        </LoadingButton>
      </section>

      <ModalAlertDesestimar
        setOpen={setOpenDesestimar}
        open={openDesestimar}
        titulo="Solicitud Cambio Estado"
        mensaje={stringMensaje}
        solicitud={solicitud}
      >
        <FormDesestimarRequerimiento solicitud={solicitud} />
      </ModalAlertDesestimar>

      <ModalAlertaNotificacionEstandar
        setOpen={setOpen}
        open={open}
        titulo="Solicitud Cambio Estado"
        mensaje="La solicitud ha cambiado a estado resuelta."
      />
    </div>
  );
};

export default DetalleCambioEstado;
