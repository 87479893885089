import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  Alert,
  Button,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { obtenerFechaActualFormulario } from "app/modules/shared/commonValues/obtenerFecha";
import {
  formatoFecha,
  validarCelular,
} from "app/modules/shared/validations/validaciones";
import { MaskCelulares } from "app/modules/shared/mask/mascarasInputs";
import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";
import {
  crearDatosRequerimiento,
  crearRequerimientoSolicitud,
  consultarDocumentosSoporte,
} from "../services/solicitudesService";
import { LoadingButton } from "@mui/lab";

const FormDevolucionProductos = ({
  idCliente,
  setIdRequerimiento,
  idLocal,
}) => {
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [loading, setLoading] = React.useState(false);

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsSubTipo = ({
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [subtipos, setSubtipos] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const respuestaEspecialidad = await consultarDocumentosSoporte(
            3,
            false
          );
          /*  console.log(respuestaEspecialidad); */

          if (respuestaEspecialidad.state) {
            setSubtipos(respuestaEspecialidad.data);
            /* console.log(eespuestaEspecialdidad); */
          } else {
            setSubtipos([]);
          }
        } catch (error) {
          setSubtipos([]);
          console.log(error);
        }
      };
      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {subtipos.map((subtipo) => (
            <MenuItem
              key={subtipo.idTipoRequerimiento}
              value={subtipo.idTipoRequerimiento}
            >
              {subtipo.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const validacionTipoDevolucion = (tipoDevolucion) => {
    /* console.log(tipoDevolucion); */

    if (tipoDevolucion === "9") {
      return true;
    } else {
      return false;
    }
  };

  const configuraionEnvioDatos = async (valoresAux) => {
    setLoading(true);

    const valores = {
      ...valoresAux,
    };

    if (Number(valoresAux.idSubTipoRequerimento) === 9) {
      valores.detalle = "";
    }

    const informacionCancelacionSeguro = {
      idRequerimiento: 0,
      idCliente: Number(idCliente),
      celular: valores.celular,
      email: valores.email,
      idTipoRequerimiento: 3,
      idSubTipoRequerimento: valores.idSubTipoRequerimento,
      estado: "ESTREQSOL",

      idLocal: idLocal,
      fechaSolicitud: obtenerFechaActualFormulario(),
      fechaResuelto: "",
      esActivo: true,
    };

    console.log(informacionCancelacionSeguro);

    let idRequeriminetoAux;

    try {
      const respuestaSolicitud = await crearRequerimientoSolicitud(
        informacionCancelacionSeguro
      );

      console.log(respuestaSolicitud);

      if (respuestaSolicitud.state) {
        setDatosEnviadosCorrectamente(true);
        setIdRequerimiento(respuestaSolicitud.data);
        idRequeriminetoAux = respuestaSolicitud.data;

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
        }, 3000);
      } else {
        setLoading(false);
        alert(`${respuestaSolicitud.message}`);
      }
    } catch (error) {
      setLoading(false);
      alert(`${error.response.data.message}`);
      console.log(error);
    }

    const informacionDatosRequerimiento = {
      idDatosRequerimiento: 0,
      idRequerimiento: Number(idRequeriminetoAux),

      fecha: valores.fecha,
      valor: Number(valores.valor) ?? 0,
      factura: Number(valores.factura) ?? 0,

      operacion: Number(valores.operacion) ?? 0,
      plazo: Number(valores.plazo) ?? 0,
      detalle: valores.detalle.toUpperCase(),
      referencia: "",
      esActivo: true,
    };

    console.log(informacionDatosRequerimiento);

    try {
      const respuestaDatosRequeridos = await crearDatosRequerimiento(
        informacionDatosRequerimiento
      );

      console.log(respuestaDatosRequeridos);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="accordion-container">
        <div>
          <Formik
            initialValues={{
              celular: "",
              email: "",
              idSubTipoRequerimento: "",
              /* cancelacion total */
              fecha: "",
              valor: "",
              factura: "",

              operacion: "",
              plazo: "",
              detalle: "",
            }}
            validate={(values) => {
              const errors = {};

              if (validarCelular(values.celular)) {
                errors.celular = "Primer digito debería ser '09'";
              }

              return errors;
            }}
            validationSchema={Yup.object({
              celular: Yup.string()
                .max(10, "Debería tener 10 números")
                .min(10, "Debería tener 10 números")
                .required("Celular requerido"),
              email: Yup.string()
                .email("Email no tiene formato")
                .required("Email requerido"),
              idSubTipoRequerimento: Yup.string().required(
                "Tipo Requerimiento requerido"
              ),
              fecha: Yup.string().required("Fecha De Compra requerido"),
              factura: Yup.string().required("Valor Factura requerido"),
            })}
            onSubmit={(valores, { resetForm }) => {
              configuraionEnvioDatos(valores);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <div className="accordion-container">
                  <div>
                    <Accordion
                      defaultExpanded="true"
                      sx={{ boxShadow: 3, lineHeight: 0 }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="accordion-summary"
                        sx={{ margin: 0 }}
                      >
                        <h2 className="formulario-cabecera">
                          Devolución de Productos
                        </h2>
                      </AccordionSummary>
                      <AccordionDetails sx={{ marginTop: "2rem" }}>
                        <Grid container spacing={1} rowSpacing={1}>
                          <Grid item md={12}>
                            <Grid container spacing={1}>
                              <Grid item md={12}>
                                <div className="cliente-informacion-seccion">
                                  <FormControl
                                    sx={{ width: "15%" }}
                                    size="small"
                                    variant="outlined"
                                    error={
                                      touched.celular && Boolean(errors.celular)
                                    }
                                    helperText={
                                      touched.celular && errors.celular
                                    }
                                    onBlur={handleBlur}
                                  >
                                    <InputLabel htmlFor="celular">
                                      Celular
                                    </InputLabel>
                                    <OutlinedInput
                                      value={values.celular}
                                      onChange={handleChange}
                                      name="celular"
                                      id="celular"
                                      inputComponent={MaskCelulares}
                                      label="celular"
                                    />
                                    <ErrorMessage
                                      name="celular"
                                      component={() => (
                                        <FormHelperText>
                                          {errors.celular}
                                        </FormHelperText>
                                      )}
                                    />
                                  </FormControl>

                                  <TextField
                                    id="email"
                                    name="email"
                                    type="text"
                                    label="Email"
                                    sx={{ width: "33%" }}
                                    value={values.email}
                                    onChange={handleChange}
                                    size="small"
                                    error={
                                      touched.email && Boolean(errors.email)
                                    }
                                    helperText={touched.email && errors.email}
                                    onBlur={handleBlur}
                                  />

                                  <SelectItemsSubTipo
                                    name="idSubTipoRequerimento"
                                    labelName="Tipo de Devolución"
                                    componentSize="32%"
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </div>

                                {/* para tipo de devolución total */}
                                {validacionTipoDevolucion(
                                  values.idSubTipoRequerimento
                                ) ? (
                                  <div className="cliente-informacion-seccion">
                                    <TextField
                                      id="fecha"
                                      name="fecha"
                                      type="date"
                                      label="Fecha Compra"
                                      sx={{ width: "16%" }}
                                      onChange={handleChange}
                                      size="small"
                                      value={formatoFecha(values.fecha)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={
                                        touched.fecha && Boolean(errors.fecha)
                                      }
                                      helperText={touched.fecha && errors.fecha}
                                      onBlur={handleBlur}
                                    />

                                    <TextField
                                      id="factura"
                                      name="factura"
                                      label="Valor Factura"
                                      sx={{ width: "23%" }}
                                      value={values.factura}
                                      onChange={handleChange}
                                      size="small"
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                      error={
                                        touched.factura &&
                                        Boolean(errors.factura)
                                      }
                                      helperText={
                                        touched.factura && errors.factura
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                ) : (
                                  <div className="cliente-informacion-seccion">
                                    <TextField
                                      id="fecha"
                                      name="fecha"
                                      type="date"
                                      label="Fecha Compra"
                                      sx={{ width: "16%" }}
                                      onChange={handleChange}
                                      size="small"
                                      value={formatoFecha(values.fecha)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={
                                        touched.fecha && Boolean(errors.fecha)
                                      }
                                      helperText={touched.fecha && errors.fecha}
                                      onBlur={handleBlur}
                                    />

                                    <TextField
                                      id="factura"
                                      name="factura"
                                      label="Valor Factura"
                                      sx={{ width: "23%" }}
                                      value={values.factura}
                                      onChange={handleChange}
                                      size="small"
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                      error={
                                        touched.factura &&
                                        Boolean(errors.factura)
                                      }
                                      helperText={
                                        touched.factura && errors.factura
                                      }
                                      onBlur={handleBlur}
                                    />

                                    <TextField
                                      id="detalle"
                                      name="detalle"
                                      multiline
                                      type="text"
                                      label="Detalle"
                                      rows={4}
                                      sx={{ width: "97%" }}
                                      value={values.detalle}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                      error={
                                        touched.detalle &&
                                        Boolean(errors.detalle)
                                      }
                                      helperText={
                                        touched.detalle && errors.detalle
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                )}

                                {datosEnviadosCorrectamente && (
                                  <Alert severity="success" variant="filled">
                                    Los datos han sido enviados correctamente.
                                  </Alert>
                                )}

                                <div className="cliente-informacion-seccion">
                                  <LoadingButton
                                    sx={{ width: "25ch" }}
                                    type="submit"
                                    size="small"
                                    loading={loading}
                                    loadingPosition="end"
                                    variant="contained"
                                  >
                                    Enviar
                                  </LoadingButton>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default FormDevolucionProductos;
