import React, { useEffect, useState, useRef } from "react";
import { Camera } from "react-camera-pro";
import { useSelector } from "react-redux";

import {
  Button,
  Box,
  Stack,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

import { obtenerCabeceraCliente } from "../services/creditoServices";

import "../styles/main.css";
import {
  grabarArchivo,
} from "app/modules/app-cliente/services/clienteService";
import { LoadingButton } from "@mui/lab";
import { green, red } from "@mui/material/colors";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";

const ImageCapture = ({ tipo, idCli, cliente, identifCliente, setInfoRegistrada}) => {
  const { authUser } = useSelector(({ auth }) => auth);

  const [clienteInfo, setClienteInfo] = useState(null);
  useState(false);
  const [alertaGuardar, setAlertaGuardar] = useState(false);
  const [fotoGrabada, setFotoGrabada] = useState(false);
  const [errorGrabar, setErrorGrabar] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [cedulaActiva, setCcedulaActiva] = useState(false);
  const [cedulaAnverso, setCedulaAnverso] = useState({
    load: false,
    content: null,
  });
  const [cedulaReverso, setCedulaReverso] = useState({
    load: false,
    content: null,
  });

  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  useEffect(() => {
    getInformacionCliente(idCli);
  }, []);

  const takePicture = async () => {
    setImage(camera.current.takePhoto());
  };

  const cleanPicture = async () => {
    setImage(null);
    //console.log(image);
  };

  const takeAnverso = async () => {
    setCedulaAnverso((args) => ({
      ...args,
      content: camera.current.takePhoto(),
    }));
  };

  const cleanAnverso = async () => {
    setCedulaAnverso((args) => ({
      ...args,
      load: false,
      content: null,
    }));
  };

  const takeReverso = async () => {
    setCedulaReverso((args) => ({
      ...args,
      content: camera.current.takePhoto(),
    }));
  };

  const cleanReverso = async () => {
    setCedulaReverso((args) => ({
      ...args,
      load: false,
      content: null,
    }));
  };

  const handleNext = async () => {
    setCcedulaActiva(true);
  };

  const savePicture = async () => {
    setFotoGrabada(false);
    setLoading(true);
    console.log(
      "DATOS",
      idCli,
      clienteInfo.nombreCliente,
      clienteInfo.celular,
      authUser.key,
      authUser.estacion
    );

    const fotobase64 = image.split(",")[1];
    const cedulaAnvBase64 = cedulaAnverso.content.split(",")[1];
    const cedulaRevBase64 = cedulaReverso.content.split(",")[1];

    // console.log(fotobase64);
    // console.log(cedulaAnvBase64);
    // console.log(cedulaRevBase64);

    var cedula = await unirFotografiaCedula(cedulaAnvBase64, cedulaRevBase64);

    //console.log(cedula);

    const archivo = {
      idCliente: idCli,
      foto: fotobase64,
      cedula: cedula.split(",")[1],
      usuarioCreacion: authUser.key,
      estacionCreacion: authUser.estacion,
      usuarioModificacion: authUser.key,
      estacionModificacion: authUser.estacion,
    };
    console.log(archivo);
    try {
      const srvGrabarArchivo = await grabarArchivo(archivo);
      if (srvGrabarArchivo.state) {
        //console.log(srvGrabarArchivo.data);
        if (setInfoRegistrada) {
          setInfoRegistrada((args) => ({
            ...args,
            imagenes: true,
          }));
        }
        

        setTimeout(() => {
          setLoading(false);
          setFotoGrabada(true);
        }, 3000);
      } else {
        setLoading(false);
        setErrorGrabar(true);
        console.log(srvGrabarArchivo.message);
      }
    } catch (error) {
      setLoading(false);
      setErrorGrabar(true);
      console.log(error);
    }
  };

  const unirFotografiaCedula = (anversoBase64, reversoBase64) => {
    return new Promise((resolve, reject) => {
      const anverso = new Image();
      anverso.src = `data:image/jpeg;base64, ${anversoBase64}`;

      const reverso = new Image();
      reverso.src = `data:image/jpeg;base64, ${reversoBase64}`;

      anverso.onload = () => {
        reverso.onload = () => {
          const anchoMaximo = Math.max(anverso.width, reverso.width);
          const alturaTotal = anverso.height + reverso.height;

          // Crear el lienzo de destino
          const lienzo = document.createElement("canvas");
          lienzo.width = anchoMaximo;
          lienzo.height = alturaTotal;
          const ctx = lienzo.getContext("2d");

          // Copiar la primera imagen en el lienzo de destino
          ctx.drawImage(anverso, 0, 0);

          // Copiar la segunda imagen en el lienzo de destino
          ctx.drawImage(reverso, 0, anverso.height);

          // Obtener la imagen unida en base64
          const cedulaUnidaString = lienzo.toDataURL("image/jpeg");

          // Imprimir el resultado (imagen unida en base64)
          resolve(cedulaUnidaString);
        };
      };

      // Manejar errores de carga de imágenes
      anverso.onerror = () => reject("Error al cargar anverso.");
      reverso.onerror = () => reject("Error al cargar reverso.");
    });
  };

  const getInformacionCliente = async (idCliente) => {
    await obtenerCabeceraCliente(idCliente)
      .then((response) => {
        console.log(response);
        if (response.state) {
          setClienteInfo(response.data);
        } else console.log(response.message);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (idCli < 0) {
    return <h2>Debe crear primero un cliente !!</h2>;
  }
  return (
    <>
      <SCSAccordion tituloCabecera="Fotografia">
      <TableContainer component={Box}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              {!cedulaActiva ? (
                <>
                  <TableRow>
                    <TableCell>
                      <h2>Fotografia de la cara.</h2>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <>
                      {!image ? (
                        <>
                          <Stack spacing={3}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              columnSpacing={3}
                            >
                              <Grid item md={6}>
                                <Camera
                                  ref={camera}
                                  aspectRatio={1 / 1}
                                  facingMode="environment"
                                />
                              </Grid>
                              <Grid item md={2}>
                                <Stack
                                  justifyContent="center"
                                  direction="column"
                                  spacing={2}
                                >
                                  <Button
                                    variant="contained"
                                    onClick={takePicture}
                                  >
                                    <CameraAltIcon />
                                    TOMAR FOTO
                                  </Button>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Stack>
                        </>
                      ) : (
                        <Stack spacing={3}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            columnSpacing={3}
                          >
                            <Grid item md={6}>
                              <Box
                                component="img"
                                sx={{
                                  height: 1 / 1.09,
                                  width: 1 / 1.09,
                                }}
                                src={image}
                              />
                            </Grid>
                            <Grid item md={2}>
                              <Stack
                                justifyContent="center"
                                direction="column"
                                spacing={2}
                              >
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={cleanPicture}
                                >
                                  <CleaningServicesIcon />
                                  RETOMAR FOTO
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={handleNext}
                                >
                                  <NavigateNextIcon />
                                  SIGUIENTE
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Stack>
                      )}
                    </>
                  </TableRow>
                </>
              ) : !cedulaAnverso.load ? (
                <>
                  <TableRow>
                    <TableCell>
                      <h2>Fotografia de la cédula ANVERSO.</h2>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <>
                      {!cedulaAnverso.content ? (
                        <>
                          <Stack spacing={3}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              columnSpacing={3}
                            >
                              <Grid item md={6}>
                                <Camera
                                  ref={camera}
                                  aspectRatio={1 / 1}
                                  facingMode="environment"
                                />
                              </Grid>
                              <Grid item md={2}>
                                <Stack
                                  justifyContent="center"
                                  direction="column"
                                  spacing={2}
                                >
                                  <Button
                                    variant="contained"
                                    onClick={takeAnverso}
                                  >
                                    <CameraAltIcon />
                                    TOMAR FOTO
                                  </Button>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Stack>
                        </>
                      ) : (
                        <Stack spacing={3}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            columnSpacing={3}
                          >
                            <Grid item md={6}>
                              <Box
                                component="img"
                                sx={{
                                  height: 1 / 1.09,
                                  width: 1 / 1.09,
                                }}
                                src={cedulaAnverso.content}
                              />
                            </Grid>
                            <Grid item md={2}>
                              <Stack
                                justifyContent="center"
                                direction="column"
                                spacing={2}
                              >
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={cleanAnverso}
                                >
                                  <CleaningServicesIcon />
                                  RETOMAR FOTO
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setCedulaAnverso((args) => ({
                                      ...args,
                                      load: true,
                                    }));
                                  }}
                                >
                                  <NavigateNextIcon />
                                  SIGUIENTE
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Stack>
                      )}
                    </>
                  </TableRow>
                </>
              ) : (
                <>
                  <TableRow>
                    <TableCell>
                      <h2>Fotografia de la cédula REVERSO.</h2>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <>
                      {!cedulaReverso.content ? (
                        <>
                          <Stack spacing={3}>
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center"
                              columnSpacing={3}
                            >
                              <Grid item md={6}>
                                <Camera
                                  ref={camera}
                                  aspectRatio={1 / 1}
                                  facingMode="environment"
                                />
                              </Grid>
                              <Grid item md={2}>
                                <Stack
                                  justifyContent="center"
                                  direction="column"
                                  spacing={2}
                                >
                                  <Button
                                    variant="contained"
                                    onClick={takeReverso}
                                  >
                                    <CameraAltIcon />
                                    TOMAR FOTO
                                  </Button>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Stack>
                        </>
                      ) : (
                        <Stack spacing={3}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            columnSpacing={3}
                          >
                            <Grid item md={6}>
                              <Box
                                component="img"
                                sx={{
                                  height: 1 / 1.09,
                                  width: 1 / 1.09,
                                }}
                                src={cedulaReverso.content}
                              />
                            </Grid>
                            <Grid item md={3}>
                              <Stack
                                justifyContent="center"
                                direction="column"
                                spacing={3}
                              >
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={cleanReverso}
                                >
                                  <CleaningServicesIcon />
                                  RETOMAR FOTO
                                </Button>
                                <LoadingButton
                                  size="small"
                                  loading={loading}
                                  loadingPosition="end"
                                  variant="contained"
                                  onClick={savePicture}
                                >
                                  <SaveIcon />
                                  Guardar Imagenes
                                </LoadingButton>
                                {fotoGrabada && (
                                  <Stack justifyContent="center"
                                  direction="row"
                                  alignItems="center"
                                  spacing={3}>
                                    <AcceptServiceresponse />
                                    <Typography align="center"
                                    color={green[600]}>
                                      Imagenes Grabadas
                                    </Typography>
                                  </Stack>
                                )}
                                {errorGrabar && (
                                  <Stack justifyContent="center"
                                  direction="row"
                                  alignItems="center"
                                  spacing={3}>
                                    <Typography align="center"
                                    color={red[600]}>
                                      Error al grabar Imagenes Reintente
                                    </Typography>
                                  </Stack>
                                )}
                              </Stack>
                            </Grid>
                          </Grid>
                        </Stack>
                      )}
                    </>
                  </TableRow>
                </>
              )}
            </Table>
          </TableContainer>
      </SCSAccordion>
    </>
  );
};

export default ImageCapture;
