import React, { useEffect, useState } from "react";

import DataGrid, {
  Column,
  Export,
  Selection,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";

import { consultarQuejasXFechas } from "../services/gestionService";
import { formatoFecha } from "../../shared/validations/validaciones";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";

const DetalleReporteQueja = ({ datosBusqueda }) => {
  const [employees, setEmployees] = useState([]);
  const [quejas, setQuejas] = useState([]);

  useEffect(() => {
    /* console.log(datosBusqueda); */

    const getData = async () => {
      try {
        const respuestaQuejasXFecha = await consultarQuejasXFechas(
          datosBusqueda.fechaInicio,
          datosBusqueda.fechaFin
        );

        /* console.log({ respuestaQuejasXFecha }); */

        if (respuestaQuejasXFecha.state) {
          const respuestaAux = respuestaQuejasXFecha.data.map(
            (queja, index) => {
              return {
                ...queja,
                fechaCierre: formatoFecha(queja.fechaCierre),
                fechaIngreso: formatoFecha(queja.fechaIngreso),
                fechaEsperada: formatoFecha(queja.fechaEsperada),
                fechagestion: formatoFecha(queja.fechagestion),
                ID: index,
              };
            }
          );

          setQuejas(respuestaAux);
          /*  console.log(respuestaQuejasXFecha);
          console.log(respuestaAux); */
        } else {
          setQuejas([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Main sheet");
    const fechaActual = new Date();
    const detalleFecha = fechaActual.toISOString().split("T")[0];

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `ReporteQuejas_${detalleFecha}.xlsx`
        );
      });
    });
    e.cancel = true;
  };

  return (
    <div>
      <BotonGerericoSalir />

      <DataGrid
        id="gridContainer"
        dataSource={quejas}
        keyExpr="ID"
        showBorders="true"
        onExporting={onExporting}
      >
        <Selection />
        {/*  <GroupPanel visible="true" />
        <Grouping autoExpandAll="true" /> */}

        <Column dataField="fechaIngreso" width={100} />
        <Column dataField="identificacionTitular" width={120} />
        <Column dataField="nombreTitular" width={200} />
        <Column dataField="telefono" width={100} />
        <Column dataField="nombreReporta" width={130} />
        <Column dataField="canalIngreso" width={180} />
        <Column dataField="tipoQueja" width={180} />
        <Column dataField="producto" width={220} />
        <Column dataField="estado" width={150} />
        <Column dataField="prestador" width={150} />
        <Column dataField="detalleQueja" width={220} />
        <Column dataField="fechagestion" width={100} />
        <Column dataField="asignacionResponsable" width={150} />
        <Column dataField="accionGestion" width={220} />
        <Column dataField="responsableGestion" width={120} />
        <Column dataField="solucion" width={180} />
        <Column dataField="fechaEsperada" width={180} />
        <Column dataField="estadoCierre" width={100} />
        <Column dataField="fechaCierre" width={100} />
        <Column dataField="tiempoEsperado" width={70} />
        <Column dataField="cumplimiento" width={70} />
        <Column dataField="diasLaborables" width={70} />
        <Column dataField="ciudad" width={100} />
        <Column dataField="email" width={220} />

        <Export enabled="true" allowExportSelectedData="true" />
      </DataGrid>
    </div>
  );
};

export default DetalleReporteQueja;
