import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { consultaListaPermisos } from "../services/cobranzaServices";
import { formatoFecha } from "app/modules/shared/validations/validaciones";

const ListaPermisosUsuario = ({ usuario, nuevoUsuario }) => {
  const [listaPermisos, setListaPermisos] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(nuevoUsuario);
    const getPermisos = async () => {
      try {
        const respuestaListapermisos = await consultaListaPermisos(
          usuario.idUsuario
        );

        console.log(respuestaListapermisos);

        if (respuestaListapermisos.state && respuestaListapermisos.data) {
          setListaPermisos(respuestaListapermisos.data);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getPermisos();
  }, [nuevoUsuario]);

  if (loading) {
    return <h2>Cargando!!</h2>;
  }

  return (
    <div className="gestionCobranza-permisos-listaPermisos">
      <h3>Lista de permisos </h3>

      <table className="gestionCobranza-permisos-tabla">
        <thead>
          <tr>
            <th>N°</th>
            <th>Fecha Inicio</th>
            <th>Fecha Fin</th>
          </tr>
        </thead>
        <tbody>
          {listaPermisos.map((permiso, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{formatoFecha(permiso?.fechaInicio)}</td>
              <td>{formatoFecha(permiso?.fechaFinal)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaPermisosUsuario;
