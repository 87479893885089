import React, { useContext, useEffect } from "react";
import ChatSideContext from "../context/ChatSideContext";
import DetalleSeccionConversacionesCall from "./DetalleSeccionConversacionesCall";
import DetalleSeccionMensajeriaCall from "./DetalleSeccionMensajeriaCall";

const DetalleChatSideCallContainer = () => {
  const { informacionChatSide, setInformacionChatSide } =
    useContext(ChatSideContext);

  useEffect(() => {
    if (informacionChatSide?.connectionHub) {
      informacionChatSide?.connectionHub
        .start()
        .then((result) => {
          console.log("Connected!");
        })
        .catch((e) => console.log("connectionHub failed: ", e));
    }
  }, [informacionChatSide?.connectionHub]);
  return (
    <>
      {informacionChatSide.changeSection ? (
        <DetalleSeccionMensajeriaCall />
      ) : (
        <DetalleSeccionConversacionesCall />
      )}
    </>
  );
};

export default DetalleChatSideCallContainer;
