import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import { FormControl, Grid, MenuItem, TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";

import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import ModalGestionCobranza from "../containers/ModalGestionCobranza";

import {
  formatoFecha,
  validarFechaMayor,
} from "app/modules/shared/validations/validaciones";

import {
  consultaEstadoCobranza,
  consultaSubestadoCobranza,
  crearCobranza,
} from "../services/cobranzaServices";

const FormGestionCobranza = ({ infoCobranza }) => {
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [openQueja, setOpenQueja] = useState(false);

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsEstadosCobranza = ({
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [estados, setEstados] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaEstadoCobranza();

          if (state) {
            setEstados(data);
          } else {
            setEstados([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {estados.map((estado, index) => (
            <MenuItem key={index} value={estado.codigo}>
              {estado.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsSubestadosCobranza = ({
    estado,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [estados, setEstados] = useState([]);

    const estadoAux = estado !== "" ? estado : 0;

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaSubestadoCobranza(estadoAux);

          if (state) {
            setEstados(data);
            /* setSubestadosBanderas(data); */
          } else {
            setEstados([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      if (estado > 0 && typeof estado !== "string") {
        getData();
      }
    }, [estado]);

    return (
      <>
        <FormControl
          sx={{ width: `${componentSize}` }}
          error={touched[name] && Boolean(errors[name])}
          helperText={touched[name] && errors[name]}
          onBlur={handleBlur}
        >
          <InputLabel id={name}>{labelName}</InputLabel>

          <Field
            name={name}
            label={name}
            component={selectPersonalizarPropiedades}
          >
            {estados.map((estado, index) => (
              <MenuItem key={index} value={estado.codigo}>
                {estado.descripcion}
              </MenuItem>
            ))}
          </Field>

          <ErrorMessage
            name={name}
            component={() => <FormHelperText>{errors[name]}</FormHelperText>}
          />
        </FormControl>
      </>
    );
  };

  const ValidarBanderas = ({
    estado,
    subestado,

    nameFecha,
    labelNameFecha,
    valorFecha,
    componentSizeFecha,

    nameHora,
    labelNameHora,
    valorHora,
    componentSizeHora,

    nameObservacion,
    labelNameObservacion,
    valorObservacion,
    componentSizeObservacion,

    handleChange,
    handleBlur,
    touched,
    errors,
  }) => {
    const [banderas, setBanderas] = useState({
      fechaCompromiso: null,
      horaCompromiso: null,
      observacion: null,
    });

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaSubestadoCobranza(estado);

          if (state) {
            const banderaAux = data.filter(
              (opciones) => opciones.codigo === subestado
            );

            setBanderas((state) => ({ ...state, ...banderaAux[0] }));
          }
        } catch (error) {
          console.log(error);
        }
      };

      if (estado > 0 && typeof estado !== "string") {
        getData();
      }
    }, [subestado, estado]);

    return (
      <>
        {banderas.fechaCompromiso && (
          <TextField
            id={nameFecha}
            name={nameFecha}
            type="date"
            label={labelNameFecha}
            sx={{ width: `${componentSizeFecha}` }}
            onChange={handleChange}
            size="small"
            value={formatoFecha(valorFecha)}
            InputLabelProps={{
              shrink: true,
            }}
            error={touched[nameFecha] && Boolean(errors[nameFecha])}
            helperText={touched[nameFecha] && errors[nameFecha]}
            onBlur={handleBlur}
          />
        )}

        {banderas.horaCompromiso && (
          <TextField
            id={nameHora}
            label={labelNameHora}
            type="time"
            sx={{ width: `${componentSizeHora}` }}
            onChange={handleChange}
            size="small"
            value={valorHora}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 60, // 5 min
            }}
            error={touched[nameHora] && Boolean(errors[nameHora])}
            helperText={touched[nameHora] && errors[nameHora]}
            onBlur={handleBlur}
          />
        )}

        {banderas.observacion && (
          <TextField
            id={nameObservacion}
            name={nameObservacion}
            type="text"
            label={labelNameObservacion}
            sx={{ width: `${componentSizeObservacion}` }}
            value={valorObservacion}
            onChange={handleChange}
            size="small"
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
            error={touched[nameObservacion] && Boolean(errors[nameObservacion])}
            helperText={touched[nameObservacion] && errors[nameObservacion]}
            onBlur={handleBlur}
          />
        )}
      </>
    );
  };

  const configuraionEnvioDatos = async (valoresAux) => {
    console.log(valoresAux);
    setLoading(true);

    const valores = { ...valoresAux };

    if (
      valoresAux.subestado === 5 ||
      valoresAux.subestado === 8 ||
      valoresAux.subestado === 9
    ) {
      valores.fechaCompromiso = valoresAux.fechaCompromiso;
    } else {
      valores.fechaCompromiso = "";
      valores.horaCompromiso = "";
    }

    const informacionGestionCobranza = {
      idCobranza: infoCobranza.idCobranza,
      idCliente: infoCobranza.idCliente,
      idUsuario: infoCobranza.idUsuario,
      idEstado: valores.subestado,

      nombreCliente: infoCobranza.nombreCliente,
      fechaCorte: infoCobranza.fechaCorte,
      estadosVencidos: infoCobranza.estadosVencidos,
      valorVencido: infoCobranza.valorVencido,
      Contacto: infoCobranza.contacto,

      fechaCompromiso: valores.fechaCompromiso,
      horaCompromiso: valores.horaCompromiso,
      observacion: valores.observacion.toUpperCase(),

      saldoAnterior: infoCobranza.saldoAnterior,
      pagosActual: infoCobranza.pagosActual,
      estadoCliente: infoCobranza.estadoCliente,
      valorApagar: infoCobranza.valorApagar,
      interesMoraVencido: infoCobranza.interesMoraVencido,
      seguroDesgravamen: infoCobranza.seguroDesgravamen,
      fechaMaxima: infoCobranza.fechaMaxima,
    };

    console.log(informacionGestionCobranza);

    /* setLoading(false); */

    try {
      const respuestaCrearCobranza = await crearCobranza(
        informacionGestionCobranza
      );

      console.log(respuestaCrearCobranza);

      if (respuestaCrearCobranza.state) {
        setDatosEnviadosCorrectamente(true);
        setOpenQueja(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
        }, 2000);
      } else {
        setLoading(false);
        alert(`${respuestaCrearCobranza.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <>
      <div className="accordion-container">
        <Formik
          initialValues={{
            estado: "",
            subestado: "",
            fechaCompromiso: "",
            horaCompromiso: "",
            observacion: "",
          }}
          validationSchema={Yup.object({
            estado: Yup.string().required("Estado requerido"),
            subestado: Yup.string().required("Subestado requerido"),
          })}
          validate={(values) => {
            const errors = {};
            const fechaActual = new Date();

            if (
              values.subestado === 5 ||
              values.subestado === 8 ||
              values.subestado === 9
            ) {
              if (
                validarFechaMayor(values.fechaCompromiso, fechaActual, "MAYOR")
              ) {
                errors.fechaCompromiso = "La fecha es menor a la fecha actual";
              }
            } else {
              values.fechaCompromiso = "";
            }

            return errors;
          }}
          onSubmit={(valores, { resetForm }) => {
            configuraionEnvioDatos(valores);
            setTimeout(() => {
              resetForm({ values: "" });
            }, 2000);
          }}
        >
          {({ values, handleChange, touched, errors, handleBlur }) => (
            <Form>
              <div className="accordion-container">
                <div>
                  <Accordion
                    defaultExpanded="true"
                    sx={{ boxShadow: 3, lineHeight: 0 }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      className="accordion-summary"
                      sx={{ margin: 0 }}
                    >
                      <h2 className="formulario-cabecera">Gestión Cobranza</h2>
                    </AccordionSummary>

                    <AccordionDetails sx={{ marginTop: "2rem" }}>
                      <Grid container spacing={1} rowSpacing={1}>
                        <Grid item md={12}>
                          <Grid container spacing={1}>
                            <Grid item md={12}>
                              <div className="cliente-informacion-seccion">
                                <SelectItemsEstadosCobranza
                                  name="estado"
                                  labelName="Estado"
                                  componentSize="26%"
                                  handleBlur={handleBlur}
                                  touched={touched}
                                  errors={errors}
                                />

                                <SelectItemsSubestadosCobranza
                                  estado={values.estado}
                                  name="subestado"
                                  labelName="Subestado"
                                  componentSize="26%"
                                  handleBlur={handleBlur}
                                  touched={touched}
                                  errors={errors}
                                />

                                <ValidarBanderas
                                  estado={values.estado}
                                  subestado={values.subestado}
                                  /* propiedades fechaCompromiso */
                                  nameFecha="fechaCompromiso"
                                  labelNameFecha="Fecha Compromiso"
                                  valorFecha={values.fechaCompromiso}
                                  componentSizeFecha="18%"
                                  /* propiedades horaCompromiso */
                                  nameHora="horaCompromiso"
                                  labelNameHora="Hora  Compromiso"
                                  valorHora={values.horaCompromiso}
                                  componentSizeHora="18%"
                                  /* propiedades obserbacion */
                                  nameObservacion="observacion"
                                  labelNameObservacion="Observación"
                                  valorObservacion={values.observacion}
                                  componentSizeObservacion="95%"
                                  /*erros*/
                                  handleBlur={handleBlur}
                                  touched={touched}
                                  errors={errors}
                                  handleChange={handleChange}
                                />
                              </div>
                            </Grid>
                          </Grid>
                          <div className="confirmar-estados-servicios">
                            <LoadingButton
                              sx={{ width: "20%" }}
                              type="submit"
                              size="small"
                              loading={loading}
                              loadingPosition="end"
                              variant="contained"
                            >
                              Guardar
                            </LoadingButton>

                            {datosEnviadosCorrectamente && (
                              <AcceptServiceresponse />
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <ModalGestionCobranza
          setOpen={setOpenQueja}
          open={openQueja}
          titulo="Gestión Cobranza"
          mensaje="Su gestión se ha realizado con exito !!."
          idUsuario={infoCobranza.idUsuario}
        />
      </div>
    </>
  );
};

export default FormGestionCobranza;
