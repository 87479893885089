import RevisionAnalistaContent from "./RevisionAnalista/RevisionAnalistaContent";

import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

const RevisionAnalista = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <RevisionAnalistaContent />
    </div>
  );
};

export default RevisionAnalista;
