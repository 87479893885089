import React, { useEffect, useState } from "react";

import { Alert, Box, Button, Paper, Snackbar } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LinkIcon from "@mui/icons-material/Link";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { consultarMedioPago } from "../services/SeguroService/seguroService";

const DetalleMedioPago = ({ idContrato }) => {
  const [medioPago, setMedioPago] = useState({
    URL: "",
    mensaje: "",
  });
  const [loading, setloading] = useState(false);
  const [generarURL, setGenerarURL] = useState(false);
  const [open, setOpen] = useState(false);

  const generarUrlMedioPago = async () => {
    setloading(true);

    try {
      const respuestaMedioPago = await consultarMedioPago(
        idContrato.numContrato
      );

      /* console.log(idContrato.numContrato); */
      console.log({ respuestaMedioPago });

      if (respuestaMedioPago.state) {
        if (!respuestaMedioPago.data) {
          setMedioPago({
            mensaje: "No se ha podido generar su URL de Pago",
            URL: "/",
          });
          setGenerarURL(true);
        } else {
          setMedioPago({
            mensaje: respuestaMedioPago.data,
            URL: respuestaMedioPago.data,
          });

          setGenerarURL(true);
        }

        setTimeout(() => {
          setloading(false);
        }, 1000);
      } else {
        setMedioPago({
          mensaje: "No se ha podido generar su URL de Pago",
          URL: "/",
        });
        setloading(false);
        setGenerarURL(true);
      }
    } catch (error) {
      console.log(error);
      setGenerarURL(true);
      setloading(false);
      /*  setMedioPago("No se ha podido generar su URL de Pago");
       */
      setMedioPago({
        mensaje: "No se ha podido generar su URL de Pago",
        URL: "/",
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  /* const copyToPortapaleles = () => {
    navigator.clipboard.writeText(medioPago.mensaje);

    setOpen(true);
  }; */

  const copyToClipboard = (text) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  const copyToPortapaleles = () => {
    copyToClipboard(medioPago.mensaje);

    setOpen(true);
  };

  return (
    <>
      <div className="medioPago_container">
        <Box className="medioPago_box">
          <Paper elevation={3} className="medioPago_paper">
            <h3>Su URL para la actualizacion de medio pago es: </h3>
            {generarURL ? (
              <Button
                /* sx={{ onBlur: "#FFFFF" }} */
                /*  onBlur={{ color: "#ff0000" }} */
                variant="outlined"
                endIcon={<ContentCopyIcon />}
                onClick={copyToPortapaleles}
              >
                {/*  <h5>{`${URLMedioPago.slice(0, 95)}....`}</h5> */}
                <h5>{`${medioPago.mensaje.slice(0, 120)}....`}</h5>
              </Button>
            ) : (
              <div className="medioPago_button">
                <LoadingButton
                  sx={{ width: "20ch" }}
                  type="submit"
                  size="medium"
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                  endIcon={<LinkIcon />}
                  onClick={generarUrlMedioPago}
                >
                  Generar
                </LoadingButton>
              </div>
            )}
          </Paper>
        </Box>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        /* key={("bottom", "center")} */
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          URL Copiada !!
        </Alert>
      </Snackbar>
    </>
  );
};

export default DetalleMedioPago;
