import React, { useEffect, useState } from "react";
import FormCambioEstado from "../components/FormCambioEstado";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { consultarClienteSISCRE } from "../services/solicitudesService";

const CambioEstado = ({ idCliente, idLocal, identificacion }) => {
  const [tieneTageta, setTieneTageta] = useState({ state: false, message: "" });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getValidacionTargeta = async () => {
      try {
        const respuestaTargetaCliente = await consultarClienteSISCRE(
          identificacion
        );

        if (respuestaTargetaCliente.state) {
          setTieneTageta((args) => ({
            ...args,
            state: true,
            message: respuestaTargetaCliente.data,
          }));
          setLoading(false);
        } else {
          setTieneTageta((args) => ({
            ...args,
            state: false,
            message: respuestaTargetaCliente.message,
          }));
          setLoading(false);
        }
      } catch (error) {
        /* alert(`${error.response.data.message}`); */
        setLoading(false);
        setTieneTageta((args) => ({
          ...args,
          state: false,
          message: error.response.data.message,
        }));

        console.log(error);
      }
    };

    getValidacionTargeta();
  }, []);

  if (loading) {
    return <SpinnerLoadSrx />;
  }

  return (
    <div>
      {tieneTageta.state ? (
        <FormCambioEstado
          idCliente={idCliente}
          idLocal={idLocal}
          data={tieneTageta.message}
        />
      ) : (
        <h2>{tieneTageta.message} !!</h2>
      )}
    </div>
  );
};

export default CambioEstado;
