import React, { useEffect, useState } from "react";

import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { consultarQuejasXCliente } from "../services/gestionService";
import { formatoFecha } from "../../shared/validations/validaciones";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ListaQuejasCliente = ({
  idCliente,
  setIdQuejaAux,
  setIdQuejaStorage,
  setCabeceraQueja,
}) => {
  const [existenQuejas, setExistenQuejas] = useState(false);
  const [clienteInfo, setClienteInfo] = useState(null);
  const [clienteQuejas, setQuejasCliente] = useState([]);

  useEffect(() => {
    const getSiniestros = async () => {
      if (idCliente < 0) {
        setExistenQuejas(false);
      } else {
        const respuestaProductosSeguros = await consultarQuejasXCliente(
          idCliente
        );

        console.log(respuestaProductosSeguros);

        if (respuestaProductosSeguros.state) {
          /* console.log(respuestaProductosSeguros); */
          setQuejasCliente(respuestaProductosSeguros.data);
          setExistenQuejas(true);
        } else {
          setQuejasCliente([]);
          setExistenQuejas(true);
        }
      }
    };
    getSiniestros();
  }, []);

  const escogerIdQueja = (queja) => {
    setCabeceraQueja(queja);
    setIdQuejaAux(queja.idQueja);
    setIdQuejaStorage(queja.idQueja);
  };

  return (
    <div>
      <div className="tipoSiniestro-container">
        {existenQuejas ? (
          <div className="tipoSiniestro-siniestros">
            <TableContainer component={Paper} sx={{ width: "100%" }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Fecha Queja</StyledTableCell>
                    <StyledTableCell>Cliente</StyledTableCell>
                    <StyledTableCell>Producto</StyledTableCell>
                    <StyledTableCell>Prestador</StyledTableCell>
                    <StyledTableCell>Estado</StyledTableCell>
                    <StyledTableCell>Gestionar</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clienteQuejas.map((queja) => (
                    <StyledTableRow key={queja.idQueja}>
                      <StyledTableCell>
                        {formatoFecha(queja.fechaQueja)}
                      </StyledTableCell>
                      <StyledTableCell>{queja.nombreCliente}</StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        {queja.producto}
                      </StyledTableCell>

                      <StyledTableCell>{queja.prestador}</StyledTableCell>
                      <StyledTableCell>{queja.estado}</StyledTableCell>

                      <StyledTableCell>
                        <Button
                          variant="contained"
                          onClick={() => escogerIdQueja(queja)}
                          endIcon={<SendIcon />}
                        >
                          Gestionar
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <div className="tipoSiniestro-siniestros">
            <h1>No existen quejas para este cliente !!</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListaQuejasCliente;
