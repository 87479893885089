import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  Alert,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { obtenerFechaActualFormulario } from "app/modules/shared/commonValues/obtenerFecha";

import {
  formatoFecha,
  validarCelular,
} from "app/modules/shared/validations/validaciones";

import { MaskCelulares } from "app/modules/shared/mask/mascarasInputs";
import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";
import {
  consultarDocumentosSoporte,
  crearDatosRequerimiento,
  crearRequerimientoSolicitud,
} from "../services/solicitudesService";
import { LoadingButton } from "@mui/lab";
import { useValorPrecancelar } from "../hooks/useValorPrecancelar";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const FormPrecancelacion = ({
  idCliente,
  identificacion,
  setIdRequerimiento,
  idLocal,
}) => {
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const { isLoading, valorPrecancelar } = useValorPrecancelar({
    identificacion,
  });

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const ValorPrecancelar = () => {
    return <h3>{` Valor Precancelar: $ ${valorPrecancelar}`}</h3>;
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsSubTipo = ({
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [subtipos, setSubtipos] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const respuestaEspecialidad = await consultarDocumentosSoporte(
            4,
            false
          );
          /* console.log(respuestaEspecialidad); */

          if (respuestaEspecialidad.state) {
            setSubtipos(respuestaEspecialidad.data);
            /* console.log(eespuestaEspecialdidad); */
          } else {
            setSubtipos([]);
          }
        } catch (error) {
          setSubtipos([]);
          console.log(error);
        }
      };
      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {subtipos.map((subtipo) => (
            <MenuItem
              key={subtipo.idTipoRequerimiento}
              value={subtipo.idTipoRequerimiento}
            >
              {subtipo.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const verificarTipoPrecancelacion = (tipoPrecancelacion, setFieldValue) => {
    /*   console.log({ tipoPrecancelacion }); */
    if (tipoPrecancelacion === "10") {
      return true;
    } else {
      return false;
    }
  };

  const configuracionEnvioDatos = async (valoresAux) => {
    setLoading(true);
    const valores = {
      ...valoresAux,
    };
    /*  console.log(valoresAux); */

    if (Number(valoresAux.idSubTipoRequerimento) === 11) {
      valores.fecha = "";
      valores.factura = "";
    }

    const informacionCancelacionSeguro = {
      idRequerimiento: 0,
      idCliente: Number(idCliente),
      celular: valores.celular,
      email: valores.email,
      idTipoRequerimiento: 4,
      idSubTipoRequerimento: Number(valores.idSubTipoRequerimento),
      estado: "ESTREQSOL",

      idLocal: idLocal,
      fechaSolicitud: obtenerFechaActualFormulario(),
      fechaResuelto: "",
      esActivo: true,
    };

    /* console.log(informacionCancelacionSeguro); */

    let idRequeriminetoAux;

    try {
      const respuestaSolicitud = await crearRequerimientoSolicitud(
        informacionCancelacionSeguro
      );

      /* console.log(respuestaSolicitud); */

      if (respuestaSolicitud.state) {
        setDatosEnviadosCorrectamente(true);
        setIdRequerimiento(respuestaSolicitud.data);
        idRequeriminetoAux = respuestaSolicitud.data;

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
        }, 3000);
      } else {
        alert(`${respuestaSolicitud.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(`${error.response.data.message}`);
    }

    const informacionDatosRequerimiento = {
      idDatosRequerimiento: 0,
      idRequerimiento: Number(idRequeriminetoAux),

      factura: Number(valores.factura),
      valor: Number(valores.valor),
      fecha: valores.fecha,

      referencia: "",
      operacion: 0,
      plazo: 0,
      detalle: "",

      esActivo: true,
    };

    /* console.log(informacionDatosRequerimiento); */

    try {
      const respuestaDatosRequeridos = await crearDatosRequerimiento(
        informacionDatosRequerimiento
      );

      /* console.log(respuestaDatosRequeridos); */

      if (!respuestaDatosRequeridos.state) {
        alert(`${respuestaDatosRequeridos.message}`);
      } /* else {
        setLoading(false);
      } */
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="accordion-container">
      <Formik
        initialValues={{
          celular: "",
          email: "",
          idSubTipoRequerimento: "",

          factura: "",
          valor: "",
          fecha: "",
        }}
        validate={(values) => {
          const errors = {};

          if (validarCelular(values.celular)) {
            errors.celular = "Primer digito debería ser '09'";
          }

          return errors;
        }}
        validationSchema={Yup.object({
          celular: Yup.string()
            .max(10, "Debería tener 10 números")
            .min(10, "Debería tener 10 números")
            .required("Celular requerido"),
          email: Yup.string()
            .email("Email no tiene formato")
            .required("Email requerido"),
          idSubTipoRequerimento: Yup.string().required(
            "Tipo Requerimiento requerido"
          ),
          valor: Yup.string().required("Valor Pagado requerido"),
        })}
        onSubmit={(valores, { resetForm }) => {
          configuracionEnvioDatos(valores);

          /* setTimeout(() => {
            resetForm({ values: "" });
          }, 3000); */
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <div className="accordion-container">
              <Accordion
                defaultExpanded="true"
                sx={{ boxShadow: 3, lineHeight: 0 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className="accordion-summary"
                  sx={{ margin: 0 }}
                >
                  <h2 className="formulario-cabecera">Precancelación</h2>
                </AccordionSummary>
                <AccordionDetails sx={{ marginTop: "2rem" }}>
                  <Grid container spacing={1} rowSpacing={1}>
                    <Grid item md={12}>
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          <div className="cliente-informacion-seccion">
                            <FormControl
                              sx={{ width: "15%" }}
                              size="small"
                              variant="outlined"
                              error={touched.celular && Boolean(errors.celular)}
                              helperText={touched.celular && errors.celular}
                              onBlur={handleBlur}
                            >
                              <InputLabel htmlFor="celular">Celular</InputLabel>
                              <OutlinedInput
                                value={values.celular}
                                onChange={handleChange}
                                name="celular"
                                id="celular"
                                inputComponent={MaskCelulares}
                                label="celular"
                              />
                              <ErrorMessage
                                name="celular"
                                component={() => (
                                  <FormHelperText>
                                    {errors.celular}
                                  </FormHelperText>
                                )}
                              />
                            </FormControl>

                            <TextField
                              id="email"
                              name="email"
                              type="text"
                              label="Email"
                              sx={{ width: "33%" }}
                              value={values.email}
                              onChange={handleChange}
                              size="small"
                              error={touched.email && Boolean(errors.email)}
                              helperText={touched.email && errors.email}
                              onBlur={handleBlur}
                            />

                            <SelectItemsSubTipo
                              name="idSubTipoRequerimento"
                              labelName="Tipo de Precancelación"
                              componentSize="32%"
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />
                          </div>

                          <div className="cliente-informacion-seccion">
                            {verificarTipoPrecancelacion(
                              values.idSubTipoRequerimento
                            ) && (
                              <>
                                <TextField
                                  id="fecha"
                                  name="fecha"
                                  type="date"
                                  label="Fecha de Credito"
                                  sx={{ width: "16%" }}
                                  onChange={handleChange}
                                  size="small"
                                  value={formatoFecha(values.fecha)}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  error={touched.fecha && Boolean(errors.fecha)}
                                  helperText={touched.fecha && errors.fecha}
                                  onBlur={handleBlur}
                                />

                                <TextField
                                  id="factura"
                                  name="factura"
                                  label="Valor de Compra"
                                  sx={{ width: "23%" }}
                                  value={values.factura}
                                  onChange={handleChange}
                                  size="small"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                  error={
                                    touched.factura && Boolean(errors.factura)
                                  }
                                  helperText={touched.factura && errors.factura}
                                  onBlur={handleBlur}
                                />
                              </>
                            )}

                            <TextField
                              id="valor"
                              name="valor"
                              label="Valor Pagado"
                              sx={{ width: "23%" }}
                              value={values.valor}
                              onChange={handleChange}
                              size="small"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              error={touched.valor && Boolean(errors.valor)}
                              helperText={touched.valor && errors.valor}
                              onBlur={handleBlur}
                            />

                            <ValorPrecancelar />
                          </div>

                          {datosEnviadosCorrectamente && (
                            <Alert severity="success" variant="filled">
                              Los datos han sido enviados correctamente.
                            </Alert>
                          )}

                          <div className="cliente-informacion-seccion">
                            <LoadingButton
                              sx={{ width: "25ch" }}
                              type="submit"
                              size="small"
                              loading={loading}
                              loadingPosition="end"
                              variant="contained"
                            >
                              Guardar
                            </LoadingButton>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormPrecancelacion;
