import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import SiniestroCerrarSiniestro from "./SiniestroCerrarSiniestro.jsx/SiniestroCerrarSiniestro";

const CerrarSiniestro = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <>
      <h2 className="titulosPrincipales-container">Siniestro - Cerrar</h2>
      <SiniestroCerrarSiniestro />
    </>
  );
};

export default CerrarSiniestro;
