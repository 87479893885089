import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  Alert,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";
import ModalAlertaNotificacionEstandar from "./ModalAlertaNotificacionEstandar";
import {
  crearCertificadoNotificacion,
  crearInformacionRequerimiento,
} from "../services/solicitudesService";
import handleDownlPdf from "app/utils/document/documentosService";
import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";
import { formatoFecha } from "app/modules/shared/validations/validaciones";

import { consultaCatalogosSinPadre } from "app/modules/app-cliente/services/Catalogos/catalogosService";

const FormGestionDevolucionProductos = ({
  informacionSolicitud,
  solicitud,
}) => {
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingNotification, setLoadingNotification] = useState(false);
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState(false);

/*   useEffect(() => {
    console.log({ informacionSolicitud });
    console.log({ solicitud });
  }, []); */

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const configuracionEnvioDatos = async (valores) => {
    /* console.log(solicitud); */

    const informacionPrecancelacion = {
      idRequerimiento: solicitud.idRequerimiento,
      estado: valores.estado,
      datosRequerimiento: {
        idRequerimiento: solicitud.idRequerimiento,
        idDatosRequerimiento:
          informacionSolicitud.datosRequerimientos[0].idDatosRequerimiento,
        //datos dependientes del flujo formulario
        referencia: "",

        factura: informacionSolicitud.datosRequerimientos[0].factura,
        fecha: informacionSolicitud.datosRequerimientos[0].fecha,
        detalle: informacionSolicitud.datosRequerimientos[0].detalle,

        valor: Number(valores.valor),
        operacion: Number(valores.operacion),
        plazo: Number(valores.plazo),

        esActivo: true,
      },
    };

    /* console.log(informacionPrecancelacion); */

    try {
      setLoading(true);

      const respuestaPrecancelacion = await crearInformacionRequerimiento(
        informacionPrecancelacion
      );

      if (respuestaPrecancelacion.state) {
        setDatosEnviadosCorrectamente(true);
        setNotification(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
        }, 3000);
      } else {
        setLoading(false);
        alert(`${respuestaPrecancelacion.message}`);
      }
    } catch (error) {
      setLoading(false);
      alert(`${error.response.data.message}`);
      console.log(error);
    }
  };

  const enviarNotificacion = async () => {
    setLoadingNotification(true);

    try {
      const respuestaPrecancelacion = await crearCertificadoNotificacion(
        "",
        solicitud.idRequerimiento
      );

      if (respuestaPrecancelacion.state) {
        handleDownlPdf(
          respuestaPrecancelacion.data,
          `CRT_DEVPRODUC_${
            solicitud.idRequerimiento
          }_${fechasDocumentosSiniestro()}`
        );
        setLoadingNotification(false);
        setOpen(true);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } else {
        setLoadingNotification(false);
        alert(`${respuestaPrecancelacion.message}`);
      }
    } catch (error) {
      setLoadingNotification(false);
      alert(`${error.response.data.message}`);
      console.log(error.response.data.message);
    }
  };

  return (
    <>
      <Card sx={{ width: "100%" }}>
        <CardContent className="card_cancelacion_container">
          <Typography
            gutterBottom
            variant="h3"
            component="div"
            style={{
              backgroundColor: "#000000",
              color: "#ffffff",
              padding: "0.5rem",
            }}
          >
            Información Devolución Producto
          </Typography>

          <Typography
            variant="h4"
            component="div"
            color="text.secondary"
            style={{
              padding: "0.5rem",
            }}
            className="informacion_notificacion_seguro"
          >
            <div className="detalle_notificacion_seguro">
              {`Local:   ${solicitud.nombreLocal}`}
              <br />
              {`Tipo:   ${solicitud.requerimiento}`}
              <br />
              {`Nombre:   ${solicitud.nombre}`}
              <br />
              {`Email:   ${solicitud.email}`}
              <br />
              {`Celular:   ${solicitud.celular}`}
              <br />
            </div>

            <div className="boton_notificacion_seguro">
              <br />
              {`Fecha Compra:  ${
                informacionSolicitud.datosRequerimientos[0].fecha
                  ? formatoFecha(
                      informacionSolicitud.datosRequerimientos[0].fecha
                    )
                  : ""
              }`}
              <br />
              {`Valor Factura:  $ ${informacionSolicitud.datosRequerimientos[0].factura}`}
              <br />
              {`Detalle: ${informacionSolicitud.datosRequerimientos[0].detalle}`}
            </div>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Formik
              initialValues={{
                valor: "",
                operacion: "",
                plazo: "",
                estado: "",
              }}
              validate={(values) => {
                const errors = {};

                return errors;
              }}
              validationSchema={Yup.object({
                valor: Yup.string().required("Valor requerido"),
                operacion: Yup.string().required("Operación requerido"),
                plazo: Yup.string().required("Plazo requerido"),
              })}
              onSubmit={(valores, { resetForm }) => {
                configuracionEnvioDatos(valores);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  <div className="devolucion-informacion-seccion">
                    <TextField
                      id="valor"
                      name="valor"
                      label="Valor Devolución"
                      sx={{ width: "18%" }}
                      value={values.valor}
                      onChange={handleChange}
                      size="small"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      error={touched.valor && Boolean(errors.valor)}
                      helperText={touched.valor && errors.valor}
                      onBlur={handleBlur}
                    />

                    <TextField
                      id="operacion"
                      name="operacion"
                      label="Operación"
                      sx={{ width: "18%" }}
                      value={values.operacion}
                      onChange={handleChange}
                      size="small"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      error={touched.operacion && Boolean(errors.operacion)}
                      helperText={touched.operacion && errors.operacion}
                      onBlur={handleBlur}
                    />

                    <TextField
                      id="plazo"
                      name="plazo"
                      type="text"
                      label="Plazo"
                      sx={{ width: "18%" }}
                      value={values.plazo}
                      onChange={handleChange}
                      size="small"
                      inputProps={{
                        style: { textTransform: "uppercase" },
                      }}
                      error={touched.plazo && Boolean(errors.plazo)}
                      helperText={touched.plazo && errors.plazo}
                      onBlur={handleBlur}
                    />

                    <SelectItemsCatalogoSinPadre
                      pais="ECU"
                      especial="false"
                      catalogos="ESTREQ"
                      grupo={1}
                      name="estado"
                      labelName="Estado"
                      componentSize="35%"
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                  </div>

                  <div className="cliente-informacion-seccion">
                    <LoadingButton
                      sx={{ width: "35%" }}
                      type="submit"
                      size="small"
                      loading={loading}
                      loadingPosition="end"
                      variant="contained"
                    >
                      GUARDAR
                    </LoadingButton>

                    {notification && (
                      <LoadingButton
                        sx={{ width: "35%" }}
                        type="button"
                        size="small"
                        loading={loadingNotification}
                        loadingPosition="end"
                        variant="contained"
                        onClick={enviarNotificacion}
                      >
                        NOTIFICAR
                      </LoadingButton>
                    )}

                    {datosEnviadosCorrectamente && (
                      <Alert severity="success" variant="filled">
                        Los datos han sido enviados correctamente.
                      </Alert>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </Typography>
        </CardContent>
      </Card>

      <ModalAlertaNotificacionEstandar
        open={open}
        setOpen={setOpen}
        titulo="Notificacion Devolucion Productos"
        mensaje="La solicitud ha sido enviada"
      />
    </>
  );
};

export default FormGestionDevolucionProductos;
