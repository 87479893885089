import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { IconButton, Stack } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InfoPopoverHover = ({ nombre, titulo, texto, children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `info${nombre}` : undefined;

  return (
    <div>
      <Typography
        aria-owns={id}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={(event) => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            handlePopoverClose();
          }
        }}
        onTouchStart={handlePopoverOpen} // Agregar soporte táctil
        color={"#3bd2a2"}
      >
        <Stack spacing={1}>
          <IconButton>
            <InfoOutlinedIcon color="success" />
          </IconButton>
          <>{titulo}</>
        </Stack>
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          onMouseEnter: () => setAnchorEl(anchorEl),
          onMouseLeave: (event) => {
            if (!event.currentTarget.contains(event.relatedTarget)) {
              handlePopoverClose();
            }
          },
          onTouchStart: (event) => event.stopPropagation(), // Evitar que el popover se cierre en dispositivos táctiles
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: texto }} />
        {children}
      </Popover>
    </div>
  );
};

export default InfoPopoverHover;
