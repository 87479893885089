import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Alert, FormControl, Grid, MenuItem, TextField } from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";

import { consultaCatalogosSinPadre } from "../../app-cliente/services/Catalogos/catalogosService";

import {
  consultarBienAsegurado,
  crearBienAsegurado,
} from "../services/SeguroService/seguroService";

import "../styles/main.css";
import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const FormActualizarImei = ({ bienAsegurado }) => {
  const [informacionBien, setInformacionBien] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  console.log({ bienAsegurado });
  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaBienAsegurado = await consultarBienAsegurado(
          bienAsegurado.idBienAsegurado
        );
        console.log({ respuestaBienAsegurado });

        if (respuestaBienAsegurado.state) {
          setInformacionBien(respuestaBienAsegurado.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setInformacionBien({});
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = React.memo(
    ({
      pais,
      especial,
      catalogos,
      grupo,
      name,
      labelName,
      componentSize,
      handleBlur,
      touched,
      errors,
    }) => {
      const [provincias, setProvincias] = useState([]);

      useEffect(() => {
        const getData = async () => {
          try {
            const { state, data } = await consultaCatalogosSinPadre(
              especial,
              catalogos,
              grupo
            );

            /* console.log({data}); */

            if (state) {
              setProvincias(data);
            } else {
              setProvincias([]);
            }
          } catch (error) {
            console.log(error);
          }
        };

        getData();
      }, []);

      return (
        <FormControl
          sx={{ width: `${componentSize}` }}
          error={touched[name] && Boolean(errors[name])}
          helperText={touched[name] && errors[name]}
          onBlur={handleBlur}
        >
          <InputLabel id={name}>{labelName}</InputLabel>

          <Field
            name={name}
            label={name}
            component={selectPersonalizarPropiedades}
          >
            {provincias.map((provincia, index) => (
              <MenuItem key={index} value={provincia.codigo}>
                {provincia.descripcion}
              </MenuItem>
            ))}
          </Field>

          <ErrorMessage
            name={name}
            component={() => <FormHelperText>{errors[name]}</FormHelperText>}
          />
        </FormControl>
      );
    }
  );

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const informacionActualizarBien = {
      idBienAsegurado: informacionBien.idBienAsegurado,
      idContrato: informacionBien.idContrato,
      idClasificacionArticulo: valores.idClasificacionArticulo,
      codigoBien: valores.codigoBien,
      descripcionBien: valores.descripcionBien.toUpperCase(),
      marca: valores.marca,
      modelo: valores.modelo,
      serie: valores.serie,
      valorBien: valores.valorBien,
      imei: valores.imei.toUpperCase(),
      esRegistro: valores.esRegistro,
      siniestrado: valores.siniestrado,
      perdidaTotal: valores.perdidaTotal,
      esActivo: true,
    };

    console.log(informacionActualizarBien);

    try {
      const respuestaBienAsegurado = await crearBienAsegurado(
        informacionActualizarBien
      );

      console.log(respuestaBienAsegurado);

      if (respuestaBienAsegurado.state) {
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
        alert(`${respuestaBienAsegurado.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <Formik
      initialValues={{
        idClasificacionArticulo: informacionBien.idClasificacionArticulo ?? "",
        codigoBien: informacionBien.codigoBien ?? "",
        descripcionBien: informacionBien.descripcionBien ?? "",
        marca: informacionBien.marca ?? "",
        modelo: informacionBien.modelo ?? "",
        serie: informacionBien.serie ?? "",
        imei: informacionBien.imei ?? "",
        valorBien: informacionBien.valorBien ?? "",
      }}
      validate={(values) => {
        const errors = {};

        return errors;
      }}
      validationSchema={Yup.object({})}
      onSubmit={(values, { resetForm }) => {
        configuracionDatosEnvio(values);
        /*   setTimeout(() => {
          resetForm({ values: "" });
        }, 5000); */
      }}
    >
      {({ values, handleChange, touched, errors, handleBlur }) => (
        <Form>
          <Accordion
            defaultExpanded="true"
            sx={{ boxShadow: 3, lineHeight: 0 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              className="accordion-summary"
              sx={{ margin: 0 }}
            >
              <h2 className="formulario-cabecera">Actualizar Bien Asegurado</h2>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={1} rowSpacing={1}>
                <Grid item md={12}>
                  <Grid container spacing={1}>
                    <Grid item md={12}>
                      <div className="cliente-informacion-seccion">
                        <SelectItemsCatalogoSinPadre
                          pais="ECU"
                          especial="true"
                          catalogos="CLAART"
                          name="idClasificacionArticulo"
                          labelName="Clasificación Artículo"
                          grupo={1}
                          componentSize="22%"
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />

                        <TextField
                          id="codigoBien"
                          name="codigoBien"
                          type="text"
                          label="Código Barras"
                          sx={{ width: "22%" }}
                          value={values.codigoBien}
                          onChange={handleChange}
                          size="small"
                        />

                        <TextField
                          id="valorBien"
                          name="valorBien"
                          label="Valor Bien"
                          sx={{ width: "15%" }}
                          value={values.valorBien}
                          onChange={handleChange}
                          size="small"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          error={touched.valorBien && Boolean(errors.valorBien)}
                          helperText={touched.valorBien && errors.valorBien}
                          onBlur={handleBlur}
                        />

                        <TextField
                          id="serie"
                          name="serie"
                          type="text"
                          label="Número Serie"
                          sx={{ width: "32%" }}
                          value={values.numeroSerie}
                          onChange={handleChange}
                          size="small"
                          inputProps={{
                            style: { textTransform: "uppercase" },
                          }}
                          error={touched.serie && Boolean(errors.serie)}
                          helperText={touched.serie && errors.serie}
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="cliente-informacion-seccion">
                        <SelectItemsCatalogoSinPadre
                          pais="ECU"
                          especial="false"
                          catalogos="MARCA"
                          name="marca"
                          labelName="Marca"
                          grupo={1}
                          componentSize="32%"
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                        />

                        <TextField
                          id="modelo"
                          name="modelo"
                          type="text"
                          label="Modelo"
                          sx={{ width: "31%" }}
                          value={values.modelo}
                          onChange={handleChange}
                          size="small"
                          inputProps={{
                            style: { textTransform: "uppercase" },
                          }}
                          error={touched.modelo && Boolean(errors.modelo)}
                          helperText={touched.modelo && errors.modelo}
                          onBlur={handleBlur}
                        />

                        <TextField
                          id="imei"
                          name="imei"
                          type="text"
                          label="Imei"
                          sx={{ width: "30%" }}
                          value={values.imei}
                          onChange={handleChange}
                          size="small"
                          inputProps={{
                            style: { textTransform: "uppercase" },
                          }}
                          error={touched.imei && Boolean(errors.imei)}
                          helperText={touched.imei && errors.imei}
                          onBlur={handleBlur}
                        />

                        <TextField
                          id="descripcionBien"
                          name="descripcionBien"
                          type="text"
                          label="Descripción Articulo"
                          sx={{ width: "98%" }}
                          value={values.descripcionBien}
                          onChange={handleChange}
                          size="small"
                          inputProps={{
                            style: { textTransform: "uppercase" },
                          }}
                          error={touched.telefono && Boolean(errors.telefono)}
                          helperText={touched.telefono && errors.telefono}
                          onBlur={handleBlur}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {datosEnviadosCorrectamente && (
                    <Alert severity="success" variant="filled">
                      Los datos han sido guardados correctamente.
                    </Alert>
                  )}
                  <div className="cliente-informacion-seccion">
                    <LoadingButton
                      sx={{ width: "35ch" }}
                      type="submit"
                      size="small"
                      loading={loading}
                      loadingPosition="end"
                      variant="contained"
                    >
                      Guardar
                    </LoadingButton>
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Form>
      )}
    </Formik>
  );
};

export default FormActualizarImei;
