import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  Alert,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  formatoFecha,
  validarFechaMayor,
} from "../../shared/validations/validaciones";

import {
  consultaCatalogosSinPadre,
  consultaUbiGeografica1,
} from "../../app-cliente/services/Catalogos/catalogosService";

import {
  consultarPrestadorMedicoXZona,
  consultarEspecialidad,
  crearCitaMedica,
  consultarPrestadorXIdPrestador,
} from "../services/citasMedicasService";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { LoadingButton } from "@mui/lab";
import ModalAlertaNotificacionEstandar from "app/modules/app-servicios/components/ModalAlertaNotificacionEstandar";

const FormCitasMedicas = ({ idCliente, idProducto, idPaciente }) => {
  const [clienteInfo, setClienteInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [emailNotificacionPrestador, setEmailNotificacionPrestador] =
    useState("");
  const [openCitaMedica, setOpenCitaMedica] = useState(false);

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsEspecialidad = ({
    TipoPrestador,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [especialdidades, setEspecialidad] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const respuestaEspecialidad = await consultarEspecialidad(
            TipoPrestador
          );

          if (
            respuestaEspecialidad.state &&
            respuestaEspecialidad.data.especialidades
          ) {
            setEspecialidad(respuestaEspecialidad.data.especialidades);
            /* console.log(eespuestaEspecialdidad); */
          } else {
            setEspecialidad([]);
          }
        } catch (error) {
          setEspecialidad([]);
          console.log(error);
        }
      };
      getData();
    }, [TipoPrestador]);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {especialdidades.map((especialidad, index) => (
            <MenuItem key={index} value={especialidad.idEspecialidad}>
              {especialidad.nombreEspecialidad}
            </MenuItem>
          ))}
        </Field>
        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsPrestadoresMedicosXZona = ({
    Ubic1,
    Ubic2,
    TipoPrestador,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [prestadoresMedicos, setPrestadoresMedicos] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const respuestaPrestadoresMedicos =
            await consultarPrestadorMedicoXZona(Ubic1, Ubic2, TipoPrestador);

          if (
            respuestaPrestadoresMedicos.state &&
            respuestaPrestadoresMedicos.data.prestadores
          ) {
            setPrestadoresMedicos(respuestaPrestadoresMedicos.data.prestadores);

            /* console.log(respuestaPrestadoresMedicos); */
          } else {
            setPrestadoresMedicos([]);
          }
        } catch (error) {
          setPrestadoresMedicos([]);
          console.log(error);
        }
      };
      getData();
    }, [Ubic1, Ubic2, TipoPrestador]);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {prestadoresMedicos.map((prestador, index) => (
            <MenuItem key={index} value={prestador.idPrestadorMedico}>
              {prestador.nombrePrestador}
            </MenuItem>
          ))}
        </Field>
        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsCatalogo = ({
    pais,
    especial,
    catalogos,
    codPadre,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaUbiGeografica1(
            especial,
            catalogos,
            codPadre,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, [pais, codPadre]);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const ConsultarEmailNotificacion = ({ idPrestador }) => {
    const [
      emailNotificacionPrestadorMedico,
      setemNotificacionailPrestadorMedico,
    ] = useState("");

    useEffect(() => {
      const getEmailNotificacion = async () => {
        try {
          const { state, data } = await consultarPrestadorXIdPrestador(
            idPrestador
          );

          if (state) {
            setemNotificacionailPrestadorMedico(data.email);
            setEmailNotificacionPrestador(data.email);
          } else {
            setemNotificacionailPrestadorMedico("");
            setEmailNotificacionPrestador("");
          }
        } catch (error) {
          console.log(error);
        }
      };
      getEmailNotificacion();
    }, [idPrestador]);

    return (
      <h3>Email Prestador Medico: {`${emailNotificacionPrestadorMedico}`}</h3>
    );
  };

  const configuraionEnvioDatos = async (valores) => {
    setLoading(true);
    const informacionCitasMedicas = {
      idCliente: Number(idCliente),
      idPaciente: Number(idPaciente),
      idProducto: Number(idProducto),
      idEspecialidad: Number(valores.idEspecialidad),
      idPrestadorMedico: Number(valores.idPrestadorMedico),
      esTitular: `${idCliente === idPaciente ? true : false}`,
      parentesco: valores.parentesco,
      estado: valores.estado,
      fecha: valores.fecha,
      hora: valores.hora,
      tipoPago: valores.tipoPago,
      /*  emailNotificacion: valores.emailNotificacion, */
      emailNotificacion: emailNotificacionPrestador,
      esActivo: true,
    };

    /* console.log(informacionCitasMedicas); */

    try {
      const respuestaCitasMedicas = await crearCitaMedica(
        informacionCitasMedicas
      );
      /*  console.log(respuestaCitasMedicas); */

      if (respuestaCitasMedicas.state) {
        setDatosEnviadosCorrectamente(true);
        setOpenCitaMedica(true);

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
        }, 4000);
      } else {
        setLoading(false);
        alert(`${respuestaCitasMedicas.data.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <>
      {clienteInfo ? (
        <div className="accordion-container">
          <div>
            <Formik
              initialValues={{
                idPaciente: "",
                parentesco: "",
                estado: "",
                fecha: "",
                hora: "",
                emailNotificacion: "",
                tipoPago: "",
                idPrestadorMedico: "",
                idEspecialidad: "",
                idProducto: "",
                tipoPrestador: "",
                idUbicGeografica1: "",
                idUbicGeografica2: "",
              }}
              validationSchema={Yup.object({
                tipoPago: Yup.string().required("Tipo Pago requerido"),
                estado: Yup.string().required("Estado requerido"),
                tipoPrestador: Yup.string().required(
                  "Tipo Prestador requerido"
                ),
                idUbicGeografica1: Yup.string().required("Provincia requerido"),
                idUbicGeografica2: Yup.string().required("Canton requerido"),
                parentesco: Yup.string().required("Parentesco requerido"),
                emailNotificacion: Yup.string().email("Email no tiene formato"),
                hora: Yup.string().required("Hora requerida"),
                fecha: Yup.string().required("Fecha requerida"),
                idPrestadorMedico: Yup.string().required(
                  "Prestador Medico requerido"
                ),
                idEspecialidad: Yup.string().required("Especialidad requerido"),
              })}
              validate={(values) => {
                const errors = {};

                const fechaActual = new Date();

                if (validarFechaMayor(values.fecha, fechaActual, "MAYOR")) {
                  errors.fecha = "La fecha es menor a la fecha actual";
                }
                return errors;
              }}
              onSubmit={(valores, { resetForm }) => {
                configuraionEnvioDatos(valores);

                /*  setTimeout(() => {
                  resetForm({ values: "" });
                }, 5000); */
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  <div className="accordion-container">
                    <div>
                      <Accordion
                        defaultExpanded="true"
                        sx={{ boxShadow: 3, lineHeight: 0 }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          className="accordion-summary"
                          sx={{ margin: 0 }}
                        >
                          <h2 className="formulario-cabecera">Cita Medica</h2>
                        </AccordionSummary>
                        <AccordionDetails sx={{ marginTop: "2rem" }}>
                          <Grid container spacing={1} rowSpacing={1}>
                            <Grid item md={12}>
                              <Grid container spacing={1}>
                                <Grid item md={12}>
                                  <div className="cliente-informacion-seccion">
                                    <SelectItemsCatalogoSinPadre
                                      pais="ECU"
                                      especial="false"
                                      catalogos="PARENTESCO"
                                      grupo={1}
                                      name="parentesco"
                                      labelName="Parentesco"
                                      componentSize="14%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />

                                    <TextField
                                      id="fecha"
                                      name="fecha"
                                      type="date"
                                      label="Fecha"
                                      sx={{ width: "16%" }}
                                      onChange={handleChange}
                                      size="small"
                                      value={formatoFecha(values.fecha)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={
                                        touched.fecha && Boolean(errors.fecha)
                                      }
                                      helperText={touched.fecha && errors.fecha}
                                      onBlur={handleBlur}
                                    />

                                    <TextField
                                      id="hora"
                                      label="Hora"
                                      type="time"
                                      sx={{ width: "16%" }}
                                      onChange={handleChange}
                                      size="small"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 60, // 5 min
                                      }}
                                      error={
                                        touched.hora && Boolean(errors.hora)
                                      }
                                      helperText={touched.hora && errors.hora}
                                      onBlur={handleBlur}
                                    />

                                    <SelectItemsCatalogo
                                      pais="ECU"
                                      especial="true"
                                      catalogos="UG1"
                                      codPadre={0}
                                      grupo={1}
                                      name="idUbicGeografica1"
                                      labelName="Provincia"
                                      componentSize="23%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />

                                    <SelectItemsCatalogo
                                      pais="ECU"
                                      especial="true"
                                      catalogos="UG2"
                                      codPadre={values.idUbicGeografica1}
                                      grupo={1}
                                      name="idUbicGeografica2"
                                      labelName="Canton"
                                      componentSize="23%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />

                                    <SelectItemsCatalogoSinPadre
                                      pais="ECU"
                                      especial="false"
                                      catalogos="TIPOPRESTMED"
                                      grupo={1}
                                      name="tipoPrestador"
                                      labelName="Tipo Prestador"
                                      componentSize="14%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />

                                    <SelectItemsPrestadoresMedicosXZona
                                      Ubic1={values.idUbicGeografica1}
                                      Ubic2={values.idUbicGeografica2}
                                      TipoPrestador={values.tipoPrestador}
                                      name="idPrestadorMedico"
                                      labelName="Prestador Medico"
                                      componentSize="30%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />

                                    <SelectItemsCatalogoSinPadre
                                      pais="ECU"
                                      especial="false"
                                      catalogos="ESTCITAMED"
                                      grupo={1}
                                      name="estado"
                                      labelName="Estado"
                                      componentSize="12%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />

                                    <SelectItemsCatalogoSinPadre
                                      pais="ECU"
                                      especial="false"
                                      catalogos="TIPOPAGOCITA"
                                      grupo={1}
                                      name="tipoPago"
                                      labelName="Tipo Pago"
                                      componentSize="14%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />

                                    <SelectItemsEspecialidad
                                      TipoPrestador={values.tipoPrestador}
                                      name="idEspecialidad"
                                      labelName="Especialidad"
                                      componentSize="22%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />

                                    <div className="cliente-informacion-seccion">
                                      <ConsultarEmailNotificacion
                                        idPrestador={values.idPrestadorMedico}
                                      />
                                    </div>
                                  </div>
                                  {datosEnviadosCorrectamente && (
                                    <Alert severity="success" variant="filled">
                                      Los datos han sido guardados
                                      correctamente.
                                    </Alert>
                                  )}

                                  <div className="cliente-informacion-seccion">
                                    <LoadingButton
                                      sx={{ width: "25ch" }}
                                      type="submit"
                                      size="small"
                                      loading={loading}
                                      loadingPosition="end"
                                      variant="contained"
                                    >
                                      Guardar
                                    </LoadingButton>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <SpinnerLoadSrx />
      )}

      <ModalAlertaNotificacionEstandar
        setOpen={setOpenCitaMedica}
        open={openCitaMedica}
        titulo="Cita Medica Creada"
        mensaje="Su cita medica ha sido creada con exito !!."
      />
    </>
  );
};

export default FormCitasMedicas;
