import React, { useContext, useEffect, useRef } from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";

import MensajeSideController from "./MensajeSideController";
import ChatSideContext from "../context/ChatSideContext";

const MensajesConversacionContainer = ({ mensajes }) => {
  const listEndRef = useRef(null);
  const { informacionChatSide, setInformacionChatSide } =
    useContext(ChatSideContext);

  useEffect(() => {
    if (listEndRef.current) {
      listEndRef.current.scrollIntoView({ behavior: "instant", block: "end" });
    }
  }, [mensajes]);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      bgcolor: "background.paper",
      /*  position: "absolute", */
      overflow: "auto",
      /* height: "80vh", */
    },
    ul: {
      padding: 0,
    },
    customListItem: {
      width: "100%",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  }));

  const classes = useStyles();

  return (
    <List
      classes={{
        root: classes.root,
        ul: classes.ul,
      }}
      /* className="movimientoCliente-menu-items" */
    >
      <ListItem className={`${classes.customListItem}`}>
        {mensajes?.map((mensaje, index) => {
          return (
            <ListItemText sx={{ width: "100%" }} key={index}>
              <MensajeSideController
                message={mensaje.mensaje}
                sender={
                  informacionChatSide.isCallCenter
                    ? !mensaje.esEmisor
                    : mensaje.esEmisor
                }
                dateTimeMessage={mensaje.fechaCreacion}
              />
            </ListItemText>
          );
        })}
        {/*   <div ref={listEndRef} /> */}
      </ListItem>
    </List>
  );
};

export default MensajesConversacionContainer;
