
//Constantes para acciones de Common 
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';

//Constantes para acciones de Auth 
export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const ON_HIDE_LOADER = "on_hide_loader";
export const ON_SHOW_LOADER = "on_show_loader";
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const UPDATE_CODE_USER = "update_code_user"

