import React, { useEffect, useState } from "react";

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import {
  consultarGenerarCertificado,
  consultarRequerimientosCliente,
} from "../services/solicitudesService";

import { formatoFecha } from "app/modules/shared/validations/validaciones";
import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";

import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/shared/mask/stylesTables";

import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";

import handleDownlPdf from "app/utils/document/documentosService";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import ModalDetalleDesestimado from "./ModalDetalleDesestimado";

import {
  detalleTipoRequerimiento,
  documentoTipoRequerimiento,
  idEstados,
  idSubTipoRequerimiento,
} from "app/modules/shared/dictionary/extensionDocumentos";

const ListaRequerimientosCliente = ({ idCliente, identificacion }) => {
  const [isLoading, setisLoading] = useState(true);
  const [clienteRequerimientos, setClienteRequerimientos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [solicitudDesestimada, setSolicitudDesestimada] = useState({});

  useEffect(() => {
    const getSiniestros = async () => {
      try {
        const respuestaRequerimientosCliente =
          await consultarRequerimientosCliente(idCliente);

        /* console.log(respuestaRequerimientosCliente); */

        if (
          respuestaRequerimientosCliente.state &&
          respuestaRequerimientosCliente.data
        ) {
          setClienteRequerimientos(respuestaRequerimientosCliente.data);
          setisLoading(false);
          /*  setClienteInfo(cliente); */
        } else {
          setisLoading(false);
          setClienteRequerimientos([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getSiniestros();
  }, []);

  const descargarCertificado = async (solicitud) => {
    /*  console.log({ solicitud }); */
    setLoading(true);
    try {
      const respuestaCertificadoPDF = await consultarGenerarCertificado(
        solicitud.idRequerimiento
      );

      if (respuestaCertificadoPDF.state) {
        handleDownlPdf(
          respuestaCertificadoPDF.data,
          `${documentoTipoRequerimiento(solicitud.idTipoRequerimiento)}_${
            solicitud.idRequerimiento
          }_${fechasDocumentosSiniestro()}`
        );

        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } else {
        alert(`${respuestaCertificadoPDF.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(`${error.response.data.message}`);
    }
  };

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  if (clienteRequerimientos.length === 0) {
    return (
      <>
        <BotonGerericoSalir />
        <h2>El Cliente No Posee Requerimientos</h2>
      </>
    );
  }

  const detalleDesestimado = (solicitud) => {
    setOpen(true);

    setSolicitudDesestimada(solicitud);
  };

  return (
    <>
      <BotonGerericoSalir />

      <HeaderInformacionCliente idCliente={idCliente} />
      <div className="tipoSiniestro-siniestros">
        <TableContainer component={Paper} sx={{ width: "100%" }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Tipo Requerimieto</StyledTableCell>
                <StyledTableCell>SubTipo Requerimieto</StyledTableCell>
                <StyledTableCell>Fecha Solicitud</StyledTableCell>

                <StyledTableCell>Estado</StyledTableCell>
                {/*  <StyledTableCell>Fecha Resolución</StyledTableCell> */}
                {/*   <StyledTableCell>Email</StyledTableCell> */}
                <StyledTableCell>Generar</StyledTableCell>
                <StyledTableCell>Observación</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clienteRequerimientos.map((solicitud) => (
                <StyledTableRow key={solicitud.idRequerimiento}>
                  <StyledTableCell>
                    {detalleTipoRequerimiento(solicitud.idTipoRequerimiento)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {idSubTipoRequerimiento(solicitud.idSubTipoRequerimento)}
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    {formatoFecha(solicitud.fechaSolicitud)}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {idEstados(solicitud.estado)}
                  </StyledTableCell>
                  {/*  <StyledTableCell>
                    {formatoFecha(solicitud.fechaResuelto)}
                  </StyledTableCell> */}
                  {/*  <StyledTableCell>{solicitud.email}</StyledTableCell> */}

                  {solicitud.estado === "ESTREQRES" ? (
                    <StyledTableCell>
                      <LoadingButton
                        sx={{ width: "50%" }}
                        type="button"
                        size="small"
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        onClick={() => descargarCertificado(solicitud)}
                      >
                        Generar
                      </LoadingButton>
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell>NO DISPONIBLE</StyledTableCell>
                  )}

                  <StyledTableCell>
                    <Button
                      size="small"
                      variant="contained"
                      type="button"
                      onClick={() => detalleDesestimado(solicitud)}
                    >
                      Ver
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <ModalDetalleDesestimado
          open={open}
          setOpen={setOpen}
          solicitudDesestimada={solicitudDesestimada}
          identificacion={identificacion}
          titulo="Detalle Solicitud"
          mensaje="Desestimado"
        />
      </div>
    </>
  );
};

export default ListaRequerimientosCliente;
