import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import SolicitudRequerimientoCliente from "./SolicitudRequerimientoCliente/SolicitudRequerimientoCliente";

const RequerimientosClienteSolicitud = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <>
      <h2>Requerimientos - Cliente </h2>
      <SolicitudRequerimientoCliente />
    </>
  );
};

export default RequerimientosClienteSolicitud;
