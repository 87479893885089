import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormikContext, Formik, Form, Field, useField } from "formik";

import { Alert, Button, Grid, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Select from "@mui/material/Select";

import "../styles/main.css";

import {
  consultaTrackingSolicitud,
  obtenerDocumento,
  obtenerFlujo,
  obtenerSolicitud,
  obtenerSolicitudDigital,
} from "../services/creditoServices";

import handleDownlPdf from "app/utils/document/documentosService";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";
import { obtenerRegistroArchivo, obtenerRegistroFirma } from "app/modules/app-cliente/services/clienteService";
import BoxDocumentosCliente from "./BoxDocumentosCliente";

const TrackingSolicitud = ({ solicitud }) => {
  const [archivoDescargado, setArchivoDescargado] = useState(false);
  const [pdfTracking, setPdfTracking] = useState();
  const [idGrupo, setIdGrupo] = useState(null);
  const [registroFirmaFoto, setRegistroFirmaFoto] = useState(null);
  const [mostrarPdf, setMostrarPdf] = useState(false);
  const [mostrarDocumentos, setMostrarDocumentos] = useState(false);

  useEffect(() => {
    //console.log(idSolic, identificacion, idFlujo);
  }, []);

  const getFlujo = async () => {
    try {
      const respFlujo = await obtenerFlujo(solicitud.idFlujo);
      if (respFlujo.state) {
        setIdGrupo(respFlujo.data.idGrupo);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    setMostrarPdf(false);
    getFlujo();
  }, [solicitud]);

  const obtenerDoc = async () => {
    console.log("Intentando descargar", solicitud.identificacion);
    await obtenerDocumento(solicitud.idSolicitud)
      .then((response) => {
        if (response.state) {
          handleDownlPdf(
            response.data,
            `Solicitud_Credito_${solicitud.identificacion}`
          );
          setArchivoDescargado(true);
        } else {
          if (solicitud.idEstado === 11) {
            obtenerSolicitudDigital(solicitud.identificacion, idGrupo)
              .then((resp) => {
                if (resp.state) {
                  handleDownlPdf(
                    resp.data,
                    `Solicitud_Credito_${solicitud.identificacion}`
                  );
                  setArchivoDescargado(true);

                  setTimeout(() => {
                    setArchivoDescargado(false);
                  }, 1500);
                } else {
                  console.log(resp.message);
                }
              })
              .catch((error) => {
                console.log(error.message);
              });
          } else alert("No existe solicitud Digital");
        }

        setTimeout(() => {
          setArchivoDescargado(false);
        }, 1500);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const obtenerTracking = async () => {
    setMostrarDocumentos(false);
    try {
      var pdf = await consultaTrackingSolicitud(
        solicitud.identificacion,
        idGrupo
      );
      if (pdf.state) {
        setPdfTracking(pdf.data);
        setMostrarPdf(true);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const getArchivosFirmaFoto = async () => {
    setMostrarPdf(false);
    let infoArchivos;
    try {
      const fotos = await obtenerRegistroArchivo(solicitud.idCliente);
      if (fotos.state) {
        infoArchivos = fotos.data;
        //setArchivoFirmaFoto(true);
        console.log("Si existe registro de Foto");
        //console.log(fotos.data);
      } else {
        console.log("No existe registro de Foto");
        //setArchivoFirmaFoto(null);
      }
    } catch (_errors) {
      console.log(_errors);
    }

    let infofirma;
    try {
      const firma = await obtenerRegistroFirma(solicitud.idCliente);
      if (firma.state) {
        infofirma = firma.data;
        //setArchivoFirmaFoto(true);
        console.log("Si existe registro de firma");
        //console.log(solicit.firma);
      } else {
        console.log("No existe registro de firma");
        //setArchivoFirmaFoto(null);
      }
    } catch (_errors) {
      console.log(_errors);
    }

    if (infoArchivos.idArchivo && infofirma.idFirma) {
      const res = {
        ...infoArchivos,
        ...infofirma,
      };
      //console.log(res);

      setRegistroFirmaFoto(res);
      setMostrarDocumentos(true);
    }
  };

  return (
    <>
      <SCSAccordion tituloCabecera={"Tracking de Solicitud"}>
        <Stack
          sx={{ marginTop: 3 }}
          direction={{ xs: "column", sm: "column", md: "row" }}
          spacing={{ xs: 2, sm: 3, md: 3 }}
          justifyContent={"center"}
        >
          <SCSGenericButton
            titulo={"Solicitud Digitalizada"}
            onClick={obtenerDoc}
            size={30}
          />
          <SCSGenericButton
            titulo={"Resumen de Solicitud"}
            onClick={obtenerTracking}
            size={30}
          />
          <SCSGenericButton
            titulo={"Documentos"}
            onClick={getArchivosFirmaFoto}
            size={30}
          />
          {archivoDescargado && (
              <Alert severity="success" variant="filled">
                Se ha Descargado el Archivo.
              </Alert>
            )}
        </Stack>
      </SCSAccordion>
      <Grid sx={{ mt: 5 }} container spacing={3} justifyContent={"center"}>
        <Grid hidden={!mostrarPdf} xs={11}>
          <div style={{ position: "relative", width: "100%" }}>
            <div
              style={{
                width: "100%",
                background: "rgb(82, 86, 89)",
                height: "70px",
                position: "absolute",
              }}
            />
            <iframe
              src={`data:application/pdf;base64,${pdfTracking}`}
              id="resumeClient"
              frameBorder="0"
              title="resumeClient"
              width="100%"
              height="700"
              name="resumeClient"
            />
          </div>
        </Grid>
      </Grid>
      { mostrarDocumentos && (
        <BoxDocumentosCliente 
        idCli={solicitud.idCliente}
        identifCliente={solicitud.identifCliente}
        registroFirmaFoto={registroFirmaFoto}
        />
      )}
    </>
  );
};

export default TrackingSolicitud;
