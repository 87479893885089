import React, { useEffect } from "react";

import FormGestionQueja from "../components/FormGestionQueja";
import BotonSalirLimiarStorageQuejas from "./BotonSalirLimiarStorageQuejas";
import useQuejasLocalStorage from "../hooks/useQuejasLocalStorage";
import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";
import HeaderInformacionQueja from "./HeaderInformacionQueja";

const DetalleQueja = ({ idQueja, idCliente, cabeceraQueja }) => {
  const [idQuejaClienteStorage, setIdQuejaClienteStorage] =
    useQuejasLocalStorage("idQuejaClienteStorage", null);

  const [quejaStorage, setQuejaStorage] = useQuejasLocalStorage(
    "quejaStorage",
    null
  );

  useEffect(() => {
    /* console.log(idQuejaClienteStorage); */
    setIdQuejaClienteStorage(idCliente);
    if (idQuejaClienteStorage) {
      setIdQuejaClienteStorage(idQuejaClienteStorage);
    }
  }, [idQuejaClienteStorage]);

  useEffect(() => {
    /* console.log(quejaStorage); */
    setQuejaStorage(cabeceraQueja);
    if (quejaStorage) {
      setQuejaStorage(quejaStorage);
    }
  }, [quejaStorage]);

  return (
    <div>
      {idQuejaClienteStorage && (
        <>
          <HeaderInformacionCliente idCliente={idQuejaClienteStorage} />

          <HeaderInformacionQueja cabeceraQueja={quejaStorage} />

          <FormGestionQueja
            idCliente={idQuejaClienteStorage}
            idQueja={idQueja}
          />
        </>
      )}
    </div>
  );
};

export default DetalleQueja;
