import React, { useState } from "react";
import { Button, Card, Grid, Stack, TextField } from "@mui/material";
import { useHistory } from "react-router-dom";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";

const BuscadorIdentificacion = ({ traerIdentificacion }) => {
  const history = useHistory();
  const [identificacion, setidCliente] = useState();

  const handleIdentificacion = (e) => {
    setidCliente(e.target.value);
  };
  const redirectHomePage = () => {
    history.push("/initialHome"); 
  };

  return (
    <>
      <Card className="buscador-container-preev">
        <div className="buscador-cabecera-preev">
          <h2>Ingrese el numero de cedula y presione Consultar.</h2>
        </div>

        <div className="buscador-body-preev">
          <div className="buscador-identificacion-preev">
            <TextField
              id="buscador"
              label="Ingrese Identificación"
              size="small"
              variant="outlined"
              value={identificacion}
              onChange={handleIdentificacion}
            />
          </div>
          <Stack
            sx={{marginTop:3}}
            direction={{ xs : "column", sm: "column", md: "row" }}
            spacing={{ xs: 2, sm: 3, md: 3 }}
            justifyContent={"center"}
          >
            <SCSGenericButton
              variant="contained"
              onClick={traerIdentificacion(identificacion)}
              titulo={"Consultar"}
            
            />

            <SCSGenericButton variant="contained" onClick={redirectHomePage}
            titulo={"Cancelar"}
            />
          </Stack>
        </div>
      </Card>
    </>
  );
};

export default BuscadorIdentificacion;
