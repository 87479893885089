import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  TextField,
  FormHelperText,
  InputLabel,
  Select,
} from "@mui/material";

import { fechasDocumentosSiniestro } from "../../shared/commonValues/obtenerFecha";

import handleDownlPdf from "../../../utils/document/documentosService";

import {
  consultaCatalogosSinPadre,
  consultaUbiGeografica1,
} from "../../app-cliente/services/Catalogos/catalogosService";

import {
  consultaCabeceraCliente,
  crearNotificacion,
  consultarDocumentoXServicio,
  notificarSiniestroCliente,
  crearSeguimiento,
} from "../Services/siniestrosService";

import {
  MaskCelulares,
  MaskTelefonos2,
} from "app/modules/shared/mask/mascarasInputs";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import {
  SCSGenericButton,
  SCSLoadingButton,
} from "app/modules/common/components/Buttons/SCSButtons";

import { validarFalsaData } from "app/modules/shared/validations/validaciones";
import { ERROR_VALIDACION_CONTACTO } from "app/modules/shared/constants/constantsMessanges";

const FormSiniestroNotificacion = ({ idSiniestro, idCliente }) => {
  const [habilitarDescarga, setHabilitarDescarga] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [informacionInformeNotificacion, setInformacionInformeNotificacion] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDoc, setLoadingDoc] = useState(false);

  const [idInfoNotificacion, setIdInfoNotificacion] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaInformacionCliente = await consultaCabeceraCliente(
          idCliente
        );

        if (respuestaInformacionCliente.state) {
          /* console.log(respuestaInformacionCliente); */

          const informacionClienteClasificado = {
            esTitular: true,
            identificacion: respuestaInformacionCliente.data.identificacion,
            nombreContacto: respuestaInformacionCliente.data.nombreCliente,
            parentesco: "",
            lugarSiniestro: "",

            idUbicGeografica1:
              respuestaInformacionCliente.data.idUbiGeografica1,
            idUbicGeografica2:
              respuestaInformacionCliente.data.idUbiGeografica2,
            idUbicGeografica3:
              respuestaInformacionCliente.data.idUbiGeografica3,

            sector: respuestaInformacionCliente.data.sector,
            callePrincipal: respuestaInformacionCliente.data.callePrincipal,
            numero: respuestaInformacionCliente.data.numeroCasa,
            calleSecundaria: respuestaInformacionCliente.data.calleSecundaria,
            referenciaUbicacion: respuestaInformacionCliente.data.referencia,

            telefono: respuestaInformacionCliente.data.telefono,
            celular: respuestaInformacionCliente.data.celular,
            email: respuestaInformacionCliente.data.email,
          };
          setInformacionInformeNotificacion(informacionClienteClasificado);
        } else {
          setInformacionInformeNotificacion({});
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogo = ({
    pais,
    especial,
    catalogos,
    codPadre,
    grupo,
    name,
    labelName,
    componentSize,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaUbiGeografica1(
            especial,
            catalogos,
            codPadre,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, [pais, codPadre]);

    return (
      <FormControl sx={{ width: `${componentSize}` }}>
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const generarCartaNotificacion = async () => {
    setLoadingDoc(true);
    try {
      const respuestaCartaNotificacion = await consultarDocumentoXServicio(
        "ActaNotificacion",
        idSiniestro
      );
      /*  console.log(respuestaCartaNotificacion);
      console.log(`SNT_${idSiniestro}_NOTI_${fechasDocumentosSiniestro()} `); */

      if (respuestaCartaNotificacion.state) {
        handleDownlPdf(
          respuestaCartaNotificacion.data,
          `SNT_${idSiniestro}_NOTI_${fechasDocumentosSiniestro()} `
        );
        setLoadingDoc(false);
      } else {
        setLoadingDoc(false);
        alert(`${respuestaCartaNotificacion.message}`);
      }
    } catch (error) {
      setLoadingDoc(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const informacionNotificacion = {
      idInformacionNotificacion: idInfoNotificacion,
      idSiniestro: Number(idSiniestro),
      esTitular: valores.esTitular,
      identificacion: valores.identificacion,
      nombreContacto: valores.nombreContacto.toUpperCase(),
      parentesco: valores.parentesco,
      telefono: valores.telefono,
      celular: valores.celular,
      email: valores.email,
      lugarSiniestro: valores.lugarSiniestro,
      idUbicGeografica1: Number(valores.idUbicGeografica1),
      idUbicGeografica2: Number(valores.idUbicGeografica2),
      idUbicGeografica3: Number(valores.idUbicGeografica3),
      sector: valores.sector.toUpperCase(),
      callePrincipal: valores.callePrincipal.toUpperCase(),
      calleSecundaria: valores.calleSecundaria.toUpperCase(),
      numero: valores.numero,
      referenciaUbicacion: valores.referenciaUbicacion.toUpperCase(),
      esActivo: true,
    };

    console.log(informacionNotificacion);

    try {
      const respuestaNotificacion = await crearNotificacion(
        informacionNotificacion
      );
      console.log(respuestaNotificacion);

      if (respuestaNotificacion.state) {
        setHabilitarDescarga(true);
        setDatosEnviadosCorrectamente(true);
        setIdInfoNotificacion(respuestaNotificacion.data);

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
        }, 1000);
      } else {
        alert(`${respuestaNotificacion.message}`);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }

    const fecha = new Date();

    const informacionSeguimientoNotificacion = {
      idSeguimiento: 0,
      idSiniestro: Number(idSiniestro),
      esSiniestro: true,
      idEstado: 2,
      idSubestado: 12,
      fechaEstado: fecha.toISOString().split(".")[0],
      esActivo: true,
    };

    try {
      const respuestaSeguimiento = await crearSeguimiento(
        informacionSeguimientoNotificacion
      );
      if (respuestaSeguimiento.state) {
        console.log(respuestaSeguimiento);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const respuestaEnvioNotificar = await notificarSiniestroCliente(
        idSiniestro
      );

      if (respuestaEnvioNotificar.state) {
        console.log(respuestaEnvioNotificar);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validarEsTitular = (esTitular) => {};

  return (
    <>
      {informacionInformeNotificacion && (
        <Formik
          /* initialValues={{
       
        esTitular: false,
        identificacion: "",
        nombreContacto: "",
        parentesco: "",
        lugarSiniestro: "",

        idUbicGeografica1: "",
        idUbicGeografica2: "",
        idUbicGeografica3: "",

        sector: "",
        callePrincipal: "",
        numero: "",
        calleSecundaria: "",
        referenciaUbicacion: "",

        telefono: "",
        celular: "",
        email: "",
      }} */

          initialValues={informacionInformeNotificacion}
          validationSchema={Yup.object({
            identificacion: Yup.string().required("Identificación requerido"),
            nombreContacto: Yup.string().required("Nombre Contacto requerido"),
            parentesco: Yup.string().required("Parentesco requerido"),
            idUbicGeografica1: Yup.string().required("Provincia requerido"),
            idUbicGeografica2: Yup.string().required("Cantón requerido"),
            idUbicGeografica3: Yup.string().required("Parroquia requerido"),
            email: Yup.string().email("Email no posee formato"),
            lugarSiniestro: Yup.string().required("Lugar Siniestro requerido"),
            celular: Yup.string()
              .min(10, "Debería tener 10 números")
              .max(10, "Debería tener 10 números")
              .required("Celular requerido"),

            telefono: Yup.string().min(9, "Debería tener 9 números"),
          })}
          validate={async (values) => {
            const errors = {};

            if (await validarFalsaData(values.celular, "CEL")) {
              errors.celular = ERROR_VALIDACION_CONTACTO;
            }

            if (await validarFalsaData(values.email, "COR")) {
              errors.email = ERROR_VALIDACION_CONTACTO;
            }

            if (await validarFalsaData(values.telefono, "CON")) {
              errors.telefono = ERROR_VALIDACION_CONTACTO;
            }

            /* if (!values.email) {
              errors.email = "Required";
            } else if (!(await verificacionAsincronoEmail(values.email))) {
              errors.email = "El email no fue encontrado";
            } */

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            configuracionDatosEnvio(values);
          }}
        >
          {({ values, handleChange, touched, errors, handleBlur }) => (
            <Form>
              <SCSAccordion tituloCabecera="Información Notificación">
                <Grid container spacing={1} rowSpacing={1}>
                  <Grid item md={12}>
                    <Grid container spacing={1} rowSpacing={1}>
                      <Grid item md={12}>
                        {/* spacing entre filas */}
                        <Grid container spacing={3}>
                          <Grid item md={12}>
                            <div className="cliente-informacion-seccion">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.esTitular}
                                      onChange={handleChange}
                                      name="esTitular"
                                    />
                                  }
                                  label="Titular"
                                />
                              </FormGroup>

                              {validarEsTitular(values.esTitular)}

                              <TextField
                                id="identificacion"
                                name="identificacion"
                                type="text"
                                label="Identificación"
                                sx={{ width: "23%" }}
                                value={values.identificacion}
                                onChange={handleChange}
                                size="small"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                error={
                                  touched.identificacion &&
                                  Boolean(errors.identificacion)
                                }
                                helperText={
                                  touched.identificacion &&
                                  errors.identificacion
                                }
                                onBlur={handleBlur}
                              />
                              <TextField
                                id="nombreContacto"
                                name="nombreContacto"
                                type="text"
                                label="Nombre Contacto"
                                sx={{ width: "38%" }}
                                value={values.nombreContacto}
                                onChange={handleChange}
                                size="small"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                error={
                                  touched.nombreContacto &&
                                  Boolean(errors.nombreContacto)
                                }
                                helperText={
                                  touched.nombreContacto &&
                                  errors.nombreContacto
                                }
                                onBlur={handleBlur}
                              />

                              <SelectItemsCatalogoSinPadre
                                pais="ECU"
                                especial="false"
                                catalogos="PARENTESCO"
                                grupo={1}
                                name="parentesco"
                                labelName="Parentesco"
                                componentSize="23%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                            <div className="cliente-informacion-seccion">
                              <SelectItemsCatalogo
                                pais="ECU"
                                especial="true"
                                catalogos="UG1"
                                codPadre={0}
                                grupo={1}
                                name="idUbicGeografica1"
                                labelName="Provincia"
                                componentSize="23%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <SelectItemsCatalogo
                                pais="ECU"
                                especial="true"
                                catalogos="UG2"
                                codPadre={values.idUbicGeografica1}
                                grupo={1}
                                name="idUbicGeografica2"
                                labelName="Cantón"
                                componentSize="23%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <SelectItemsCatalogo
                                pais="ECU"
                                especial="true"
                                catalogos="UG3"
                                codPadre={values.idUbicGeografica2}
                                grupo={1}
                                name="idUbicGeografica3"
                                labelName="Parroquia"
                                componentSize="23%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <TextField
                                id="sector"
                                name="sector"
                                type="text"
                                label="Sector"
                                sx={{ width: "23%" }}
                                value={values.sector}
                                onChange={handleChange}
                                size="small"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                              />
                            </div>

                            <div className="cliente-informacion-seccion">
                              <TextField
                                id="callePrincipal"
                                name="callePrincipal"
                                type="text"
                                label="Calle Principal"
                                value={values.callePrincipal}
                                onChange={handleChange}
                                sx={{ width: "40%" }}
                                size="small"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                              />

                              <TextField
                                id="numero"
                                name="numero"
                                label="N° Casa"
                                value={values.numero}
                                onChange={handleChange}
                                sx={{ width: "14%" }}
                                size="small"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                              />

                              <TextField
                                id="calleSecundaria"
                                name="calleSecundaria"
                                type="text"
                                label="Calle Secundaria"
                                sx={{ width: "40%" }}
                                value={values.calleSecundaria}
                                onChange={handleChange}
                                size="small"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                              />
                            </div>

                            <div className="cliente-informacion-seccion">
                              <TextField
                                id="referenciaUbicacion"
                                name="referenciaUbicacion"
                                label="Referencia"
                                value={values.referenciaUbicacion}
                                sx={{ width: "98%" }}
                                onChange={handleChange}
                                size="small"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={12}>
                    <Grid container spacing={1} rowSpacing={1}>
                      <Grid item md={12}>
                        {/* spacing entre filas */}
                        <Grid container spacing={3}>
                          <Grid item md={12}>
                            <div className="cliente-informacion-seccion">
                              <SelectItemsCatalogoSinPadre
                                pais="ECU"
                                especial="false"
                                catalogos="LUGARACCTE"
                                grupo={1}
                                name="lugarSiniestro"
                                labelName="Lugar Siniestro"
                                componentSize="23%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <FormControl
                                sx={{ width: "15%" }}
                                size="small"
                                variant="outlined"
                                error={
                                  touched.telefono && Boolean(errors.telefono)
                                }
                                helperText={touched.telefono && errors.telefono}
                                onBlur={handleBlur}
                              >
                                <InputLabel htmlFor="telefono">
                                  Télefono
                                </InputLabel>
                                <OutlinedInput
                                  value={values.telefono}
                                  onChange={handleChange}
                                  name="telefono"
                                  id="telefono"
                                  inputComponent={MaskTelefonos2}
                                  /* label es el tama;o que maneja el label */
                                  label="telefono"
                                />
                                <ErrorMessage
                                  name="telefono"
                                  component={() => (
                                    <FormHelperText>
                                      {errors.telefono}
                                    </FormHelperText>
                                  )}
                                />
                              </FormControl>

                              <FormControl
                                sx={{ width: "15%" }}
                                size="small"
                                variant="outlined"
                                error={
                                  touched.celular && Boolean(errors.celular)
                                }
                                helperText={touched.celular && errors.celular}
                                onBlur={handleBlur}
                              >
                                <InputLabel htmlFor="celular">
                                  Celular
                                </InputLabel>
                                <OutlinedInput
                                  value={values.celular}
                                  onChange={handleChange}
                                  name="celular"
                                  id="celular"
                                  inputComponent={MaskCelulares}
                                  label="celular"
                                />
                                <ErrorMessage
                                  name="celular"
                                  component={() => (
                                    <FormHelperText>
                                      {errors.celular}
                                    </FormHelperText>
                                  )}
                                />
                              </FormControl>

                              <TextField
                                id="email"
                                name="email"
                                type="text"
                                label="Email"
                                sx={{ width: "23%" }}
                                value={values.email}
                                onChange={handleChange}
                                size="small"
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <div className="confirmar-estados-servicios">
                      <SCSLoadingButton
                        size={25}
                        loading={loading}
                        titulo="GUARDAR"
                      />

                      {habilitarDescarga && (
                        <SCSGenericButton
                          titulo={"Generar Acta"}
                          size={25}
                          onClick={generarCartaNotificacion}
                        />
                      )}

                      {datosEnviadosCorrectamente && <AcceptServiceresponse />}
                    </div>
                  </Grid>
                </Grid>
              </SCSAccordion>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default FormSiniestroNotificacion;
