import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import SeguroVender from "./VenderSeguro/SeguroVender";
import ActualizarSeguro from "./ActualizarSeguro/ActualizarSeguro";

const SeguroActualizar = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Actualizar Bien - Seguro</h2>
      {/*  <SeguroVender /> */}
      <ActualizarSeguro />
    </div>
  );
};

export default SeguroActualizar;
