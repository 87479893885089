import React, { useEffect, useState } from "react";

import { Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/shared/mask/stylesTables";

const ListaPagosSeguro = ({ listaCuotas }) => {
  if (listaCuotas.length === 0) {
    return (
      <>
        <h2>No se registran pagos!!</h2>
      </>
    );
  }

  return (
    <div className="seguros-listaPagos-container">
      <TableContainer component={Paper} sx={{ width: "100%" }}>
        <Table
          sx={{ width: "100%" }}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" width="10%">
                Acción
              </StyledTableCell>
              <StyledTableCell align="center" width="15%">
                Cuota
              </StyledTableCell>
              <StyledTableCell align="center" width="10%">
                Fecha de Pago
              </StyledTableCell>
              <StyledTableCell align="center" width="10%">
                Fecha Vcto
              </StyledTableCell>
              <StyledTableCell align="center" width="10%">
                Valor
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaCuotas.map((cuotas, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {cuotas.accion}
                </StyledTableCell>
                <StyledTableCell align="center">{cuotas.cuota}</StyledTableCell>
                <StyledTableCell align="center">
                  {cuotas.fechaPago}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {cuotas.fechaVcto}
                </StyledTableCell>
                <StyledTableCell align="center">{`${cuotas.valor} $`}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ListaPagosSeguro;
