import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";

import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";

import {
  consultarListaRequerimientos,
  crearCambioEstadoRequerimiento,
} from "../services/solicitudesService";
import { formatoFecha } from "app/modules/shared/validations/validaciones";
import ModalAlertDesestimar from "../components/ModalAlertDesestimar";
import FormDesestimarRequerimiento from "../components/FormDesestimarRequerimiento";

const ListaCancelacionSeguros = ({ setSolicitud }) => {
  const [existenQuejas, setExistenQuejas] = useState(false);
  const [clienteProductos, setClienteProductos] = useState([]);
  const [updating, setUpdating] = useState(true);
  const [openDesestimar, setOpenDesestimar] = useState(false);
  const [informacionSolicitudAux, setInformacionSolicitudAux] = useState({});

  useEffect(() => {
    const getSiniestros = async () => {
      const respuestaListaRequerimientosCredito =
        await consultarListaRequerimientos(2);

      /* console.log(respuestaListaRequerimientosCredito); */

      if (
        respuestaListaRequerimientosCredito.state &&
        respuestaListaRequerimientosCredito.data
      ) {
        setClienteProductos(respuestaListaRequerimientosCredito.data);
        setExistenQuejas(true);
        /*  setClienteInfo(cliente); */
      } else {
        setExistenQuejas(true);
        setClienteProductos([]);
      }
    };
    getSiniestros();
  }, [updating]);

  const renderDetailsButton = (params) => {
    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => procesarSolicitidXtipo(params.row)}
      >
        Gestionar
      </Button>
    );
  };

  const renderDesestimarButton = (params) => {
    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => procesarDesestimar(params.row)}
      >
        Desestimar
      </Button>
    );
  };

  const columns = [
    {
      field: "idRequerimiento",
      hide: true,
      headerName: "ID",
      width: 80,
      headerClassName: "header-grid-solicitud",
    },
    {
      field: "identificacion",
      headerName: "Identificación",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
    },
    {
      field: "nombre",
      headerName: "Nombre",
      headerClassName: "header-grid-solicitud",
      width: 160,
      editable: false,
    },
    {
      field: "celular",
      headerName: "Celular",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "header-grid-solicitud",
      width: 185,
      editable: false,
    },
    {
      field: "fechaSolicitud",
      headerName: "Fecha Solicitud",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
      valueFormatter: (params) => {
        return formatoFecha(params.value);
      },
    },
    {
      field: "estado",
      headerName: "Estado",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
    },
    {
      field: "nombreLocal",
      headerName: "Nombre Local",
      headerClassName: "header-grid-solicitud",
      width: 130,
      editable: false,
    },
    {
      field: "revision",
      headerName: "Revisión",
      headerClassName: "header-grid-solicitud",
      width: 100,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
    },
    {
      field: "desestimar",
      headerName: "Desestimar",
      headerClassName: "header-grid-solicitud",
      width: 100,
      renderCell: renderDesestimarButton,
      disableClickEventBubbling: false,
    },
  ];

  const procesarDesestimar = async (solicitud) => {
    setInformacionSolicitudAux(solicitud);
    setOpenDesestimar(true);
  };

  const procesarSolicitidXtipo = async (solicitud) => {
    /* console.log(solicitud); */

    try {
      const respuestaCambioEstado = await crearCambioEstadoRequerimiento(
        solicitud.idRequerimiento,
        "ESTREQPRO"
      );

      if (respuestaCambioEstado.state) {
        setUpdating(!updating);
        /*  setSolicitud(solicitud); */
        setSolicitud(solicitud);
      } else {
        alert(`${respuestaCambioEstado.message}`);
      }
    } catch (error) {
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  const rows = clienteProductos;

  const stringMensaje = `Datos de la solicitud 
  | Nombre: ${informacionSolicitudAux.nombre} 
  | Identificación: ${informacionSolicitudAux.identificacion} 
  | Email: ${informacionSolicitudAux.email}
  | Fecha: ${informacionSolicitudAux.fechaSolicitud}`;

  return (
    <div>
      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          getRowId={(row) => row.idRequerimiento}
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>

      <ModalAlertDesestimar
        setOpen={setOpenDesestimar}
        open={openDesestimar}
        titulo="Solicitud Estado Deuda"
        mensaje={stringMensaje}
        solicitud={informacionSolicitudAux}
      >
        <FormDesestimarRequerimiento solicitud={informacionSolicitudAux} />
      </ModalAlertDesestimar>
    </div>
  );
};

export default ListaCancelacionSeguros;
