import React, { useState } from "react";
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useHistory } from "react-router";
import { MaskID } from "app/modules/shared/mask/mascarasInputs";

const BuscadorPorIdentificador = ({ traerIdColaborador }) => {
  const [idColaborador, setidColaborador] = useState();

  const navigate = useHistory();
  const handleId = (e) => {
    setidColaborador(e.target.value);
  };

  const redirectHomePage = () => {
    navigate.push("/initialHome");
  };

  return (
    <>
      <Card className="buscador-container">
        <div className="buscador-cabecera">
          <h2>Consultar información de Colaborador</h2>
        </div>
        <div className="buscador-body">
          <div className="buscador-identificacion">
            <FormControl sx={{ width: "100%" }} size="small" variant="outlined">
              <InputLabel htmlFor="buscador">Identificación</InputLabel>
              <OutlinedInput
                id="buscador"
                value={idColaborador}
                label="Identificación"
                inputComponent={MaskID}
                onChange={handleId}
                required="true"
              />
            </FormControl>
          </div>
          <div className="buscador-botones">
            <Button
              variant="contained"
              onClick={traerIdColaborador(idColaborador)}
            >
              Buscar
            </Button>

            <Button variant="contained" onClick={redirectHomePage}>
              Cancelar
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default BuscadorPorIdentificador;
