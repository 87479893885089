import React, { useEffect, useState } from "react";

import { Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/shared/mask/stylesTables";

import { consultaListaSegurosRecurrencia } from "../services/SeguroService/seguroService";

const ListaSegurosRecurenciaCliente = ({
  idCliente,
  setIdContratoAux,
  isCancel,
}) => {
  const [segurosCliente, setSegurosCliente] = useState([]);

  useEffect(() => {
    const getSeguros = async () => {
      try {
        //consultaListaSegurosRecurrencia
        const respuestaSegurosCliente = await consultaListaSegurosRecurrencia(
          idCliente
        );

        console.log(respuestaSegurosCliente);

        if (respuestaSegurosCliente.state) {
          setSegurosCliente(respuestaSegurosCliente.data);
        } else {
          setSegurosCliente([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getSeguros();
  }, []);

  const escogerIdContrato = (contrato) => {
    setIdContratoAux(contrato);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ width: "100%" }}>
        <Table
          sx={{ width: "100%" }}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell width="5%">N° Contrato</StyledTableCell>
              <StyledTableCell align="center" width="10%">
                Producto
              </StyledTableCell>
              <StyledTableCell align="center" width="15%">
                Fecha de Inicio
              </StyledTableCell>
              <StyledTableCell align="center" width="10%">
                Fecha de Fin
              </StyledTableCell>
              <StyledTableCell align="center" width="10%">
                Estado
              </StyledTableCell>
              <StyledTableCell align="center" width="10%">
                Tipo Pago
              </StyledTableCell>
              <StyledTableCell align="center" width="10%">
                Gestión
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {segurosCliente.map((contratos) => (
              <StyledTableRow key={contratos.idContrato}>
                <StyledTableCell component="th" scope="row">
                  {contratos.numContrato}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {contratos.producto}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {contratos.fechaInicio}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {contratos.fechaFin}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {contratos.estado}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {contratos.tipoPago}
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Button
                    variant="contained"
                    onClick={() => escogerIdContrato(contratos)}
                    endIcon={<SendIcon />}
                  >
                    {isCancel ? "Cancelar" : "Generar"}
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/*   {Busqueda && <h1> No existen seguros Disponibles </h1>} */}

      {/*  {!ExistenContrato && !Busqueda && (
          <h1> Por favor Presione Buscar Seguros </h1>
        )} */}
    </>
  );
};

export default ListaSegurosRecurenciaCliente;
