import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import TabAumentoCupo from "../../containers/tabAumentoCupo";
import { consultaCliente } from "app/modules/app-cliente/services/clienteService";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const AumentoCupo = () => {
  const history = useHistory();
  //console.log(history.location.state.state.identificacion)
  // const [identificacion] = location;
  const identificacion = history.location.state.state.identificacion;
  // const identificacion = location.state.identificacion;
  const [idCliente, setIdCliente] = useState(null);
  const [cliente, setCliente] = useState(null);

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  useEffect(() => {
    consultaCliente(identificacion)
      .then((resp) => {
        if (resp.state) {
          console.log(resp.data);
          setIdCliente(resp.data.idCliente);
          setCliente(resp.data);
        } else {
          setIdCliente(-1);
          setCliente({});
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div>
      {idCliente ? (
        <div className=" p-24 bg-[#ffffff]  h-full w-full ">
          <h1>
            Solicitud de credito <br /> Cédula: {identificacion}
          </h1>
          <TabAumentoCupo
            identificacion={identificacion}
            idCli={idCliente}
            clienteConsultado={cliente}
          />
        </div>
      ) : (
        <SpinnerLoadSrx />
      )}
    </div>
  );
};

export default AumentoCupo;
