import React, { useEffect, useState } from "react";

import { useHistory } from "react-router";
import { Card, Skeleton } from "@mui/material";

import { consultarDatosSolicitud } from "../services/transaccionalService";
import DetalleOpcionGenerica from "./DetalleOpcionGenerica";

import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";

const DetalleDatosSolicitud = ({
  cabenceraTransaccional,
  datosBusqueda,
  alturaResolucion,
}) => {
  const [datosSolicitud, setDatosSolicitud] = useState({});
  const [datosDevuelta, setDatosDevuelta] = useState({});

  const history = useHistory();

  const [isData, setIsData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaEstadosCredito = await consultarDatosSolicitud(
          datosBusqueda.identificacion,
          datosBusqueda.idGrupo
        );

        /*  console.log(respuestaEstadosCredito); */

        if (respuestaEstadosCredito.state && respuestaEstadosCredito.data) {
          setIsData(respuestaEstadosCredito.data.solicitud);

          const ultimaSolicitud =
            respuestaEstadosCredito.data?.solicitud.slice(-1);

          const ultimaDevuelta =
            respuestaEstadosCredito.data?.devolucion.slice(-1);

          setDatosSolicitud(ultimaSolicitud[0]);
          setDatosDevuelta(ultimaDevuelta[0]);

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={alturaResolucion}
        sx={{ borderRadius: "32px" }}
      />
    );
  }

  if (isData.length <= 0) {
    return (
      <DetalleOpcionGenerica mensaje={"No existe información del cliente."} />
    );
  }

  const columnsData = [
    { id: 1, name: "Motivo" },
    { id: 2, name: "Fecha" },
    { id: 3, name: "Devolver" },
  ];

  const handleSolicitudDevuelta = () => {
    const path = "/credito/flujop";

    history.push(path, {
      state: { identificacion: datosBusqueda.identificacion },
    });
  };

  return (
    <>
      <h2 style={{ textAlign: "center" }}>
        <strong>Datos Solicitud</strong>
      </h2>
      <Card sx={{ minHeight: "10vh", maxHeight: "auto", borderRadius: "25px" }}>
        <header className="header-datosSolicitud-information">
          <section className="header-datosSolicitud-titulo"></section>

          <section className="header-datosSolicitud-detalle">
            <div className="header-datosSolicitud-detalle-filas">
              <h4>
                <strong>N° Solicitud: </strong>
                {datosSolicitud?.numeroSolicitud
                  ? `${datosSolicitud?.numeroSolicitud}`
                  : ""}
              </h4>
            </div>
            <div className="header-datosSolicitud-detalle-filas">
              <h4>
                <strong>Fecha Solicitud: </strong>
                {datosSolicitud?.fechaSolicitud
                  ? `${datosSolicitud?.fechaSolicitud}`
                  : ""}
              </h4>
            </div>
            <div className="header-datosSolicitud-detalle-filas">
              <h4>
                <strong>Monto Solicitado: </strong>
                {datosSolicitud?.montoSolicitado
                  ? `$ ${datosSolicitud?.montoSolicitado}`
                  : ""}
              </h4>
            </div>
            <div className="header-datosSolicitud-detalle-filas">
              <h4>
                <strong>Monto Aprobado: </strong>
                {datosSolicitud?.montoAprobado
                  ? `$ ${datosSolicitud?.montoAprobado}`
                  : ""}
              </h4>
            </div>
            <div className="header-datosSolicitud-detalle-filas">
              <h4>
                <strong>Estado: </strong>
                {datosSolicitud?.estado ? `${datosSolicitud?.estado}` : ""}
              </h4>
            </div>

            <br />

            {datosSolicitud?.aumentoCupo && (
              <div className="header-datosSolicitud-detalle-filas">
                <h4>
                  <strong>Aumento Cupo: </strong>
                  {datosSolicitud?.aumentoCupo ? "APROBADO" : "NEGADO"}
                </h4>
              </div>
            )}
          </section>
        </header>
      </Card>
      {datosSolicitud?.estado === "DEVUELTA" && (
        <section style={{ marginTop: "1rem" }}>
          <Card
            sx={{
              borderRadius: "25px",
              padding: "1rem",
            }}
          >
            <h4>
              <strong>Motivo devolución:</strong>
              {` ${datosDevuelta.motivoDevolucion}`}
            </h4>
            <h4>
              <strong>Fecha devolución:</strong>
              {` ${datosDevuelta.fechaDevolucion}`}
            </h4>

            <section
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <SCSGenericButton
                size={20}
                titulo="Regularizar"
                onClick={() => handleSolicitudDevuelta()}
              />
            </section>
          </Card>
        </section>
      )}
    </>
  );
};

export default DetalleDatosSolicitud;
