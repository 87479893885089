import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import TableBody from "@mui/material/TableBody";
import SendIcon from "@mui/icons-material/Send";

import { consultarPagos } from "../Services/siniestrosService";
import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/shared/mask/stylesTables";
import { formatoFecha } from "app/modules/shared/validations/validaciones";
import { formatoFechaHora } from "app/modules/shared/commonValues/obtenerFecha";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const TablaPagosSiniestro = ({ idSiniestro, isUpdate, setIdPagoSiniestro }) => {
  const [pagosSiniestro, setPagosSiniestro] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const { state, data } = await consultarPagos(idSiniestro);

        if (state) {
          console.log(data);
          setPagosSiniestro(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setPagosSiniestro([]);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    getData();
  }, [isUpdate]);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const gestionarPagoSiniestro = (pagoSiniestro) => {
    console.log({ pagoSiniestro });
    setIdPagoSiniestro(pagoSiniestro);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Fecha</StyledTableCell>
              <StyledTableCell>Usuario</StyledTableCell>
              <StyledTableCell align="center">N° Cuota</StyledTableCell>
              <StyledTableCell>Fecha Pago</StyledTableCell>
              <StyledTableCell align="center">N° Referencia</StyledTableCell>
              <StyledTableCell align="center">Monto Pagado</StyledTableCell>
              <StyledTableCell align="center">Porcentaje</StyledTableCell>
              <StyledTableCell align="center">Titular</StyledTableCell>
              <StyledTableCell align="center">Gestionar</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pagosSiniestro.map((pago, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  {formatoFechaHora(pago.fechaCreacion)}
                </StyledTableCell>
                <StyledTableCell>{pago.usuarioCreacion}</StyledTableCell>

                <StyledTableCell component="th" scope="row" align="center">
                  {pago.numeroCuota}
                </StyledTableCell>
                <StyledTableCell>
                  {formatoFecha(pago.fechaPago)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {pago.numeroReferencia}
                </StyledTableCell>
                <StyledTableCell align="center">{`${pago.montoPagado} $`}</StyledTableCell>
                <StyledTableCell align="center">{`${pago.porcentaje} %`}</StyledTableCell>
                <StyledTableCell align="center">{`${
                  pago.esTitular ? "SI" : "NO"
                }`}</StyledTableCell>

                {pago.esTitular ? (
                  <StyledTableCell align="center">
                    <p>No Beneficiarios</p>
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center">
                    <Button
                      variant="contained"
                      onClick={() => gestionarPagoSiniestro(pago)}
                      endIcon={<SendIcon />}
                    >
                      Gestionar
                    </Button>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TablaPagosSiniestro;
