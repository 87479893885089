import React, { useEffect, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  Alert,
  Button,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { formatoFecha } from "../../shared/validations/validaciones";

import { consultaCatalogosSinPadre } from "../../app-cliente/services/Catalogos/catalogosService";

import {
  consultarBeneficiariosCliente,
  crearBeneficiarioCliente,
} from "../services/citasMedicasService";
import { MaskID } from "app/modules/shared/mask/mascarasInputs";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { LoadingButton } from "@mui/lab";

const FormBeneficiarioCitaMedica = ({
  idCliente,
  idProducto,
  identificacionCliente,
  setActualizarNuevoBeneficiario,
  setBeneficiariosCliente,
}) => {
  const [idClienteForm, setIdClienteForm] = useState([]);
  const [clienteInfo, setClienteInfo] = useState([]);
  const [updateting, setUpdateting] = useState(false);
  const [loading, setLoading] = useState(false);

  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaBeneficiarios = await consultarBeneficiariosCliente(
          identificacionCliente
        );

        if (
          respuestaBeneficiarios.state &&
          respuestaBeneficiarios.data.beneficiarios
        ) {
          setBeneficiariosCliente(respuestaBeneficiarios.data.beneficiarios);
          console.log(respuestaBeneficiarios);
        } else {
          setBeneficiariosCliente([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [updateting]);

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const validarFechaNacimiento = (fechaInicio, fechaFin, parametro) => {
    /* console.log(fechaInicio);
    console.log(fechaFin); */

    const mesesPermitidosValidos = 275;

    const formatoFechaComprobar = new Date(fechaInicio);
    const formatoFechaActual = new Date(fechaFin);

    /* console.log(formatoFechaComprobar);
    console.log(formatoFechaActual); */

    const difference = Math.abs(formatoFechaActual - formatoFechaComprobar);

    const days = difference / (1000 * 3600 * 24);
    const meses = days / 30.44;

    console.log(meses);

    if (meses <= mesesPermitidosValidos) {
      return true;
    }
    return false;
  };

  const validarFechaMayorMenor = (fechaInicio, fechaFin, parametro) => {
    /* console.log(fechaInicio);
    console.log(fechaFin); */

    const formatoFechaComprobar = new Date(fechaInicio);
    const formatoFechaActual = new Date(fechaFin);

    /* console.log(formatoFechaComprobar);
    console.log(formatoFechaActual); */

    if (parametro === "MENOR") {
      /* console.log("entra a menor"); */
      if (formatoFechaComprobar <= formatoFechaActual) {
        /*  console.log("la fecha a comprobar es menor a la fecha actual"); */
        return true;
      }
      /* console.log("la fecha a comprobar es mayor a la fecha actual"); */
      return false;
    }
    return false;
  };

  const configuraionEnvioDatos = async (valores) => {
    /* console.log(valores); */

    /* no se que es el idProducto */
    setLoading(true);

    const informacionBeneficiarios = {
      idBeneficiario: 0,
      idCliente: Number(idCliente),
      idProducto: Number(idProducto),

      tipoIdentificacion: valores.tipoIdentificacion,
      identificacion: valores.identificacion,
      primerNombre: valores.primerNombre.toUpperCase(),
      segundoNombre: valores.segundoNombre.toUpperCase(),
      primerApellido: valores.primerApellido.toUpperCase(),
      segundoApellido: valores.segundoApellido.toUpperCase(),
      fechaNacimiento: valores.fechaNacimiento,
      parentesco: valores.parentesco,

      esActivo: true,
    };

    /* console.log(informacionBeneficiarios); */

    const informacionBeneficiario = {
      identificacion: identificacionCliente,
      beneficiario: {
        idBeneficiario: 0,
        idCliente: 0,
        tipoIdentificacion: valores.tipoIdentificacion,
        identificacion: valores.identificacion,
        primerNombre: valores.primerNombre.toUpperCase(),
        segundoNombre: valores.segundoNombre.toUpperCase(),
        primerApellido: valores.primerApellido.toUpperCase(),
        segundoApellido: valores.segundoApellido.toUpperCase(),
        fechaNacimiento: valores.fechaNacimiento,
        parentesco: valores.parentesco,
        idProducto: Number(idProducto),
        esActivo: true,
      },
    };
    console.log(informacionBeneficiario);
    setActualizarNuevoBeneficiario("actualizado");

    try {
      const respuestaBeneficiarioCliente = await crearBeneficiarioCliente(
        informacionBeneficiario
      );

      if (respuestaBeneficiarioCliente.state) {
        console.log(respuestaBeneficiarioCliente);
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
          setUpdateting(!updateting);
        }, 2000);
      } else {
        setLoading(false);
        alert(`${respuestaBeneficiarioCliente.data.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <>
      {clienteInfo ? (
        <div className="accordion-container">
          <div>
            <Formik
              initialValues={{
                tipoIdentificacion: "",
                identificacion: "",
                primerNombre: "",
                segundoNombre: "",
                primerApellido: "",
                segundoApellido: "",
                fechaNacimiento: "",
                parentesco: "",
              }}
              validationSchema={Yup.object({
                tipoIdentificacion: Yup.string().required(
                  "Tipo Identificación requerido"
                ),
                identificacion: Yup.string().required(
                  "Identificación requerido"
                ),
                fechaNacimiento: Yup.string().required(
                  "Fecha Nacimiento requerido"
                ),
                primerApellido: Yup.string().required(
                  "Primer Apellido requerido"
                ),
                primerNombre: Yup.string().required("Primer Nombre requerido"),
                parentesco: Yup.string().required("Parentesco requerido"),
              })}
              validate={(values) => {
                const errors = {};

                const fechaActual = new Date();

                if (!values.fechaNacimiento) {
                  errors.fechaNacimiento = "Fecha Nacimiento requerido";
                } else if (
                  !validarFechaMayorMenor(
                    values.fechaNacimiento,
                    fechaActual,
                    "MENOR"
                  )
                ) {
                  errors.fechaNacimiento = "Fecha Nacimiento mayor a la actual";
                } /* else if (
                  !validarFechaNacimiento(
                    values.fechaNacimiento,
                    fechaActual,
                    "MENOR"
                  )
                ) {
                  errors.fechaNacimiento = "No es sujeto a beneficio";
                } */

                return errors;
              }}
              onSubmit={(valores, { resetForm }) => {
                configuraionEnvioDatos(valores);

                setTimeout(() => {
                  resetForm({ values: "" });
                }, 2000);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  <div className="accordion-container">
                    <div>
                      <Accordion
                        defaultExpanded="true"
                        sx={{ boxShadow: 3, lineHeight: 0 }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          className="accordion-summary"
                          sx={{ margin: 0 }}
                        >
                          <h2 className="formulario-cabecera">Beneficiario</h2>
                        </AccordionSummary>
                        <AccordionDetails sx={{ marginTop: "2rem" }}>
                          <Grid container spacing={1} rowSpacing={1}>
                            <Grid item md={12}>
                              <Grid container spacing={1}>
                                <Grid item md={12}>
                                  <div className="cliente-informacion-seccion">
                                    <SelectItemsCatalogoSinPadre
                                      pais="ECU"
                                      especial="false"
                                      catalogos="TIPOIDEN"
                                      grupo={1}
                                      name="tipoIdentificacion"
                                      labelName="Tipo Identificación"
                                      componentSize="14%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />

                                    <FormControl
                                      sx={{ width: "15%" }}
                                      size="small"
                                      variant="outlined"
                                      error={
                                        touched.identificacion &&
                                        Boolean(errors.identificacion)
                                      }
                                      helperText={
                                        touched.identificacion &&
                                        errors.identificacion
                                      }
                                      onBlur={handleBlur}
                                    >
                                      <InputLabel htmlFor="identificacion">
                                        Identificación
                                      </InputLabel>
                                      <OutlinedInput
                                        value={values.identificacion}
                                        onChange={handleChange}
                                        name="identificacion"
                                        id="identificacion"
                                        inputComponent={MaskID}
                                        label="identificacion"
                                      />
                                      <ErrorMessage
                                        name="identificacion"
                                        component={() => (
                                          <FormHelperText>
                                            {errors.identificacion}
                                          </FormHelperText>
                                        )}
                                      />
                                    </FormControl>

                                    <TextField
                                      id="primerApellido"
                                      name="primerApellido"
                                      type="text"
                                      label="Primer Apellido"
                                      sx={{ width: "15%" }}
                                      value={values.primerApellido}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                      error={
                                        touched.primerApellido &&
                                        Boolean(errors.primerApellido)
                                      }
                                      helperText={
                                        touched.primerApellido &&
                                        errors.primerApellido
                                      }
                                      onBlur={handleBlur}
                                    />
                                    <TextField
                                      id="segundoApellido"
                                      name="segundoApellido"
                                      type="text"
                                      label="Segundo Apellido"
                                      sx={{ width: "15%" }}
                                      value={values.segundoApellido}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                    />

                                    <TextField
                                      id="primerNombre"
                                      name="primerNombre"
                                      type="text"
                                      label="Primer Nombre"
                                      sx={{ width: "15%" }}
                                      value={values.primerNombre}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                      error={
                                        touched.primerNombre &&
                                        Boolean(errors.primerNombre)
                                      }
                                      helperText={
                                        touched.primerNombre &&
                                        errors.primerNombre
                                      }
                                      onBlur={handleBlur}
                                    />

                                    <TextField
                                      id="segundoNombre"
                                      name="segundoNombre"
                                      type="text"
                                      label="Segundo Nombre"
                                      sx={{ width: "15%" }}
                                      value={values.segundoNombre}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                    />
                                  </div>
                                  <div className="cliente-informacion-seccion">
                                    <TextField
                                      id="fechaNacimiento"
                                      name="fechaNacimiento"
                                      type="date"
                                      label="Fecha Nacimiento"
                                      sx={{ width: "16%" }}
                                      onChange={handleChange}
                                      size="small"
                                      value={formatoFecha(
                                        values.fechaNacimiento
                                      )}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={
                                        touched.fechaNacimiento &&
                                        Boolean(errors.fechaNacimiento)
                                      }
                                      helperText={
                                        touched.fechaNacimiento &&
                                        errors.fechaNacimiento
                                      }
                                      onBlur={handleBlur}
                                    />

                                    <SelectItemsCatalogoSinPadre
                                      pais="ECU"
                                      especial="false"
                                      catalogos="PARENTESCO"
                                      grupo={1}
                                      name="parentesco"
                                      labelName="Parentesco"
                                      componentSize="14%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />
                                  </div>

                                  {datosEnviadosCorrectamente && (
                                    <Alert severity="success" variant="filled">
                                      Los datos han sido guardados
                                      correctamente.
                                    </Alert>
                                  )}

                                  <div className="cliente-informacion-seccion">
                                    <LoadingButton
                                      sx={{ width: "25ch" }}
                                      type="submit"
                                      size="small"
                                      loading={loading}
                                      loadingPosition="end"
                                      variant="contained"
                                    >
                                      Guardar
                                    </LoadingButton>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <SpinnerLoadSrx />
      )}
    </>
  );
};

export default FormBeneficiarioCitaMedica;
