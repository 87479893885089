import React, { useEffect, useState } from "react";

import { Skeleton } from "@mui/material";

import {
  SCSTableData,
  SCSTableDataRow,
  SCSTableDataCell,
} from "app/modules/common/components/TableData/SCSTableData";

import {
  consultaSiniestrosTrx,
  consultarDatosSeguro,
} from "../services/transaccionalService";
import DetalleOpcionGenerica from "./DetalleOpcionGenerica";

const DetalleSiniestros = ({
  cabenceraTransaccional,
  datosBusqueda,
  alturaResolucion,
}) => {
  const [estadosCredito, setEstadosCredito] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaEstadosCredito = await consultaSiniestrosTrx(
          datosBusqueda.identificacion,
          datosBusqueda.idGrupo
        );

        /* console.log({ respuestaEstadosCredito });
         */
        if (respuestaEstadosCredito.state && respuestaEstadosCredito.data) {
          setEstadosCredito(respuestaEstadosCredito.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={alturaResolucion}
        sx={{ borderRadius: "32px" }}
      />
    );
  }

  if (estadosCredito.length <= 0) {
    return (
      <DetalleOpcionGenerica mensaje={"No existe información del cliente."} />
    );
  }

  const columnsData = [
    { id: 1, name: "N° Contrato" },
    { id: 2, name: "Cod. Siniestro" },
    { id: 3, name: "Flujo" },
    { id: 4, name: "Estado" },
    { id: 5, name: "SubEstado" },
    { id: 6, name: "Fecha Siniestro" },
    { id: 7, name: "Fecha Notificacion" },
    { id: 8, name: "Fecha Cierre" },
  ];

  return (
    <>
      <SCSTableData columnsData={columnsData} heightTable={alturaResolucion}>
        {estadosCredito?.map((siniestro) => (
          <SCSTableDataRow key={siniestro.contrato}>
            <SCSTableDataCell align="center">
              {siniestro.contrato}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {siniestro.numeroSiniestro}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {siniestro.flujo}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {siniestro.estado}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {siniestro.subEstado}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {siniestro.fechaSiniestro}
            </SCSTableDataCell>

            <SCSTableDataCell align="center">
              {siniestro.fechaNotificacion}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {siniestro.fechaCierre}
            </SCSTableDataCell>
          </SCSTableDataRow>
        ))}
      </SCSTableData>
    </>
  );
};

export default DetalleSiniestros;
