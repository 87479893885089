import React from "react";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  FormGroup,
  Box,
  Button,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
import { auth } from "@jumbo/services/auth/firebase/firebase";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_AVATARS, ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
//import code from "../Checkboxes/demo-code/checkbox-group.txt";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../../../redux/actions/Common";

import {
  onActualizarOtp,
  onConsultarCI,
  onEnviarOtpSms,
} from "../services/index";

import { grabarAceptacion } from "../../app-evaluacion/services/evaluacionServices";
import { userPhotos } from "app/shared/widgets/UserPhotos/data";
import { useState } from "react";
import { consultarGrupoGenerico } from "app/modules/app-configuracion/services/configuracionServices";
import HouseIcon from "@mui/icons-material/House";

const validationSchema = yup.object({
  celular: yup
    .string("Ingrese Celular")
    .required("Celular es Obligatorio")
    .matches(
      /^[0]{1}[1-9]{2}[0-9]{3}[0-9]{4}$/i,
      "Ingresa un número de celular válido (10 dígitos)"
    ),
  email: yup
    .string("Ingrese su email")
    .email("Ingrese email válido")
    .required("Email es obligatorio"),
  cedula: yup.string("Ingrese Cédula").required("Cédula es Obligatorio"),
});

const Welcome1 = () => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    uno: false,
    dos: false,
    tres: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { uno, dos, tres } = state;
  const error = [uno, dos, tres].filter((v) => v).length !== 3;

  const origen = localStorage.getItem("origen");
  const idGrupo = localStorage.getItem("idGrupoStorage");
  //localStorage.setItem('origen', origen);

  const [color, setColor] = useState("");
  const [url, setUrl] = useState("");

  const history = useHistory();
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    switch (origen) {
      case "tvtas":
        setColor("#FF9201");
        setUrl("https://www.tventas.com/content/55-creditventas");
        break;
      case "corpfav":
        setColor("#e1251b");
        setUrl("https://www.creditofavorito.com/");
        break;
      default:
        setColor("#e1251b");
        setUrl("https://www.creditofavorito.com/");
        break;
    }
    const obtenerGrupo = async () => {
      const resp = await consultarGrupoGenerico(origen, 1);
      if (resp.state) {
        localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
      } else {
        console.log(resp.message);
      }
    };
    obtenerGrupo();
  }, []);

  // const obtenerGrupo = async () => {
  //   const resp = await consultarGrupoGenerico(origen, 1);
  //   if (resp.state) {
  //     localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
  //   } else {
  //     console.log(resp.message);
  //   }
  // };

  function generate_uuidv4() {
    var dt = new Date().getTime();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var rnd = Math.random() * 16; //random number in range 0 to 16
        rnd = (dt + rnd) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? rnd : (rnd & 0x3) | 0x8).toString(16);
      }
    );
  }

  const mutation = useAuthSignInWithEmailAndPassword(auth, {
    onError(error) {
      console.log(error);
    },
    onSuccess(data) {
      history.push("/");
    },
  });

  const onSaveDctosCF = async (cedula, mail) => {
    debugger;
    let user = JSON.parse(sessionStorage.getItem("user"));

    const result = await axios.get("https://api.ipify.org");
    let ipAdress = result.data;

    //let ipAdress = generate_uuidv4();
    let datos = {
      identificacion: cedula,
      idGrupo: parseInt(idGrupo),
      fechaConsulta: new Date(), //"6/28/2023, 5:26:31 PM",
      usuario: user.key,
      estacion: ipAdress,
      enviaDatos: false,
      tcu: true,
      ppr: true,
      bur: true,
      esCliente: true,
      documentosFirmados: false,
      email: mail,
    };

    let response = await grabarAceptacion(datos);
    if (!response.state) {
      alert(response.message);
    }
  };

  const onSignIn = async (email, cedula, celular) => {
    debugger;
    let tipocliente = localStorage.getItem("tipocliente");
    if (tipocliente == "NUEVO") {
      const datosPersona = await onConsultarCI(cedula);
      localStorage.setItem("cd", datosPersona.data.codigoDactilar);

      let apellidos =
        datosPersona.data.primerApellido +
        " " +
        datosPersona.data.segundoApellido;
      let nombres =
        datosPersona.data.primerNombre + " " + datosPersona.data.segundoNombre;

      localStorage.setItem("ap", apellidos);
      localStorage.setItem("nm", nombres);

      //debugger;
      //history.push("/auth-pages/datos");
    }
    
    let response = await onActualizarOtp(email, cedula);
    if (response.state) {
      history.push("/auth-pages/otpregistro");
      debugger;
      let nombres =
        localStorage.getItem("ap") + " " + localStorage.getItem("nm");
        console.log("otp");
      //envia otp celular
      onEnviarOtpSms(nombres, response.data, celular, cedula);


      //graba datos corporacion
      localStorage.setItem("cedula", cedula);
      localStorage.setItem("email", email);
      localStorage.setItem("celular", celular);

      onSaveDctosCF(cedula, email);

      
    } else {
      //alert(response.message);
      dispatch(fetchError(response.message));
    }
  };

  return (
    <Div
      sx={{
        flex: 1,
        marginTop: 15,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(4),
      }}
    >
      <Box
        component="img"
        src={`${process.env.REACT_APP_IMAGES_PATH}/logo-${origen}.png`}
        sx={{
          justifyContent: "center",
          width: 350,
          height: 140,
        }}
      />
      {/* <Div sx={{mb: 3, display: 'inline-flex'}}>
                <Link
                    href="#"
                    underline="none"
                    sx={{display: 'inline-flex'}}
                >
                    <img src={`${ASSET_IMAGES}/logo.png`} alt="Jumbo React"/>
                </Link>
            </Div> */}
      <Card sx={{ maxWidth: "100%", width: 360, mb: 4 }}>
        <Div sx={{ position: "relative", height: "200px" }}>
          <CardMedia
            component="img"
            height="200"
            sx={{ backgroundColor: color }}
          />
          <Div
            sx={{
              flex: 1,
              inset: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              p: (theme) => theme.spacing(3),
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                color: "common.white",
                fontSize: "1.5rem",
                mb: 0,
              }}
            >
              Bienvenido
            </Typography>
          </Div>
        </Div>
        <CardContent sx={{ pt: 0 }}>
          <Formik
            validateOnChange={true}
            initialValues={{
              email: "",
              cedula: localStorage.getItem("cedula"),
              celular: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              onSignIn(data.email, data.cedula, data.celular);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                {/* <Avatar
                  sx={{
                    width: 56,
                    height: 56,
                    marginLeft: "auto",
                    boxShadow: shadows[3],
                    transform: "translateY(-50%)",
                  }}
                /> */}
                {mutation.isError && <p>{mutation.error.message}</p>}
                <Div sx={{ mb: 2, mt: 1, marginTop: 5 }}>
                  <JumboTextField
                    fullWidth
                    name="cedula"
                    label="Cedula"
                    //type="password"
                  />
                </Div>
                <Div sx={{ mb: 3, mt: 1 }}>
                  <JumboTextField fullWidth name="email" label="Email" />
                </Div>
                <Div sx={{ mb: 2, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="celular"
                    label="Número Celular"
                  />
                </Div>

                <FormControl
                  required
                  error={error}
                  component="fieldset"
                  sx={{ m: 1 }}
                  variant="standard"
                >
                  <FormLabel component="legend">
                    Políticas de aceptación de servicio
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ alignItems: "flex-start", mb: 1 }}
                      control={
                        <Checkbox
                          sx={{
                            marginTop: -1,
                          }}
                          checked={uno}
                          onChange={handleChange}
                          name="uno"
                        />
                      }
                      label="Acepto los Términos y condiciones de uso"
                    />
                    <FormControlLabel
                      sx={{ alignItems: "flex-start", mb: 1 }}
                      control={
                        <Checkbox
                          sx={{
                            marginTop: -1,
                          }}
                          checked={dos}
                          onChange={handleChange}
                          name="dos"
                        />
                      }
                      label="Acepto los tratamientos de mis datos personales"
                    />
                    <FormControlLabel
                      sx={{ alignItems: "flex-start", mb: 2 }}
                      control={
                        <Checkbox
                          sx={{
                            marginTop: -1,
                          }}
                          checked={tres}
                          onChange={handleChange}
                          name="tres"
                        />
                      }
                      label="Autorizo a consultar referencias crediticias en el Buró de Crédito"
                    />
                  </FormGroup>
                  {/* <FormHelperText>You can display an error</FormHelperText> */}
                </FormControl>
                <LoadingButton
                  disabled={error}
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting || mutation.isLoading}
                >
                  CONTINUAR
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
      <Button
        size="lg"
        variant="outlined"
        startIcon={<HouseIcon />}
        onClick={() => {
          window.open(url, "_self");
        }}
      >
        <Typography> Regresar a la pagina Principal</Typography>
      </Button>
    </Div>
  );
};

export default Welcome1;
