import React, { useEffect, useState } from "react";
import { useFormikContext, Formik, Form, Field, useField } from "formik";
import { useSelector } from "react-redux";

import { Alert, Button, Grid, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  grabarDocumento,
  cambiarEstadoSolicitud,
  obtenerDatosAprobacion,
  grabarDatosAprobacion,
} from "../services/creditoServices";

import "../styles/main.css";

const FormSubirDocumentosCrediweb = ({ idSol, handleSave }) => {
  const { authUser } = useSelector(({ auth }) => auth);

  const [clienteInfo, setClienteInfo] = useState(null);
  const [idSolicitudActual, setIdSolicitudActual] = useState(null);
  const [docBase64, setDocBase64] = useState("");
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [alertaGuardar, setAlertaGuardar] = useState(false);

  useEffect(() => {
    console.log(idSol);
    console.log("AQUI", authUser);
  }, []);

  const fileToBase64 = async (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);

  const changeHandler = (event) => {
    let doc = "";
    let docByte = [];
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
    fileToBase64(event.target.files[0], (err, result) => {
      if (result) {
        doc = result.split(",");
        docByte = base64ToArrayBuffer(doc[1]);
        //console.log(doc[1]);
        setDocBase64(doc[1]);
      }
    });
  };

  const base64ToArrayBuffer = (base64) => {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const configuracionDatosEnvio = async (values) => {
    let formatTwoDigits = (digit) => ("0" + digit).slice(-2);
    var tempDate = new Date();
    var date = `${tempDate.getFullYear()}${formatTwoDigits(
      tempDate.getMonth() + 1
    )}${formatTwoDigits(tempDate.getDate())}`;

    const documento = {
      idSolicitud: idSol,
      origen: "FW",
      imgDocumento: docBase64,
      nombreDocumento: `SLC_${idSol}_${date}.pdf`,
      usuarioCreacion: authUser.key,
      estacionCreacion: authUser.estacion,
      usuarioModificacion: authUser.key,
      estacionModificacion: authUser.estacion,
    };
    console.log(documento);

    try {
      console.log("Envio de documento a base");

      await grabarDocumento(documento);
      await cambiarEstadoSolicitud(idSol, 11, authUser.key, authUser.estacion);

      try {
        const srvDatosAprob = await obtenerDatosAprobacion(idSol);
        if (srvDatosAprob.state) {
          const datosAprob = srvDatosAprob.data;
          const respuestaDatosAprobacion = grabarDatosAprobacion(datosAprob);
        }
      } catch (error) {
        console.log(error);
      }

      setDatosEnviadosCorrectamente(true);

      setTimeout(() => {
        setDatosEnviadosCorrectamente(false);
      }, 3000);

      handleSave();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          fechaCorte: "1",
        }}
        onSubmit={(values, { setSubmitting }) => {
          // console.log(values);
          configuracionDatosEnvio(values);
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <div className="accordion-container">
              {/* Documento PDF */}
              <div>
                <Accordion
                  defaultExpanded="true"
                  sx={{ boxShadow: 3, lineHeight: 0 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className="accordion-summary"
                  >
                    <h2 className="formulario-cabecera">Documentacion</h2>
                  </AccordionSummary>
                  <AccordionDetails sx={{ marginTop: "2rem" }}>
                    <Grid container justifyContent="center">
                      <Grid item xl={12}>
                        <Grid
                          container
                          spacing={15}
                          rowSpacing={4}
                          justifyContent="center"
                        >
                          <Grid item xl={12}>
                            <Button variant="contained" component="label">
                              Seleccionar Archivo
                              <input
                                hidden
                                type="file"
                                accept="application/pdf"
                                name="documento"
                                onChange={changeHandler}
                              />
                            </Button>
                          </Grid>
                          <Grid item lg={12}>
                            {isSelected ? (
                              <div>
                                <Grid item lg={4}>
                                  <p>
                                    Nombre de archivo:{" "}
                                    {selectedFile ? selectedFile.name : ""}
                                  </p>
                                  <p>
                                    Tipo:{" "}
                                    {selectedFile ? selectedFile.type : ""}
                                  </p>
                                </Grid>
                              </div>
                            ) : (
                              <p>Suba el documento digitalizado </p>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
              {datosEnviadosCorrectamente && (
                <Alert severity="success" variant="filled">
                  Los datos han sido guardados correctamente.
                </Alert>
              )}
              {alertaGuardar && (
                <Alert severity="error" variant="filled">
                  Ya existe una solicitud creada.
                </Alert>
              )}
              <Stack
                sx={{ marginTop: "1.5ch" }}
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent="center"
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ width: "25ch" }}
                  /* className="accordion-item" */
                >
                  {false ? "Actualizar" : "Guardar"}
                </Button>
              </Stack>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormSubirDocumentosCrediweb;
