import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import SendIcon from "@mui/icons-material/Send";

import { consultaSegurosBienes } from "../../Services/siniestrosService";
import { formatoFecha } from "../../../shared/validations/validaciones";

import {
  SCSTableData,
  SCSTableDataCell,
  SCSTableDataRow,
} from "app/modules/common/components/TableData/SCSTableData";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const TipoSiniestros = ({ idCliente, identificador, datosBusqueda }) => {
  const [existenSiniestros, setExistenSiniestros] = useState(false);
  const [clienteSiniestros, setClienteSiniestros] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useHistory();

  useEffect(() => {
    const getSiniestros = async () => {
      if (idCliente < 0) {
        setExistenSiniestros(false);
        setIsLoading(false);
      } else {
        try {
          const respuestaProductosSeguros = await consultaSegurosBienes(
            idCliente,
            datosBusqueda.idGrupo
          );

          /* console.log(respuestaProductosSeguros); */

          if (respuestaProductosSeguros.state) {
            setClienteSiniestros(respuestaProductosSeguros.data);
            setExistenSiniestros(true);
            setIsLoading(false);
          } else {
            setClienteSiniestros([]);
            setExistenSiniestros(true);
            setIsLoading(false);
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }
    };
    getSiniestros();
  }, []);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  if (!existenSiniestros) {
    const redirectToSinSeguro = () => {
      const path = "/siniestros/crear/tiposSiniestro/sinSeguro";
      navigate.push(path, { state: { identificacion: identificador } });
    };

    return (
      <div className="tipoSiniestro-container">
        <div className="tipoSiniestro-siniestros">
          <h3>No existen registros de productos a siniestrar!!</h3>
        </div>

        <div className="tipoSiniestro-tipos">
          <button
            className="tipoSiniestro-tipos-sinseguro"
            onClick={redirectToSinSeguro}
          >
            <img src={"/images/logo/SinSeguro.png"} alt="logo_sin_seguro" />
            Sin Seguro
          </button>
        </div>
      </div>
    );
  }

  const redirectToSinSeguro = () => {
    const path = "/siniestros/crear/tiposSiniestro/sinSeguro";
    navigate.push(path, { state: { identificacion: identificador } });
  };

  const escogerIdSiniestro = (idContrato) => {
    /* console.log(idContrato); */
    const path = "/siniestros/crear/tiposSiniestro/conSeguro";
    /*  navigate.push(
      `/siniestros/crear/tiposSiniestro/conSeguro/${idContrato}/cliente/${idCliente}`
    ); */
    navigate.push(path, {
      state: {
        cliente: idCliente,
        contrato: idContrato,
        idGrupoSiniestro: datosBusqueda.idGrupo,
      },
    });
  };

  const columnsData = [
    { id: 1, name: "Grupo" },
    { id: 2, name: "N° Contrato" },
    { id: 3, name: "Producto" },
    { id: 3, name: "Artículo" },
    { id: 4, name: "Fecha de Compra" },
    { id: 5, name: "Valor" },
    { id: 6, name: "Siniestrar" },
  ];

  return (
    <>
      <div className="tipoSiniestro-container">
        <div className="tipoSiniestro-siniestros">
          {clienteSiniestros.length > 0 ? (
            <SCSTableData columnsData={columnsData} heightTable={35}>
              {clienteSiniestros.map((siniestro) => (
                <SCSTableDataRow key={siniestro.numeroContrato}>
                  <SCSTableDataCell align="center">
                    {siniestro.grupo}
                  </SCSTableDataCell>
                  <SCSTableDataCell align="center">
                    {siniestro.numeroContrato}
                  </SCSTableDataCell>

                  <SCSTableDataCell component="th" scope="row">
                    {siniestro.producto}
                  </SCSTableDataCell>

                  <SCSTableDataCell align="center">
                    {siniestro.descripcionBien}
                  </SCSTableDataCell>
                  <SCSTableDataCell align="center">
                    {formatoFecha(siniestro.fechaInicio)}
                  </SCSTableDataCell>
                  <SCSTableDataCell align="center">
                    {`$${siniestro.valorBien}`}
                  </SCSTableDataCell>

                  <SCSTableDataCell align="center">
                    <SCSGenericButton
                      size={100}
                      titulo="Siniestrar"
                      onClick={() => escogerIdSiniestro(siniestro.idContrato)}
                      endIcon={<SendIcon />}
                    />
                  </SCSTableDataCell>
                </SCSTableDataRow>
              ))}
            </SCSTableData>
          ) : (
            <div className="tipoSiniestro-siniestros">
              <h3>No existen registros de productos a siniestrar!!</h3>
            </div>
          )}
        </div>

        <div className="tipoSiniestro-tipos">
          <button
            className="tipoSiniestro-tipos-sinseguro"
            onClick={redirectToSinSeguro}
          >
            <img src={"/images/logo/SinSeguro.png"} alt="logo_sin_seguro" />
            Sin Seguro
          </button>
        </div>
      </div>
    </>
  );
};

export default TipoSiniestros;
