import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import ConsultarCliente from "./ConsultarCliente/ConsultarCliente";

const ClienteConsultar = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <>
      <h2 className="titulosPrincipales-container">Cliente - Información</h2>
      <ConsultarCliente />
    </>
  );
};

export default ClienteConsultar;
