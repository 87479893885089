import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ChatSideContext from "../context/ChatSideContext";

import DetalleCoversacionesPorUsuario from "./DetalleCoversacionesPorUsuario";
import ModalPreguntasSugeridas from "app/modules/app-comunicaciones/components/ModalPreguntasSugeridas";

import {
  consultarClientesPorUsuario,
  crearGestionMensaje,
} from "app/modules/app-comunicaciones/services/comunicacionesService";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import { diccionarioGrupo } from "app/modules/shared/dictionary/extensionDocumentos";
import SkeletonConversaciones from "../components/SkeletonConversaciones";

const DetalleSeccionConversaciones = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { informacionChatSide, setInformacionChatSide } =
    useContext(ChatSideContext);

  const [isLoading, setIsLoading] = useState(true);
  const [conversaccion, setConversaccion] = useState([]);
  const [updatedChat, setUpdatedChat] = useState(false);
  const [updateHubConversacion, setUpdateHubConversacion] = useState(false);

  /* MANEJO DE MODAL - CREAR CHAT */
  const [open, setOpen] = useState(false);
  const [updatePreguntas, setUpdatePreguntas] = useState(false);
  const [loading, setLoading] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const latestConversaccion = useRef(null);
  latestConversaccion.current = conversaccion;

  useEffect(() => {
    const getClientesPorUsuarios = async () => {
      try {
        const respuestaClientesXUsuario = await consultarClientesPorUsuario(
          authUser.idUsuario,
          !informacionChatSide.isCallCenter
        );

        /* console.log(respuestaClientesXUsuario); */

        if (respuestaClientesXUsuario.state) {
          const listaConversaciones = respuestaClientesXUsuario.data.map(
            (conversacion, index) => ({
              ...conversacion,
              isSelected: false,
            })
          );

          setConversaccion(listaConversaciones);
          setUpdateHubConversacion(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getClientesPorUsuarios();
  }, [updatedChat, updateHubConversacion]);

  useEffect(() => {
    if (informacionChatSide?.connectionHub) {
      informacionChatSide?.connectionHub.on("NuevaConversacion", (message) => {
        const updatedChat = [...latestConversaccion.current];
        updatedChat.push(message);

        setConversaccion(updatedChat);
      });
    }
  }, [informacionChatSide?.connectionHub]);

  useEffect(() => {
    if (informacionChatSide?.connectionHub) {
      informacionChatSide?.connectionHub.on(
        "ActualizarConversacion",
        (message) => {
          setUpdateHubConversacion(message);
        }
      );
    }
  }, [informacionChatSide?.connectionHub]);

  if (isLoading) {
    return <SkeletonConversaciones />;
  }

  const gestionModalNuevaConversacion = () => {
    setUpdatePreguntas(!updatePreguntas);
    setOpen(true);
  };

  const crearNuevaConversaccionHub = async (
    idOpcionConversacion,
    listaPreguntas
  ) => {
    setLoading(true);
    const preguntaConversacion = listaPreguntas.filter(
      (pregunta) =>
        pregunta.idTipoPregunta === idOpcionConversacion.opcionConversacion
    );
    const informacionConversacion = {
      idMensaje: 0,
      codigoEmisor: authUser.idUsuario.toString(),
      /* idReceptor: Number(repuestaUsuarioDisponible.data), */
      idReceptor: Number(1551),
      esExterno: false,
      espacio: "servimax-Interno",
      origen: diccionarioGrupo(Number(localStorage.getItem("idGrupoStorage"))),
      comentario: preguntaConversacion[0].tipoPregunta,
      esActivo: true,
    };
    console.log(informacionConversacion);

    if (informacionChatSide?.connectionHub._connectionStarted) {
      let respAux;
      try {
        respAux = await informacionChatSide?.connectionHub.invoke(
          "IniciarConversacion",
          informacionConversacion
        );
        console.log(respAux);

        try {
          const informacionGestionMensaje = {
            idGestionMensaje: 0,
            idMensaje: Number(respAux?.idMensaje),
            idEnviaMsje: Number(authUser.idUsuario), //idusuario - logeado
            mensaje: preguntaConversacion[0].mensaje,
            esEmisor: false,
            esActivo: true,
          };
          console.log(informacionGestionMensaje);
          //const respuestaGestionMensaje
          const respuestaGestionMensaje = await crearGestionMensaje(
            informacionGestionMensaje
          );

          console.log({ respuestaGestionMensaje });

          if (respuestaGestionMensaje.state) {
            setTimeout(() => {
              setDatosEnviadosCorrectamente(false);
              setLoading(false);
              setOpen(false);
              setUpdatedChat(!updatedChat);
            }, 1000);
          }
        } catch (error) {
          alert("No existen agentes disponibles !!");
          console.log(error);
        }
      } catch (e) {
        alert("No existen agentes disponibles !!");
        console.log(e);
      }
    } else {
      alert("No existen agentes disponibles !!");
    }
  };

  return (
    <>
      <section style={{ marginTop: "2.5rem" }}>
        <section
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SCSLoadingButton
            titulo="Crear Chat"
            onClick={gestionModalNuevaConversacion}
            size={50}
          />
        </section>

        <DetalleCoversacionesPorUsuario conversaccion={conversaccion} />
      </section>

      <ModalPreguntasSugeridas
        open={open}
        setOpen={setOpen}
        updatePreguntas={updatePreguntas}
        crearNuevaConversaccionHub={crearNuevaConversaccionHub}
        loading={loading}
        datosEnviadosCorrectamente={datosEnviadosCorrectamente}
      />
    </>
  );
};

export default DetalleSeccionConversaciones;
