import React from "react";
import FormCitasMedicas from "../components/FormCitasMedicas";

const TitularCitaMedica = ({ idCliente, idProducto }) => {
  return (
    <div>
      <FormCitasMedicas
        idCliente={idCliente}
        idProducto={idProducto}
        idPaciente={idCliente}
      />
    </div>
  );
};

export default TitularCitaMedica;
 