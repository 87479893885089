import React, { useEffect, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";

/* import { IMaskInput } from "react-imask"; */
import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  consultaUbiGeografica1,
  consultaCatalogosSinPadre,
} from "../../app-cliente/services/Catalogos/catalogosService";

import {
  consultarCiudadesCitas,
  consultarPrestadorMedico,
  crearPrestadorMedico,
} from "../services/citasMedicasService";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

/* const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="0000000000000"
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
}); */

const FormPrestadorMedico = ({ NRUC }) => {
  const [prestador, setPrestador] = useState(155);
  const [prestadorMedicInfo, setPrestadorMedicInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [existePrestador, setExistePrestador] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  /* obtener ciudades que es para citas medicas */
  useEffect(() => {
    console.log(NRUC);

    const getData = async () => {
      try {
        const respuestaCiudadesCitas = await consultarCiudadesCitas();

        console.log(respuestaCiudadesCitas.ubicGeografica);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    console.log(NRUC);

    const getData = async () => {
      try {
        const respuestaPrestadorMedico = await consultarPrestadorMedico(NRUC);

        if (respuestaPrestadorMedico.state) {
          setPrestadorMedicInfo(respuestaPrestadorMedico.data);
          setIsLoading(false);
          setExistePrestador(true);
          console.log(respuestaPrestadorMedico);
        } else {
          setIsLoading(false);
          setPrestadorMedicInfo({});
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      if (pais === "ECU") {
        const getData = async () => {
          const prov = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          console.log("estamos aqui");
          console.log(prov);

          setProvincias(prov.data);
        };
        getData();
      }
    }, [pais]);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {pais === "ECU" ? (
            provincias.map((provincia, index) => (
              <MenuItem key={index} value={provincia.codigo}>
                {provincia.descripcion}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">none</MenuItem>
          )}
        </Field>
        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsCatalogo = ({
    pais,
    especial,
    catalogos,
    codPadre,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      if (pais === "ECU") {
        const getData = async () => {
          const prov = await consultaUbiGeografica1(
            especial,
            catalogos,
            codPadre,
            grupo
          );

          setProvincias(prov.data);
        };
        getData();
      }
    }, [pais, codPadre]);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {pais === "ECU" ? (
            provincias.map((provincia, index) => (
              <MenuItem key={index} value={provincia.codigo}>
                {provincia.descripcion}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">none</MenuItem>
          )}
        </Field>
        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const configuracionDatosEnvio = async (valores) => {
    const informacionPrestadorMedico = {
      idPrestadorMedico: prestadorMedicInfo.idPrestadorMedico || 0,
      ruc: valores.ruc || "",
      nombre: valores.nombre.toUpperCase() || "",
      tipoPrestadorMedico: valores.tipoPrestadorMedico || "",
      idUbicGeografica1: Number(valores.idUbicGeografica1),
      idUbicgeografica2: Number(valores.idUbicgeografica2),
      direccion: valores.direccion.toUpperCase() || "",
      sector: valores.sector.toUpperCase() || "",
      zona: valores.zona || "",
      contacto: valores.contacto.toUpperCase() || "",
      telefonoContacto: valores.telefonoContacto || "",
      telefonoClinica: valores.telefonoClinica || "",
      email: valores.email || "",
      esActivo: valores.esActivo,

      /*  fechaCreacion: valores.fechaCreacion || "",
      usuarioCreacion: valores.usuarioCreacion || "",
      estacionCreacion: valores.estacionCreacion || "",
      fechaModificacion: valores.fechaModificacion || "",
      usuarioModificacion: valores.usuarioModificacion || "",
      estacionModificacion: valores.estacionModificacion || "", */
    };
    console.log(informacionPrestadorMedico);

    try {
      const respuestaCrearPrestadorMedico = await crearPrestadorMedico(
        informacionPrestadorMedico
      );

      console.log(respuestaCrearPrestadorMedico);

      setDatosEnviadosCorrectamente(true);

      setTimeout(() => {
        setDatosEnviadosCorrectamente(false);
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <BotonGerericoSalir />
      <Formik
        initialValues={{
          ruc: prestadorMedicInfo.ruc || "",
          nombre: prestadorMedicInfo.nombre || "",
          tipoPrestadorMedico: prestadorMedicInfo.tipoPrestadorMedico || "",
          idUbicGeografica1: prestadorMedicInfo.idUbicGeografica1 || "",
          idUbicgeografica2: prestadorMedicInfo.idUbicgeografica2 || "",
          direccion: prestadorMedicInfo.direccion || "",
          sector: prestadorMedicInfo.sector || "",

          zona: prestadorMedicInfo.zona || "",
          contacto: prestadorMedicInfo.contacto || "",
          telefonoContacto: prestadorMedicInfo.telefonoContacto || "",
          telefonoClinica: prestadorMedicInfo.telefonoClinica || "",
          email: prestadorMedicInfo.email || "",

          esActivo: prestadorMedicInfo.esActivo ?? true,
        }}
        validationSchema={Yup.object({
          ruc: Yup.string().required("RUC requerido"),
          nombre: Yup.string().required("Nombre requerido"),
          tipoPrestadorMedico: Yup.string().required("Tipo requerido"),
          zona: Yup.string().required("Zona requerido"),
          idUbicGeografica1: Yup.string().required("Provincia requerida"),
          idUbicgeografica2: Yup.string().required("Cantón requerida"),
          email: Yup.string().email("Email no tiene formato"),
        })}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          configuracionDatosEnvio(values);
        }}
      >
        {({ values, handleChange, touched, errors, handleBlur }) => (
          <Form>
            <div className="accordion-container">
              <div>
                <Accordion
                  sx={{ boxShadow: 3, lineHeight: 0 }}
                  defaultExpanded="true"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className="accordion-summary"
                    sx={{ margin: 0 }}
                  >
                    <h2 className="formulario-cabecera">Prestador Médico</h2>
                  </AccordionSummary>

                  <AccordionDetails sx={{ marginTop: "0rem" }}>
                    <Grid container spacing={1} rowSpacing={1}>
                      <Grid item md={12}>
                        <Grid container spacing={1} rowSpacing={1}>
                          <Grid item md={12}>
                            {/* spacing entre filas */}
                            <Grid container spacing={1}>
                              <Grid item md={12}>
                                <div className="cliente-informacion-seccion">
                                  <TextField
                                    id="ruc"
                                    name="ruc"
                                    type="text"
                                    label="RUC"
                                    sx={{ width: "15%" }}
                                    value={values.ruc}
                                    onChange={handleChange}
                                    size="small"
                                    error={touched.ruc && Boolean(errors.ruc)}
                                    helperText={touched.ruc && errors.ruc}
                                    onBlur={handleBlur}
                                  />

                                  {/*     <FormControl variant="outlined">
                                    <InputLabel>RUC</InputLabel>
                                    <Input
                                      value={values.ruc}
                                      onChange={handleChange}
                                      name="ruc"
                                      id="ruc"
                                      inputComponent={TextMaskCustom}
                                    />
                                  </FormControl> */}

                                  <TextField
                                    id="nombre"
                                    name="nombre"
                                    type="text"
                                    label="Nombre"
                                    sx={{ width: "37%" }}
                                    value={values.nombre}
                                    onChange={handleChange}
                                    size="small"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                    error={
                                      touched.nombre && Boolean(errors.nombre)
                                    }
                                    helperText={touched.nombre && errors.nombre}
                                    onBlur={handleBlur}
                                  />

                                  <SelectItemsCatalogoSinPadre
                                    pais="ECU"
                                    especial="false"
                                    catalogos="TIPOPRESTMED"
                                    grupo={1}
                                    name="tipoPrestadorMedico"
                                    labelName="Tipo Prestador Médico"
                                    componentSize="20%"
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                  />
                                  <SelectItemsCatalogoSinPadre
                                    pais="ECU"
                                    especial="false"
                                    catalogos="ZONGEO"
                                    grupo={1}
                                    name="zona"
                                    labelName="Zona"
                                    componentSize="20%"
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </div>

                                <div className="cliente-informacion-seccion">
                                  <SelectItemsCatalogo
                                    pais="ECU"
                                    especial="true"
                                    catalogos="UG1"
                                    codPadre={0}
                                    grupo={1}
                                    name="idUbicGeografica1"
                                    labelName="Provincia"
                                    componentSize="20%"
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                  />

                                  <SelectItemsCatalogo
                                    pais="ECU"
                                    especial="true"
                                    catalogos="UG2"
                                    codPadre={values.idUbicGeografica1}
                                    grupo={1}
                                    name="idUbicgeografica2"
                                    labelName="Cantón"
                                    componentSize="18%"
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                  />

                                  <TextField
                                    id="sector"
                                    name="sector"
                                    type="text"
                                    label="Sector"
                                    sx={{ width: "17%" }}
                                    value={values.sector}
                                    onChange={handleChange}
                                    size="small"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />

                                  <TextField
                                    id="direccion"
                                    name="direccion"
                                    type="text"
                                    label="Dirección"
                                    sx={{ width: "37%" }}
                                    value={values.direccion}
                                    onChange={handleChange}
                                    size="small"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                </div>

                                <div className="cliente-informacion-seccion">
                                  <TextField
                                    id="contacto"
                                    name="contacto"
                                    type="text"
                                    label="Contacto"
                                    sx={{ width: "30%" }}
                                    value={values.contacto}
                                    onChange={handleChange}
                                    size="small"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />

                                  <TextField
                                    id="telefonoContacto"
                                    name="telefonoContacto"
                                    type="text"
                                    label="Telefono Contacto"
                                    sx={{ width: "17%" }}
                                    value={values.telefonoContacto}
                                    onChange={handleChange}
                                    size="small"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                  <TextField
                                    id="telefonoClinica"
                                    name="telefonoClinica"
                                    type="text"
                                    label="Telefono Clinica"
                                    sx={{ width: "17%" }}
                                    value={values.telefonoClinica}
                                    onChange={handleChange}
                                    size="small"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                  />
                                  <TextField
                                    id="email"
                                    name="email"
                                    type="text"
                                    label="Email"
                                    sx={{ width: "28%" }}
                                    value={values.email}
                                    onChange={handleChange}
                                    size="small"
                                    error={
                                      touched.email && Boolean(errors.email)
                                    }
                                    helperText={touched.email && errors.email}
                                    onBlur={handleBlur}
                                  />

                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={values.esActivo}
                                          onChange={handleChange}
                                          name="esActivo"
                                        />
                                      }
                                      label="Activo"
                                    />
                                  </FormGroup>
                                </div>
                              </Grid>
                            </Grid>

                            {datosEnviadosCorrectamente && (
                              <Alert severity="success" variant="filled">
                                Los datos han sido guardados correctamente.
                              </Alert>
                            )}
                          </Grid>
                        </Grid>

                        <div className="cliente-informacion-seccion">
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{ width: "25ch" }}
                            /* className="accordion-item" */
                          >
                            {existePrestador ? "Actualizar" : "Guardar"}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPrestadorMedico;
