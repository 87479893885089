import React, { useEffect, useState } from "react";

import BuscadorPorIdentificador from "../../../app-cliente/containers/BuscadorPorIdentificador";
import ListaQuejasCliente from "../../components/ListaQuejasCliente";
import DetalleCerrarQueja from "../../containers/DetalleCerrarQueja";

import useQuejasLocalStorage from "../../hooks/useQuejasLocalStorage";

import { consultaCliente } from "../../../app-cliente/services/clienteService";
import BotonSalirLimiarStorageQuejas from "../../containers/BotonSalirLimiarStorageQuejas";

const QuejasCerrar = () => {
  const [cliente, setCliente] = useState(null);
  const [idCliente, setIdCliente] = useState("");
  const [mostrarDetalle, setMostrarDetalle] = useState(true);
  const [idQuejaAux, setIdQuejaAux] = useState(null);
  const [cabeceraQueja, setCabeceraQueja] = useState({});

  const [idQuejaStorage, setIdQuejaStorage] = useQuejasLocalStorage(
    "idQuejaStorage",
    null
  );

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  useEffect(() => {
    /* console.log(idQuejaStorage); */
    if (idQuejaStorage) {
      setIdQuejaAux(idQuejaStorage);
      setMostrarDetalle(false);
    }
  }, [idQuejaAux, setIdQuejaAux]);

  const buscarExistenciaCliente = async (identificador) => {
    try {
      const respuestaCliente = await consultaCliente(identificador);

      if (respuestaCliente.state) {
        setIdCliente(respuestaCliente.data.idCliente);
        setCliente(respuestaCliente.data);
        setMostrarDetalle(false);
      } else {
        setCliente(nuevoCliente);
        setIdCliente(nuevoIdCliente);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdCliente = (id) => () => {
    /*   console.log(id); */
    buscarExistenciaCliente(id);
  };

  if (mostrarDetalle) {
    return <BuscadorPorIdentificador traerIdCliente={traerIdCliente} />;
  }

  return (
    <div>
      <BotonSalirLimiarStorageQuejas />

      {idQuejaAux ? (
        <DetalleCerrarQueja
          idCliente={idCliente}
          idQueja={idQuejaAux}
          cabeceraQueja={cabeceraQueja}
        />
      ) : (
        <ListaQuejasCliente
          cliente={cliente}
          idCliente={idCliente}
          setIdQuejaAux={setIdQuejaAux}
          setIdQuejaStorage={setIdQuejaStorage}
          setCabeceraQueja={setCabeceraQueja}
        />
      )}
    </div>
  );
};

export default QuejasCerrar;
