import { useState, useEffect } from "react";

export const useResolutionScreen = () => {
  const [alturaResolucion, setAlturaResolucion] = useState(() => {
    const altoP = window.innerHeight;
    const vhToPixels = (vh) => (vh / 100) * altoP;
    const resolucionCalculada = altoP - 80 - 32 - vhToPixels(10) - 32 - 52;
    return resolucionCalculada;
  });

  useEffect(() => {
    const updateResolution = () => {
      const altoP = window.innerHeight;
      const vhToPixels = (vh) => (vh / 100) * altoP;
      const resolucionCalculada = altoP - 80 - 32 - vhToPixels(10) - 32 - 52;

      setAlturaResolucion(resolucionCalculada);
    };

    window.addEventListener("resize", updateResolution);

    return () => {
      window.removeEventListener("resize", updateResolution);
    };
  }, []);

  return { alturaResolucion };
};
