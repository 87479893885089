import React from "react";

const CabeceraChatSide = ({ nombre, emisor }) => {
  return (
    <div
      className="cabeceraChatSide_container"
      style={{ backgroundColor: "#fbdfdd" }}
    >
      <h4
        style={{
          margin: "0.3rem",
          fontWeight: "600",
          letterSpacing: "-0.5px",
        }}
      >
        {emisor ? `DE: ${nombre}` : `PARA: ${nombre}`}
      </h4>
    </div>
  );
};

export default CabeceraChatSide;
