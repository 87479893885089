import React from "react";
import { Card } from "@mui/material";
import IconoSemaforoListaNegra from "../IconoSemaforoListaNegra";

const CabeceraInformacionColaborador = ({ infoColaborador }) => {
  return (
    <div className="header-client-container">
      <Card>
        <header className="header-client-information">
          <div>
            <IconoSemaforoListaNegra
              state={infoColaborador.data?.tipoBloqueo}
            />
          </div>
          <div>
            <h3>Colaborador no registrado en lista negra !</h3>
          </div>
        </header>
      </Card>
    </div>
  );
};

export default CabeceraInformacionColaborador;
