import React, { useEffect, useState } from "react";

import FormServicioTecnico from "../components/FormServicioTecnico";
import HeaderInformacionCliente from "../../shared/containers/HeaderInformacionCliente";
import HeaderInformacionProducto from "../components/HeaderInformacionProducto";
import BotonSalirLimpiarStorage from "./BotonSalirLimpiarStorage";
import HeaderInformacionSiniestro from "./HeaderInformacionSiniestro";

import {
  consultarDetalleFlujo,
  consultaSiniestroXIdSiniestro,
} from "../Services/siniestrosService";

const DetalleInformeTecnicoSiniestro = ({
  idCliente,
  idContrato,
  idSiniestro,
  idFlujo,
  idGrupo
}) => {
  const [verificarFlujo, setVerificarFlujo] = useState(true);
  const [verificarEsActivoSiniestro, setVerificarEsActivoSiniestro] =
    useState(true);

  useEffect(() => {
    const verificarDetalleFlujo = async () => {
      try {
        const respuestaDetalleFlujo = await consultarDetalleFlujo(idFlujo);
        /* console.log(respuestaDetalleFlujo); */

        if (respuestaDetalleFlujo.data.informe) {
          setVerificarFlujo(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    verificarDetalleFlujo();
  }, []);

  useEffect(() => {
    const verificarEsActivo = async () => {
      try {
        const respuestaSiniestroXId = await consultaSiniestroXIdSiniestro(
          idSiniestro
        );
        /* console.log(respuestaSiniestroXId); */

        if (respuestaSiniestroXId.data.esActivo) {
          setVerificarEsActivoSiniestro(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    verificarEsActivo();
  }, []);

  if (verificarEsActivoSiniestro) {
    return (
      <>
        <BotonSalirLimpiarStorage />
        <h1>El siniestro se encuentra inactivo</h1>
      </>
    );
  }

  if (verificarFlujo) {
    return (
      <>
        <BotonSalirLimpiarStorage />
        <h1>Opción no permitida para el siniestro seleccionado</h1>
      </>
    );
  }

  return (
    <>
      <BotonSalirLimpiarStorage />

      <HeaderInformacionCliente idCliente={idCliente} />

      <HeaderInformacionProducto idSiniestro={idSiniestro} />

      <HeaderInformacionSiniestro
        idCliente={idCliente}
        idSiniestro={idSiniestro}
      />

      <FormServicioTecnico idSiniestro={idSiniestro} />
    </>
  );
};
export default DetalleInformeTecnicoSiniestro;
