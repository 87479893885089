import { useState, useEffect } from "react";
import {getAllRol, getAllOptions} from "../index"

export const useRoles = (system) => {
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(null);
  const [isLoadingRoles, setLoadingRoles] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const coreResponse = await getAllRol(system);
        
        if (coreResponse.state) {
          let i = 0;
          var newroles = coreResponse.data.map(function (items) {
            return { ...items, id: i++ };
          });

          setRoles(newroles);
        } else setRoles([]);
        setLoadingRoles(false);
      } catch (error) {
        setError(error);
        setLoadingRoles(false);
      }
    };
    fetchData();
  }, []);

  return {roles, setRoles,isLoadingRoles, error };
};


export const useGetOption = () => {

  const [options, setOptions] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const coreResponse = await getAllOptions()

        if (coreResponse.state) {
          var datos = coreResponse.data
          var datosFintrados = datos.filter((x) => x.idMenuOrigen !== null);
          let i = 0;
          
          var options = datosFintrados.map((doc) => {
            return {
              id: i++,
              name: doc.titulo,
              code: doc.idMenu,
            }
          })

          setOptions(options);
        } else setOptions([])
        setIsLoadingOptions(false)
      } catch (error) {
        setIsLoadingOptions(false)
      }
    }

    fetchData()
  }, [])

  return { options, setOptions, isLoadingOptions }
}


