// import { SEND_FORGET_PASSWORD_EMAIL, UPDATE_AUTH_USER, UPDATE_LOAD_USER } from '../../@jumbo/constants/ActionTypes';

// export const setAuthUser = user => {
//   return dispatch => {
//     dispatch({
//       type: UPDATE_AUTH_USER,
//       payload: user,
//     });
//   };
// };

// export const updateLoadUser = loading => {
//   return dispatch => {
//     dispatch({
//       type: UPDATE_LOAD_USER,
//       payload: loading,
//     });
//   };
// };

// export const setForgetPassMailSent = status => {
//   return dispatch => {
//     dispatch({
//       type: SEND_FORGET_PASSWORD_EMAIL,
//       payload: status,
//     });
//   };
// };
import {
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  UPDATE_CODE_USER,
  //SEND_FORGET_PASSWORD_EMAIL

} from '../../../@jumbo/constants/ActionTypes';

export const updateCodeUser = (user) => {
  return {

    type: UPDATE_CODE_USER,
    payload: user,

  };
};

export const setAuthUserOut = (user) => {
  return {

    type: SIGNOUT_USER_SUCCESS,
    payload: user,

  };
};

export const setAuthUser = (user) => {
  return {

    type: UPDATE_AUTH_USER,
    payload: user,

  };
};

export const updateLoadUser = (loading) => {
  return {

    type: UPDATE_LOAD_USER,
    payload: loading,

  };
};

export const userSignInSuccess = (authUser) => {////////
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
    roles: authUser.usuarioRol,
  };
};

export const showAuthLoader = () => {///////////
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideAuthLoader = () => {///////////////////////
  return {
    type: ON_HIDE_LOADER,
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      //type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status,
    });
  };
};