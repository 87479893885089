import React from 'react';
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUT_NAMES} from "../../../layouts/layouts";

import MasterUsers from "./UsersContainers/MasterUsers"
const Users = () => {
  const {setActiveLayout} = useJumboApp();

  React.useEffect(() => {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
      <div>
        <h2>Administrador de Usuarios</h2>
            <MasterUsers/>
      </div>
  );
};

export default Users;
