import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Button, Card, TextField } from "@mui/material";

const BuscadorPorFechas = ({ setMostrarBuscador, setDatosBusqueda }) => {
  const [idCliente, setidCliente] = useState();

  const handleId = (e) => {
    setidCliente(e.target.value);
  };

  const enviarDatosBusqueda = (valores) => {
    console.log(valores);
    setDatosBusqueda({
      fechaInicio: valores.fechaInicio,
      fechaFin: valores.fechaFin,
    });
    setMostrarBuscador(false);
  };

  return (
    <>
      <Card className="buscador-container">
        <div className="buscador-cabecera">
          <h2>Consultar información del cliente</h2>
        </div>

        <Formik
          initialValues={{
            fechaInicio: "",
            fechaFin: "",
          }}
          onSubmit={(valores, { setSubmitting }) => {
            enviarDatosBusqueda(valores);
          }}
        >
          {({ values, handleChange }) => (
            <Form>
              <div className="buscador-gestion-opciones">
                <TextField
                  id="fechaInicio"
                  name="fechaInicio"
                  type="date"
                  label="Fecha Inicio"
                  sx={{ width: "25%" }}
                  value={values.fechaInicio}
                  onChange={handleChange}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />

                <TextField
                  id="fechaFin"
                  name="fechaFin"
                  type="date"
                  label="Fecha Fin"
                  sx={{ width: "25%" }}
                  value={values.fechaFin}
                  onChange={handleChange}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  sx={{ width: "25ch" }}
                >
                  Buscar
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </>
  );
};

export default BuscadorPorFechas;
