import http from "../../../comunication/http-common";

//http://10.10.0.79:5016/requerimiento/ObtenerRequerimiento?idRequerimiento=64
export const consultarRequerimiento = async (idRequerimiento) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/ObtenerRequerimiento?idRequerimiento=${idRequerimiento}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

//http://10.10.0.79:5016/requerimiento/ListarRequerimientos?tipoRequerimiento=1
export const consultarListaRequerimientos = async (tipoRequerimiento) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/ListarRequerimientos?tipoRequerimiento=${tipoRequerimiento}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

//http://localhost:5016/requerimiento/ObtenerCatalogo?idPadre=4&esSoporte=true
export const consultarDocumentosSoporte = async (idPadre, soporte) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/ObtenerCatalogo?idPadre=${idPadre}&esSoporte=${soporte}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

//http://10.10.0.79:5016/requerimiento/BuscarRequerimientos?idCliente=287056
export const consultarRequerimientosCliente = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/BuscarRequerimientos?idCliente=${idCliente}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://10.10.0.79:5016/requerimiento/VerificarTieneDeuda?idCliente=287056
export const consultarVerificarTieneDeuda = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/VerificarTieneDeuda?idCliente=${idCliente}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://localhost:5016/requerimiento/GenerarPDFRequerimiento?idRequerimiento=287056
export const consultarGenerarCertificado = async (idRequerimiento) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/GenerarPDFRequerimiento?idRequerimiento=${idRequerimiento}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5015/Seguro/ObtenerCabeceraContrato?idContrato=227391
export const consultarCabeceraSeguros = async (idContrato) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}Seguro/ObtenerCabeceraContrato?idContrato=${idContrato}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/requerimiento/ObtenerValorPrecancelar?identificacion=1725786931
export const consultarValorPrecancelar = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/ObtenerValorPrecancelar?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5016/requerimiento/ObtenerEstadoClienteSiscre?identificacion=1723967699
export const consultarClienteSISCRE = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/ObtenerEstadoClienteSiscre?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5016/requerimiento/ValidarCambioEstadoSiscre?idRequerimiento=195
export const consultarCambioEstadoSISCRE = async (idRequerimiento) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/ValidarCambioEstadoSiscre?idRequerimiento=${idRequerimiento}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5011/evaluacion/ObtenerListaNegraColaborador?identificacion=0803282425
export const consultarColaboradorListaNegra = async (identificacion, usuario, host) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_EVALUACION}evaluacion/ObtenerListaNegraColaborador?identificacion=${identificacion}&usuario=${usuario}&host=${host}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/requerimiento/CambiarEstadoRequerimiento?idRequerimiento=5&nuevoEstado=ESTREQRES
export const crearCambioEstadoRequerimiento = async (
  idRequerimiento,
  nuevoEstado
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/CambiarEstadoRequerimiento?idRequerimiento=${idRequerimiento}&nuevoEstado=${nuevoEstado}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5016/requerimiento/GuardarRequerimiento
export const crearRequerimientoSolicitud = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/GuardarRequerimiento`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5016/requerimiento/GuardarDatosRequerimiento
export const crearDatosRequerimiento = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/GuardarDatosRequerimiento`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5016/requerimiento/GuardarSoporteRequerimiento
export const crearSoporteRequerimiento = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/GuardarSoporteRequerimiento`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/requerimiento/NotificarRequerimiento?idRequerimiento=74
export const crearCertificadoNotificacion = (data, idRequerimiento) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/NotificarRequerimiento?idRequerimiento=${idRequerimiento}`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://10.10.0.79:5000/requerimiento/GuardarInformacionRequerimiento
export const crearInformacionRequerimiento = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/GuardarInformacionRequerimiento`;

    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://localhost:5016/requerimiento/DesestimarRequerimiento
export const desestimarRequerimiento = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}requerimiento/DesestimarRequerimiento`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
