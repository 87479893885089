import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";

function downloadExcel(file, nombreDocumento) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet("Datos");

  const headers = Object.keys(file[0]);
  worksheet.addRow(headers);

  // Agrega las filas de datos
  file.forEach((row) => {
    const values = Object.values(row);
    worksheet.addRow(values);
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: "application/octet-stream" });

    // Descarga el archivo Excel con el nombre "archivo.xlsx"
    saveAs(blob, `${nombreDocumento}.xlsx`);
  });
}

export default downloadExcel;
