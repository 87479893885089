import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import ReporteQuejas from "./ReporteQuejas/ReporteQuejas";

const QuejasReportes = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Quejas - Reporte</h2>
      <ReporteQuejas />
    </div>
  );
};

export default QuejasReportes;
