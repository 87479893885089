import React, { useState } from "react";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";

import TablaReporteSolicitudZona from "../../containers/TablaReporteSolicitudZona";
import BotonConsultaSolicitudReporteZona from "../../components/BotonConsultaSolicitudReporteZona";
import HeaderReporte from "../../containers/HeaderReporte";

const ReportesSolicitud = () => {
  const [isReport, setIsReport] = useState({
    stateReport: true,
    dataSiniestro: [],
  });

  return (
    <>
      <BotonGerericoSalir />
      <div className="header-reporte-detalle">
        <HeaderReporte title="Reporte Solicitud Por Zona" />
      </div>
      
      <div className="reportes-container">
        <BotonConsultaSolicitudReporteZona setIsReport={setIsReport} />
      </div>
    </>
  );
};

export default ReportesSolicitud;
