import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Rating,
  TextField,
  Box,
  useTheme,
  useMediaQuery,
  Stack,
  Typography,
} from "@mui/material";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";
import { useSelector } from "react-redux";
import { grabarCalificacion } from "app/modules/app-cliente/services/clienteService";

const ModalCalificacion = ({
  idCliente,
  open,
  setOpenImagenFinal,
  setOpenCalificacion,
}) => {
  const [valor, setValor] = useState(3);
  const [comentario, setComentario] = useState("");
  const [hover, setHover] = useState(-1);
  const theme = useTheme();
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("lg");
  const { authUser } = useSelector(({ auth }) => auth);
  const history = useHistory();
  const [showComment, setShowComment] = useState(false);

  const labels = {
    1: "Deficiente",
    2: "Deficiente",
    3: "Aceptable",
    4: "Aceptable",
    5: "Regular",
    6: "Regular",
    7: "Bueno",
    8: "Bueno",
    9: "Excelente",
    10: "Excelente",
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }
  const handleSave = async () => {
    const today = new Date().toLocaleString("en-US");

    const calificacion = {
      idCliente: idCliente,
      calificacion1: valor,
      sugerencia: comentario,
      fecha: today,
      estacion: authUser.estacion,
    };

    try {
      const srvGrabarCalificacion = await grabarCalificacion(calificacion);

      if (srvGrabarCalificacion.state) {
        //alert("Datos grabados correctamente");
        //some stuff after save
        // const path = "http://www.akitranquilo.ec";
        // history.push(path);
        setOpenCalificacion(false);
        setOpenImagenFinal(true);
      } else {
        console.log("Error al grabar calificacion");
        const path = "/initialHome";
        history.push(path);
      }
    } catch (error) {
      console.log("Error al grabar calificacion", error);
      const path = "/initialHome";
      history.push(path);
    }
  };

  return (
    <Dialog
      aria-describedby="alert-dialog-slide-description"
      //onClose={() => close(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperProps={{ sx: { borderRadius: "40px" } }}
    >
      <Box>
        <DialogTitle
          textAlign={"center"}
          sx={{
            backgroundColor:
              Number(localStorage.getItem("idGrupoStorage")) === 3
                ? "#FF9201"
                : "#e1251b",
            margin: 0,
          }}
        >
          <Typography
            variant="h2"
            fontSize={{ lg: "2rem", md: "1.5rem", sm: "1.5rem", xs: "1.25rem" }}
          >
            CALIFICA TU EXPERIENCIA
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} direction="column" alignItems="center">
            <Typography
              variant="h2"
              fontSize={{
                lg: "1.5rem",
                md: "1.5rem",
                sm: "1.5rem",
                xs: "1rem",
              }}
            >
              En una escala del 1 al 10. <br />
              ¿Qué tan satisfecho estás hoy con tu experiencia al ingresar tu
              solicitud de crédito?
            </Typography>
            <Rating
              name="size-large"
              defaultValue={1}
              size="large"
              max={10}
              onChange={(event, value) => {
                setValor(value);
                setShowComment(true);
              }}
              sx={{
                fontSize: { lg: "4rem", md: "3rem", sm: "3rem", xs: "2rem" },
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              getLabelText={getLabelText}
            />
            {valor !== null && (
              <Box sx={{ fontSize: "1.8rem" }}>
                {labels[hover !== -1 ? hover : valor]}
              </Box>
            )}
          </Stack>
          {showComment ? (
            <Stack
              spacing={2}
              direction="column"
              sx={{ visibility: showComment ? "visible" : "hidden" }}
            >
              <Typography variant="h3">
                Tu opinión es importante, Cuéntamos cómo podemos mejorar tu
                experiencia.
              </Typography>
              <TextField
                label="Sugerencia"
                id="fullWidth"
                onChange={(event) => {
                  setComentario(event.target.value);
                }}
              />
            </Stack>
          ) : (
            <Stack mt={4} alignItems="center">
              <Typography variant="h2">
                Selecciona tu calificacion del 1 al 10 para continuar.
              </Typography>
            </Stack>
          )}
        </DialogContent>

        <DialogActions sx={{ visibility: showComment ? "visible" : "hidden" }}>
          <SCSGenericButton
            variant="contained"
            onClick={() => handleSave()}
            titulo={"Enviar"}
            //size={20}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ModalCalificacion;
