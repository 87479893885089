import React, { useEffect, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  FormControl,
  Grid,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";

import HeaderInformacionTrasladoIndirecto from "../container/HeaderInformacionTrasladoIndirecto";

import { formatoFecha } from "../../shared/validations/validaciones";
import { consultaCatalogosSinPadre } from "../../app-cliente/services/Catalogos/catalogosService";

import {
  consultarTransporte,
  consultarCatalogoSiniestro,
  consultarTransporteXTransp,
  crearTraslado,
  crearSeguimiento,
  notificarSiniestroCliente,
} from "../Services/siniestrosService";

import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

const FormTraslado = ({ idSiniestro, setIdTraslado, idGrupo }) => {
  const [clienteInfo, setClienteInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const [trasladoDirecto, setTrasladoDirecto] = useState(null);
  const [trasladoIndirecto, setTrasladoIndirecto] = useState(null);

  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [idTrasladoAux, setIdTrasladoAux] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaTrasladoSiniestro = await consultarTransporte(
          idSiniestro
        );

        console.log(respuestaTrasladoSiniestro);

        if (respuestaTrasladoSiniestro.state) {
          setClienteInfo(respuestaTrasladoSiniestro.data);

          if (respuestaTrasladoSiniestro.data.length === 0) {
            setTrasladoDirecto({});
          }

          if (respuestaTrasladoSiniestro.data.length === 1) {
            respuestaTrasladoSiniestro.data.forEach((infoTraslado) => {
              if (infoTraslado.tipoTraslado === "DIRECTO") {
                /*  console.log("Traslado Directo");
                console.log(infoTraslado); */
                setTrasladoDirecto(infoTraslado);
                setIdTrasladoAux(infoTraslado.idTraslado);
              }
              if (infoTraslado.tipoTraslado === "INDIRECTO") {
                /*  console.log("Traslado Indirecto");
                console.log(infoTraslado); */
                setTrasladoDirecto({});
                setTrasladoIndirecto(infoTraslado.idTraslado);
                setIdTraslado(infoTraslado);
              }
            });
          }

          if (respuestaTrasladoSiniestro.data.length >= 2) {
            respuestaTrasladoSiniestro.data.forEach((infoTraslado) => {
              if (infoTraslado.tipoTraslado === "DIRECTO") {
                /*   console.log("Traslado Directo");
                console.log(infoTraslado); */
                setTrasladoDirecto(infoTraslado);
                setIdTrasladoAux(infoTraslado.idTraslado);
              }
              if (infoTraslado.tipoTraslado === "INDIRECTO") {
                /*   console.log("Traslado Indirecto");
                console.log(infoTraslado); */
                setIdTraslado(infoTraslado);
                setTrasladoIndirecto(infoTraslado.idTraslado);
              }
            });
          }
        } else {
          setClienteInfo([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const CustomizedSelectTipoTraslado = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsTransportista = ({ name, labelName, componentSize }) => {
    const [transportistas, setTransportistas] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultarCatalogoSiniestro("TRANSPTA");

          if (state) {
            /*  console.log(data); */
            setTransportistas(data);
          } else {
            setTransportistas([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, []);

    return (
      <FormControl sx={{ width: `${componentSize}` }}>
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {transportistas.map((transportista, index) => (
            <MenuItem key={index} value={transportista.codigo}>
              {transportista.descripcion}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    );
  };

  const SelectItemsTransporte = ({
    name,
    labelName,
    transportista,
    componentSize,
  }) => {
    const [transporte, setTransporte] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultarTransporteXTransp(
            transportista
          );

          if (state) {
            /*  console.log(data); */
            setTransporte(data);
          } else {
            setTransporte([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, [transportista]);

    return (
      <FormControl sx={{ width: `${componentSize}` }}>
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {transporte.map((transpor, index) => (
            <MenuItem key={index} value={transpor.codigo}>
              {transpor.descripcion}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    );
  };

  const verificarTipoTraslado = (tipoTraslado) => {
    if (tipoTraslado === "INDIRECTO") {
      return true;
    }
    return false;
  };

  const SelectItemsTaller = ({ name, labelName, componentSize }) => {
    const [talleres, setTalleres] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultarCatalogoSiniestro("TALLER");

          if (state) {
            setTalleres(data);
          } else {
            setTalleres([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, []);

    return (
      <FormControl sx={{ width: `${componentSize}` }}>
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {talleres.map((taller, index) => (
            <MenuItem key={index} value={taller.codigo}>
              {taller.descripcion}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    );
  };

  function validarFechaInicioFinTraslado(fechaInicio, traslado) {
    /* calcula simpre la fecha finsin importar que ya exista una */

    if (!fechaInicio) {
      return "";
    }
    let diasValidos;

    if (traslado === "DIRECTO") {
      diasValidos = 3;
    } else {
      diasValidos = 5;
    }

    const nuevaFechaCalculada = new Date(fechaInicio);
    nuevaFechaCalculada.setDate(nuevaFechaCalculada.getDate() + diasValidos);
    /* console.log(nuevaFechaCalculada.toISOString().split("T")[0]); */
    return nuevaFechaCalculada.toISOString().split("T")[0];
  }

  /* select pendiente que traiga los subestados in/directo con el estado 3 */
  /*   const selectTipoTraslado = ({
    name,
    labelName,
    transportista,
    componentSize,
  }) => {
    const [transporte, setTransporte] = useState([]);

    useEffect(() => {
      const getData = async () => {
        const respuesta = await consultarTransporteXTransp(
          transportista
        );

        setTransporte(respuesta);
        console.log(respuesta);
      };
      getData();
    }, [transportista]);

    return (
      <FormControl sx={{ width: `${componentSize}` }}>
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {transporte.map((transpor, index) => (
            <MenuItem key={index} value={transpor.codigo}>
              {transpor.descripcion}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    );
  }; */

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const informacionTrasladoAux = {
      ...valores,
    };

    if (valores.tipoTraslado === "DIRECTO") {
      informacionTrasladoAux.fechaFin = validarFechaInicioFinTraslado(
        valores.fechaInicio,
        "DIRECTO"
      );
    }

    if (valores.tipoTraslado === "INDIRECTO") {
      informacionTrasladoAux.fechaFin = validarFechaInicioFinTraslado(
        valores.fechaInicio,
        "INDIRECTO"
      );
    }

    const informacionTraslado = {
      idSiniestro: Number(idSiniestro),
      idTraslado: idTrasladoAux,
      idTransporte: Number(valores.idTransporte),
      idTransportista: Number(valores.idTransportista),
      tipoTraslado: valores.tipoTraslado,
      idLocalOrigen: Number(valores.idLocalOrigen),
      destino: Number(valores.destino),
      fechaInicio: valores.fechaInicio,
      fechaFin: informacionTrasladoAux.fechaFin,
      detalleEnvio: valores.detalleEnvio.toUpperCase(),
      esActivo: true,
    };

    console.log(informacionTraslado);

    try {
      const respuestaTraslado = await crearTraslado(informacionTraslado);
      console.log(respuestaTraslado);

      if (respuestaTraslado.state) {
        setDatosEnviadosCorrectamente(true);
        setIdTrasladoAux(respuestaTraslado.data);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
        }, 1000);

        if (valores.tipoTraslado === "INDIRECTO") {
          setTrasladoIndirecto(respuestaTraslado.data);
          setIdTrasladoAux(0);
        }
      } else {
        alert(`${respuestaTraslado.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

    let subestadoTraslado;
    if (valores.tipoTraslado === "DIRECTO") {
      subestadoTraslado = 14;
    }

    if (valores.tipoTraslado === "INDIRECTO") {
      subestadoTraslado = 15;
    }

    const fecha = new Date();

    const informacionSeguimientoNotificacion = {
      idSeguimiento: 0,
      idSiniestro: Number(idSiniestro),
      esSiniestro: true,
      idEstado: 3,
      idSubestado: subestadoTraslado,
      fechaEstado: fecha.toISOString().split(".")[0],
      esActivo: true,
    };

    try {
      const respuestaSeguimiento = await crearSeguimiento(
        informacionSeguimientoNotificacion
      );
      console.log(respuestaSeguimiento);
    } catch (error) {
      console.log(error);
    }

    try {
      const respuestaEnvioNotificar = await notificarSiniestroCliente(
        idSiniestro
      );

      if (respuestaEnvioNotificar.state) {
        console.log(respuestaEnvioNotificar);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {trasladoIndirecto && (
        <HeaderInformacionTrasladoIndirecto idTraslado={trasladoIndirecto} />
      )}

      {trasladoDirecto && (
        <Formik
          initialValues={{
            tipoTraslado: trasladoDirecto.tipoTraslado || "",
            idLocalOrigen: trasladoDirecto.idLocalOrigen || "",
            fechaInicio: trasladoDirecto.fechaInicio || "",
            fechaFin: "",
            destino: trasladoDirecto.destino || "",
            detalleEnvio: trasladoDirecto.detalleEnvio || "",
            idTransporte: trasladoDirecto.idTransporte || "",
            idTransportista: trasladoDirecto.idTransportista || "",
          }}
          validate={(values) => {
            const errors = {};
          }}
          validationSchema={Yup.object({
            /* tipoTraslado: Yup.string().required("Tipo traslado requerido"), */
            idLocalOrigen: Yup.string().required("Local Origen requerido"),
            tipoTraslado: Yup.string().required("Tipo Traslado requerido"),
            fechaInicio: Yup.string().required("Fecha Inicio requerido"),
          })}
          onSubmit={(values, { resetForm }) => {
            configuracionDatosEnvio(values);

            if (values.tipoTraslado === "INDIRECTO") {
              setTimeout(() => {
                resetForm({ values: "" });
              }, 1000);
            }
          }}
        >
          {({ values, handleChange, touched, errors, handleBlur }) => (
            <Form>
              <SCSAccordion tituloCabecera="Información Traslado">
                <Grid container spacing={1} rowSpacing={1}>
                  <Grid item md={12}>
                    <Grid container spacing={1}>
                      <Grid item md={12}>
                        <div className="cliente-informacion-seccion">
                          <FormControl
                            sx={{ width: "23%" }}
                            size="small"
                            error={
                              touched.tipoTraslado &&
                              Boolean(errors.tipoTraslado)
                            }
                            helperText={
                              touched.tipoTraslado && errors.tipoTraslado
                            }
                            onBlur={handleBlur}
                          >
                            <InputLabel id="tipoTraslado">
                              Tipo Traslado
                            </InputLabel>
                            <Field
                              name="tipoTraslado"
                              component={CustomizedSelectTipoTraslado}
                            >
                              <MenuItem value="DIRECTO">DIRECTO</MenuItem>

                              <MenuItem value="INDIRECTO">INDIRECTO</MenuItem>
                            </Field>

                            <ErrorMessage
                              name="tipoTraslado"
                              component={() => (
                                <FormHelperText>
                                  {errors.tipoTraslado}
                                </FormHelperText>
                              )}
                            />
                          </FormControl>

                          <SelectItemsCatalogoSinPadre
                            pais="ECU"
                            especial="true"
                            catalogos="LOCAL"
                            grupo={idGrupo}
                            name="idLocalOrigen"
                            labelName="Local Origen"
                            componentSize="23%"
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                          />

                          <SelectItemsTransportista
                            name="idTransportista"
                            labelName="Transpotista"
                            componentSize="23%"
                          />

                          <SelectItemsTransporte
                            name="idTransporte"
                            labelName="Transporte"
                            transportista={values.idTransportista}
                            componentSize="23%"
                          />
                        </div>

                        <div className="cliente-informacion-seccion">
                          {verificarTipoTraslado(values.tipoTraslado) ? (
                            <SelectItemsCatalogoSinPadre
                              pais="ECU"
                              especial="true"
                              catalogos="LOCAL"
                              grupo={idGrupo}
                              name="destino"
                              labelName="Destino Local"
                              componentSize="24%"
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />
                          ) : (
                            <SelectItemsTaller
                              name="destino"
                              labelName="Taller"
                              componentSize="23%"
                            />
                          )}

                          <TextField
                            id="fechaInicio"
                            name="fechaInicio"
                            type="date"
                            label="Fecha Inicio"
                            sx={{ width: "16%" }}
                            onChange={handleChange}
                            size="small"
                            value={formatoFecha(values.fechaInicio)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={
                              touched.fechaInicio && Boolean(errors.fechaInicio)
                            }
                            helperText={
                              touched.fechaInicio && errors.fechaInicio
                            }
                            onBlur={handleBlur}
                          />

                          <TextField
                            disabled
                            id="fechaFin"
                            name="fechaFin"
                            type="date"
                            label="Fecha Fin "
                            sx={{ width: "16%" }}
                            onChange={handleChange}
                            size="small"
                            value={validarFechaInicioFinTraslado(
                              values.fechaInicio,
                              values.tipoTraslado
                            )}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />

                          <TextField
                            id="detalleEnvio"
                            name="detalleEnvio"
                            type="text"
                            label="Detalle Envio"
                            sx={{ width: "37%" }}
                            value={values.detalleEnvio}
                            onChange={handleChange}
                            size="small"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <div className="confirmar-estados-servicios">
                      <SCSLoadingButton loading={loading} titulo="GUARDAR" />

                      {datosEnviadosCorrectamente && <AcceptServiceresponse />}
                    </div>
                  </Grid>
                </Grid>
              </SCSAccordion>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default FormTraslado;
