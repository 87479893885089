import React, { useEffect, useState } from "react";

import { Skeleton } from "@mui/material";

import {
  SCSTableData,
  SCSTableDataRow,
  SCSTableDataCell,
} from "app/modules/common/components/TableData/SCSTableData";

import {
  consultaQuejasTrx,
  consultarDatosSeguro,
} from "../services/transaccionalService";
import DetalleOpcionGenerica from "./DetalleOpcionGenerica";

const DetalleQuejas = ({
  cabenceraTransaccional,
  datosBusqueda,
  alturaResolucion,
}) => {
  const [estadosCredito, setEstadosCredito] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaEstadosCredito = await consultaQuejasTrx(
          datosBusqueda.identificacion,
          datosBusqueda.idGrupo
        );

       /*  console.log(respuestaEstadosCredito); */

        if (respuestaEstadosCredito.state && respuestaEstadosCredito.data) {
          setEstadosCredito(respuestaEstadosCredito.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={alturaResolucion}
        sx={{ borderRadius: "32px" }}
      />
    );
  }

  if (estadosCredito.length <= 0) {
    return <DetalleOpcionGenerica mensaje={"No existe información."} />;
  }

  const columnsData = [
    { id: 1, name: "N° Queja" },
    { id: 2, name: "Fecha" },
    { id: 3, name: "Producto" },
    { id: 4, name: "Tipo Queja" },
    { id: 5, name: "Estado" },
    { id: 6, name: "Prestador" },
    { id: 7, name: "Tipo Solucion" },
    { id: 8, name: "Fecha Cierre" },
  ];

  return (
    <SCSTableData columnsData={columnsData} heightTable={alturaResolucion}>
      {estadosCredito?.map((consumo, index) => (
        <SCSTableDataRow key={consumo.index}>
          <SCSTableDataCell align="center">{index + 1}</SCSTableDataCell>
          <SCSTableDataCell align="center">{consumo.fecha}</SCSTableDataCell>
          <SCSTableDataCell align="center">{consumo.producto}</SCSTableDataCell>
          <SCSTableDataCell align="center">
            {consumo.tipoQueja}
          </SCSTableDataCell>
          <SCSTableDataCell align="center">{consumo.estado}</SCSTableDataCell>
          <SCSTableDataCell align="center">
            {consumo.prestador}
          </SCSTableDataCell>
          <SCSTableDataCell align="center">
            {consumo.tipoSolucion}
          </SCSTableDataCell>
          <SCSTableDataCell align="center">
            {consumo.fechaCierre}
          </SCSTableDataCell>
        </SCSTableDataRow>
      ))}
    </SCSTableData>
  );
};

export default DetalleQuejas;
