import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import FormInformacionCliente from "../../app-cliente/components/FormInformacionCliente";
import FormSiniestroNotificacion from "../components/FormSiniestroNotificacion";
import FormSiniestroGO from "./FormsGarantiaOriginal/FormSiniestroGO";
import FormProductoGO from "./FormsGarantiaOriginal/FormProductoGO";

import { consultaCliente } from "../../app-cliente/services/clienteService";
import BotonSalirLimpiarStorage from "./BotonSalirLimpiarStorage";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const CrearProductoSinSeguro = () => {
  /* const { idClienteBuscar } = useParams(); */

  const history = useHistory();
  const idClienteBuscar = history.location.state.state.identificacion;

  const [idCliente, setIdCliente] = useState(null);
  const [idClienteInformacion, setIdClienteInformacion] = useState(null);
  const [idSiniestro, setIdSiniestro] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [idProductoAux, setidProductoAux] = useState(null);

  useEffect(() => {
    /* console.log(idClienteBuscar); */
    const getData = async () => {
      try {
        const infoCliente = await consultaCliente(idClienteBuscar);
        /*  console.log(infoCliente);
         */
        if (infoCliente.state) {
          setIdCliente(infoCliente.data.idCliente);
          setIsLoading(false);
        } else {
          /* console.log(infoCliente); */
          setIdCliente(-1);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    /* console.log(idClienteInformacion); */
    setIsLoading(false);
  }, [idClienteInformacion]);

  useEffect(() => {
    /* console.log(idSiniestro); */
  }, [idSiniestro]);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }
  return (
    <>
      <BotonSalirLimpiarStorage />

      <div className="accordion-container">
        {idCliente && (
          <FormInformacionCliente
            idCliente={idCliente}
            setIdClienteInformacion={setIdClienteInformacion}
            identificacion={idClienteBuscar}
          />
        )}

        {idClienteInformacion && (
          <>
            <FormSiniestroGO
              idCliente={idClienteInformacion}
              setIdSiniestro={setIdSiniestro}
            />
            {idSiniestro && (
              <>
                <FormProductoGO
                  idCliente={idClienteInformacion}
                  idSiniestro={idSiniestro}
                  setidProductoAux={setidProductoAux}
                />
                {idProductoAux && (
                  <FormSiniestroNotificacion
                    idSiniestro={idSiniestro}
                    idCliente={idClienteInformacion}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CrearProductoSinSeguro;
