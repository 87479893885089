import axios from "axios";

console.log('Bearer common',sessionStorage.getItem('jwtToken'));


     
//manejo de axios
export default axios.create({
  headers: {
    'Content-Type': 'application/json',
    Authorization:  'Bearer ' + sessionStorage.getItem('jwtToken')
  },
  timeout: 10 * 6000,
});
