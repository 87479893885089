import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";

import { NumberFormatPercent } from "app/modules/shared/mask/mascaraPorcentaje";
import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";

import TablaPagosSiniestro from "./TablaPagosSiniestro";
import { crearPagoSiniestro } from "../Services/siniestrosService";

import { formatoFecha } from "app/modules/shared/validations/validaciones";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

const FormPagos = ({ idSiniestro, setIdPagoSiniestro }) => {
  const [loading, setLoading] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);
    console.log(valores);

    const informacionPagoSiniestro = {
      idSiniestro: idSiniestro,
      idPagoSiniestro: 0,

      esTitular: valores.esTitular === "true" ? true : false,
      cheque: valores.cheque,
      porcentaje: Number(100),
      montoPagado: Number(valores.montoPagado),
      numeroReferencia: valores.numeroReferencia,
      numeroCuota: Number(valores.numeroCuota),
      fechaPago: valores.fechaPago,
      esActivo: true,
    };

    /* console.log(informacionPagoSiniestro); */

    /*   setTimeout(() => {
      setDatosEnviadosCorrectamente(false);
      setLoading(false);
      setIsUpdate(!isUpdate);
    }, 2000); */

    try {
      const respuestaPago = await crearPagoSiniestro(informacionPagoSiniestro);

      if (respuestaPago.state) {
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
          setIsUpdate(!isUpdate);
        }, 1000);
      } else {
        setLoading(false);
        alert(`${respuestaPago.data.message}`);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <Formik
      initialValues={{
        esTitular: "true",
        cheque: false,
        porcentaje: 100,
        montoPagado: "",
        numeroReferencia: "",
        numeroCuota: "",
        fechaPago: "",
      }}
      validate={async (values) => {
        const errors = {};

        /* if (!values.email) {
            errors.email = "Required";
          } else if (!(await verificacionAsincronoEmail(values.email))) {
            errors.email = "El email no fue encontrado";
          } */

        return errors;
      }}
      validationSchema={Yup.object({
        esTitular: Yup.string().required("Titular requerido"),
        montoPagado: Yup.string().required("Monto requerido"),
        numeroCuota: Yup.string().required("N° Cuota requerido"),
        cheque: Yup.string().required("cheque requerido"),
      })}
      onSubmit={(values, { resetForm }) => {
        configuracionDatosEnvio(values);

        setTimeout(() => {
          resetForm({ values: "" });
        }, 1000);
      }}
    >
      {({ values, handleChange, touched, errors, handleBlur }) => (
        <Form>
          <SCSAccordion tituloCabecera="Pagos Siniestro">
            <Grid container spacing={1} rowSpacing={1}>
              <Grid item md={12}>
                <Grid container spacing={1} rowSpacing={1}>
                  <Grid item md={12}>
                    <div className="cliente-informacion-seccion">
                      <div className="pagosSiniestro-opciones-pago">
                        <FormControl
                          error={touched.esTitular && Boolean(errors.esTitular)}
                          helperText={touched.esTitular && errors.esTitular}
                          onBlur={handleBlur}
                        >
                          <RadioGroup
                            aria-labelledby="esTitular"
                            defaultValue={values.esTitular}
                            name="esTitular"
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="Titular"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="Beneficiario"
                            />
                          </RadioGroup>

                          <ErrorMessage
                            name="esTitular"
                            component={() => (
                              <FormHelperText>
                                {errors.esTitular}
                              </FormHelperText>
                            )}
                          />
                        </FormControl>
                      </div>

                      <TextField
                        id="montoPagado"
                        name="montoPagado"
                        label="Monto A Pagar"
                        sx={{ width: "15%" }}
                        value={values.montoPagado}
                        onChange={handleChange}
                        size="small"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        error={
                          touched.montoPagado && Boolean(errors.montoPagado)
                        }
                        helperText={touched.montoPagado && errors.montoPagado}
                        onBlur={handleBlur}
                      />

                      <TextField
                        disabled
                        id="porcentaje"
                        name="porcentaje"
                        label="Porcentaje"
                        sx={{ width: "10%" }}
                        value={values.porcentaje}
                        onChange={handleChange}
                        size="small"
                        InputProps={{
                          inputComponent: NumberFormatPercent,
                        }}
                      />

                      <TextField
                        id="numeroReferencia"
                        name="numeroReferencia"
                        type="text"
                        label="N° referencia"
                        sx={{ width: "14%" }}
                        value={values.numeroReferencia}
                        onChange={handleChange}
                        size="small"
                      />

                      <TextField
                        id="numeroCuota"
                        name="numeroCuota"
                        type="number"
                        label="N° Cuota"
                        sx={{ width: "10%" }}
                        value={values.numeroCuota}
                        onChange={handleChange}
                        size="small"
                        error={
                          touched.numeroCuota && Boolean(errors.numeroCuota)
                        }
                        helperText={touched.numeroCuota && errors.numeroCuota}
                        onBlur={handleBlur}
                      />

                      <TextField
                        id="fechaPago"
                        label="Fecha Pago"
                        type="date"
                        size="small"
                        sx={{ width: "14%" }}
                        onChange={handleChange}
                        value={formatoFecha(values.fechaPago)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <FormGroup
                        error={touched.cheque && Boolean(errors.cheque)}
                        helperText={touched.cheque && errors.cheque}
                        onBlur={handleBlur}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.cheque}
                              onChange={handleChange}
                              name="cheque"
                            />
                          }
                          label="Cheque"
                        />
                        <ErrorMessage
                          name="cheque"
                          component={() => (
                            <FormHelperText>{errors.cheque}</FormHelperText>
                          )}
                        />
                      </FormGroup>
                    </div>
                  </Grid>
                </Grid>
                <div className="confirmar-estados-servicios">
                  <SCSLoadingButton loading={loading} titulo="GUARDAR" />

                  {datosEnviadosCorrectamente && <AcceptServiceresponse />}
                </div>
              </Grid>
            </Grid>

            <div className="cliente-informacion-seccion">
              <TablaPagosSiniestro
                idSiniestro={idSiniestro}
                isUpdate={isUpdate}
                setIdPagoSiniestro={setIdPagoSiniestro}
              />
            </div>
          </SCSAccordion>
        </Form>
      )}
    </Formik>
  );
};

export default FormPagos;
