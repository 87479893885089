import http from "../../../../comunication/http-common";

//http://localhost:4002/identity/ObtenerUsuarioXIdentificacion?identificacion=1711111111
export const ObtenerUsuarioXidentificacion = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURIDAD}identity/ObtenerUsuarioXIdentificacion?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://localhost:4002/identity/ActulizarLocalUsuario?idUsuario=1718081894&idLocal=911&activo=true
export const ActulizarLocalUsuario = async (
  idUsuario,
  idLocal,
  activo,
  oficina,
  usuario,
  estacion
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURIDAD}identity/ActulizarLocalUsuario?idUsuario=${idUsuario}&idLocal=${idLocal}&activo=${activo}&oficina=${oficina}&usuario=${usuario}&estacion=${estacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
