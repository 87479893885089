import React, { useState } from "react";

import BuscadorPorIdentificador from "../../app-cliente/containers/BuscadorPorIdentificador";
import FormQuejas from "../components/FormQuejas";
import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";
import BotonSalirLimiarStorageQuejas from "./BotonSalirLimiarStorageQuejas";

import { consultaCliente } from "../../app-cliente/services/clienteService";

const DetalleCrearQueja = () => {
  const [cliente, setCliente] = useState(null);
  const [idCliente, setIdCliente] = useState("");
  const [mostrarDetalle, setMostrarDetalle] = useState(true);
  const [identificadorAux, setIdentificadorAux] = useState(false);

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  const buscarExistenciaCliente = async (identificador) => {
    try {
      const respuestaCliente = await consultaCliente(identificador);

      if (respuestaCliente.state) {
        setIdCliente(respuestaCliente.data.idCliente);
        setCliente(respuestaCliente.data);
        setMostrarDetalle(false);
      } else {
        setCliente(nuevoCliente);
        setIdCliente(nuevoIdCliente);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdCliente = (id) => () => {
    /*   console.log(id); */
    buscarExistenciaCliente(id);
  };

  if (idCliente <= -1) {
    return <h2>No existen el cliente!!</h2>;
  }

  if (mostrarDetalle) {
    return (
      <BuscadorPorIdentificador
        traerIdCliente={traerIdCliente}
        setIdentificadorAux={setIdentificadorAux}
      />
    );
  }

  return (
    <div>
      {cliente && (
        <>
          <BotonSalirLimiarStorageQuejas />

          <HeaderInformacionCliente idCliente={idCliente} />

          <FormQuejas idCliente={idCliente} /> 
        </>
      )}
    </div>
  );
};

export default DetalleCrearQueja;
