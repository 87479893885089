import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
} from "@mui/material";

import {
  consultaCatalogosConPadre,
  consultaCatalogosSinPadre,
} from "../services/Catalogos/catalogosService";

import {
  consultaEstFinanciero,
  crearClienteEstadoFinanciero,
  obtenerPesosActividadEconomica,
} from "../services/clienteService";

import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";

import { StyleInputs } from "../styles/themes";

import "../styles/main.css";

const FormClienteEstadoFinanciero = ({
  idCliente,
  setActividadEconomica,
  setInfoClienteGrabada,
}) => {
  const [clienteInfo, setClienteInfo] = useState(null);
  const [controlActividad, setControlActividad] = useState(true);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [idEstadoFinancieroAux, setIdEstadoFinancieroAux] = useState(0);

  const [pesosDependiente, setPesosDependiente] = useState({
    COMISION: 100,
    OTROS: 100,
    REMESA: 100,
    RENTA: 100,
    SUELDO: 100,
    VENTA: 100,
  });

  const [pesosIndependiente, setPesosIndependiente] = useState({
    COMISION: 100,
    OTROS: 100,
    REMESA: 100,
    RENTA: 100,
    SUELDO: 100,
    VENTA: 100,
  });

  useEffect(() => {
    const getData = async () => {
      let infoEstadoFinanciero;

      try {
        const informacionEstadoFinanciero = await consultaEstFinanciero(
          idCliente
        );
        /* console.log(infoEstadoFinanciero); */

        if (informacionEstadoFinanciero.state) {
          infoEstadoFinanciero = informacionEstadoFinanciero.data;

          setIdEstadoFinancieroAux(
            informacionEstadoFinanciero.data.idEstadoFinanciero
          );
        } else {
          infoEstadoFinanciero = {};
        }
      } catch (error) {
        console.log(error);
      }

      const res = {
        ...infoEstadoFinanciero,
      };

      setClienteInfo(res);
    };
    getData();
  }, []);

  useEffect(() => {
    const getPesosDependiente = async () => {
      try {
        const respuestaPesosDependiente = await obtenerPesosActividadEconomica(
          "AECDEP"
        );

        if (respuestaPesosDependiente.state) {
          const objectPesosDependiente = respuestaPesosDependiente.data.reduce(
            (acumuladorPesos, valorPeso) => {
              const objetoDependiente = {
                ...acumuladorPesos,
                [valorPeso.tipoIngreso]: valorPeso.porcentaje,
              };

              return objetoDependiente;
            },
            {}
          );

          console.log({ objectPesosDependiente });

          setPesosDependiente(objectPesosDependiente);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getPesosDependiente();
  }, []);

  useEffect(() => {
    const getPesosIndependiente = async () => {
      try {
        const respuestaPesosDependiente = await obtenerPesosActividadEconomica(
          "AECIND"
        );

        if (respuestaPesosDependiente.state) {
          const objectPesosIndependiente =
            respuestaPesosDependiente.data.reduce(
              (acumuladorPesos, valorPeso) => {
                const objetoIndependiente = {
                  ...acumuladorPesos,
                  [valorPeso.tipoIngreso]: valorPeso.porcentaje,
                };

                return objetoIndependiente;
              },
              {}
            );

          console.log({ objectPesosIndependiente });

          setPesosIndependiente(objectPesosIndependiente);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getPesosIndependiente();
  }, []);

  const condicionRederActividadIndependiente = (origenIngresos) => {
    /* console.log(origenIngresos); */
    if (origenIngresos === "AECDEP") {
      setControlActividad(false);
      setActividadEconomica(false);
      return false;
    }
    setControlActividad(true);
    setActividadEconomica(true);
    return true;
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        /* sx={{ width: "14ch" }} */
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogo = ({
    pais,
    especial,
    catalogos,
    codPadre,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      if (pais === "ECU") {
        const getData = async () => {
          const prov = await consultaCatalogosConPadre(
            especial,
            catalogos,
            codPadre,
            grupo
          );

          setProvincias(prov.data);
        };
        getData();
      }
    }, [pais, codPadre]);

    return (
      <FormControl
        sx={StyleInputs(componentSize.pcWidth, componentSize.phoneWidth)}
        //sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {pais === "ECU" ? (
            provincias.map((provincia, index) => (
              <MenuItem key={index} value={provincia.codigo}>
                {provincia.descripcion}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">none</MenuItem>
          )}
        </Field>
        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={StyleInputs(componentSize.pcWidth, componentSize.phoneWidth)}
        //sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  /*  Agregar llamada para obtener los porcentajes y verificar los valores ingresados  */
  const calcularTotalIngresos = (valores) => {
    let respuestaPorcentajes;
    if (valores.origenIngresos === "AECDEP") {
      respuestaPorcentajes = {
        ...pesosDependiente,
      };
    } else {
      respuestaPorcentajes = {
        ...pesosIndependiente,
      };
    }

    const { ingresoMensual, comision, renta, remesa, ventas, otrosIngresos } =
      valores;

    const totalIngresos =
      Number(ingresoMensual) * (Number(respuestaPorcentajes.SUELDO) / 100) +
      Number(comision) * (Number(respuestaPorcentajes.COMISION) / 100) +
      Number(renta) * (Number(respuestaPorcentajes.RENTA) / 100) +
      Number(remesa) * (Number(respuestaPorcentajes.REMESA) / 100) +
      Number(ventas) * (Number(respuestaPorcentajes.VENTA) / 100) +
      Number(otrosIngresos) * (Number(respuestaPorcentajes.OTROS) / 100);

    return totalIngresos.toFixed(2);
  };

  /* dependiendo del origen de ingresos dependiente/independiente cambian los campos de ingresos */
  const validarOrigenIngresos = (origenIngresos) => {
    if (origenIngresos === "AECDEP") {
      return true;
    } else {
      return false;
    }
  };

  const configuracionDatosEnvio = async (valoresAux) => {
    setLoading(true);

    const valores = { ...valoresAux };

    if (valoresAux.origenIngresos === "AECIND") {
      valores.ingresoMensual = 0;
      valores.comision = 0;
    } else if (valoresAux.origenIngresos === "AECDEP") {
      valores.ventas = 0;
    }

    const informacionEstadoFinanciero = {
      idCliente: idCliente,
      idEstadoFinanciero: idEstadoFinancieroAux,
      actividadEconomica: valores.actividadEconomica,
      origenIngresos: valores.origenIngresos,

      ingresoMensual: Number(valores.ingresoMensual),
      comision: Number(valores.comision),
      renta: Number(valores.renta),
      remesa: Number(valores.remesa),
      ventas: Number(valores.ventas),
      otrosIngresos: Number(valores.otrosIngresos),
      totalIngresos: Number(valores.totalIngresos),
      detalleOtrosIngresos: valores.detalleOtrosIngresos.toUpperCase(),

      profesion: valores.profesion,
      esActivo: true,
    };

    console.log("Estado Financiero");
    console.log(informacionEstadoFinanciero);

    try {
      const respuestaClienteInformacion = await crearClienteEstadoFinanciero(
        informacionEstadoFinanciero
      );

      console.log(respuestaClienteInformacion);

      if (respuestaClienteInformacion.state) {
        setDatosEnviadosCorrectamente(true);

        setIdEstadoFinancieroAux(respuestaClienteInformacion.data);
        if (setInfoClienteGrabada) {
          setInfoClienteGrabada((args) => ({
            ...args,
            financiero: true,
          }));
        }

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
        }, 1000);
      } else {
        setLoading(false);
        alert(`${respuestaClienteInformacion.message}`);
      }
    } catch (error) {
      setLoading(false);
      alert(`${error.response.data.message}`);
      console.log(error);
    }
  };

  return (
    <>
      {clienteInfo ? (
        <>
          <Formik
            initialValues={{
              origenIngresos: clienteInfo.origenIngresos || "",
              actividadEconomica: clienteInfo.actividadEconomica || "",
              profesion: clienteInfo.profesion || "",
              ingresoMensual: clienteInfo.ingresoMensual || 0,
              comision: clienteInfo.comision || 0,
              renta: clienteInfo.renta || 0,
              remesa: clienteInfo.remesa || 0,
              ventas: clienteInfo.ventas || 0,
              otrosIngresos: clienteInfo.otrosIngresos || 0,
              totalIngresos: clienteInfo.totalIngresos || 0,
              detalleOtrosIngresos: clienteInfo.detalleOtrosIngresos || "",
            }}
            validationSchema={Yup.object({
              origenIngresos: Yup.string().required(
                "Origen Ingresos requerido"
              ),
              actividadEconomica: Yup.string().required(
                "Actividad Economica requerido"
              ),
            })}
            onSubmit={(values) => {
              configuracionDatosEnvio(values);
            }}
          >
            {({ values, handleChange, touched, errors, handleBlur }) => (
              <Form>
                <SCSAccordion tituloCabecera="Estado Financiero">
                  <div className="cliente-informacion-seccion">
                    <SelectItemsCatalogoSinPadre
                      pais="ECU"
                      especial="false"
                      catalogos="FTEING"
                      grupo={1}
                      name="origenIngresos"
                      labelName="Origen Ingresos"
                      componentSize={{ pcWidth: 25, phoneWidth: 45 }}
                      //componentSize="25%"
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                    {condicionRederActividadIndependiente(
                      values.origenIngresos
                    )}
                    <SelectItemsCatalogo
                      pais="ECU"
                      especial="false"
                      catalogos="TIPOACTEC"
                      codPadre={values.origenIngresos}
                      grupo={1}
                      name="actividadEconomica"
                      labelName="Actividad Economica"
                      //componentSize="25%"
                      componentSize={{ pcWidth: 25, phoneWidth: 47 }}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />

                    {/* cambio de catalogo de PROF -> CARGO   */}
                    <SelectItemsCatalogoSinPadre
                      pais="ECU"
                      especial="false"
                      catalogos="CARGO"
                      grupo={1}
                      name="profesion"
                      labelName="Profesión"
                      componentSize={{ pcWidth: 35, phoneWidth: 96 }}
                      //componentSize="27%"
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                  </div>

                  <div>
                    <h3>Ingresos</h3>
                  </div>

                  {validarOrigenIngresos(values.origenIngresos) ? (
                    <>
                      <div className="cliente-informacion-seccion">
                        <TextField
                          id="ingresoMensual"
                          name="ingresoMensual"
                          label="Ingreso Mensual"
                          sx={StyleInputs(16, 45)}
                          //sx={{ width: "16%" }}
                          value={values.ingresoMensual}
                          onChange={handleChange}
                          size="small"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          error={
                            touched.ingresoMensual &&
                            Boolean(errors.ingresoMensual)
                          }
                          helperText={
                            touched.ingresoMensual && errors.ingresoMensual
                          }
                          onBlur={handleBlur}
                        />

                        <TextField
                          id="comision"
                          name="comision"
                          label="Comisión"
                          //sx={{ width: "16%" }}
                          sx={StyleInputs(16, 47)}
                          value={values.comision}
                          onChange={handleChange}
                          size="small"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          error={touched.comision && Boolean(errors.comision)}
                          helperText={touched.comision && errors.comision}
                          onBlur={handleBlur}
                        />

                        <TextField
                          id="renta"
                          name="renta"
                          label="Renta"
                          //sx={{ width: "16%" }}
                          sx={StyleInputs(16, 45)}
                          value={values.renta}
                          onChange={handleChange}
                          size="small"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          error={touched.renta && Boolean(errors.renta)}
                          helperText={touched.renta && errors.renta}
                          onBlur={handleBlur}
                        />

                        <TextField
                          id="remesa"
                          name="remesa"
                          label="Remesa"
                          //sx={{ width: "16%" }}
                          sx={StyleInputs(16, 47)}
                          value={values.remesa}
                          onChange={handleChange}
                          size="small"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          error={touched.remesa && Boolean(errors.remesa)}
                          helperText={touched.remesa && errors.remesa}
                          onBlur={handleBlur}
                        />
                        <TextField
                          id="otrosIngresos"
                          name="otrosIngresos"
                          label="Otros Ingresos"
                          //sx={{ width: "16%" }}
                          sx={StyleInputs(16, 45)}
                          value={values.otrosIngresos}
                          onChange={handleChange}
                          size="small"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          error={
                            touched.otrosIngresos &&
                            Boolean(errors.otrosIngresos)
                          }
                          helperText={
                            touched.otrosIngresos && errors.otrosIngresos
                          }
                          onBlur={handleBlur}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="cliente-informacion-seccion">
                        <TextField
                          id="ventas"
                          name="ventas"
                          label="Ventas"
                          sx={StyleInputs(20, 45)}
                          //sx={{ width: "20%" }}
                          value={values.ventas}
                          onChange={handleChange}
                          size="small"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          error={touched.ventas && Boolean(errors.ventas)}
                          helperText={touched.ventas && errors.ventas}
                          onBlur={handleBlur}
                        />

                        <TextField
                          id="renta"
                          name="renta"
                          label="Renta"
                          sx={StyleInputs(20, 47)}
                          //sx={{ width: "20%" }}
                          value={values.renta}
                          onChange={handleChange}
                          size="small"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          error={touched.renta && Boolean(errors.renta)}
                          helperText={touched.renta && errors.renta}
                          onBlur={handleBlur}
                        />

                        <TextField
                          id="remesa"
                          name="remesa"
                          label="Remesa"
                          sx={StyleInputs(20, 45)}
                          //sx={{ width: "20%" }}
                          value={values.remesa}
                          onChange={handleChange}
                          size="small"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          error={touched.remesa && Boolean(errors.remesa)}
                          helperText={touched.remesa && errors.remesa}
                          onBlur={handleBlur}
                        />

                        <TextField
                          id="otrosIngresos"
                          name="otrosIngresos"
                          label="Otros Ingresos"
                          sx={StyleInputs(20, 47)}
                          //sx={{ width: "20%" }}
                          value={values.otrosIngresos}
                          onChange={handleChange}
                          size="small"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          error={
                            touched.otrosIngresos &&
                            Boolean(errors.otrosIngresos)
                          }
                          helperText={
                            touched.otrosIngresos && errors.otrosIngresos
                          }
                          onBlur={handleBlur}
                        />
                      </div>
                    </>
                  )}

                  <div className="cliente-informacion-seccion">
                    <TextField
                      disabled
                      id="totalIngresos"
                      name="totalIngresos"
                      label="Total Ingresos"
                      sx={StyleInputs(20, 33)}
                      //sx={{ width: "20%" }}
                      value={calcularTotalIngresos(values)}
                      onChange={handleChange}
                      size="small"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      error={
                        touched.totalIngresos && Boolean(errors.totalIngresos)
                      }
                      helperText={touched.totalIngresos && errors.totalIngresos}
                      onBlur={handleBlur}
                    />

                    <TextField
                      id="detalleOtrosIngresos"
                      name="detalleOtrosIngresos"
                      type="text"
                      label="Detalle Otros Ingresos"
                      sx={StyleInputs(70, 60)}
                      //sx={{ width: "70%" }}
                      value={values.detalleOtrosIngresos}
                      onChange={handleChange}
                      size="small"
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      error={
                        touched.detalleOtrosIngresos &&
                        Boolean(errors.detalleOtrosIngresos)
                      }
                      helperText={
                        touched.detalleOtrosIngresos &&
                        errors.detalleOtrosIngresos
                      }
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="confirmar-estados-servicios">
                    <SCSLoadingButton loading={loading} titulo="GUARDAR" />

                    {datosEnviadosCorrectamente && <AcceptServiceresponse />}
                  </div>
                </SCSAccordion>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <SpinnerLoadSrx />
      )}
    </>
  );
};

export default FormClienteEstadoFinanciero;
