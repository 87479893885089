import React, { useState } from "react";
import Box from "@mui/material/Box";

import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import BotonSalirLimpiarStorageUsuario from "./BotonSalirLimpiarStorageUsuario";
import BotonDesbloquearUsuario from "../Components/BotonDesbloquearUsuario";
import { Card } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DetalleInformacionUsuario = ({ idUsuario, usuario, keyUsuario }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // console.log(usuario);

  const rows = [];
  rows.push(usuario);

  return (
    <>
      <BotonSalirLimpiarStorageUsuario />

      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
          <TabPanel value={value} index={0}>
            <BotonDesbloquearUsuario usuario={usuario} />
          </TabPanel>
        </Box>

        <div className="header-client-container">
          <Card>
            <header className="header-client-information">
              <div>
                <h3 align="center">
                  <b>Usuario</b>
                </h3>
                <h4 align="center">{usuario.key}</h4>
              </div>
              <div>
                <h3 align="center">
                  <b>Email</b>
                </h3>
                <h4 align="center">{usuario.email}</h4>
              </div>
              <div>
                <h3 align="center">
                  <b>Nombres</b>
                </h3>
                <h4 align="center">{usuario.nombres}</h4>
              </div>
              <div>
                <h3 align="center">
                  <b>Apellido</b>
                </h3>
                <h4 align="center">{usuario.apellidos}</h4>
              </div>
              <div>
                <h3 align="center">
                  <b>Estado Actual</b>
                </h3>
                <h4 align="center">{usuario.estado}</h4>
              </div>
            </header>
          </Card>
        </div>
      </Box>
    </>
  );
};

export default DetalleInformacionUsuario;
