import React, { useState } from "react";

import { Button, IconButton } from "@mui/material";
import AddCommentIcon from "@mui/icons-material/AddComment";
import FormComentarios from "../components/FormComentarios";

const BotonIngresarComentarios = ({ idSiniestro }) => {
  const [isComment, setIsComment] = useState(true);

  const ingresarNuevoComentario = () => {
    console.log("nuevo comentario ingresado ");
    setIsComment(!isComment);
  };

  return (
    <>
      <div className="botonComentario-container">
        <Button
          variant="contained"
          size="large"
          onClick={() => ingresarNuevoComentario()}
          endIcon={<AddCommentIcon />}
        >
          Añadir Comentario
        </Button>
      </div>

      {isComment && (
        <>
          <FormComentarios idSiniestro={idSiniestro} />
        </>
      )}
    </>
  );
};

export default BotonIngresarComentarios;
