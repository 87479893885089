import React from 'react';
import Box from '@mui/material/Box';
//import makeStyles from '@material-ui/core/styles/makeStyles';
import { CircularProgress } from "@mui/material";

// const useStyles = makeStyles(theme => ({
//   circularProgressRoot: {
//     position: 'absolute',
//     left: 0,
//     top: 0,
//     zIndex: 999999,
//     width: '100%',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
// }));

const PageLoader = () => {
  //const classes = useStyles();

  return (
      //className={classes.circularProgressRoot}>
    <Box >
      <CircularProgress />
    </Box>
  );
};

export default PageLoader;
