import React, { Fragment } from "react";
import Switch from "@mui/material/Switch";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import DateControlGrid from "../Date/DateControlGrid";
import { formatDate } from "../../../../utils/common";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Clear from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";

const styles = {
  numericInput: {
    textAlign: "right",
    width: "100%",
  },
};

const BooleanFormatterComponent = ({ value, row, commitChanges, column }) => {
  const onValueChange = (event) =>
    commitChanges({
      changed: {
        [row.id]: {
          [column.name]: event.target.checked,
        },
      },
    });
  return (
    <Checkbox
      tabIndex={0}
      checked={value}
      onChange={onValueChange}
      inputProps={{ "aria-label": "primary checkbox" }}
    />
  );
};

const BooleanFormatter = ({ value }) => (
  <Checkbox color="primary" checked={value} style={{ height: "10px" }} />
);

const BooleanEditor = ({
  value,
  onValueChange,
  onBlur,
  onKeyDown,
  autoFocus,
}) => (
  <Checkbox
    checked={value}
    onChange={(event) => onValueChange(!value)}
    onBlur={onBlur}
    onKeyDown={onKeyDown}
    color={"primary"}
    value={"true"}
    autoFocus={autoFocus}
  />
);
const BooleanTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={BooleanFormatter}
    editorComponent={BooleanEditor}
    {...props}
  />
);

const DateFormatter = ({ value }) => formatDate(value);

class DateEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
    };
  }

  render() {
    const { column, value, onValueChange, onBlur, onKeyDown } = this.props;
    const { touched } = this.state;
    return (
      <Fragment>
        <DateControlGrid
          name={column.name}
          value={typeof value === "string" ? new Date(value) : value}
          onChange={(date) => {
            onValueChange(date);
          }}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onFocus={() => this.setState({ touched: true })}
          error={column.error !== undefined}
        />
        <FormHelperText
          style={{
            color: column.error !== undefined ? "#f44336" : "grey",
            marginLeft: "10px",
          }}
        >
          {column.error}
        </FormHelperText>
      </Fragment>
    );
  }
}

const DateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={DateFormatter}
    editorComponent={DateEditor}
    {...props}
  />
);

const getCurrencyInputValue = (value) => (value === undefined ? "" : value);

const CurrencyEditorBase = ({ value, onValueChange, classes }) => {
  const handleChange = (event) => {
    const { value: targetValue } = event.target;
    if (targetValue.trim() === "") {
      onValueChange();
      return;
    }
    onValueChange(parseInt(targetValue, 10));
  };
  return (
    <Input
      type="number"
      classes={{
        input: classes.numericInput,
      }}
      fullWidth
      value={getCurrencyInputValue(value)}
      inputprops={{
        min: 0,
        placeholder: "Filter...",
      }}
      onChange={handleChange}
    />
  );
};

const CurrencyEditor = withStyles(styles)(CurrencyEditorBase);
const CurrencyFormatter = ({ value }) => `$${value}`;

const CurrencyTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={CurrencyFormatter}
    editorComponent={CurrencyEditor}
    {...props}
  />
);

const getNumberInputValue = (value) => (value === undefined ? "" : value);

class NumberEditorBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
    };
  }

  handleChange = (event) => {
    const { onValueChange } = this.props;
    const { value: targetValue } = event.target;
    if (targetValue.trim() === "") {
      onValueChange();
      return;
    }
    onValueChange(parseInt(targetValue, 10));
  };

  render() {
    const { touched } = this.state;
    const { value, column, onBlur, onKeyDown, autoFocus } = this.props;
    return (
      <TextField
        type="number"
        fullWidth
        value={getNumberInputValue(value)}
        inputprops={{
          min: 0,
        }}
        onChange={this.handleChange}
        onKeyDown={onKeyDown}
        onBlur={() => {
          this.setState({ touched: true });
          if (typeof onBlur == "function") {
            onBlur();
          }
        }}
        error={column.error !== undefined}
        helperText={column.error}
        autoFocus={autoFocus}
      />
    );
  }
}

const NumberEditor = withStyles(styles)(NumberEditorBase);
const NumberFormatter = ({ value }) => `${value}`;

const NumberTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={NumberFormatter}
    editorComponent={NumberEditor}
    {...props}
  />
);

const getPercentInputValue = (value) =>
  value === undefined ? "" : (value * 100).toFixed(1);

const PercentFormatter = ({ value }) => `${getPercentInputValue(value)}%`;

const PercentEditorBase = ({ value, onValueChange, classes }) => {
  const handleChange = (event) => {
    const { value: targetValue } = event.target;
    if (targetValue === "") {
      onValueChange();
      return;
    }
    onValueChange(Math.min(Math.max(parseFloat(targetValue / 100), 0), 1));
  };
  return (
    <Input
      type="number"
      classes={{
        input: classes.numericInput,
      }}
      fullWidth
      value={getPercentInputValue(value)}
      inputprops={{
        step: 0.1,
        min: 0,
        max: 100,
        placeholder: "Filtro...",
      }}
      onChange={handleChange}
    />
  );
};

const PercentEditor = withStyles(styles)(PercentEditorBase);

const PercentTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={PercentFormatter}
    editorComponent={PercentEditor}
    {...props}
  />
);

const getStringInputValue = (value) =>
  value === undefined || value === null ? "" : value;

class StringEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
    };
  }

  handleChange = (event) => {
    const { onValueChange } = this.props;
    const { value: targetValue } = event.target;
    onValueChange(targetValue.toUpperCase());
  };

  render() {
    const { value, column, onBlur, onKeyDown, row, autoFocus } = this.props;
    const { touched } = this.state;

    return (
      <TextField
        fullWidth
        value={getStringInputValue(value)}
        onChange={this.handleChange}
        onKeyDown={onKeyDown}
        onBlur={() => {
          this.setState({ touched: true });
          if (typeof onBlur == "function") {
            onBlur();
          }
        }}
        error={row ? column.error !== undefined : false}
        helperText={column.error}
        autoFocus={autoFocus}
        InputProps={{
          readOnly: column.disabled,
        }}
      />
    );
  }
}

const StringTypeProvider = (props) => {
  return <DataTypeProvider editorComponent={StringEditor} {...props} />;
};

class BasicStringEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
    };
  }

  handleChange = (event) => {
    const { onValueChange } = this.props;
    const { value: targetValue } = event.target;
    onValueChange(targetValue);
  };

  render() {
    const { value, column, onBlur, onKeyDown, row, autoFocus } = this.props;
    const { touched } = this.state;

    return (
      <TextField
        fullWidth
        value={getStringInputValue(value)}
        onChange={this.handleChange}
        onKeyDown={onKeyDown}
        onBlur={() => {
          this.setState({ touched: true });
          if (typeof onBlur == "function") {
            onBlur();
          }
        }}
        error={row ? column.error !== undefined : false}
        helperText={column.error}
        autoFocus={autoFocus}
        InputProps={{
          readOnly: column.disabled,
        }}
      />
    );
  }
}
const BasicStringTypeProvider = (props) => {
  return <DataTypeProvider editorComponent={BasicStringEditor} {...props} />;
};

class SelectEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
    };
  }

  render() {
    const { touched } = this.state;
    const {
      value,
      onValueChange,
      column,
      row,
      availableValues,
      onBlur,
      rowChanges,
      autoFocus,
    } = this.props;

    const hasChanged =
      rowChanges && rowChanges.length > 0 && rowChanges[row.id]
        ? rowChanges[row.id][column.dependsOnColumn]
        : undefined;

    const options = !column.dependsOnColumn
      ? availableValues[column.name]
      : hasChanged
      ? availableValues[rowChanges[row.id][column.dependsOnColumn]]
      : availableValues[row[column.dependsOnColumn]];

    const items = options ? options : [];

    return (
      <Fragment>
        <Select
          value={getStringInputValue(value)}
          onChange={(event) => onValueChange(event.target.value)}
          onBlur={() => {
            this.setState({ touched: true });
            if (typeof onBlur == "function") {
              onBlur();
            }
          }}
          style={{ width: "100%" }}
          error={column.error !== undefined}
          autoFocus={autoFocus}
        >
          {items.map((item) => {
            return (
              <MenuItem key={item.key} value={item.key} primarytext={item.name}>
                {" "}
                {item.name}{" "}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText
          style={{ color: column.error !== undefined ? "#fec03e" : "grey" }}
        >
          {column.error}
        </FormHelperText>
      </Fragment>
    );
  }
}

const SelectFormatter = (props) => {
  const { value, availableValues, column } = props;

  if (!column.dependsOnColumn) {
    const v = availableValues[column.name].filter((rec) => rec.key === value);
    return v && v[0] ? v[0].name : "";
  } else {
    const av = availableValues[props.row[column.dependsOnColumn]];

    const v = av ? av.filter((rec) => rec.key === value) : undefined;

    return v && v[0] ? v[0].name : "";
  }
};

const SelectTypeProvider = (props) => {
  const { availableValues, rowChanges } = props;

  const CustomSelectFormatter = (props1) => (
    <SelectFormatter
      {...props1}
      availableValues={availableValues}
      rowChanges={rowChanges}
    />
  );
  const CustomSelectEditor = (props2) => (
    <SelectEditor
      {...props2}
      availableValues={availableValues}
      rowChanges={rowChanges}
    />
  );

  return (
    <DataTypeProvider
      formatterComponent={CustomSelectFormatter}
      editorComponent={CustomSelectEditor}
      {...props}
    />
  );
};

export {
  BasicStringTypeProvider,
  StringTypeProvider,
  BooleanTypeProvider,
  DateTypeProvider,
  CurrencyTypeProvider,
  NumberTypeProvider,
  PercentTypeProvider,
  SelectTypeProvider,
  BooleanFormatterComponent,
  BooleanEditor,
};
