import React from "react";
import ListaCambioEstado from "../../containers/ListaCambioEstado";
import BotonGenericoSalir from "app/modules/shared/containers/BotonGenericoSalir";

const SolicitudCambioEstado = () => {
  return (
    <div>
      <BotonGenericoSalir />
      <ListaCambioEstado />
    </div>
  );
};

export default SolicitudCambioEstado;
