import { useState, useEffect } from "react";

import { consultaConyugue } from "../services/clienteService";

export const useGetConyugue = ({ idCliente }) => {
  const [conyugue, setClienteInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [idConyugueAux, setIdConyugueAux] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const informacionConyugue = await consultaConyugue(idCliente);

        /*  console.log(informacionConyugue); */

        if (informacionConyugue.state) {
          setIdConyugueAux(informacionConyugue.data.idConyuge);
          setClienteInfo(informacionConyugue.data);
          setIsLoading(false);
        } else {
          setClienteInfo({});
          setIsLoading(false);
        }
      } catch (error) {
        setClienteInfo({});
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
  }, []);

  return { isLoading, conyugue, idConyugueAux, setIdConyugueAux };
};
