import React from "react";
import { Card } from "@mui/material";
import { useState } from "react";

import { formatoFechaEspacio } from "../../shared/validations/validaciones";

const HeaderBeneficiario = ({ informacionBeneficiario }) => {
  /*  const [beneficiario, setBeneficiario] = useState(""); */
  /* console.log({ informacionBeneficiario }); */

  const diccionarioParentesco = (codigoPrentesco) => {
    const codigoParentesco = {
      ABU: "ABUELO(A)",
      AMI: "AMIGO",
      CDO: "CONCUÑADO",
      CN: "CUÑADO(A)",
      COY: "CÓNYUGE",
      HER: "HERMANO(A)",
      HIJ: "HIJO(A)",
      MAD: "MADRE",
      MTR: "MADRASTRA",
      NIE: "NIETO/A",
      NUE: "NUERA",
      OT: "OTRO",
      PAD: "PADRE",
      PRI: "PRIMO(A)",
      PTR: "PADRASTRO",
      SOB: "SOBRINO(A)",
      SUE: "SUEGRO(A)",
      TIO: "TÍO(A)",
      TIT: "TITULAR",
      YER: "YERNO",
    };

    return codigoParentesco[codigoPrentesco];
  };

  return (
    <div className="header-client-container">
      <Card>
        <header className="header-client-information">
          <div>
            <h2>Beneficiario</h2>
          </div>
          <div>
            <h4>Identificación</h4>
            <h4>
              {informacionBeneficiario.identificacion
                ? informacionBeneficiario.identificacion
                : ""}
            </h4>
          </div>
          <div>
            <h4>Nombre</h4>
            <h4>{`${informacionBeneficiario.primerNombre} ${informacionBeneficiario.primerApellido}`}</h4>
          </div>

          <div>
            <h4>Parentesco</h4>
            <h4>
              {informacionBeneficiario.parentesco
                ? diccionarioParentesco(informacionBeneficiario.parentesco)
                : ""}
            </h4>
          </div>
          <div>
            <h4>Fecha Nacimiento</h4>
            <h4>
              {informacionBeneficiario.fechaNacimiento
                ? formatoFechaEspacio(informacionBeneficiario.fechaNacimiento)
                : ""}
            </h4>
          </div>
        </header>
      </Card>
    </div>
  );
};

export default HeaderBeneficiario;
