import React, { useEffect, useState } from "react";
import { useFormikContext, Formik, Form, Field, useField } from "formik";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { Alert, Button, Paper, Snackbar, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AodOutlinedIcon from "@mui/icons-material/AodOutlined";

import { obtenerCabeceraCliente } from "../services/creditoServices";

import "../styles/main.css";
import { generarCapturaFirma } from "app/modules/app-cliente/services/clienteService";
import TabFirmaFotoCliente from "../containers/tabFirmaFotoCliente";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import { Box } from "devextreme-react";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";

const BoxGenerarLinkFirma = ({
  idCli,
  cliente,
  identifCliente,
  idSolicitudChanger,
  setInfoGrabada,
  infoGrabada,
}) => {
  const { authUser } = useSelector(({ auth }) => auth);

  const [clienteInfo, setClienteInfo] = useState(null);
  const [idSolicitudActual, setIdSolicitudActual] = useState(null);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [alertaGuardar, setAlertaGuardar] = useState(false);
  const [mensajeError, setMensajeError] = useState("");

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [generoLink, setGeneroLink] = useState(false);
  //const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log("USUARIO EN FIRMA", identifCliente);
    //console.log("AQUI", authUser);
    getInformacionCliente(idCli);
  }, []);

  const getInformacionCliente = async (idCliente) => {
    await obtenerCabeceraCliente(idCliente)
      .then((response) => {
        //console.log(response);
        if (response.state) {
          setClienteInfo(response.data);
        } else console.log(response.message);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const configuracionDatosEnvio = async (values) => {
    setLoading(true);
    if (clienteInfo.celular != "") {
      try {
        console.log("Envio de peticion de firma al backend");
        const token = await generarCapturaFirma(
          idCli,
          clienteInfo.nombreCliente,
          clienteInfo.celular,
          authUser.estacion,
          authUser.key,
          authUser.usuarioFlujos[0].idGrupo
        ).then(() => {
          setOpen(true);
          setGeneroLink(true);
          setDatosEnviadosCorrectamente(true);
          if (setInfoGrabada) {
            setInfoGrabada((args) => ({
              ...args,
              firma: true,
            }));
          }

          setTimeout(() => {
            setLoading(false);
            //setDatosEnviadosCorrectamente(false);
          }, 3000);
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setMensajeError(
        "No se encuentra un celular registrado en la informacion de cliente"
      );
      setAlertaGuardar(true);

      setTimeout(() => {
        setAlertaGuardar(false);
      }, 5000);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  if (idCli < 0) {
    return <h2>Debe crear primero un cliente !!</h2>;
  }
  return (
    <>
      <SCSAccordion tituloCabecera="Documentos Digitales">
        <Formik
          initialValues={{}}
          onSubmit={(values, { setSubmitting }) => {
            // console.log(values);
            configuracionDatosEnvio(values);
          }}
        >
          {({ values, handleChange }) => (
            <Form>
              {/* Informacion de solicitud */}
              <Stack alignItems="center">
                <SCSLoadingButton
                  loading={loading}
                  titulo=".   Generar link a Whatsapp para captura de documentos   ."
                />
                {generoLink && (
                  <Alert sx={{ mt: 2 }} severity="success">
                    Ha generado un link de captura en su dispositivo móvil, puede continuar.
                  </Alert>
                )}
              </Stack>
              {/* <Button type="submit" className="link-boton-firma">
                  <img
                    src={"/images/logo/FirmaDigital.png"}
                    alt="boton firma digital"
                  />
                  Generar petición de firma digital en dispositivo de Cliente 
                </Button> */}
              <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
              >
                <Alert
                  variant="filled"
                  onClose={handleClose}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  El link se ha enviado a su whatsapp
                </Alert>
              </Snackbar>
              {alertaGuardar && (
                <Alert severity="error" variant="filled">
                  {mensajeError}
                </Alert>
              )}
            </Form>
          )}
        </Formik>
        {/* {!generoLink ? ( */}
        <TabFirmaFotoCliente
          idCli={idCli}
          cliente={cliente}
          identifCliente={identifCliente}
          idSolicitudChanger={idSolicitudChanger}
          setInfoGrabada={setInfoGrabada}
          infoGrabada={infoGrabada}
          inhabilitar={generoLink}
        />

        {/* // ) : (
        //   <Alert sx={{mt:2}} severity="success">Ha generado un link Puede continuar.</Alert>
        // )} */}
      </SCSAccordion>
    </>
  );
};

export default BoxGenerarLinkFirma;
