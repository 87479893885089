import React, { useEffect, useState } from "react";

import { Skeleton } from "@mui/material";

import {
  SCSTableData,
  SCSTableDataRow,
  SCSTableDataCell,
} from "app/modules/common/components/TableData/SCSTableData";

import { consultarMovimientosCliente } from "../services/transaccionalService";
import { formatoFecha } from "app/modules/shared/validations/validaciones";
import DetalleOpcionGenerica from "./DetalleOpcionGenerica";

const DetalleMovimientosCliente = ({
  cabenceraTransaccional,
  datosBusqueda,
  alturaResolucion,
}) => {
  const [pagosCliente, setPagosCliente] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaListaAutorizaciones = await consultarMovimientosCliente(
          cabenceraTransaccional.idCuenta
        );

        console.log(respuestaListaAutorizaciones);

        if (
          respuestaListaAutorizaciones.state &&
          respuestaListaAutorizaciones.data
        ) {
          setPagosCliente(respuestaListaAutorizaciones.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    getData();
  }, []);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={alturaResolucion}
        sx={{ borderRadius: "32px" }}
      />
    );
  }

  if (cabenceraTransaccional.idCuenta <= 0) {
    return (
      <DetalleOpcionGenerica mensaje={"No existe información del cliente."} />
    );
  }

  const columnsData = [
    { id: 1, name: "N°" },
    { id: 2, name: "Fecha" },
    { id: 3, name: "Codigo" },
    { id: 3, name: "Local" },
    { id: 4, name: "Descripcion" },
    { id: 4, name: "Cuota" },
    { id: 4, name: "Valor" },
    { id: 4, name: "Saldo Dif." },
  ];

  return (
    <>
      <SCSTableData columnsData={columnsData} heightTable={alturaResolucion}>
        {pagosCliente.map((pago, index) => (
          <SCSTableDataRow key={index}>
            <SCSTableDataCell align="center">{index + 1}</SCSTableDataCell>
            <SCSTableDataCell align="center">
              {formatoFecha(pago.fecha)}
            </SCSTableDataCell>
            <SCSTableDataCell>{pago.codigo}</SCSTableDataCell>
            <SCSTableDataCell>{pago.local}</SCSTableDataCell>
            <SCSTableDataCell>{pago.descripcion}</SCSTableDataCell>
            <SCSTableDataCell align="center">{pago.cuota}</SCSTableDataCell>
            <SCSTableDataCell align="center">{`$ ${pago.valor}`}</SCSTableDataCell>
            <SCSTableDataCell align="center">{`$ ${pago.saldo}`}</SCSTableDataCell>
          </SCSTableDataRow>
        ))}
      </SCSTableData>
    </>
  );
};

export default DetalleMovimientosCliente;
