import React, { useState } from "react";

import { Button, Card, CardContent, Typography } from "@mui/material";
import ModalAlertaNotificacion from "./ModalAlertaNotificacion";
import { crearCertificadoNotificacion } from "../services/solicitudesService";
import handleDownlPdf from "app/utils/document/documentosService";
import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";
import { LoadingButton } from "@mui/lab";
import ModalAlertaNotificacionEstandar from "./ModalAlertaNotificacionEstandar";
import { useEffect } from "react";

const BotonNotificacionCancelacionSeguro = ({
  solicitud,
  informacionSolicitud,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    console.log({ solicitud });
  }, []);

  /*  const notificarCancelacionSeguro1 = async () => {
    console.log({ solicitud });
    const informacionPrecancelacion = {
      idRequerimiento: solicitud.idRequerimiento,
      datosRequerimiento: {
        idRequerimiento: solicitud.idRequerimiento,
        idDatosRequerimiento:
          solicitud.datosRequerimientos[0].idDatosRequerimiento,

        referencia: solicitud.datosRequerimientos[0].referencia,
        fecha: solicitud.datosRequerimientos[0].fecha,

        factura: solicitud.datosRequerimientos[0].factura,
        operacion: solicitud.datosRequerimientos[0].operacion,
        plazo: solicitud.datosRequerimientos[0].plazo,
        detalle: solicitud.datosRequerimientos[0].detalle,

        esActivo: true,
      },
    };

    console.log(informacionPrecancelacion);

    try {
      setLoading(true);

      const respuestaPrecancelacion = await crearCertificadoNotificacion(
        informacionPrecancelacion
      );

      console.log(respuestaPrecancelacion);

      if (respuestaPrecancelacion.state) {
        handleDownlPdf(
          respuestaPrecancelacion.data,
          `CRT_CNLSEG_${
            solicitud.idRequerimiento
          }_${fechasDocumentosSiniestro()}`
        );
        setOpen(true);

        setTimeout(() => {
          setLoading(false);

          navigate.push("/initialHome");
        }, 3000);
      } else {
        alert(`${respuestaPrecancelacion.message}`);
      }
    } catch (error) {
      alert(`${error.response.data.message}`);
      console.log(error.response);
      console.log(error.response.data.message);
    }
  }; */

  const notificarCancelacionSeguro = async () => {
    setLoading(true);

    try {
      const respuestaCertificadoSinDeuda = await crearCertificadoNotificacion(
        "",
        solicitud.idRequerimiento
      );

      console.log(respuestaCertificadoSinDeuda);
      if (respuestaCertificadoSinDeuda.state) {
        handleDownlPdf(
          respuestaCertificadoSinDeuda.data,
          `CRT_CNLSEG_${
            solicitud.idRequerimiento
          }_${fechasDocumentosSiniestro()}`
        );
        setOpen(true);
      } else {
        alert(`${respuestaCertificadoSinDeuda.message}`);
      }
    } catch (error) {
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <>
      <Card sx={{ width: "85%" }}>
        <CardContent className="card_cancelacion_container">
          <Typography
            gutterBottom
            variant="h3"
            component="div"
            style={{
              backgroundColor: "#000000",
              color: "#ffffff",
              padding: "0.5rem",
            }}
          >
            Información Cancelación de Seguro
          </Typography>

          <Typography
            variant="h3"
            component="div"
            color="text.secondary"
            style={{
              padding: "0.5rem",
            }}
            className="informacion_notificacion_seguro"
          >
            <div className="detalle_notificacion_seguro">
              {`Local:   ${informacionSolicitud.nombreLocal}`}
              <br />
              {`Nombre:   ${informacionSolicitud.nombre}`}
              <br />
              {`Email:   ${informacionSolicitud.email}`}
              <br />
              {`Celular:   ${informacionSolicitud.celular}`}
            </div>

            <div className="boton_notificacion_seguro">
              <LoadingButton
                sx={{ width: "80%" }}
                type="buton"
                size="medium"
                loading={loading}
                loadingPosition="end"
                variant="contained"
                onClick={() => notificarCancelacionSeguro()}
              >
                Notificar
              </LoadingButton>
            </div>
          </Typography>
        </CardContent>
      </Card>

      <ModalAlertaNotificacionEstandar
        open={open}
        setOpen={setOpen}
        titulo="Notificación Cancelación De Seguro"
        mensaje="Solicitud Notificada con exito!!"
      />
    </>
  );
};

export default BotonNotificacionCancelacionSeguro;
