import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import ReporteSegurosPolisas from "./ReporteSegurosPolisas/ReporteSegurosPolisas";

import "../styles/main.css";

const SegurosReportePolisas = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Reportes - Seguros</h2>

      <ReporteSegurosPolisas />
    </div>
  );
};

export default SegurosReportePolisas;
