import http from "../../../comunication/http-common";

export const consultarPrestadorMedico = async (ruc) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };

    const url = `${process.env.REACT_APP_API_GESTION}citamedica/ObtenerPrestadorMedico?ruc=${ruc}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const consultarProductosServimax = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/ObtenerProductoEstado?identificacion=${identificacion}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const consultarCiudadesCitas = async () => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/ObtenerCiudades`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://localhost:5011/citamedica/ObtenerPrestadoresMedicos?idUbiGeo1=17&idUbiGeo2=178&tipoPrestador=PRESTCOD

export const consultarPrestadorMedicoXZona = async (
  ubi1,
  ubi2,
  tipoPrestador
) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/ObtenerPrestadoresMedicos?idUbiGeo1=${ubi1}&idUbiGeo2=${ubi2}&tipoPrestador=${tipoPrestador}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://localhost:5011/citamedica/ObtenerEspecialidad?tipoPrestador=PRESTCEM
export const consultarEspecialidad = async (tipoPrestador) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/ObtenerEspecialidad?tipoPrestador=${tipoPrestador}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//citamedica/ObtenerBeneficiarios?identificacion=1722958459
export const consultarBeneficiariosCliente = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/ObtenerBeneficiarios?identificacion=${identificacion}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://localhost:5011/citamedica/ObtenerCitasXCliente?idCliente=247600
export const consultarCitasMedicas = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/ObtenerCitasXCliente?idCliente=${idCliente}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
/* http://10.10.0.79:5000/citamedica/ObtenerCitasMedicasXCliente?idCliente=247600 */
export const consultarCitasMedicasXCliente = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/ObtenerCitasMedicasXCliente?idCliente=${idCliente}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
/* http://10.10.0.79:5016/citamedica/ObtenerPrestadorMedicoXId?idPrestador=289 */
export const consultarPrestadorXIdPrestador = async (idPrestador) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/ObtenerPrestadorMedicoXId?idPrestador=${idPrestador}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
/* http://localhost:5016/CitaMedica/CancelarCitaMedica?IdCitaMedica=27124&EstadoCita=CITACANPRE */
export const cancelarCitaMedicaPrestador = async (idCitaMedica) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/CancelarCitaMedica?IdCitaMedica=${idCitaMedica}&EstadoCita=CITACANPRE`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const cancelarCitaMedicaCliente = async (idCitaMedica) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/CancelarCitaMedica?IdCitaMedica=${idCitaMedica}&EstadoCita=CITACANCLI`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const crearPrestadorMedico = (data) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/GuardarPrestadorMed`;
    http
      .post(url, data,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const crearCitaMedica = (data) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/GuardarCitaMedica`;
    http
      .post(url, data,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const crearBeneficiarioCliente = (data) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/GuardarBeneficiario`;
    http
      .post(url, data,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
