import React, { useState, useEffect, Fragment } from "react";
import { Form, Field } from "react-final-form";
import MenuItem from "@mui/material/MenuItem";

import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import { OnChange } from "react-final-form-listeners";
//components
import { SaveButton, CancelButton } from "../../../common/components/Buttons";
//import ActionButton from '../../../common/components/TransactionalButtons/ActionButton';
import ProgressBar from "../../../common/components/ProgressBar";
import GenericGrid from "../../../common/components/GenericGrid";
import {
  maxLength,
  minLength,
  required,
  composeValidators,
  email,
} from "../../../common/components/RenderFields/validations";
//bootstrap styles
//import "bootstrap/dist/css/bootstrap.css";

import {
  onlyTextAndNumbers,
  onlyText,
  onlyNumber,
} from "../../../../utils/normalizers";

import {
  RenderSelectField,
  RenderTextField,
} from "../../../common/components/RenderFields";

import { emptyItem } from "../../../../utils/common";
import {
  Grid,
  
} from "@mui/material";



const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const FormUser = ({
  flagEdit,
  handlePopUpSubmit,
  handleClose,
  singleUser,
  roles,
}) => {
  const [showProgressBar, setShowProgressBar] = useState(false);
  //const [singleUser, setSingleUser] = useState(undefined);
  const [selectedidentificationType, setSelectedidentificationType] =
    useState(false);

  const classes = useStyles();

  const identificationType = [
    { id: 1, code: "CEDULA", value: "CEDULA" },
    { id: 2, code: "PASAPORTE", value: "PASAPORTE" },
  ];

  const oficinaType = [
    { id: 1, code: "1", value: "Matriz" },
    { id: 2, code: "2", value: "Oficina 1" },
  ];
  const ubicacionType = [
    { id: 1, code: "1701", value: "Quito" },
    { id: 2, code: "0901", value: "Gye" },
  ];
  const estadoType = [
    { id: 1, code: "true", value: "Activo" },
    { id: 2, code: "false", value: "Inactivo" },
  ];
  return (
   
    <Fragment>
      <Form
        initialValues={flagEdit == true ? singleUser : undefined}
        onSubmit={handlePopUpSubmit}
        subscription={{ valid: true, submitting: true }}
        validate={(values) => {
          const errors = {};
        }}
        render={({ handleSubmit, submitting, valid }) => (
          <form onSubmit={handleSubmit} style={{ padding: "10px" }}>
            
            <Grid container  spacing={2}>  
              <Grid item xs={4}>
                  <Field
                    name="key"
                    label={"Código"}
                    disabled={flagEdit}
                    component={RenderTextField}
                    subscription={{ value: true, error: true, touched: true }}
                    parse={onlyText}
                    validate={composeValidators(required,maxLength(32))}
                  />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="tipoIdentificacion"
                  label={"Tipo Identificación"}
                  disabled={flagEdit}
                  component={RenderSelectField}
                  subscription={{ value: true, error: true, touched: true }}
                  validate={required}
                >
                  {emptyItem.concat(identificationType).map((item, index) => {
                    return (
                      <MenuItem key={item.code} value={item.value}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
                </Field>
                    <OnChange name="tipoIdentificacion">
                      {(value, previous) => {
                        if (value != "" || value != undefined) {
                          setSelectedidentificationType(true);
                        } else {
                          setSelectedidentificationType(false);
                        }
                      }}
                    </OnChange>
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="identificacion"
                  label={"Identificación"}
                  disabled={flagEdit}
                  component={RenderTextField}
                  subscription={{ value: true, error: true, touched: true }}
                  validate={composeValidators(required,maxLength(32))}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="nombres"
                  label={"Nombres"}
                  component={RenderTextField}
                  subscription={{ value: true, error: true, touched: true }}
                  parse={onlyText}
                  validate={composeValidators(required,maxLength(32))}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="apellidos"
                  label={"Apellidos"}
                  component={RenderTextField}
                  subscription={{ value: true, error: true, touched: true }}
                  parse={onlyText}
                  validate={composeValidators(required,maxLength(32))}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="email"
                  label={"Email"}
                  disabled={flagEdit}
                  component={RenderTextField}
                  subscription={{ value: true, error: true, touched: true }}
                  validate={composeValidators(required, email)}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="oficina"
                  label={"Oficina"}
                  disabled={false}
                  component={RenderSelectField}
                  subscription={{ value: true, error: true, touched: true }}
                  validate={required}
                >
                  {emptyItem.concat(oficinaType).map((item, index) => {
                    return (
                      <MenuItem key={item.code} value={item.code}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="ubicacionGeografica2"
                  label={"Ciudad"}
                  disabled={false}
                  component={RenderSelectField}
                  subscription={{ value: true, error: true, touched: true }}
                  validate={required}
                >
                  {emptyItem.concat(ubicacionType).map((item, index) => {
                    return (
                      <MenuItem key={item.code} value={item.code}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
                </Field>
              </Grid>
              <Grid item xs={4}>    
                <Field
                  name="telefono1"
                  label={"Telefono"}
                  component={RenderTextField}
                  subscription={{ value: true, error: true, touched: true }}
                  parse={onlyNumber}
                  validate={composeValidators(required, maxLength(10))}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={4}>    
                <Field
                  name="activo"
                  label={"Estado"}
                  component={RenderSelectField}
                  subscription={{ value: true, error: true, touched: true }}
                  validate={required}
                >
                  {emptyItem.concat(estadoType).map((item, index) => {
                    return (
                      <MenuItem key={item.code} value={item.code}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
                </Field>
                </Grid>
              
                <Paper elvation={0}>
                  <label>Roles Usuarios</label>
                  <br />
                  <br />
                  <Field
                    name={"usuarioRol"}
                    columns={[
                      {
                        name: "idRol",
                        title: "Rol",
                      },
                      {
                        name: "estado",
                        title: "Estado",
                      },
                    ]}
                     booleanColumns={["estado"]}
                    component={GenericGrid}
                    commitValidate={(rows) => {}}
                    errorMessage={""}
                    selectColumns={["idRol"]}
                                      catalogs={{
                      idRol: roles.map((item) => {
                        return {
                          key: item.idRol,
                          value: item.idRol,
                          name: item.descripcion,
                        };
                      }),
                    }}
                    gridValidate={(row) => {
                      const errors = {};

                      if (!row.idRol) errors.idRol = " ";

                      return errors;
                    }}
                  />
                </Paper>
          
                <SaveButton type={"submit"} disabled={submitting || !valid} />
                <CancelButton onClick={handleClose} />
                {showProgressBar && <ProgressBar />}
                </Grid>
          </form>
        )}
      />
    </Fragment>
  );
};

export default FormUser;