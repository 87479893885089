import React, { useEffect, useState } from "react";

import ModalDocuments from "app/modules/common/components/Modals/ModalDocuments";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { consultarTablaAmortizacionXAutorizacion } from "../services/transaccionalService";

const VisualizarTablaAmortizacion = ({ open, setOpen, autorizacion }) => {
  const [documentoAux, setDocumentoAux] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getTablaAmortizaciones = async () => {
      try {
        const respuestaTablaAmortizacion =
          await consultarTablaAmortizacionXAutorizacion(autorizacion);

        /* console.log(respuestaTablaAmortizacion); */

        if (respuestaTablaAmortizacion.state) {
          const stringPDF = `data:application/pdf;base64,${respuestaTablaAmortizacion.data}`;
          setDocumentoAux(stringPDF);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          alert("Documento No Encontrado");
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    if (open) {
      getTablaAmortizaciones();
    }

    return () => {
      setIsLoading(true);
    };
  }, [autorizacion]);

  if (isLoading && open) {
    return <SpinnerLoadSrx />;
  }

  return (
    <div>
      <ModalDocuments open={open} setOpen={setOpen}>
        <iframe
          id="frame_docs"
          src={documentoAux}
          title="resumeClient"
          width="100"
          height="1100"
          frameborder="0"
          allowfullscreen
          style={{
            transform: `scale(${1.2})`,
            width: " 64vw",
            height: "102vh",
            border: "  0px",
            zoom: " 0.9,",
          }}
        />
      </ModalDocuments>
    </div>
  );
};

export default VisualizarTablaAmortizacion;
