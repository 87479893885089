import React, { useEffect, useState } from "react";
import ListaAutorizacionesCliente from "../components/ListaAutorizacionesCliente";
import CabeceraClienteTransaccional from "../components/CabeceraClienteTransaccional";
import { consultarCabeceraTsx } from "../services/transaccionalService";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { Paper } from "@mui/material";

const DetalleListaAutorizaciones = ({ datosBusqueda }) => {
  const [cabenceraTransaccional, setCabenceraTransaccional] = useState({
    idCuenta: 0,
  });
  const [validacionCuenta, setValidacionCuenta] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCabeceraTsx = async () => {
      try {
        const respuestaCabeceraTsx = await consultarCabeceraTsx(
          datosBusqueda.identificacion,
          datosBusqueda.idGrupo
        );

        console.log(respuestaCabeceraTsx);

        if (respuestaCabeceraTsx.state) {
          setCabenceraTransaccional(respuestaCabeceraTsx.data);

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    getCabeceraTsx();
  }, []);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  if (
    cabenceraTransaccional.idCuenta <= 0 ||
    cabenceraTransaccional.idCuenta === null
  ) {
    return (
      <Paper
        sx={{ width: "70%", padding: "2rem", borderRadius: "25px" }}
        elevation={3}
      >
        Cliente no posee cuenta.!!
      </Paper>
    );
  }

  return (
    <div>
      <CabeceraClienteTransaccional
        cabenceraTransaccional={cabenceraTransaccional}
      />

      <ListaAutorizacionesCliente
        datosBusqueda={datosBusqueda}
        cabenceraTransaccional={cabenceraTransaccional}
      />
    </div>
  );
};

export default DetalleListaAutorizaciones;
