import React, { useState } from "react";

import BuscadorAutorizaciones from "../../components/BuscadorAutorizaciones";
import BuscadorMovimientosServimax from "../../components/BuscadorMovimientosServimax";
import DetalleTransaccional from "../../containers/DetalleTransaccional";
import DetalleTransaccionalMobile from "../../containers/DetalleTransaccionalMobile";
import { useMediaQuery } from "@mui/material";

const TransaccionesCliente = () => {
  const [datosBusqueda, setDatosBusqueda] = useState({
    identificacion: "",
    mostrarBuscador: true,
    idMovimiento: 0,
    idGrupo: Number(localStorage.getItem("idGrupoStorage")),
    menuMobile: true,
  });

  const transactionModeMobile = useMediaQuery("(max-width:769px)");

  if (datosBusqueda.mostrarBuscador) {
    return (
      <>
        {datosBusqueda.idGrupo === 4 ? (
          <main className="gestion-siniestros-container">
            <BuscadorMovimientosServimax
              setDatosBusqueda={setDatosBusqueda}
              titulo="Consultar Movimientos Cliente"
            />
          </main>
        ) : (
          <main className="gestion-siniestros-container">
            <BuscadorAutorizaciones
              setDatosBusqueda={setDatosBusqueda}
              titulo="Consultar Movimientos Cliente"
            />
          </main>
        )}
      </>
    );
  }

  return (
    <>
      {transactionModeMobile ? (
        <DetalleTransaccionalMobile
          datosBusqueda={datosBusqueda}
          setDatosBusqueda={setDatosBusqueda}
        />
      ) : (
        <DetalleTransaccional
          datosBusqueda={datosBusqueda}
          setDatosBusqueda={setDatosBusqueda}
        />
      )}
    </>
  );
};

export default TransaccionesCliente;
