import React from "react";
import { useHistory } from "react-router-dom";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BotonSalirLimiarStorageQuejas = () => {
  const navigate = useHistory();

  /* limpia el storage con los datos de las quejas  */
  const handleSalirLimpiar = () => {
    window.localStorage.removeItem("idQuejaStorage");
    window.localStorage.removeItem("quejaStorage");
    window.localStorage.removeItem("idQuejaClienteStorage");
    navigate.push("/initialHome");
  };

  return (
    <div className="closeButton-container">
      <IconButton
        aria-label="cerrar"
        size="large"
        onClick={() => {
          handleSalirLimpiar();
        }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
    </div>
  );
};

export default BotonSalirLimiarStorageQuejas;
