import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
  LinearProgress,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
import { auth } from "@jumbo/services/auth/firebase/firebase";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_AVATARS, ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import Home from "../../home/index";
import { useDispatch } from "react-redux";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

//import { useOnLogin } from '../hook/index';
import { fetchError } from "../../../redux/actions/Common";

import {
  onLoginCli,
  onActualizarOtp,
  obtenerRegistroCliente,
  onEnviarOtpSms,
} from "../services/index";
import InfoPopoverButton from "app/modules/shared/components/InfoPopoverButton";
import { consultarGrupoGenerico } from "app/modules/app-configuracion/services/configuracionServices";
import HouseIcon from "@mui/icons-material/House";
import { preevaluacionCliente } from "app/modules/app-credito/services/creditoServices";
import { useOnLogin } from "../hook";
import RefreshIcon from "@mui/icons-material/Refresh";

const validationSchema = yup.object({
  cedula: yup
    .string("Ingrese su Cédula")
    //.email('Enter a valid email')
    .required("Cédula es obligatorio"),
  password: yup.string("Ingrese su Clave").required("Clave es obligatoria"),
});

const Login1 = () => {
  const dispatch = useDispatch();

  //const origen = localStorage.getItem("origen");
  //localStorage.setItem("origen", origen);

  const [origen, setOrigen] = useState(localStorage.getItem("origen"));
  const history = useHistory();
  const { setActiveLayout } = useJumboApp();
  const [send, setSend] = useState(false);
  const [cerrar, setCerrar] = useState(false);
  const [color, setColor] = useState("");
  const [url, setUrl] = useState("");
  useOnLogin(send, setSend);
  const { authUser } = useSelector(({ auth }) => auth);
  const [open, setOpen] = useState(false);
  const [mensajeCliente, setMensajeCiente] = useState("");
  const [continuaProceso, setContinuaProceso] = useState(false);
  const [emailOtp, setEmailOtp] = useState("");
  const [celularOtp, setCelularOtp] = useState(null);
  const [nombresOtp, setNombresOtp] = useState("");

  const [esperaLogin, setesperaLogin] = useState(true);
  const [loggedUser, setLoggedUser] = useState(false);
  const [mostarRefresh, setMostrarRefresh] = useState(false);
  const [refresh, setRefresh] = useState(false);

  React.useEffect(() => {
    //setSend(true);
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);

    setTimeout(() => {
      setSend(true);
      //obtenerGrupo();
    }, 50);

    setTimeout(() => {
      //console.log("paso 2");
      //setSend(true)
      obtenerGrupo();
    }, 3000);

    setTimeout(() => {
      loggedUser ? setMostrarRefresh(true) : setMostrarRefresh(true);
    }, 8000);

    switch (origen) {
      case "tvtas":
        setColor("#FF9201");
        setUrl("https://www.tventas.com/content/55-creditventas");
        break;
      case "corpfav":
        setColor("#e1251b");
        setUrl("https://www.creditofavorito.com/");
        break;
      default:
        setColor("#e1251b");
        setUrl("https://www.creditofavorito.com/");
        break;
    }

    const obtenerGrupo = async () => {
      const resp = await consultarGrupoGenerico(origen, 1);
      if (resp.state) {
        //localStorage.setItem("tipocliente", "NUEVO");
        localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
        setLoggedUser(true);
      } else {
        console.log(resp.message);
      }
    };

    return () => {
      setSend(false);
    };
  }, [refresh]);

  const iniciarSesionGenerico = async () => {
    //console.log("entro generico");
    setSend(true);
  };

  const obtenerGrupo2 = async () => {
    console.log("enttro a grupo 2");
    const resp = await consultarGrupoGenerico(origen, 1);
    if (resp.state) {
      //localStorage.setItem("tipocliente", "NUEVO");
      localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
      setLoggedUser(true);
    } else {
      console.log(resp.message);
    }
  };

  const handleCloseEspera = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      //console.log(reason);
      setesperaLogin(true);
    } else {
      setesperaLogin(false);
    }
  };

  const mutation = useAuthSignInWithEmailAndPassword(auth, {
    onError(error) {
      console.log(error);
    },
    onSuccess(data) {
      history.push("/");
    },
  });

  // const onSignIn = async (cedula, password) => {
  //   //debugger;
  //   try {
  //     localStorage.setItem("tipocliente", "EXISTE");
  //     let response = await onLoginCli(cedula, password);
  //     if (response.state) {
  //       sessionStorage.setItem("jwtToken", response.data.jwt.accessToken);

  //       let cedula = localStorage.getItem("cedula");
  //       let email = localStorage.getItem("email");

  //       response = await onActualizarOtp(email, cedula);
  //       if (response.state) {
  //         localStorage.setItem("tipocliente", "EXISTE");

  //         history.push("/auth-pages/otp");
  //       } else {
  //         alert(response.message);
  //         //dispatch(fetchError(data.message))
  //       }

  //       //history("/home/home"); history("/home/home");
  //     } else {
  //       dispatch(fetchError(response.message));
  //     }
  //   } catch (e) {
  //     dispatch(fetchError(e.message));
  //   }

  //   //     await onLoginCli(cedula, password)
  //   //       .then((response) => {

  //   //         console.log(response);
  //   //         if (response.state) {
  //   //             // guarda el token
  //   //             sessionStorage.setItem('jwtToken', data.data.jwt.accessToken);

  //   //             history("/home/home", {replace: true});
  //   //         } else console.log(response.message);
  //   //       })
  //   //       .catch((e) => {
  //   //         console.log(e);
  //   //       })
  // };

  const onSignIn = async (cedula, password) => {
    localStorage.setItem("tipocliente", "NUEVO");

    let response = await onLoginCli(cedula, password);
    if (response.state) {
      debugger;
      let emailOtp = response.data.userdto.email;
      let celularOtp = response.data.userdto.telefono1;
      let nombresOtp = response.data.userdto.nombres;

      if (
        response.data.userdto.rol == null ||
        response.data.userdto.rol ==
          process.env.REACT_APP_USUARIO_REGISTRO.replace("{0}", origen) ||
        response.data.userdto.rol == ""
      ) {
        debugger;
        setEmailOtp(response.data.userdto.email);
        setCelularOtp(response.data.userdto.telefono1);
        setNombresOtp(response.data.userdto.nombres);

        //no termino de registrarse en el flujo
        try {
          const respRegistro = await obtenerRegistroCliente(
            cedula,
            localStorage.getItem("idGrupoStorage")
          );

          const esAutoservicio = eval(localStorage.getItem("autoservicio"));
          let usuarioConsulta;
          if (esAutoservicio) {
            usuarioConsulta = localStorage.getItem("nombreMaquina");
          } else {
            usuarioConsulta = `LandingWeb-${origen}`;
          }
          if (respRegistro.state) {
            if (respRegistro.data.secuencia === 0) {
              try {
                const respPreevaluacion = await preevaluacionCliente(
                  cedula,
                  authUser.key,
                  usuarioConsulta,
                  true
                );
                if (respPreevaluacion.state) {
                  if (respPreevaluacion.data.validacion) {
                    setMensajeCiente(respPreevaluacion.data.mensaje);
                    setOpen(true);
                    setContinuaProceso(true);

                    sessionStorage.setItem(
                      "jwtToken",
                      response.data.jwt.accessToken
                    );
                    localStorage.setItem("cedula", cedula);
                    //setLoadingPreevaluar(false);
                  } else {
                    setMensajeCiente(respPreevaluacion.data.mensaje);
                    setOpen(true);
                    setContinuaProceso(false);
                    //setLoadingPreevaluar(false);
                  }
                } else {
                  console.log(respPreevaluacion.message);
                }
              } catch (e) {
                console.log(e);
              }
            } else {
              sessionStorage.setItem("jwtToken", response.data.jwt.accessToken);
              localStorage.setItem("cedula", cedula);

              try {
                const respPreevaluacion = await preevaluacionCliente(
                  cedula,
                  authUser.key,
                  usuarioConsulta,
                  true
                );
                if (respPreevaluacion.state) {
                  if (respPreevaluacion.data.validacion) {
                    setMensajeCiente(respPreevaluacion.data.mensaje);
                    setOpen(true);
                    setContinuaProceso(true);

                    sessionStorage.setItem(
                      "jwtToken",
                      response.data.jwt.accessToken
                    );
                    localStorage.setItem("cedula", cedula);
                    //setLoadingPreevaluar(false);
                  } else {
                    setMensajeCiente(respPreevaluacion.data.mensaje);
                    setOpen(true);
                    setContinuaProceso(false);
                    //setLoadingPreevaluar(false);
                  }
                } else {
                  console.log(respPreevaluacion.message);
                }
              } catch (e) {
                console.log(e);
              }

              // let email = response.data.userdto.email;

              // response = await onActualizarOtp(email, cedula);

              // if (response.state) {
              //   debugger;
              //   if (celularOtp)
              //     onEnviarOtpSms(nombresOtp, response.data, celularOtp,  localStorage.getItem("cedula"));

              //   localStorage.setItem("tipocliente", "EXISTE");

              //   history.push("/auth-pages/otpregistro");
              // }
            }
          } else {
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        // que si esta complatado el flujo de prospección
        //debugger;

        sessionStorage.setItem("jwtToken", response.data.jwt.accessToken);
        localStorage.setItem("cedula", cedula);

        let email = response.data.userdto.email;

        response = await onActualizarOtp(email, cedula);
        if (response.state) {
          debugger;
          if (celularOtp)
            onEnviarOtpSms(
              nombresOtp,
              response.data,
              celularOtp,
              localStorage.getItem("cedula")
            );

          localStorage.setItem("tipocliente", "EXISTE");

          history.push("/auth-pages/otp");
        } else {
          alert(response.message);
          //dispatch(fetchError(data.message))
        }
      }

      //history("/home/home"); history("/home/home");
    } else {
      dispatch(fetchError(response.message));
    }

    //     await onLoginCli(cedula, password)
    //       .then((response) => {

    //         console.log(response);
    //         if (response.state) {
    //             // guarda el token
    //             sessionStorage.setItem('jwtToken', data.data.jwt.accessToken);

    //             history("/home/home", {replace: true});
    //         } else console.log(response.message);
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //       })
  };

  const onCallForm = () => {
    localStorage.setItem("tipocliente", "NUEVO");
    history.push("/auth-pages/validarcedula");
  };

  const redirectFlujoPunta = async () => {
    let response = await onActualizarOtp(
      emailOtp,
      localStorage.getItem("cedula")
    );

    if (response.state) {
      if (celularOtp)
        onEnviarOtpSms(
          nombresOtp,
          response.data,
          celularOtp,
          localStorage.getItem("cedula")
        );

      localStorage.setItem("tipocliente", "EXISTE");

      history.push("/auth-pages/otpregistro");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRefresh = async () => {
    setSend(false);
    setMostrarRefresh(false);
    setRefresh(!refresh);
  };

  return (
    <>
      {!loggedUser ? (
        <Dialog
          onClose={handleCloseEspera}
          aria-labelledby="customized-dialog-title"
          open={esperaLogin}
          fullWidth
        >
          <DialogContent dividers>
            {!mostarRefresh ? (
              <Stack direction={"column"} spacing={5}>
                <Box marginTop={5}>
                  <LinearProgress color="inherit" />
                </Box>
                <Box margin={5}>
                  <Typography align="center">
                    Un momento estamos preparanto todo.
                  </Typography>
                </Box>
                <Box margin={5}></Box>
              </Stack>
            ) : (
              <>
                <Box margin={5}>
                  <Stack
                    direction={"column"}
                    spacing={5}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <RefreshIcon fontSize="large" />
                    <Button
                      size="lg"
                      variant="outlined"
                      //startIcon={<RefreshIcon />}
                      onClick={handleRefresh}
                    >
                      <Typography>
                        {" "}
                        Hubo un inconveniente, intenta de nuevo.
                      </Typography>
                    </Button>
                  </Stack>
                </Box>
              </>
            )}
          </DialogContent>
        </Dialog>
      ) : (
        <Div
          sx={{
            flex: 1,
            marginTop: 20,
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: (theme) => theme.spacing(4),
          }}
        >
          <Box
            component="img"
            src={`${process.env.REACT_APP_IMAGES_PATH}/logo-${origen}.png`}
            sx={{
              justifyContent: "center",
              width: 350,
              height: 140,
            }}
          />
          <Card sx={{ maxWidth: "100%", width: 360, mb: 4 }}>
            <Div sx={{ position: "relative", height: "200px" }}>
              <CardMedia
                component="img"
                height="200"
                sx={{ backgroundColor: color }}
              />
              <Div
                sx={{
                  flex: 1,
                  inset: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  // backgroundColor: (theme) =>
                  //   alpha(theme.palette.common.black, 0.5),
                  p: (theme) => theme.spacing(3),
                }}
              >
                <Typography
                  variant={"h2"}
                  sx={{
                    color: "common.white",
                    fontSize: "1.5rem",
                    mb: 0,
                  }}
                >
                  Iniciar Sesion
                </Typography>
              </Div>
            </Div>
            <CardContent sx={{ pt: 0 }}>
              <Formik
                validateOnChange={true}
                initialValues={{
                  cedula: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                  setSubmitting(true);
                  onSignIn(data.cedula, data.password);
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    {/* <Avatar
                  sx={{
                    width: 56,
                    height: 56,
                    marginLeft: "auto",
                    boxShadow: shadows[3],
                    transform: "translateY(-50%)",
                  }}
                /> */}
                    <Typography textAlign={"left"} variant={"h3"} mb={1}>
                      Si ya eres cliente de Crédito Directo, ingresa tu cédula y
                      clave para consultar datos de tu crédito.
                      <br />
                    </Typography>
                    {mutation.isError && <p>{mutation.error.message}</p>}
                    <Div sx={{ mb: 3, mt: 4 }}>
                      <JumboTextField fullWidth name="cedula" label="Cédula" />
                    </Div>
                    <Stack direction={"row"} sx={{ mb: 2, mt: 1 }}>
                      <JumboTextField
                        fullWidth
                        name="password"
                        label="Clave"
                        type="password"
                      />
                      <InfoPopoverButton
                        nombre={"CDL"}
                        texto={`<p> El codigo Dactilar se encuentra impreso en el reverso <br/>de la cédula de identidad, en la parte superior derecha</p>`}
                      >
                        <Stack alignItems={"center"} justifyContent={"center"}>
                          <Box
                            component="img"
                            src={`${process.env.REACT_APP_IMAGES_PATH}/dactilar.jpg`}
                            sx={{
                              justifyContent: "center",
                              width: 360,
                              height: 192,
                              mb: 2,
                            }}
                          />
                        </Stack>
                      </InfoPopoverButton>
                    </Stack>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                      loading={isSubmitting || mutation.isLoading}
                    >
                      Login
                    </LoadingButton>
                    <Typography textAlign={"center"} variant={"body1"} mb={1}>
                      No tienes cuenta?
                      <Link
                        underline="none"
                        href="#"
                        onClick={() => {
                          onCallForm();
                        }}
                      >
                        {` Registrate`}
                      </Link>
                    </Typography>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle
              sx={{
                backgroundColor: continuaProceso ? "#389F26" : "#BD0808",
                margin: 0,
              }}
              id="alert-dialog-title"
            >
              <Stack direction={"row"} spacing={3} alignItems={"center"}>
                <Typography style={{ color: "white" }} variant="h2">
                  {continuaProceso
                    ? "PREEVALUACION EXITOSA"
                    : "PREEVALUACION NO EXITOSA"}
                </Typography>
                {continuaProceso ? (
                  <SentimentSatisfiedAltIcon
                    style={{ color: "white" }}
                    fontSize="large"
                  />
                ) : (
                  <SentimentVeryDissatisfiedIcon
                    style={{ color: "white" }}
                    fontSize="large"
                  />
                )}
              </Stack>
            </DialogTitle>
            <DialogContent sx={{ mt: 5 }}>
              <Typography variant="h2">{mensajeCliente}</Typography>
              <Stack
                mt={5}
                direction={"row"}
                spacing={3}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Button
                  //variant="outlined"
                  size="large"
                  onClick={handleClose}
                  autoFocus
                >
                  Salir
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  disabled={!continuaProceso}
                  onClick={redirectFlujoPunta}
                >
                  Continuar
                </Button>
              </Stack>
            </DialogContent>
          </Dialog>
          <Button
            size="lg"
            variant="outlined"
            startIcon={<HouseIcon />}
            onClick={() => {
              window.open(url, "_self");
            }}
          >
            <Typography> Regresar a la pagina Principal</Typography>
          </Button>
        </Div>
      )}
    </>
  );
};

export default Login1;
