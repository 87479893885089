import InboxIcon from "@mui/icons-material/MoveToInbox";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import MovingIcon from "@mui/icons-material/Moving";

import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";

export const LISTA_MENU = [
  {
    id: 1,
    name: "Estado Solicitud",
    isSelected: false,
    iconMenu: <PostAddOutlinedIcon />,
  },
  {
    id: 2,
    /* name: "Estado Crédito", */
    name: "Consumos",
    isSelected: false,
    iconMenu: <InventoryOutlinedIcon />,
  },
  {
    id: 3,
    name: "Datos Seguro",
    isSelected: false,
    iconMenu: <SecurityOutlinedIcon />,
  },
  {
    id: 4,
    name: "Pagos",
    isSelected: false,
    iconMenu: <CreditScoreIcon />,
  },

  {
    id: 5,
    name: "Estados Cuenta",
    isSelected: false,
    iconMenu: <MovingIcon />,
  },
  {
    id: 6,
    name: "Mov. a la fecha",
    isSelected: false,
    iconMenu: <InboxIcon />,
  },
  {
    id: 7,
    name: "Siniestros",
    isSelected: false,
    iconMenu: <WarningAmberOutlinedIcon />,
  },
  {
    id: 8,
    name: "Quejas",
    isSelected: false,
    iconMenu: <SupportAgentRoundedIcon />,
  },
];

export const LISTA_OPCIONES = {
  ESTADO_SOLICITUD: 1,
  ESTADO_CREDITO: 2,
  DATOS_SEGURO: 3,
  PAGOS: 4,
  ESTADO_CUENTA: 5,
  MOV_FECHA: 6,
  SINIESTROS: 7,
  QUEJAS: 8,
};
