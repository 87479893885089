import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  FormControl,
  Grid,
  MenuItem,
  TextField,
  InputLabel,
  Select,
} from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import { consultaCatalogosSinPadre } from "../../app-cliente/services/Catalogos/catalogosService";

import {
  consultarInformacionAdicional,
  crearInformacionAdicional,
} from "../Services/siniestrosService";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

const FormInformacionAdicional = ({
  idSiniestro,
  idFlujo,
  idGrupo,
  setIdProductoAux,
}) => {
  const [infoAdicional, setInfoAdicional] = useState({});

  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [isloadding, setIsloadding] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaInformacionAdicional =
          await consultarInformacionAdicional(idSiniestro);

        /* console.log({ respuestaInformacionAdicional }); */

        if (respuestaInformacionAdicional.state) {
          setInfoAdicional(respuestaInformacionAdicional.data);
          setIsloadding(false);
        } else {
          setInfoAdicional({});
          setIsloadding(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  if (isloadding) {
    return <SpinnerLoadSrx />;
  }

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        /* sx={{ width: "14ch" }} */
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const configuracionDatosEnvio = async (values) => {
    setLoading(true);

    const informacionAdicional = {
      idInformacionAdicional: infoAdicional.idInformacionAdicional ?? 0,
      idSiniestro: Number(idSiniestro),
      horaSiniestro: values.horaSiniestro,
      edad: Number(values.edad),
      actividadEconomica: values.actividadEconomica,
      ocupacion: values.ocupacion,
      raza: values.raza.toUpperCase(),
      esActivo: true,
    };

    console.log({ informacionAdicional });

    try {
      const respuestaInformacionAdicional = await crearInformacionAdicional(
        informacionAdicional
      );

      console.log(respuestaInformacionAdicional);

      if (respuestaInformacionAdicional.state) {
        setDatosEnviadosCorrectamente(true);

        {
          setIdProductoAux &&
            setIdProductoAux(respuestaInformacionAdicional.data);
        }

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
        }, 1000);
      } else {
        alert(`${respuestaInformacionAdicional.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <Formik
      initialValues={{
        horaSiniestro: infoAdicional.horaSiniestro || "",
        edad: infoAdicional.edad || 0,
        actividadEconomica: infoAdicional.actividadEconomica || "",
        ocupacion: infoAdicional.ocupacion || "",
        raza: infoAdicional.raza || "",
      }}
      validate={(values) => {
        const errors = {};
      }}
      validationSchema={Yup.object({})}
      onSubmit={(values, { setSubmitting }) => {
        configuracionDatosEnvio(values);
      }}
    >
      {({ values, handleChange, touched, errors, handleBlur }) => (
        <Form>
          <SCSAccordion tituloCabecera="Información Adicional">
            <Grid container spacing={1} rowSpacing={1}>
              <Grid item md={12}>
                <Grid container spacing={1} rowSpacing={1}>
                  <Grid item md={12}>
                    {/* spacing entre filas */}
                    <Grid container spacing={1}>
                      <Grid item md={12}>
                        <div className="cliente-informacion-seccion">
                          {idFlujo === 5 && (
                            <>
                              <TextField
                                id="horaSiniestro"
                                label="Hora Siniestro"
                                type="time"
                                sx={{ width: "16%" }}
                                onChange={handleChange}
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 60, // 5 min
                                }}
                                value={values.horaSiniestro}
                                error={
                                  touched.horaSiniestro &&
                                  Boolean(errors.horaSiniestro)
                                }
                                helperText={
                                  touched.horaSiniestro && errors.horaSiniestro
                                }
                                onBlur={handleBlur}
                              />

                              <TextField
                                id="edad"
                                name="edad"
                                type="number"
                                label="Edad"
                                sx={{ width: "16%" }}
                                onChange={handleChange}
                                size="small"
                                value={values.edad}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />

                              <SelectItemsCatalogoSinPadre
                                pais="ECU"
                                especial="false"
                                catalogos="TIPOACTEC"
                                grupo={1}
                                name="actividadEconomica"
                                labelName="Actividad Economica"
                                componentSize="25%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <SelectItemsCatalogoSinPadre
                                pais="ECU"
                                especial="false"
                                catalogos="EMPDOR"
                                grupo={1}
                                name="ocupacion"
                                labelName="Ocupación"
                                componentSize="30%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />
                            </>
                          )}

                          {idFlujo === 6 && (
                            <TextField
                              id="raza"
                              name="raza"
                              type="text"
                              label="Raza"
                              sx={{ width: "25%" }}
                              value={values.raza}
                              onChange={handleChange}
                              size="small"
                              inputProps={{
                                style: {
                                  textTransform: "uppercase",
                                },
                              }}
                              error={touched.raza && Boolean(errors.raza)}
                              helperText={touched.raza && errors.raza}
                              onBlur={handleBlur}
                            />
                          )}
                        </div>

                        <div className="confirmar-estados-servicios">
                          <SCSLoadingButton
                            loading={loading}
                            titulo="GUARDAR"
                          />

                          {datosEnviadosCorrectamente && (
                            <AcceptServiceresponse />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </SCSAccordion>
        </Form>
      )}
    </Formik>
  );
};

export default FormInformacionAdicional;
