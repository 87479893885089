import React, {memo,useState,useEffect} from 'react';
//import { Routes, Route, useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { DataGrid, GridRowsProp, GridColDef,GridRenderCellParams  } from '@mui/x-data-grid';

import {
  listarSolicitudes, listarSolicitudesDevueltas
} from '../../services/creditoServices'


import "../../styles/main.css";


function DevueltasContent() {

  const [catalogos, setCatalogos] = useState([]);
  const [flagEdit, setflagEdit] = useState(false);
  const [solicitudes, setSolicitudes] = useState([]);
  const [idSolicitud, setIdSolicitud] = useState([]);
  const [flujoSol, setflujoSol]= useState("Revision");

  const history = useHistory();
  
  useEffect( () => {
    getSolicitudes();
  }, []);

  const getSolicitudes =() => {
     
    listarSolicitudesDevueltas()
    .then((response) => {
      if(response.state)
      {
        setSolicitudes(response.data);
      }
    })
    .catch((_errors) => {console.log(_errors); });
     
  };

  const renderDetailsButton = (params) => {
    return (
        <strong>
          <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() => {
                routeChange(params.id);
              }}
          >
              Gestionar
          </Button>
      </strong>
    )
  };

  const formatearFecha = (fecha) => {
    if (!fecha) {
      return "";
    }
    const fechaModificada = fecha.split("T");
    return fechaModificada[0];
};


  const columns = [
    { field: 'idSolicitud',hide: true, headerName: 'ID', width: 80,  headerClassName : 'header-grid-solicitud',
  },
    {
      field: 'numeroSolicitud',
      headerName: 'Numero de Solicitud',
      headerClassName : 'header-grid-solicitud',
      width: 200,
      editable: false,
    },
    {
      field: 'identificacion',
      headerName: 'Identificación',
      headerClassName : 'header-grid-solicitud',
      width: 160,
      editable: false,
    },
    
    {
      field: 'fechaSolicitud',
      headerName: 'Fecha de Solicitud',
      headerClassName : 'header-grid-solicitud',
      width: 200,
      editable: false,
      valueFormatter: (params)=>{
        return formatearFecha(params.value);
      }
      
    },
    {
      field: 'usuarioCreacion',
      headerName: 'Usuario',
      headerClassName : 'header-grid-solicitud',
      width: 160,
      editable: false,
    },
    {
      field: 'local',
      headerName: 'Local',
      headerClassName : 'header-grid-solicitud',
      width: 160,
      editable: false,
    },
    {
      field: 'revision',
      headerName: 'Revisión',
      headerClassName : 'header-grid-solicitud',
      width: 150,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
      
    },
    
  ];
  
  const rows = solicitudes;

  
  //const navigate = useNavigate(); 
  const routeChange = (idRow) =>{ 
    const path = "/credito/revisionAnalista"; 
    history.push(path ,  { state :{ id: idRow , flujo : flujoSol}} );
    //navigate(path , { state: { id: idRow , flujo : flujoSol} });
  };

  return (
    <div>
        <Box sx={{ height: 800, width: '100%' }}>
            <DataGrid
              getRowId={(row) => row.idSolicitud}
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
        </Box>        

    </div>
  );
}

export default memo(DevueltasContent);
