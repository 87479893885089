import React, { useEffect, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

import {
  FormControl,
  Grid,
  MenuItem,
  TextField,
  FormHelperText,
  InputLabel,
  Select,
} from "@mui/material";

import { formatoFecha } from "../../../shared/validations/validaciones";

import {
  consultaCabeceraProducto,
  consultarDetalleProducto,
  crearInformacionArticulo,
} from "../../Services/siniestrosService";

import { consultaCatalogosSinPadre } from "../../../app-cliente/services/Catalogos/catalogosService";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString={false}
      prefix="$ "
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const FormProductoGestion = ({ idSiniestro, idContrato, idGrupo }) => {
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [datosBien, setDatosBien] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      let informacionArticulo;
      let detalleProducto;

      try {
        const { state, data } = await consultarDetalleProducto(idSiniestro);
        console.log({ data });

        if (state) {
          /*  setDatosBien(data); */
          /*       console.log(data); */
          informacionArticulo = data;
        } else {
          informacionArticulo = {};
          /* setDatosBien({}); */
        }
      } catch (error) {
        informacionArticulo = {};
        /*  setDatosBien({}); */
        console.log(error);
      }

      try {
        const { state, data } = await consultaCabeceraProducto(idSiniestro);

        console.log({ data });

        if (state) {
          /*  setProductoCabecera(data); */
          detalleProducto = {
            modelo: data.modelo,
            dot: data.dot,
            placa: data.placa,
            imei: data.imei,
            codigoBarras: data.codigoBarras,
            numeroSerie: data.numeroSerie,
            marca: data.codMarca,
            articulo: data.descripcionBien,
            valor: data.valorBien,
            idClasificacionArticulo: data.idClasificacion,
            fechaCompra: data.fechaInicio,
          };

          /*  console.log(data); */
        } else {
          detalleProducto = {};
          /* setProductoCabecera({}); */
        }
      } catch (error) {
        detalleProducto = {};
        /* setProductoCabecera({}); */
        console.log(error);
      }

      const informacionArticuloProducto = {
        ...detalleProducto,
        ...informacionArticulo,
      };

      setDatosBien(informacionArticuloProducto);
    };
    getData();
  }, []);

  //buscar datos del producto desde la cabecera
  /* useEffect(() => {
    const getData = async () => {
      try {
        const { state, data } = await consultaCabeceraProducto(idContrato);

        console.log({ data });

        if (state) {
          setProductoCabecera(data);
          console.log(data);
        } else {
          setProductoCabecera({});
        }
      } catch (error) {
        setProductoCabecera({});
        console.log(error);
      }
    };
    getData();
  }, []); */

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  //aun no se usa esta validacion ==> verificar flujo
  const validarFlujoLlantas = () => {
    return true;
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const informacionInfoArticulo = {
      idInformacionArticulo: Number(datosBien.idInformacionArticulo) || 0,
      idSiniestro: Number(idSiniestro),
      idClasificacionArticulo: Number(valores.idClasificacionArticulo),
      articulo: valores.articulo,
      codigoBarras: valores.codigoBarras,
      dot: valores.dot,
      fechaCompra: valores.fechaCompra,
      marca: valores.marca,
      modelo: valores.modelo.toUpperCase(),
      numeroFactura: valores.numeroFactura,
      numeroSerie: valores.numeroSerie,
      placa: valores.placa,
      valor: valores.valor,
      esActivo: true,
    };
    /* console.log(informacionInfoArticulo); */
    /*  enviarSolicitudFlujo(); */

    try {
      const respuestaInformacionProducto = await crearInformacionArticulo(
        informacionInfoArticulo
      );
      /* console.log(respuestaInformacionProducto); */
      setDatosEnviadosCorrectamente(true);

      setTimeout(() => {
        setDatosEnviadosCorrectamente(false);
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {datosBien ? (
        <>
          <Formik
            initialValues={{
              idClasificacionArticulo: datosBien.idClasificacionArticulo ?? "",
              numeroFactura: datosBien.numeroFactura ?? "",
              codigoBarras: datosBien.codigoBarras ?? "",
              marca: datosBien.marca ?? "",
              modelo: datosBien.modelo ?? "",
              articulo: datosBien.articulo ?? "",
              fechaCompra: datosBien.fechaCompra ?? "",
              numeroSerie: datosBien.numeroSerie ?? "",
              valor: datosBien.valor ?? "",
              dot: datosBien.dot ?? "",
              placa: datosBien.placa ?? "",
              imei: datosBien.imei ?? "",
            }}
            validationSchema={Yup.object({
              idClasificacionArticulo: Yup.string().required(
                "Clasificación requerida"
              ),
              numeroFactura: Yup.string().required("N° Factura requerida"),

              marca: Yup.string().required("Marca requerida"),
            })}
            validate={async (values) => {
              const errors = {};

              /* if (!values.email) {
                errors.email = "Required";
              } else if (!(await verificacionAsincronoEmail(values.email))) {
                errors.email = "El email no fue encontrado";
              } */

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              configuracionDatosEnvio(values);
            }}
          >
            {({ values, handleChange, touched, errors, handleBlur }) => (
              <Form>
                <SCSAccordion tituloCabecera="Información Producto">
                  <Grid container spacing={3} rowSpacing={2}>
                    <Grid item md={12}>
                      <Grid container spacing={3} rowSpacing={2}>
                        <Grid item md={12}>
                          {/* spacing entre filas */}
                          <Grid container spacing={3}>
                            <Grid item md={12}>
                              <div className="cliente-informacion-seccion">
                                <SelectItemsCatalogoSinPadre
                                  pais="ECU"
                                  especial="true"
                                  catalogos="CLAART"
                                  name="idClasificacionArticulo"
                                  labelName="Clasificación Articulo"
                                  grupo={idGrupo}
                                  componentSize="19%"
                                  handleBlur={handleBlur}
                                  touched={touched}
                                  errors={errors}
                                />

                                <TextField
                                  id="codigoBarras"
                                  name="codigoBarras"
                                  type="text"
                                  label="Codigo Barras"
                                  sx={{ width: "18%" }}
                                  value={values.codigoBarras}
                                  onChange={handleChange}
                                  size="small"
                                />
                                <TextField
                                  id="fechaCompra"
                                  label="Fecha Compra"
                                  type="date"
                                  size="small"
                                  sx={{ width: "15%" }}
                                  onChange={handleChange}
                                  value={formatoFecha(values.fechaCompra)}
                                  InputLabelProps={{ shrink: true }}
                                />

                                <TextField
                                  id="valor"
                                  name="valor"
                                  label="Valor Del Bien"
                                  sx={{ width: "15%" }}
                                  value={values.valor}
                                  onChange={handleChange}
                                  size="small"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                />
                                <TextField
                                  id="numeroFactura"
                                  name="numeroFactura"
                                  type="text"
                                  label="Número Factura"
                                  sx={{ width: "20%" }}
                                  value={values.numeroFactura}
                                  onChange={handleChange}
                                  size="small"
                                  inputProps={{
                                    style: {
                                      textTransform: "uppercase",
                                    },
                                  }}
                                  error={
                                    touched.numeroFactura &&
                                    Boolean(errors.numeroFactura)
                                  }
                                  helperText={
                                    touched.numeroFactura &&
                                    errors.numeroFactura
                                  }
                                  onBlur={handleBlur}
                                />
                              </div>

                              <div className="cliente-informacion-seccion">
                                <TextField
                                  id="articulo"
                                  name="articulo"
                                  type="text"
                                  label="Descripción Articulo"
                                  sx={{ width: "33%" }}
                                  value={values.articulo}
                                  onChange={handleChange}
                                  size="small"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                />

                                <SelectItemsCatalogoSinPadre
                                  pais="ECU"
                                  especial="true"
                                  catalogos="MARCA"
                                  name="marca"
                                  labelName="Marca"
                                  grupo={1}
                                  componentSize="18%"
                                  handleBlur={handleBlur}
                                  touched={touched}
                                  errors={errors}
                                />

                                <TextField
                                  id="modelo"
                                  name="modelo"
                                  type="text"
                                  label="Modelo"
                                  sx={{ width: "18%" }}
                                  value={values.modelo}
                                  onChange={handleChange}
                                  size="small"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                />

                                <TextField
                                  id="numeroSerie"
                                  name="numeroSerie"
                                  type="text"
                                  label="N° de Serie"
                                  sx={{ width: "20%" }}
                                  value={values.numeroSerie}
                                  onChange={handleChange}
                                  size="small"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                />
                              </div>

                              {validarFlujoLlantas() && (
                                <>
                                  <div className="cliente-informacion-seccion">
                                    <TextField
                                      id="dot"
                                      name="dot"
                                      type="text"
                                      label="DOT"
                                      sx={{ width: "20%" }}
                                      value={values.dot}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: {
                                          textTransform: "uppercase",
                                        },
                                      }}
                                    />

                                    <TextField
                                      id="placa"
                                      name="placa"
                                      type="text"
                                      label="Placa"
                                      sx={{ width: "20%" }}
                                      value={values.placa}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: {
                                          textTransform: "uppercase",
                                        },
                                      }}
                                    />

                                    <TextField
                                      id="imei"
                                      name="imei"
                                      type="text"
                                      label="IMEI"
                                      sx={{ width: "20%" }}
                                      value={values.imei}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: {
                                          textTransform: "uppercase",
                                        },
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <div className="confirmar-estados-servicios">
                        <SCSLoadingButton loading={loading} titulo="GUARDAR" />

                        {datosEnviadosCorrectamente && (
                          <AcceptServiceresponse />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </SCSAccordion>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <SpinnerLoadSrx />
      )}
    </>
  );
};

export default FormProductoGestion;
