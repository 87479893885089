import React, { useState } from "react";
import { Formik, Form } from "formik";

import * as Yup from "yup";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import TaskIcon from "@mui/icons-material/Task";

import { consultarSabanaPolizas } from "../services/ReporteService";
import downloadExcel from "app/utils/document/documentoDescargaExel";
import { formatoFecha } from "app/modules/shared/validations/validaciones";
import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    /*  hight: 2000 */
    minWidth: 800,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    /* width: "50%", */
  },
  /* "& .MuiDialogActions-root": {
    with: 30,
  }, */
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalReportePolizas = ({ children, open, setOpen, titulo, mensaje }) => {
  const [reporteSabanaPolizas, setReporteSabanaPolizas] = useState([]);
  const [loading, setLoading] = useState(false);

  const obtenerReporteSabanaPolizas = async (fechaInicio, fechaFin) => {
    setLoading(true);

    try {
      const respuestaSabanaPolizas = await consultarSabanaPolizas(
        fechaInicio,
        fechaFin
      );

      console.log(respuestaSabanaPolizas);

      if (respuestaSabanaPolizas.state) {
        const respuestaSabanaPolizasAux = respuestaSabanaPolizas.data.map(
          (polizas, index) => {
            return {
              ...polizas,
              fechaIngreso: formatoFecha(polizas.fechaIngreso),
              ID: index,
            };
          }
        );

        setReporteSabanaPolizas(respuestaSabanaPolizasAux);

        downloadExcel(
          respuestaSabanaPolizas.data,
          `REPSABANAPOLIZAS_${fechaInicio}__${fechaFin}`
        );
        setLoading(false);
      } else {
        setReporteSabanaPolizas([]);
        setLoading(false);
        alert("Error: No se ha podido gererar el reporte !!");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Error: No se ha podido gererar el reporte !!");
    }
  };

  const enviarDatosBusqueda = (valores) => {
    console.log(valores);

    console.log({
      fechaInicio: valores.fechaInicio,
      fechaFin: valores.fechaFin,
    });
    obtenerReporteSabanaPolizas(valores.fechaInicio, valores.fechaFin);
  };

  return (
    <BootstrapDialog
      /* aria-describedby="alert-dialog-slide-description" */
      /* style={{ height: "30%" }} */

      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => setOpen(false)}
      >
        {titulo}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={{
            fechaInicio: "",
            fechaFin: "",
          }}
          validationSchema={Yup.object({
            fechaInicio: Yup.string().required("Requerido"),
            fechaFin: Yup.string().required("Requerido"),
          })}
          onSubmit={(valores, { setSubmitting }) => {
            enviarDatosBusqueda(valores);
          }}
        >
          {({ values, handleChange, touched, errors, handleBlur }) => (
            <Form>
              <div className="buscador-gestion-opciones">
                <TextField
                  id="fechaInicio"
                  name="fechaInicio"
                  type="date"
                  label="Fecha Inicio"
                  sx={{ width: "25%" }}
                  value={values.fechaInicio}
                  onChange={handleChange}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  error={touched.fechaInicio && Boolean(errors.fechaInicio)}
                  helperText={touched.fechaInicio && errors.fechaInicio}
                  onBlur={handleBlur}
                />

                <TextField
                  id="fechaFin"
                  name="fechaFin"
                  type="date"
                  label="Fecha Fin"
                  sx={{ width: "25%" }}
                  value={values.fechaFin}
                  onChange={handleChange}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  error={touched.fechaFin && Boolean(errors.fechaFin)}
                  helperText={touched.fechaFin && errors.fechaFin}
                  onBlur={handleBlur}
                />

                <LoadingButton
                  size="large"
                  type="submit"
                  loading={loading}
                  variant="contained"
                  loadingPosition="end"
                  endIcon={<TaskIcon />}
                >
                  Generar
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={() => setOpen(false)}>
          Cerrar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ModalReportePolizas;
