import http from "../../../comunication/http-common";

/* eslint-disable camelcase */

const tokenApp = "73119EA4-DF01-40E6-9A64-8F4291C268C7";

export const consultarPrestadorMedico = async (ruc) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/ObtenerPrestadorMedico?ruc=${ruc}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const consultarQuejasXCliente = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}queja/ObtenerQuejasXCliente?idCliente=${idCliente}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const consultarCiudadesCitas = async () => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/ObtenerCiudades?tokenAplicacion=${tokenApp}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const consultarQuejaXid = async (idQueja) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}queja/obtenerQuejaXid?idQueja=${idQueja}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const consultarGestionQueja = async (idQueja) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}queja/ObtenerGestionesXQueja?idQueja=${idQueja}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const consultarTipoSolucion = async () => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}queja/CatalogoTipoSolucion`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const consultarQuejasXFechas = async (fechaInicio, fechaFin) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}queja/ObtenerQuejasXFecha?fechaIni=${fechaInicio}&fechaFin=${fechaFin}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://localhost:5011/queja/ObtenerQuejasPendientes
export const consultarQuejasPendientes = async () => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}queja/ObtenerQuejasPendientes`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const crearPrestadorMedico = (data) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}citamedica/GuardarPrestadorMed`;
    http
     .post(url, data,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const crearQueja = (data) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}queja/GuardarQueja`;
    http
     .post(url, data,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/* FAIL: servicio requerido */
export const crearGestionQueja = (data) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_GESTION}queja/GuardarGestionQueja`;
    http
     .post(url, data,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
