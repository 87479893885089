import React, { useEffect, useState } from "react";

import { Skeleton } from "@mui/material";

import {
  consultarEstadosCuenta,
  consultarPDFEstado,
  notificarEstadoCuenta,
} from "../services/transaccionalService";

import {
  SCSGenericButton,
  SCSLoadingButton,
} from "app/modules/common/components/Buttons/SCSButtons";

import {
  SCSTableData,
  SCSTableDataRow,
  SCSTableDataCell,
} from "app/modules/common/components/TableData/SCSTableData";

import VisualizarDocumento from "../components/VisualizarDocumento";
import DetalleOpcionGenerica from "./DetalleOpcionGenerica";
import ModalEstadoProceso from "app/modules/common/components/Modals/ModalEstadoProceso";

const DetalleValoresPorPagar = ({
  cabenceraTransaccional,
  datosBusqueda,
  alturaResolucion,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [listaAutorizaciones, setListaAutorizaciones] = useState({
    header: { identificacion: "", nombreCliente: "", numeroTarjeta: "" },
    data: [],
  });

  const [open, setOpen] = useState(false);
  const [nombreDocumento, setNombreDocumento] = useState("");

  //TODO: Funcion para ver que usuario es
  const [validarUsuario, setValidarUsuario] = useState(() => {
    const rolUsuarioString = sessionStorage.getItem("user");

    // Manejar el caso en que rolUsuarioString sea null

    if (!rolUsuarioString) {
      console.log("No se encontró ningún usuario en sessionStorage.");
      return false; // Devuelve false si no hay datos de usuario
    }

    // Intentar parsear el string a JSON
    let rolUsuario;
    try {
      rolUsuario = JSON.parse(rolUsuarioString);
    } catch (error) {
      console.log("Error al parsear el JSON:", error);
      return false; // Devuelve false si la parseo falla
    }

    // Verificar que el objeto parseado tenga la propiedad 'Rol'
    /* console.log(rolUsuario.rol); */
    if (rolUsuario && rolUsuario.rol === "GENERICOCLI") {
      return false;
    } else {
      return true;
    }
  });

  const [loadingNotificar, setLoadingNotificar] = useState(false);

  const [openModalEmail, setOpenModalEmail] = useState(false);

  const deplegarPDF = (docDigital) => {
    setNombreDocumento(docDigital);
    setOpen(true);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaListaAutorizaciones = await consultarEstadosCuenta(
          cabenceraTransaccional.idCuenta
        );

        /* console.log(respuestaListaAutorizaciones);
         */
        if (
          respuestaListaAutorizaciones.state &&
          respuestaListaAutorizaciones.data
        ) {
          setListaAutorizaciones((args) => ({
            ...args,
            header: respuestaListaAutorizaciones.data.cabecera,
            data: respuestaListaAutorizaciones.data.valorEstados,
          }));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={alturaResolucion}
        sx={{ borderRadius: "32px" }}
      />
    );
  }

  if (cabenceraTransaccional.idCuenta <= 0) {
    return (
      <DetalleOpcionGenerica mensaje={"No existe información del cliente."} />
    );
  }

  const notificarEmailEstados2 = () => {
    setOpenModalEmail(true);
  };

  const notificarEmailEstados = async (valorEstado) => {
    setLoadingNotificar(true);

    if (!cabenceraTransaccional.email) {
      setLoadingNotificar(false);
      alert("Error: No existe un email registrado.");
      return;
    }

    if (valorEstado.digital === "") {
      setLoadingNotificar(false);
      alert("Error: No se ha podido enviar el estado de cuenta.");
      return;
    }

    try {
      const respuestaDocumento = await consultarPDFEstado(valorEstado.digital);

      if (!respuestaDocumento.state) {
        setLoadingNotificar(false);
        alert("Error: No se ha podido enviar el estado de cuenta.");
        return;
      }

      if (respuestaDocumento.data === null) {
        setLoadingNotificar(false);
        alert("Error: No se ha podido enviar el estado de cuenta.");
        return;
      }

      const informacionData = `${cabenceraTransaccional.nombreCliente}|${valorEstado.fechaCorte}|${valorEstado.fechaVcto}|${valorEstado.valorXPagar}`;

      if (respuestaDocumento.state) {
        const informacionNotificacion = {
          IdGrupo: 0,
          CodigoComunicacion: "ESTCTA",
          EspacioComunicacion: "SENDGRID",
          TipoComunicacion: "EMAIL",
          Variables: informacionData,
          Destino: cabenceraTransaccional.email,
          /* Destino: "rommel.pumisacho@gmail.com", */
          IdTransaccion: cabenceraTransaccional.idCuenta,
          AdjuntoMensaje: [
            {
              NombreAdjunto: `${valorEstado.digital}`,
              Adjunto: respuestaDocumento.data,
            },
          ],
        };
        /* Adjunto: estaoPdf, */

        console.log({ informacionNotificacion });

        const respuestaNotificarEstadoCuenta = await notificarEstadoCuenta(
          informacionNotificacion
        );

        console.log({ respuestaNotificarEstadoCuenta });

        if (
          respuestaNotificarEstadoCuenta.data &&
          respuestaNotificarEstadoCuenta.state
        ) {
          setOpenModalEmail(true);
          setLoadingNotificar(false);
        } else {
          setLoadingNotificar(false);
          alert("Error: No se ha podido enviar el estado de cuenta.");
        }
      }
    } catch (error) {
      console.log(error);
      alert("Error: No existe un email registrado.");
    }
  };

  const validarTipoUsuario = () => {
    if (validarUsuario) {
      const columnsData = [
        /* { id: 1, name: "N°" }, */
        { id: 2, name: "F. Corte" },
        { id: 2, name: "F. Vencimiento" },
        { id: 6, name: "F. Pago" },
        { id: 8, name: "Días Vencido" },
        { id: 3, name: "Valor Gestion" },
        { id: 3, name: "Valor Estado" },
        { id: 4, name: "Valor Pagado" },
        { id: 7, name: "Valor Por Pagar" },
        { id: 9, name: "Detalle" },
        { id: 10, name: "Email" },
      ];

      return columnsData;
    } else {
      const columnsDataCliente = [
        /* { id: 1, name: "N°" }, */
        { id: 2, name: "F. Corte" },
        { id: 2, name: "F. Vencimiento" },
        { id: 6, name: "F. Pago" },
        { id: 8, name: "Días Vencido" },
        { id: 3, name: "Valor Gestion" },
        { id: 3, name: "Valor Estado" },
        { id: 4, name: "Valor Pagado" },
        { id: 7, name: "Valor Por Pagar" },
        { id: 9, name: "Detalle" },
      ];
      return columnsDataCliente;
    }
  };

  return (
    <>
      <SCSTableData
        columnsData={validarTipoUsuario()}
        heightTable={alturaResolucion}
      >
        {listaAutorizaciones.data?.map((historico, index) => (
          <SCSTableDataRow key={historico.idEstadoCuenta}>
            <SCSTableDataCell align="center">
              {historico.fechaCorte}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {historico.fechaVcto}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {historico.fechaPago}
            </SCSTableDataCell>

            <SCSTableDataCell align="center">
              {historico.diasVcdo}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">{`$ ${historico.valorGestion}`}</SCSTableDataCell>

            <SCSTableDataCell align="center">{`$ ${historico.valorEstado}`}</SCSTableDataCell>

            <SCSTableDataCell align="center">{`$ ${historico.valorPagado}`}</SCSTableDataCell>

            <SCSTableDataCell align="center">{`$ ${historico.valorXPagar}`}</SCSTableDataCell>

            <SCSTableDataCell align="center" style={{ padding: "0.5rem" }}>
              <SCSGenericButton
                titulo="Ver"
                size={100}
                onClick={() => deplegarPDF(historico.digital)}
              />
            </SCSTableDataCell>
            {validarUsuario && (
              <SCSTableDataCell align="center" style={{ padding: "0.5rem" }}>
                <SCSLoadingButton
                  titulo="Email"
                  loading={loadingNotificar}
                  size={100}
                  onClick={() => notificarEmailEstados(historico)}
                />
              </SCSTableDataCell>
            )}
          </SCSTableDataRow>
        ))}
      </SCSTableData>

      <VisualizarDocumento
        setOpen={setOpen}
        open={open}
        nombreDocumento={nombreDocumento}
      />

      <ModalEstadoProceso
        titulo="Email"
        mensaje={`Se ha enviado el estado de cuenta al correo: ${cabenceraTransaccional.email}`}
        open={openModalEmail}
        setOpen={setOpenModalEmail}
      />
    </>
  );
};

export default DetalleValoresPorPagar;
