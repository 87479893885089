import React, { useEffect, useState } from "react";
import { useFormikContext, Formik, Form, Field, useField } from "formik";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
  Alert,
  Button,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "../styles/main.css";
import {
  cambiarEstadoSolicitud,
  grabarVerificacion,
  obtenerObservaciones,
} from "../services/creditoServices";
import { useSelector } from "react-redux";
import {
  SCSGenericButton,
  SCSLoadingButton,
} from "app/modules/common/components/Buttons/SCSButtons";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { consultaInfoDemografica } from "app/modules/app-cliente/services/clienteService";
import { obtenerDatosDigercic } from "app/modules/app-evaluacion/services/evaluacionServices";
import handleDownlPdf from "app/utils/document/documentosService";

const FormValidacionInterna = ({ idSolic, solic, idCliente }) => {
  const [idClienteForm, setIdClienteForm] = useState(null);
  const [clienteInfo, setClienteInfo] = useState(null);
  const { authUser } = useSelector(({ auth }) => auth);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [values, setValues] = useState({});
  const [mensajeError, setMensajeError] = useState("");
  const [mensajeErrorCambioSolicitud, setMensajeErrorCambioSolicitud] =
    useState(false);
  const [archivoDescargado, setArchivoDescargado] = useState(false);

  useEffect(() => {
    console.log(idCliente);
    obtenerObservacionesSolicitud(idSolic);
  }, []);

  useEffect(() => {}, [values]);

  const habilitarCampo = (valor) => {
    if (valor === "0") return false;
    else return true;
  };

  const filasAMapear = [
    { name: "Mail", variableInicial: "mail" },
    { name: "Celular", variableInicial: "celular" },
    { name: "Convencional", variableInicial: "convencional" },
    { name: "Fiscalia", variableInicial: "fiscalia" },
    { name: "Judicatura", variableInicial: "judicatura" },
    { name: "Listas Negras", variableInicial: "listaNegra" },
    { name: "Codigo Dactilar Cedula", variableInicial: "codDactilar" },
    { name: "Fecha Expedicion Cedula", variableInicial: "fechaExpedicion" },
    {
      name: "Firmas Similares en Documentos",
      variableInicial: "firmasSimilares",
    },
  ];

  const configuracionDatosEnvio = async (values) => {
    try {
      var resp = await cambiarEstadoSolicitud(
        idSolic,
        4,
        authUser.key,
        authUser.estacion
      );
      if (resp.state) {
        const today = new Date().toLocaleString("en-US");
        filasAMapear.map((row) => {
          var nombreObservacion = `observ${row.variableInicial}`;
          if (values[row.variableInicial] === "0") {
            try {
              var datos = {
                idSolicitud: idSolic,
                tipo: row.variableInicial,
                observacion: values[nombreObservacion],
                esActivo: true,
                fechaCreacion: today,
                usuarioCreacion: authUser.key,
                estacionCreacion: authUser.estacion,
                fechaModificacion: today,
                usuarioModificacion: authUser.key,
                estacionModificacion: authUser.estacion,
              };

              console.log("Grabar observacion de ", row.variableInicial);

              const respuestaVerificacion = grabarVerificacion(datos)
                .then((response) => {
                  console.log(response.data);
                  setDatosEnviadosCorrectamente(true);

                  setTimeout(() => {
                    setDatosEnviadosCorrectamente(false);
                  }, 5000);
                })
                .catch((e) => {
                  console.log(e);
                });
            } catch (error) {
              console.log(error);
            }
          }
        });
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
        }, 5000);
      } else {
        setMensajeErrorCambioSolicitud(true);

        setTimeout(() => {
          setMensajeError(resp.message);
          setMensajeErrorCambioSolicitud(false);
        }, 5000);
      }
    } catch (e) {
      setMensajeErrorCambioSolicitud(true);

      setTimeout(() => {
        setMensajeError(e.message);
        setMensajeErrorCambioSolicitud(false);
      }, 5000);
    }
  };

  const valoresIniciales = {};

  const obtenerObservacionesSolicitud = (idSolicitud) => {
    obtenerObservaciones(idSolicitud)
      .then((response) => {
        if (response.state) {
          verificarDocumentos(response.data);
        }
      })
      .catch((_errors) => {
        console.log(_errors);
      });
  };

  const obtenerDatosRegCivil = async () => {
    //console.log(idCliente);
    let codDactilar;
    try {
      const datosDemograficos = await consultaInfoDemografica(idCliente);
      if (datosDemograficos.state) {
        codDactilar = datosDemograficos.data.codigoDactilar;

        try {
          const consultaDigercic = await obtenerDatosDigercic(
            solic.identificacion,
            codDactilar,
            authUser.key
          );
          if (consultaDigercic.state) {
            handleDownlPdf(consultaDigercic.data, `DIGERCIC${solic.identificacion}`);
            setArchivoDescargado(true);
          } else {
            alert("Error al obtener Digercic - Código dactilar Incorrecto");
          }

          setTimeout(() => {
            setArchivoDescargado(false);
          }, 3000);
        } catch (err) {
          console.log(err);
        }
      } else {
        console.log(datosDemograficos.message);
      }
      //console.log(datosDemograficos);
    } catch (e) {
      console.log(e);
    }
  };

  const verificarDocumentos = (observ) => {
    if (observ.length === 0) {
      filasAMapear.map((row) => {
        valoresIniciales[row.variableInicial] = "";
        valoresIniciales[`observ${row.variableInicial}`] = "";
      });
    } else {
      const array = [];
      Object.keys(observ).forEach((e) => {
        var nombreItem = observ[e].tipo;
        var observItem = observ[e].observacion;
        array.push({ [nombreItem]: observItem });
      });

      filasAMapear.map((row) => {
        valoresIniciales[row.variableInicial] = "1";
        valoresIniciales[`observ${row.variableInicial}`] = "";

        array.forEach((item) => {
          if (item.hasOwnProperty(`${row.variableInicial}`)) {
            valoresIniciales[row.variableInicial] = "0";
            valoresIniciales[`observ${row.variableInicial}`] =
              item[row.variableInicial];
          }
        });
      });

      setValues(valoresIniciales);
      console.log(array, valoresIniciales);
    }
  };

  return (
    <>
      <SCSAccordion tituloCabecera="Fuentes de Validación Interna">
        <Formik
          initialValues={valoresIniciales}
          onSubmit={(values, { setSubmitting }) => {
            configuracionDatosEnvio(values);
            console.log(values);
          }}
        >
          {({ values, handleChange }) => (
            <Form>
              <Grid
                container
                justifyContent="center"
                spacing={3}
                rowSpacing={4}
              >
                <Grid item xl={12}>
                  <Stack mb={3} justifyContent={"center"} alignItems={"center"}>
                    <SCSGenericButton
                      //className="buscador-botones-centro"
                      variant="contained"
                      onClick={() => obtenerDatosRegCivil()}
                      size={15}
                      titulo={"DIGERCIC"}
                    />
                    {archivoDescargado && (
                      <Alert severity="success" variant="filled">
                        Se ha Descargado el Archivo.
                      </Alert>
                    )}
                  </Stack>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableBody>
                        {filasAMapear.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell>{row.name}</TableCell>
                            <TableCell align="left">
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name={`${row.variableInicial}`}
                                  id={`${row.variableInicial}`}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    value="1"
                                    control={
                                      <Radio
                                        checked={
                                          values[row.variableInicial] === "1"
                                        }
                                      />
                                    }
                                    label="Si"
                                  />
                                  <FormControlLabel
                                    value="0"
                                    control={
                                      <Radio
                                        checked={
                                          values[row.variableInicial] === "0"
                                        }
                                      />
                                    }
                                    label="No"
                                  />
                                  <TextField
                                    disabled={habilitarCampo(
                                      values[row.variableInicial]
                                    )}
                                    id={`observ${row.variableInicial}`}
                                    name={`observ${row.variableInicial}`}
                                    label="Observación"
                                    type="text"
                                    variant="standard"
                                    value={
                                      values[`observ${row.variableInicial}`]
                                    }
                                    onChange={handleChange}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xl={4}>
                  <SCSLoadingButton variant="contained" titulo={"Grabar"} />
                </Grid>
                {datosEnviadosCorrectamente && (
                  <Alert severity="success" variant="filled">
                    Los datos han sido guardados correctamente.
                  </Alert>
                )}
                {mensajeErrorCambioSolicitud && (
                  <Alert severity="error" variant="filled">
                    {mensajeError}
                  </Alert>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </SCSAccordion>
    </>
  );
};

export default FormValidacionInterna;
