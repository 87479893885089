import React, { useState } from "react";

import TaskIcon from "@mui/icons-material/Task";

import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";
import ModalReporteSegurosCancelaciones from "../containers/ModalReporteSegurosCancelaciones";

const BotonConsultaSegurosCancelados = () => {
  const [open, setOpen] = useState(false);

  const obtenerReporteSabanaPolizas = () => {
    setOpen(true);
  };

  return (
    <>
      <SCSGenericButton
        titulo="Reporte Seguros Cancelados"
        endIcon={<TaskIcon />}
        /* onClick={({ id = 5 }) => obtenerReporteSabanaPolizas(id)} */
        onClick={obtenerReporteSabanaPolizas}
      />

      <ModalReporteSegurosCancelaciones
        open={open}
        setOpen={setOpen}
        titulo="Reporte Seguros Cancelados"
      />
    </>
  );
};

export default BotonConsultaSegurosCancelados;
