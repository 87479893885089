import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { styled } from "@mui/material/styles";

import {
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
  Card,
  Alert,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import * as Yup from "yup";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import { crearUsuarioFlujo, listarFlujo } from "../Service/usuarioService";
import ModalAlertaNotificacionEstandar from "app/modules/app-servicios/components/ModalAlertaNotificacionEstandar";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

const FormImformacionUsuario = ({ idUsuario, usuario }) => {
  const [idUsuarioAux, setIdUsuarioAux] = useState(idUsuario);
  const [loading, setLoading] = useState(false);
  const [openUsuarioFlujo, setOpenUsuarioFlujo] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [idGrupoAux, setIdGrupoAux] = useState([]);

  const [estilos, setEstilos] = useState(
    Number(localStorage.getItem("idGrupoStorage"))
  );

  useEffect(() => {
    console.log({ usuario });
    console.log({ idUsuario });
  }, []);

  const { usuarioFlujo } = usuario;
  const usuarioValidado = usuarioFlujo ?? "";

  const SelectItemsFlujoSinPadre = ({
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
    setGrupoSeleccionado,
  }) => {
    const [flujo, setFlujo] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await listarFlujo();
          //console.log(data);

          if (state) {
            setFlujo(data);
          } else {
            setFlujo([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {flujo.map((flujo, index) => (
            <MenuItem
              key={index}
              value={flujo.idFlujo}
              onClick={() => setGrupoSeleccionado(flujo.idGrupo)}
            >
              {flujo.nombreFlujo}
            </MenuItem>
          ))}
        </Field>
        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const handleGrupoSeleccionado = (idGrupo) => {
    setIdGrupoAux(idGrupo);
    //console.log(idGrupo);
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const informacionUsuarioFlujo = {
      idUsuarioFlujo: Number(
        `${usuarioValidado == "" ? 0 : usuarioValidado.idUsuarioFlujo}`
      ),
      idUsuario: Number(idUsuarioAux),
      idFlujo: Number(valores.idFlujo),
      idGrupo: Number(
        `${idGrupoAux == 0 ? usuarioValidado.idGrupo : idGrupoAux}`
      ),
      esActivo: valores.esActivo,
    };
    console.log("VALORES", informacionUsuarioFlujo);

    try {
      const respuestaUsuarioFlujo = await crearUsuarioFlujo(
        informacionUsuarioFlujo
      );

      if (respuestaUsuarioFlujo.state) {
        console.log(respuestaUsuarioFlujo);
        setOpenUsuarioFlujo(true);
        setLoading(false);
      } else {
        alert(`${respuestaUsuarioFlujo.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(`${error.response.data.message}`);
    }
  };

  const diccionarioFlujo = (idFlujo) => {
    const nombreFlujoXid = {
      1: "FLUJO CORPORACION",
      5: "FLUJO TVENTAS",
      6: "FLUJO GRUPO REY",
    };

    return nombreFlujoXid[idFlujo];
  };

  const validarGrupo = () => {
    if (Number(estilos) === 1) {
      return "#e1251b";
    } else if (Number(estilos) === 2) {
      return "#e1251b";
    } else if (Number(estilos) === 3) {
      return "#ef732c";
    } else if (Number(estilos) === 4) {
      return "#c73436";
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: `${validarGrupo()}`,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 35,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <div className="header-client-container">
        <Card>
          <header className="header-client-information">
            <div>
              <h2>Usuario</h2>
            </div>
            <div>
              <h4 align="center">
                <b>Nombre</b>
              </h4>
              <h4 align="center">
                {usuario.apellidos} {usuario.nombres}
              </h4>
            </div>
            <div>
              <h4 align="center">
                <b>Usuario</b>
              </h4>
              <h4 align="center">{usuario.key}</h4>
            </div>
            <div>
              <h4 align="center">
                <b>Email</b>
              </h4>
              <h4 align="center">{usuario.email}</h4>
            </div>
            <div>
              <h4 align="center">
                <b>Flujo Actual</b>
              </h4>
              <h4 align="center">
                {usuario.usuarioFlujo?.idFlujo
                  ? diccionarioFlujo(usuario.usuarioFlujo.idFlujo)
                  : "FLUJO NO ASIGNADO"}
              </h4>
            </div>
            <div>
              <h4 align="center">Estado Flujo</h4>
              <h4 align="center">
                {usuario.usuarioFlujo?.esActivo ? "ACTIVO" : "INACTIVO"}
              </h4>
            </div>
          </header>
        </Card>
      </div>
      <br />
      {usuarioValidado == "" && (
        <Alert
          color="warning"
          severity="warning"
          sx={{ padding: 1, margin: 1 }}
        >
          El Usuario NO tiene asignado un flujo.!! Complete y guarde la
          información.
        </Alert>
      )}

      <Formik
        initialValues={{
          idFlujo: usuarioValidado.idFlujo ?? "",
          esActivo: usuarioValidado.esActivo ?? false,
        }}
        validationSchema={Yup.object({
          idFlujo: Yup.string().required("Flujo requerido"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          // console.log(values);
          configuracionDatosEnvio(values);
        }}
      >
        {({ values, handleChange, touched, errors, handleBlur }) => (
          <Form>
            <div className="tipoSiniestro-container">
              <TableContainer component={Paper} sx={{ width: "45%" }}>
                <TableContainer sx={{ width: "100%" }}>
                  <Table /*aria-label="customized table"*/>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          sx={{ textAlign: "center", width: "65%" }}
                        >
                          Actualizar Flujo
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ textAlign: "center", width: "40%" }}
                        >
                          Actualizar Estado
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <StyledTableCell>
                        <SelectItemsFlujoSinPadre
                          name="idFlujo"
                          labelName="Flujo"
                          componentSize="100%"
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          setGrupoSeleccionado={handleGrupoSeleccionado}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormGroup
                          error={touched.esActivo && Boolean(errors.esActivo)}
                          helperText={touched.esActivo && errors.esActivo}
                          onBlur={handleBlur}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.esActivo}
                                onChange={handleChange}
                                name="esActivo"
                              />
                            }
                            label="Activo"
                          />
                          <ErrorMessage
                            name="esActivo"
                            component={() => (
                              <FormHelperText>{errors.esActivo}</FormHelperText>
                            )}
                          />
                        </FormGroup>
                      </StyledTableCell>
                    </TableBody>
                  </Table>
                  <Table>
                    {/* <StyledTableCell sx={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ width: "50%" }}
                      >
                        Guardar
                      </Button>
                    </StyledTableCell> */}

                    <StyledTableCell>
                      <SCSLoadingButton loading={loading} titulo="GUARDAR" />
                      {datosEnviadosCorrectamente && <AcceptServiceresponse />}
                    </StyledTableCell>
                  </Table>
                </TableContainer>
              </TableContainer>
            </div>
          </Form>
        )}
      </Formik>

      {/* <ModalAlertaNotificacion
        setOpen={setOpenUsuarioFlujo}
        open={openUsuarioFlujo}
      /> */}

      <ModalAlertaNotificacionEstandar
        setOpen={setOpenUsuarioFlujo}
        open={openUsuarioFlujo}
        titulo="Administrador - Usuario Flujo"
        mensaje="Transacción ejecutada con exito !!."
      />
    </>
  );
};

export default FormImformacionUsuario;
