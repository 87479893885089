import React from "react";
import { useHistory } from "react-router-dom";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { Card, TextField } from "@mui/material";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";

import "../styles/main.css";
import {
  StyleInputs,
  StylesCardBuscador,
} from "app/modules/common/styles/themes";

const BuscadorPorIdentificacionGenerico = ({ titulo, setDatosBusqueda }) => {
  const navigate = useHistory();

  const redirectHomePage = () => {
    navigate.push("/initialHome");
  };

  const enviarDatosBusqueda = (valores) => {
    /* console.log(valores); */
    setDatosBusqueda((args) => ({
      ...args,
      identificacion: valores.identificacion,
      mostrarBuscador: false,
    }));
  };

  return (
    <div div className="buscador-movimientos-container">
      <Card sx={StylesCardBuscador()}>
        <header className="buscador-movimientos-header">
          <div className="buscador-movimientos-titulo">
            <h2>{titulo}</h2>
          </div>

          <Formik
            initialValues={{
              identificacion: "",
            }}
            validationSchema={Yup.object({
              identificacion: Yup.string().required("Identificación requerida"),
            })}
            onSubmit={(valores, { setSubmitting }) => {
              enviarDatosBusqueda(valores);
            }}
          >
            {({ values, handleChange, touched, errors, handleBlur }) => (
              <Form>
                <div className="buscador-movimientos-opciones">
                  <div className="buscador-movimientos-opciones__inputs">
                    <TextField
                      id="identificacion"
                      name="identificacion"
                      type="text"
                      label="Identificación"
                      /* sx={{ width: "45%" }} */
                      sx={StyleInputs(45, 100)}
                      value={values.identificacion}
                      onChange={handleChange}
                      size="small"
                      error={
                        touched.identificacion && Boolean(errors.identificacion)
                      }
                      helperText={
                        touched.identificacion && errors.identificacion
                      }
                      onBlur={handleBlur}
                    />
                  </div>

                  <div className="buscador-movimientos-opciones__buttons">
                    <SCSGenericButton type="submit" size={45} titulo="Buscar" />

                    <SCSGenericButton
                      type="button"
                      size={45}
                      titulo="Cancelar"
                      onClick={redirectHomePage}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </header>
      </Card>
    </div>
  );
};

export default BuscadorPorIdentificacionGenerico;
