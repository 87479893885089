import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import CitaMedicaGestion from "./CitaMedicaGestion/CitaMedicaGestion";

const GestionCitaMedica = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Gestion - CitaMedica</h2>
      <CitaMedicaGestion />
    </div>
  );
};

export default GestionCitaMedica;
