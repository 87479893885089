import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormInformacionConyugue from "../components/FormInformacionConyugue";
import FormInformacionCliente from "../components/FormInformacionCliente";
import FormInformacionUbicabilidad from "../components/FormInformacionUbicabilidad";
import BotonSalirLimiarStorageCliente from "./BotonSalirLimiarStorageCliente";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const InformacionCliente = ({ idCliente, cliente, identificacion }) => {
  /* controla las ventanas de la informacion  */
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [idClienteInformacion, setIdClienteInformacion] = useState(idCliente);
  const [validarConyugue, setValidarConyugue] = useState(false);

  /* Controla si se permite guardar un ubicabilidad o conyugue solo si existe un cliente */
  useEffect(() => {
    console.log({ idClienteInformacion });
    console.log({ idCliente });
    console.log({ identificacion });

    /* setIdClienteInformacion(idCliente); */
    if (idClienteInformacion > 0) {
      setIdClienteInformacion(idClienteInformacion);
    }
  }, [idClienteInformacion]);

  return (
    <>
      <BotonSalirLimiarStorageCliente />
      <Box sx={{ width: "100%", padding: 0 }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="cabecera-tabs-clientes"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="Información Cliente" {...a11yProps(0)} />
            <Tab label="Información Ubicabilidad" {...a11yProps(1)} />
            <Tab label="Información Conyugue" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <FormInformacionCliente
            idCliente={idClienteInformacion}
            cliente={cliente}
            identificacion={identificacion}
            setIdClienteInformacion={setIdClienteInformacion}
            setValidarConyugue={setValidarConyugue}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FormInformacionUbicabilidad
            idCliente={idClienteInformacion}
            tipoDireccion="DIRDOM"
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {validarConyugue ? (
            <FormInformacionConyugue idCliente={idClienteInformacion} />
          ) : (
            <h2>Cliente NO Posee Conyugue </h2>
          )}
        </TabPanel>
      </Box>
    </>
  );
};

export default InformacionCliente;
