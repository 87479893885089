import React from "react";

import FormCancelacion from "../components/FormCancelacion";
import CabeceraSeguro from "./CabeceraSeguro";

const DetalleCancelacionSeguro = ({ idCliente, contrato }) => {
  console.log({ contrato });
  return (
    <>
      <CabeceraSeguro seguro={contrato} />
      <FormCancelacion contrato={contrato} idContrato={contrato.idContrato} />
    </>
  );
};

export default DetalleCancelacionSeguro;
