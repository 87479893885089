import React, { useEffect, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import {
  formatoFecha,
  validarFechaMayorMenor,
} from "../../shared/validations/validaciones";

import {
  validarFechaRango,
  obtenerFechaActualFormulario,
  obtenerFechaHoraActual2,
} from "app/modules/shared/commonValues/obtenerFecha";

import { consultaCatalogosSinPadre } from "../../app-cliente/services/Catalogos/catalogosService";
import { LoadingButton } from "@mui/lab";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";

import {
  consultarDetalleFlujo,
  consultarProductoFlujo,
  consultarColaborador,
  consultarNumeroSiniestro,
  crearSiniestroCrear,
  crearSeguimiento,
  consultaCabeceraProductoContrato,
  consultarFechaSistema,
} from "../Services/siniestrosService";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

const FormSiniestro = ({
  idCliente,
  idContrato,
  idGrupo,
  setIdFlujo,
  setIdSiniestro,
}) => {
  const [detalleFlujo, setDetalleFlujo] = useState(null);
  const [numeroSiniestro, setNumeroSiniestro] = useState("");
  const [datosEntregaArticulo, setdatosEntregaArticulo] = useState(null);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [existeColaborador, setExisteColaborador] = useState(false);
  const [datosBien, setDatosBien] = useState(null);

  const [idSiniestroAux, setIdSiniestroAux] = useState({
    idSiniestro: 0,
    stateSiniestro: true,
  });

  useEffect(() => {
    const getData = async () => {
      let respuestaInformacionBienXContrato;
      let obtenerCobertura;
      let respuestaProductoFlujo;

      try {
        respuestaInformacionBienXContrato =
          await consultaCabeceraProductoContrato(idContrato);
        /* console.log(respuestaInformacionBienXContrato); */

        obtenerCobertura = await consultarProductoFlujo(
          respuestaInformacionBienXContrato.data.idProducto
        );

        /* console.log(obtenerCobertura);
      console.log(obtenerCobertura[0].idFlujo); */

        /*  con el idFlujo obtengo la cobertura asociada el producto */

        respuestaProductoFlujo = await consultarDetalleFlujo(
          obtenerCobertura.data[0].idFlujo
        );

        /* console.log(respuestaProductoFlujo); */

        const datosFlujos = {
          ...respuestaInformacionBienXContrato.data,
          ...obtenerCobertura.data,
          ...respuestaProductoFlujo.data,
        };

        /*   console.log(datosFlujos); */

        setIdFlujo(datosFlujos.idFlujo);
        setDetalleFlujo(datosFlujos);
      } catch (error) {
        console.log(error);
      }

      try {
        const respuestaCabeceraProducto =
          await consultaCabeceraProductoContrato(idContrato);

        if (respuestaCabeceraProducto.state) {
          /* console.log(respuestaCabeceraProducto); */
          setDatosBien(respuestaCabeceraProducto.data);
        } else {
          setDatosBien({});
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        id={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        id={name}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} id={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsCobertura = ({
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [coberturas, setCoberturas] = useState([]);
    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultarProductoFlujo(
            detalleFlujo.idProducto
          );

          if (state) {
            setCoberturas(data);
          } else {
            setCoberturas([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, []);

    return (
      <FormControl
        sx={{ minWidth: `${componentSize}` }}
        id={name}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          id={name}
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {coberturas.map((cobertura, index) => (
            <MenuItem key={index} id={index} value={cobertura.codigoDetalle}>
              {cobertura.descripcion}
            </MenuItem>
          ))}
        </Field>
        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const BuscarColaborador = ({ idVendedor }) => {
    const [colaborador, setColaborador] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const vendedorAuxiliar = idVendedor !== "" ? idVendedor : 0;

      const getData = async () => {
        try {
          const { state, data } = await consultarColaborador(vendedorAuxiliar);

          /*  console.log(data); */

          if (state) {
            if (!data.codigo) {
              setExisteColaborador(true);
            } else {
              setExisteColaborador(false);
              setIsLoading(false);
              setColaborador(data);
            }
          }
        } catch (error) {
          setExisteColaborador(true);
          setIsLoading(true);
          console.log(error);
        }
      };
      getData();
    }, [idVendedor]);

    if (isLoading) {
      return <h3 className="siniestro-colaborador">Buscar Colaborador !!</h3>;
    }
    return (
      <>
        <h3 className="siniestro-colaborador">
          {colaborador.descripcion ?? colaborador.descripcion}
        </h3>
      </>
    );
  };

  const VerificarTipoFlujo = () => {
    return (
      <>
        <h3>{`FLUJO: ${detalleFlujo.nombreFlujo}`}</h3>
      </>
    );
  };

  const validarTipoEntrega = (tipoEntrega) => {
    console.log(tipoEntrega);

    if (tipoEntrega === "ENTRLOC") {
      return true;
    }
    return false;
  };

  const validarEntregaArticulo = (entregaArticulo) => {
    console.log(entregaArticulo);
    if (entregaArticulo) {
      setdatosEntregaArticulo(false);
      return true;
    }
    setdatosEntregaArticulo(true);

    return false;
  };

  const MostrarNumeroSiniestro = () => {
    return (
      <h3 className="siniestro-codigoSiniestro">{`Código: ${
        numeroSiniestro ?? ""
      }`}</h3>
    );
  };

  const validarcoberturaCaidas = (coberturaSiniestro) => {
    const dias = 30;

    const fechaActual = new Date();
    const fechaDeCompraProducto = new Date(datosBien.fechaInicio);

    const difference = Math.abs(fechaActual - fechaDeCompraProducto);
    const days = difference / (1000 * 3600 * 24);

    if (days >= dias) {
      return true;
    }
    return false;
  };

  const validarCoberturaMenorACien = (coberturaSiniestro) => {
    const valorBines = Number(datosBien.valorBien);

    if (valorBines <= 100) {
      return false;
    }
    return true;
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    let respuestaNumeroSiniestro = null;

    if (idSiniestroAux.stateSiniestro) {
      try {
        respuestaNumeroSiniestro = await consultarNumeroSiniestro(
          detalleFlujo.idFlujo
        );
        /*  console.log(respuestaNumeroSiniestro); */

        if (respuestaNumeroSiniestro.state) {
          /* respuestaNumeroSiniestro = respuestaNumeroSiniestro.data; */
          setNumeroSiniestro(respuestaNumeroSiniestro.data);
        } else {
          setNumeroSiniestro("");
        }
      } catch (error) {
        console.log(error);
      }
    }

    let respuestaFechaSistema = null;

    try {
      const respuestaFechaSistemaAux = await consultarFechaSistema();
      /*  console.log(respuestaFechaSistema); */

      if (respuestaFechaSistemaAux.state) {
        respuestaFechaSistema = respuestaFechaSistemaAux.data;
        /* setNumeroSiniestro(respuestaFechaSistema.data); */
      } else {
        /* setNumeroSiniestro(""); */
        respuestaFechaSistema = {
          fecha: obtenerFechaActualFormulario(),
          fechaHora: obtenerFechaHoraActual2(),
        };
      }
    } catch (error) {
      respuestaFechaSistema = {
        fecha: obtenerFechaActualFormulario(),
        fechaHora: obtenerFechaHoraActual2(),
      };
      console.log(error);
    }

    /* console.log(valores); */

    const valoresAux = {
      ...valores,
      fechaEntrega: respuestaFechaSistema.fecha,
    };

    if (datosEntregaArticulo) {
      valoresAux.condicionesArticulo = "";
      valoresAux.fechaEntrega = "";
    }

    const informacionSiniestro = {
      idCliente: Number(idCliente),
      idSiniestro: idSiniestroAux.idSiniestro,
      idFlujo: detalleFlujo.idFlujo,
      idContrato: detalleFlujo.idContrato,
      idEstado: 1,
      idSubEstado: 10,
      idLocal: Number(valoresAux.idLocal),
      idVendedor: Number(valoresAux.idVendedor),
      numeroSiniestro: respuestaNumeroSiniestro?.data ?? numeroSiniestro,
      coberturaSiniestro: valoresAux.coberturaSiniestro,
      tipoEntrega: valoresAux.tipoEntrega,

      fechaSiniestro: valoresAux.fechaSiniestro,
      fechaNotificacion: respuestaFechaSistema.fechaHora,
      fechaEntrega: valoresAux.fechaEntrega,

      detalleSiniestro: valoresAux.detalleSiniestro.toUpperCase(),
      entregaArticulo: valoresAux.entregaArticulo,
      inmediato: valoresAux.inmediato,
      condicionesArticulo: valoresAux.condicionesArticulo.toUpperCase(),

      esActivo: true,
    };

    console.log(informacionSiniestro);

    let idSiniestroTemporal;

    try {
      idSiniestroTemporal = await crearSiniestroCrear(informacionSiniestro);

      /* console.log(idSiniestroTemporal); */

      if (idSiniestroTemporal.state) {
        setIdSiniestro(idSiniestroTemporal.data);
        setDatosEnviadosCorrectamente(true);
        //setIdSiniestroAux(idSiniestroTemporal.data);
        setIdSiniestroAux((state) => ({
          ...state,
          idSiniestro: idSiniestroTemporal.data,
        }));

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
        }, 100);
      } else {
        alert(`${idSiniestroTemporal.message}`);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert(`${error.response.data.message}`);
      console.log(error);
    }

    if (idSiniestroAux.stateSiniestro) {
      const fechaSeguimiento = new Date();

      const informacionSeguimiento = {
        idSeguimiento: 0,
        idSiniestro: Number(idSiniestroTemporal.data),
        esSiniestro: true,
        idEstado: 1,
        idSubestado: 11,
        fechaEstado: fechaSeguimiento.toISOString().split(".")[0],
        esActivo: true,
      };
      /* console.log(informacionSeguimiento); */

      try {
        const respuestaSeguimiento = await crearSeguimiento(
          informacionSeguimiento
        );

        /*  console.log(respuestaSeguimiento);
         */
        if (respuestaSeguimiento.state) {
          setIdSiniestroAux((state) => ({
            ...state,
            stateSiniestro: false,
          }));
        }
        /* console.log(respuestaSeguimiento); */
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {detalleFlujo ? (
        <Formik
          initialValues={{
            codigoSiniestro: "",
            coberturaSiniestro: "",
            fechaSiniestro: "",

            idLocal: "",
            idVendedor: "",
            detalleSiniestro: "",
            tipoEntrega: "",
            entregaArticulo: false,

            fechaEntrega: "",
            condicionesArticulo: "",
            inmediato: false,
          }}
          validationSchema={Yup.object({
            coberturaSiniestro: Yup.string().required(
              "Cobertura Siniestro requerido"
            ),
            fechaSiniestro: Yup.string().required("Fecha Siniestro requerido"),
            idLocal: Yup.string().required("Local requerido"),
          })}
          validate={(values) => {
            const errors = {};
            const fechaActual = new Date();

            if (
              !validarFechaMayorMenor(
                values.fechaSiniestro,
                fechaActual,
                "MENOR"
              )
            ) {
              errors.fechaSiniestro = "La fecha es mayor a la fecha actual";
            }

            if (
              !validarFechaRango(
                values.fechaSiniestro,
                fechaActual,
                detalleFlujo.diasNotifica
              )
            ) {
              errors.fechaSiniestro =
                "La fecha esta fuera del rango de cobertura";
            }

            if (existeColaborador) {
              errors.idVendedor = "No existe el colaborador";
            }

            if (values.coberturaSiniestro === "COB038") {
              if (validarcoberturaCaidas(values.coberturaSiniestro)) {
                errors.coberturaSiniestro =
                  "No es posible aplicar la cobertura";
              }
            }

            if (values.coberturaSiniestro === "COB039") {
              if (validarCoberturaMenorACien(values.coberturaSiniestro)) {
                errors.coberturaSiniestro =
                  "No es posible aplicar la cobertura";
              }
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            configuracionDatosEnvio(values);
          }}
        >
          {({ values, handleChange, touched, errors, handleBlur }) => (
            <Form>
              <SCSAccordion tituloCabecera="Información Siniestro">
                <Grid container spacing={1} rowSpacing={1}>
                  <Grid item md={12}>
                    <Grid container spacing={1} rowSpacing={1}>
                      <Grid item md={12}>
                        <div className="cliente-informacion-seccion">
                          <MostrarNumeroSiniestro />

                          <SelectItemsCobertura
                            name="coberturaSiniestro"
                            labelName="Cobertura Siniestro"
                            componentSize="29%"
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                          />

                          <TextField
                            id="fechaSiniestro"
                            label="Fecha Siniestro"
                            type="date"
                            size="small"
                            sx={{ width: "15%" }}
                            onChange={handleChange}
                            value={formatoFecha(values.fechaSiniestro)}
                            InputLabelProps={{ shrink: true }}
                            error={
                              touched.fechaSiniestro &&
                              Boolean(errors.fechaSiniestro)
                            }
                            helperText={
                              touched.fechaSiniestro && errors.fechaSiniestro
                            }
                            onBlur={handleBlur}
                          />
                          <SelectItemsCatalogoSinPadre
                            pais="ECU"
                            especial="true"
                            catalogos="LOCAL"
                            grupo={idGrupo}
                            name="idLocal"
                            labelName="Local"
                            componentSize="28%"
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                          />
                        </div>

                        <div className="cliente-informacion-seccion">
                          <TextField
                            id="idVendedor"
                            name="idVendedor"
                            type="text"
                            label="Colaborador"
                            sx={{ width: "15%" }}
                            value={values.idVendedor}
                            onChange={handleChange}
                            size="small"
                            error={
                              touched.idVendedor && Boolean(errors.idVendedor)
                            }
                            helperText={touched.idVendedor && errors.idVendedor}
                            onBlur={handleBlur}
                          />

                          <div className="siniestro-informacion-estatica">
                            <BuscarColaborador idVendedor={values.idVendedor} />

                            <VerificarTipoFlujo />

                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.inmediato}
                                    onChange={handleChange}
                                    name="inmediato"
                                  />
                                }
                                label="Inmediato"
                              />
                            </FormGroup>
                          </div>
                        </div>

                        <div className="cliente-informacion-seccion">
                          <TextField
                            id="detalleSiniestro"
                            name="detalleSiniestro"
                            type="text"
                            label="Detalle Siniestro"
                            sx={{ width: "75%" }}
                            value={values.detalleSiniestro}
                            onChange={handleChange}
                            size="small"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />

                          <SelectItemsCatalogoSinPadre
                            pais="ECU"
                            especial="false"
                            catalogos="TIPOENTR"
                            grupo={1}
                            name="tipoEntrega"
                            labelName="Tipo Entrega"
                            componentSize="20%"
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                          />
                        </div>

                        {validarTipoEntrega(values.tipoEntrega) && (
                          <>
                            <div className="cliente-informacion-seccion">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.entregaArticulo}
                                      onChange={handleChange}
                                      name="entregaArticulo"
                                    />
                                  }
                                  label="Entrega Articulo"
                                />
                              </FormGroup>

                              {validarEntregaArticulo(
                                values.entregaArticulo
                              ) && (
                                <>
                                  <TextField
                                    disabled
                                    id="fechaEntrega"
                                    label="Fecha Entrega"
                                    type="date"
                                    size="small"
                                    sx={{ width: "30%" }}
                                    onChange={handleChange}
                                    value={obtenerFechaActualFormulario()}
                                    InputLabelProps={{ shrink: true }}
                                  />

                                  <TextField
                                    id="condicionesArticulo"
                                    name="condicionesArticulo"
                                    type="text"
                                    label="Condiciones Entrega"
                                    sx={{ width: "100%" }}
                                    value={values.condicionesArticulo}
                                    onChange={handleChange}
                                    size="small"
                                    inputProps={{
                                      style: {
                                        textTransform: "uppercase",
                                      },
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </>
                        )}

                        <div className="confirmar-estados-servicios">
                          <SCSLoadingButton
                            loading={loading}
                            titulo="GUARDAR"
                          />

                          {datosEnviadosCorrectamente && (
                            <AcceptServiceresponse />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SCSAccordion>
            </Form>
          )}
        </Formik>
      ) : (
        <SpinnerLoadSrx />
      )}
    </>
  );
};

export default FormSiniestro;
