import React, { useEffect } from "react";

const IconoSemaforoListaNegra = ({ state }) => {
  useEffect(() => {
    console.log(state);
  }, [state]);

  const validarTipoBloqueo = () => {
    if (state === "PARCIAL" || state === "BLANDO") {
      return "light yellow";
    }
    if (state === "DURO") {
      return "light red";
    }
    if (state === "NADA") {
      return "light green";
    }
  };

  return (
    <div class="semaphore">
      <div class={validarTipoBloqueo()}></div>
    </div>
  );
};

export default IconoSemaforoListaNegra;
