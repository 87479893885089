import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import React, { useEffect, useState } from "react";
import DocumentosPrecancelacion from "../components/DocumentosPrecancelacion";
import FormGestionDevolucionProductos from "../components/FormGestionDevolucionProductos";
import { consultarRequerimiento } from "../services/solicitudesService";

const InformacionDevolucionProductos = ({ solicitud }) => {
  const [informacionSolicitud, setInformacionSolicitud] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    /* console.log(solicitud); */
    const getData = async () => {
      try {
        const respuesta = await consultarRequerimiento(
          solicitud.idRequerimiento
        );

        /* console.log(respuesta); */

        if (respuesta.state) {
          setInformacionSolicitud(respuesta.data);
          setIsLoading(false);
        } else {
          setInformacionSolicitud({});
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        alert(`${error.response.data.message}`);
      }
    };
    getData();
  }, [solicitud]);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  return (
    <div className="informacionPrecancelacion-container">
      <FormGestionDevolucionProductos
        solicitud={solicitud}
        informacionSolicitud={informacionSolicitud}
        documentos={informacionSolicitud.soporteRequerimientos}
      />
      <DocumentosPrecancelacion
        documentos={informacionSolicitud.soporteRequerimientos}
      />
    </div>
  );
};

export default InformacionDevolucionProductos;
