import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { useHistory } from 'react-router-dom';
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
import { auth } from "@jumbo/services/auth/firebase/firebase";
import * as yup from 'yup';
import { Form, Formik } from "formik";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
//import {useNavigate} from "react-router-dom";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Collapse, IconButton, Link } from '@mui/material/';
import Alert from '@mui/material/Alert';
import { useOnForgotPassword } from '../../app-auth/hook/index'
import CloseIcon from '@mui/icons-material/Close';


import {onlyTexto} from "../../../utils/normalizers";

//import ContentLoader from '../../../../@jumbo/components/JumboContentLayout';

// const Alert = React.forwardRef(function Alert(props, ref) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });


const validationSchema = yup.object({
    email: yup
    .string('Ingrese código de usuario')
    /*.matches(onlyTexto, 'Ingrese solo texto')*/
    .max(32, 'Excede longitud máxima')
    .required('Código es requerido'),
});
const ForgotPassword = () => {

    const { code } = useSelector((state) => state.auth);

    const [open, setOpen] = useState(false);
    const [sendForgot, setSendForgot] = useState(false);
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    useOnForgotPassword(email, sendForgot, setSendForgot);
  
    const onSubmit = () => {
       setSendForgot(true)
    };

    // //const navigate = useNavigate();
    // const {setActiveLayout} = useJumboApp();
    // React.useEffect(() => {
    //     setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    // }, []);

    const mutation = useAuthSignInWithEmailAndPassword(auth, {
        onError(error) {
            console.log(error);
        },
        onSuccess(data) {
            history.push("/");
        }
    });
    const handleCancel = () =>{
        history.push("/");
      }

    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: theme => theme.spacing(4),
        }}>

            <Card sx={{ maxWidth: '100%', width: 360, mb: 4 }}>
                <Div sx={{ position: 'relative', height: '200px' }}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="200"
                        image={`${ASSET_IMAGES}/logo-login.jpg`}
                    />
                    <Div
                        sx={{
                            flex: 1,
                            inset: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: theme => alpha(theme.palette.common.black, .5),
                            p: theme => theme.spacing(3),
                        }}
                    >
                        <Typography
                            variant={"h2"}
                            sx={{
                                color: 'common.white',
                                fontSize: '1.5rem',
                                mb: 0
                            }}
                        >
                            Recuperar Contraseña
                        </Typography>
                    </Div>
                </Div>
                <CardContent sx={{ pt: 0 }}>
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            email: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => {
                            setSubmitting(true);
                           // onSignIn(data.email);
                           setEmail(data.email);
                          
                           setSendForgot(true);
                            setSubmitting(false);
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                                <Avatar
                                    alt="Usuario" 
                                      sx={{
                                        width: 56,
                                        height: 56,
                                        marginLeft: 'auto',
                                        boxShadow: shadows[3],
                                        transform: 'translateY(-50%)',
                                    }}
                                />
                                {mutation.isError && <p>{mutation.error.message}</p>}
                                <Div sx={{mb: 3, mt: 1}}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label="Usuario"
                                    />
                                </Div>
                            
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting || mutation.isLoading}
                                >Reset Password</LoadingButton>
                                <LoadingButton
                                 color="secondary"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    onClick={() => handleCancel()}
                                >Cancel</LoadingButton>

                            </Form>
                        )}
                    </Formik>
                    
                    
                </CardContent>
            </Card>

        </Div>
    );
};

export default ForgotPassword;
