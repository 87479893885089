import React, { useState } from "react";

import BuscadorPorIdentificador from "app/modules/app-cliente/containers/BuscadorPorIdentificador";
import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";

import DetalleCancelacionSeguro from "../../containers/DetalleCancelacionSeguro";
import ListaSegurosCancelacion from "../../containers/ListaSegurosCancelacion";

import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";

import { consultaCliente } from "../../../app-cliente/services/clienteService";

const SeguroGestionar = () => {
  const [cliente, setCliente] = useState(null);
  const [idCliente, setIdCliente] = useState("");
  const [mostrarDetalle, setMostrarDetalle] = useState(true);
  const [idContratoAux, setIdContratoAux] = useState(null);

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  const buscarExistenciaCliente = async (identificador) => {
    try {
      const respuestaCliente = await consultaCliente(identificador);

      if (respuestaCliente.state) {
        setIdCliente(respuestaCliente.data.idCliente);

        setCliente(respuestaCliente.data);
        setMostrarDetalle(false);
      } else {
        setCliente(nuevoCliente);
        setIdCliente(nuevoIdCliente);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdCliente = (id) => () => {
    buscarExistenciaCliente(id);
  };

  if (mostrarDetalle) {
    return <BuscadorPorIdentificador traerIdCliente={traerIdCliente} />;
  }

  if (idCliente < 0) {
    return <h2>No se ha encontrado el cliente.!!</h2>;
  }

  return (
    <div>
      <BotonGerericoSalir />

      <HeaderInformacionCliente idCliente={idCliente} />

      {idContratoAux ? (
        <DetalleCancelacionSeguro
          idCliente={idCliente}
          contrato={idContratoAux}
        />
      ) : (
        <ListaSegurosCancelacion
          cliente={cliente}
          idCliente={idCliente}
          setIdContratoAux={setIdContratoAux}
          isCancel={true}
        />
      )}
    </div>
  );
};

export default SeguroGestionar;
