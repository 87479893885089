import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { green } from "@mui/material/colors";

import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

import "./styles.css";

const useStyles = makeStyles((theme) => ({
  primerAccordion: {
    boxShadow: 3,
    lineHeight: 0,
    borderRadius: "25px !important",
    animation: "none",
  },
}));

const SCSAccordion = ({
  tituloCabecera,
  handleExpand,
  controlExpand = {},
  children,
}) => {
  const classes = useStyles();
  /* const [controlExpand, setControlExpand] = useState(controlExpanded); */

  const [estilos, setEstilos] = useState(
    Number(localStorage.getItem("idGrupoStorage"))
  );

  const validarGrupo = () => {
    if (Number(estilos) === 1) {
      return "#e1251b";
    } else if (Number(estilos) === 2) {
      return "#e1251b";
    } else if (Number(estilos) === 3) {
      return "#ef732c";
    } else if (Number(estilos) === 4) {
      return "#c73436";
    }
  };

  return (
    <Accordion
      defaultExpanded={true}
      className={classes.primerAccordion}
      expanded={controlExpand.controlExpanded}
      /* expanded={!controlSave} */
    >
      <AccordionSummary
        onClick={handleExpand}
        expandIcon={
          <>
            {!controlExpand.controlExpanded && controlExpand.controlSave && (
              <AcceptServiceresponse />
            )}
            <ExpandMoreIcon sx={{ color: "#ffffff" }} />
            {controlExpand.controlExpanded && controlExpand.controlSave && (
              <CheckCircleOutlineIcon
                sx={{
                  color: green[500],
                  transform: "rotate(180deg)",
                  animation: "none",
                }}
                fontSize="large"
              />
            )}
          </>
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        /* Funcion para el cambio de color */
        sx={{
          margin: 0,
          borderRadius: "25px",
          backgroundColor: `${validarGrupo()}`,
          animation: "none",
        }}
      >
        <h3 className="formulario-cabecera">{tituloCabecera}</h3>
      </AccordionSummary>

      <AccordionDetails sx={{ marginTop: "0.5rem" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default SCSAccordion;
