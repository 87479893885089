import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";

import { formatoFecha } from "../../shared/validations/validaciones";

import { consultaCabeceraTraslado } from "../Services/siniestrosService";

const HeaderInformacionTrasladoIndirecto = ({ idTraslado }) => {
  const [datosBien, setDatosBien] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const { state, data } = await consultaCabeceraTraslado(idTraslado);

        if (state) {
          console.log(data);
          setDatosBien(data);
          setIsLoading(false);
        } else {
          setDatosBien({});
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [idTraslado]);

  if (isLoading) {
    return <h1>Traslado no encontrado</h1>;
  }

  return (
    <div className="header-traslado-container">
      <Card>
        <header className="header-traslado-grid">
          <div className="header-traslado-titulo">
            <h2>Traslado</h2>
            <h2>Indirecto</h2>
          </div>

          <div className="header-traslado-body">
            <div className="header-traslado-information">
              <div>
                <div className="header-traslado-informacion">
                  <h4>Tipo Traslado:</h4>
                  <h4>{datosBien.tipoTraslado}</h4>
                </div>

                <div className="header-traslado-informacion">
                  <h4>Origen:</h4>
                  <h4>{datosBien.origen}</h4>
                </div>
              </div>

              <div>
                <div className="header-traslado-informacion">
                  <h4>Fecha Inicio:</h4>
                  <h4>{formatoFecha(datosBien.fechaInicio)}</h4>
                </div>
                <div className="header-traslado-informacion">
                  <h4>Destino:</h4>
                  <h4>{datosBien.destino}</h4>
                </div>
              </div>

              <div>
                <div className="header-traslado-informacion">
                  <h4>Fecha Fin:</h4>
                  <h4>{formatoFecha(datosBien.fechaFin)}</h4>
                </div>

                <div className="header-traslado-informacion">
                  <h4>Transportista:</h4>
                  <h4>{datosBien.transportista}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="header-traslado-detalle">
                <h4>Detalle Envio:</h4>
                <h4>{datosBien.detalleEnvio}</h4>
              </div>
            </div>
          </div>
        </header>
      </Card>
    </div>
  );
};

export default HeaderInformacionTrasladoIndirecto;
