import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import ReportesSiniestros from "./ReportesSiniestros/ReportesSiniestros";
import "../styles/main.css";

const SiniestrosReportes = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Reportes - Siniestro</h2>
      <ReportesSiniestros />
    </div>
  );
};

export default SiniestrosReportes;
