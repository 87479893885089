import React, { useState } from "react";

import { useHistory } from "react-router";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { consultarSiguienteLlamada } from "../services/cobranzaServices";
import { LoadingButton } from "@mui/lab";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    /*  hight: 2000 */
    minWidth: 800,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    /* width: "50%", */
  },
  /* "& .MuiDialogActions-root": {
    with: 30,
  }, */
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalGestionCobranza = ({
  children,
  open,
  setOpen,
  titulo,
  mensaje,
  idUsuario,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useHistory();

  const navegarHome = () => {
    navigate.push("/cobranza/gestionCobranza");
  };

  const gestionarSiguienteLlamada = async () => {
    setLoading(true);

    try {
      const respuestaSiguienteLlamada = await consultarSiguienteLlamada(
        idUsuario
      );

      console.log(respuestaSiguienteLlamada);

      if (respuestaSiguienteLlamada.state) {
        setLoading(false);
        const path = "/cobranza/gestionLlamada";

        navigate.push(path, {
          state: { informacionCobranza: respuestaSiguienteLlamada.data },
        });
      } else {
        setLoading(false);

        const path = "/cobranza/gestionCobranza";
        navigate.push(path);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      const path = "/cobranza/gestionCobranza";
      navigate.push(path);
    }
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        /* aria-describedby="alert-dialog-slide-description" */
        /* style={{ height: "30%" }} */

        /* onClose={() => setOpen(false)} */
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          /* onClose={() => setOpen(false)} */
        >
          {titulo}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{mensaje}</Typography>
        </DialogContent>

        {children && <DialogContent dividers>{children}</DialogContent>}

        <DialogActions>
          <LoadingButton
            variant="contained"
            size="small"
            loading={loading}
            onClick={() => gestionarSiguienteLlamada()}
          >
            Siguiente
          </LoadingButton>

          <Button autoFocus onClick={() => navegarHome()}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ModalGestionCobranza;
