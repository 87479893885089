import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import SaveIcon from "@mui/icons-material/Save";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";

import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";

import handleDownlPdf from "../../../utils/document/documentosService";
import descargaImage from "../../../utils/document/documentoPng";

import {
  consultarDocumentosFlujo,
  consultarDocumentosGuradadosSiniestro,
  consultarDocumentoXServicio,
  consultarImagenDocumento,
  crearDocumentoActaNotificacion,
  crearSeguimiento,
} from "../Services/siniestrosService";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

import "../styles/main.css";
import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/shared/mask/stylesTables";
import ModalCerrarSiniestro from "../container/ModalCerrarSiniestro";
import { diccionarioExtenciones } from "app/modules/shared/dictionary/extensionDocumentos";

const FormSiniestrosDocumentos = ({
  idSiniestro,
  numeroSiniestro,
  idFlujo,
}) => {
  const [documentos, setDocumentos] = useState([]);
  const [file, setFile] = useState("");
  const [fileIMG, setFileIMG] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [idDocumentoAux, setIdDocumentoAux] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [existeDocumento, setExisteDocumento] = useState(false);
  const [IdDocumentoExiste, setIdDocumentoExiste] = useState(null);
  const [extensionDocumento, setExtensionDocumento] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [documentosSiniestro, setDocumentosSiniestro] = useState([]);

  const [isUpdatingDocs, setIsUpdatingDocs] = useState(true);
  const [open, setOpen] = useState(false);

  const [openFiniquito, setOpenFiniquito] = useState(false);

  useEffect(() => {
    let idsDocumentos = [];
    const getData = async () => {
      try {
        const respuestaDocumentosSiniestro = await consultarDocumentosFlujo(
          idFlujo
        );

        /* console.log({ respuestaDocumentosSiniestro }); */

        if (respuestaDocumentosSiniestro.state) {
          try {
            const respuestaDocumentosGuardadosSiniestro =
              await consultarDocumentosGuradadosSiniestro(idSiniestro);

            /*  console.log(respuestaDocumentosGuardadosSiniestro.data); */

            if (respuestaDocumentosGuardadosSiniestro.state) {
              setIsLoading(false);

              respuestaDocumentosGuardadosSiniestro.data.map((doc, index) => {
                idsDocumentos.push(doc.idDocumento);
              });

              setDocumentosSiniestro(idsDocumentos);
            } else {
              setIsLoading(false);
              setDocumentosSiniestro([]);
            }
          } catch (error) {
            setIsLoading(false);
            console.log(error);
          }

          const arrayAuxiliar = respuestaDocumentosSiniestro.data.map(
            (documento, index) => {
              if (idsDocumentos.includes(documento.idDocumento)) {
                return {
                  ...documento,
                  existe: true,
                };
              } else {
                return {
                  ...documento,
                  existe: false,
                };
              }
            }
          );
          /* console.log({ arrayAuxiliar }); */
          setDocumentos(arrayAuxiliar);
        } else {
          setIsLoading(false);
          setDocumentos([]);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
  }, [isUpdatingDocs]);

  /*   useEffect(() => {
    const getData = async () => {
      try {
        const respuestaDocumentosGuardadosSiniestro =
          await consultarDocumentosGuradadosSiniestro(idSiniestro);

        console.log(respuestaDocumentosGuardadosSiniestro.data);

        if (respuestaDocumentosGuardadosSiniestro.state) {
          setIsLoading(false);

          setDocumentosSiniestro(idsDocumentos);

          respuestaDocumentosGuardadosSiniestro.data.map((doc, index) => {
            idsDocumentos.push(doc.idDocumento);
          });
          console.log(idsDocumentos);
        } else {
          setIsLoading(false);
          setDocumentosSiniestro([]);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    let idsDocumentos = [];
    getData();
  }, []);
 */

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  /* para transformar PDF */
  const base64Transform = (archivo, cb) => {
    const reader = new FileReader();

    reader.readAsDataURL(archivo);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const cargarPDF = ({ target }) => {
    if (target.file < 1 || !target.validity.valid) {
      return;
    }

    base64Transform(target.files[0], (err, result) => {
      if (result) {
        const documentSplit = result.split(",");
        console.log(result);
        /* setFile(result); */
        setFileIMG(result);
        /* setFile(documentSplit[0]) */ setFile(documentSplit[1]);
        /* console.log(documentSplit[0]); */
        /*  console.log(documentSplit[1]);
        console.log(result); */
        setIsSelected(true);
      }
    });
  };

  const dowloadDocumentoService = async (nombreServicio, id) => {
    setLoadingGenerate(true);

    try {
      const respuestaDocumento = await consultarDocumentoXServicio(
        nombreServicio,
        id
      );

      /*  console.log(respuestaDocumento); */
      if (respuestaDocumento.state) {
        handleDownlPdf(
          respuestaDocumento.data,
          `SNT_${nombreServicio}_${numeroSiniestro}` /* nombreServicio */
        );
        setLoadingGenerate(false);
      } else {
        alert(`${respuestaDocumento.message}`);
        setLoadingGenerate(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingGenerate(false);
    }
  };

  const handleDownload = (nombreServicio) => {
    /* console.log(nombreServicio); */
    dowloadDocumentoService(nombreServicio, idSiniestro);
  };

  const handleUpload = (event) => {
    /* console.log(event); */
    cargarPDF(event);

    /*     carga de documentos - sacar el typo de documento a subir   */

    const fileAux = event.target.files[0].type.split("/");
    /*  console.log(fileAux); */
    setExtensionDocumento(fileAux[1]);
    /* console.log(event.target.files[0].type); */
    setSelectedFile(event.target.files[0]);
  };

  const descargarBase64 = async (idDocumento, nombreDocumento) => {
    /* console.log({ nombreDocumento }); */
    setIdDocumentoExiste(idDocumento);
    try {
      const respuestaImagenDocumento = await consultarImagenDocumento(
        idSiniestro,
        idDocumento
      );

      /* console.log(respuestaImagenDocumento); */

      if (respuestaImagenDocumento.state) {
        if (respuestaImagenDocumento.data.extension === ".pdf") {
          /* se pasa la base64 y el nombre con el cual se va descargar */
          handleDownlPdf(
            respuestaImagenDocumento.data.documento,
            `SNT_${numeroSiniestro}_${idDocumento}_${fechasDocumentosSiniestro()}`
          );
        } else {
          descargaImage(
            respuestaImagenDocumento.data.documento,
            `SNT_${numeroSiniestro}_${idDocumento}_${fechasDocumentosSiniestro()}`
          );
        }
      } else {
        alert(`${respuestaImagenDocumento.message}`);
      }
    } catch (error) {
      console.log(error);
      alert("No existe el documento");
    }
  };

  const configuracionDatosEnvioDocumento = async (idDocumento) => {
    setLoading(true);

    //validacion para control de carga de documentos
    if (file.length === 0) {
      setOpen(true);
      setLoading(false);
      return;
    }

    const informacionDocumentos = {
      idDocumentoSiniestro: 0,
      idDocumento: Number(idDocumento),
      idSiniestro: Number(idSiniestro),
      documento: file,
      extension: diccionarioExtenciones(extensionDocumento),
      nombreDocumento: `SNT_${numeroSiniestro}_${Number(
        idDocumento
      )}_${fechasDocumentosSiniestro()}`,
      esActivo: true,
    };

    console.log(informacionDocumentos);

    setLoading(false);

    try {
      const respuestaDocumentos = await crearDocumentoActaNotificacion(
        informacionDocumentos
      );

      if (respuestaDocumentos.state) {
        alert("Documento Guardado exitosamente");

        setTimeout(() => {
          setLoading(false);
          setIsUpdatingDocs(!isUpdatingDocs);
        }, 1000);

        //aqui va lo de cancelacion de siniestro
      } else {
        setLoading(false);
        alert(`${respuestaDocumentos.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert("No se ha podido guardar");
    }

    if (idDocumento === 7) {
      setOpenFiniquito(true);
    }

    setIdDocumentoAux(null);
    setIsSelected(false);
    setFile("");
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Documento</StyledTableCell>
              <StyledTableCell align="center">Detalle</StyledTableCell>
              <StyledTableCell align="center">Generar</StyledTableCell>
              <StyledTableCell align="center">Cargar</StyledTableCell>
              <StyledTableCell align="center">Guardar</StyledTableCell>
              <StyledTableCell align="center">Descargar</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentos.map((documento, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {documento.nombreDocumento}
                </StyledTableCell>

                <StyledTableCell component="th" scope="row" align="center">
                  {isSelected && idDocumentoAux === documento.idDocumento ? (
                    <div>
                      <p>Nombre: {selectedFile.name}</p>
                      {/*   <p>Tipo: {selectedFile.type}</p> */}
                    </div>
                  ) : (
                    <p>Archivo!! </p>
                  )}
                </StyledTableCell>
                {documento.autogenerado ? (
                  <>
                    <StyledTableCell align="center">
                      <LoadingButton
                        sx={{ width: "85%" }}
                        color="info"
                        variant="contained"
                        size="small"
                        endIcon={<FileDownloadIcon />}
                        loading={loadingGenerate}
                        onClick={() => handleDownload(documento.servicio)}
                        loadingPosition="end"
                      >
                        Generar
                      </LoadingButton>
                    </StyledTableCell>
                  </>
                ) : (
                  <StyledTableCell align="center">
                    <p>No disponible</p>
                  </StyledTableCell>
                )}

                <StyledTableCell align="center">
                  <Button
                    variant="outlined"
                    component="label"
                    size="small"
                    sx={{ width: "85%" }}
                    endIcon={<FileUploadIcon />}
                    onClick={() => {
                      setIdDocumentoAux(documento.idDocumento);
                    }}
                  >
                    <input
                      hidden
                      accept=".png, .jpg, .jpeg, .pdf"
                      type="file"
                      onChange={handleUpload}
                    />
                    Cargar
                  </Button>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <LoadingButton
                    sx={{ width: "85%" }}
                    color="info"
                    variant="contained"
                    size="small"
                    endIcon={<SaveIcon />}
                    loading={loading}
                    onClick={() =>
                      configuracionDatosEnvioDocumento(documento.idDocumento)
                    }
                    loadingPosition="end"
                  >
                    Guardar
                  </LoadingButton>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Button
                    color={!documento.existe ? "success" : "error"}
                    variant="contained"
                    size="small"
                    sx={{ width: "85%" }}
                    endIcon={<FileDownloadIcon />}
                    onClick={() =>
                      descargarBase64(
                        documento.idDocumento,
                        documento.nombreDocumento
                      )
                    }
                  >
                    Descargar
                  </Button>

                  {existeDocumento &&
                    IdDocumentoExiste === documento.idDocumento && (
                      <CheckIcon
                        sx={{ color: "#64dd17" }}
                        className="fa-plus-circle"
                      />
                    )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
        /* key={("bottom", "center")} */
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          Cargue Primero un Documento !!
        </Alert>
      </Snackbar>

      <ModalCerrarSiniestro open={openFiniquito} setOpen={setOpenFiniquito} />

      {/*   <iframe src={fileIMG} frameBorder="0" title="myFrame" />
      <img src={fileIMG} alt="" /> */}
    </>
  );
};

export default FormSiniestrosDocumentos;
