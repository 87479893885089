import http from "../../../comunication/http-commonsession";

/* eslint-disable camelcase */
export const obtenerParametro = async (codigoParametro) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ObtenerParametro?codigoParametro=${codigoParametro}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerParametroGrupo = async (codigoParametro, idGrupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ObtenerParametroGrupo?codigoParametro=${codigoParametro}&idGrupo=${idGrupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerRegistroGrupo = async (idGrupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ObtenerRegistroGrupo?idGrupo=${idGrupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

//http://10.10.0.79:5012/configuracion/DescargarArchivo?name=SLC_158002_20221222.pdf&modulo=solicitud
export const obtenerDocumentoConfiguracion = async (
  nombreDocumento,
  modulo
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}configuracion/DescargarArchivo?name=${nombreDocumento}&modulo=${modulo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerLocal = async (idLocal) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ObtenerLocal?idLocal=${idLocal}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerTiposConsumo = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/obtenerTipoConsumo`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const descargarArchivoStatic = async (name) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}configuracion/DescargarArchivoStatic?name=${name}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerUbicGeografica = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ObtenerFormato`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export const obtenerFormato = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ObtenerFormato`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export const listarLocales = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ListarLocales`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

//http://localhost:5018/transaccional/ObtenerLocalXSecuencialLocal?grupo=1&secuancialLocal=711
export const ObtenerLocalXSecuencialLocal = async (grupo, secuencialLocal) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}transaccional/ObtenerLocalXSecuencialLocal?grupo=${grupo}&secuencialLocal=${secuencialLocal}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const ObtenerColaborador = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ObtenerColaboradorXIdentificacion?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

//http://10.10.0.79:5000/Catalogo/GuardarLocal
export const crearLocal = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}transaccional/GuardarLocal`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/Catalogo/GuardarColaborador
export const crearColaborador = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}Catalogo/GuardarColaborador`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const ObtenerLocalUbiGeografica1 = async (idLocal) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ObtenerLocalUbiGeografica1?idLocal=${idLocal}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const consultarGrupoGenerico = async (dato, tipo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ObtenerGrupo?dato=${dato}&tipo=${tipo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
