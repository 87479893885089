import React from "react";

import FormSiniestrosDocumentos from "../components/FormSiniestrosDocumentos";
import HeaderInformacionCliente from "../../shared/containers/HeaderInformacionCliente";
import HeaderInformacionProducto from "../components/HeaderInformacionProducto";
import HeaderInformacionSiniestro from "./HeaderInformacionSiniestro";

const DetalleDocumentosSiniestro = ({
  idCliente,
  idSiniestro,
  idFlujo,
  numeroSiniestro,
  idGrupo,
}) => {
  return (
    <div>
      <HeaderInformacionCliente idCliente={idCliente} />

      <HeaderInformacionProducto idSiniestro={idSiniestro} />

      <HeaderInformacionSiniestro
        idCliente={idCliente}
        idSiniestro={idSiniestro}
      />

      <FormSiniestrosDocumentos
        idSiniestro={idSiniestro}
        numeroSiniestro={numeroSiniestro}
        idFlujo={idFlujo}
      />
    </div>
  );
};

export default DetalleDocumentosSiniestro;
