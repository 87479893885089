import React, { useEffect, useState } from "react";

import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  Paper,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  styled,
} from "@mui/material";

import { consultarSegurosCliente } from "../services/SeguroService/seguroService";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ListaSegurosCliente = ({ idCliente, setIdContratoAux, isCancel }) => {
  const [segurosCliente, setSegurosCliente] = useState([]);

  useEffect(() => {
    const getSeguros = async () => {
      try {
        const respuestaSegurosCliente = await consultarSegurosCliente(
          idCliente
        );

        console.log(respuestaSegurosCliente);

        if (respuestaSegurosCliente.state) {
          setSegurosCliente(respuestaSegurosCliente.data);
        } else {
          setSegurosCliente([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getSeguros();
  }, []);

  const escogerIdContrato = (contrato) => {
    setIdContratoAux(contrato);
  };

  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table sx={{ width: "100%" }} aria-label="customized table" size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell width="5%">N° Contrato</StyledTableCell>
            <StyledTableCell align="center" width="10%">
              Producto
            </StyledTableCell>
            <StyledTableCell align="center" width="15%">
              Fecha de Inicio
            </StyledTableCell>
            <StyledTableCell align="center" width="10%">
              Fecha de Fin
            </StyledTableCell>
            <StyledTableCell align="center" width="10%">
              Estado
            </StyledTableCell>
            <StyledTableCell align="center" width="10%">
              Tipo Pago
            </StyledTableCell>
            <StyledTableCell align="center" width="10%">
              Gestión
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {segurosCliente.map((contratos) => (
            <StyledTableRow key={contratos.idContrato}>
              <StyledTableCell component="th" scope="row">
                {contratos.numContrato}
              </StyledTableCell>
              <StyledTableCell align="center">
                {contratos.producto}
              </StyledTableCell>
              <StyledTableCell align="center">
                {contratos.fechaInicio}
              </StyledTableCell>
              <StyledTableCell align="center">
                {contratos.fechaFin}
              </StyledTableCell>
              <StyledTableCell align="center">
                {contratos.estado}
              </StyledTableCell>
              <StyledTableCell align="center">
                {contratos.tipoPago}
              </StyledTableCell>

              <StyledTableCell align="center">
                <Button
                  variant="contained"
                  onClick={() => escogerIdContrato(contratos)}
                  endIcon={<SendIcon />}
                >
                  {isCancel ? "Cancelar" : "Generar"}
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListaSegurosCliente;
