import React, { useEffect, useState } from "react";

import BuscadorGestionSeguros from "../../container/BuscadorGestionSeguros";
import DetalleCerrarSiniestro from "../../container/DetalleCerrarSiniestro";
import ListaSiniestrosCliente from "../../container/ListaSiniestrosCliente";
import useSiniestroLocalStorage from "../../hooks/useSiniestrosLocalStorage";

import { consultaCliente } from "../../../app-cliente/services/clienteService";
import { consultarSiniestroXCodigo } from "../../Services/siniestrosService";

const SiniestroCerrarSiniestro = () => {
  const [idCliente, setIdCliente] = useState(null);
  /* Este deberia ser Codigo Siniestro */
  const [idSiniestro, setIdSiniestro] = useState(null);
  const [idContrato, setIdContrato] = useState(null);
  const [mostrarBuscador, setMostrarBuscador] = useState(true);
  const [idFlujo, setIdFlujo] = useState(null);

  const [codigoSiniestro, setCodigoSiniestro] = useSiniestroLocalStorage(
    "idSiniestroStorage",
    null
  );

  const [grupoSiniestro, setGrupoSiniestro] = useSiniestroLocalStorage(
    "idSiniestroGrupoStorage",
    null
  );

  const [datosBusqueda, setDatosBusqueda] = useState({
    identificacion: "",
    opcionBusqueda: "",
    idGrupo: null,
  });

  const nuevoIdCliente = -1;

  useEffect(() => {
    /* console.log(datosBusqueda);
    console.log(codigoSiniestro); */

    if (codigoSiniestro) {
      setDatosBusqueda({
        identificacion: codigoSiniestro,
        opcionBusqueda: "codigo",
        idGrupo: grupoSiniestro,
      });
    }
  }, []);

  useEffect(() => {
    /*   console.log(datosBusqueda); */
    /* setMostrarBuscador(true); */
    if (datosBusqueda.opcionBusqueda === "identificacion") {
      buscarSiniestrosXIdentificacion(datosBusqueda.identificacion);
    } else if (datosBusqueda.opcionBusqueda === "codigo") {
      /* console.log("Entra en el segundo tipo de busquda siniestro");
      console.log(datosBusqueda.opcionBusqueda); */
      /* setMostrarDetalle(!mostrarDetalle); */
      buscarSiniestroXCodigo(datosBusqueda.identificacion);
    }
  }, [datosBusqueda]);

  const buscarSiniestrosXIdentificacion = (identificacionCliente) => {
    /*  console.log("Entra a consultar todos los siniestros del cliente");
    console.log(identificacionCliente); */

    consultaCliente(identificacionCliente)
      .then((respuesta) => {
        if (respuesta.state) {
          /*  setCliente(respuesta); */
          setIdCliente(respuesta.data.idCliente);
          setMostrarBuscador(false);
        } else {
          /* setCliente(nuevoCliente); */
          setIdCliente(nuevoIdCliente);
          setMostrarBuscador(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const buscarSiniestroXCodigo = (identificacionCodigo) => {
    consultarSiniestroXCodigo(identificacionCodigo)
      .then((respuesta) => {
        if (respuesta.state) {
          /*  console.log(respuesta); */
          setMostrarBuscador(false);
          setIdCliente(respuesta.data.idCliente);
          setIdSiniestro(respuesta.data.idSiniestro);
          setIdContrato(respuesta.data.idContrato);
          setIdFlujo(respuesta.data.idFlujo);
          /* implementandoguardar valiable en localStorage */
          setCodigoSiniestro(respuesta.data.numeroSiniestro);

          if (datosBusqueda.idGrupo === null) {
            setGrupoSiniestro(respuesta.data.idGrupo);
            setDatosBusqueda((args) => ({
              ...args,
              idGrupo: respuesta.data.idGrupo,
            }));
          } else {
            setGrupoSiniestro(datosBusqueda.idGrupo);
          }
        } else {
          setMostrarBuscador(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verificarBusquedaPorIdentificacion = () => {
    if (datosBusqueda.opcionBusqueda === "") {
      /*  console.log("entra dentro de la verificacion"); */
      return false;
    }
    if (datosBusqueda.opcionBusqueda === "identificacion") {
      return true;
    }
    return false;
  };

  const verificarBusquedaPorCodigoSiniestro = () => {
    if (datosBusqueda.opcionBusqueda === "") {
      /*   console.log("entra dentro de la verificacion"); */
      return false;
    }
    if (datosBusqueda.opcionBusqueda === "codigo") {
      return true;
    }
    return false;
  };

  if (mostrarBuscador) {
    return (
      <div className="gestion-siniestros-container">
        <BuscadorGestionSeguros setDatosBusqueda={setDatosBusqueda} />
      </div>
    );
  }
  return (
    <>
      {verificarBusquedaPorIdentificacion() && idCliente && (
        <ListaSiniestrosCliente
          idCliente={idCliente}
          setDatosBusqueda={setDatosBusqueda}
        />
      )}

      {verificarBusquedaPorCodigoSiniestro() &&
        idCliente &&
        idSiniestro &&
        idFlujo && (
          <DetalleCerrarSiniestro
            idCliente={idCliente}
            idSiniestro={idSiniestro}
            idContrato={idContrato}
            idGrupo={datosBusqueda.idGrupo}
          />
        )}
    </>
  );
};

export default SiniestroCerrarSiniestro;
