import React, { useEffect, useState } from "react";

import FormCancelacion from "app/modules/app-seguro/components/FormCancelacion";
import { consultarRequerimiento } from "../services/solicitudesService";
import BotonNotificacionCancelacionSeguro from "../components/BotonNotificacionCancelacionSeguro";
import CabeceraSeguro from "app/modules/app-seguro/containers/CabeceraSeguro";
import CabeceraSeguroService from "./CabeceraSeguroService";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const DetalleCancelacionSeguro = ({ solicitud }) => {
  const [informacionSolicitud, setInformacionSolicitud] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    console.log(solicitud);
    const getData = async () => {
      try {
        const respuesta = await consultarRequerimiento(
          solicitud.idRequerimiento
        );

        console.log(respuesta);

        if (respuesta.state) {
          setInformacionSolicitud(respuesta.data);
          setIsLoading(false);
        } else {
          setInformacionSolicitud({});
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        alert(`${error.response.data.message}`);
      }
    };
    getData();
  }, [solicitud]);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  return (
    <>
      {informacionSolicitud && (
        <CabeceraSeguroService
          idContrato={informacionSolicitud.datosRequerimientos[0].referencia}
        />
      )}

      {informacionSolicitud && !isSaved && (
        <>
          {/* <CabeceraSeguro seguro={seguro} /> */}

          <FormCancelacion
            idContrato={informacionSolicitud.datosRequerimientos[0].referencia}
            setIsSaved={setIsSaved}
          />
        </>
      )}
      {isSaved && (
        <div className="notificacion_cancelacion_seguro">
          <BotonNotificacionCancelacionSeguro
            solicitud={informacionSolicitud}
            informacionSolicitud={solicitud}
          />
        </div>
      )}
    </>
  );
};

export default DetalleCancelacionSeguro;

/* 
{
    informacionSolicitud && !isSaved && (
    <FormCancelacion
      idContrato={informacionContrato.datosRequerimientos[0].referencia}
      setIsSaved={setIsSaved}
    />)

} */

/* {isSaved && (
        <BotonNotificacionCancelacionSeguro solicitud={informacionContrato} />
      )}  */
