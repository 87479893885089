import React, { useEffect, useState } from "react";

import FormResolucion from "../components/FormResolucion";
import HeaderInformacionCliente from "../../shared/containers/HeaderInformacionCliente";
import HeaderInformacionProducto from "../components/HeaderInformacionProducto";
import BotonSalirLimpiarStorage from "./BotonSalirLimpiarStorage";
import HeaderInformacionSiniestro from "./HeaderInformacionSiniestro";

import { consultaSiniestroXIdSiniestro } from "../Services/siniestrosService";

const DetalleResolucionSiniestro = ({
  idCliente,
  idContrato,
  idSiniestro,
  idGrupo,
}) => {
  const [verificarEsActivoSiniestro, setVerificarEsActivoSiniestro] =
    useState(true);

  useEffect(() => {
    const verificarEsActivo = async () => {
      try {
        const respuestaSiniestroXId = await consultaSiniestroXIdSiniestro(
          idSiniestro
        );
        console.log(respuestaSiniestroXId);

        if (respuestaSiniestroXId.data.esActivo) {
          setVerificarEsActivoSiniestro(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    verificarEsActivo();
  }, []);

  if (verificarEsActivoSiniestro) {
    return (
      <>
        <BotonSalirLimpiarStorage />
        <h1>El siniestro se encuentra inactivo</h1>;
      </>
    );
  }

  return (
    <>
      <BotonSalirLimpiarStorage />

      <HeaderInformacionCliente idCliente={idCliente} />

      <HeaderInformacionProducto idSiniestro={idSiniestro} />

      <HeaderInformacionSiniestro
        idCliente={idCliente}
        idSiniestro={idSiniestro}
      />

      <FormResolucion idSiniestro={idSiniestro} idContrato={idContrato} />
    </>
  );
};

export default DetalleResolucionSiniestro;
