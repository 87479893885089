import React, { useEffect, useState } from "react";

import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";

import { formatoFecha } from "../../shared/validations/validaciones";

import { consultaSeguimiento } from "../Services/siniestrosService";
import { formatoFechaHora } from "app/modules/shared/commonValues/obtenerFecha";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/shared/mask/stylesTables";

const HistorialSeguimiento = ({ idSiniestro }) => {
  const [seguimientos, setSeguimientos] = useState([]);
  const [isUpdating, setIsUpdating] = useState(true);

  useEffect(() => {
    const getdata = async () => {
      try {
        const { state, data } = await consultaSeguimiento(idSiniestro);

        if (state) {
          /*  console.log(data); */
          setSeguimientos(data);
          setIsUpdating(false);
        } else {
          setIsUpdating(false);
          setSeguimientos([]);
        }
      } catch (error) {
        setIsUpdating(false);
        console.log(error);
      }
    };

    getdata();
  }, []);

  if (isUpdating) {
    return <SpinnerLoadSrx />;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Siniestro/Resolución</StyledTableCell>
              <StyledTableCell>Estado</StyledTableCell>
              <StyledTableCell>SubEstado</StyledTableCell>
              <StyledTableCell>Fecha</StyledTableCell>
              <StyledTableCell>Dias</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {seguimientos.map((seguimiento, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {seguimiento.esSiniestro}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {seguimiento.estado}
                </StyledTableCell>
                <StyledTableCell>{seguimiento.subEstado}</StyledTableCell>
                <StyledTableCell>
                  {formatoFechaHora(seguimiento.fechaEstado)}
                </StyledTableCell>
                <StyledTableCell>{seguimiento.dias}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default HistorialSeguimiento;
