import React, {memo,useState,useEffect} from 'react';
//import { Routes, Route, useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { DataGrid} from '@mui/x-data-grid';

import {
  listarSolicitudesCrediwebSinDoc
} from '../../services/creditoServices'


import "../../styles/main.css";
import FormSubirDocumentos from '../../components/FormSubirDocumentosCrediweb';


function DocumentosCrediwebContent() {

  const [catalogos, setCatalogos] = useState([]);
  
  
  const [idSolicitudActual, setIdSolicitudActual] = useState();
  const [solicitudes, setSolicitudes] = useState([]);
  const [flag, setFlag] = useState(false);
  
  const handleSave = async () => {
    
    handleClose();
    setFlag(!flag);
  };

  const history = useHistory();
  
  useEffect( () => {
    getSolicitudes();
  }, []);

  useEffect( () => {
    getSolicitudes();
  }, [flag]);

  const getSolicitudes =() => {
     
    listarSolicitudesCrediwebSinDoc()
    .then((response) => {
      if(response.state)
      {
        setSolicitudes(response.data);
      }
    })
    .catch((_errors) => {console.log(_errors); });
     
  };

  const renderDetailsButton = (params) => {
    return (
        <strong>
          <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() => {
                handleClickOpen(params.id);
              }}
          >
              Gestionar
          </Button>
      </strong>
    )
  };

  const formatearFecha = (fecha) => {
    if (!fecha) {
      return "";
    }
    const fechaModificada = fecha.split("T");
    return fechaModificada[0];
};


  const columns = [
    { field: 'idSolicitud',hide: true, headerName: 'ID', width: 80,  headerClassName : 'header-grid-solicitud',
  },
    {
      field: 'numeroSolicitud',
      headerName: 'Numero de Solicitud',
      headerClassName : 'header-grid-solicitud',
      width: 200,
      editable: false,
    },
    {
      field: 'identificacion',
      headerName: 'Identificación',
      headerClassName : 'header-grid-solicitud',
      width: 160,
      editable: false,
    },
    {
      field: 'codigoBarras',
      headerName: 'Codigo de Barras',
      headerClassName : 'header-grid-solicitud',
      width: 160,
      editable: false,
    },
    {
      field: 'fechaSolicitud',
      headerName: 'Fecha de Solicitud',
      headerClassName : 'header-grid-solicitud',
      width: 200,
      editable: false,
      valueFormatter: (params)=>{
        return formatearFecha(params.value);
      }
      
    },
    {
      field: 'usuarioCreacion',
      headerName: 'Usuario',
      headerClassName : 'header-grid-solicitud',
      width: 160,
      editable: false,
    },
    {
      field: 'revision',
      headerName: 'Revisión',
      headerClassName : 'header-grid-solicitud',
      width: 150,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
      
    },
    
  ];
  
  const rows = solicitudes;

    // Modal 
    // const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    //   "& .MuiDialogContent-root": {
    //     padding: theme.spacing(2),
    //   },
    //   "& .MuiDialogActions-root": {
    //     padding: theme.spacing(1),
    //   },
    // }));

    const BootstrapDialogTitle = (props) => {
      const { children, onClose, ...other } = props;
  
      return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
          {children}
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      );
    };
    BootstrapDialogTitle.propTypes = {
      children: PropTypes.node,
      onClose: PropTypes.func.isRequired,
    };
  
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = (idSolicitud) => {
      setIdSolicitudActual(idSolicitud);
      setOpen(true);
    };
    const handleClose = (event, reason) => {
      setOpen(false);
    };

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Box sx={{ height: 800, width: "100%" }}>
        <DataGrid
          getRowId={(row) => row.idSolicitud}
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
        />
      </Box>
      <div>
        <Dialog
          fullScreen={fullScreen}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          
        >
          <DialogContent dividers>
            <FormSubirDocumentos 
            idSol = {idSolicitudActual}
            handleSave={handleSave}/>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default memo(DocumentosCrediwebContent);
