import React from "react";

import { useHistory } from "react-router";

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import {
  Button,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  IconButton,
  DialogActions,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import {
  detalleTipoRequerimiento,
  idEstados,
  idSubTipoRequerimiento,
} from "app/modules/shared/dictionary/extensionDocumentos";
import { formatoFecha } from "app/modules/shared/validations/validaciones";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    /*  hight: 2000 */
    minWidth: 800,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    /* width: "50%", */
  },
  /* "& .MuiDialogActions-root": {
      with: 30,
    }, */
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalDetalleDesestimado = ({
  children,
  open,
  setOpen,
  titulo,
  mensaje,
  solicitudDesestimada,
  identificacion,
}) => {
  return (
    <div>
      <BootstrapDialog
        /* aria-describedby="alert-dialog-slide-description" */
        /* style={{ height: "30%" }} */

        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpen(false)}
        >
          {titulo}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <section className="detalle-desestimado">
            <h3>Identificación:</h3>
            <h3>{`${identificacion}`}</h3>
          </section>

          <section className="detalle-desestimado">
            <h3>Requerimiento:</h3>
            <h3>
              {`${detalleTipoRequerimiento(
                solicitudDesestimada.idTipoRequerimiento
              )} - ${
                solicitudDesestimada?.idSubTipoRequerimento
                  ? idSubTipoRequerimiento(
                      solicitudDesestimada?.idSubTipoRequerimento
                    )
                  : ""
              }`}
            </h3>
          </section>
          <section className="detalle-desestimado">
            <h3>Estado:</h3>
            <h3>{`${idEstados(solicitudDesestimada.estado)}`}</h3>
          </section>

          <section className="detalle-desestimado">
            <h3>Email:</h3>
            <h3>{solicitudDesestimada.email}</h3>
          </section>
          <section className="detalle-desestimado">
            <h3>Celular:</h3>
            <h3>{solicitudDesestimada.celular}</h3>
          </section>
          <section className="detalle-desestimado">
            <h3>Fecha Solicitud:</h3>
            <h3>{formatoFecha(solicitudDesestimada.fechaSolicitud)}</h3>
          </section>
          <section className="detalle-desestimado">
            <h3>Fecha Resolución:</h3>
            <h3>{formatoFecha(solicitudDesestimada.fechaResuelto)}</h3>
          </section>
        </DialogContent>

        {solicitudDesestimada?.observacionRequerimientos?.length > 0 && (
          <DialogContent dividers>
            <h3>SOLICITUD DESESTIMADA</h3>

            <section className="detalle-desestimado">
              <h3>Observación:</h3>
              <h3>
                {solicitudDesestimada.observacionRequerimientos[0].observacion}
              </h3>
            </section>
          </DialogContent>
        )}

        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ModalDetalleDesestimado;
