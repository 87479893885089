import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ModalGestionUsuarios from "../../containers/ModalGestionUsuarios";
import FormGestionPermisos from "../../components/FormGestionPermisos";
import ListaPermisosUsuario from "../../containers/ListaPermisosUsuario";
import { consultarListaUsuariosCobranza } from "../../services/cobranzaServices";

import DetalleGestionUsuarios from "../../containers/DetalleGestionUsuarios";
import BotonGenericoSalir from "app/modules/shared/containers/BotonGenericoSalir";
import DetalleGestionPermisos from "../../containers/DetalleGestionPermisos";

const GestionUsuariosCobranza = () => {
  const [gestionSolicitud, setGestionSolicitud] = useState({
    state: false,
    data: {},
  });
  const [clienteProductos, setClienteProductos] = useState([]);

  const [openAsignacion, setOpenAsignacion] = useState({
    state: false,
    data: {},
  });
  const [openPermisos, setOpenPermisos] = useState({ state: false, data: {} });

  useEffect(() => {
    const getSiniestros = async () => {
      try {
        const respuestaListaUsuariosCobranza =
          await consultarListaUsuariosCobranza();

        console.log(respuestaListaUsuariosCobranza);

        if (
          respuestaListaUsuariosCobranza.state &&
          respuestaListaUsuariosCobranza.data
        ) {
          const listaAux = respuestaListaUsuariosCobranza.data.map(
            (usuario) => ({
              ...usuario,
              nombreCompleto: `${usuario.nombres} ${usuario.apellidos}`,
            })
          );
          console.log(listaAux);
          setClienteProductos(listaAux);

          /*  setClienteInfo(cliente); */
        } else {
          setClienteProductos([]);
        }
      } catch (error) {
        console.log(error);
        alert(`${error.response.data.message}`);
      }
    };
    getSiniestros();
  }, []);

  const renderAsignacionButton = (params) => {
    return (
      <Button
        variant="contained"
        color="success"
        size="small"
        sx={{ minWidth: "40" }}
        onClick={() =>
          setOpenAsignacion((args) => ({
            ...args,
            state: true,
            data: params.row,
          }))
        }
      >
        Asignación
      </Button>
    );
  };

  const renderPermisosButton = (params) => {
    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() =>
          setOpenPermisos((args) => ({
            ...args,
            state: true,
            data: params.row,
          }))
        }
      >
        Permisos
      </Button>
    );
  };

  const columns = [
    {
      field: "idUsuario",
      hide: true,
      headerName: "ID",
      width: 80,
      headerClassName: "header-grid-solicitud",
    },
    {
      field: "identificacion",
      headerName: "Identificación",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
    },
    {
      field: "nombreCompleto",
      headerName: "Nombre",
      headerClassName: "header-grid-solicitud",
      width: 220,
      editable: false,
    },
    {
      field: "key",
      headerName: "Key",
      headerClassName: "header-grid-solicitud",
      width: 150,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "header-grid-solicitud",
      width: 190,
      editable: false,
    },
    {
      field: "sistema",
      headerName: "Sistema",
      headerClassName: "header-grid-solicitud",
      width: 80,
      editable: false,
    },
    {
      field: "rol",
      headerName: "Rol",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
      valueFormatter: (params) => {
        return `${params.value === "GESTORCBR" ? "COBRANZA" : "COBRANZA"}`;
      },
    },

    {
      field: "asignacion",
      headerName: "Asignación",
      headerClassName: "header-grid-solicitud",
      width: 120,
      renderCell: renderAsignacionButton,
      disableClickEventBubbling: false,
    },
    {
      field: "permisos",
      headerName: "Permisos",
      headerClassName: "header-grid-solicitud",
      width: 120,
      renderCell: renderPermisosButton,
      disableClickEventBubbling: false,
    },
  ];

  const rows = clienteProductos;

  return (
    <>
      <BotonGenericoSalir />
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          getRowId={(row) => row.idUsuario}
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>

      <ModalGestionUsuarios
        setOpen={setOpenAsignacion}
        open={openAsignacion}
        titulo="Asignación Máxima"
      >
        <DetalleGestionUsuarios usuario={openAsignacion.data} />
      </ModalGestionUsuarios>

      <ModalGestionUsuarios
        setOpen={setOpenPermisos}
        open={openPermisos}
        titulo="Registro de Permisos"
      >
        <div>
          <DetalleGestionPermisos data={openPermisos.data} />

          {/*  <FormGestionPermisos usuario={openPermisos.data} />
          <ListaPermisosUsuario usuario={openPermisos.data} /> */}
        </div>
      </ModalGestionUsuarios>
    </>
  );
};

export default GestionUsuariosCobranza;
