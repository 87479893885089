import React, { Fragment } from "react";
import { touch } from "redux-form";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
// import DateControl from "../Date/DateControl";
// import HourControl from "../Hour/HourControl";
import Radio from "@mui/material/Radio";
import { FieldRenderProps } from "react-final-form";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";

import Clear from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

const useStyles = makeStyles((theme) => ({
  label: {
    "&$erroredLabel": {
      color: "#fec03e",
    },
    "& label.Mui-disabled": {
      color: "#2196F3",
    },
  },
  underline: {
    "&$error:after": {
      borderBottomColor: "#00875A",
    },
  },
  error: {},
  selectEmpty: {
    /*  marginTop: theme.spacing(2), */
  },
  errorText: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#E00930",
      marginTop: "35px",
      position: "absolute",
    },
  },
}));

const RenderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <TextField
      inputlabelprops={{
        classes: {
          root: {
            "&$erroredLabel": {
              color: "#fec03e",
            },
          },
          focused: {},
          error: {},
        },
      }}
      inputprops={{
        classes: {
          root: {
            "&$error:after": {
              borderBottomColor: "#00875A",
            },
          },
          error: {},
        },
      }}
      fullWidth
      variant="outlined"
      style={{ margin: 8 }}
      // error={error ? true : false}
      error={touched ? (error ? true : false) : false}
      helperText={error}
      label={label}
      {...input}
      {...custom}
    />
  );
};

const RenderSelectField = ({
  input,
  label,
  disabled,
  meta: { error, touched },
  children,
  isClearable,
  ...custom
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <FormControl fullWidth variant="outlined" style={{ margin: 8 }}>
        {/* <InputLabel id="demo-simple-select-outlined-label" style={{color: error ? "#6c757d" : "" }}>{label}</InputLabel> */}
        <InputLabel
          id="demo-simple-select-outlined-label"
          style={{ color: error ? "#6c757d" : "#000000" }}
        >
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          onChange={(event, index, value) => {
            input.onChange(value);
          }}
          label={label}
          children={children}
          error={touched ? (error ? true : false) : false}
          inputlabelprops={{
            classes: {
              root: classes.label,
              focused: {},
              error: {},
            },
          }}
          disabled={disabled}
          {...input}
          {...custom}
        ></Select>
        <FormHelperText style={{ color: error ? "#6c757d" : "grey" }}>
          {error}
        </FormHelperText>
      </FormControl>
    </Fragment>
  );
};

const RenderMultiSelectField = ({
  input: { name, value, onChange, ...restInput },
  meta: { error, touched },
  label,
  formControlProps,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <FormControl fullWidth style={{ marginLeft: 8 }}>
        <InputLabel
          style={{ marginTop: 8, color: error ? "#6c757d" : "" }}
          htmlFor="name-native-disabled"
          shrink={value ? true : false}
        >
          {label}
        </InputLabel>
        <Select
          error={touched ? (error ? true : false) : false}
          style={{ marginTop: 25 }}
          inputlabelprops={{
            classes: {
              root: classes.label,
              focused: {},
              error: {},
            },
          }}
          inputprops={{
            classes: {
              root: classes.underline,
              error: classes.error,
            },
          }}
          {...rest}
          multiple
          name={name}
          value={
            value
              ? typeof value === "string" || value instanceof String
                ? JSON.parse(value)
                : Array.isArray(value)
                ? value
                : []
              : []
          }
          onChange={onChange}
          inputprops={restInput}
        />
        <FormHelperText style={{ color: error ? "#6c757d" : "grey" }}>
          {error}
        </FormHelperText>
      </FormControl>
    </Fragment>
  );
};

// // const renderDateField = ({
// //   input,
// //   label,
// //   meta: { error, touched },
// //   children,
// //   ...custom
// // }) => {
// //   const classes = useStyles();
// //   return (
// //     <Fragment>
// //       <DateControl
// //         floatingLabelText={label}
// //         hintText={label}
// //         value={typeof input.value === "object" ? input.value : null}
// //         onChange={(date) => input.onChange(date)}
// //         error={touched ? (error ? true : false) : false}
// //         {...custom}
// //       />
// //       <FormHelperText
// //         style={{ color: error ? "#f44336" : "grey", marginLeft: "10px" }}
// //       >
// //         {error}
// //       </FormHelperText>
// //     </Fragment>
// //   );
// // };

// // const renderHourField = ({
// //   input,
// //   label,
// //   meta: { error, touched },
// //   children,
// //   ...custom
// // }) => {
// //   return (
// //     <Fragment>
// //       <HourControl
// //         floatingLabelText={label}
// //         hintText={label}
// //         value={typeof input.value === "object" ? input.value : null}
// //         width={"150px"}
// //         onChange={(date) => input.onChange(date)}
// //         error={touched ? (error ? true : false) : false}
// //         {...custom}
// //       />
// //       <FormHelperText
// //         style={{ color: error ? "#f44336" : "grey", marginLeft: "10px" }}
// //       >
// //         {error}
// //       </FormHelperText>
// //     </Fragment>
// //   );
// // };

const RenderRadioButtons = ({ input, options, meta: { error } }) => {
  return (
    <Fragment>
      {options.map((option) => (
        <div key={option.code} className="radio-input">
          <Radio
            color={"primary"}
            id={option.code}
            type="radio"
            {...input}
            value={option.code}
            checked={option.code === input.value}
          />
          <label htmlFor={option.code}>{option.description}</label>
        </div>
      ))}

      <FormHelperText
        style={{ color: error ? "#6c757d" : "grey", marginLeft: "10px" }}
      >
        {error}
      </FormHelperText>
    </Fragment>
  );
};

const RenderNumberField = ({
  input,
  label,
  numDecimals,
  meta: { touched, error },
  ...custom
}) => {
  const classes = useStyles();
  return (
    <TextField
      inputlabelprops={{
        classes: {
          root: classes.label,
          focused: {},
          error: {},
        },
      }}
      inputprops={{
        classes: {
          root: classes.underline,
          error: classes.error,
        },
      }}
      fullWidth
      variant="outlined"
      style={{ margin: 8 }}
      error={touched ? (error ? true : false) : false}
      helperText={error}
      label={label}
      {...input}
      {...custom}
      onBlur={() => {
        input.onBlur(
          typeof input.value === "number"
            ? parseFloat(input.value).toFixed(numDecimals)
            : 0
        );
      }}
    />
  );
};

// const RenderRadioButton: React.SFC<FieldRenderProps> = ({
//   input: { checked, value, name, onChange, ...restInput },
//   meta,
//   ...rest
// }) => (
//   <Radio
//     {...rest}
//     color={'primary'}
//     name={name}
//     inputprops={restInput}
//     onChange={onChange}
//     checked={checked}
//     value={value}
//   />
// );

const RenderSwitch = ({ input, label, disabled, color }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          value={"true"}
          color={"primary"}
          checked={input.value ? true : false}
          onChange={(e) => input.onChange(e.target.checked)}
          disabled={disabled}
        />
      }
      label={label}
      style={{ marginTop: 10 }}
    />
  );
};
export {
  RenderSwitch,
  RenderTextField,
  RenderSelectField,
  RenderMultiSelectField,
  //renderDateField,
  RenderRadioButtons,
  //RenderRadioButton,
  RenderNumberField,
  //renderHourField,
};
