import React from "react";
import { Form, Formik } from "formik";

import { IconButton, InputAdornment, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const FormInputMensajeSide = ({ enviarMensajeHub }) => {
  const handleSubmitTest = (values, { resetForm }) => {
    enviarMensajeHub(values.mensaje);
    resetForm({ values: "" });
  };

  return (
    <div className="formularioSide-input-mensajes">
      <Formik initialValues={{ mensaje: "" }} onSubmit={handleSubmitTest}>
        {({ values, handleChange, handleSubmit }) => (
          <Form>
            <section className="formulario-input-mensajes__container">
              <TextField
                onSubmit={handleSubmit}
                sx={{ minWidth: "95%", fontSize: "11px" }}
                id="mensaje"
                placeholder="Escribe un mensaje"
                name="mensaje"
                type="text"
                value={values.mensaje}
                onChange={handleChange}
                size="small"
                variant="standard"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />
            </section>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormInputMensajeSide;
