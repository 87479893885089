import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  FormHelperText,
  InputLabel,
  Select,
} from "@mui/material";

import handleDownlPdf from "../../../utils/document/documentosService";
import { formatoFecha } from "../../shared/validations/validaciones";

import {
  obtenerFechaActualFormulario,
  obtenerFechaHoraActual,
} from "app/modules/shared/commonValues/obtenerFecha";

import { consultaCatalogosSinPadre } from "../../app-cliente/services/Catalogos/catalogosService";

import {
  consultarResolucion,
  consultaCabeceraProducto,
  consultarProductoFlujo,
  consultarEstadosResolucion,
  consultarSubestadosResolucion,
  consultarDocumentoXServicio,
  crearResolucion,
  crearSeguimiento,
  consultarMontoAPagar,
  consultarDetalleFlujo,
  consultarCabeceraSiniestro,
  notificarSiniestroCliente,
} from "../Services/siniestrosService";

import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

const FormResolucion = ({ idSiniestro }) => {
  const [clienteInfo, setClienteInfo] = useState(null);
  const [idProductoAux, setIdProductoAux] = useState(-1);
  const [idFlujoAux, setIdFlujoAux] = useState(-1);
  const [esReapertura, setEsReapertura] = useState(false);
  const [montoAPagar, setMontoAPagar] = useState(0);
  const [diasDemoraFlujo, setDiasDemoraFlujo] = useState({});
  const [informacionSinistro, setInformacionSinistro] = useState({});

  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [habilitarDescarga, setHabilitarDescarga] = useState(false);

  const [loading, setLoading] = useState(false);
  const [idResolucionAux, setIdResolucionAux] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const { state, data } = await consultarResolucion(idSiniestro);

        if (state) {
          setClienteInfo(data);
          setIdResolucionAux(data.idResolucion);
        } else {
          setClienteInfo({});
        }

        let respuestaInformacionBienXContrato;

        respuestaInformacionBienXContrato = await consultaCabeceraProducto(
          idSiniestro
        );

        console.log({ respuestaInformacionBienXContrato });

        if (respuestaInformacionBienXContrato.state) {
          /*  console.log(respuestaInformacionBienXContrato); */
          setIdProductoAux(respuestaInformacionBienXContrato.data.idProducto);
        } else {
          setIdProductoAux(1);
        }

        const coberturasOpciones = await consultarProductoFlujo(
          respuestaInformacionBienXContrato.data.idProducto
        );

        /* console.log(coberturasOpciones); */
        if (coberturasOpciones.state) {
          setIdFlujoAux(coberturasOpciones.data[0].idFlujo);
        } else {
          setIdFlujoAux(1);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const { state, data } = await consultarMontoAPagar(idSiniestro);

        if (state) {
          /* console.log(data); */
          setMontoAPagar(data.montoIndemnizar);
        } else {
          setMontoAPagar(0);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const { state, data } = await consultarDetalleFlujo(idFlujoAux);

        if (state) {
          setDiasDemoraFlujo(data);
        } else {
          setDiasDemoraFlujo({});
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [idFlujoAux]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { state, data } = await consultarCabeceraSiniestro(idSiniestro);

        if (state) {
          setInformacionSinistro(data);
        } else {
          setInformacionSinistro({});
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsCobertura = ({ name, labelName, componentSize }) => {
    const [coberturas, setCoberturas] = useState([]);
    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultarProductoFlujo(idProductoAux);

          if (state) {
            setCoberturas(data);
          } else {
            setCoberturas([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, []);

    return (
      <FormControl sx={{ minWidth: `${componentSize}` }}>
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {coberturas.map((cobertura, index) => (
            <MenuItem key={index} value={cobertura.codigoDetalle}>
              {cobertura.descripcion}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    );
  };

  const SelectItemsEstadoResolucion = ({
    name,
    labelName,
    idEstado,
    componentSize,
  }) => {
    const [subestados, setSubestados] = useState([]);

    useEffect(() => {
      /*  console.log(idEstado); */
      const getData = async () => {
        try {
          const { state, data } = await consultarEstadosResolucion(5);

          if (state) {
            /* console.log(data); */
            setSubestados(data);
          } else {
            setSubestados([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, [idEstado]);

    return (
      <FormControl sx={{ width: `${componentSize}` }}>
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {subestados.map((subestado, index) => (
            <MenuItem key={index} value={subestado.idEstado}>
              {subestado.nombreEstado}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    );
  };

  const SelectItemsSubestadoResolucion = ({
    name,
    labelName,
    idEstado,
    componentSize,
  }) => {
    const [subestados, setSubestados] = useState([]);

    useEffect(() => {
      /*   console.log(idEstado); */
      const getData = async () => {
        try {
          const { state, data } = await consultarSubestadosResolucion(
            idEstado,
            idFlujoAux
          );

          if (state) {
            /*  console.log(data); */
            setSubestados(data);
          } else {
            setSubestados([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, [idEstado]);

    return (
      <FormControl sx={{ width: `${componentSize}` }}>
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {subestados.map((subestado, index) => (
            <MenuItem key={index} value={subestado.idEstado}>
              {subestado.nombreEstado}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    );
  };

  const generarCartaNotificacion = async () => {
    try {
      const respuestaCartaNotificacion = await consultarDocumentoXServicio(
        "ActaFiniquito",
        idSiniestro
      );
      if (respuestaCartaNotificacion.state) {
        handleDownlPdf(respuestaCartaNotificacion.data, "ActaFiniquito");
      } else {
        alert(respuestaCartaNotificacion.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validarFechaMayorMenor = (fechaInicio, fechaFin, parametro) => {
    const formatoFechaComprobar = new Date(fechaInicio);
    const formatoFechaActual = new Date(fechaFin);

    if (parametro === "MENOR") {
      if (formatoFechaComprobar <= formatoFechaActual) {
        return true;
      }

      return false;
    }
    return false;
  };

  const validarEsReapertura = (esReapertura) => {
    if (esReapertura) {
      setEsReapertura(true);
      return true;
    } else {
      setEsReapertura(false);
      return false;
    }
  };

  const validarDemora = () => {
    /*  console.log({ diasDemoraFlujo });
    console.log({ informacionSinistro }); */

    /* validacion => comprueba que la fecha de notificacion vs la fecha actual sea menor igual a los dias de demora */
    const fechaActual = new Date();
    const fechaNotificacion = new Date(informacionSinistro.fechaNotificacion);

    const calculoFechaActualFechaNotificacion = Math.abs(
      fechaActual - fechaNotificacion
    );
    /* console.log({ calculoFechaActualFechaNotificacion }); */
    const diasEntreFechaActualFechaNotificacion =
      calculoFechaActualFechaNotificacion / (1000 * 3600 * 24);
    /*  console.log({ diasEntreFechaActualFechaNotificacion }); */
    if (diasEntreFechaActualFechaNotificacion >= 6) {
      /*  if (diasDemoraFlujo.diasDemora < diasEntreFechaActualFechaNotificacion) { */
      return true;
    }
    return false;
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const valoresAux = {
      ...valores,
      fechaReapertura: obtenerFechaHoraActual(),
    };

    if (!esReapertura) {
      valoresAux.fechaReapertura = "";
    }

    if (esReapertura) {
      valoresAux.montoPagar = valores.montoReapertura;
    }

    const informacionResolucion = {
      idSiniestro: Number(idSiniestro),
      idResolucion: idResolucionAux,
      fechaResolucion: valoresAux.fechaResolucion,
      coberturaResolucion: valoresAux.coberturaResolucion,
      montoPagar: Number(valoresAux.montoPagar),

      idEstado: Number(valoresAux.idEstado),
      idSubEstado: Number(valoresAux.idSubEstado),

      observacion: valoresAux.observacion.toUpperCase(),
      responsableResolucion: valoresAux.responsableResolucion.toUpperCase(),
      esReapertura: valoresAux.esReapertura,

      fechaReapertura: valoresAux.fechaReapertura,

      usuarioReapertura: valoresAux.usuarioReapertura,
      responsableDemora: valoresAux.responsableDemora,
      motivoDemora: valoresAux.motivoDemora,
      esActivo: true,
    };

    console.log(informacionResolucion);

    try {
      const respuestaResolucion = await crearResolucion(informacionResolucion);
      console.log(respuestaResolucion);

      if (respuestaResolucion.state) {
        setDatosEnviadosCorrectamente(true);
        setHabilitarDescarga(true);
        setIdResolucionAux(respuestaResolucion.data);

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
        }, 100);
      } else {
        alert(respuestaResolucion.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }

    const fecha = new Date();

    const informacionSeguimientoNotificacion = {
      idSeguimiento: 0,
      idSiniestro: Number(idSiniestro),
      esSiniestro: false,
      idEstado: Number(valores.idEstado),
      idSubestado: Number(valores.idSubEstado),
      fechaEstado: fecha.toISOString().split(".")[0],
      esActivo: true,
    };
    /*  console.log(informacionSeguimientoNotificacion); */

    try {
      const respuestaSeguimiento = await crearSeguimiento(
        informacionSeguimientoNotificacion
      );

      /* console.log(respuestaSeguimiento); */
    } catch (error) {
      console.log(error);
    }

    try {
      const respuestaEnvioNotificar = await notificarSiniestroCliente(
        idSiniestro
      );

      if (respuestaEnvioNotificar.state) {
        console.log(respuestaEnvioNotificar);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {clienteInfo && montoAPagar >= 0 && (
        <>
          <Formik
            initialValues={{
              coberturaResolucion: clienteInfo.coberturaResolucion || "",
              idEstado: clienteInfo.idEstado || "",
              idSubEstado: clienteInfo.idSubEstado || "",
              montoPagar: clienteInfo.montoPagar ?? montoAPagar ?? "",
              fechaResolucion: clienteInfo.fechaResolucion || "",
              observacion: clienteInfo.observacion || "",
              responsableResolucion: clienteInfo.responsableResolucion || "",
              fechaReapertura: clienteInfo.fechaReapertura || "",
              responsableDemora: clienteInfo.responsableDemora || "",
              usuarioReapertura: clienteInfo.usuarioReapertura || "",
              motivoDemora: clienteInfo.motivoDemora || "",
              esReapertura: clienteInfo.esReapertura || false,
              montoReapertura: montoAPagar,
            }}
            validationSchema={Yup.object({
              fechaResolucion: Yup.string().required(
                "Fecha Resolución requerida"
              ),
            })}
            validate={(values) => {
              const errors = {};

              const fechaActual = new Date();
              if (
                !validarFechaMayorMenor(
                  values.fechaResolucion,
                  fechaActual,
                  "MENOR"
                )
              ) {
                errors.fechaResolucion = "La fecha es mayor a la fecha actual";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              configuracionDatosEnvio(values);
            }}
          >
            {({ values, handleChange, touched, errors, handleBlur }) => (
              <Form>
                <SCSAccordion tituloCabecera="Resolución">
                  <Grid container spacing={1} rowSpacing={1}>
                    <Grid item md={12}>
                      <Grid container spacing={1} rowSpacing={1}>
                        <Grid item md={12}>
                          <div className="cliente-informacion-seccion">
                            <SelectItemsCobertura
                              name="coberturaResolucion"
                              labelName="Cobertura Resoución"
                              componentSize="23%"
                            />

                            <SelectItemsEstadoResolucion
                              name="idEstado"
                              labelName="Estado"
                              idEstado={7}
                              componentSize="16%"
                            />

                            <SelectItemsSubestadoResolucion
                              name="idSubEstado"
                              labelName="Acción"
                              idEstado={values.idEstado}
                              componentSize="23%"
                            />

                            <SelectItemsCatalogoSinPadre
                              pais="ECU"
                              especial="false"
                              catalogos="RESPRESOL"
                              grupo={1}
                              name="responsableResolucion"
                              labelName="Responsable Resolución"
                              componentSize="15%"
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />

                            <TextField
                              id="fechaResolucion"
                              label="Fecha Resolución"
                              type="date"
                              size="small"
                              sx={{ width: "14%" }}
                              onChange={handleChange}
                              value={formatoFecha(values.fechaResolucion)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={
                                touched.fechaResolucion &&
                                Boolean(errors.fechaResolucion)
                              }
                              helperText={
                                touched.fechaResolucion &&
                                errors.fechaResolucion
                              }
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="cliente-informacion-seccion">
                            <TextField
                              id="observacion"
                              name="observacion"
                              type="text"
                              label="Observación"
                              sx={{ width: "60%" }}
                              value={values.observacion}
                              onChange={handleChange}
                              size="small"
                              inputProps={{
                                style: {
                                  textTransform: "uppercase",
                                },
                              }}
                            />

                            <TextField
                              id="montoPagar"
                              name="montoPagar"
                              label="Monto a Pagar"
                              sx={{ width: "15%" }}
                              value={values.montoPagar}
                              onChange={handleChange}
                              size="small"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              error={
                                touched.montoPagar && Boolean(errors.montoPagar)
                              }
                              helperText={
                                touched.montoPagar && errors.montoPagar
                              }
                              onBlur={handleBlur}
                            />

                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.esReapertura}
                                    onChange={handleChange}
                                    name="esReapertura"
                                  />
                                }
                                label="Reapertura"
                              />
                            </FormGroup>
                          </div>

                          {validarEsReapertura(values.esReapertura) && (
                            <>
                              <div className="cliente-informacion-seccion">
                                <h3>Reapertura</h3>
                                <TextField
                                  disabled
                                  id="fechaReapertura"
                                  label="Fecha Reapertura"
                                  type="date"
                                  size="small"
                                  sx={{ width: "23%" }}
                                  onChange={handleChange}
                                  value={obtenerFechaActualFormulario()}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />

                                <TextField
                                  id="montoReapertura"
                                  name="montoReapertura"
                                  label="Monto Reapertura"
                                  sx={{ width: "23%" }}
                                  value={values.montoReapertura}
                                  onChange={handleChange}
                                  size="small"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                />
                              </div>
                            </>
                          )}
                          {validarDemora() && (
                            <>
                              <div className="cliente-informacion-seccion">
                                <h3>Demora</h3>
                                <SelectItemsCatalogoSinPadre
                                  pais="ECU"
                                  especial="false"
                                  catalogos="RESPDEM"
                                  grupo={1}
                                  name="responsableDemora"
                                  labelName="Responsable Demora"
                                  componentSize="23%"
                                  handleBlur={handleBlur}
                                  touched={touched}
                                  errors={errors}
                                />

                                <SelectItemsCatalogoSinPadre
                                  pais="ECU"
                                  especial="false"
                                  catalogos="MOTDEM"
                                  grupo={1}
                                  name="motivoDemora"
                                  labelName="Motivo Demora"
                                  componentSize="23%"
                                  handleBlur={handleBlur}
                                  touched={touched}
                                  errors={errors}
                                />
                              </div>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <div className="confirmar-estados-servicios">
                        <SCSLoadingButton loading={loading} titulo="GUARDAR" />

                        {datosEnviadosCorrectamente && (
                          <AcceptServiceresponse />
                        )}

                        {habilitarDescarga && (
                          <Button
                            type="button"
                            variant="contained"
                            sx={{ width: "25ch" }}
                            onClick={generarCartaNotificacion}
                          >
                            Generar Orden Finiquito
                          </Button>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </SCSAccordion>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default FormResolucion;
