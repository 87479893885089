import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
import { auth } from "@jumbo/services/auth/firebase/firebase";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_AVATARS, ASSET_IMAGES } from "../../../utils/constants/paths";
import { useDispatch } from "react-redux";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../../../redux/actions/Common";
import { useOnLoginRegistro } from "../hook/index";

import { validarDocumentosCF } from "../../app-cliente/services/clienteService";
import { consultarGrupoGenerico } from "app/modules/app-configuracion/services/configuracionServices";
import { obtenerRegistroCliente } from "../services";
import HouseIcon from "@mui/icons-material/House";
import RefreshIcon from "@mui/icons-material/Refresh";

const validationSchema = yup.object({
  password: yup.string("Ingrese Cédula").required("Cédula es Obligatorio"),
});

const ValidarCedula = () => {
  const dispatch = useDispatch();

  //const origen = localStorage.getItem("origen");
  const [origen, setOrigen] = useState(localStorage.getItem("origen"));

  const history = useHistory();
  const { setActiveLayout } = useJumboApp();

  const [send, setSend] = useState(false);
  useOnLoginRegistro(send, setSend);

  const [color, setColor] = useState("");
  const [url, setUrl] = useState("");
  const [esperaLogin, setesperaLogin] = useState(true);
  const [loggedUser, setLoggedUser] = useState(false);
  const [mostarRefresh, setMostrarRefresh] = useState(false);
  const [refresh, setRefresh] = useState(false);

  React.useEffect(() => {
    //setSend(true);
    localStorage.setItem("tipocliente", "NUEVO");
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    setTimeout(() => {
      setSend(true);
      //obtenerGrupo();
    }, 100);

    setTimeout(() => {
      //console.log("paso 2");
      //setSend(true)
      obtenerGrupo();
    }, 3000);

    setTimeout(() => {
      loggedUser ? setMostrarRefresh(true) : setMostrarRefresh(true);
    }, 8000);

    switch (origen) {
      case "tvtas":
        setColor("#FF9201");
        setUrl("https://www.tventas.com/content/55-creditventas");
        break;
      case "corpfav":
        setColor("#e1251b");
        setUrl("https://www.creditofavorito.com/");
        break;
      default:
        setColor("#e1251b");
        setUrl("https://www.creditofavorito.com/");
        break;
    }
    localStorage.setItem("tipocliente", "NUEVO");

    const obtenerGrupo = async () => {
      const resp = await consultarGrupoGenerico(origen, 1);
      if (resp.state) {
        localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
        setLoggedUser(true);
      } else {
        console.log(resp.message);
      }
    };

    return () => {
      setSend(false);
    };
  }, [refresh]);

  const obtenerGrupo2 = async () => {
    const resp = await consultarGrupoGenerico(origen, 1);
    if (resp.state) {
      localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
    } else {
      console.log(resp.message);
    }
  };

  const handleCloseEspera = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      //console.log(reason);
      setesperaLogin(true);
    } else {
      setesperaLogin(false);
    }
  };

  const mutation = useAuthSignInWithEmailAndPassword(auth, {
    onError(error) {
      console.log(error);
    },
    onSuccess(data) {
      history.push("/");
    },
  });

  const onSignIn = async (password) => {
    //setSend(true);
    //await obtenerGrupo2();
    try {
      localStorage.setItem("cedula", password);
      let response = await obtenerRegistroCliente(
        password,
        localStorage.getItem("idGrupoStorage")
      );
      //debugger;
      if (response.state) {
        alert("Ya te encuentras registrado, por favor inicia sesión.");
        history.push("/signincli");
        //debugger;
      } else {
        //si no registrado envía welcome1
        history.push("/auth-pages/welcome");
      }
      // } else {
      //   //alert(response.message);
      //   dispatch(fetchError(response.message));
      // }
    } catch (e) {
      history.push("/auth-pages/welcome");
    }
  };
  const handleRefresh = async () => {
    setSend(false);
    setMostrarRefresh(false);
    setRefresh(!refresh);
  };

  return (
    <>
      {!loggedUser ? (
        <Dialog
          onClose={handleCloseEspera}
          aria-labelledby="customized-dialog-title"
          open={esperaLogin}
          fullWidth
        >
          <DialogContent dividers>
            {!mostarRefresh ? (
              <Stack direction={"column"} spacing={5}>
                <Box marginTop={5}>
                  <LinearProgress color="inherit" />
                </Box>
                <Box margin={5}>
                  <Typography align="center">
                    Un momento estamos preparanto todo.
                  </Typography>
                </Box>
                <Box margin={5}></Box>
              </Stack>
            ) : (
              <>
                <Box margin={5}>
                  <Stack direction={"column"} spacing={5} justifyContent={"center"} alignItems={"center"}>
                    <RefreshIcon fontSize="large"/>
                    <Button
                      size="lg"
                      variant="outlined"
                      //startIcon={<RefreshIcon />}
                      onClick={handleRefresh}
                    >
                      <Typography>
                        {" "}
                        Hubo un inconveniente, intenta de nuevo.
                      </Typography>
                    </Button>
                  </Stack>
                </Box>
              </>
            )}
          </DialogContent>
        </Dialog>
      ) : (
        <Div
          sx={{
            flex: 1,
            marginTop: 20,
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: (theme) => theme.spacing(4),
          }}
        >
          <Box
            component="img"
            src={`${process.env.REACT_APP_IMAGES_PATH}/logo-${origen}.png`}
            sx={{
              justifyContent: "center",
              width: 350,
              height: 140,
            }}
          />
          <Card sx={{ maxWidth: "100%", width: 360, mb: 4 }}>
            <Div sx={{ position: "relative", height: "200px" }}>
              <CardMedia
                component="img"
                height="200"
                sx={{ backgroundColor: color }}
              />
              <Div
                sx={{
                  flex: 1,
                  inset: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  p: (theme) => theme.spacing(3),
                }}
              >
                <Stack direction={"column"}>
                  <Typography
                    variant={"h2"}
                    sx={{
                      color: "common.white",
                      fontSize: "2rem",
                      mb: 0,
                    }}
                  >
                    ¡Bienvenido! <br />
                    <br />
                  </Typography>
                  <Typography
                    variant={"h4"}
                    sx={{
                      color: "common.white",
                      fontSize: "1.5 rem",
                      mb: 0,
                    }}
                  >
                    Registrate para aplicar a tu Crédito Directo.
                  </Typography>
                </Stack>
              </Div>
            </Div>
            <CardContent sx={{ pt: 0 }}>
              <Formik
                validateOnChange={true}
                initialValues={{
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting }) => {
                  setSubmitting(true);
                  onSignIn(data.password);
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    {/* <Avatar
                  sx={{
                    width: 56,
                    height: 56,
                    marginLeft: "auto",
                    boxShadow: shadows[3],
                    transform: "translateY(-50%)",
                  }}
                /> */}
                    <Typography
                      textAlign={"left"}
                      variant={"h3"}
                      mb={1}
                      marginTop={5}
                    >
                      Ingresa tus datos como consta en tu cédula de identidad
                      para agilizar el proceso de tu Crédito Directo.
                      <br />
                      <br />
                    </Typography>
                    {mutation.isError && <p>{mutation.error.message}</p>}
                    <Div sx={{ mb: 2, mt: 1 }}>
                      <JumboTextField
                        fullWidth
                        name="password"
                        label="Ingresa tu Cédula"
                        //type="password"
                      />
                    </Div>

                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                      loading={isSubmitting || mutation.isLoading}
                    >
                      CONTINUAR
                    </LoadingButton>
                  </Form>
                )}
              </Formik>
            </CardContent>
          </Card>
          <Button
            size="lg"
            variant="outlined"
            startIcon={<HouseIcon />}
            onClick={() => {
              window.open(url, "_self");
            }}
          >
            <Typography> Regresar a la pagina Principal</Typography>
          </Button>
        </Div>
      )}
    </>
  );
};

export default ValidarCedula;
