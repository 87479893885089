import React, { useEffect, useState } from "react";
import { useFormikContext, Formik, Form, Field, useField } from "formik";

import { Grid, Card, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  obtenerInfoSolAprobada,
  obtenerPagosCliente,
} from "../services/creditoServices";

import "../styles/main.css";
import { DataGrid } from "@mui/x-data-grid";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";

const ComportamientoPago = ({ idCliente, cliente, cupoPreap, solic }) => {
  const [solicitud, setSolicitud] = useState(solic);

  const [infoPagos, setInfoPagos] = useState([]);
  const [infoSolicitudAprobada, setInfoSolicitudAprobada] = useState([]);

  useEffect(() => {
    console.log(solicitud);

    const getDatos = async () => {
      let infoSolicitud;
      try {
        const respPagos = await obtenerPagosCliente(solicitud.idCliente);
        if (respPagos.state) {
          if (respPagos.data == null) infoSolicitud = {};
          else {
            setInfoPagos(respPagos.data);
          }
        } else {
          console.log(respPagos.message);
        }
      } catch (_errors) {
        console.log(_errors);
      }

      try {
        const infoAprobacion = await obtenerInfoSolAprobada(
          solicitud.idSolicitud
        );
        if (infoAprobacion.state) {
          if (infoAprobacion.data == null) infoSolicitud = {};
          else {
            infoSolicitud = infoAprobacion.data;
          }
        } else {
          console.log(infoAprobacion.message);
        }
      } catch (_errors) {
        console.log(_errors);
      }

      const res = {
        ...infoSolicitud,
      };

      setInfoSolicitudAprobada(res);
    };
    getDatos();
  }, []);

  const formatearFecha = (fecha) => {
    if (!fecha) {
      return "";
    }
    const fechaModificada = fecha.split("T");
    return fechaModificada[0];
  };

  // Tabla Pagos

  const columns = [
    {
      field: "numeroTransaccion",
      //hide: true,
      headerName: "Numero de Transaccion",
      width: 200,
      headerClassName: "header-grid-solicitud",
    },
    {
      field: "nombreLocal",
      headerName: "Nombre Local",
      headerClassName: "header-grid-solicitud",
      width: 200,
      editable: false,
    },
    {
      field: "valor",
      headerName: "Valor",
      headerClassName: "header-grid-solicitud",
      width: 160,
      editable: false,
    },
    {
      field: "fecha",
      headerName: "Fecha Pago",
      headerClassName: "header-grid-solicitud",
      width: 200,
      editable: false,
    },
  ];

  const rows = infoPagos;

  return (
    <>
      <SCSAccordion tituloCabecera="Comportamiento de Pago">
        <div className="accordion-container">
          <Grid container justifyContent="center" rowSpacing={3}>
            <Grid item lg={12}>
              <Card style={{ justifyContent: "center" }}>
                <header className="header-client-information">
                  <div>
                    <h4>Fecha Corte</h4>
                    <h4>
                      {infoSolicitudAprobada.fechaCorte == 1
                        ? "15"
                        : "30" || "Sin informacion"}
                    </h4>
                  </div>
                  <div>
                    <h4>Fecha Aprobacion Solicitud</h4>
                    <h4>
                      {formatearFecha(infoSolicitudAprobada.fechaAprobacion) ||
                        "Sin informacion"}
                    </h4>
                  </div>
                  <div>
                    <h4>Monto Solicitado Aumento de monto</h4>
                    <h4>${infoSolicitudAprobada.montoSolicitado}</h4>
                  </div>
                  <div>
                    <h4>Monto Aprobado solicitud</h4>
                    <h4>${infoSolicitudAprobada.montoAprobadoOri}</h4>
                  </div>
                </header>
              </Card>
            </Grid>
            <Grid item lg={12}>
              <h2>Ultimos pagos de Cliente</h2>
            </Grid>
            <Grid item lg={6}>
              <div>
                <Box sx={{ height: 400, width: "100%" }}>
                  <DataGrid
                    getRowId={(row) => row.numeroTransaccion}
                    rows={rows}
                    columns={columns}
                    //pageSize={10}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>
      </SCSAccordion>
    </>
  );
};

export default ComportamientoPago;
