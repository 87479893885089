import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormikContext, Formik, Form, Field, useField } from "formik";
import {
  Alert,
  Button,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  obtenerCabeceraCliente,
} from "../services/creditoServices";

import "../styles/main.css";
import BotonCerrar from "./BotonCerrar";
import MasterFirma from "app/modules/app-seguridad/Firma/FirmaContainers/MasterFirma";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";

const FormFirmaDigital = ({
  idCli,
  cliente,
  identifCliente,
  idSolicitudChanger,
  setInfoRegistrada,
  inhabilitar
}) => {
  const { authUser } = useSelector(({ auth }) => auth);

  const [clienteInfo, setClienteInfo] = useState(null);
  const [idSolicitudActual, setIdSolicitudActual] = useState(null);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [alertaGuardar, setAlertaGuardar] = useState(false);
  const [mensajeError, setMensajeError] = useState("");

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    //console.log("USUARIO EN FIRMA", identifCliente);
    //console.log("AQUI", authUser);
  }, []);

  if (idCli < 0) {
    return <h2>Debe crear primero un cliente !!</h2>;
  }
  return (
    <>
      <SCSAccordion tituloCabecera="Firma">
      <TableContainer component={Box}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
              <TableHead>
                <TableRow >
                  <TableCell ><h2>Firma Digital Flujo - CC: {identifCliente}</h2></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableCell component="th" scope="row">
                  <Box>
                    <MasterFirma 
                    identificacion={identifCliente}
                    setInfoRegistrada={setInfoRegistrada}
                    inhabilitar={inhabilitar}
                    />
                  </Box>
                </TableCell>
              </TableBody>
            </Table>
          </TableContainer>
      </SCSAccordion>
    </>
  );
};

export default FormFirmaDigital;
