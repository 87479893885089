import React, { useEffect, useState } from "react";

import BuscadorPorLocal from "../../containers/BuscadorPorLocal";
import DetalleInformacionLocal from "../../containers/DetalleInformacionLocal";

import { ObtenerLocalUbiGeografica1 } from "../../services/configuracionServices";

const InformacionLocal = () => {
  const [local, setLocal] = useState(null);
  const [idLocal, setIdLocal] = useState(null);
  const [mostrarDetalle, setMostrarDetalle] = useState(false);

  const nuevoLocal = {};
  const nuevoIdLocal = -1;

  const buscarExistenciaLocal = async (idLocal) => {
    console.log(idLocal);

    try {
      const respuestaLocal = await ObtenerLocalUbiGeografica1(idLocal);
      console.log(respuestaLocal);

      if (respuestaLocal.data != null) {
        console.log(respuestaLocal);
        setIdLocal(respuestaLocal.data.idLocal);
        setLocal(respuestaLocal.data);
        setMostrarDetalle(false);
      } else {
        setLocal({ idLocal: idLocal });
        setIdLocal(nuevoIdLocal);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdLocal = (idLocal) => () => {
    console.log(idLocal);
    buscarExistenciaLocal(idLocal);
  };

  return (
    <div>
      {idLocal && local ? (
        <DetalleInformacionLocal idLocal={idLocal} local={local} />
      ) : (
        <BuscadorPorLocal traerIdLocal={traerIdLocal} />
      )}
    </div>
  );
};

export default InformacionLocal;
