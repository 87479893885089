import React, { useEffect, useState } from "react";

import { Skeleton } from "@mui/material";

import {
  SCSTableData,
  SCSTableDataRow,
  SCSTableDataCell,
} from "app/modules/common/components/TableData/SCSTableData";

import { consultarDatosSeguro } from "../services/transaccionalService";
import DetalleOpcionGenerica from "./DetalleOpcionGenerica";

const DetalleDatosSeguros = ({
  cabenceraTransaccional,
  datosBusqueda,
  alturaResolucion,
}) => {
  const [estadosCredito, setEstadosCredito] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaEstadosCredito = await consultarDatosSeguro(
          datosBusqueda.identificacion,
          datosBusqueda.idGrupo
        );

        if (respuestaEstadosCredito.state && respuestaEstadosCredito.data) {
          setEstadosCredito(respuestaEstadosCredito.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={alturaResolucion}
        sx={{ borderRadius: "32px" }}
      />
    );
  }

  if (estadosCredito.length <= 0) {
    return (
      <DetalleOpcionGenerica mensaje={"No existe información del cliente."} />
    );
  }

  const columnsData = [
    { id: 1, name: "N° Contrato" },
    { id: 2, name: "Estado" },
    { id: 9, name: "Artículo" },
    { id: 3, name: "Producto" },
    { id: 4, name: "Fecha Inicio" },
    { id: 5, name: "Fecha Fin" },
    { id: 6, name: "Recurrencia" },
    { id: 7, name: "Valor" },
    { id: 8, name: "Fecha Cancelación" },
  ];

  return (
    <>
      <SCSTableData columnsData={columnsData} heightTable={alturaResolucion}>
        {estadosCredito?.map((consumo, index) => (
          <SCSTableDataRow key={consumo.numContrato}>
            <SCSTableDataCell align="center">
              {consumo.numContrato}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">{consumo.estado}</SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.articulo}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.producto}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.fechaInicio}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.fechaFin}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.recurrencia}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">{`$ ${consumo.valor}`}</SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.fechaCancelacion}
            </SCSTableDataCell>
          </SCSTableDataRow>
        ))}
      </SCSTableData>
    </>
  );
};

export default DetalleDatosSeguros;
