import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  TextField,
  FormControlLabel,
  MenuItem,
  FormHelperText,
  Select,
  InputLabel,
  FormControl,
  Stack,
} from "@mui/material";

import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

import {
  SCSGenericButton,
  SCSLoadingButton,
} from "app/modules/common/components/Buttons/SCSButtons";

import {
  consultarDatosDebitoCuenta,
  consultarDocumentoDebitoCuenta,
  crearDebitoCuenta,
} from "../services/creditoServices";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { SCSSwitch } from "app/modules/common/components/Switches/SCSSwitch";
import { StyleInputs } from "app/modules/common/styles/themes";
import { consultaCatalogosSinPadre } from "app/modules/app-cliente/services/Catalogos/catalogosService";
import handleDownlPdf from "app/utils/document/documentosService";
import InfoPopoverButton from "app/modules/shared/components/InfoPopoverButton";

const FormDebitoCuenta = ({
  idCliente,
  idGrupo,
  idCuenta,
  identificacion = "0",
  notificar,
}) => {
  const [debitoCuenta, setDebitoCuenta] = useState({});
  const [idDebitoCuentaAux, setIdDebitoCuentaAux] = useState(0);

  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingDocumento, setLoadingDocumento] = useState(false);

  useEffect(() => {
    const getDatosCuenta = async () => {
      try {
        const respuestaDebitoCuenta = await consultarDatosDebitoCuenta(
          idCliente,
          idGrupo
        );

        console.log(respuestaDebitoCuenta);

        if (respuestaDebitoCuenta.state) {
          setDebitoCuenta(respuestaDebitoCuenta.data);
          setIdDebitoCuentaAux(respuestaDebitoCuenta.data.idDebitoCuenta);
          setIsLoading(false);
        } else {
          setDebitoCuenta({});
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    getDatosCuenta();
  }, []);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={StyleInputs(componentSize.pcWidth, componentSize.phoneWidth)}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const handleNotificarDebito = async () => {
    setLoadingDocumento(true);
    try {
      const respuestaDocumentoDebito = await consultarDocumentoDebitoCuenta(
        identificacion,
        idGrupo
      );

      if (respuestaDocumentoDebito.state) {
        setLoadingDocumento(false);

        setTimeout(() => {
          setLoadingDocumento(false);
          handleDownlPdf(
            respuestaDocumentoDebito.data,
            `Autorizacion_${idCliente}`
          );
        }, 1000);
      }
    } catch (error) {
      setLoadingDocumento(false);
      console.log(error);
    }
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const today = new Date().toLocaleString("en-US");

    const informacionDebitoCuenta = {
      idDebitoCuenta: idDebitoCuentaAux,
      idCliente: idCliente,
      idGrupo: idGrupo,
      idCuenta: idCuenta ? Number(idCuenta) : null,
      fechaSolicitud: today,
      institucion: valores.institucion.toUpperCase(),
      numeroCuenta: valores.numeroCuenta,
      esAhorro: valores.esAhorro,
      esActivo: valores.esActivo,
    };

    console.log(informacionDebitoCuenta);

    try {
      const respuestaCrearDebito = await crearDebitoCuenta(
        informacionDebitoCuenta
      );

      if (respuestaCrearDebito.state) {
        setDatosEnviadosCorrectamente(true);
        setLoading(false);
        setIdDebitoCuentaAux(respuestaCrearDebito.data);

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          idDebitoCuenta: debitoCuenta?.idDebitoCuenta ?? 0,
          institucion: debitoCuenta?.institucion ?? "",
          numeroCuenta: debitoCuenta?.numeroCuenta ?? "",
          esAhorro: debitoCuenta?.esAhorro ?? true,
          esActivo: debitoCuenta?.esActivo ?? true,
        }}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        validationSchema={Yup.object({
          numeroCuenta: Yup.string()
            .min(6, "Mínimo caracteres necesarios")
            .max(16, "Excede caracteres permitidos")
            .required("Número Cuenta requerido"),
          institucion: Yup.string().required("Institución requerida"),
        })}
        onSubmit={(values) => {
          configuracionDatosEnvio(values);
        }}
      >
        {({ values, handleChange, touched, errors, handleBlur }) => (
          <Form>
            <SCSAccordion tituloCabecera="Débito a Cuenta">
              <div className="debitoCuenta-form-seccion">
                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  direction={"row"}
                >
                  <InfoPopoverButton
                    nombre={"DC"}
                    texto={
                      "<p>El costo de esta transacción será cobrado por el Banco propietario de su cuenta </p>"
                    }
                  />
                  <div className="debitoCuenta-form-controls">
                    <SelectItemsCatalogoSinPadre
                      pais="ECU"
                      especial="false"
                      catalogos="FINANPAGO"
                      grupo={1}
                      name="institucion"
                      labelName="Institución"
                      componentSize={{ pcWidth: 100, phoneWidth: 100 }}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />

                    <TextField
                      id="numeroCuenta"
                      name="numeroCuenta"
                      label="N° Cuenta"
                      sx={StyleInputs(90, 100)}
                      type="text"
                      value={values.numeroCuenta}
                      onChange={handleChange}
                      size="small"
                      inputProps={{
                        style: {
                          textTransform: "uppercase",
                        },
                      }}
                      error={
                        touched.numeroCuenta && Boolean(errors.numeroCuenta)
                      }
                      helperText={touched.numeroCuenta && errors.numeroCuenta}
                      onBlur={handleBlur}
                    />
                  </div>
                </Stack>

                <div className="debitoCuenta-form-options">
                  <section className="debitoCuenta-form-switch">
                    <h3 style={{ fontSize: "19px" }}>
                      <strong>Tipo de Cuenta: </strong>
                    </h3>
                    <h4 style={{ textAlign: "right", paddingRight: "1rem" }}>
                      <strong>Corriente</strong>
                    </h4>

                    <FormControlLabel
                      control={
                        <SCSSwitch
                          //sx={{ m: 1 }}
                          checked={values.esAhorro}
                          onChange={handleChange}
                          name="esAhorro"
                        />
                      }
                    />
                    <h4>
                      <strong>Ahorros</strong>
                    </h4>
                  </section>

                  <section className="debitoCuenta-form-switch">
                    <h3 style={{ fontSize: "19px" }}>
                      <strong>Estado:</strong>
                    </h3>
                    <h4 style={{ textAlign: "right", paddingRight: "1rem" }}>
                      <strong>Inactivo</strong>
                    </h4>

                    <FormControlLabel
                      control={
                        <SCSSwitch
                          sx={{ m: 1 }}
                          checked={values.esActivo}
                          onChange={handleChange}
                          name="esActivo"
                        />
                      }
                    />
                    <h4>
                      <strong>Activo</strong>
                    </h4>
                  </section>
                </div>
              </div>

              <div className="confirmar-estados-servicios">
                <SCSLoadingButton loading={loading} titulo="GUARDAR" />

                {notificar && idDebitoCuentaAux > 0 && (
                  <SCSLoadingButton
                    titulo="Notificar"
                    type="button"
                    loading={loadingDocumento}
                    onClick={() => handleNotificarDebito()}
                  />
                )}

                {datosEnviadosCorrectamente && <AcceptServiceresponse />}
              </div>
            </SCSAccordion>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormDebitoCuenta;
