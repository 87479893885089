import React, { useState } from 'react';
import { Field } from 'react-final-form';
import GenericGrid from '../../../common/components/GenericGrid';

const GridRoles = ({ onSelectionChange }) => {
  return (
    <Field
      name={'roles'}
      columns={[
        {
          name: 'codigo',
          title: 'Código',
        },

        {
          name: 'sistema',
          title: 'Sistema',
        },
        {
          name: 'canal',
          title: 'Canal',
        },
        {
          name: 'descripcion',
          title: 'Descripción',
        },
        {
          name: 'activo',
          title: 'Activo',
        },
      ]}
      booleanColumns={['activo']}
      component={GenericGrid}
      disableAdd
      disableEdit
      defaultCurrentPage={0}
      pageSize={30}
      enableSelection
      onSelectionChange={onSelectionChange}
      defaultFilters={[]}
      enableColumnResizing={true}

      // defaultColumnWidths={columnWidth}
    />
  );
};
export default GridRoles;
