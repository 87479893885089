import React, { useEffect, useState } from "react";

import { Grid, Card } from "@mui/material";

import {
  obtenerCabeceraSolicitud,
  obtenerCabeceraCliente,
} from "../services/creditoServices";

function CabeceraSolicitud({ idSolic }) {
  const [idSolicit, setIdSolicit] = useState(idSolic);
  const [cabeceraCliente, setCabeceraCliente] = useState({});
  const [cabeceraSolicitud, setCabeceraSolicitud] = useState({});
  const [cupoPreap, setCupoPreap] = useState();
  const [mostrar, setMostrar] = useState(false);

  useEffect(() => {
    console.log(idSolicit);
    const getCabecera = async (id) => {
      try {
        const response = await obtenerCabeceraSolicitud(id);
        if (response.state) {
          setCabeceraSolicitud(response.data);
          //console.log(response.data);
          try{
            const cli = await obtenerCabeceraCliente(response.data.idCliente);
              if (cli.state) {
                //console.log(cli.data);
                setCabeceraCliente(cli.data);
              }
            }
            catch(e) {
              console.log(e);
            };
        }
      } catch (err) {
        console.log(err);
      }
    };    
    
    getCabecera(idSolicit);
  }, []);

  const formatoFecha = (fecha) => {
    const fechaModificada = fecha.split("T");
    return fechaModificada[0];
  };

  return (
    <div className="header-client-container">
      <Card>
        <header className="header-client-information">
          <div>
            <h2>Cliente</h2>
          </div>
          <div>
            <h4>Identificación</h4>
            <h4>
              {cabeceraCliente.identificacion
                ? cabeceraCliente.identificacion
                : ""}
            </h4>
          </div>
          <div>
            <h4>Nombre</h4>
            <h4>{cabeceraCliente.nombreCliente}</h4>
          </div>

          <div>
            <h4>Fecha Nacimiento</h4>
            <h4>
              {cabeceraCliente.fechaNacimiento
                ? formatoFecha(cabeceraCliente.fechaNacimiento)
                : ""}
            </h4>
          </div>
          <div>
            <h4>Telefono</h4>
            <h4>{cabeceraCliente.telefono ? cabeceraCliente.telefono : ""}</h4>
          </div>
          <div>
            <h4>Celular</h4>
            <h4>{cabeceraCliente.celular ? cabeceraCliente.celular : ""}</h4>
          </div>
          <div>
            <h4>Tipo Cliente</h4>
            <h4>
              {cabeceraSolicitud.tipoCliente
                ? cabeceraSolicitud.tipoCliente
                : ""}
            </h4>
          </div>
        </header>
      </Card>
      <br />
      <Card>
        <header className="header-client-information">
          <div>
            <h2>Solicitud</h2>
          </div>
          <div>
            <h4>Numero de Solicitud</h4>
            <h4>
              {cabeceraSolicitud.numeroSolicitud
                ? cabeceraSolicitud.numeroSolicitud
                : ""}
            </h4>
          </div>
          <div>
            <h4>Flujo</h4>
            <h4>{cabeceraSolicitud.flujo}</h4>
          </div>

          <div>
            <h4>Local</h4>
            <h4>
              {cabeceraSolicitud.local
                ? `${cabeceraSolicitud.idLocal} - ${cabeceraSolicitud.local}`
                : ""}
            </h4>
          </div>
          <div>
            <h4>Estado</h4>
            <h4>{cabeceraSolicitud.estado ? cabeceraSolicitud.estado : ""}</h4>
          </div>
          <div>
            <h4>Monto Solicitado</h4>
            <h4>
              {cabeceraSolicitud.montoSolicitado
                ? `$ ${cabeceraSolicitud.montoSolicitado}`
                : ""}
            </h4>
          </div>
          <div>
            <h4>Cupo Preaprobado</h4>
            <h4>$ {cabeceraSolicitud.montoPreaprobado}</h4>
          </div>
        </header>
      </Card>
    </div>
  );
}

export default CabeceraSolicitud;
