import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  FormControl,
  MenuItem,
  TextField,
  OutlinedInput,
  FormHelperText,
  InputLabel,
  Select,
  Alert,
  Typography,
} from "@mui/material";

import {
  validarCelular,
  validarFalsaData,
} from "app/modules/shared/validations/validaciones";

import {
  consultaDireccion,
  consultaContacto,
  crearClienteContacto,
  crearClienteDireccion,
  consultarCodigoProvincia,
} from "../services/clienteService";

import {
  consultaUbiGeografica1,
  consultaCatalogosSinPadre,
} from "../services/Catalogos/catalogosService";

import {
  MaskCelulares,
  MaskTelefonos,
} from "app/modules/shared/mask/mascarasInputs";

import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { StyleInputs } from "../styles/themes";
import SkeletonForm from "app/modules/common/components/Skeleton/SkeletonForm";
import { ERROR_VALIDACION_CONTACTO } from "app/modules/shared/constants/constantsMessanges";

const FormInformacionUbicabilidad = ({
  idCliente,
  tipoDireccion,
  setInfoClienteGrabada,
}) => {
  const [clienteInfo, setClienteInfo] = useState(null);
  const [controlExpand, setControlExpand] = useState({
    controlExpanded: true,
    controlSave: false,
  });
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [codigoProvincia, setCodigoProvincia] = useState("00");
  const [loading, setLoading] = useState(false);

  const [idDireccionAux, setIdDireccionAux] = useState(0);
  const [idContactonAux, setIdContactoAux] = useState(0);

  useEffect(() => {
    /* console.log(JSON.parse(localStorage.getItem("contacto"))); */
    const getData = async () => {
      let infoDireccion;
      let infoContacto;

      const contacto = JSON.parse(localStorage.getItem("contacto"));
      try {
        const informacionDireccion = await consultaDireccion(
          idCliente,
          tipoDireccion ?? "DIRDOM"
        );

        if (informacionDireccion.state) {
          infoDireccion = informacionDireccion.data;
          //console.log(infoDireccion);
          setIdDireccionAux(informacionDireccion.data.idDireccionCliente);
        } else {
          contacto === null
            ? (infoDireccion = {})
            : (infoDireccion = {
                callePrincipal: contacto.callePrincipalDireccionPrincipal || "",
                calleTransversal:
                  contacto.calleTransversalDireccionPrincipal || "",
                referencia: contacto.direccionPrincipal || "",
                numeroCasa: contacto.numeroDireccionPrincipal || "",
              });
        }
      } catch (error) {
        contacto === null
          ? (infoDireccion = {})
          : (infoDireccion = {
              callePrincipal: contacto.callePrincipalDireccionPrincipal || "",
              calleTransversal:
                contacto.calleTransversalDireccionPrincipal || "",
              referencia: contacto.direccionPrincipal || "",
              numeroCasa: contacto.numeroDireccionPrincipal || "",
            });
        console.log(error);
      }

      try {
        const informacionContacto = await consultaContacto(
          idCliente,
          tipoDireccion ?? "DIRDOM"
        );

        if (informacionContacto.state) {
          infoContacto = informacionContacto.data;
          /* console.log(infoContacto); */
          setIdContactoAux(informacionContacto.data.idContacto);
        } else {
          contacto === null
            ? (infoContacto = {})
            : (infoContacto = {
                celular: "",
                email: "",
                telefono: "",
              });
        }
      } catch (error) {
        contacto === null
          ? (infoContacto = {})
          : (infoContacto = {
              celular: "",
              email: "",
              telefono: "",
            });
        console.log(error);
      }

      const res = {
        ...infoDireccion,
        ...infoContacto,
      };

      /* console.log(res); */
      setClienteInfo(res);
    };
    getData();
  }, []);

  if (idCliente <= 0) {
    return <h2>Cliente NO existe. Primero cree el Cliente.!!</h2>;
  }

  const CodigoProvincia = ({ provincia }) => {
    const [codProvincia, setCodProvincia] = useState("00");
    useEffect(() => {
      /*  console.log(provincia); */
      const getCodigoProvincia = async () => {
        try {
          const respuestaCodigoProvincia = await consultarCodigoProvincia(
            provincia
          );
          /* console.log(respuestaCodigoProvincia); */

          if (respuestaCodigoProvincia.state) {
            setCodigoProvincia(respuestaCodigoProvincia.data.codigoTelefonico);
            setCodProvincia(respuestaCodigoProvincia.data.codigoTelefonico);
          } else {
            setCodigoProvincia("00");
            setCodProvincia("00");
          }
        } catch (error) {
          console.log(error);
          setCodigoProvincia("00");
          setCodProvincia("00");
        }
      };

      getCodigoProvincia();
    }, [provincia]);

    return (
      <TextField
        disabled
        id="codigo"
        name="codigo"
        type="text"
        label="COD"
        value={codProvincia}
        size="small"
        //sx={{ width: "6%" }}
        sx={StyleInputs(6, 16)}
      />
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        //sx={{ width: `${componentSize}` }}
        sx={StyleInputs(componentSize.pcWidth, componentSize.phoneWidth)}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsCatalogo = ({
    pais,
    especial,
    catalogos,
    codPadre,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaUbiGeografica1(
            especial,
            catalogos,
            codPadre,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, [pais, codPadre]);

    return (
      <FormControl
        sx={StyleInputs(componentSize.pcWidth, componentSize.phoneWidth)}
        //sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const verificarTipoDomicilio = (tipoDomicilio) => {
    if (tipoDomicilio === "DPTO") {
      return true;
    }
    return false;
  };

  const validarTelefono = (telefono) => {
    if (!telefono) {
      return false;
    } else {
      return telefono.slice(-7);
    }
  };

  const validarDepartamento = (tipoDomicilio) => {
    if (tipoDomicilio === "CASA" || "") {
      return false;
    } else {
      return true;
    }
  };

  const validarNombreEdificio = (nombreEdificio) => {
    if (
      nombreEdificio === "" ||
      !/^[a-zA-ZÑñ" "0-9]{0,2}$/.test(nombreEdificio)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const configuracionDatosEnvio = async (valoresAux) => {
    setLoading(true);

    /*  console.log({ valoresAux }); */

    const valores = { ...valoresAux };
    if (valoresAux.telefono === "") {
      valores.telefono = "";
    } else {
      valores.telefono = codigoProvincia + valores.telefono;
    }

    /* console.log({ valores }); */

    const informacionDireccion = {
      idCliente: idCliente,
      idDireccionCliente: idDireccionAux,

      idUbicGeografica1: valores.idUbicGeografica1,
      idUbicGeografica2: valores.idUbicGeografica2,
      idUbicGeografica3: valores.idUbicGeografica3,

      callePrincipal: valores.callePrincipal.toUpperCase(),
      calleTransversal: valores.calleTransversal.toUpperCase(),
      nombreEdificio: valores.nombreEdificio.toUpperCase(),
      numeroCasa: valores.numeroCasa.toUpperCase(),
      numeroDepartamento: valores.numeroDepartamento,
      referencia: valores.referencia.toUpperCase(),
      sector: valores.sector.toUpperCase(),
      /* tiempoResidencia: valores.tiempoResidencia, */

      tipoDireccion: tipoDireccion ?? "DIRDOM",
      tipoDomicilio: valores.tipoDomicilio,
      tipoVivienda: valores.tipoVivienda,
      esActivo: true,
    };

    /* console.log("Tabla Informacion Direccion");
    console.log(informacionDireccion); */

    const informacionContacto = {
      idCliente: idCliente,
      idContacto: idContactonAux,

      celular: valores.celular,
      email: valores.email.toLowerCase(),
      telefono: valores.telefono,
      telefono2: valores.telefono2,
      extension: valores.extension,
      tipoDireccion: tipoDireccion ?? "DIRDOM",
      preferencia: valores.preferencia,
      esPersonal: true,
      esActivo: true,
    };

    /* console.log("Tabla Informacion Contacto");
    console.log(informacionContacto); */

    try {
      const respuestaClienteContacto = await crearClienteContacto(
        informacionContacto
      );

      if (respuestaClienteContacto.state) {
        /*  console.log({ respuestaClienteContacto }); */
        setIdContactoAux(respuestaClienteContacto.data);
      } else {
        alert(`${respuestaClienteContacto.message}`);
      }
    } catch (error) {
      alert(`${error.response.data.message}`);

      console.log(error);
    }

    try {
      const respuestaClienteDireccion = await crearClienteDireccion(
        informacionDireccion
      );

      /*  console.log({ respuestaClienteDireccion }); */

      if (respuestaClienteDireccion.state) {
        setIdDireccionAux(respuestaClienteDireccion.data);
      } else {
        alert(`${respuestaClienteDireccion.message}`);
      }
    } catch (error) {
      alert(`${error.response.data.message}`);

      console.log(error);
    }

    if (setInfoClienteGrabada) {
      setInfoClienteGrabada((args) => ({
        ...args,
        ubicabilidad: true,
      }));
    }
    setDatosEnviadosCorrectamente(true);

    setTimeout(() => {
      setLoading(false);
      setDatosEnviadosCorrectamente(false);
      handleExpand();
      setControlExpand((args) => ({
        ...args,
        controlSave: true,
      }));
    }, 1000);
  };

  const handleExpand = () => {
    setControlExpand((args) => ({
      ...args,
      controlExpanded: !args.controlExpanded,
    }));
  };

  return (
    <>
      {clienteInfo ? (
        <Formik
          initialValues={{
            /* direccion Info */
            idUbicGeografica1: clienteInfo.idUbicGeografica1 || "",
            idUbicGeografica2: clienteInfo.idUbicGeografica2 || "",
            idUbicGeografica3: clienteInfo.idUbicGeografica3 || "",
            sector: clienteInfo.sector || "",
            referencia: clienteInfo.referencia || "",
            numeroCasa: clienteInfo.numeroCasa || "",
            nombreEdificio: clienteInfo.nombreEdificio || "",
            callePrincipal: clienteInfo.callePrincipal || "",
            calleTransversal: clienteInfo.calleTransversal || "",
            numeroDepartamento: clienteInfo.numeroDepartamento || "",
            tipoVivienda: clienteInfo.tipoVivienda || "",

            tipoDomicilio: clienteInfo.tipoDomicilio || "",

            /* contacto Info */
            celular: clienteInfo.celular || "",
            email: clienteInfo.email || "",
            telefono: validarTelefono(clienteInfo.telefono) || "",
            telefono2: clienteInfo.telefono2 || "",
            extension: clienteInfo.extension || "",
            preferencia: clienteInfo.preferencia || "",
          }}
          validate={async (values) => {
            const errors = {};

            if (!/^[0-9]{0,11}$/.test(values.telefono)) {
              errors.telefono = "Solo se aceptan números";
            }

            if (!/^[0-9]{0,11}$/.test(values.extension)) {
              errors.extension = "Solo se aceptan números";
            }

            if (!/^[0-9]{0,11}$/.test(values.telefono2)) {
              errors.telefono2 = "Solo se aceptan números";
            }
            if (validarCelular(values.telefono2)) {
              errors.telefono2 = "Primer digito debería ser '09'";
            }

            if (await validarFalsaData(values.celular, "CEL")) {
              errors.celular = ERROR_VALIDACION_CONTACTO;
            }

            if (await validarFalsaData(values.telefono2, "CEL")) {
              errors.telefono2 = ERROR_VALIDACION_CONTACTO;
            }

            if (!/^[0-9]{0,11}$/.test(values.celular)) {
              errors.celular = "Solo se aceptan números";
            }

            if (validarCelular(values.celular)) {
              errors.celular = "Primer digito debería ser '09'";
            }

            if (validarDepartamento(values.tipoDomicilio)) {
              if (validarNombreEdificio(values.nombreEdificio)) {
                errors.nombreEdificio = "Deberia tener al menos 3 caracteres";
              }
            }

            if (validarDepartamento(values.tipoDomicilio)) {
              if (validarNombreEdificio(values.numeroDepartamento)) {
                errors.numeroDepartamento =
                  "Deberia tener al menos 3 caracteres";
              }
            }

            if (await validarFalsaData(values.email, "COR")) {
              errors.email = "Data invalida";
            }

            return errors;
          }}
          validationSchema={Yup.object({
            idUbicGeografica1: Yup.string().required("Provincia requerida"),
            idUbicGeografica2: Yup.string().required("Cantón requerida"),
            idUbicGeografica3: Yup.string().required("Parroquia requerida"),

            callePrincipal: Yup.string()
              .max(128, "Debería tener máximo 128 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Calle Principal requerida"),
            numeroCasa: Yup.string()
              .max(32, "Debería tener máximo 32 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            calleTransversal: Yup.string()
              .max(128, "Debería tener máximo 128 caracteres")
              .min(3, "Debería tener al menos 3 caracteres")
              .required("Calle Transversal requerida"),
            referencia: Yup.string()
              .max(256, "Debería tener máximo 256 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            sector: Yup.string()
              .max(128, "Debería tener máximo 128 caracteres")
              .min(3, "Debería tener al menos 3 caracteres"),
            tipoDomicilio: Yup.string().required("Tipo Domicilio requerido"),

            celular: Yup.string()
              .min(10, "Debería tener 10 números")
              .max(10, "Debería tener 10 números")
              .required("Celular requerido"),
            telefono: Yup.string()
              .min(7, "Debería tener 7 números")
              .max(7, "Debería tener 7 números"),
            telefono2: Yup.string()
              .min(10, "Debería tener 10 números")
              .max(10, "Debería tener 10 números"),
            extension: Yup.string().min(1, "Debería tener 1 números"),
            email: Yup.string().email("Email no tiene formato"),
            preferencia: Yup.string().required("Preferencia requerida"),
          })}
          onSubmit={(values) => {
            configuracionDatosEnvio(values);
          }}
        >
          {({ values, handleChange, touched, errors, handleBlur }) => (
            <Form>
              <SCSAccordion
                tituloCabecera="Información Ubicabilidad"
                controlExpand={controlExpand}
                handleExpand={handleExpand}
              >
                <div>
                  <h3>Dirección</h3>
                </div>
                <Alert severity="info">
                  <Typography
                    sx={{
                      fontSize: "1.2rem",
                    }}
                  >
                    Confirma o actualiza tus datos..
                  </Typography>
                </Alert>

                <div className="cliente-informacion-seccion">
                  <SelectItemsCatalogo
                    pais="ECU"
                    especial="true"
                    catalogos="UG1"
                    codPadre={0}
                    grupo={1}
                    name="idUbicGeografica1"
                    labelName="Provincia"
                    //componentSize="23%"
                    componentSize={{ pcWidth: 23, phoneWidth: 45 }}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                  />

                  <SelectItemsCatalogo
                    pais="ECU"
                    especial="true"
                    catalogos="UG2"
                    codPadre={values.idUbicGeografica1}
                    grupo={1}
                    name="idUbicGeografica2"
                    labelName="Canton"
                    componentSize={{ pcWidth: 23, phoneWidth: 45 }}
                    //componentSize="23%"
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                  />

                  <SelectItemsCatalogo
                    pais="ECU"
                    especial="true"
                    catalogos="UG3"
                    codPadre={values.idUbicGeografica2}
                    grupo={1}
                    name="idUbicGeografica3"
                    labelName="Parroquia"
                    componentSize={{ pcWidth: 23, phoneWidth: 45 }}
                    //componentSize="23%"
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                  />

                  <TextField
                    id="sector"
                    name="sector"
                    type="text"
                    label="Sector"
                    //sx={{ width: "23%" }}
                    sx={StyleInputs(23, 45)}
                    value={values.sector}
                    onChange={handleChange}
                    size="small"
                    inputProps={{
                      style: { textTransform: "uppercase" },
                    }}
                    error={touched.sector && Boolean(errors.sector)}
                    helperText={touched.sector && errors.sector}
                    onBlur={handleBlur}
                  />
                </div>

                <div className="cliente-informacion-seccion">
                  <TextField
                    id="callePrincipal"
                    name="callePrincipal"
                    type="text"
                    label="Calle Principal"
                    value={values.callePrincipal}
                    onChange={handleChange}
                    //sx={{ width: "40%" }}
                    sx={StyleInputs(40, 65)}
                    size="small"
                    inputProps={{
                      style: { textTransform: "uppercase" },
                    }}
                    error={
                      touched.callePrincipal && Boolean(errors.callePrincipal)
                    }
                    helperText={touched.callePrincipal && errors.callePrincipal}
                    onBlur={handleBlur}
                  />
                  <TextField
                    id="numeroCasa"
                    name="numeroCasa"
                    label="N° Casa"
                    value={values.numeroCasa}
                    onChange={handleChange}
                    //sx={{ width: "13%" }}
                    sx={StyleInputs(13, 25)}
                    size="small"
                    inputProps={{
                      style: { textTransform: "uppercase" },
                    }}
                    error={touched.numeroCasa && Boolean(errors.numeroCasa)}
                    helperText={touched.numeroCasa && errors.numeroCasa}
                    onBlur={handleBlur}
                  />

                  <TextField
                    id="calleTransversal"
                    name="calleTransversal"
                    type="text"
                    label="Calle Transversal"
                    //sx={{ width: "41%" }}
                    sx={StyleInputs(41, 65)}
                    value={values.calleTransversal}
                    onChange={handleChange}
                    size="small"
                    inputProps={{
                      style: { textTransform: "uppercase" },
                    }}
                    error={
                      touched.calleTransversal &&
                      Boolean(errors.calleTransversal)
                    }
                    helperText={
                      touched.calleTransversal && errors.calleTransversal
                    }
                    onBlur={handleBlur}
                  />
                </div>

                <div className="cliente-informacion-seccion">
                  <TextField
                    id="referencia"
                    name="referencia"
                    label="Referencia"
                    value={values.referencia}
                    onChange={handleChange}
                    size="small"
                    //sx={{ width: "98%" }}
                    sx={StyleInputs(97, 95)}
                    inputProps={{
                      style: { textTransform: "uppercase" },
                    }}
                    error={touched.referencia && Boolean(errors.referencia)}
                    helperText={touched.referencia && errors.referencia}
                    onBlur={handleBlur}
                  />
                </div>

                <div>
                  <h3>Vivienda</h3>
                </div>

                <div className="cliente-informacion-seccion">
                  <SelectItemsCatalogoSinPadre
                    pais="ECU"
                    especial="false"
                    catalogos="TIPODOMIC"
                    grupo={1}
                    name="tipoDomicilio"
                    labelName="Tipo Domicilio"
                    componentSize={{ pcWidth: 19, phoneWidth: 45 }}
                    //componentSize="19%"
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                  />

                  <SelectItemsCatalogoSinPadre
                    pais="ECU"
                    especial="false"
                    catalogos="TIPOVIV"
                    grupo={1}
                    name="tipoVivienda"
                    labelName="Tipo Vivivenda"
                    componentSize={{ pcWidth: 19, phoneWidth: 45 }}
                    //componentSize="19%"
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                  />

                  {verificarTipoDomicilio(values.tipoDomicilio) && (
                    <>
                      <TextField
                        id="nombreEdificio"
                        name="nombreEdificio"
                        type="text"
                        label="Nombre Edificio"
                        sx={StyleInputs(22, 45)}
                        //sx={{ width: "22%" }}
                        value={values.nombreEdificio}
                        onChange={handleChange}
                        size="small"
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        error={
                          touched.nombreEdificio &&
                          Boolean(errors.nombreEdificio)
                        }
                        helperText={
                          touched.nombreEdificio && errors.nombreEdificio
                        }
                        onBlur={handleBlur}
                      />
                      <TextField
                        id="numeroDepartamento"
                        name="numeroDepartamento"
                        type="text"
                        label="Departamento"
                        sx={StyleInputs(15, 45)}
                        //sx={{ width: "15%" }}
                        value={values.numeroDepartamento}
                        onChange={handleChange}
                        size="small"
                        inputProps={{
                          style: {
                            textTransform: "uppercase",
                          },
                        }}
                        error={
                          touched.numeroDepartamento &&
                          Boolean(errors.numeroDepartamento)
                        }
                        helperText={
                          touched.numeroDepartamento &&
                          errors.numeroDepartamento
                        }
                        onBlur={handleBlur}
                      />
                    </>
                  )}
                </div>

                <div>
                  <h3>Contacto</h3>
                  <Alert severity="info">
                    <Typography
                      sx={{
                        fontSize: "1.2rem",
                      }}
                    >
                      Ingresa tu celular y email para notificacion.
                    </Typography>
                  </Alert>
                </div>

                <div className="cliente-informacion-seccion">
                  <FormControl
                    //sx={{ width: "15%" }}
                    sx={StyleInputs(15, 45)}
                    size="small"
                    variant="outlined"
                    error={touched.celular && Boolean(errors.celular)}
                    helperText={touched.celular && errors.celular}
                    onBlur={handleBlur}
                  >
                    <InputLabel htmlFor="celular">Celular</InputLabel>
                    <OutlinedInput
                      value={values.celular}
                      onChange={handleChange}
                      name="celular"
                      id="celular"
                      inputComponent={MaskCelulares}
                      label="celular"
                    />
                    <ErrorMessage
                      name="celular"
                      component={() => (
                        <FormHelperText>{errors.celular}</FormHelperText>
                      )}
                    />
                  </FormControl>

                  <TextField
                    id="email"
                    name="email"
                    type="text"
                    label="Email para notificaciones"
                    //sx={{ width: "30%" }}
                    sx={StyleInputs(30, 95)}
                    value={values.email}
                    onChange={handleChange}
                    size="small"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                  />

                  <CodigoProvincia provincia={values.idUbicGeografica1} />

                  <FormControl
                    //sx={{ width: "15%" }}
                    sx={StyleInputs(15, 42)}
                    size="small"
                    variant="outlined"
                    error={touched.telefono && Boolean(errors.telefono)}
                    helperText={touched.telefono && errors.telefono}
                    onBlur={handleBlur}
                  >
                    <InputLabel htmlFor="telefono">Télefono</InputLabel>
                    <OutlinedInput
                      value={values.telefono}
                      onChange={handleChange}
                      name="telefono"
                      id="telefono"
                      inputComponent={MaskTelefonos}
                      /* label es el tama;o que maneja el label */
                      label="telefono"
                    />
                    <ErrorMessage
                      name="telefono"
                      component={() => (
                        <FormHelperText>{errors.telefono}</FormHelperText>
                      )}
                    />
                  </FormControl>

                  <TextField
                    id="extension"
                    name="extension"
                    type="text"
                    label="Extensión"
                    inputProps={{ min: 0 }}
                    //sx={{ width: "8%" }}
                    sx={StyleInputs(8, 30)}
                    value={values.extension}
                    onChange={handleChange}
                    size="small"
                    error={touched.extension && Boolean(errors.extension)}
                    helperText={touched.extension && errors.extension}
                    onBlur={handleBlur}
                  />

                  <FormControl
                    //sx={{ width: "15%" }}
                    sx={StyleInputs(15, 45)}
                    size="small"
                    variant="outlined"
                    error={touched.telefono2 && Boolean(errors.telefono2)}
                    helperText={touched.telefono2 && errors.telefono2}
                    onBlur={handleBlur}
                  >
                    <InputLabel htmlFor="telefono2">Celular 2</InputLabel>
                    <OutlinedInput
                      value={values.telefono2}
                      onChange={handleChange}
                      name="telefono2"
                      id="telefono2"
                      inputComponent={MaskCelulares}
                      label="telefono2"
                    />
                    <ErrorMessage
                      name="telefono2"
                      component={() => (
                        <FormHelperText>{errors.telefono2}</FormHelperText>
                      )}
                    />
                  </FormControl>

                  {/* Preferecia */}

                  <SelectItemsCatalogoSinPadre
                    pais="ECU"
                    especial="false"
                    catalogos="TIPOCOM"
                    grupo={1}
                    name="preferencia"
                    labelName="Preferencia"
                    componentSize={{ pcWidth: 15, phoneWidth: 46 }}
                    //componentSize="19%"
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                  />
                </div>

                <div className="confirmar-estados-servicios">
                  <SCSLoadingButton loading={loading} titulo="GUARDAR" />

                  {datosEnviadosCorrectamente && <AcceptServiceresponse />}
                </div>
              </SCSAccordion>
            </Form>
          )}
        </Formik>
      ) : (
        <SkeletonForm />
      )}
    </>
  );
};

export default FormInformacionUbicabilidad;
