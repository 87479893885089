import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

import "./styles.css";

const SCSTableDataCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    margin: "0 0.5rem",
  },

  [`&.${tableCellClasses.head}`]: {
    padding: "1rem 0.5rem",
    backgroundColor: "#fbdfdd",
    color: theme.palette.common.black,
    border: 0,
    borderRadius: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: 0,
    borderBottom: `1px solid rgba(0, 0, 0, 0.1)`,
    borderTop: `1px solid rgba(0, 0, 0, 0.1)`,
  },
}));

const SCSTableDataRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.Mui-selected": {
    backgroundColor: "#fbdfdd",
  },
  "&.Mui-hover": {
    backgroundColor: "#fbdfdd",
  },
  cursor: "pointer",
}));

const SCSTableData = ({
  columnsData,
  children,
  heightTable,
  widthTable,
  size,
}) => {
  const validarAltura = (altura) => {
    if (altura) {
      if (altura === "auto") {
        return "auto";
      }
      if (altura < 100) {
        return `${altura}vh`;
      } else {
        return `${altura}px`;
      }
    }
    return "40vh";
  };

  const validarAncho = (ancho) => {
    if (ancho) {
      if (ancho < 100) {
        return `${ancho}vh`;
      } else {
        return `${ancho}px`;
      }
    }
    return "650px";
  };

  return (
    <TableContainer
      component={Paper}
      variant="scrollable"
      sx={{
        height: validarAltura(heightTable),
        padding: "0.3rem 0.7rem 1rem 0.7rem",
        borderRadius: "25px",
        overflowAnchor: "auto",
      }}
      className="detallePagosCliente-test"
    >
      <Table
        size={size ? size : "medium"}
        sx={{
          minWidth: validarAncho(widthTable),
          overflowX: "auto",
        }}
        aria-label="customized table"
        stickyHeader={true}
      >
        <TableHead>
          <TableRow /* selected */>
            {columnsData.map((column) => (
              <SCSTableDataCell key={column.id} align="center">
                <strong>{column.name}</strong>
              </SCSTableDataCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export { SCSTableData, SCSTableDataRow, SCSTableDataCell };
