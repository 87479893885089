import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  Alert,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { consultaCatalogosSinPadre } from "../../app-cliente/services/Catalogos/catalogosService";

import {
  consultarQuejaXid,
  consultarTipoSolucion,
  crearQueja,
} from "../services/gestionService";

import { formatoFecha } from "../../shared/validations/validaciones";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const FormSolucionQuejas = ({ idCliente, idQueja }) => {
  const [quejaInfo, setQuejaInfo] = useState(null);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  useEffect(() => {
    /* console.log(idQueja); */
    const getData = async () => {
      try {
        const respuestaQueja = await consultarQuejaXid(idQueja);

        if (respuestaQueja.data) {
          /*  console.log(respuestaQueja); */
          setQuejaInfo(respuestaQueja.data);
        } else {
          setQuejaInfo({});
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectTiposSolucion = ({ name, labelName, componentSize }) => {
    const [tipoSolucion, setTipoSolucion] = useState([]);

    useEffect(() => {
      const getData = async () => {
        const { state, data } = await consultarTipoSolucion();

        if (state) {
          setTipoSolucion(data);
        } else {
          setTipoSolucion([]);
        }
      };
      getData();
    }, []);

    return (
      <FormControl sx={{ width: `${componentSize}` }}>
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {tipoSolucion.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    );
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const configuraionEnvioDatos = async (valores) => {
   /*  console.log(valores); */
    const informacionQueja = {
      idQueja: Number(idQueja),
      idCliente: Number(idCliente),

      fechaQueja: quejaInfo.fechaQueja,
      canalIngreso: quejaInfo.canalIngreso,
      nombreReporta: quejaInfo.nombreReporta,
      tipoQueja: valores.tipoQueja,
      detalle: quejaInfo.detalle,
      idUbicGeografica2: quejaInfo.idUbicGeografica2,
      prestador: quejaInfo.prestador,
      idProducto: quejaInfo.idProducto,
      estado: quejaInfo.estado,

      idTipoSolucion: Number(valores.idTipoSolucion),
      codigoAsigna: valores.codigoAsigna,

      fechaCierre: "",
      esActivo: true,
    };

    /* console.log(informacionQueja); */

    try {
      const respuestaCrearQueja = await crearQueja(informacionQueja);
     /*  console.log(respuestaCrearQueja); */
      setDatosEnviadosCorrectamente(true);

      setTimeout(() => {
        setDatosEnviadosCorrectamente(false);
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {quejaInfo ? (
        <div className="accordion-container">
          <div>
            <Formik
              initialValues={{
                fechaQueja: quejaInfo.fechaQueja,
                canalIngreso: quejaInfo.canalIngreso,

                nombreReporta: quejaInfo.nombreReporta,
                idProducto: quejaInfo.idProducto,
                tipoQueja: quejaInfo.tipoQueja,

                prestador: quejaInfo.prestador,
                detalle: quejaInfo.detalle,
                codigoAsigna: quejaInfo.codigoAsigna,
                idTipoSolucion: quejaInfo.idTipoSolucion,
              }}
              onSubmit={(valores, { resetForm }) => {
                configuraionEnvioDatos(valores);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  <div className="accordion-container">
                    <div>
                      <Accordion
                        defaultExpanded="true"
                        sx={{ boxShadow: 3, lineHeight: 0 }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          className="accordion-summary"
                          sx={{ margin: 0 }}
                        >
                          <h2 className="formulario-cabecera">
                            Solución Queja
                          </h2>
                        </AccordionSummary>
                        <AccordionDetails sx={{ marginTop: "2rem" }}>
                          <Grid container spacing={1} rowSpacing={1}>
                            <Grid item md={12}>
                              <Grid container spacing={1}>
                                <Grid item md={12}>
                                  <div className="cliente-informacion-seccion">
                                    <TextField
                                      id="fechaQueja"
                                      name="fechaQueja"
                                      type="date"
                                      label="Fecha Queja"
                                      sx={{ width: "15%" }}
                                      onChange={handleChange}
                                      size="small"
                                      value={formatoFecha(values.fechaQueja)}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={
                                        touched.fechaQueja &&
                                        Boolean(errors.fechaQueja)
                                      }
                                      helperText={
                                        touched.fechaQueja && errors.fechaQueja
                                      }
                                      onBlur={handleBlur}
                                    />

                                    <SelectItemsCatalogoSinPadre
                                      pais="ECU"
                                      especial="false"
                                      catalogos="CANALQUEJA"
                                      grupo={1}
                                      name="canalIngreso"
                                      labelName="Canal Ingreso"
                                      componentSize="26%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />

                                    <TextField
                                      id="nombreReporta"
                                      name="nombreReporta"
                                      type="text"
                                      label="Nombre de quien reporta"
                                      sx={{ minWidth: 200 }}
                                      value={values.nombreReporta}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                      error={
                                        touched.nombreReporta &&
                                        Boolean(errors.nombreReporta)
                                      }
                                      helperText={
                                        touched.nombreReporta &&
                                        errors.nombreReporta
                                      }
                                      onBlur={handleBlur}
                                    />

                                    <TextField
                                      id="prestador"
                                      name="prestador"
                                      type="text"
                                      label="Prestador"
                                      sx={{ minWidth: 200 }}
                                      value={values.prestador}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                      error={
                                        touched.prestador &&
                                        Boolean(errors.prestador)
                                      }
                                      helperText={
                                        touched.prestador && errors.prestador
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>

                                  <div className="cliente-informacion-seccion">
                                    <SelectItemsCatalogoSinPadre
                                      pais="ECU"
                                      especial="false"
                                      catalogos="TIPOQUEJA"
                                      grupo={1}
                                      name="tipoQueja"
                                      labelName="Tipo Queja"
                                      componentSize="28%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />

                                    <SelectItemsCatalogoSinPadre
                                      pais="ECU"
                                      especial="true"
                                      catalogos="PROD"
                                      grupo={1}
                                      name="idProducto"
                                      labelName="Producto"
                                      componentSize="27%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />
                                  </div>

                                  <div className="cliente-informacion-seccion">
                                    <TextField
                                      id="detalle"
                                      name="detalle"
                                      type="text"
                                      label="Detalle"
                                      sx={{ width: "96%" }}
                                      value={values.detalle}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                      error={
                                        touched.detalle &&
                                        Boolean(errors.detalle)
                                      }
                                      helperText={
                                        touched.detalle && errors.detalle
                                      }
                                      onBlur={handleBlur}
                                    />

                                    <SelectItemsCatalogoSinPadre
                                      pais="ECU"
                                      especial="false"
                                      catalogos="TIPOASIGRESP"
                                      grupo={1}
                                      name="codigoAsigna"
                                      labelName="Codigo Asigna"
                                      componentSize="20%"
                                      handleBlur={handleBlur}
                                      touched={touched}
                                      errors={errors}
                                    />

                                    <SelectTiposSolucion
                                      name="idTipoSolucion"
                                      labelName="Tipo Solucion"
                                      componentSize="25%"
                                    />
                                  </div>

                                  <div className="cliente-informacion-seccion">
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      sx={{ width: "25ch" }}
                                    >
                                      Guardar
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>

                              {datosEnviadosCorrectamente && (
                                <Alert severity="success" variant="filled">
                                  Los datos han sido guardados correctamente.
                                </Alert>
                              )}
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <SpinnerLoadSrx />
      )}
    </>
  );
};

export default FormSolucionQuejas;
