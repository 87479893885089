import React, { useEffect } from "react";

import CabeceraSeguroService from "app/modules/app-servicios/containers/CabeceraSeguroService";
import FormPagoRecurrencia from "../components/FormPagoRecurrencia";
import ListaPagosSeguro from "./ListaPagosSeguro";

const DetallePagoRecurrencia = ({ idCliente, idContrato }) => {
/*   useEffect(() => {
    console.log({ idContrato });
  }, []); */

  return (
    <div>
      <CabeceraSeguroService
        idContrato={idContrato.idContrato}
        esPagoRecurrencias={true}
      />

      <FormPagoRecurrencia contrato={idContrato} />

   
    </div>
  );
};

export default DetallePagoRecurrencia;
