/* funcion que retorna la fecha con la hora actual para parametro tipo dateTime --- 23/11/2022, 16:40:55 */
export const obtenerFechaActual = () => {
  const fechaActual = new Date();
  /* console.log(typeof fechaActual.toLocaleDateString());*/
  return fechaActual.toLocaleString();
};

/* formato para envio en formularios --- "2022-11-23T16:40:55" */
export const obtenerFechaHoraActual2 = () => {
  const fechaActual = new Date();
  /* console.log(typeof fechaActual.toLocaleDateString()); */
  const horaActual = fechaActual
    .toLocaleString("es-EC", {
      timeZone: "America/Guayaquil",
    })
    .split(" ")[1];
  const fechaActualFormato = fechaActual.toISOString().split("T")[0];
  /* .split("/")
    .reverse()
    .join("-");  */

  /* console.log(fechaActualFormato); */
  return fechaActualFormato + "T" + horaActual;
};

export const obtenerFechaHoraActual = () => {
  const fechaActual = new Date();

  /* console.log(typeof fechaActual.toLocaleDateString()); */
  const horaActual = fechaActual.toLocaleString().split(" ")[1];
  const fechaActualFormato = fechaActual
    .toLocaleString()
    .split(",")[0]
    .split("/")
    .reverse()
    .join("-");

  /*  console.log(fechaActualFormato + "T" + horaActual); */
  return fechaActualFormato + "T" + horaActual;
};

/* funcion que retorna la fecha -> 2023-02-28*/
export const obtenerFechaActualFormulario = () => {
  const fechaActual = new Date();
  const [anio, mes, dia] = fechaActual
    .toLocaleDateString("es-EC", {
      timeZone: "America/Guayaquil",
    })
    .split("/")
    .reverse();

  /* console.log(fechaActual.toLocaleDateString()); */

  const diccionarioMes = (mes) => {
    const meses = {
      "01": "01",
      "02": "02",
      "03": "03",
      "04": "04",
      "05": "05",
      "06": "06",
      "07": "07",
      "08": "08",
      "09": "09",
      1: "01",
      2: "02",
      3: "03",
      4: "04",
      5: "05",
      6: "06",
      7: "07",
      8: "08",
      9: "09",
      10: "10",
      11: "11",
      12: "12",
    };
    return meses[mes];
  };

  const diccionarioDia = (dia) => {
    const dias = {
      1: "01",
      2: "02",
      3: "03",
      4: "04",
      5: "05",
      6: "06",
      7: "07",
      8: "08",
      9: "09",
      10: "10",
      11: "11",
      12: "12",
      13: "13",
      14: "14",
      15: "15",
      16: "16",
      17: "17",
      18: "18",
      19: "19",
      20: "20",
      21: "21",
      22: "22",
      23: "23",
      24: "24",
      25: "25",
      26: "26",
      27: "27",
      28: "28",
      29: "29",
      30: "30",
      31: "31",
      32: "32",
    };

    return dias[dia];
  };

  /* console.log(`${anio}-${diccionarioMes(mes)}-${diccionarioDia(dia)}`); */
  return `${anio}-${diccionarioMes(mes)}-${diccionarioDia(dia)}`;
};

export const validarFechaRango = (fechaInicio, fechaFin, diasNotifica) => {
  /* console.log({ diasNotifica }); */
  /* se produce un error al transformar la fecha del formulario a un Date, para corregirlo se inicializa la fecha con 00:00:00 */
  const formatoFechaComprobar = new Date(`${fechaInicio} 00:00:00`);
  const formatoFechaActual = new Date(fechaFin);

  /*   console.log(formatoFechaComprobar);
  console.log(formatoFechaActual); */

  const difference = Math.abs(formatoFechaActual - formatoFechaComprobar);
  const days = difference / (1000 * 3600 * 24);

  if (days <= diasNotifica) {
    return true;
  }
  return false;
};

/* formato de fechas para documentos siniestros */
export const fechasDocumentosSiniestro = () => {
  const fechaActual = new Date();
  const detalleFecha = fechaActual
    .toLocaleString()
    .split(",")[0]
    .split("/")
    .reverse()
    .join("");

  /*  console.log(detalleFecha); */
  return detalleFecha;
};

/* formato para vista en tablas entrada ---> "2022-11-23T16:40:55" ==> "2022-11-23 16:40"  */
export const formatoFechaHora = (fecha) => {
  /* console.log(fecha); */
  if (!fecha) {
    return "";
  }

  const [dbFecha, dbHora] = fecha.split("T");

  /* console.log({ dbFecha, dbHora }); */
  return `${dbFecha} ${dbHora.slice(0, 5)} `;
};

export const sumaDiasFecha = (fecha, dias) => {
  const fechaNueva = new Date(fecha);
  fechaNueva.setDate(fechaNueva.getDate() + dias);

  // Formato personalizado: YYYY-MM-DD
  const dia = fechaNueva.getDate().toString().padStart(2, "0");
  const mes = (fechaNueva.getMonth() + 1).toString().padStart(2, "0");
  const año = fechaNueva.getFullYear();
  /*console.log(`${año}-${mes}-${dia}`);*/
  return `${año}-${mes}-${dia}`;
};
