import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";
import React from "react";
import FormActualizarImei from "../components/FormActualizarImei";
import CabeceraSeguro from "./CabeceraSeguro";

const DetalleActualizacionImei = ({ idCliente, idContrato }) => {
  return (
    <>
      <CabeceraSeguro seguro={idContrato} />
      <FormActualizarImei idCliente={idCliente} bienAsegurado={idContrato} />
    </>
  );
};

export default DetalleActualizacionImei;
