import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import TableBody from "@mui/material/TableBody";

import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/shared/mask/stylesTables";

import { consultarBeneficiarioSiniestro } from "../Services/siniestrosService";

const TablaBeneficiarios = ({ idPagoSiniestro, isUpdating, data }) => {
  const [idSiniestroAux, setidSiniestroAux] = useState(null);
  const [beneficiarios, setBeneficiarios] = useState([]);

  /*   useEffect(() => {
    const getData = async () => {
      try {
        const { state, data } = await consultarBeneficiarioSiniestro(
          idPagoSiniestro.idPagoSiniestro
        );
        console.log({ data });

        if (state) {
          console.log(data);
          setBeneficiarios(data);
        } else {
          setBeneficiarios([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [isUpdating]); */
  useEffect(() => {}, [data]);

  const diccionarioParentesco = (codigoPrentesco) => {
    const codigoParentesco = {
      ABU: "ABUELO(A)",
      AMI: "AMIGO",
      CDO: "CONCUÑADO",
      CN: "CUÑADO(A)",
      COY: "CÓNYUGE",
      HER: "HERMANO(A)",
      HIJ: "HIJO(A)",
      MAD: "MADRE",
      MTR: "MADRASTRA",
      NIE: "NIETO/A",
      NUE: "NUERA",
      OT: "OTRO",
      PAD: "PADRE",
      PRI: "PRIMO(A)",
      PTR: "PADRASTRO",
      SOB: "SOBRINO(A)",
      SUE: "SUEGRO(A)",
      TIO: "TÍO(A)",
      TIT: "TITULAR",
      YER: "YERNO",
    };

    return codigoParentesco[codigoPrentesco];
  };

  const diccionarioIdentificacion = (codigoIdentificacion) => {
    const cogidoIdentificacion = {
      CI: "CEDULA",
      PAS: "PASAPORTE",
      RUC: "RUC",
    };

    return cogidoIdentificacion[codigoIdentificacion];
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Tipo Id</StyledTableCell>
            <StyledTableCell>Identificación</StyledTableCell>
            <StyledTableCell>Apellido</StyledTableCell>
            <StyledTableCell>Nombre</StyledTableCell>

            <StyledTableCell>Parentesco</StyledTableCell>
            <StyledTableCell>Monto</StyledTableCell>
            <StyledTableCell>Porcentaje</StyledTableCell>
            <StyledTableCell>N° Referencia</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((beneficiario, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                {diccionarioIdentificacion(beneficiario.tipoIdentificacion)}
              </StyledTableCell>
              <StyledTableCell>{beneficiario.identificacion}</StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {beneficiario.primerApellido}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {beneficiario.primerNombre}
              </StyledTableCell>
              <StyledTableCell>
                {diccionarioParentesco(beneficiario.parentesco)}
              </StyledTableCell>
              <StyledTableCell>{`${beneficiario.montoPagado} $`}</StyledTableCell>
              <StyledTableCell>{`${beneficiario.porcentaje} %`}</StyledTableCell>

              <StyledTableCell>{beneficiario.numeroReferencia}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TablaBeneficiarios;
