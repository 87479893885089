import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { consultaCatalogosSinPadre } from "../../app-cliente/services/Catalogos/catalogosService";

import TablaBeneficiarios from "./TablaBeneficiarios";

import {
  consultarBeneficiarioSiniestro,
  crearBeneficiarioSiniestro,
} from "../Services/siniestrosService";

import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";
import { NumberFormatPercent } from "app/modules/shared/mask/mascaraPorcentaje";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

const FormBeneficiarioSiniestro = ({ idPagoSiniestro }) => {
  const [loading, setLoading] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [isUpdating, setIsUpdating] = useState(false);
  const [listaBeneficiarios, setListaBeneficiarios] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const { state, data } = await consultarBeneficiarioSiniestro(
          idPagoSiniestro.idPagoSiniestro
        );
        /* console.log({ data }); */

        if (state) {
          /*  console.log(data); */
          setListaBeneficiarios(data);
          setIsLoadingList(false);
        } else {
          setIsLoadingList(false);
          setListaBeneficiarios([]);
        }
      } catch (error) {
        setIsLoadingList(false);
        console.log(error);
      }
    };

    getData();
  }, [isUpdating, idPagoSiniestro]);

  if (isLoadingList) {
    return <SpinnerLoadSrx />;
  }

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const validarPorcentajePago = (porcentajeFormulario) => {
    /* console.log({ listaBeneficiarios }); */
    let valorInicial = 0;

    if (listaBeneficiarios.length === 0) {
      /* console.log("tamano del arreglo  = 0"); */
      valorInicial = 0;
    } else {
      valorInicial = listaBeneficiarios.reduce((accumulador, sigPorcentaje) => {
        /*  console.log(sigPorcentaje); */
        return accumulador + sigPorcentaje.porcentaje;
      }, 0);
    }

    const porcentajeAcumulado =
      Number(valorInicial) + Number(porcentajeFormulario);

    /*  console.log({ porcentajeAcumulado }); */

    if (porcentajeAcumulado > 100) {
      return true;
    } else {
      return false;
    }
  };

  const calculoMontoPagar = (porcentaje) => {
    /* console.log({ porcentaje }); */
    const valorCalculado = (idPagoSiniestro.montoPagado * porcentaje) / 100;
    /* console.log({ porcentaje }); */
    return valorCalculado;
  };

  const configuracionDatosEnvio = async (valores) => {
    validarPorcentajePago();

    /* console.log(valores); */
    setLoading(true);
    const informacionBeneficiarios = {
      idBeneficiarioSiniestro: 0,
      idPagoSiniestro: idPagoSiniestro.idPagoSiniestro,
      tipoIdentificacion: valores.tipoIdentificacion,
      identificacion: valores.identificacion,

      primerApellido: valores.primerApellido.toUpperCase(),
      segundoApellido: valores.segundoApellido.toUpperCase(),
      primerNombre: valores.primerNombre.toUpperCase(),
      segundoNombre: valores.segundoNombre.toUpperCase(),

      parentesco: valores.parentesco,
      porcentaje: Number(valores.porcentaje),
      montoPagado: Number(valores.montoPagado),
      numeroReferencia: valores.numeroReferencia,
      esActivo: true,
    };

    /*  console.log(informacionBeneficiarios); */

    try {
      const respuestaBeneficiario = await crearBeneficiarioSiniestro(
        informacionBeneficiarios
      );
      /* console.log(respuestaBeneficiario); */

      if (respuestaBeneficiario.state) {
        setDatosEnviadosCorrectamente(true);
        setIsUpdating(!isUpdating);

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
        }, 1000);
      } else {
        setLoading(false);
        alert(`${respuestaBeneficiario.data.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <Formik
      initialValues={{
        tipoIdentificacion: "",
        identificacion: "",
        primerApellido: "",
        segundoApellido: "",
        primerNombre: "",
        segundoNombre: "",
        parentesco: "",
        porcentaje: "",
        montoPagado: "",
        numeroReferencia: "",
      }}
      validationSchema={Yup.object({
        tipoIdentificacion: Yup.string().required(
          "Tipo Identificación requerida"
        ),
        identificacion: Yup.string().required("Identificación requerida"),
        primerNombre: Yup.string().required("Nombre requerido"),
        primerApellido: Yup.string().required("Apellido requerido"),
        porcentaje: Yup.string().required("Porcentaje requerido"),
        parentesco: Yup.string().required("Parentesco requerido"),
      })}
      validate={async (values) => {
        const errors = {};

        if (values.porcentaje > 100) {
          errors.porcentaje = "El porcentaje no debe superar el 100%";
        }

        if (validarPorcentajePago(values.porcentaje)) {
          errors.porcentaje = "El porcentaje supera el 100%";
        }

        return errors;
      }}
      onSubmit={(values, { resetForm }) => {
        configuracionDatosEnvio(values);

        setTimeout(() => {
          resetForm({ values: "" });
        }, 1000);
      }}
    >
      {({ values, handleChange, touched, errors, handleBlur }) => (
        <Form>
          <SCSAccordion tituloCabecera="Beneficiarios Siniestro">
            <Grid container spacing={1} rowSpacing={1}>
              <Grid item md={12}>
                <Grid container spacing={1} rowSpacing={1}>
                  <Grid item md={12}>
                    <div className="cliente-informacion-seccion">
                      <SelectItemsCatalogoSinPadre
                        pais="ECU"
                        especial="false"
                        catalogos="TIPOIDEN"
                        grupo={1}
                        name="tipoIdentificacion"
                        labelName="Tipo Identificación"
                        componentSize="14%"
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />

                      <TextField
                        id="identificacion"
                        name="identificacion"
                        type="text"
                        label="Identificación"
                        sx={{ width: "15%" }}
                        value={values.identificacion}
                        onChange={handleChange}
                        size="small"
                        error={
                          touched.identificacion &&
                          Boolean(errors.identificacion)
                        }
                        helperText={
                          touched.identificacion && errors.identificacion
                        }
                        onBlur={handleBlur}
                      />

                      <TextField
                        id="primerApellido"
                        name="primerApellido"
                        type="text"
                        label="Primer Apellido"
                        sx={{ width: "15%" }}
                        value={values.primerApellido}
                        onChange={handleChange}
                        size="small"
                        inputProps={{
                          style: { textTransform: "uppercase" },
                        }}
                        error={
                          touched.primerApellido &&
                          Boolean(errors.primerApellido)
                        }
                        helperText={
                          touched.primerApellido && errors.primerApellido
                        }
                        onBlur={handleBlur}
                      />
                      <TextField
                        id="segundoApellido"
                        name="segundoApellido"
                        type="text"
                        label="Segundo Apellido"
                        sx={{ width: "15%" }}
                        value={values.segundoApellido}
                        onChange={handleChange}
                        size="small"
                        inputProps={{
                          style: { textTransform: "uppercase" },
                        }}
                      />

                      <TextField
                        id="primerNombre"
                        name="primerNombre"
                        type="text"
                        label="Primer Nombre"
                        sx={{ width: "15%" }}
                        value={values.primerNombre}
                        onChange={handleChange}
                        size="small"
                        inputProps={{
                          style: { textTransform: "uppercase" },
                        }}
                        error={
                          touched.primerNombre && Boolean(errors.primerNombre)
                        }
                        helperText={touched.primerNombre && errors.primerNombre}
                        onBlur={handleBlur}
                      />

                      <TextField
                        id="segundoNombre"
                        name="segundoNombre"
                        type="text"
                        label="Segundo Nombre"
                        sx={{ width: "15%" }}
                        value={values.segundoNombre}
                        onChange={handleChange}
                        size="small"
                        inputProps={{
                          style: { textTransform: "uppercase" },
                        }}
                      />
                    </div>

                    <div className="cliente-informacion-seccion">
                      <SelectItemsCatalogoSinPadre
                        pais="ECU"
                        especial="false"
                        catalogos="PARENTESCO"
                        grupo={1}
                        name="parentesco"
                        labelName="Parentesco"
                        componentSize="14%"
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />

                      <TextField
                        id="numeroReferencia"
                        name="numeroReferencia"
                        type="text"
                        label="N° Referencia"
                        value={values.numeroReferencia}
                        onChange={handleChange}
                        size="small"
                      />

                      <TextField
                        id="porcentaje"
                        name="porcentaje"
                        label="Porcentaje"
                        sx={{ width: "15%" }}
                        value={values.porcentaje}
                        onChange={handleChange}
                        size="small"
                        InputProps={{
                          inputComponent: NumberFormatPercent,
                        }}
                        error={touched.porcentaje && Boolean(errors.porcentaje)}
                        helperText={touched.porcentaje && errors.porcentaje}
                        onBlur={handleBlur}
                      />

                      <TextField
                        disabled
                        id="montoPagado"
                        name="montoPagado"
                        label="Monto A Pagar"
                        sx={{ width: "15%" }}
                        value={calculoMontoPagar(values.porcentaje)}
                        onChange={handleChange}
                        size="small"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>

                <div className="confirmar-estados-servicios">
                  <SCSLoadingButton loading={loading} titulo="GUARDAR" />

                  {datosEnviadosCorrectamente && <AcceptServiceresponse />}
                </div>
              </Grid>
            </Grid>

            <div className="cliente-informacion-seccion">
              <TablaBeneficiarios
                idPagoSiniestro={idPagoSiniestro}
                isUpdating={isUpdating}
                data={listaBeneficiarios}
              />
            </div>
          </SCSAccordion>
        </Form>
      )}
    </Formik>
  );
};

export default FormBeneficiarioSiniestro;
