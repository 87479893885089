import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import GestionCobranza from "./GestionCobranza/GestionCobranza";

const CobranzaGestion = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Cobranza - Gestion</h2>
      <GestionCobranza />
    </div>
  );
};

export default CobranzaGestion;
