import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { consultarUsuariosChat } from "app/modules/app-comunicaciones/services/comunicacionesService";

import ChatSideCall from "../ChatSideCall/ChatSideCall";
import ChatSide from "../ChatSide/ChatSide";
import ChatSideContext from "../../context/ChatSideContext";
import "../../styles/main.css";

const ChatSideContainer = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { informacionChatSide, setInformacionChatSide } =
    useContext(ChatSideContext);

  const [connection, setConnection] = useState(null);

  const [usuariosChat, setUsuariosChat] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const newConnection = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_API_CHAT}${encodeURIComponent(authUser.key)}`,
        { headers }
      )
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);

    setInformacionChatSide((args) => ({
      ...args,
      connectionHub: newConnection,
    }));

    return () => {
      console.log("Termino Componente");
      newConnection
        .stop()
        .then(() => {
          console.log("SignalR connection stopped", authUser.key);
        })
        .catch((error) => {
          console.error(`Error stopping SignalR connection: ${error}`);
        });
    };
  }, []);

  useEffect(() => {
    const dataUsuariosChat = async () => {
      try {
        const respuestaUsuariosChat = await consultarUsuariosChat();

        if (respuestaUsuariosChat.state) {
          const arrayUsuariosChat = respuestaUsuariosChat.data
            .toString()
            .split("|");

          setUsuariosChat(arrayUsuariosChat);
          setIsLoading(false);
        } else {
          setUsuariosChat(["999999"]);
          setIsLoading(false);
        }
      } catch (error) {
        setUsuariosChat(["999999"]);
        setIsLoading(false);
        console.log(error);
      }
    };

    dataUsuariosChat();
  }, []);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  return (
    <main
      style={{
        backgroundColor: "#f5f7fa",
        width: "100%",
        position: "relative",
      }}
    >
      {usuariosChat.includes(authUser?.usuarioRol[0]?.idRol.toString()) ? (
        <ChatSideCall />
      ) : (
        <ChatSide />
      )}
    </main>
  );
};

export default ChatSideContainer;
