import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";


import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import FormVisado from "../components/FormVisado";
import FormValidacionInterna from "../components/FormValidacionInterna";
import FormValidacionTelefonica from "../components/FormValidacionTelefonica";
import FormCapacidadPago from "../components/FormCapacidadPago";

import { cambiarEstadoSolicitud, obtenerSolicitud } from "../services/creditoServices";
import { consultaCliente } from "../../app-cliente/services/clienteService";

import "../styles/main.css";
import FormClienteEstadoFinanciero from "../../app-cliente/components/FormClienteEstadoFinanciero";
import CabeceraSolicitud from "../components/CabeceraSolicitud";
import BotonCerrar from "../components/BotonCerrar";
import BoxDescargarSolicitud from "../components/BoxDescargarSolicitud";
import ComportamientoPago from "../components/ComportamientoPago";
import { Alert, Button, IconButton } from "@mui/material";
import handleDownlPdf from "app/utils/document/documentosService";
import { obtenerConsulmax } from "app/modules/app-evaluacion/services/evaluacionServices";
import FormInformacionUbicabilidad from "app/modules/app-cliente/components/FormInformacionUbicabilidad";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import FormClienteReferencias from "app/modules/app-cliente/components/FormClienteReferencias";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";
import FormClienteActividadEconomica from "app/modules/app-cliente/components/FormClienteActividadEconomica";


function TabVerificacionAnalista() {
  const history = useHistory();
  
  const [value, setValue] = useState(0);
  const [idSolicitud, setIdSolicitud] = useState(
    history.location.state.state.id
  );
  const [flujoSol, setflujoSol] = useState(history.location.state.state.flujo);
  const [solicitud, setSolicitud] = useState({});
  const [cliente, setCliente] = useState({});
  const [idCliente, setIdCliente] = useState(-1);
  const [mostrarInfo, setMostrarInfo] = useState(false);

  const [actividadEconomica, setActividadEconomica] = useState(null);
  const [archivoDescargado, setArchivoDescargado] = useState(false);

  useEffect(  () => {
    //console.log(idSolicitud);
    getSolicitud(idSolicitud);
  }, []);

  useEffect(() => {
    // console.log(solicitud.idCliente);
    getCliente(solicitud.identificacion);

    setMostrarInfo(true);
  }, [solicitud]);

  const getSolicitud = async (id) => {
    obtenerSolicitud(id)
      .then((response) => {
        if (response.state) {
          setSolicitud(response.data);
        } else console.log(response.data.message);
      })
      .catch((_errors) => {
        console.log(_errors);
      });
  };

  const getCliente = (id) => {
    consultaCliente(id).then((response) => {
      // console.log(response);
      if (response.state) {
        setCliente(response.data);
        setIdCliente(response.data.idCliente);
      } else {
        console.log(response.data);
      }
    });
    // .catch((_errors) => {alert(_errors); });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const obtenerReporte = async (identificacion) => {
    await obtenerConsulmax(identificacion)
      .then((response) => {
        if (response.state) {
          handleDownlPdf(response.data, `Consulmax${identificacion}`);
          setArchivoDescargado(true);
        }

        setTimeout(() => {
          setArchivoDescargado(false);
        }, 3000);
      })
      .catch();

    console.log(identificacion);
  };

  return (
    <div>
      <BotonCerrar />

      {solicitud.identificacion ? (
        <Box sx={{ width: "100%" }}>
          <Box>
            <CabeceraSolicitud idSolic={idSolicitud} />
          </Box>
          <div>
            <SCSGenericButton
              //className="buscador-botones-centro"
              variant="contained"
              onClick={() => obtenerReporte(solicitud.identificacion)}
              size={15}            
              titulo={"Consulmax"}
            />
            {archivoDescargado && (
              <Alert severity="success" variant="filled">
                Se ha Descargado el Archivo.
              </Alert>
            )}
          </div>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            marginTop="30px"
          >
            <Tabs
              centered
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor="success"
              //value={tab}
              //onChange={(e, newValue) => setTab(newValue)}
            >
              <Tab
                label="Documentos"
                value={0}
                //{...a11yProps(2)}
                className="tabsAnalista"
              />
              <Tab
                label="Ubicabilidad"
                value={1}
                //{...a11yProps(2)}
                className="tabsAnalista"
              />
              {!solicitud.aumentoCupo && (
                <Tab
                  label="Visado"
                  value={2}
                  // {...a11yProps(0)}
                  className="tabsAnalista"
                  //disabled={solicitud.aumentoCupo}
                />
              )}
              <Tab
                label="Estado Financiero"
                value={3}
                //{...a11yProps(3)}
                className="tabsAnalista"
              />
              {solicitud.aumentoCupo && (
                <Tab
                  label="Comportamiento"
                  value={4}
                  //{...a11yProps(4)}
                  className="tabsAnalista"
                />
              )}
              <Tab
                label="Referencias"
                value={5}
                //{...a11yProps(3)}
                className="tabsAnalista"
              />
              <Tab
                label="Capacidad de Pago "
                value={6}
                //{...a11yProps(4)}
                className="tabsAnalista"
              />
              {!solicitud.aumentoCupo && (
                <Tab
                  label="Fuentes de Validación Interna"
                  value={7}
                  //{...a11yProps(1)}
                  className="tabsAnalista"
                  //hidden={solicitud.aumentoCupo}
                />
              )}
              <Tab
                label="Verificación Telefonica"
                value={8}
                //{...a11yProps(2)}
                className="tabsAnalista"
              />
            </Tabs>
          </Box>
          {value === 0 && (
            <BoxDescargarSolicitud
              idSolic={idSolicitud}
              idCliente={idCliente}
              identificacion={solicitud.identificacion}
              solic={solicitud}
            />
          )}
          {value === 1 && (
            <FormInformacionUbicabilidad idCliente={solicitud.idCliente} />
          )}
          {!solicitud.aumentoCupo && value === 2 && (
            <FormVisado
              idSolic={idSolicitud}
              idCliente={idCliente}
              identificacion={solicitud.identificacion}
              solic={solicitud}
            />
          )}
          {value === 3 && (
            <FormClienteActividadEconomica
                idCliente={idCliente}
              />
          )}
          {solicitud.aumentoCupo && value === 4 && (
            <ComportamientoPago
              cliente={cliente}
              idCliente={idCliente}
              cupoPreap
              solic={solicitud}
            />
          )}
          {value === 5 && (
            <FormClienteReferencias idCliente={idCliente} />
          )}
          {value === 6 && (
            <FormCapacidadPago
              cliente={cliente}
              idCliente={idCliente}
              cupoPreap
              solic={solicitud}
            />
          )}
          {!solicitud.aumentoCupo && value === 7 && (
            <FormValidacionInterna idSolic={idSolicitud} solic={solicitud} idCliente={idCliente}/>
          )}
          {value === 8 && (
            <FormValidacionTelefonica idSolic={idSolicitud} solic={solicitud} />
          )}
        </Box>
      ) : (
        <SpinnerLoadSrx />
      )}
    </div>
  );
}

export default TabVerificacionAnalista;
