import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import TitularCitaMedica from "./TitularCitaMedica";
import BeneficiarioCitaMedica from "./BeneficiarioCitaMedica";
import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";
import { Switch } from "@mui/material";
import HeaderBeneficiario from "./HeaderBeneficiario";
import { useEffect } from "react";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const DetalleCrearCitaMedica = ({
  idProductoAux,
  idCliente,
  identificacionCliente,
}) => {
  const [controlTitularBeneficiario, setControlTitularBeneficiario] =
    useState(false);

  const [informacionBeneficiario, setInformacionBeneficiario] = useState(null);

  useEffect(() => {}, [informacionBeneficiario]);

  useEffect(() => {
    if (!controlTitularBeneficiario) {
      setInformacionBeneficiario(null);
    }
  }, [controlTitularBeneficiario]);

  return (
    <div className="agendamiento-container">
      <div className="agendamiento-header">
        <h1>Agendamiento Citas Medicas</h1>
      </div>

      <div className="agendamiento-content-information">
        <HeaderInformacionCliente idCliente={idCliente} />
      </div>

      {informacionBeneficiario && (
        <div className="agendamiento-content-information">
          <HeaderBeneficiario
            informacionBeneficiario={informacionBeneficiario}
          />
        </div>
      )}

      <div className="agendamiento-content-options">
        <h3>Titular</h3>
        <AntSwitch
          defaultChecked
          inputProps={{ "aria-label": "ant design" }}
          checked={controlTitularBeneficiario}
          onChange={() =>
            setControlTitularBeneficiario(!controlTitularBeneficiario)
          }
        />
        <h3>Beneficiario</h3>
      </div>

      {!controlTitularBeneficiario ? (
        <TitularCitaMedica idCliente={idCliente} idProducto={idProductoAux} />
      ) : (
        <BeneficiarioCitaMedica
          idCliente={idCliente}
          idProducto={idProductoAux}
          identificacionCliente={identificacionCliente}
          setInformacionBeneficiario={setInformacionBeneficiario}
        />
      )}
    </div>
  );
};

export default DetalleCrearCitaMedica;
