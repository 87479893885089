import React, { useState } from "react";

import BuscadorPorIdentificador from "app/modules/app-cliente/containers/BuscadorPorIdentificador";

import { consultaCliente } from "../../../app-cliente/services/clienteService";

import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import DetallePagoRecurrencia from "../../containers/DetallePagoRecurrencia";
import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";
import ListaSegurosRecurenciaCliente from "../../containers/ListaSegurosRecurenciaCliente";

const SeguroPagoRecurrencias = () => {
  const [cliente, setCliente] = useState(null);
  const [idCliente, setIdCliente] = useState("");
  const [mostrarDetalle, setMostrarDetalle] = useState(true);
  const [idContratoAux, setIdContratoAux] = useState(null);

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  const buscarExistenciaCliente = async (identificador) => {
    try {
      const respuestaCliente = await consultaCliente(identificador);

      if (respuestaCliente.state) {
        setIdCliente(respuestaCliente.data.idCliente);

        setCliente(respuestaCliente.data);
        setMostrarDetalle(false);
      } else {
        setCliente(nuevoCliente);
        setIdCliente(nuevoIdCliente);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdCliente = (id) => () => {
    buscarExistenciaCliente(id);
  };

  if (mostrarDetalle) {
    return <BuscadorPorIdentificador traerIdCliente={traerIdCliente} />;
  }

  if (idCliente < 0) {
    return (
      <>
        <BotonGerericoSalir />
        <h2>No existe información del cliente!!</h2>
      </>
    );
  }

  const validarEsRecurrencia = (idContrato) => {
    if (!idContrato) {
      return false;
    }
    const includeRecurrencia = idContrato.tipoPago.split(" ");
    return includeRecurrencia.includes("RECURRENCIA") ? true : false;
  };

  return (
    <>
      <BotonGerericoSalir />

      <HeaderInformacionCliente idCliente={idCliente} />

      {idContratoAux ? (
        <>
          {validarEsRecurrencia(idContratoAux) ? (
            <DetallePagoRecurrencia
              idCliente={idCliente}
              idContrato={idContratoAux}
            />
          ) : (
            <h2>Este seguro no admite pagos recurrentes.</h2>
          )}
        </>
      ) : (
        <ListaSegurosRecurenciaCliente
          cliente={cliente}
          idCliente={idCliente}
          setIdContratoAux={setIdContratoAux}
          isCancel={true}
        />
      )}
    </>
  );
};

export default SeguroPagoRecurrencias;
