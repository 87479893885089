import React, { useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";

import { useHistory } from "react-router-dom";

import * as Yup from "yup";

import { Alert, FormControl, MenuItem, TextField } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import { formatoFecha } from "app/modules/shared/validations/validaciones";
import {
  crearCertificadoNotificacion,
  crearInformacionRequerimiento,
} from "../../services/solicitudesService";
import handleDownlPdf from "app/utils/document/documentosService";
import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";
import ModalAlertaNotificacionEstandar from "../ModalAlertaNotificacionEstandar";

const FormDatosEstadoCuenta = ({ solicitud }) => {
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingNotificacion, setLoadingNotificacion] = useState(false);
  const [notification, setNotification] = useState(false);
  const [open, setOpen] = useState(false);

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const configuraionEnvioDatos = async (valores) => {
    setLoading(true);
    const informacionSinDeuda = {
      idRequerimiento: Number(solicitud.idRequerimiento),
      estado: "ESTREQPRO",
      datosRequerimiento: {
        IdDatosRequerimiento: 0,
        IdRequerimiento: Number(solicitud.idRequerimiento),
        Referencia: valores.referencia,
        Fecha: valores.fecha,
        Factura: 0,
        Operacion: 0,
        Plazo: 0,
        Detalle: "",
        EsActivo: true,
      },
    };

    console.log(informacionSinDeuda);

    try {
      const respuestaCertificadoSinDeuda = await crearInformacionRequerimiento(
        informacionSinDeuda
      );

      console.log(respuestaCertificadoSinDeuda);
      if (respuestaCertificadoSinDeuda.state) {
        setDatosEnviadosCorrectamente(true);
        setNotification(true);
        /* handleDownlPdf(
          respuestaCertificadoSinDeuda.data,
          `CRT_ESTADOCUENTA_${
            solicitud.idRequerimiento
          }_${fechasDocumentosSiniestro()}`
        ); */

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
          /* navigate.push("/initialHome"); */
        }, 3000);
      } else {
        alert(`${respuestaCertificadoSinDeuda.message}`);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert(`${error.response.data.message}`);
      console.log(error);
    }
  };

  const solicitarInformacion = async () => {
    setLoadingNotificacion(true);

    try {
      const respuestaCertificadoEstadoDeuda =
        await crearCertificadoNotificacion("", solicitud.idRequerimiento);

      console.log(respuestaCertificadoEstadoDeuda);
      if (respuestaCertificadoEstadoDeuda.state) {
        handleDownlPdf(
          respuestaCertificadoEstadoDeuda.data,
          `CRT_CREDITO_${
            solicitud.idRequerimiento
          }_${fechasDocumentosSiniestro()}`
        );
        setLoadingNotificacion(false);
        setOpen(true);
      } else {
        setLoadingNotificacion(false);
        alert(`${respuestaCertificadoEstadoDeuda.message}`);
      }
    } catch (error) {
      console.log(error);
      setLoadingNotificacion(false);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <div className="accordion-container">
      <Formik
        initialValues={{
          referencia: "",
          fecha: "",
          estado: "",
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        validationSchema={Yup.object({
          fecha: Yup.string().required("Fecha De Emisión requerido"),
          referencia: Yup.string().required("Estado requerido"),
        })}
        onSubmit={(valores, { resetForm }) => {
          configuraionEnvioDatos(valores);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <div /* className="accordion-container" */>
              <div className="cliente-informacion-seccion">
                <TextField
                  id="fecha"
                  name="fecha"
                  type="date"
                  label="Fecha de Emisión"
                  sx={{ width: "25%" }}
                  onChange={handleChange}
                  size="small"
                  value={formatoFecha(values.fecha)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={touched.fecha && Boolean(errors.fecha)}
                  helperText={touched.fecha && errors.fecha}
                  onBlur={handleBlur}
                />

                <FormControl
                  sx={{ width: "25%" }}
                  error={touched.referencia && Boolean(errors.referencia)}
                  helperText={touched.referencia && errors.referencia}
                  onBlur={handleBlur}
                >
                  <InputLabel id="referencia">Estado</InputLabel>

                  <Field
                    name="referencia"
                    label="referencia"
                    component={selectPersonalizarPropiedades}
                  >
                    <MenuItem value="Al Dia">Al Día</MenuItem>
                    <MenuItem value="Vencido">Vencido</MenuItem>
                  </Field>
                  <ErrorMessage
                    name="referencia"
                    component={() => (
                      <FormHelperText>{errors.referencia}</FormHelperText>
                    )}
                  />
                </FormControl>

                <LoadingButton
                  sx={{ width: "20%" }}
                  type="submit"
                  size="small"
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                >
                  GUARDAR
                </LoadingButton>

                {notification && (
                  <LoadingButton
                    sx={{ width: "20%" }}
                    type="button"
                    size="small"
                    loading={loadingNotificacion}
                    loadingPosition="end"
                    variant="contained"
                    onClick={solicitarInformacion}
                  >
                    NOTIFICAR
                  </LoadingButton>
                )}

                {datosEnviadosCorrectamente && (
                  <Alert severity="success" variant="filled">
                    Los datos han sido enviados correctamente.
                  </Alert>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ModalAlertaNotificacionEstandar
        setOpen={setOpen}
        open={open}
        titulo="Solicitud Estado De Deuda"
        mensaje="La solicitud ha sido generada con exito y enviada al correo!!"
      />
    </div>
  );
};

export default FormDatosEstadoCuenta;
