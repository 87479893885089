import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import FormCancelacionSeguros from "../components/FormCancelacionSeguros";
import CancelacionSeguros from "./CancelacionSeguros";
import CertificadoCredito from "./CertificadoCredito";
import Precancelacion from "./Precancelacion";
import DevolucionProductos from "./DevolucionProductos";
import CambioEstado from "./CambioEstado";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const IngresoSolicitudes = ({ idCliente, idLocal, identificacion }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <BotonGerericoSalir />

      <HeaderInformacionCliente idCliente={idCliente} />

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className="cabecera-tabs-clientes"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="Certificado de Credito" {...a11yProps(0)} />
            <Tab label="Cancelacion de Seguros" {...a11yProps(1)} />
            <Tab label="Devolución de Productos" {...a11yProps(2)} />
            <Tab label="Precancelación" {...a11yProps(3)} />
            <Tab
              label="Cambio Estado
            "
              {...a11yProps(4)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {/* <h2>Certificado de Credito</h2> */}
          <CertificadoCredito idCliente={idCliente} idLocal={idLocal} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <h2>Lista Seguros</h2>
          <CancelacionSeguros idCliente={idCliente} idLocal={idLocal} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* <h2>Devolución de Productos</h2> */}
          <DevolucionProductos idCliente={idCliente} idLocal={idLocal} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          {/* <h2>Precancelación</h2> */}
          <Precancelacion
            idCliente={idCliente}
            idLocal={idLocal}
            identificacion={identificacion}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          {/* <h2>Cambio Estado</h2> */}
          <CambioEstado
            idCliente={idCliente}
            idLocal={idLocal}
            identificacion={identificacion}
          />
        </TabPanel>
      </Box>
    </div>
  );
};

export default IngresoSolicitudes;
