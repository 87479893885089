import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import React from "react";
import FormDatosEstadoCuenta from "../../components/FormsDatosRequerimiento/FormDatosEstadoCuenta";
import ListaCertificadosCredito from "../../containers/ListaCertificadosCredito";

const SolicitudCredito = () => {
  return (
    <>
      <BotonGerericoSalir />

      <ListaCertificadosCredito />
    </>
  );
};

export default SolicitudCredito;
