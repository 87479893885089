import React, { useEffect, useState } from "react";

import BuscadorPorKeyUsuario from "../../Containers/BuscadorPorKeyUsuario";
import DetalleInformacionUsuario from "../../Containers/DetalleInformacionUsuario";

import { obtenerUsuario } from "../../Service/usuariosService";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";

const UsuarioDesbloqueo = () => {
  const [usuario, setUsuario] = useState(null);
  const [idUsuario, setIdUsuario] = useState(null);
  const [mostrarDetalle, setMostrarDetalle] = useState(false);
  const [keyUsuario, setKeyUsuario] = useState(0);

  const nuevoUsuario = {};
  const nuevoIdUsuario = -1;

  useEffect(() => {
    console.log(usuario);
  }, []);

  if (idUsuario < 0) {
    return (
      <>
        <BotonGerericoSalir />
        <h2>No existe información del Usuario!!</h2>
      </>
    );
  }

  const buscarExistenciaUsuario = async (keyUser) => {
    console.log(keyUser);
    
    try {
      const respuestaUsuario = await obtenerUsuario(keyUser);

      if (respuestaUsuario.data != null) {
        console.log(respuestaUsuario);
        setIdUsuario(respuestaUsuario.data.idUsuario);
        setUsuario(respuestaUsuario.data);
        setMostrarDetalle(false);
      } else {
        setUsuario({ keyUsuario: keyUser });
        setIdUsuario(nuevoIdUsuario);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdUsuario = (keyUsuario) => () => {
    setKeyUsuario(keyUsuario);
    buscarExistenciaUsuario(keyUsuario);
  };

  return (
    <div>
      {idUsuario && usuario ? (
        <DetalleInformacionUsuario
          idUsuario={idUsuario}
          usuario={usuario}
          keyUsuario={keyUsuario}
        />
      ) : (
        <BuscadorPorKeyUsuario traerIdUsuario={traerIdUsuario} />
      )}
    </div>
  );
};

export default UsuarioDesbloqueo;
