import http from "../../../comunication/http-common";

//http://10.10.0.179:5000/cuenta/ObtenerPDFEstado?documento=9_202307.pdf
export const consultarPDFEstado = async (documento) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}cuenta/ObtenerPDFEstado?documento=${documento}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5018/transaccional/ObtenerCuotaGarantia?tipoConsumo=79&plazo=12&valor=100&idLocal=6068
export const obtenerCuotaGarantia = async (
  tipoConsumo,
  plazo,
  valor,
  idLocal
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}transaccional/ObtenerCuotaGarantia?tipoConsumo=${tipoConsumo}&plazo=${plazo}&valor=${valor}&idLocal=${idLocal}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/*  TRANSACCIONAL SISTEMA CLIENTE */
//http://10.10.0.79:5000/catalogo/ObtenerGrupo?dato=&tipo=4
export const consultarGrupo = async (dato, tipo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ObtenerGrupo?dato=${dato}&tipo=${tipo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5000/transaccional/ObtenerCabeceraTrx?identificacion=0911555472&idGrupo=3
export const consultarCabeceraTsx = async (identificacion, grupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}transaccional/ObtenerCabeceraTrx?identificacion=${identificacion}&idGrupo=${grupo}`;

    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

//http://10.10.0.179:5000/consumo/ListarAutorizacionesXCuenta?identificacion=0911555472&idCuenta=15
export const consultarListaTransaccional = async (identificacion, idCuenta) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}consumo/ListarAutorizacionesXCuenta?identificacion=${identificacion}&idCuenta=${idCuenta}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5018/transaccional/ObtenerAmortizacion?idAutorizacion=39
export const consultarTablaAmortizacionXAutorizacion = async (
  idAutorizacion
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}transaccional/ObtenerAmortizacion?idAutorizacion=${idAutorizacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5018/consumo/ListarAutorizacionesXCuenta?idCuenta=91
export const consultarListaTransaccionalXCuenta = async (idCuenta, filas) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}consumo/ListarAutorizacionesXCuenta?idCuenta=${idCuenta}&filas=${filas}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5000/cuenta/ObtenerEstadosXCuenta?idCuenta=15
export const consultarEstadosCuenta = async (idCuenta) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}cuenta/ObtenerEstadosXCuenta?idCuenta=${idCuenta}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5000/Cuenta/ObtenerMovimientosXCuenta?idCuenta=15
export const consultarMovimientosCliente = async (cuenta) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}Cuenta/ObtenerMovimientosXCuenta?idCuenta=${cuenta}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//https://10.10.0.79:5001/Solicitud/ObtenerDatosSolicitud?Identificacion=%200101430676&IdGrupo=1
export const consultarDatosSolicitud = async (identificacion, grupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}Solicitud/ObtenerDatosSolicitud?Identificacion=${identificacion}&IdGrupo=${grupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5018/Cuenta/ObtenerPagosCliente?identificacion=1708063340&idGrupo=3&filas=9
export const consultarPagos = async (identificacion, grupo, filas) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}Cuenta/ObtenerPagosCliente?identificacion=${identificacion}&idGrupo=${grupo}&filas=${filas}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//https://10.10.0.79:5001/Transaccional/ObtenerPagosTransaccional?Identificacion=%200101430676&IdGrupo=1
export const consultarPagosTransaccional = async (identificacion, grupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}Transaccional/ObtenerPagosTransaccional?Identificacion=${identificacion}&IdGrupo=${grupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5000/Cuenta/ObtenerPagosXCuenta?idCuenta=15&filas=15
export const consultarPagosCliente = async (cuenta, filas) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}Cuenta/ObtenerPagosXCuenta?idCuenta=${cuenta}&filas=${filas}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//https://10.10.0.79:5001/Seguro/ObtenerDatosSeguro?Identificacion=%200101430676&IdGrupo=1
export const consultarDatosSeguro = async (identificacion, grupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}Seguro/ObtenerDatosSeguro?Identificacion=${identificacion}&IdGrupo=${grupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//https://10.10.0.79:5001/Transaccional/ObtenerEstadoCredito?Identificacion=1713125555&IdGrupo=3
export const consultarEstadoCredito = async (identificacion, grupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}Transaccional/ObtenerEstadoCredito?Identificacion=${identificacion}&IdGrupo=${grupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//https://10.10.0.79:5001/Transaccional/ObtenerEstadoCredito?Identificacion=1713125555&IdGrupo=3
export const consultarConsumosCredito = async (identificacion, grupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}Transaccional/ObtenerConsumos?Identificacion=${identificacion}&IdGrupo=${grupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5018/cuenta/ObtenerValorDeuda?identificacion=1724490287&idLocal=6000
export const obtenerValoresDeudaRefinanciamiento = async (
  identificacion,
  local
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}Cuenta/ObtenerValorDeuda?identificacion=${identificacion}&idLocal=${local}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const obtenerNotasCreditoCliente = async (identificacion, grupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}Cuenta/ObtenerNotasCreditoCliente?Identificacion=${identificacion}&IdGrupo=${grupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5012/catalogo/obtenerConsDescripcion?objeto=OFICDEF&identificador=3
export const consultaParametroLocalXGrupo = async (idGrupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/obtenerConsDescripcion?objeto=OFICDEF&identificador=${idGrupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
        /*  console.log(response.data); */
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5018/transaccional/ObtenerValorExoneracion?identificacion=1724490287&idLocal=6000
export const consultaValoresCondonacion = async (identificacion, local) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}transaccional/ObtenerValorExoneracion?identificacion=${identificacion}&idLocal=${local}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
        /*  console.log(response.data); */
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5011/evaluacion/ObtenerInformacionSiniestro?Identificacion=0500538988&IdGrupo=1
export const consultaSiniestrosTrx = async (identificacion, idGrupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_EVALUACION}evaluacion/ObtenerInformacionSiniestro?identificacion=${identificacion}&idGrupo=${idGrupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
        /*  console.log(response.data); */
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5011/evaluacion/ObtenerInformacionQueja?Identificacion=0500538988
export const consultaQuejasTrx = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_EVALUACION}evaluacion/ObtenerInformacionQueja?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
        /*  console.log(response.data); */
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const registrarCambioCiclo = async (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}cuenta/RegistrarCambioCiclo`;

    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

/* POST SERVICE */
//http://10.10.0.79:5011/evaluacion/GrabarBloqueoClienteDetalle
export const grabarBloqueoOrquestacion = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}evaluacion/GrabarBloqueoClienteDetalle`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const grabarNotaCredito = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}cuenta/GuardarNotaCredito`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const grabarNotaDebito = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}cuenta/GuardarNotaDebito`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://10.10.0.179:5018/transaccional/GuardarExoneracion
export const crearCondonacion = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}transaccional/GuardarExoneracion`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5018/transaccional/GuardarExoneracion

//
export const crearRefinanciamiento = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}cuenta/RegistrarRefinanciamiento`;

    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5018/transaccional/GuardarExoneracion
export const notificarEstadoCuenta = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_NOTIFICAR}EnvioNotificacion/Notificar`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5011/cuenta/SimularRefinanciamiento
export const consultaSimuladorRefinanciamientos = async (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}cuenta/SimularRefinanciamiento`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const pagarCuenta = async (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_TRANSACCIONAL}cuenta/PagarCuenta`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
