import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Alert, LoadingButton } from "@mui/lab";
import { desbloquearUsuarioService } from "../Service/usuariosService";
import ModalInformacionDesbloqueo from "./ModalInformacionDesbloqueo";
import ModalAlertaNotificacionEstandar from "app/modules/app-servicios/components/ModalAlertaNotificacionEstandar";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";

const BotonDesbloquearUsuario = ({ usuario }) => {
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [openDesbloqueo, setOpenDesbloqueo] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);

  const desbloquearUsuario = async () => {
    setLoading(true);

    if (usuario.estado !== "BLOQUEADO") {
      setOpen(true);
      setLoading(false);
      return;
    }

    try {
      const respuestaUsuarios = await desbloquearUsuarioService(
        usuario.idUsuario,
        authUser.oficina,
        authUser.key,
        authUser.estacion
      );
      console.log(respuestaUsuarios);

      if (respuestaUsuarios.state) {
        setOpenDesbloqueo(true);
        setLoading(false);
      } else {
        alert(`${respuestaUsuarios.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      alert(`${error.response.data.message}`);
      setLoading(false);
    }
  };

  const mensajeAlerta = (
    <>
      El Usuario no se encuentra <strong> BLOQUEADO</strong> !!
    </>
  );

  const mensajeAlertaConfirmacion = (
    <>
      El usuario <strong> {usuario?.key} </strong> ha sido
      <strong> DESBLOQUEADO </strong>con Exito !!
    </>
  );

  return (
    <div>
      <SCSLoadingButton
        loading={loading}
        titulo="DESBLOQUEAR"
        onClick={() => desbloquearUsuario()}
      />

      <ModalAlertaNotificacionEstandar
        open={openDesbloqueo}
        setOpen={setOpenDesbloqueo}
        titulo="Confirmación Desbloqueo"
        mensaje={mensajeAlertaConfirmacion}
      />

      <ModalInformacionDesbloqueo
        open={open}
        setOpen={setOpen}
        titulo="ALERTA"
        mensaje={mensajeAlerta}
      />
    </div>
  );
};

export default BotonDesbloquearUsuario;
