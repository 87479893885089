import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ContentLoader from '../../../../@jumbo/components/ContentLoader';
import { useOnValidateOTP,useOnActualizarOTP } from '../hook/index'
import { Card, CardContent, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { alpha } from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import * as yup from 'yup';
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";


const validationSchema = yup.object({
  password: yup
      .string('Ingrese Clave Temporal')
      .max(4, 'Excede longitud máxima')
      .required('Clave es requerido'),
  
});

const Otp = () => {

 // const { authUser, code } = useSelector(({ auth }) => auth);
  //const history = useHistory();
  const [jsonPayload, setJsonPayload] = useState({});
  const [sendValidateOtp, setSendValidateOtp] = useState(false);
  useOnValidateOTP(jsonPayload, sendValidateOtp, setSendValidateOtp);


  const [jsonOtpload, setJsonOtpload] = useState({});
  const [sendActualizarOtp, setSendActualizarOtp] = useState(false);
  useOnActualizarOTP(jsonOtpload, sendActualizarOtp, setSendActualizarOtp);

  
  const onSignIn = async   (password) => {
   let user = JSON.parse( sessionStorage.getItem('user'));
    const payload = {
      userCode: user.key,
      password: password,
    };
    setJsonPayload(payload);
    setSendValidateOtp(true);
  };
  
  const handleCancel = () =>{
    let user = JSON.parse( sessionStorage.getItem('user'));
    const payload = {
      userCode: user.key,
      sistema: process.env.REACT_APP_SISTEMA,
    };
    setJsonOtpload(payload);
    setSendActualizarOtp(true);
  }



  return (
    <Div sx={{
      flex: 1,
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      p: theme => theme.spacing(4),
    }}>

      <Card sx={{ maxWidth: '100%', width: 360, mb: 4 }}>
        <Div sx={{ position: 'relative', height: '200px' }}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="200"
            image={`${ASSET_IMAGES}/logo-login.jpg`}
          />
          <Div
            sx={{
              flex: 1,
              inset: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: theme => alpha(theme.palette.common.black, .5),
              p: theme => theme.spacing(3),
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                color: 'common.white',
                fontSize: '1.5rem',
                mb: 0
              }}
            >
              Ingrese Clave Temporal
            </Typography>
          </Div>
        </Div>
        <CardContent sx={{ pt: 0 }}>
          <Formik
                validateOnChange={true}
                initialValues={{
                    password: ''
                }}
                validationSchema={validationSchema}
                onSubmit={(data, {setSubmitting}) => {
                    setSubmitting(true);
                    onSignIn(data.password);
                    setSubmitting(false);
                }}
            >
                {({isSubmitting}) => (
                    <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                        <Div sx={{mb: 3, mt: 1}}>
                            <JumboTextField
                                fullWidth
                                name="password"
                                label="Clave temporal"
                                type="password"
                            />
                        </Div>
                        
                        <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{mb: 3}}
                            loading={isSubmitting }
                        >Validar Clave</LoadingButton>
                      <LoadingButton
                                 color="secondary"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    onClick={() => handleCancel()}
                                >Reenviar Clave</LoadingButton>

                    </Form>
                )}
            </Formik>
            <ContentLoader />
        </CardContent>
      </Card>

    </Div>
  );
};

export default Otp;
