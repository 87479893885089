import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import QuejasCerrar from "./QuejasCerrar/QuejasCerrar";
import BotonSalirLimiarStorageQuejas from "../containers/BotonSalirLimiarStorageQuejas";

const CerrarQueja = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Cerrar - Queja</h2>
      <QuejasCerrar />
    </div>
  );
};

export default CerrarQueja;
