import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import CabeceraChatSide from "../../components/CabeceraChatSide";
import DetalleChatSideContainer from "../../containers/DetalleChatSideContainer";
import ChatSideContext from "../../context/ChatSideContext";
import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { makeStyles } from "@mui/styles";

const useStylesButtonColapse = makeStyles((theme) => ({
  buttonColapse: {
    position: "absolute",
    right: "10px",
    top: "5rem",
    background: "white",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
    zIndex: "9999",
    "&:hover": {
      background: "white",
    },
  },
}));

const ChatSide = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { informacionChatSide, setInformacionChatSide } =
    useContext(ChatSideContext);
  const styleButtonColapse = useStylesButtonColapse();

  useEffect(() => {
    setInformacionChatSide((args) => ({ ...args, isCallCenter: false }));
  }, []);

  const handleReturnConversaciones = () => {
    setInformacionChatSide((args) => ({
      ...args,
      changeSection: false,
      mostrarCabecera: false,
    }));
  };

  return (
    <main className="chatSide-header">
      <section
        style={{
          position: "fixed",
          minWidth: "240px",
          maxWidth: "240px",
          zIndex: 9999,
        }}
      >
        <CabeceraChatSide nombre={authUser.key} emisor={true} />

        {informacionChatSide.mostrarCabecera && (
          <>
            <CabeceraChatSide
              nombre={informacionChatSide.clienteSeleccionado.nombreEmisor}
              emisor={false}
            />

            <IconButton
              className={styleButtonColapse.buttonColapse}
              aria-label="calapse-right"
              size="large"
              onClick={handleReturnConversaciones}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          </>
        )}
      </section>

      <section className="chatSide-container">
        <DetalleChatSideContainer />
      </section>
    </main>
  );
};

export default ChatSide;
