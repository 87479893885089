import React, { useEffect } from "react";
import { Box, Paper } from "@mui/material";
import { useResolutionScreen } from "../hooks/useResolutionScreen";

const DetalleOpcionGenerica = ({ mensaje }) => {
  const { alturaResolucion } = useResolutionScreen();

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "nowrap",
        "& > :not(style)": {
          m: 1,
          width: "100%",
          height: `${alturaResolucion}px`,
          margin: "0px",
          borderRadius: "32px",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Paper elevation={0} className="detalleOpciones-container">
        <h3>{mensaje}</h3>
      </Paper>
    </Box>
  );
};

export default DetalleOpcionGenerica;
