import { Card } from "@mui/material";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { formatoFecha } from "app/modules/shared/validations/validaciones";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { consultarCabeceraSeguros } from "../services/solicitudesService";

const CabeceraSeguroService = ({ idContrato, esPagoRecurrencias = false }) => {
  const [seguro, setSeguro] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log({ idContrato });

    const getData = async () => {
      try {
        const respuestaCabeceraSeguro = await consultarCabeceraSeguros(
          idContrato
        );

        console.log({ respuestaCabeceraSeguro });

        if (respuestaCabeceraSeguro.state) {
          setSeguro(respuestaCabeceraSeguro.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          alert(`${respuestaCabeceraSeguro.message}`);
        }
      } catch (error) {
        setIsLoading(false);
        alert(`${error.response.data.message}`);
        console.log(error);
      }
    };
    getData();
  }, [idContrato]);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  return (
    <div className="header-client-container">
      <Card>
        <header className="header-client-information">
          <div>
            <h2>Seguro</h2>
          </div>
          <div>
            <h4>Número Contrato</h4>
            <h4>{seguro.numContrato ? seguro.numContrato : ""}</h4>
          </div>
          <div>
            <h4>Producto</h4>
            <h4>{seguro.producto}</h4>
          </div>
          <div>
            <h4>Tipo Pago</h4>
            <h4>{seguro.tipoPago}</h4>
          </div>
          <div>
            <h4>Fecha Inicio</h4>
            <h4>
              {seguro.fechaInicio ? formatoFecha(seguro.fechaInicio) : ""}
            </h4>
          </div>
          <div>
            <h4>Estado</h4>
            <h4>{seguro.estado}</h4>
          </div>
          {esPagoRecurrencias && (
            <div>
              <h4>Valor</h4>
              <h4>{`${seguro.valor} $`}</h4>
            </div>
          )}
        </header>
      </Card>
    </div>
  );
};

export default CabeceraSeguroService;
