import React, { useState } from "react";

import BuscadorPorIdentificador from "../../../app-cliente/containers/BuscadorPorIdentificador";

import { consultaCliente } from "../../../app-cliente/services/clienteService";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import ListaRequerimientosCliente from "../../containers/ListaRequerimientosCliente";

const SolicitudRequerimientoCliente = () => {
  const [cliente, setCliente] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [mostrarDetalle, setMostrarDetalle] = useState(false);
  const [identificacion, setIdentificacion] = useState("");

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  if (idCliente < 0) {
    return (
      <>
        <BotonGerericoSalir />
        <h2>No existe información del cliente!!</h2>
      </>
    );
  }

  const buscarExistenciaCliente = async (identificador) => {
    try {
      const respuestaCliente = await consultaCliente(identificador);

      console.log(respuestaCliente);
      if (respuestaCliente.state) {
        setIdCliente(respuestaCliente.data.idCliente);
        setCliente(respuestaCliente.data);
        setIdentificacion(respuestaCliente.data.identificacion);
        setMostrarDetalle(false);
      } else {
        setCliente(nuevoCliente);
        setIdCliente(nuevoIdCliente);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdCliente = (id) => () => {
    console.log(id);
    buscarExistenciaCliente(id);
  };

  return (
    <>
      {idCliente ? (
        <ListaRequerimientosCliente
          cliente={cliente}
          idCliente={idCliente}
          identificacion={identificacion}
        />
      ) : (
        <BuscadorPorIdentificador traerIdCliente={traerIdCliente} />
      )}
    </>
  );
};

export default SolicitudRequerimientoCliente;
