import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";

import { consultarGestionQueja } from "../services/gestionService";
import { formatoFechaHora } from "app/modules/shared/commonValues/obtenerFecha";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SeguimientoQuejasTabla = ({ idQueja, isUpdating }) => {
  const [gestionQueja, setGestionQueja] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaGestionQueja = await consultarGestionQueja(idQueja);

        if (respuestaGestionQueja.state) {
          setGestionQueja(respuestaGestionQueja.data);
        } else {
          setGestionQueja([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [isUpdating]);

  return (
    <div>
      <TableContainer component={Paper} sx={{ width: "100%" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Fecha</StyledTableCell>
              <StyledTableCell>Responsable</StyledTableCell>
              <StyledTableCell>Acción</StyledTableCell>
              <StyledTableCell align="center">Comentario</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gestionQueja.map((queja) => (
              <StyledTableRow key={queja.idGestionQueja}>
                <StyledTableCell>
                  {formatoFechaHora(queja.fechaCreacion)}
                </StyledTableCell>
                <StyledTableCell>{queja.responsable}</StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {queja.accion}
                </StyledTableCell>
                <StyledTableCell>{queja.comentario}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SeguimientoQuejasTabla;
