import React, { useEffect, useState } from "react";

import { Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { consultaListaBienesAsegurados } from "../services/SeguroService/seguroService";
import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/shared/mask/stylesTables";

const ListaBienesAsegurados = ({ idCliente, setIdContratoAux }) => {
  const [segurosCliente, setSegurosCliente] = useState([]);

  useEffect(() => {
    const getSeguros = async () => {
      try {
        const respuestaSegurosCliente = await consultaListaBienesAsegurados(
          idCliente
        );

        console.log(respuestaSegurosCliente);

        if (respuestaSegurosCliente.state) {
          setSegurosCliente(respuestaSegurosCliente.data);
        } else {
          setSegurosCliente([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getSeguros();
  }, []);

  const escogerIdContrato = (contrato) => {
    setIdContratoAux(contrato);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ width: "100%" }}>
        <Table
          sx={{ width: "100%" }}
          aria-label="customized table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell width="5%">N° Contrato</StyledTableCell>
              <StyledTableCell align="center" width="10%">
                Producto
              </StyledTableCell>
              <StyledTableCell align="center" width="15%">
                Fecha de Inicio
              </StyledTableCell>
              <StyledTableCell align="center" width="10%">
                Fecha de Fin
              </StyledTableCell>
              <StyledTableCell align="center" width="10%">
                Descripcion Bien
              </StyledTableCell>
              <StyledTableCell align="center" width="10%">
                Valor Bien
              </StyledTableCell>
              <StyledTableCell align="center" width="10%">
                Gestión
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {segurosCliente.map((contratos) => (
              <StyledTableRow key={contratos.idContrato}>
                <StyledTableCell component="th" scope="row">
                  {contratos.numContrato}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {contratos.producto}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {contratos.fechaInicio}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {contratos.fechaFin}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {contratos.descripcionBien}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {contratos.valorBien} $
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Button
                    variant="contained"
                    onClick={() => escogerIdContrato(contratos)}
                    endIcon={<SendIcon />}
                  >
                    Gestionar
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ListaBienesAsegurados;
