import React, { useEffect, useState } from "react";

import { Card } from "@mui/material";
import { consultarColaboradorListaNegra } from "../services/solicitudesService";
import IconoSemaforoListaNegra from "../components/IconoSemaforoListaNegra";
import CabeceraInformacionColaborador from "../components/ListasNegras/CabeceraInformacionColaborador";
import { useSelector } from "react-redux";

const TablaInformacionListaNegra = ({ identificacion }) => {
  const [infoColaborador, setInfoColaborador] = useState({
    state: false,
    data: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const { authUser } = useSelector(({ auth }) => auth);

  console.log(identificacion, authUser.key, authUser.estacion);

  useEffect(() => {
    console.log(identificacion);
    const getInfoColaborador = async () => {
      try {
        const respuestaInformacionColaborador =
          await consultarColaboradorListaNegra(identificacion, authUser.key, authUser.estacion);
          

        if (respuestaInformacionColaborador.state) {
          if (respuestaInformacionColaborador.data) {
            setInfoColaborador((args) => ({
              ...args,
              state: true,
              data: respuestaInformacionColaborador.data,
            }));
          } else {
            setInfoColaborador((args) => ({
              ...args,
              state: false,
              data: { tipoBloqueo: "NADA" },
            }));
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    getInfoColaborador();
  }, [identificacion]);

  if (isLoading) {
    return <h2>Cargando !!!</h2>;
  }

  return (
    <div>
      {infoColaborador.state ? (
        <div className="header-client-container">
          <Card>
            <header className="header-client-information">
              <div>
                <IconoSemaforoListaNegra
                  state={infoColaborador.data?.tipoBloqueo}
                />
              </div>
              <div>
                <h4>Nombre</h4>
                <h4> {infoColaborador.data?.nombre}</h4>
              </div>
              <div>
                <h4>Razon</h4>
                <h4> {infoColaborador.data?.razon}</h4>
              </div>

              <div>
                <h4>Origen</h4>
                <h4>{infoColaborador.data?.origen}</h4>
              </div>
              <div>
                <h4>Tiempo Laboral</h4>
                <h4>{`${
                  infoColaborador.data?.tiempoLaboral
                    ? infoColaborador.data?.tiempoLaboral
                    : ""
                }  meses`}</h4>
              </div>
            </header>
          </Card>
        </div>
      ) : (
        <CabeceraInformacionColaborador infoColaborador={infoColaborador} />
      )}
    </div>
  );
};

export default TablaInformacionListaNegra;
