import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import GestionPrestadoresMedicos from "./GestionPrestadoresMedicos/GestionPrestadoresMedicos";

const PrestadoresMedicosGestion = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Gestión - Prestadores Medicos</h2>
      <GestionPrestadoresMedicos />
    </div>
  );
};

export default PrestadoresMedicosGestion;
