import React from "react";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";

import HeaderReporte from "../../containers/HeaderReporte";
import BotonConsultaGestionRequerimiento from "../../components/BotonConsultaGestionRequerimiento";
import BotonConsultaBloqueoColaborador from "../../components/BotonConsultaBloqueColaborador";

const ReporteRequerimiento = () => {
  return (
    <>
      <BotonGerericoSalir />
      <div className="header-reporte-detalle">
        <HeaderReporte title="Reporte Gestión Requerimiento" />
      </div>
      <div className="reportes-container">
        <BotonConsultaGestionRequerimiento />
        
        <BotonConsultaBloqueoColaborador />
      </div>
    </>
  );
};

export default ReporteRequerimiento;
