import { consultaValidacionData } from "../services/sheredServices";

export const formatoFecha = (fecha) => {
  if (!fecha) {
    return "";
  }
  const fechaModificada = fecha.split("T");
  return fechaModificada[0];
};

export const formatoFechaEspacio = (fecha) => {
  if (!fecha) {
    return "";
  }
  const fechaModificada = fecha.split(" ");
  return fechaModificada[0];
};

export const validarFechaMayorMenor = (fechaInicio, fechaFin, parametro) => {
  const formatoFechaComprobar = new Date(`${fechaInicio} 00:00:00`);
  const formatoFechaActual = new Date(fechaFin);

  /* console.log(formatoFechaComprobar);
  console.log(formatoFechaActual); */

  /*   if (parametro === "MAYOR") {
      console.log("entra a mayor");
      if (formatoFecha1 > formatoFecha2) {
        console.log("la fecha de inicio es mayor que la final");
      }
      console.log("la fecha de inicio es mayor que la final");
    } */

  if (parametro === "MENOR") {
    /* console.log("entra a menor"); */
    if (formatoFechaComprobar <= formatoFechaActual) {
      /* console.log("la fecha a comprobar es menor a la fecha actual"); */
      return true;
    }
    /* console.log("la fecha a comprobar es mayor a la fecha actual"); */
    return false;
  }
  return false;
};

export const validarFechaMayor = (fechaInicio, fechaFin, parametro) => {
  if (fechaInicio === "") {
    return false;
  }
  const formatoFechaComprobar = new Date(fechaInicio).toISOString();
  const formatoFechaActual = new Date(fechaFin).toISOString();

  const fechaAux = formatoFechaActual.split("T");
  const formatoFechaActual2 = new Date(fechaAux[0]).toISOString();

  if (parametro === "MAYOR") {
    /* console.log("entra a mayor"); */
    if (formatoFechaComprobar >= formatoFechaActual2) {
      /* console.log("la fecha a comprobar es menor a la fecha actual"); */
      return false;
    }
    /* console.log("la fecha a comprobar es mayor a la fecha actual"); */
    return true;
  }
  return true;
};

export const validarCelular = (celular) => {
  /* console.log(celular); */
  /* tipo de dato celular -> string  */
  const primerNumero = celular.slice(0, 2);
  /* console.log({ primerNumero }); */
  if (celular.length === 10) {
    if (primerNumero !== "09") {
      return true;
    }
  }
  return false;
};

export const validarTelefono = (telefono) => {
  const primerNumero = telefono.slice(0, 1);

  if (telefono.length === 9) {
    if (primerNumero !== "0") {
      return true;
    }
  }
  return false;
};

export const validarIngresoTexto = (texto) => {
  const regex = /^[a-zA-Z0-9]+(?:[- ]+[a-zA-Z0-9]+)*(?!\s)$/g;
  const validation = regex.test(texto);

  return validation;
};

export const obtenerHoraDeFecha = (fechaConHora) => {
  const fechaSoloHora = fechaConHora.split("T");

  const arrayHoras = fechaSoloHora[1].split(":");

  const horaYminutos = `${arrayHoras[0]}:${arrayHoras[1]} `;

  return horaYminutos;
};

/**
 * Validate Contacto
 * @param {string} contacto
 * @param {string} tipo CEL-CON-COR
 * @returns {boolean}
 */
export const validarFalsaData = async (contacto, tipo) => {
  let res;

  try {
    const respuestaValidacionFalsa = await consultaValidacionData(
      contacto,
      tipo
    );

    //console.log(respuestaValidacionFalsa);

    if (respuestaValidacionFalsa.state) {
      if (respuestaValidacionFalsa.data) {
        res = true;
      } else {
        res = false;
      }
    } else {
      res = false;
    }
  } catch (error) {
    console.log(error);
    res = false;
  }

  return res;
};
