import http from "../../../../comunication/http-common";

//http://localhost:4002/identity/ObtenerUsuarioXkey?usuario=eduardo.puga
export const obtenerUsuario = async (usuario) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURIDAD}identity/ObtenerUsuarioXkey?key=${usuario}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://localhost:4002/identity/ActualizarEstadoUsuario?idUsuario=1260
export const desbloquearUsuarioService = async (idUsuario, oficina, usuario, estacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURIDAD}identity/ActualizarEstadoUsuario?idUsuario=${idUsuario}&oficina=${oficina}&usuario=${usuario}&estacion=${estacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://localhost:4002/identity/ActualizarEmailUsuario?idUsuario=1260&email=prueba@hotmail.com
export const actualizarEmailUsuarioService = async (idUsuario, email) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURIDAD}identity/ActualizarEmailUsuario?idUsuario=${idUsuario}&email=${email}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

