import React, { Suspense, useReducer } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { QueryClient, QueryClientProvider } from "react-query";
import SocketProvider from "../app/modules/common/components/SocketProvider/SocketProvider";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import configureStore, { history } from "./redux/store";
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import { SnackbarProvider } from "notistack";
import AppProvider from "./AppProvider";
import { config } from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import { hideAuthLoader } from "./redux/actions/Auth";
import { fetchError } from "./redux/actions/Common";
import axios from "../app/comunication/http-common";
import { Switch } from "react-router-dom";
import Routes from "./routes";
import "../../src/app/modules/shared/styles/main.css";
import "../../src/app/modules/app-actualizarPagos/styles/main.css";
import "../../src/app/modules/app-servicios/styles/main.css";
import "../../src/app/modules/app-cobranzas/styles/main.css";
import "devextreme/dist/css/dx.light.css";

import { ASSET_IMAGES } from "./utils/constants/paths";
import { consultarGrupoGenerico } from "./modules/app-configuracion/services/configuracionServices";
import { useEffect } from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const store = configureStore();
const signalrUrl = process.env.REACT_APP_API_SIGNALR_ROOT;

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const origen = queryParams.get("origen");
  const init = queryParams.get("init");
  //console.log(navigator.userAgent);
  
  const regex = /Autoservicio\d{4}/;
  const nombreObtenido = navigator.userAgent.match(regex);
  const autoservicio = navigator.userAgent.includes("Autoservicio");


  // Verifica si hay una coincidencia
  
  //debugger;
  // localStorage.setItem("origen", origen);
  // localStorage.setItem("init", init);

  if (origen !== null) {
    localStorage.setItem("origen", origen);
    localStorage.setItem("init", init);
    localStorage.setItem("autoservicio", autoservicio);

    if (autoservicio) {
      // Obtiene la palabra "MAQUINA" seguida de los cuatro dígitos
      const nombreMaquina = nombreObtenido[0];
      localStorage.setItem("nombreMaquina", nombreMaquina);
    } 
    else{
      localStorage.setItem("nombreMaquina", null);
    }

    sessionStorage.setItem("origen", origen);
  } else {
    localStorage.setItem("origen", sessionStorage.getItem("origen"));
  }

  // useEffect(() => {
  //   if (origen !== null) {
  //     localStorage.setItem("origen", origen);
  //     localStorage.setItem("init", init);

  //     sessionStorage.setItem("origen", origen);
  //   } else {
  //     localStorage.setItem("origen", sessionStorage.getItem("origen"));
  //     //localStorage.setItem('init', init);
  //   }

  //   const resp = consultarGrupoGenerico(origen, 1);
  //   if (resp.state) {
  //     localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
  //   } else {
  //     console.log(resp.message);
  //   }

  // }, []);

  // const resp = consultarGrupoGenerico(origen, 1);
  // if (resp.state) {
  //   localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
  // } else {
  //   console.log(resp.message);
  // }

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {/* <SocketProvider url={signalrUrl}> */}
        {/* <BrowserRouter history={history}> */}
        <ConnectedRouter history={history}>
          <AppProvider>
            <JumboApp activeLayout={config.activeLayout}>
              <JumboTheme init={config.theme}>
                <JumboRTL>
                  <JumboDialogProvider>
                    <JumboDialog />
                    <SnackbarProvider
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      maxSnack={3}
                    >
                      <AppLayout>
                        <Suspense
                          fallback={
                            <Div
                              sx={{
                                display: "flex",
                                minWidth: 0,
                                alignItems: "center",
                                alignContent: "center",
                                height: "100%",
                              }}
                            >
                              <CircularProgress sx={{ m: "-40px auto 0" }} />
                            </Div>
                          }
                        >
                          {/* <BrowserRouter>
                                                        <AppRoutes />
                                                    </BrowserRouter> */}
                          <Switch>
                            <Routes />
                          </Switch>
                        </Suspense>
                      </AppLayout>
                    </SnackbarProvider>
                  </JumboDialogProvider>
                </JumboRTL>
              </JumboTheme>
            </JumboApp>
          </AppProvider>
        </ConnectedRouter>
        {/* </BrowserRouter> */}
        {/* </SocketProvider> */}
      </Provider>
    </QueryClientProvider>
  );
}

const { dispatch } = store;

axios.interceptors.response.use(
  (response) => {
    return response.status === 200
      ? response
      : { state: false, message: "Error genérico" };
  },
  (error) => {
    if (error.response) {
      const { status } = error.response;
      // debugger;
      if (status === 401) {
        dispatch(hideAuthLoader());
        dispatch(
          fetchError("LA SESIÓN HA EXPIRADO, POR FAVOR VUELVA A INGRESAR")
        );
      }
    } else if (error.request) {
      if (error.code === "ECONNABORTED") {
        dispatch(hideAuthLoader());
        dispatch(fetchError("ERROR DE TIEMPO DE ESPERA"));
      } else {
        dispatch(hideAuthLoader());
        dispatch(fetchError("ERROR DE CONEXIÓN"));
      }
    } else {
      dispatch(hideAuthLoader());
      dispatch(fetchError("ERROR DE CONEXIÓN"));
    }
    return Promise.reject(error);
  }
);

export default App;
