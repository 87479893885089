import React, { useEffect, useState } from "react";
import { Card, Skeleton } from "@mui/material";

import { makeStyles } from "@mui/styles";
import { formatoFecha } from "../../shared/validations/validaciones";
import { consultaCabeceraCliente } from "../../siniestros/Services/siniestrosService";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 1rem",
    },
  },
}));

const HeaderInformacionCliente = ({ idCliente }) => {
  const [cliente, setCliente] = useState(null);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaCabeceraCliente = await consultaCabeceraCliente(
          idCliente
        );
        /*  console.log({ respuestaCabeceraCliente }); */

        if (respuestaCabeceraCliente.state) {
          /*  console.log(respuestaCabeceraCliente); */
          setCliente(respuestaCabeceraCliente.data);
          setLoading(false);
        } else {
          setCliente({});
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (idCliente > 0) {
      setTimeout(() => {
        getData();
      }, 10);
    }
  }, [idCliente]);

  if (loading) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: "25px",
          margin: "1rem 0 2rem 0",
          width: "100%",
          minHeight: "10vh",
          maxHeight: "auto",
        }}
      />
    );
  }

  return (
    <section className="header-client-container">
      <Card className={classes.cardRoot}>
        <header className="header-client-information">
          <div className="header-client-sectionName">
            <h2>Cliente</h2>
          </div>

          <div className="header-client-sectionIdentificacion">
            <h4>
              <strong>Identificación</strong>
            </h4>
            <h4>{cliente.identificacion ? cliente.identificacion : ""}</h4>
          </div>

          <div className="header-client-sectionNombre">
            <h4>
              <strong>{`Nombre `}</strong>
            </h4>
            <h4>{`${cliente.nombreCliente}`}</h4>
          </div>

          <div className="header-client-sectionFecha">
            <h4>
              <strong>Fecha Nacimiento</strong>
            </h4>
            <h4>
              {cliente.fechaNacimiento
                ? formatoFecha(cliente.fechaNacimiento)
                : ""}
            </h4>
          </div>

          <div className="header-client-sectionTelefono">
            <h4>
              <strong>Teléfono</strong>
            </h4>
            <h4>{cliente.telefono ? cliente.telefono : ""}</h4>
          </div>

          <div className="header-client-sectionCelular">
            <h4>
              <strong>Celular</strong>
            </h4>
            <h4>{cliente.celular ? cliente.celular : ""}</h4>
          </div>
        </header>
      </Card>
    </section>
  );
};

export default HeaderInformacionCliente;
