import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { FormControl, Grid, MenuItem, TextField } from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import { formatoFecha } from "../../shared/validations/validaciones";

import { consultaCatalogosSinPadre } from "../../app-cliente/services/Catalogos/catalogosService";

import {
  consultarInformeTecnico,
  consultarCatalogoSiniestro,
  crearServicioTecnico,
  crearSeguimiento,
  consultaSiniestroXIdSiniestro,
  notificarSiniestroCliente,
} from "../Services/siniestrosService";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

const FormServicioTecnico = ({ idSiniestro, idGrupo }) => {
  const [clienteInfo, setClienteInfo] = useState(null);
  const [informacionSiniestro, setInformacionSiniestro] = useState({});

  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [idServicioTecnicoAux, setIdServicioTecnicoAux] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        console.log(idSiniestro);
        const { state, data } = await consultarInformeTecnico(idSiniestro);

        if (state) {
          console.log(data);
          setClienteInfo(data);
          setIdServicioTecnicoAux(data.idInformeTecnico);
        } else {
          setClienteInfo({});
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const { state, data } = await consultaSiniestroXIdSiniestro(
          idSiniestro
        );

        if (state) {
          console.log(data);
          setInformacionSiniestro(data);
        } else {
          setInformacionSiniestro({});
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        id={name}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} id={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsTaller = ({ name, labelName, componentSize }) => {
    const [talleres, setTalleres] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultarCatalogoSiniestro("TALLER");

          /* const orderData = data1.sort((a, b) => a.descripcion > b.descripcion); */

          /*  console.log({ orderData }); */

          if (state) {
            setTalleres(data);
          } else {
            setTalleres([]);
          }
        } catch (error) {
          console.log(error);
          setTalleres([]);
        }
      };
      getData();
    }, []);

    return (
      <FormControl sx={{ width: `${componentSize}` }}>
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {talleres.map((taller, index) => (
            <MenuItem key={index} value={taller.codigo}>
              {taller.descripcion}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    );
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const informacionServicioTecnico = {
      idSiniestro: Number(idSiniestro),
      idInformeTecnico: idServicioTecnicoAux,
      idTaller: valores.idTaller,
      recibidoPor: valores.recibidoPor.toUpperCase(),
      fechaIngreso: valores.fechaIngreso,
      numeroOrden: valores.numeroOrden,
      detalleInforme: valores.detalleInforme.toUpperCase(),
      diagnostico: valores.diagnostico,
      montoReparacion: valores.montoReparacion,
      fechaEntrega: valores.fechaEntrega,
      fechaRetiro: valores.fechaRetiro,
      esActivo: true,
    };

    /* console.log(informacionServicioTecnico); */

    try {
      const respuestaServicioTecnico = await crearServicioTecnico(
        informacionServicioTecnico
      );
      /*  console.log(respuestaServicioTecnico);
       */
      setDatosEnviadosCorrectamente(true);
      setIdServicioTecnicoAux(respuestaServicioTecnico.data);

      setTimeout(() => {
        setLoading(false);
        setDatosEnviadosCorrectamente(false);
      }, 100);
    } catch (error) {
      console.log(error);
    }

    const fecha = new Date();

    const informacionSeguimientoNotificacion = {
      idSeguimiento: 0,
      idSiniestro: Number(idSiniestro),
      esSiniestro: true,
      idEstado: 4,
      idSubestado: 16,
      fechaEstado: fecha.toISOString().split(".")[0],
      esActivo: true,
    };
    /*  console.log(informacionSeguimientoNotificacion);
     */
    try {
      const respuestaSeguimiento = await crearSeguimiento(
        informacionSeguimientoNotificacion
      );
      /*   console.log(respuestaSeguimiento); */
    } catch (error) {
      console.log(error);
    }

    try {
      const respuestaEnvioNotificar = await notificarSiniestroCliente(
        idSiniestro
      );

      if (respuestaEnvioNotificar.state) {
        console.log(respuestaEnvioNotificar);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {clienteInfo ? (
        <Formik
          initialValues={{
            /* informacion informe tecnico */
            idTaller: clienteInfo.idTaller || "",
            recibidoPor: clienteInfo.recibidoPor || "",
            fechaIngreso: clienteInfo.fechaIngreso || "",
            montoReparacion: clienteInfo.montoReparacion || 0,
            numeroOrden: clienteInfo.numeroOrden || "",
            diagnostico: clienteInfo.diagnostico || "",
            fechaEntrega: clienteInfo.fechaEntrega || "",
            fechaRetiro: clienteInfo.fechaRetiro || "",
            detalleInforme: clienteInfo.detalleInforme || "",
          }}
          validationSchema={Yup.object({
            recibidoPor: Yup.string().required("Recibido requerido"),
            montoReparacion: Yup.string().required(
              "Monto Reparacion requerido"
            ),
            numeroOrden: Yup.string().required("Numero Orden requerido"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            configuracionDatosEnvio(values);
          }}
        >
          {({ values, handleChange, touched, errors, handleBlur }) => (
            <Form>
              <SCSAccordion tituloCabecera="Servicio Técnico">
                <Grid container spacing={1} rowSpacing={2}>
                  <Grid item md={12}>
                    <Grid container spacing={3} rowSpacing={2}>
                      <Grid item md={12}>
                        {/* spacing entre filas */}

                        <div className="cliente-informacion-seccion">
                          <SelectItemsTaller
                            name="idTaller"
                            labelName="Taller"
                            componentSize="33%"
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                          />

                          <TextField
                            id="recibidoPor"
                            name="recibidoPor"
                            type="text"
                            label="Recibido Por"
                            sx={{ width: "23%" }}
                            value={values.recibidoPor}
                            onChange={handleChange}
                            size="small"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            error={
                              touched.recibidoPor && Boolean(errors.recibidoPor)
                            }
                            helperText={
                              touched.recibidoPor && errors.recibidoPor
                            }
                            onBlur={handleBlur}
                          />

                          <TextField
                            id="fechaIngreso"
                            name="fechaIngreso"
                            type="date"
                            label="Fecha Ingreso "
                            sx={{ width: "23%" }}
                            onChange={handleChange}
                            size="small"
                            value={formatoFecha(values.fechaIngreso)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />

                          <TextField
                            id="numeroOrden"
                            name="numeroOrden"
                            type="text"
                            label="N° Orden"
                            sx={{ width: "13%" }}
                            value={values.numeroOrden}
                            onChange={handleChange}
                            size="small"
                            error={
                              touched.numeroOrden && Boolean(errors.numeroOrden)
                            }
                            helperText={
                              touched.numeroOrden && errors.numeroOrden
                            }
                            onBlur={handleBlur}
                          />
                        </div>

                        <div className="cliente-informacion-seccion">
                          {informacionSiniestro.idFlujo === 4 ? (
                            <SelectItemsCatalogoSinPadre
                              pais="ECU"
                              especial="false"
                              catalogos="DIAGLLTA"
                              grupo={1}
                              name="diagnostico"
                              labelName="Diagnostico"
                              componentSize="24%"
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />
                          ) : (
                            <SelectItemsCatalogoSinPadre
                              pais="ECU"
                              especial="false"
                              catalogos="DIAGSNTR"
                              grupo={1}
                              name="diagnostico"
                              labelName="Diagnostico"
                              componentSize="24%"
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />
                          )}

                          <TextField
                            id="montoReparacion"
                            name="montoReparacion"
                            label="Monto Reparación"
                            sx={{ width: "22%" }}
                            value={values.montoReparacion}
                            onChange={handleChange}
                            size="small"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                            error={
                              touched.montoReparacion &&
                              Boolean(errors.montoReparacion)
                            }
                            helperText={
                              touched.montoReparacion && errors.montoReparacion
                            }
                            onBlur={handleBlur}
                          />

                          <TextField
                            id="fechaEntrega"
                            name="fechaEntrega"
                            type="date"
                            label="Fecha Entrega"
                            sx={{ width: "23%" }}
                            onChange={handleChange}
                            size="small"
                            value={formatoFecha(values.fechaEntrega)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <TextField
                            id="fechaRetiro"
                            name="fechaRetiro"
                            type="date"
                            label="Fecha Retiro"
                            sx={{ width: "23%" }}
                            onChange={handleChange}
                            size="small"
                            value={formatoFecha(values.fechaRetiro)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                        <div className="cliente-informacion-seccion">
                          <TextField
                            id="detalleInforme"
                            name="detalleInforme"
                            type="text"
                            label="Detalle Informe"
                            sx={{ width: "97%" }}
                            value={values.detalleInforme}
                            onChange={handleChange}
                            size="small"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>

                        <div className="confirmar-estados-servicios">
                          <SCSLoadingButton
                            loading={loading}
                            titulo="GUARDAR"
                          />

                          {datosEnviadosCorrectamente && (
                            <AcceptServiceresponse />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SCSAccordion>
            </Form>
          )}
        </Formik>
      ) : (
        <SpinnerLoadSrx />
      )}
    </>
  );
};

export default FormServicioTecnico;
