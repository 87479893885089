import React, { useState } from "react";

import TaskIcon from "@mui/icons-material/Task";

import ModalReportePolizas from "../containers/ModalReportePolizas";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";

const BotonConsultaSegurosPolizas = () => {
  const [open, setOpen] = useState(false);

  const obtenerReporteSabanaPolizas = () => {
    setOpen(true);
  };

  return (
    <div /* className="tipoSiniestro-tipos" */>
      <SCSGenericButton
        titulo="Reporte Sabana Polizas"
        endIcon={<TaskIcon />}
        onClick={obtenerReporteSabanaPolizas}
      />

      <ModalReportePolizas
        open={open}
        titulo="Reporte Aseguradora"
        mensaje="Reporte Aseguradora"
        setOpen={setOpen}
      />
    </div>
  );
};

export default BotonConsultaSegurosPolizas;
