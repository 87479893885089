import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import SiniestroSeguimiento from "./SiniestroSeguimiento/SiniestroSeguimiento";

const SeguimientoSiniestro = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);

  return (
    <>
      <h2 className="titulosPrincipales-container">Siniestro - Seguimiento</h2>
      <SiniestroSeguimiento />
    </>
  );
};

export default SeguimientoSiniestro;
