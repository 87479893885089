import { Card } from "@mui/material";
import { formatoFecha } from "app/modules/shared/validations/validaciones";
import React from "react";

const CabeceraSeguro = ({ seguro }) => {
  return (
    <div className="header-client-container">
      <Card>
        <header className="header-client-information">
          <div>
            <h2>Seguro</h2>
          </div>
          <div>
            <h4>Número Contrato</h4>
            <h4>{seguro.numContrato ? seguro.numContrato : ""}</h4>
          </div>
          <div>
            <h4>Producto</h4>
            <h4>{seguro.producto}</h4>
          </div>
          <div>
            <h4>Tipo Pago</h4>
            <h4>{seguro.tipoPago}</h4>
          </div>
          <div>
            <h4>Fecha Inicio</h4>
            <h4>
              {seguro.fechaInicio ? formatoFecha(seguro.fechaInicio) : ""}
            </h4>
          </div>
          <div>
            <h4>Estado</h4>
            <h4>{seguro.estado}</h4>
          </div>
        </header>
      </Card>
    </div>
  );
};

export default CabeceraSeguro;
