import React from "react";
import { Formik, Form, Field } from "formik";

import {
  Button,
  Card,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";

const BuscadorGestionSeguros = ({ setDatosBusqueda }) => {
  const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const enviarDatosBusqueda = (valores) => {
    setDatosBusqueda((args) => ({
      ...args,
      identificacion: valores.identificacion,
      opcionBusqueda: valores.opcionBusqueda,
    }));
  };

  return (
    <div className="buscador-siniestros-container">
      <Card
        sx={{
          width: 700,
          height: 300,
        }}
      >
        <header className="header-colaborador-header">
          <div className="buscador-siniestros-titulo">
            <h2>Consultar información del siniestro</h2>
          </div>

          <Formik
            initialValues={{
              identificacion: "",
              opcionBusqueda: "",
            }}
            onSubmit={(valores, { setSubmitting }) => {
              enviarDatosBusqueda(valores);
            }}
          >
            {({ values, handleChange }) => (
              <Form>
                <div className="buscador-gestion-opciones">
                  <FormControl sx={{ width: "31%" }} size="small">
                    <InputLabel id="opcionBusqueda">Opción Busqueda</InputLabel>
                    <Field
                      name="opcionBusqueda"
                      component={CustomizedSelectForFormik}
                    >
                      <MenuItem value="identificacion">Identificación</MenuItem>
                      <MenuItem value="codigo">Código Siniestro</MenuItem>
                    </Field>
                  </FormControl>

                  <TextField
                    id="identificacion"
                    name="identificacion"
                    type="text"
                    label="Identificación"
                    sx={{ width: "27%" }}
                    value={values.identificacion}
                    onChange={handleChange}
                    size="small"
                  />
                  {/* 
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ width: "17ch" }}
                  >
                    Buscar
                  </Button> */}

                  <SCSGenericButton type="submit" size={35} titulo="Buscar" />
                </div>
              </Form>
            )}
          </Formik>
        </header>
      </Card>
    </div>
  );
};

export default BuscadorGestionSeguros;
