import React from "react";
// import FusePageSimple from "@fuse/core/FusePageSimple";

import TabVerificacionAnalista from "../../containers/tabVerificacionAnalista";


function RevisionAnalistaContent () {
  return (
        <div className=" p-24 bg-[#ffffff]  h-full w-full ">
          
          <TabVerificacionAnalista/>
        </div>
  );
};

export default RevisionAnalistaContent;
