import React, { useEffect, useState } from "react";

import { Alert } from "@mui/material";

import HeaderInformacionCliente from "../../shared/containers/HeaderInformacionCliente";
import HeaderInformacionProducto from "../components/HeaderInformacionProducto";
import HeaderInformacionSiniestro from "./HeaderInformacionSiniestro";
import BotonSalirLimpiarStorage from "./BotonSalirLimpiarStorage";

import {
  consultaSiniestroXIdSiniestro,
  crearSeguimiento,
} from "../Services/siniestrosService";

const DetalleCerrarSiniestro = ({ idCliente, idSiniestro, idContrato }) => {
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [verificarEsActivoSiniestro, setVerificarEsActivoSiniestro] =
    useState(true);

  useEffect(() => {
    const verificarEsActivo = async () => {
      try {
        const respuestaSiniestroXId = await consultaSiniestroXIdSiniestro(
          idSiniestro
        );
        /*  console.log(respuestaSiniestroXId); */

        if (respuestaSiniestroXId.data.esActivo) {
          setVerificarEsActivoSiniestro(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    verificarEsActivo();
  }, []);

  const handleCerrarSiniesto = async () => {
    /* console.log("Cerrar Siniestro"); */

    const fecha = new Date();

    const informacionSeguimientoNotificacion = {
      idSeguimiento: 0,
      idSiniestro: Number(idSiniestro),
      esSiniestro: true,
      idEstado: 9,
      idSubestado: null,
      fechaEstado: fecha.toISOString().split(".")[0],
      esActivo: true,
    };
    /* console.log(informacionSeguimientoNotificacion); */

    try {
      const respuestaSeguimiento = await crearSeguimiento(
        informacionSeguimientoNotificacion
      );
      /* console.log(respuestaSeguimiento); */

      setDatosEnviadosCorrectamente(true);

      setTimeout(() => {
        setDatosEnviadosCorrectamente(false);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  if (verificarEsActivoSiniestro) {
    return (
      <>
        <BotonSalirLimpiarStorage />
        <h1>El siniestro se encuentra inactivo</h1>
      </>
    );
  }

  return (
    <>
      <BotonSalirLimpiarStorage />

      <HeaderInformacionCliente idCliente={idCliente} />

      <HeaderInformacionProducto idSiniestro={idSiniestro} />

      <HeaderInformacionSiniestro
        idCliente={idCliente}
        idSiniestro={idSiniestro}
      />

      <div className="cerrarSiniestro-container">
        <button
          type="button"
          className="tipoSiniestro-tipos-sinseguro"
          onClick={handleCerrarSiniesto}
        >
          Cerrar Siniestro
        </button>
      </div>

      {datosEnviadosCorrectamente && (
        <Alert severity="success" variant="filled">
          Los datos han sido guardados correctamente.
        </Alert>
      )}
    </>
  );
};

export default DetalleCerrarSiniestro;
