import React from "react";

import BotonSalirLimiarStorageCliente from "../containers/BotonSalirLimiarStorageCliente";
import DetalleRefereciasPersonales from "../containers/DetalleRefereciasPersonales";

import "../styles/main.css";

const FormClienteReferencias = ({ idCliente, setInfoClienteGrabada }) => {
  return (
    <>
      <BotonSalirLimiarStorageCliente />

      <DetalleRefereciasPersonales
        idCliente={idCliente}
        setInfoClienteGrabada={setInfoClienteGrabada}
      />
    </>
  );
};

export default FormClienteReferencias;
