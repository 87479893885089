import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector } from "react-redux";

import { consultarMensajesConversacion } from "app/modules/app-comunicaciones/services/comunicacionesService";
import MensajesConversacionContainer from "../components/MensajesConversacionContainer";
import ChatSideContext from "../context/ChatSideContext";
import FormInputMensajeSide from "../components/FormInputMensajeSide";

const DetalleSeccionMensajeriaCall = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { informacionChatSide, setInformacionChatSide } =
    useContext(ChatSideContext);
  const listEndRef = useRef(null);

  const [updateConversacion, setUpdateConversacion] = useState(false);

  //states conversacion hub
  const [chat, setChat] = useState([]);
  const latestChat = useRef(null);

  latestChat.current = chat;

  useEffect(() => {
    if (listEndRef.current) {
      listEndRef.current.scrollIntoView({ behavior: "instant", block: "end" });
    }
  }, [chat]);

  useEffect(() => {
    if (informacionChatSide?.connectionHub) {
      informacionChatSide?.connectionHub.on("NuevoMensaje", (message) => {
        const updatedChat = [...latestChat.current];
        updatedChat.push(message);

        setChat(updatedChat);
      });
    }
  }, [informacionChatSide?.connectionHub]);

  useEffect(() => {
    const getConversacion = async () => {
      try {
        const repuestaConversacion = await consultarMensajesConversacion(
          informacionChatSide?.clienteSeleccionado?.idMensaje,
          !informacionChatSide?.isCallCenter
        );

        console.log(repuestaConversacion);

        if (repuestaConversacion.state) {
          /* setListaMensajes(repuestaConversacion.data); */

          setChat(repuestaConversacion.data.mensajes);

          //setUltimoMensaje(repuestaConversacion.data?.esMensajeActivo);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getConversacion();
  }, [!informacionChatSide?.isCallCenter, updateConversacion]);

  const enviarMensajeHub = async (mensaje) => {
    if (mensaje === "") {
      return;
    }

    const informacionNuevoMensaje = {
      idGestionMensaje: 0,
      idMensaje: Number(informacionChatSide?.clienteSeleccionado?.idMensaje),
      idEnviaMsje: Number(authUser.idUsuario), //idusuario - logeado
      mensaje: mensaje,
      esEmisor: !informacionChatSide?.isCallCenter,
      esActivo: true,
    };

    /*  console.log(informacionNuevoMensaje); */

    if (informacionChatSide?.connectionHub?._connectionStarted) {
      try {
        informacionChatSide?.connectionHub
          .invoke("EnviarMensaje", informacionNuevoMensaje)
          .then((resp) => {
            /*   console.log(resp); */
            const updatedChat = [...latestChat.current];

            /* updatedChat.push({ esEmisor: true, mensaje: mensaje }); */
            updatedChat.push(resp);

            setChat(updatedChat);
          })
          .catch((e) => console.log(e));
      } catch (e) {
        console.log(e);
      }
    } else {
      alert("No connectionHub to server yet.");
    }
  };

  return (
    <div ref={listEndRef}>
      <section className="detalleSeccionConversacionSide-mensajeria">
        <MensajesConversacionContainer mensajes={chat} />
      </section>
      <section className="detalleSeccionConversacion-inputs">
        <FormInputMensajeSide enviarMensajeHub={enviarMensajeHub} />
      </section>
    </div>
  );
};

export default DetalleSeccionMensajeriaCall;
