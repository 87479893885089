import http from "../../../comunication/http-common";

/* REPORTES */
//http://10.10.0.79:5000/reporte/ObtenerReporteRequerimiento
export const consultarReporteRequerimiento = async (ruc) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_GESTION}reporte/ObtenerReporteRequerimiento`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/reporte/ObtenerSolicitudXZona?fechaIni=2023-01-01&fechafin=2023-01-31
export const consultarSolicitudXZona = async (fechaInicio, fechaFin) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_REPORTES}reporte/ObtenerSolicitudXZona?fechaIni=${fechaInicio}&fechaFin=${fechaFin}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/* REPORTES SEGUROS */

//http://10.10.0.79:5023/reporte/ObtenerSabanaPolizas?fechaIni=2023-01-01&fechaFin=2023-01-31
export const consultarSabanaPolizas = async (fechaInicio, fechaFin) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_REPORTES}reporte/ObtenerSabanaPolizas?fechaIni=${fechaInicio}&fechaFin=${fechaFin}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/* REPORTES SINIESTROS */

//http://10.10.0.79:5023/reporte/ObtenerSiniestrosDiario
export const consultarSiniestroDiario = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_REPORTES}reporte/ObtenerSiniestrosDiario`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5023/reporte/ObtenerSiniestrosAseguradora?fechaIni=2022-12-01&fechaFin=2022-12-31
export const consultarSiniestroAseguradora = async (fechaInicio, fechaFin) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_REPORTES}reporte/ObtenerSiniestrosAseguradora?fechaIni=${fechaInicio}&fechaFin=${fechaFin}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5023/reporte/ObtenerSiniestrosCorporacion?fechaIni=2023-01-01&fechaFin=2023-02-30
export const consultarSiniestroMensual = async (fechaInicio, fechaFin) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_REPORTES}reporte/ObtenerSiniestrosCorporacion?fechaIni=${fechaInicio}&fechaFin=${fechaFin}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://localhost:5023/reporte/ObtenerLogConsultaBloqueoColaborador?fechaIni=2023-06-07&fechaFin=2023-06-08
export const consultarBloqueoColaborador = async (fechaInicio, fechaFin) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_REPORTES}reporte/ObtenerLogConsultaBloqueoColaborador?fechaIni=${fechaInicio}&fechaFin=${fechaFin}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5000/seguro/RepSegurosCancelados?fechaIni=2023-05-01&fechaFin=2023-06-01
export const consultarReporteSegurosCancelados = async (
  fechaInicio,
  fechaFin
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}seguro/RepSegurosCancelados?fechaIni=${fechaInicio}&fechaFin=${fechaFin}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
