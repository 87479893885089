import http from "../../../comunication/http-common";

/* eslint-disable camelcase */
export const obtenerPerfilCF = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_EVALUACION}perfilCorporacion/obtenerPerfilCF?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerConsulmax = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_EVALUACION}evaluacion/ObtenerConsulmax?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerAceptacion = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_EVALUACION}evaluacion/ObtenerAceptacion?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

//http://localhost:5011/evaluacion/ObtenerBloqueoClienteXIdentificacion?identificacion=0101873230
export const consultarBloqueoCliente = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_EVALUACION}evaluacion/ObtenerBloqueoClienteXIdentificacion?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://localhost:5011/evaluacion/ObtenerBloqueosXIdentificacion?identificacion=1718081894
export const obtenerListaBloqueosManuales = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_EVALUACION}evaluacion/ListarBloqueosXIdentificacion?identificacion=${identificacion}`;

    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const obtenerDatosDigercic = async (identificacion, dactilar, usuario) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_EVALUACION}evaluacion/ObtenerDatosDigercic?identificacion=${identificacion}&dactilar=${dactilar}&usuario=${usuario}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

//http://localhost:5011/evaluacion/ActualizarDetalleBloqueo
export const actualizarDetalleBloqueo = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_EVALUACION}evaluacion/ActualizarDetalleBloqueo`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5011/evaluacion/GrabarBloqueoClienteDetalle
export const grabarBloqueoOrquestacion = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_EVALUACION}evaluacion/GrabarBloqueoClienteDetalle`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const grabarAceptacion = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_EVALUACION}evaluacion/GrabarAceptacion`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const notificarAceptacion = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_EVALUACION}evaluacion/NotificarAceptacion`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
