import React, { useEffect, useState } from "react";
import BuscadorPorIdentificador from "../../containers/BuscadorPorIdentificador";
import InformacionCliente from "../../containers/InformacionCliente";
import useClienteLocalStorage from "../../hook/useClienteLocalStorage";

import { consultaCliente } from "../../services/clienteService";

const InformacionPersonalCliente = () => {
  const [cliente, setCliente] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [mostrarDetalle, setMostrarDetalle] = useState(false);
  const [identificacion, setIdentificacion] = useState("");

  const [idClienteStorage, setIdClienteStorage] = useClienteLocalStorage(
    "idClienteStorage",
    null
  );

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  useEffect(() => {
    if (idClienteStorage) {
      setIdCliente(idClienteStorage);
    }
  }, []);

  const buscarExistenciaCliente = async (identificador) => {
    try {
      const respuestaCliente = await consultaCliente(identificador);

      if (respuestaCliente.state) {
        setIdCliente(respuestaCliente.data.idCliente);
        setIdClienteStorage(respuestaCliente.data.idCliente);
        setCliente(respuestaCliente.data);
        setMostrarDetalle(false);
      } else {
        setCliente(nuevoCliente);
        setIdCliente(nuevoIdCliente);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdCliente = (identificacion) => () => {
    buscarExistenciaCliente(identificacion);
    setIdentificacion(identificacion);
    /*  setIdentificacionStorage(identificacion); */
  };

  return (
    <>
      {idCliente ? (
        <InformacionCliente
          cliente={cliente}
          idCliente={idCliente}
          identificacion={identificacion}
        />
      ) : (
        <div className="gestion-siniestros-container">
          <BuscadorPorIdentificador traerIdCliente={traerIdCliente} />
        </div>
      )}
    </>
  );
};

export default InformacionPersonalCliente;
