import React from "react";
import { Card } from "@mui/material";

import { formatoFecha } from "../../shared/validations/validaciones";

import "../styles/main.css";

const HeaderInformacionQueja = ({ cabeceraQueja }) => {
/*   console.log(cabeceraQueja); */
  return (
    <div className="header-client-container">
      <Card>
        <header className="header-queja-grid">
          <div className="header-traslado-titulo">
            <h2>Queja</h2>
          </div>

          <div className="header-queja-detalle">
            <div className="header-traslado-information">
              <div>
                <div className="header-traslado-informacion">
                  <h4>Fecha Queja:</h4>
                  <h4>{formatoFecha(cabeceraQueja.fechaQueja)}</h4>
                </div>

                <div className="header-traslado-informacion">
                  <h4>Producto:</h4>
                  <h4>{cabeceraQueja.producto}</h4>
                </div>

                <div className="header-traslado-informacion">
                  <h4>Tipo Queja:</h4>
                  <h4>{cabeceraQueja.tipoQueja}</h4>
                </div>
              </div>

              <div>
                <div className="header-traslado-informacion">
                  <h4>Estado:</h4>
                  <h4>{cabeceraQueja.estado}</h4>
                </div>

                <div className="header-traslado-informacion">
                  <h4>Prestador:</h4>
                  <h4>{cabeceraQueja.prestador}</h4>
                </div>

                <div className="header-traslado-informacion">
                  <h4>Asignación:</h4>
                  <h4>{cabeceraQueja.asignacion}</h4>
                </div>
              </div>
              <div>
                <div className="header-traslado-informacion">
                  <h4>Tipo Solución:</h4>
                  <h4>{cabeceraQueja.tipoSolucion}</h4>
                </div>

                <div className="header-traslado-informacion">
                  <h4>Fecha Cierre:</h4>
                  <h4>{cabeceraQueja.fechaCierre}</h4>
                </div>
              </div>
            </div>

            <div className="footer-queja-detalle">
              <div className="header-traslado-informacion">
                <h4>Detalle: </h4>
                <h4>{cabeceraQueja.detalle}</h4>
              </div>
            </div>
          </div>
        </header>
      </Card>
    </div>
  );
};

export default HeaderInformacionQueja;
