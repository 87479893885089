export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Dirección email inválida"
    : undefined;

  export const ValidatePasaporte = function (pasaporte) {
    if (pasaporte.length == 0)
        return false;

    let pas = pasaporte.trim();
    //let esCaracterValido = ValidadorBehaivor.EsLetrasYNumeros(pasaporte);
    if (pas.length <= 16)
        return true;
    else
        return false;
};

export const psInteger = value => (value === undefined ? 0 : parseInt(value));
export const psFloat = value => (value === undefined ? 0 : parseFloat(value));

export const passwordsMustMatch = (value, allValues) =>
  value !== allValues.newPassword ? "Las contraseñas no coinciden" : undefined;

  export const maxLength = max => value =>
  value && value.length > max
    ? `Debe ser de ${max} caracteres o menos`
    : undefined;
export const minLength = min => value =>
  value && value.length < min
    ? `Debe ser de ${min} caracteres o más`
    : undefined;

export const required = value => (value ? undefined : " ");
export const maxLength15 = maxLength(15);
export const minLength5 = minLength(5);
export const minLength3 = minLength(3);
export const maxLength13 = maxLength(13);
export const maxLength8 = maxLength(8);
export const maxLength16 = maxLength(16);
export const maxLength32 = maxLength(32);
export const maxLength64 = maxLength(64);
export const maxLength128 = maxLength(128);
export const maxLength256 = maxLength(256);


/////////////////////////////////////


export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

