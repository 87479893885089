import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Toolbar } from "@mui/material";

import {
  consultaRefComercial,
  consultaRefPersonal,
} from "app/modules/app-cliente/services/clienteService";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

import { diccionarioParentesco } from "app/modules/shared/dictionary/extensionDocumentos";
import { consultarLlamadaUsuario } from "../services/cobranzaServices";
import BackdroLlamadaUsuario from "./BackdroLlamadaUsuario";

const ListaContactoReferencia = ({ informacionCobranza }) => {
  const [referenciasCliente, setReferenciasCliente] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLlamada, setIsLoadingLlamada] = useState(false);

  useEffect(() => {
    const getData = async () => {
      let referenciaPersonal;
      let referenciaComercial;

      try {
        const informacionRefPersonal = await consultaRefPersonal(
          informacionCobranza.idCliente
        );

        /* console.log(informacionRefPersonal);
         */
        if (informacionRefPersonal.state) {
          /*  setReferenciasCliente(informacionRefPersonal.data); */
          referenciaPersonal = informacionRefPersonal.data.map((ref) => ({
            ...ref,
            nombreCompleto: `${ref.primerNombre} ${ref.primerApellido}`,
          }));
        } else {
          /* setReferenciasCliente([]); */
          referenciaPersonal = [];
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }

      try {
        const informacionRefComercial = await consultaRefComercial(
          informacionCobranza.idCliente
        );

        /*  console.log(informacionRefComercial); */

        if (informacionRefComercial.state) {
          /* setReferenciasCliente(informacionRefComercial.data); */
          referenciaComercial = {
            ...informacionRefComercial.data,
            nombreCompleto: informacionRefComercial.data.nombreEmpresa,
            parentesco: "EMP",
            celular: informacionRefComercial.data.celularEmpresa,
            telefono: informacionRefComercial.data.telefonoEmpresa,
            email: informacionRefComercial.data.emailEmpresa,
          };
        } else {
          referenciaComercial = {};
          /*  setReferenciasCliente([]); */
        }
      } catch (error) {
        console.log(error);
      }

      const referenciasAux = [...referenciaPersonal];
      referenciasAux.push(referenciaComercial);

      setReferenciasCliente(referenciasAux);
      setIsLoading(false);
    };

    getData();
  }, [informacionCobranza.idCliente]);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const gestionarLlamadaUsuario = async (telefono) => {
    console.log(telefono);
    if (!telefono || telefono === "") return;

    setIsLoadingLlamada(true);

    try {
      const respuestaLlamadaUsuario = await consultarLlamadaUsuario(
        informacionCobranza.idUsuario,
        telefono
      );

      console.log({ respuestaLlamadaUsuario });

      if (respuestaLlamadaUsuario.state) {
        setIsLoadingLlamada(false);
      } else {
        setIsLoadingLlamada(false);
      }
    } catch (error) {
      setIsLoadingLlamada(false);
      console.log(error);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Toolbar>
          <h4>Referencias </h4>
        </Toolbar>

        <Table
          size="small"
          sx={{ minWidth: 650, padding: 5 }} /*  aria-label="simple table" */
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">N°</TableCell>
              <TableCell align="center">Nombre</TableCell>
              <TableCell align="center">Parentesco</TableCell>
              <TableCell align="center">Celular</TableCell>
              <TableCell align="center">Teléfono</TableCell>
              <TableCell align="center">Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {referenciasCliente.map((historico, index) => (
              <TableRow key={index}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{historico.nombreCompleto}</TableCell>
                <TableCell align="center">
                  {diccionarioParentesco(historico.parentesco)}
                </TableCell>
                <TableCell
                  align="center"
                  className="gestionllamada-contacto"
                  onClick={() => gestionarLlamadaUsuario(historico.celular)}
                >
                  {historico.celular}
                </TableCell>
                <TableCell
                  align="center"
                  className="gestionllamada-contacto"
                  onClick={() => gestionarLlamadaUsuario(historico.telefono)}
                >
                  {historico.telefono}
                </TableCell>
                <TableCell align="center">{historico.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <BackdroLlamadaUsuario isLoadingLlamada={isLoadingLlamada} />
    </>
  );
};

export default ListaContactoReferencia;
