import React from 'react';
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUT_NAMES} from "../../../layouts/layouts";
import GestionSolicitudContent from './GestionSolicitud/GestionSolicitudContent';


const GestionSolicitudPage = () => {
  const {setActiveLayout} = useJumboApp();

  React.useEffect(() => {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
      <div>
        <h2>Gestion de Solicitud.</h2>
        <GestionSolicitudContent></GestionSolicitudContent>
      </div>
  );
};

export default GestionSolicitudPage;