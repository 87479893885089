import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import { Grid, TextField } from "@mui/material";

import { LoadingButton } from "@mui/lab";

import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

import { formatoFecha } from "app/modules/shared/validations/validaciones";
import { crearPermisos } from "../services/cobranzaServices";

const FormGestionPermisos = ({ usuario, setNuevoUsuario, nuevoUsuario }) => {
  const [loading, setLoading] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  useEffect(() => {
    console.log({ usuario });
  }, []);

  const configuraionEnvioDatos = async (valores) => {
    /* console.log(valores); */

    const informacionPermisos = {
      idPermiso: 0,
      idUsuario: usuario.idUsuario,

      fechaInicio: valores.fechaInicio,
      fechaFinal: valores.fechaFin,
      esActivo: true,

      /*  fechaCreacion: "2023-04-10T00:00:00",
      usuarioCreacion: "rommel.pumisacho",
      estacionCreacion: "10.10.0.120",
      fechaModificacion: null,
      usuarioModificacion: null,
      estacionModificacion: null, */
    };

    console.log({ informacionPermisos });
    /*    setNuevoUsuario(!nuevoUsuario);
    setLoading(false); */

    try {
      const respuestaCrearPermisos = await crearPermisos(informacionPermisos);

      console.log(respuestaCrearPermisos);

      if (respuestaCrearPermisos.state) {
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setNuevoUsuario(!nuevoUsuario);
          setLoading(false);
        }, 2000);
      } else {
        setLoading(false);
        alert(`${respuestaCrearPermisos.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <Formik
      initialValues={{
        fechaInicio: "",
        fechaFin: "",
      }}
      validationSchema={Yup.object({
        fechaInicio: Yup.string().required("Fecha Inicio requerido"),
        fechaFin: Yup.string().required("Fecha Fin requerido"),
      })}
      onSubmit={(valores, { resetForm }) => {
        configuraionEnvioDatos(valores);
        setTimeout(() => {
          resetForm({ values: "" });
        }, 2000);
      }}
    >
      {({ values, handleChange, touched, errors, handleBlur }) => (
        <Form>
          <div /* className="accordion-container" */>
            <div>
              <Grid container spacing={1} rowSpacing={1}>
                <Grid item md={12}>
                  <Grid container spacing={1}>
                    <Grid item md={12}>
                      <div className="cliente-informacion-seccion">
                        <TextField
                          id="fechaInicio"
                          name="fechaInicio"
                          type="date"
                          label="Fecha Inicio"
                          sx={{ width: "45%" }}
                          onChange={handleChange}
                          size="small"
                          value={formatoFecha(values.fechaInicio)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            touched.fechaInicio && Boolean(errors.fechaInicio)
                          }
                          helperText={touched.fechaInicio && errors.fechaInicio}
                          onBlur={handleBlur}
                        />

                        <TextField
                          id="fechaFin"
                          name="fechaFin"
                          type="date"
                          label="Fecha Fin"
                          sx={{ width: "45%" }}
                          onChange={handleChange}
                          size="small"
                          value={formatoFecha(values.fechaFin)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={touched.fechaFin && Boolean(errors.fechaFin)}
                          helperText={touched.fechaFin && errors.fechaFin}
                          onBlur={handleBlur}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="confirmar-estados-servicios">
                    <LoadingButton
                      sx={{ width: "30%" }}
                      type="submit"
                      size="small"
                      loading={loading}
                      loadingPosition="end"
                      variant="contained"
                      /* startIcon={<AttachMoneyIcon />} */
                    >
                      Guardar
                    </LoadingButton>

                    {datosEnviadosCorrectamente && <AcceptServiceresponse />}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormGestionPermisos;
