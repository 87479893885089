import { useSelector } from "react-redux";

import {
  useJumboLayoutFooter,
  useJumboLayoutHeader,
  useJumboLayoutSidebar,
} from "@jumbo/hooks/index";

import useJumboLayoutRoot from "@jumbo/hooks/useJumboLayoutRoot";
import useJumboLayoutContent from "@jumbo/hooks/useJumboLayoutContent";

const useJumboLayout = () => {
  const { authUser } = useSelector(({ auth }) => auth);

  const { setSidebarOptions } = useJumboLayoutSidebar();
  const { setHeaderOptions } = useJumboLayoutHeader();
  const { setFooterOptions } = useJumboLayoutFooter();
  const { setRootOptions } = useJumboLayoutRoot();
  const { setContentOptions } = useJumboLayoutContent();

  /* console.log("hola desde las opciones de config");
  console.log({ authUser }); */
  /* console.log(authUser.rol, authUser.usuarioRol[0].idRol); */

  const setJumboLayoutOptions = (layoutOptions) => {
    const auxConfig = {
      ...layoutOptions,
      sidebar: { open: false, hide: true },
    };

    if (
      authUser?.rol === "GENERICOCLI" ||
      authUser?.rol === "PROSPECCION" ||
      Number(authUser?.usuarioRol[0]?.idRol) === 29
    ) {
      setSidebarOptions(auxConfig?.sidebar);
      setHeaderOptions(auxConfig?.header);
      setFooterOptions(auxConfig?.footer);
      setRootOptions(auxConfig?.root);
      setContentOptions(auxConfig?.content);
    } else {
      setSidebarOptions(layoutOptions?.sidebar);
      setHeaderOptions(layoutOptions?.header);
      setFooterOptions(layoutOptions?.footer);
      setRootOptions(layoutOptions?.root);
      setContentOptions(layoutOptions?.content);
    }
  };

  return { setJumboLayoutOptions };
};

export default useJumboLayout;
