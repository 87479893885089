import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import SeguroConsultarCuota from "./SeguroConsultarCuota/SeguroConsultarCuota";

const ConsultarCuotaSeguro = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Seguro - Consultar Cuota</h2>

      <SeguroConsultarCuota />
    </div>
  );
};

export default ConsultarCuotaSeguro;
