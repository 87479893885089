import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import "../styles/main.css";
import FormFirmaDigital from "../components/FormFirmaDigital";

import ImageCapture from "../components/ImageCapture";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const TabFirmaFotoCliente = ({
  tipo,
  idCli,
  cliente,
  identifCliente,
  idSolicitudChanger,
  setInfoGrabada,
  infoGrabada,
  inhabilitar
}) => {
  const [value, setValue] = useState(0);
  const [valueCli, setValueCli] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  /* const [idCliente, setIdCliente] = useState(idCli); */
  const [mostrarCliente, setMostrarCliente] = useState(false);
  const [idSolicitud, setIdSolicitud] = useState(-1);
  const [actividadEconomica, setActividadEconomica] = useState(null);

  const [validarConyugue, setValidarConyugue] = useState(false);

  const [idClienteInformacion, setIdClienteInformacion] = useState(-1);
  const [idClienteAux, setIdClienteAux] = useState(null);

  const [infoRegistrada, setInfoRegistrada] = useState({
    firma: false,
    imagenes: false,
  });
  //if (infoRegistrada.firma) console.log("firma");
  //if (infoRegistrada.imagenes) console.log("imagenes");

  useEffect(() => {
    //console.log(inhabilitar);
    if (!infoGrabada.firma) {
      setInfoGrabada((args) => ({
        ...args,
        firma: false,
      }));
    }
    //console.log(idCli);
    //console.log(identifCliente);
  }, []);

  useEffect(() => {
    //console.log("cambio");
    //console.log(inhabilitar);
  }, [inhabilitar]);
  useEffect(() => {
    //console.log(infoRegistrada);
    if (infoRegistrada.firma && infoRegistrada.imagenes) {
      //console.log("Entra a cambiar estado")
      setInfoGrabada((args) => ({
        ...args,
        firma: true,
      }));
    } else {
      //console.log("Entro ");
      setInfoGrabada((args) => ({
        ...args,
        firma: false,
      }));
    }
  }, [infoRegistrada.imagenes]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box>
        <Tabs
          variant="fullWidth"
          centered
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="success"
        >
          <Tab icon={<LooksOneIcon />} label="Firma" className="tabsAnalista"  disabled={inhabilitar}/>
          <Tab
            icon={<LooksTwoIcon />}
            label="Fotografia"
            className="tabsAnalista"
            disabled={inhabilitar}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FormFirmaDigital
          idCli={idCli}
          cliente={cliente}
          identifCliente={identifCliente}
          idSolicitudChanger={idSolicitudChanger}
          setInfoRegistrada={setInfoRegistrada}
          inhabilitar={inhabilitar}
        />
      </TabPanel>
      <TabPanel value={value} index={1} >
        <ImageCapture
          idCli={idCli}
          cliente={cliente}
          identifCliente={identifCliente}
          setInfoRegistrada={setInfoRegistrada}
        />
      </TabPanel>
    </div>
  );
};

export default TabFirmaFotoCliente;
