import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import {
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
  Card,
} from "@mui/material";

import InputLabel from "@mui/material/InputLabel";

import Select from "@mui/material/Select";
import * as Yup from "yup";

import { listarLocales } from "app/modules/app-configuracion/services/configuracionServices";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ActulizarLocalUsuario } from "../Service/usuarioService";
import ModalAlertaNotificacionEstandar from "app/modules/app-servicios/components/ModalAlertaNotificacionEstandar";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

const FormImformacionUsuario = ({ idUsuario, usuario }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const [idUsuarioAux, setIdUsuarioAux] = useState(idUsuario);
  const [localesInformacion, setLocalesInformacion] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openUsuario, setOpenUsuario] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [estilos, setEstilos] = useState(
    Number(localStorage.getItem("idGrupoStorage"))
  );

  useEffect(() => {
    console.log({ usuario });
    console.log({ idUsuario });
  }, []);

  const SelectItemsLocal = ({
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [local, setLocal] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await listarLocales();

          if (state) {
            setLocal(data);
          } else {
            setLocal([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {local.map((local, index) => (
            <MenuItem key={index} value={local.idLocal}>
              {`${local.idLocal} - ${local.nombreLocal}`}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const informacionUsuario = {
      idUsuario: Number(usuario.idUsuario),
      idLocal: Number(valores.idLocal),
      esActivo: valores.esActivo,
      oficina: authUser.oficina,
      usuario: authUser.key,
      estacion: authUser.estacion,
    };
    // console.log("VALORES", informacionUsuario);

    try {
      const respuestaUsuario = await ActulizarLocalUsuario(
        informacionUsuario.idUsuario,
        informacionUsuario.idLocal,
        informacionUsuario.esActivo,
        informacionUsuario.oficina,
        informacionUsuario.usuario,
        informacionUsuario.estacion
      );

      if (respuestaUsuario.state) {
        setDatosEnviadosCorrectamente(true);
        setOpenUsuario(true);
        setLoading(false);

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
        }, 2000);
      } else {
        alert(`${respuestaUsuario.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(`${error.response.data.message}`);
    }
  };

  const validarGrupo = () => {
    if (Number(estilos) === 1) {
      return "#e1251b";
    } else if (Number(estilos) === 2) {
      return "#e1251b";
    } else if (Number(estilos) === 3) {
      return "#ef732c";
    } else if (Number(estilos) === 4) {
      return "#c73436";
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: `${validarGrupo()}`,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 35,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <div className="header-client-container">
        <Card>
          <header className="header-client-information">
            <div>
              <h2>Usuario</h2>
            </div>
            <div>
              <h4 align="center">
                <b>Identificación</b>
              </h4>
              <h4 align="center">
                {usuario.identificacion ? usuario.identificacion : ""}
              </h4>
            </div>
            <div>
              <h4 align="center">
                <b>Nombre</b>
              </h4>
              <h4 align="center">
                {usuario.apellidos} {usuario.nombres}
              </h4>
            </div>
            <div>
              <h4 align="center">
                <b>Usuario</b>
              </h4>
              <h4 align="center">{usuario.key}</h4>
            </div>
            <div>
              <h4 align="center">
                <b>Email</b>
              </h4>
              <h4 align="center">{usuario.email}</h4>
            </div>
            <div>
              <h4 align="center">
                <b>Local Actual</b>
              </h4>
              <h4 align="center">{usuario.oficina}</h4>
            </div>
            <div>
              <h4 align="center">
                <b>Estado Actual</b>
              </h4>
              <h4 align="center">{usuario.activo ? "ACTIVO" : "INACTIVO"}</h4>
            </div>
          </header>
        </Card>
      </div>
      <br />
      <Formik
        initialValues={{
          idLocal: usuario.oficina ?? "",
          esActivo: usuario.activo ?? "",
        }}
        validationSchema={Yup.object({
          idLocal: Yup.string().required("Código local requerido"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          // console.log(values);
          configuracionDatosEnvio(values);
        }}
      >
        {({ values, handleChange, touched, errors, handleBlur }) => (
          <Form>
            <div className="tipoSiniestro-container">
              <TableContainer component={Paper} sx={{ width: "50%" }}>
                <TableContainer sx={{ width: "100%" }}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          sx={{ textAlign: "center", width: "75%" }}
                        >
                          Actualizar Local
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ textAlign: "center", width: "35%" }}
                        >
                          Actualizar Estado
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <StyledTableCell>
                        <SelectItemsLocal
                          name="idLocal"
                          labelName="Nombre Local"
                          componentSize="100%"
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          onBlur={handleBlur}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <FormGroup
                          error={touched.esActivo && Boolean(errors.esActivo)}
                          helperText={touched.esActivo && errors.esActivo}
                          onBlur={handleBlur}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.esActivo}
                                onChange={handleChange}
                                name="esActivo"
                              />
                            }
                            label="Activo"
                          />
                          <ErrorMessage
                            name="esActivo"
                            component={() => (
                              <FormHelperText>{errors.esActivo}</FormHelperText>
                            )}
                          />
                        </FormGroup>
                      </StyledTableCell>
                    </TableBody>
                  </Table>
                  <Table>
                    <StyledTableCell>
                      <SCSLoadingButton loading={loading} titulo="GUARDAR" />
                      {datosEnviadosCorrectamente && <AcceptServiceresponse />}
                    </StyledTableCell>
                  </Table>
                </TableContainer>
              </TableContainer>
            </div>
          </Form>
        )}
      </Formik>

      <ModalAlertaNotificacionEstandar
        setOpen={setOpenUsuario}
        open={openUsuario}
        titulo="Administrador Usuario Promotor"
        mensaje="Transacción ejecutada con exito !!."
      />
    </>
  );
};

export default FormImformacionUsuario;
