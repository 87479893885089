import React, { useState } from "react";

import BuscadorPorIdentificacionGenerico from "app/modules/app-cliente/containers/BuscadorPorIdentificacionGenerico";
import BuscadorPorIdentificacionServimax from "app/modules/app-cliente/containers/BuscadorPorIdentificacionServimax";
import DetalleListaAutorizaciones from "../../containers/DetalleListaAutorizaciones";

import "../../styles/main.css";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";

const ListadoAutorizaciones = () => {
  const [datosBusqueda, setDatosBusqueda] = useState({
    identificacion: "",
    mostrarBuscador: true,
    idGrupo: Number(localStorage.getItem("idGrupoStorage")),
  });

  if (datosBusqueda.mostrarBuscador) {
    return (
      <>
        {datosBusqueda.idGrupo === 4 ? (
          <div className="gestion-siniestros-container">
            <BuscadorPorIdentificacionServimax
              setDatosBusqueda={setDatosBusqueda}
              titulo="Consultar información del cliente"
            />
          </div>
        ) : (
          <div className="gestion-siniestros-container">
            <BuscadorPorIdentificacionGenerico
              setDatosBusqueda={setDatosBusqueda}
              titulo="Consultar información del cliente"
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <BotonGerericoSalir />
      <DetalleListaAutorizaciones datosBusqueda={datosBusqueda} />
    </>
  );
};

export default ListadoAutorizaciones;
