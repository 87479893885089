import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import SiniestroDetalleGestion from "./SiniestroDetalleGestion/SiniestroDetalleGestion";

const DetalleGestionSiniestro = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <>
      <h2 className="titulosPrincipales-container">Siniestro - Gestión</h2>
      <SiniestroDetalleGestion />
    </>
  );
};

export default DetalleGestionSiniestro;
