import React, { useEffect, useState } from "react";

import { consultarResumenInformacionCliente } from "../services/clienteService";
import BotonSalirLimiarStorageCliente from "./BotonSalirLimiarStorageCliente";

const ResumenInformacionCliente = ({
  datosBusqueda,
  cliente,
  idCliente,
  identificacion,
}) => {
  const [resumenInfoCliente, setResumenInfoCliente] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const { state, data } = await consultarResumenInformacionCliente(
          identificacion,
          datosBusqueda.idGrupo
        );

        if (state) {
          setResumenInfoCliente(data);
        } else {
          setResumenInfoCliente("");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  return (
    <>
      <BotonSalirLimiarStorageCliente />
      <div style={{ position: "relative", width: "100%" }}>
        <div
          style={{
            width: "100%",
            background: "rgb(82, 86, 89)",
            height: "70px",
            position: "absolute",
          }}
        />
        <iframe
          src={`data:application/pdf;base64,${resumenInfoCliente}`}
          id="resumeClient"
          frameBorder="0"
          title="resumeClient"
          width="100%"
          height="700"
          name="resumeClient"
        />
      </div>
    </>
  );
};

export default ResumenInformacionCliente;
