import React, { memo, useState, useEffect } from "react";
//import { Routes, Route, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import {
  DataGrid,
} from "@mui/x-data-grid";

import { cambiarEstadoSolicitud, listarSolicitudes, obtenerSolicitud } from "../../services/creditoServices";

import "../../styles/main.css";
import { darken, lighten } from "@mui/system";

function SolicitudesContent() {
  const [catalogos, setCatalogos] = useState([]);
  const { authUser } = useSelector(({ auth }) => auth);
  const [flagEdit, setflagEdit] = useState(false);
  const [solicitudes, setSolicitudes] = useState([]);
  const [flagRefrescar, setFlagRefrescar] = useState(false);
  const [flujoSol, setflujoSol] = useState("Revision");

  const history = useHistory();

  useEffect(() => {
    getSolicitudes();
    setTimeout(() => {
      setFlagRefrescar(!flagRefrescar);
    }, 45000);
  }, []);

  useEffect(() => {
    //console.log("resfreshed")
    getSolicitudes();
    setTimeout(() => {
      setFlagRefrescar(!flagRefrescar);
    }, 45000);
  }, [flagRefrescar]);

  const getSolicitudes = () => {
    listarSolicitudes()
      .then((response) => {
        if (response.state) {
          setSolicitudes(response.data);
        }

      })
      .catch((_errors) => {
        console.log(_errors);
      });
  };

  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => {
            routeChange(params);
          }}
        >
          Gestionar
        </Button>
      </strong>
    );
  };

  const formatearFecha = (fecha) => {
    if (!fecha) {
      return "";
    }
    const fechaModificada = fecha.split("T");
    return fechaModificada[0] + " / "+fechaModificada[1];
  };

  const columns = [
    {
      field: "idSolicitud",
      hide: true,
      headerName: "ID",
      width: 80,
      headerClassName: "header-grid-solicitud",
    },
    {
      field: "numeroSolicitud",
      headerName: "Numero de Solicitud",
      headerClassName: "header-grid-solicitud",
      width: 200,
      editable: false,
    },
    {
      field: "identificacion",
      headerName: "Identificación",
      headerClassName: "header-grid-solicitud",
      width: 160,
      editable: false,
    },
    {
      field: "fechaSolicitud",
      headerName: "Fecha de Solicitud",
      headerClassName: "header-grid-solicitud",
      width: 200,
      editable: false,
      valueFormatter: (params) => {
        return formatearFecha(params.value);
      },
    },
    {
      field: "usuarioCreacion",
      headerName: "Usuario",
      headerClassName: "header-grid-solicitud",
      width: 160,
      editable: false,
    },
    {
      field: "idLocal",
      headerName: "Local",
      headerClassName: "header-grid-solicitud",
      width: 90,
      editable: false,
      /*  valueFormatter: async (params) => {
        const resp = await obtenerLocal(params.value);
        console.log({ resp });
        return resp.data.idLocal;
      }, */
    },
    {
      field: "aumentoCupo",
      headerName: "Aumento de Monto",
      headerClassName: "header-grid-solicitud",
      width: 140,
      editable: false,
      valueFormatter: (params) => {
        return params.value? "Si": "No"  
      },
    },
    {
      field: "revision",
      headerName: "Revisión",
      headerClassName: "header-grid-solicitud",
      width: 150,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
    },
  ];

  const rows = solicitudes;

  //const navigate = useNavigate();
  const routeChange = async (params) => {
    var solicitud = await obtenerSolicitud(params.id);
    if((!solicitud.data.aumentoCupo && solicitud.data.idEstado !==2) || (solicitud.data.aumentoCupo && solicitud.data.idEstado!== 4))
    {
      alert("Solicitud ya esta en proceso");
    }
    else{
      try {
        if (params.row.aumentoCupo) {
          var resp = await cambiarEstadoSolicitud(
            params.id,
            5,
            authUser.key,
            authUser.estacion
          );
  
        } else {
          var resp = await cambiarEstadoSolicitud(
            params.id,
            3,
            authUser.key,
            authUser.estacion
          );
        }
        const path = "/credito/revisionAnalista";
        history.push(path, { state: { id: params.id, flujo: flujoSol } });
      } catch (e) {
        alert("Solicitud ya esta en proceso");
      }
    }
    
  };

  const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

  return (
    <div>
      <Box sx={{ height: 800, width: "100%", 
    '& .aumento-monto-theme--true': 
    {
      bgcolor: (theme) =>
        getBackgroundColor(theme.palette.error.main, theme.palette.mode),
        '&:hover': {
          bgcolor: (theme) =>
            getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
        },
    },}}>
        <DataGrid
          getRowId={(row) => row.idSolicitud}
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          getRowClassName={(params) => `aumento-monto-theme--${params.row.aumentoCupo}`}
        />
      </Box>
    </div>
  );
}

export default memo(SolicitudesContent);
