import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import React from "react";

import { useState, useEffect } from "react";
import { consultaResumenPagos } from "../services/SeguroService/seguroService";
import TableCuotaComun from "./TableCuotaComun";

const TablasDetalleCuota = ({ idContrato, idCliente }) => {
  const [detalleCuotas, setDetalleCuotas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const columsPagos = [
    {
      field: "id",
      hide: true,
      headerName: "id",
      width: 80,
      headerClassName: "header-grid-solicitud",
    },
    {
      field: "cuotas",
      headerName: "Cuotas",
      headerClassName: "MuiDataGrid-cellCenter",
      width: 160,
      editable: false,
    },
    {
      field: "estado",
      headerName: "Estado",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
    },

    {
      field: "valor",
      headerName: "Valor",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
      valueFormatter: (params) => {
        return `${params.value} $`;
      },
    },
  ];

  const columsPagadas = [
    {
      field: "cuota",
      headerName: "Cuota",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
    },
    {
      field: "fechaVcto",
      headerName: "Fecha Vencimiento",
      headerClassName: "header-grid-solicitud",
      width: 160,
      editable: false,
    },
    {
      field: "fechaPago",
      headerName: "Fecha Pago",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
    },
    {
      field: "tipoPago",
      headerName: "Tipo Pago",
      headerClassName: "header-grid-solicitud",
      width: 130,
      editable: false,
    },
    {
      field: "valor",
      headerName: "Valor",
      headerClassName: "header-grid-solicitud",
      width: 130,
      editable: false,
      valueFormatter: (params) => {
        return `${params.value} $`;
      },
    },
    {
      field: "numAutorizacion",
      headerName: "N° Autorización",
      headerClassName: "header-grid-solicitud",
      width: 130,
      editable: false,
    },
    {
      field: "numTransaccion",
      headerName: "N° Transacción",
      headerClassName: "header-grid-solicitud",
      width: 130,
      editable: false,
    },
  ];

  const columsImpagas = [
    {
      field: "cuota",
      headerName: "Cuota",
      headerClassName: "header-grid-solicitud",
      width: 120,
    },
    {
      field: "fechaVcto",
      headerName: "Fecha Vencimiento",
      headerClassName: "header-grid-solicitud",
      width: 160,
      editable: false,
    },
    {
      field: "estado",
      headerName: "Estado",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
    },
    {
      field: "valor",
      headerName: "Valor",
      headerClassName: "header-grid-solicitud",
      width: 130,
      editable: false,

      valueFormatter: (params) => {
        return `${params.value} $`;
      },
    },
  ];

  useEffect(() => {
    console.log({ idContrato });
    console.log({ idCliente });

    const getData = async () => {
      try {
        const respuestaResumenPagos = await consultaResumenPagos(idContrato);

        console.log(respuestaResumenPagos);
        if (respuestaResumenPagos.state) {
          setDetalleCuotas(respuestaResumenPagos.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  return (
    <div className="tablaCuotas-container">
      <div className="item">
        {/* pagadas */}
        <h4>Cuotas Pagadas</h4>
        <TableCuotaComun
          datos={detalleCuotas.pagadas}
          columnas={columsPagadas}
          sizeWidth="100"
        />
      </div>
      <div className="item">
        {/* pagos */}
        <h4>Pagos Cuotas</h4>
        <TableCuotaComun
          datos={detalleCuotas.pagos}
          columnas={columsPagos}
          sizeWidth="600"
        />
      </div>
      <div className="item">
        {/* impagadas */}
        <h4>Cuotas Impagas</h4>
        <TableCuotaComun
          datos={detalleCuotas.impagas}
          columnas={columsImpagas}
          sizeWidth="600"
        />
      </div>
    </div>
  );
};

export default TablasDetalleCuota;
