import React, { useEffect, useState } from "react";

import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
  cancelarCitaMedicaCliente,
  cancelarCitaMedicaPrestador,
  consultarCitasMedicasXCliente,
} from "../services/citasMedicasService";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TablaCitasMedicasCliente = ({ idCliente }) => {
  const [listaCitasMedicas, setListaCitasMedicas] = useState([]);

  const [updateTable, setUpdateTable] = useState(true);

  useEffect(() => {
    console.log(idCliente);

    const getData = async () => {
      try {
        const respuestaCitasMedicas = await consultarCitasMedicasXCliente(
          idCliente
        );

        if (respuestaCitasMedicas.state) {
          console.log(respuestaCitasMedicas);
          setListaCitasMedicas(respuestaCitasMedicas.data);
        } else {
          setListaCitasMedicas([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [updateTable]);

  const escogerIdQueja = (idCitaMedica) => {
    /* setIdProductoAux(idQueja); */
    /* setIdQuejaStorage(idQueja); */
    console.log(idCitaMedica);
  };

  const cancelarCitaMCliente = async (idCitaMedica) => {
    try {
      const respuestaCancelacionCliente = await cancelarCitaMedicaCliente(
        idCitaMedica
      );

      if (respuestaCancelacionCliente.state) {
        alert("Cita Cancelada Por Cliente");
        setUpdateTable(!updateTable);
      } else {
        alert(`${respuestaCancelacionCliente.message}`);
        setUpdateTable(!updateTable);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancelarCitaMPrestador = async (idCitaMedica) => {
    try {
      const respuestaCancelacionPrestador = await cancelarCitaMedicaPrestador(
        idCitaMedica
      );
      if (respuestaCancelacionPrestador.state) {
        alert("Cita Cancelada Por Prestador Médico");
        setUpdateTable(!updateTable);
      } else {
        alert(`${respuestaCancelacionPrestador.message}`);
        setUpdateTable(!updateTable);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="tipoSiniestro-container">
      {listaCitasMedicas ? (
        <div className="tipoSiniestro-siniestros">
          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Titular</StyledTableCell>
                  <StyledTableCell>Paciente</StyledTableCell>
                  <StyledTableCell>Parentesco</StyledTableCell>
                  <StyledTableCell>Producto</StyledTableCell>
                  <StyledTableCell>Estado</StyledTableCell>
                  <StyledTableCell>Fecha</StyledTableCell>
                  <StyledTableCell>Hora</StyledTableCell>
                  <StyledTableCell>Tipo Pago</StyledTableCell>
                  <StyledTableCell>Prestador</StyledTableCell>
                  <StyledTableCell>Especialidad</StyledTableCell>
                  <StyledTableCell>Email Notificacion</StyledTableCell>
                  <StyledTableCell>Cancelar Cliente</StyledTableCell>
                  <StyledTableCell>Cancelar Prestador</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listaCitasMedicas.map((citaMedica) => (
                  <StyledTableRow key={citaMedica.idCliente}>
                    <StyledTableCell>{citaMedica.identTitular}</StyledTableCell>
                    <StyledTableCell>
                      {citaMedica.identPaciente}
                    </StyledTableCell>

                    <StyledTableCell component="th" scope="row">
                      {citaMedica.parentesco === ""
                        ? "TITULAR"
                        : citaMedica.parentesco}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {citaMedica.producto}
                    </StyledTableCell>

                    <StyledTableCell component="th" scope="row">
                      {citaMedica.estado}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {citaMedica.fecha}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {citaMedica.hora}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {citaMedica.tipoPago}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {citaMedica.prestador}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {citaMedica.especialidad}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {citaMedica.email}
                    </StyledTableCell>

                    <StyledTableCell component="th" scope="row">
                      <Button
                        variant="contained"
                        onClick={() =>
                          cancelarCitaMCliente(citaMedica.idCitaMedica)
                        }
                        endIcon={<SendIcon />}
                      >
                        Cancelar
                      </Button>
                    </StyledTableCell>

                    <StyledTableCell>
                      <Button
                        variant="contained"
                        onClick={() =>
                          cancelarCitaMPrestador(citaMedica.idCitaMedica)
                        }
                        endIcon={<SendIcon />}
                      >
                        Cancelar
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div className="tipoSiniestro-siniestros">
          <h1>No existen seguros asociados a este cliente !!</h1>
        </div>
      )}
    </div>
  );
};

export default TablaCitasMedicasCliente;
