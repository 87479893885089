import React, { useState } from "react";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  FormGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Button,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
import { auth } from "@jumbo/services/auth/firebase/firebase";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_AVATARS, ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { useDispatch } from "react-redux";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../../../redux/actions/Common";

import {
  SignUpCli,
  SignUpCliExistente,
  grabarRegistroCLiente,
  obtenerDatosPreevaluacion,
  obtenerRegistroCliente,
} from "../services/index";
import {
  obtenerSolicitud,
  preevaluacionCliente,
} from "app/modules/app-credito/services/creditoServices";
import InfoPopoverButton from "app/modules/shared/components/InfoPopoverButton";
import { consultarEstadoCredito } from "app/modules/app-transaccional/services/transaccionalService";
import HouseIcon from "@mui/icons-material/House";
import { consultarGrupoGenerico } from "app/modules/app-configuracion/services/configuracionServices";

let dactilarLocal = "";
const dactilarValidation = (codigo) => {
  let coddactilar = localStorage.getItem("cd");
  const valid = coddactilar == codigo ? true : false;
  //const valid = true;
  return valid
    ? {
        isValid: true,
      }
    : {
        isValid: false,
        errorMessage:
          "Código Dactilar no coincide. Favor comuníquese al numero 3811960",
      };
};

const validationSchema = yup.object({
  // celular: yup
  // .string('Ingrese su celular')
  // //.celular('Ingrese celular válido')
  //.required('Celular es obligatorio'),
  dactilar: yup
    .string("Ingrese Código Dactilar")
    .test("validator-custom-name", function (value) {
      const validation = dactilarValidation(value);
      if (!validation.isValid) {
        return this.createError({
          path: this.path,
          message: validation.errorMessage,
        });
      } else {
        return true;
      }
    })
    .required("Código Dactilar es Obligatorio"),
});

const Datos = () => {
  const dispatch = useDispatch();

  //const origen = localStorage.getItem("origen");
  const [origen, setOrigen] = useState(localStorage.getItem("origen"));
  const cedula = localStorage.getItem("cedula");

  const history = useHistory();
  const { setActiveLayout } = useJumboApp();
  const [open, setOpen] = useState(false);
  const [mensajeCliente, setMensajeCiente] = useState("");
  const [continuaProceso, setContinuaProceso] = useState(false);
  const [color, setColor] = useState("");
  const [url, setUrl] = useState("");
  const { authUser } = useSelector(({ auth }) => auth);

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
    switch (origen) {
      case "tvtas":
        setColor("#FF9201");
        setUrl("https://www.tventas.com/content/55-creditventas");
        break;
      case "corpfav":
        setColor("#e1251b");
        setUrl("https://www.creditofavorito.com/");
        break;
      default:
        setColor("#e1251b");
        setUrl("https://www.creditofavorito.com/");
        break;
    }
    const obtenerGrupo = async () => {
      const resp = await consultarGrupoGenerico(origen, 1);
      if (resp.state) {
        localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
      } else {
        console.log(resp.message);
      }
    };

    obtenerGrupo();
  }, []);

  // const obtenerGrupo = async () => {
  //   const resp = await consultarGrupoGenerico(origen, 1);
  //   if (resp.state) {
  //     localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
  //   } else {
  //     console.log(resp.message);
  //   }
  // };

  const mutation = useAuthSignInWithEmailAndPassword(auth, {
    onError(error) {
      console.log(error);
    },
    onSuccess(data) {
      history.push("/", { replace: true });
    },
  });

  const onSignIn = async (dactilar) => {
    console.log("entro");
    let celular = localStorage.getItem("celular");

    try {
      const srvObtenerDatosSol = await consultarEstadoCredito(
        cedula,
        localStorage.getItem("idGrupoStorage")
      );

      const esAutoservicio = eval(localStorage.getItem("autoservicio"));
      let usuarioConsulta;
      if (esAutoservicio) {
        usuarioConsulta = localStorage.getItem("nombreMaquina")
      } else {
        usuarioConsulta = `LandingWeb-${origen}`;
      }
      debugger;
      if (!srvObtenerDatosSol.state) {
        let response = await SignUpCli(cedula, celular, dactilar);
        if (response.state) {
          try {
            const resp = await preevaluacionCliente(
              cedula,
              authUser.key,
              usuarioConsulta,
              true
            );
            console.log(resp);
            if (resp.state) {
              if (resp.data.validacion) {
                setMensajeCiente(resp.data.mensaje);
                setOpen(true);
                setContinuaProceso(true);
                //setLoadingPreevaluar(false);
              } else {
                setMensajeCiente(resp.data.mensaje);
                setOpen(true);
                setContinuaProceso(false);
                //setLoadingPreevaluar(false);
              }
            }
          } catch (err) {
            console.log(err.message);
          }
        }
      } else {
        alert("Cliente ya existe por favor inicie Sesion");
        //debugger;
        let response = await SignUpCliExistente(cedula, celular, dactilar);
        if (response.state) {
          try {
            let idCliente;
            let idFlujo;
            const rspCli = await obtenerDatosPreevaluacion(
              cedula,
              authUser.key
            );
            if (rspCli.state) {
              idCliente = rspCli.data.idCliente;
              const rspSolicitud = await obtenerSolicitud(
                rspCli.data.idSolicitud
              );
              if (rspSolicitud.state) {
                idFlujo = rspSolicitud.data.idFlujo;
              }
            }

            const srvRegistroCliente = await obtenerRegistroCliente(
              cedula,
              localStorage.getItem("idGrupoStorage")
            );
            if (srvRegistroCliente.state) {
              let registro = srvRegistroCliente.data;
              registro.secuencia = 6;
              registro.procesado = true;
              registro.idCliente = idCliente;
              registro.idFlujo = idFlujo;
              try {
                var srvGrabarRegistro = await grabarRegistroCLiente(registro);
              } catch (e) {
                //console.log(e);
              }
            }
          } catch (e) {
            //console.log(e.message);
          }

          history.push("/signincli");
        }
      }
    } catch (e) {
      console.log(e.message);
    }

    //mutation.mutate({email, password});
  };

  const redirectFlujoPunta = async () => {
    const path = "/credito/flujop";
    history.push(path, { state: { identificacion: cedula } });
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Div
      sx={{
        flex: 1,
        marginTop: 20,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(4),
      }}
    >
      <Box
        component="img"
        src={`${process.env.REACT_APP_IMAGES_PATH}/logo-${origen}.png`}
        sx={{
          justifyContent: "center",
          width: 350,
          height: 140,
        }}
      />
      <Card sx={{ maxWidth: "100%", width: 360, mb: 4 }}>
        <Div sx={{ position: "relative", height: "200px" }}>
          <CardMedia
            component="img"
            height="200"
            sx={{ backgroundColor: color }}
          />
          <Div
            sx={{
              flex: 1,
              inset: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              p: (theme) => theme.spacing(3),
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                color: "common.white",
                fontSize: "1.5rem",
                mb: 0,
              }}
            >
              Registro de Datos
            </Typography>
          </Div>
        </Div>
        <CardContent sx={{ pt: 0 }}>
          <Formik
            validateOnChange={true}
            initialValues={{
              dactilar: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              dactilarLocal = data.dactilar;
              onSignIn(data.dactilar);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                {/* <Avatar
                  sx={{
                    width: 56,
                    height: 56,
                    marginLeft: "auto",
                    boxShadow: shadows[3],
                    transform: "translateY(-50%)",
                  }}
                /> */}
                {mutation.isError && <p>{mutation.error.message}</p>}
                <Div sx={{ mb: 2, mt: 1, marginTop: 10 }}>
                  <Typography>
                    Ingresa tu Código Dactilar
                    <InfoPopoverButton
                      nombre={"CD"}
                      texto={`<p> El codigo Dactilar se encuentra impreso en el reverso <br/>de la cédula de identidad, en la parte superior derecha</p>`}
                    >
                      <Stack alignItems={"center"} justifyContent={"center"}>
                        <Box
                          component="img"
                          src={`${process.env.REACT_APP_IMAGES_PATH}/dactilar.jpg`}
                          sx={{
                            justifyContent: "center",
                            width: 360,
                            height: 192,
                            mb: 3,
                          }}
                        />
                      </Stack>
                    </InfoPopoverButton>
                    <br />
                    <br />
                  </Typography>
                  <JumboTextField
                    fullWidth
                    name="dactilar"
                    //label="Código Dactilar"
                    //type="password"
                    focused={true}
                  />
                </Div>
                {/* <Div sx={{ mb: 3, mt: 1 }}>
                  <JumboTextField
                    fullWidth
                    name="celular"
                    label="Número Celular"
                  />
                </Div> */}

                <LoadingButton
                  //disabled = {error}
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting || mutation.isLoading}
                >
                  CONTINUAR
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            backgroundColor: continuaProceso ? "#389F26" : "#BD0808",
            margin: 0,
          }}
          id="alert-dialog-title"
        >
          <Stack direction={"row"} spacing={3} alignItems={"center"}>
            <Typography style={{ color: "white" }} variant="h2">
              {continuaProceso
                ? "PREEVALUACION EXITOSA"
                : "PREEVALUACION NO EXITOSA"}
            </Typography>
            {continuaProceso ? (
              <SentimentSatisfiedAltIcon
                style={{ color: "white" }}
                fontSize="large"
              />
            ) : (
              <SentimentVeryDissatisfiedIcon
                style={{ color: "white" }}
                fontSize="large"
              />
            )}
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ mt: 5 }}>
          <Typography variant="h2">{mensajeCliente}</Typography>
          <Stack
            mt={5}
            direction={"row"}
            spacing={3}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Button
              //variant="outlined"
              size="large"
              onClick={handleClose}
              autoFocus
            >
              Salir
            </Button>
            <Button
              variant="outlined"
              size="large"
              disabled={!continuaProceso}
              onClick={redirectFlujoPunta}
            >
              Continuar
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Button
        size="lg"
        variant="outlined"
        startIcon={<HouseIcon />}
        onClick={() => {
          window.open(url, "_self");
        }}
      >
        <Typography> Regresar a la pagina Principal</Typography>
      </Button>
    </Div>
  );
};

export default Datos;
