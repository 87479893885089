import http from "../../../comunication/http-common";

/* eslint-disable camelcase */

//http://10.10.0.79:5000/cliente/obtenerContacto?idCliente=287056&tipo=DIRDOM
export const consultaValidacionData = async (contacto, tipoDato) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CLIENTE}cliente/obtenerValidezContacto?contacto=${contacto}&tipo=${tipoDato}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });

    /* setTimeout(() => {
        const isEmailValid = contacto === "0900000000"; // Simulando validación en el servidor
        const miObjeto = {
          state: true,
          data: {}, //{ErrorContactoDto - bool}
          code: "00",
          message: "",
          guid: null,
        };
  
        const miObjetoReject = {
          state: true,
          data: null,
          code: "00",
          message: "",
          guid: null,
        };
  
        if (isEmailValid) {
          resolve(miObjeto);
        } else {
          reject(miObjetoReject);
        }
      }, 1000); */
  });
};
