import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

export const NumberFormatPercent = React.forwardRef(
  function NumberFormatPercent(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        allowNegative={false}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString={false}
        suffix=" %"
        decimalScale={0}
        /* format="000" */
      />
    );
  }
);

NumberFormatPercent.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
