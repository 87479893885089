import React, { useState, createContext } from "react";

const valuesChatSide = {
  identificacion: "",
  idCliente: "",
  idGrupo: 4,
  connectionHub: null,
  mostrarCabecera: false,
  isCallCenter: false,
  changeSection: false,
  clienteSeleccionado: {},
};

const ChatSideContext = createContext({});

export function ContextProviderChatSide({ children }) {
  const [informacionChatSide, setInformacionChatSide] =
    useState(valuesChatSide);

  return (
    <ChatSideContext.Provider
      value={{ informacionChatSide, setInformacionChatSide }}
    >
      {children}
    </ChatSideContext.Provider>
  );
}

export default ChatSideContext;
