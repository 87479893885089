import React, { useEffect, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  FormControl,
  Grid,
  MenuItem,
  TextField,
  FormHelperText,
  Select,
  InputLabel,
} from "@mui/material";

import { formatoFecha } from "../../shared/validations/validaciones";
import { consultaCatalogosSinPadre } from "../../app-cliente/services/Catalogos/catalogosService";

import {
  consultaCabeceraProducto,
  crearInformacionArticulo,
} from "../Services/siniestrosService";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

const FormProducto = ({ idSiniestro, setIdProductoAux }) => {
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [datosBien, setDatosBien] = useState(null);
  const [loading, setLoading] = useState(false);
  const [idSiniestroAux, setIdSiniestroAux] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const { state, data } = await consultaCabeceraProducto(idSiniestro);

        if (state) {
          console.log(data);
          setDatosBien(data);
        } else {
          setDatosBien({});
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const informacionInfoArticulo = {
      idInformacionArticulo: Number(idSiniestroAux),
      idSiniestro: Number(idSiniestro),
      idClasificacionArticulo: Number(valores.idClasificacionArticulo),
      articulo: valores.articulo.toUpperCase(),
      codigoBarras: valores.codigoBarras,
      dot: valores.dot,
      fechaCompra: valores.fechaCompra,
      marca: valores.marca,
      modelo: valores.modelo.toUpperCase(),
      numeroFactura: valores.numeroFactura,
      numeroSerie: valores.numeroSerie,
      placa: valores.placa,
      valor: valores.valor,
      imei: valores.imei,
      esActivo: true,
    };

    console.log(informacionInfoArticulo);
    /*  enviarSolicitudFlujo(); */

    try {
      const respuestaInformacionProducto = await crearInformacionArticulo(
        informacionInfoArticulo
      );
      /* console.log(respuestaInformacionProducto); */

      if (respuestaInformacionProducto.state) {
        setDatosEnviadosCorrectamente(true);

        setIdSiniestroAux(respuestaInformacionProducto.data);
        setIdProductoAux(respuestaInformacionProducto.data);

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
        }, 100);
      } else {
        setLoading(false);
        alert(`${respuestaInformacionProducto.message}`);
      }
    } catch (error) {
      alert(`${error.response.data.message}`);
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {datosBien ? (
        <>
          <Formik
            initialValues={{
              /* informacion producto */
              idClasificacionArticulo: datosBien.idClasificacion || "",
              numeroFactura: datosBien.numeroFactura || "",
              codigoBarras: datosBien.codigoBarras || "",

              marca: datosBien.codMarca || "",
              modelo: datosBien.modelo || "",
              articulo: datosBien.descripcionBien || "",
              fechaCompra: datosBien.fechaInicio || "",
              numeroSerie: datosBien.serie || "",
              valor: datosBien.valorBien || "",
              dot: datosBien.dot || "",
              placa: datosBien.placa || "",
              imei: datosBien.imei || "",
            }}
            validationSchema={Yup.object({
              idClasificacionArticulo: Yup.string().required(
                "Clasificación requerida"
              ),
              numeroFactura: Yup.string().required("N° Factura requerida"),

              marca: Yup.string().required("Marca requerida"),
            })}
            validate={async (values) => {
              const errors = {};

              /* if (!values.email) {
                errors.email = "Required";
              } else if (!(await verificacionAsincronoEmail(values.email))) {
                errors.email = "El email no fue encontrado";
              } */

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              configuracionDatosEnvio(values);
            }}
          >
            {({ values, handleChange, touched, errors, handleBlur }) => (
              <Form>
                <SCSAccordion tituloCabecera="Información Producto">
                  <Grid container spacing={3} rowSpacing={2}>
                    <Grid item md={12}>
                      <Grid container spacing={3} rowSpacing={2}>
                        <Grid item md={12}>
                          {/* spacing entre filas */}
                          <Grid container spacing={3}>
                            <Grid item md={12}>
                              <div className="cliente-informacion-seccion">
                                <SelectItemsCatalogoSinPadre
                                  pais="ECU"
                                  especial="true"
                                  catalogos="CLAART"
                                  name="idClasificacionArticulo"
                                  labelName="Clasificación Articulo"
                                  grupo={1}
                                  componentSize="19%"
                                  handleBlur={handleBlur}
                                  touched={touched}
                                  errors={errors}
                                />

                                <TextField
                                  disabled
                                  id="codigoBarras"
                                  name="codigoBarras"
                                  type="text"
                                  label="Codigo Barras"
                                  sx={{ width: "18%" }}
                                  value={values.codigoBarras}
                                  onChange={handleChange}
                                  size="small"
                                />
                                <TextField
                                  disabled
                                  id="fechaCompra"
                                  label="Fecha Compra"
                                  type="date"
                                  size="small"
                                  sx={{ width: "15%" }}
                                  onChange={handleChange}
                                  value={formatoFecha(values.fechaCompra)}
                                  InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                  disabled
                                  id="valor"
                                  name="valor"
                                  label="Valor Del Bien"
                                  sx={{ width: "15%" }}
                                  value={values.valor}
                                  onChange={handleChange}
                                  size="small"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                  }}
                                />
                                <TextField
                                  id="numeroFactura"
                                  name="numeroFactura"
                                  type="text"
                                  label="Número Factura"
                                  sx={{ width: "20%" }}
                                  value={values.numeroFactura}
                                  onChange={handleChange}
                                  size="small"
                                  inputProps={{
                                    style: {
                                      textTransform: "uppercase",
                                    },
                                  }}
                                  error={
                                    touched.numeroFactura &&
                                    Boolean(errors.numeroFactura)
                                  }
                                  helperText={
                                    touched.numeroFactura &&
                                    errors.numeroFactura
                                  }
                                  onBlur={handleBlur}
                                />
                              </div>

                              <div className="cliente-informacion-seccion">
                                <TextField
                                  id="articulo"
                                  name="articulo"
                                  type="text"
                                  label="Descripción Articulo"
                                  sx={{ width: "33%" }}
                                  value={values.articulo}
                                  onChange={handleChange}
                                  size="small"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                />

                                <SelectItemsCatalogoSinPadre
                                  pais="ECU"
                                  especial="true"
                                  catalogos="MARCA"
                                  name="marca"
                                  labelName="Marca"
                                  grupo={1}
                                  componentSize="18%"
                                  handleBlur={handleBlur}
                                  touched={touched}
                                  errors={errors}
                                />

                                <TextField
                                  id="modelo"
                                  name="modelo"
                                  type="text"
                                  label="Modelo"
                                  sx={{ width: "18%" }}
                                  value={values.modelo}
                                  onChange={handleChange}
                                  size="small"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                />

                                <TextField
                                  id="numeroSerie"
                                  name="numeroSerie"
                                  type="text"
                                  label="N° de Serie"
                                  sx={{ width: "20%" }}
                                  value={values.numeroSerie}
                                  onChange={handleChange}
                                  size="small"
                                  inputProps={{
                                    style: { textTransform: "uppercase" },
                                  }}
                                />
                              </div>

                              {true && (
                                <>
                                  <div className="cliente-informacion-seccion">
                                    <TextField
                                      id="dot"
                                      name="dot"
                                      type="text"
                                      label="DOT"
                                      sx={{ width: "20%" }}
                                      value={values.dot}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: {
                                          textTransform: "uppercase",
                                        },
                                      }}
                                    />

                                    <TextField
                                      id="placa"
                                      name="placa"
                                      type="text"
                                      label="Placa"
                                      sx={{ width: "20%" }}
                                      value={values.placa}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: {
                                          textTransform: "uppercase",
                                        },
                                      }}
                                    />

                                    <TextField
                                      id="imei"
                                      name="imei"
                                      type="text"
                                      label="IMEI"
                                      sx={{ width: "20%" }}
                                      value={values.imei}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: {
                                          textTransform: "uppercase",
                                        },
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <div className="confirmar-estados-servicios">
                        <SCSLoadingButton loading={loading} titulo="GUARDAR" />

                        {datosEnviadosCorrectamente && (
                          <AcceptServiceresponse />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </SCSAccordion>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <SpinnerLoadSrx />
      )}
    </>
  );
};

export default FormProducto;
