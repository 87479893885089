import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import FormBeneficiarioCitaMedica from "../components/FormBeneficiarioCitaMedica";
import FormCitasMedicas from "../components/FormCitasMedicas";
import TablaBeneficiariosCitasMedicas from "../components/TablaBeneficiariosCitasMedicas";
import HeaderBeneficiario from "./HeaderBeneficiario";

const BeneficiarioCitaMedica = ({
  idCliente,
  idProducto,
  identificacionCliente,
  setInformacionBeneficiario,
}) => {
  const [idPacienteAux, setIdPacienteAux] = useState(null);
  const [addBeneficiario, setAddBeneficiario] = useState(false);

  const [actualizarNuevoBeneficiario, setActualizarNuevoBeneficiario] =
    useState(null);
  const [beneficiariosCliente, setBeneficiariosCliente] = useState([]);

  useEffect(() => {
    console.log(actualizarNuevoBeneficiario);
  }, [actualizarNuevoBeneficiario]);

  return (
    <div className="accordion-container">
      <Button
        variant="contained"
        onClick={() => setAddBeneficiario(!addBeneficiario)}
        endIcon={<SendIcon />}
      >
        Añadir Beneficiario
      </Button>

      {!addBeneficiario && (
        <>
          <FormBeneficiarioCitaMedica
            idCliente={idCliente}
            idProducto={idProducto}
            identificacionCliente={identificacionCliente}
            setActualizarNuevoBeneficiario={setActualizarNuevoBeneficiario}
            setBeneficiariosCliente={setBeneficiariosCliente}
          />

          <TablaBeneficiariosCitasMedicas
            identificacionCliente={identificacionCliente}
            setIdPacienteAux={setIdPacienteAux}
            setInformacionBeneficiario={setInformacionBeneficiario}
            beneficiariosCliente={beneficiariosCliente}
            setAddBeneficiario={setAddBeneficiario}
          />
        </>
      )}

      {idPacienteAux ? (
        <>
          <FormCitasMedicas
            idCliente={idCliente}
            idProducto={idProducto}
            idPaciente={idPacienteAux}
          />
        </>
      ) : (
        <h2>Escoja un beneficiario !! </h2>
      )}
    </div>
  );
};

export default BeneficiarioCitaMedica;
