import React, { useEffect, useState } from "react";

import { Skeleton } from "@mui/material";

import {
  SCSTableData,
  SCSTableDataRow,
  SCSTableDataCell,
} from "app/modules/common/components/TableData/SCSTableData";

import {
  consultarPagos,
  consultarPagosTransaccional,
} from "../services/transaccionalService";
import DetalleOpcionGenerica from "./DetalleOpcionGenerica";

const DetallePagosTransaccional = ({
  cabenceraTransaccional,
  datosBusqueda,
  alturaResolucion,
}) => {
  const [estadosCredito, setEstadosCredito] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaEstadosCredito = await consultarPagos(
          datosBusqueda.identificacion,
          datosBusqueda.idGrupo,
          9
        );

        if (respuestaEstadosCredito.state && respuestaEstadosCredito.data) {
          setEstadosCredito(respuestaEstadosCredito.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={alturaResolucion}
        sx={{ borderRadius: "32px" }}
      />
    );
  }

  if (estadosCredito.length <= 0) {
    return (
      <DetalleOpcionGenerica mensaje={"No existe información del cliente."} />
    );
  }

  const columnsData = [
    { id: 1, name: "Comprobante" },
    { id: 4, name: "Fecha" },
    { id: 2, name: "Local" },
    { id: 3, name: "Valor" },
    { id: 5, name: "Tipo Pago" },
    { id: 6, name: "Canal" },
  ];

  return (
    <SCSTableData columnsData={columnsData} heightTable={alturaResolucion}>
      {estadosCredito?.map((pago) => (
        <SCSTableDataRow key={pago.comprobante}>
          <SCSTableDataCell align="center">{pago.comprobante}</SCSTableDataCell>
          <SCSTableDataCell align="center">{pago.fecha}</SCSTableDataCell>
          <SCSTableDataCell align="center">{pago.local}</SCSTableDataCell>
          <SCSTableDataCell align="center">{`$ ${pago.valor}`}</SCSTableDataCell>
          <SCSTableDataCell align="center">{pago.tipoPago}</SCSTableDataCell>
          <SCSTableDataCell align="center">{pago.canal}</SCSTableDataCell>
        </SCSTableDataRow>
      ))}
    </SCSTableData>
  );
};

export default DetallePagosTransaccional;
