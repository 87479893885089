import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";

import { formatoFecha } from "../../shared/validations/validaciones";

import { consultaComentarios } from "../Services/siniestrosService";
import { formatoFechaHora } from "app/modules/shared/commonValues/obtenerFecha";
import {
  SCSTableData,
  SCSTableDataCell,
  SCSTableDataRow,
} from "app/modules/common/components/TableData/SCSTableData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ComentariosTabla = ({ idSiniestro, isUpdating }) => {
  const [comentarios, setComentarios] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { state, data } = await consultaComentarios(idSiniestro);

        if (state) {
          /*  console.log(data); */
          setComentarios(data);
        } else {
          setComentarios([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [isUpdating]);

  const columnsData = [
    { id: 1, name: "Fecha" },
    { id: 2, name: "Usuario" },
    { id: 3, name: "Comentario" },
  ];

  return (
    <div>
      {comentarios.length > 0 ? (
        <SCSTableData columnsData={columnsData} heightTable={30}>
          {comentarios.map((comentario, index) => (
            <SCSTableDataRow key={index}>
              <SCSTableDataCell>
                {formatoFechaHora(comentario.fechaCreacion)}
              </SCSTableDataCell>
              <SCSTableDataCell>{comentario.usuarioCreacion}</SCSTableDataCell>
              <SCSTableDataCell>{comentario.comentario1}</SCSTableDataCell>
            </SCSTableDataRow>
          ))}
        </SCSTableData>
      ) : (
        <h1>No existen comentarios !!!</h1>
      )}
    </div>
  );
};

export default ComentariosTabla;
