import React from "react";

const PagosActualizar = () => {
  const redirectAkiWeb = () => {
    console.log("hola mundo desde el redirect");
    window.open("http://www.akitranquilo.ec");
  };

  const redirectMaxiWeb = () => {
    console.log("hola mundo desde el redirect");
    window.open("http://www.maxiseguros.ec");
  };

  return (
    <div className="actualizarPagos-container">
      <div>
        <img
          onClick={redirectMaxiWeb}
          className="actualizarPagos-logos"
          src={
            "https://maxiseguros.ec/wp-content/uploads/2020/08/logo-maxiseguros.png"
          }
          alt="logo-supermaxi"
        />
      </div>

      <div /* className="actualizarPagos-logos-container" */>
        <img
          className="actualizarPagos-logos"
          onClick={redirectAkiWeb}
          src={
            "https://akitranquilo.ec/wp-content/uploads/2020/11/cropped-logotipo-akitranquilo.png"
          }
          alt="logo-aki"
        />
      </div>
    </div>
  );
};

export default PagosActualizar;
