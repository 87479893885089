import React, { useEffect } from "react";
import FormGestionParametros from "../components/FormGestionParametros";
import { consultaParametroPeriodo } from "../services/cobranzaServices";
import { useState } from "react";

const DetalleGestionParametros = ({ valores }) => {
  const [detallePeriodo, setDetallePeriodo] = useState({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(valores);

    const getParametrosPeriodo = async () => {
      try {
        const respuestaParametrosPeriodo = await consultaParametroPeriodo(
          valores.data
        );

        console.log(respuestaParametrosPeriodo);

        if (respuestaParametrosPeriodo.state) {
          setDetallePeriodo(respuestaParametrosPeriodo.data[0]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getParametrosPeriodo();
  }, [valores.data]);

  if (loading) {
    return (
      <>
        <h2>Cargando!!</h2>
      </>
    );
  }

  return (
    <div>
      <FormGestionParametros detallePeriodo={detallePeriodo} />
    </div>
  );
};

export default DetalleGestionParametros;
