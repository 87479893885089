import React, { useEffect, useState } from "react";
import { useFormikContext, Formik, Form, Field, useField } from "formik";
import { useSelector } from "react-redux";


import {
  Alert,
  Button,
  Grid,
  Stack,
  TextField,
  Box,
  InputAdornment,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  grabarSolicitud,
  verificarCupoTarjeta,
  grabarVerificacion,
  obtenerSolicitudAumentoCupo,
  grabarAumentoCupo,
  obtenerDatosAprobacion,
  obtenerAumentoCupo,
  obtenerCabeceraCliente,
} from "../services/creditoServices";

import "../styles/main.css";
import { obtenerLocal, obtenerParametroGrupo } from "app/modules/app-configuracion/services/configuracionServices";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import {
  consultaInfoDemografica,
  grabarDocumentoFirmadoCF,
  grabarPersonaCorporativo,
} from "app/modules/app-cliente/services/clienteService";
import {
  grabarAceptacion,
  obtenerAceptacion,
} from "app/modules/app-evaluacion/services/evaluacionServices";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";

const FormSolicitudAumentoCupo = ({
  idCli,
  cliente,
  identifCliente,
  idSolicitudChanger,
}) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const [clienteInfo, setClienteInfo] = useState(null);

  const [idSolicitudActual, setIdSolicitudActual] = useState(null);
  const [solicitudActual, setSolicitudActual] = useState(null);
  const [montoAprobado, setMontoAprobado] = useState(null);
  const [montoDisponible, setMontoDisponible] = useState(null);
  const [montoUtilizado, setMontoUtilizado] = useState(null);

  const [montoMinimo, setMontoMinimo] = useState();
  const [montoMaximo, setMontoMaximo] = useState();

  const [datosCupo, setDatosCupo] = useState(null);
  const [docBase64, setDocBase64] = useState("");
  const [mostrarLocal, setMostrarLocal] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [expanded, setExpanded] = useState(false);
  const [local, setLocal] = useState();
  const [seleccionadoGravamen, setSeleccionadoGravamen] = useState(true);
  const [mensajeError, setMensajeError] = useState("");
  const [mostrarMensajeError, setMostrarMensajeError] = useState(false);

  useEffect(() => {
    //console.log(idCli);
    getLocal();
    //getDatosCupo(identifCliente);
    

    const getSolicitud = async () => {
      let infoSolicitud;
      try {
        const solicit = await obtenerSolicitudAumentoCupo(identifCliente, authUser.usuarioFlujos[0].idGrupo);
        if (solicit.state) {
          if (solicit.data == null)
          {
           infoSolicitud = {};
           setIdSolicitudActual(null);
          }  
          else {
            infoSolicitud = solicit.data;
            setIdSolicitudActual(infoSolicitud.idSolicitud);
            setSeleccionadoGravamen(infoSolicitud.segDesgravamen);
            setMontoAprobado(infoSolicitud.montoAprobado);
          }
        } else {
          console.log(solicit.message);
        }
      } catch (_errors) {
        console.log(_errors);
      }

      const res = {
        ...infoSolicitud,
      };

      setSolicitudActual(res);
    };

    const enviarPersonaCF = async () => {
      //const idLocal = authUser.oficina;
      const respInfoAceptacion = await obtenerAceptacion(identifCliente);
      if (respInfoAceptacion.state) {
        const infoAceptacion = respInfoAceptacion.data;
        console.log(infoAceptacion.idGrupo);
        if (infoAceptacion.idGrupo === 1) {
          try {
            console.log("Enviando datos CF");
            const response = await obtenerCabeceraCliente(idCli);
            //console.log(response.data);
            const responseinfDem = await consultaInfoDemografica(idCli);

            if (!respInfoAceptacion.data.enviaDatos) {
              const documentosFirmados = [];
              if (infoAceptacion.esCliente) {
                // Esta registrado en CF

                if (!infoAceptacion.documentosFirmados) {
                  if (infoAceptacion.ppr) documentosFirmados.push("PPR");
                  if (infoAceptacion.tcu) documentosFirmados.push("TCD");

                  const documentosEnviados = {
                    documentNumber: identifCliente,
                    typeDocumenteNumber: "CI",
                    typeDocumentLegal: documentosFirmados.toString(),
                    system: "SCS",
                    clientIp: "34.173.105.14",
                  };
                  console.log(documentosEnviados);

                  try {
                    const respSaveLegalDoc = await grabarDocumentoFirmadoCF(
                      documentosEnviados
                    );
                    if (respSaveLegalDoc.state) {
                      if (respSaveLegalDoc.data.response.id === 1) {
                        infoAceptacion.enviaDatos = true;
                        console.log(respSaveLegalDoc);
                      } else {
                        infoAceptacion.enviaDatos = false;
                        console.log(respSaveLegalDoc.data.response.message);
                      }
                    }
                  } catch (e) {
                    alert("Error al grabar archivos firmados TyC" + e);
                  }

                  console.log(documentosFirmados.toString());
                } else {
                  console.log("No firma nada");
                }
              } // No esta registrado en CF
              else {
                try {
                  const infoCabecera = response.data;
                  const infoDemografica = responseinfDem.data;

                  const personaCorporativo = {
                    tipoDocumento: cliente.tipoIdentificacion || "CI",
                    numeroDocumento: identifCliente,
                    primerNombre: infoCabecera.primerNombre,
                    segundoNombre: infoCabecera.segundoNombre,
                    primerApellido: infoCabecera.primerApellido,
                    segundoApellido: infoCabecera.segundoApellido,
                    genero: infoDemografica.genero,
                    userId: "USR1737940",
                    sistemaModificacion: "CRD", //CRD
                    origenDato: "CRD", //CRD
                    contacto: {
                      codigoCompania: 1,
                      codigoTipoContacto: "PCD",
                      numeroTelefonicoCelular: infoCabecera.celular,
                      numeroTelefonicoPrincipal: infoCabecera.telefono,
                      callePrincipalDireccionPrincipal:
                        infoCabecera.callePrincipal,
                      numeroDireccionPrincipal: infoCabecera.numeroCasa,
                      calleTransversalDireccionPrincipal:
                        infoCabecera.calleSecundaria,
                      emailPrincipal: infoCabecera.email,
                      codigoSistema: "CREDIMAX",
                      userId: "USR1737940",
                    },
                  };
                  console.log(personaCorporativo);

                  try {
                    //const respGrabarPerson = await registrarPersonaCf(personaCF);
                    const respGrabarPerson = await grabarPersonaCorporativo(
                      personaCorporativo
                    );

                    console.log(respGrabarPerson);
                    if (respGrabarPerson.data.codigoPersona) {
                      if (!infoAceptacion.documentosFirmados) {
                        if (infoAceptacion.ppr) documentosFirmados.push("PPR");
                        if (infoAceptacion.tcu) documentosFirmados.push("TCD");

                        const documentosEnviados = {
                          documentNumber: identifCliente,
                          typeDocumenteNumber: "CI",
                          typeDocumentLegal: documentosFirmados.toString(),
                          system: "SCS",
                          clientIp: "34.173.105.14",
                        };
                        console.log(documentosEnviados);
                        try {
                          const respSaveLegalDoc =
                            await grabarDocumentoFirmadoCF(documentosEnviados);
                          if (respSaveLegalDoc.state) {
                            if (respSaveLegalDoc.data.response.id === 1) {
                              infoAceptacion.enviaDatos = true;
                              console.log(respSaveLegalDoc);
                            } else {
                              infoAceptacion.enviaDatos = false;
                              console.log(
                                respSaveLegalDoc.data.response.message
                              );
                            }
                          }
                        } catch (e) {
                          infoAceptacion.enviaDatos = false;
                          alert("Error al grabar archivos firmados TyC" + e);
                        }
                      }
                    } else {
                      alert("Error a grabar persona CF");
                    }
                  } catch (e) {
                    alert("Error al resgistrar persona CF" + e);
                  }
                } catch (e) {
                  alert(e);
                }
              }

              const srvGrabarAceptacion = await grabarAceptacion(
                infoAceptacion
              );
              //console.log(srvGrabarAceptacion);
            } else {
              //console.log("No hace nada");
            }
          } catch (_errors) {
            console.log(_errors);
          }
        } else {
          //no hace nada porque no es CF
          //console.log("no envia porque no es cf");
        }
      } else {
        //No hace nada
      }
    };

    enviarPersonaCF();
    getMontoMaximo();
    getSolicitud();
    console.log(solicitudActual);
    console.log(idSolicitudActual);
  }, []);

  const getMontoMaximo = async () => {
    obtenerParametroGrupo("MTOMAXCRE", authUser.usuarioFlujos[0].idGrupo)
      .then((response) => {
        if (response.state) {
          console.log(response.data);
          setMontoMinimo(response.data.valorDesde);
          setMontoMaximo(response.data.valorHasta);
        } else {
          console.log("Error al obtener monto maximo ", response.message);
        }
      })
      .catch((_errors) => {
        console.log(_errors);
      });
  };

  // const getDatosCupo = async (identificacion) => {
  //   const montos = await verificarCupoTarjeta(identificacion);
  //   if (montos.state) {
  //     setMontoAprobado(montos.data.otorgado);
  //     setMontoDisponible(montos.data.disponible);
  //   } else {
  //     console.log(montos.message);
  //   }
  // };

  const getLocal = async () => {
    const idLocal = authUser.oficina;
    console.log(idLocal);
    obtenerLocal(idLocal)
      .then((response) => {
        if (response.state) {
          if (response.data == null) {
            console.log("Error al obtener local");
            console.log(response);
          } else setLocal(response.data);
        } else {
          console.log("Error al obtener local");
          console.log(response);
        }
      })
      .catch((_errors) => {
        console.log(_errors);
      });
  };

  const fileToBase64 = async (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);

  const changeHandler = (event) => {
    let doc = "";
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
    fileToBase64(event.target.files[0], (err, result) => {
      if (result) {
        doc = result.slice(28);
        console.log(doc);
        setDocBase64(doc);
      }
    });
  };

  const configuracionDatosEnvio = async (values) => {
    console.log(solicitudActual);
    const date = new Date();
    const hoy = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];

    let aumentCupo;
    let grabaAumentoCupo = false;
    var respDatosAumentoCupo = await obtenerAumentoCupo(
      solicitudActual.idSolicitud
    );
    if (respDatosAumentoCupo.state) {
      aumentCupo = respDatosAumentoCupo.data;
    } else {
      aumentCupo = {};
      console.log(respDatosAumentoCupo.message);
    }
    console.log(aumentCupo);
    if (aumentCupo !== null) {
      const fechaAumento = await aumentCupo.fechaSolicitud.split("T");

      //console.log(fechaAumento[0]);
      //console.log(hoy);
      if (fechaAumento[0] === hoy) {
        grabaAumentoCupo = false;
        setMensajeError(
          "Ya existe una solicitud de Aumento de monto en proceso"
        );
        setMostrarMensajeError(true);
        setTimeout(() => {
          setMostrarMensajeError(false);
        }, 3000);
      } else {
        grabaAumentoCupo = true;
      }
    } else grabaAumentoCupo = true;
    if (grabaAumentoCupo) {
      let idSolicitudAct = solicitudActual.idSolicitud;

      const today = new Date().toLocaleString("en-US");
      const infoSolicitud = {
        idSolicitud: idSolicitudAct,
        idCliente: solicitudActual.idCliente,
        tipoIdentificacion: solicitudActual.tipoIdentificacion,
        identificacion: solicitudActual.identificacion,
        numeroSolicitud: solicitudActual.numeroSolicitud,
        idLocal: local.codigo,
        idFlujo: solicitudActual.idFlujo,
        idEstado: 4,
        IdVendedor: authUser.idUsuario.toString(),
        codigoBarras: solicitudActual.codigoBarras,
        montoSolicitado: values.montoSolicitadoTotal,
        montoAprobado: montoAprobado,
        tipoCliente: solicitudActual.tipoCliente,
        fechaCorte: solicitudActual.fechaCorte,
        fechaSolicitud: today,
        esActivo: true,
        migracion: solicitudActual.migracion,
        tieneSeguro: seleccionadoGravamen,
        ingresoFijoMensual: solicitudActual.ingresoFijoMensual,
        aumentoCupo: true,
        fechaCreacion: solicitudActual.fechaCreacion,
        usuarioCreacion: solicitudActual.usuarioCreacion,
        estacionCreacion: solicitudActual.estacionCreacion,
        fechaModificacion: today,
        usuarioModificacion: authUser.key,
        estacionModificacion: authUser.estacion,
      };

      console.log(infoSolicitud);

      try {
        console.log("Envio de peticiones al POST");

        const respGrabarSolicitud = await grabarSolicitud(infoSolicitud);
        idSolicitudChanger(idSolicitudAct);
        console.log(idSolicitudAct);
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
        }, 5000);
      } catch (error) {
        console.log(error);
      }

      const verificacion = {
        idSolicitud: idSolicitudAct,
        tipo: "Aumento Cupo",
        observacion: values.observacion || "",
        esActivo: true,
        fechaCreacion: today,
        usuarioCreacion: authUser.key,
        estacionCreacion: authUser.estacion,
        fechaModificacion: today,
        usuarioModificacion: authUser.key,
        estacionModificacion: authUser.estacion,
      };

      //console.log(verificacion);

      try {
        console.log("Envio de observacion a base");

        await grabarVerificacion(verificacion);
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
        }, 3000);
      } catch (error) {
        console.log(error);
      }

      const datosAprobacion = await obtenerDatosAprobacion(idSolicitudAct);
      //Grabar Aumento Cupo

      const aumentoCupo = {
        idAumentoCupo: 0,
        IdSolicitud: idSolicitudAct,
        IdEstado: 4,
        IdVendedor: authUser.idUsuario.toString(),
        IdLocal: local.codigo,
        FechaSolicitud: today,
        MontoSolicitado: values.montoSolicitadoTotal,
        idEstadoOri: solicitudActual.idEstado,
        idVendedorOri: solicitudActual.idVendedor,
        idLocalOri: solicitudActual.idLocal,
        fechaSolicitudOri: solicitudActual.fechaSolicitud,
        montoSolicitadoOri: solicitudActual.montoSolicitado,
        montoAprobadoOri: montoAprobado,
        ingresoFijoMensualOri: solicitudActual.ingresoFijoMensual,
        EsActivo: true,
        fechaCreacion: today,
        usuarioCreacion: authUser.key,
        estacionCreacion: authUser.estacion,
        fechaModificacion: today,
        usuarioModificacion: authUser.key,
        estacionModificacion: authUser.estacion,
      };
      console.log(aumentoCupo);

      try {
        console.log("Envio de Aumento Cupo a base");

        await grabarAumentoCupo(aumentoCupo);
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
        }, 3000);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {idSolicitudActual ? (
        montoAprobado ? (
          <Formik
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {
              if(values.montoSolicitadoTotal > montoMaximo)
              {
                alert(`El nuevo monto de crédito no debe superar los $${montoMaximo}`)
              }
              else{
                //console.log(values);
                configuracionDatosEnvio(values);

              }
            }}
          >
            {({ values, handleChange }) => (
              <Form>
                <div className="accordion-container">
                  {/* Informacion de solicitud */}
                  <div>
                    <SCSAccordion tituloCabecera="Informacion de Solicitud ">
                      <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent="space-around"
                      >
                        <>
                          {local ? (
                            <div>
                              <b>
                                LOCAL : {local.codigo} - {local.descripcion}
                              </b>
                            </div>
                          ) : (
                            <SpinnerLoadSrx />
                          )}
                        </>
                        <Typography component="div">
                          <Box sx={{ fontWeight: "bold", m: 1 }}>
                            Monto Otorgado
                          </Box>
                          <Box sx={{ fontWeight: "regular", m: 1 }}>
                            $ {montoAprobado}
                          </Box>
                        </Typography>
                        <TextField
                          focused
                          id="montoSolicitadoAumento"
                          name="montoSolicitadoAumento"
                          type="number"
                          label="Monto que desea Aumentar "
                          sx={{ width: "20ch" }}
                          value={values.montoSolicitadoAumento}
                          onChange={handleChange}
                          size="large"
                          InputProps={{
                            inputProps: {
                              min: montoMinimo,
                              max: montoMaximo,
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          focused
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#000" },
                          }}
                          disabled
                          id="montoSolicitadoTotal"
                          name="montoSolicitadoTotal"
                          type="number"
                          label="Nuevo Monto de Credito"
                          sx={{ width: "17ch", color: "#000" }}
                          value={
                            (values.montoSolicitadoTotal =
                              montoAprobado + values.montoSolicitadoAumento)
                          }
                          onChange={handleChange}
                          size="large"
                          InputProps={{
                            inputProps: {
                              min: montoMinimo,
                              max: montoMaximo,
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            
                          }}
                        />
                      </Stack>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent="space-around"
                      >
                        <TextField
                          id="observacion"
                          name="observacion"
                          type="text"
                          label="Producto que desea llevar el cliente"
                          sx={{ width: "50ch" }}
                          value={values.observacion}
                          onChange={handleChange}
                          size="small"
                        />
                      </Stack>
                    </SCSAccordion>
                  </div>
                  <Stack
                    sx={{ marginTop: "1.5ch" }}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    justifyContent="center"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ width: "25ch" }}
                      /* className="accordion-item" */
                    >
                      {false ? "Actualizar" : "Guardar"}
                    </Button>
                  </Stack>
                  {datosEnviadosCorrectamente && (
                    <Alert severity="success" variant="filled">
                      Los datos han sido guardados correctamente.
                    </Alert>
                  )}
                  {mostrarMensajeError && (
                    <Alert severity="error" variant="filled">
                      {mensajeError}
                    </Alert>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <SpinnerLoadSrx />
        )
      ) : (
        <Alert severity="error">
          <Typography>No se puede generar una solicitud de aumento de monto.<br/>Comuniquese con soporte para mayor informacion.</Typography>
        </Alert>
      )}
    </>
  );
};

export default FormSolicitudAumentoCupo;
