import React, { useState } from "react";
import { Formik, Form } from "formik";

import * as Yup from "yup";

import { Alert, Button, Grid, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { crearGestionQueja } from "../services/gestionService";
import SeguimientoQuejasTabla from "./SeguimientoQuejasTabla";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const FormGestionQueja = ({ idCliente, idQueja }) => {
  const [clienteInfo, setClienteInfo] = useState([]);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [isUpdating, setIsUpdating] = useState(true);

  const configuraionEnvioDatos = async (valores) => {
    console.log(valores);

    const informacionGestionQueja = {
      idGestionQueja: 0,
      idQueja: Number(idQueja),
      accion: valores.accion.toUpperCase(),
      responsable: valores.responsable.toUpperCase(),
      comentario: valores.comentario.toUpperCase(),
      esActivo: true,
      /*  fechaCreacion,
      usuarioCreacion,
      estacionCreacion,
      fechaModificacion,
      usuarioModificacion,
      estacionModificacion, */
    };

    console.log(informacionGestionQueja);

    try {
      const respuestaGestionQuejas = await crearGestionQueja(
        informacionGestionQueja
      );
      console.log(respuestaGestionQuejas);
      setDatosEnviadosCorrectamente(true);
      setIsUpdating(!isUpdating);

      setTimeout(() => {
        setDatosEnviadosCorrectamente(false);
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {clienteInfo ? (
        <div className="accordion-container">
          <div>
            <Formik
              initialValues={{
                accion: "",
                responsable: "",
                comentario: "",
              }}
              validationSchema={Yup.object({
                accion: Yup.string().required(
                  "Nombre de quien reporta requerido"
                ),
                responsable: Yup.string().required("Responsable requerido"),
                comentario: Yup.string().required("Comentario requerido"),
              })}
              onSubmit={(valores, { resetForm }) => {
                configuraionEnvioDatos(valores);
                setTimeout(() => {
                  resetForm({ values: "" });
                }, 400);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  <div className="accordion-container">
                    <div>
                      <Accordion
                        defaultExpanded="true"
                        sx={{ boxShadow: 3, lineHeight: 0 }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          className="accordion-summary"
                          sx={{ margin: 0 }}
                        >
                          <h2 className="formulario-cabecera">
                            Seguimiento Queja
                          </h2>
                        </AccordionSummary>
                        <AccordionDetails sx={{ marginTop: "2rem" }}>
                          <Grid container spacing={1} rowSpacing={1}>
                            <Grid item md={12}>
                              <Grid container spacing={1}>
                                <Grid item md={12}>
                                  <div className="cliente-informacion-seccion">
                                    <TextField
                                      id="accion"
                                      name="accion"
                                      type="text"
                                      label="Nombre de quien reporta"
                                      sx={{ minWidth: 200 }}
                                      value={values.accion}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                      error={
                                        touched.accion && Boolean(errors.accion)
                                      }
                                      helperText={
                                        touched.accion && errors.accion
                                      }
                                      onBlur={handleBlur}
                                    />

                                    <TextField
                                      id="responsable"
                                      name="responsable"
                                      type="text"
                                      label="Responsable"
                                      sx={{ minWidth: 200 }}
                                      value={values.responsable}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                      error={
                                        touched.responsable &&
                                        Boolean(errors.responsable)
                                      }
                                      helperText={
                                        touched.responsable &&
                                        errors.responsable
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>

                                  <div className="cliente-informacion-seccion">
                                    <TextField
                                      id="comentario"
                                      name="comentario"
                                      type="text"
                                      label="Comentario"
                                      sx={{ width: "100%" }}
                                      value={values.comentario}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                      error={
                                        touched.comentario &&
                                        Boolean(errors.comentario)
                                      }
                                      helperText={
                                        touched.comentario && errors.comentario
                                      }
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                  {datosEnviadosCorrectamente && (
                                    <Alert severity="success" variant="filled">
                                      Los datos han sido guardados
                                      correctamente.
                                    </Alert>
                                  )}
                                  <div className="cliente-informacion-seccion">
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      sx={{ width: "25ch" }}
                                      /* className="accordion-item" */
                                    >
                                      Guardar
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <SeguimientoQuejasTabla idQueja={idQueja} isUpdating={isUpdating} />
        </div>
      ) : (
        <SpinnerLoadSrx />
      )}
    </>
  );
};

export default FormGestionQueja;
