import React, { useState, useEffect } from "react";
import ResumenInformacionCliente from "./ResumenInformacionCliente";
import { consultaCliente } from "../services/clienteService";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";

const DetalleReporteCliente = ({ datosBusqueda }) => {
  const [cliente, setCliente] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [identificacion, setIdentificacion] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  useEffect(() => {
    console.log(datosBusqueda);

    const getInformacionCliente = async () => {
      try {
        const respuestaCliente = await consultaCliente(
          datosBusqueda.identificacion
        );

        console.log(respuestaCliente);
        if (respuestaCliente.state) {
          setIdCliente(respuestaCliente.data.idCliente);
          setCliente(respuestaCliente.data);
          setIdentificacion(respuestaCliente.data.identificacion);

          setIsLoading(false);
        } else {
          setCliente(nuevoCliente);
          setIdCliente(nuevoIdCliente);

          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    getInformacionCliente();
  }, []);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  if (idCliente < 0) {
    return <h2>No existe informacion de este cliente!!!!</h2>;
  }

  return (
    <>
      <BotonGerericoSalir />
      
      <ResumenInformacionCliente
        cliente={cliente}
        idCliente={idCliente}
        identificacion={identificacion}
        datosBusqueda={datosBusqueda}
      />
    </>
  );
};

export default DetalleReporteCliente;
