import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Typography from "@mui/material/Typography";

import { LISTA_MENU } from "../constants/constants";
import { CardActionArea } from "@mui/material";

const DetalleMenuMobile = ({ datosBusqueda, setDatosBusqueda }) => {
  const handleItemTransaccional = (id) => {
    setDatosBusqueda((args) => ({
      ...args,
      idMovimiento: id,
      menuMobile: false,
    }));
  };

  return (
    <>
      <div className="movimientoClienteMobile-container">
        <>
          {Number(datosBusqueda.idGrupo) === 1 ? (
            <img
              style={{ width: "auto", height: "10vh" }}
              src="/images/logo/logo-corpfav.png"
              alt="logo-copfav"
              loading="lazy"
            />
          ) : Number(datosBusqueda.idGrupo) === 2 ? (
            <img
              style={{ width: "auto", height: "11.5vh" }}
              src="/images/logo/logo-corpfav.png"
              alt="logo-copfav"
              loading="lazy"
            />
          ) : Number(datosBusqueda.idGrupo) === 3 ? (
            <img
              style={{ width: "auto", height: "11.5vh" }}
              src="/images/logo/logo-tvtas.png"
              alt="logo-copfav"
              loading="lazy"
            />
          ) : Number(datosBusqueda.idGrupo) === 4 ? (
            <img
              style={{ width: "auto", height: "11.5vh" }}
              src="/images/logo/logo-corpfav.png"
              alt="logo-copfav"
              loading="lazy"
            />
          ) : (
            <img
              style={{ width: "auto", height: "11.5vh" }}
              src="/images/logo/logo-corpfav.png"
              alt="logo-copfav"
              loading="lazy"
            />
          )}
        </>
      </div>
      <div className="movimientoClienteMobile-item">
        {LISTA_MENU.map((item) => (
          <Card key={item.id} /*  */>
            <CardActionArea onClick={() => handleItemTransaccional(item.id)}>
              <CardMedia
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "2rem 0 0 0",
                }}
              >
                {item.iconMenu}
              </CardMedia>

              <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                <Typography gutterBottom variant="h3" component="div">
                  {item.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </div>
    </>
  );
};

export default DetalleMenuMobile;
