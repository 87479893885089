// import React, { Suspense, useState, useEffect } from "react";
// import { CircularProgress, IconButton } from "@mui/material";
// import menus from "./menus";
// import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
// import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
// import JumboScrollbar from "@jumbo/components/JumboScrollbar";
// import { useJumboLayoutSidebar, useJumboSidebarTheme } from "@jumbo/hooks";
// import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
// import Logo from "../../../../shared/Logo";
// import MenuOpenIcon from "@mui/icons-material/MenuOpen";
// import Zoom from "@mui/material/Zoom";
// import Div from "@jumbo/shared/Div";
// import { red } from "@mui/material/colors";
// import SidebarSkeleton from "./SidebarSkeleton";
// import ContentLoader from "@jumbo/components/ContentLoader";
// import { useSelector } from "react-redux";

// import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';

// import ItemIcono from './ItemIcono';

// const Sidebar = () => {
//   const userRedux = useSelector((state) => state.userReducer);
//   const [menu, setMenu] = useState([]);

//   const loadMenu=(data)=>{
//    /*  debugger; */
//     const menu = data.map(item => {
//       var obj = new Object();

//       obj.label = item.label;
//       obj.type =  "section";
//       obj.children =loadItem(item.children);
//       obj.icon = <ItemIcono icono={item.icon} />;
//       obj.visible = item.visible ;

//         return obj;
//     })
//     return menu;
// }

// const loadItem=(data)=>{

//     const datositem = data.map(item => {

//       var obj = new Object();

//       obj.uri = item.uri;
//       obj.label = item.label;
//       obj.type = item.type;

//       obj.icon = <ItemIcono icono={item.icon} />;
//       obj.visible = item.visible ;

//       obj.children =loadItem1(item.children);
//       return obj;
//   })

//     return datositem;

// }

// const loadItem1=(data)=>{
//   const datositem = data.map(item => {
//     var obj = new Object();
//     obj.uri= item.uri;
//     obj.label = item.label;
//     obj.type = item.type;
//     obj.icon = <ItemIcono icono={item.icon} />;
//     obj.visible = item.visible ;
//     return obj;
//   })
//   return datositem;
// }

//   useEffect(() => {
//     if (sessionStorage.getItem("menu") != undefined) {
//       var menu1 = loadMenu(JSON.parse(sessionStorage.getItem("menu")));
//       //setMenu(JSON.parse(sessionStorage.getItem("menu")));
//       setMenu(menu1);
//     } else {
//       setMenu([]);
//     }
//   }, []);

//   // useEffect(() => {
//   //   console.log("Hola Menu", menu);
//   // }, [menu]);

//   return (
//     <React.Fragment>
//       <SidebarHeader />
//       <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
//         <Suspense
//           fallback={
//             <Div
//               sx={{
//                 display: "flex",
//                 minWidth: 0,
//                 alignItems: "center",
//                 alignContent: "center",
//                 px: 3,
//               }}
//             >
//               <SidebarSkeleton />
//             </Div>
//           }
//         >
//           {menu.length > 0 && <JumboVerticalNavbar items={menu} />}

//           {/* <JumboVerticalNavbar translate items={userRedux.menu}/> */}
//           {/* {userRedux.menu ? (
//             <JumboVerticalNavbar translate items={userRedux.menu} />
//           ) : (
//             <></>
//           )} */}
//         </Suspense>
//         <ContentLoader/>
//       </JumboScrollbar>
//     </React.Fragment>
//   );
// };

// const SidebarHeader = () => {
//   const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
//   const { sidebarTheme } = useJumboSidebarTheme();

//   const isMiniAndClosed = React.useMemo(() => {
//     return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
//   }, [sidebarOptions.view, sidebarOptions.open]);

//   return (
//     <React.Fragment>
//       {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
//         <DrawerHeader>
//           <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />
//           {
//             <Zoom in={sidebarOptions?.open}>
//               <IconButton
//                 edge="start"
//                 color="inherit"
//                 aria-label="open drawer"
//                 sx={{ ml: 0, mr: -1.5 }}
//                 onClick={() => setSidebarOptions({ open: false })}
//               >
//                 <MenuOpenIcon />
//               </IconButton>
//             </Zoom>
//           }
//         </DrawerHeader>
//       )}
//     </React.Fragment>
//   );
// };

// export default Sidebar;

import React, { Suspense, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import { useJumboLayoutSidebar, useJumboSidebarTheme } from "@jumbo/hooks";
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import Div from "@jumbo/shared/Div";
import ContentLoader from "@jumbo/components/ContentLoader";

import { IconButton, Button, Zoom } from "@mui/material";

import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import SidebarSkeleton from "./SidebarSkeleton";

import ItemIcono from "./ItemIcono";
import Logo from "../../../../shared/Logo";
import SideChatContainer from "../../modules/app-chatSidebar/pages/SideChatContainer";

const Sidebar = () => {
  const userRedux = useSelector((state) => state.userReducer);
  const [menu, setMenu] = useState([]);
  const [itemChat, setitemChat] = useState(false);

  const loadMenu = (data) => {
    const menu = data.map((item) => {
      var obj = new Object();
      obj.label = item.label;
      obj.type = "section";
      obj.children = loadItem(item.children);
      obj.icon = <ItemIcono icono={item.icon} />;
      obj.visible = item.visible;
      return obj;
    });

    return menu;
  };

  const loadItem = (data) => {
    data.forEach(function (item) {
      if (item.label == "Chat") {
        setitemChat(true);
      }
    });

    const datositem = data.map((item) => {
      var obj = new Object();
      obj.uri = item.uri;
      obj.label = item.label;
      obj.type = item.type;
      obj.icon = <ItemIcono icono={item.icon} />;
      obj.visible = item.visible;
      obj.children = loadItem1(item.children);

      return obj;
    });

    return datositem;
  };

  const loadItem1 = (data) => {
    const datositem = data.map((item) => {
      var obj = new Object();
      obj.uri = item.uri;
      obj.label = item.label;
      obj.type = item.type;
      obj.icon = <ItemIcono icono={item.icon} />;
      obj.visible = item.visible;

      return obj;
    });

    return datositem;
  };

  useEffect(() => {
    if (sessionStorage.getItem("menu") != undefined) {
      var menu1 = loadMenu(JSON.parse(sessionStorage.getItem("menu")));

      //setMenu(JSON.parse(sessionStorage.getItem("menu")));

      setMenu(menu1);
    } else {
      setMenu([]);
    }
  }, []);

  return (
    <React.Fragment>
      <SidebarHeader />
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                alignContent: "center",
                px: 3,
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          {menu.length > 0 && <JumboVerticalNavbar items={menu} />}

          {/* <JumboVerticalNavbar translate items={userRedux.menu}/> */}

          {/* {userRedux.menu ? (

            <JumboVerticalNavbar translate items={userRedux.menu} />

          ) : (

            <></>

          )} */}
        </Suspense>

        <ContentLoader />
      </JumboScrollbar>

      {itemChat ? <SidebarFoot /> : <></>}
    </React.Fragment>
  );
};

const SidebarHeader = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();

  const { sidebarTheme } = useJumboSidebarTheme();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <DrawerHeader>
          <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />

          {
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                onClick={() => setSidebarOptions({ open: false })}
              >
                <MenuOpenIcon />
              </IconButton>
            </Zoom>
          }
        </DrawerHeader>
      )}
    </React.Fragment>
  );
};

const SidebarFoot = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { sidebarTheme } = useJumboSidebarTheme();
  const [ventana, setVentana] = useState("0px");
  const [titulo, settitulo] = useState("Abrir Chat");
  const [activeChat, setActiveChat] = useState(false);

  const handleOpenDrawer = () => {
    if (ventana == "500px") {
      setVentana("0px");
      settitulo("Abrir Chat");
      setActiveChat(false);
    } else {
      setVentana("500px");
      settitulo("Cerrar Chat");
      setActiveChat(true);
    }
  };

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <div>
          <Button
            onClick={handleOpenDrawer}
            style={{ color: "white", backgroundColor: "red" }}
            startIcon={<WhatsAppIcon />}
          >
            {titulo}
          </Button>

          <div
            style={{
              width: "100%",
              height: ventana,
              overflowY: "auto",
              position: "relative",
              /*  border: "1px solid red", */
            }}
          >
            <SideChatContainer activeChat={activeChat} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
