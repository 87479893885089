import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Zoom
} from "@mui/material";
import FormUser from '../UsersContainers/usersform';

class PopUp extends React.Component {
  render() {
    const { flagEdit, open, onClose, handlePopUpSubmit, showProgressBar, title, size, user,roles } = this.props;

    return (
        <Dialog onClose={onClose} open={open} maxWidth={size} fullScreen={false}>
          <DialogTitle color={'primary'}>{title}</DialogTitle>
          <DialogContent>
            <FormUser flagEdit={flagEdit} handlePopUpSubmit={handlePopUpSubmit} handleClose={onClose} singleUser={user} roles={roles}/>
          </DialogContent>
        </Dialog>
    );
  }
}

export default PopUp;
