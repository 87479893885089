import React, { useState, useEffect } from "react";
import Input from "@mui/material/Input";
import TableCell from "@mui/material/TableCell";
import { TableFilterRow } from "@devexpress/dx-react-grid-material-ui";
import { withStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";

const placeholder = " Filtrar...";

const styles = (theme) => ({
  cell: {
    width: "100%",
    /* padding: theme.spacing(1), */
  },
  input: {
    fontSize: "14px",
    width: "100%",
  },
});

const BasicFilterCellBase = (props) => {
  const { filter, onFilter, classes, column } = props;

  const [boolValue, setBoolValue] = useState(false);

  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    if (didMount && type === "BooleanEditor") {
      onFilter({ value: boolValue });
    }
  }, [boolValue]);

  const type = props.children[1].type.name;

  if (type === "BooleanEditor") {
    return (
      <TableCell className={classes.cell}>
        <Switch
          checked={boolValue}
          onChange={(e) => {
            setDidMount(true);
            setBoolValue(!boolValue);
          }}
          color={"primary"}
          value={"true"}
        />
      </TableCell>
    );
  }

  return (
    <TableCell className={classes.cell}>
      <Input
        className={classes.input}
        value={filter ? filter.value : ""}
        onChange={(e) =>
          onFilter(e.target.value ? { value: e.target.value } : null)
        }
        placeholder={column && column.disabledFilter ? "" : placeholder}
        inputprops={{
          style: { height: "inherit" },
        }}
        readOnly={column && column.disabledFilter}
      />
    </TableCell>
  );
};
const BasicFilterCell = withStyles(styles, { name: "BasicFilterCell" })(
  BasicFilterCellBase
);

export const FilterCell = (props) => {
  return <BasicFilterCell {...props} />;
  return <TableFilterRow.Cell {...props} />;
};
