import React from "react";

import { useJumboApp } from "@jumbo/hooks";
import { ContextProviderChatSide } from "../context/ChatSideContext";
import { LAYOUT_NAMES } from "app/utils/constants/layouts";
import ChatSideContainer from "./ChatSideContainer/ChatSideContainer";

const SideChatContainer = ({ activeChat }) => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <>
      {activeChat && (
        <ContextProviderChatSide>
          <ChatSideContainer />
        </ContextProviderChatSide>
      )}
    </>
  );
};

export default SideChatContainer;
