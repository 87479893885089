import http from "../../../comunication/http-common";

/* eslint-disable camelcase */

export const preevaluacionCliente = async (
  identificaion,
  usuario,
  host,
  validaBuro
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/PreevaluacionCliente?identificacion=${identificaion}&usuario=${usuario}&host=${host}&validaBuro=${validaBuro}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const validarAumentoCupo = async (identificaion, usuario) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ValidarAumentoCupo?identificacion=${identificaion}&usuario=${usuario}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerSolicitud = async (idSolicitud) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/obtenerSolicitud?idSolicitud=${idSolicitud}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerSolicitudPreevaluacion = async (
  identificacion,
  idGrupo
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerSolicitudPreevaluacion?identificacion=${identificacion}&idGrupo=${idGrupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerSolicitudAumentoCupo = async (identificacion, idGrupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerSolicitudAumentoCupo?identificacion=${identificacion}&idGrupo=${idGrupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerEstadoSolicitud = async (idEstado) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/obtenerEstado?idEstado=${idEstado}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const verificarCupoTarjeta = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/VerificarMontoTarjeta?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        if (response.status) resolve(response.data);
        else reject(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const validacionInicial = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/evaluacionInicial?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        if (response.status) resolve(response.data);
        else reject(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const validacionBuro = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/validacionBuro?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        if (response.status) resolve(response.data);
        else reject(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const listarSolicitudes = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/listarSolicitudes`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const listarSolicitudesDevueltas = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ListarSolicitudesDevueltas`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const listarSolicitudesNegadas = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/listarSolCliNegadas?idCliente=${idCliente}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const listarSolicitudesPorDigitar = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ListarSolicitudesPorDigitar`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const listarSolicitudesCrediwebSinDoc = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ListarSolicitudesCrediwebSinDoc`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const listarCorreos = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    /*console.log(headers);*/
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/listarCorreos`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerCorreos = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerCorreos`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenetSolicitudesXAnexar = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerSolicitudesPorAnexar`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const verificarSolicitudExistente = async (identificacion, idFlujo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/validarSolPreev?identificacion=${identificacion}&idFlujo=${idFlujo}`;
    http
      .get(url, { headers })
      .then((response) => {
        if (response.status) resolve(response.data);
        else reject(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerNumeroSolicitud = async (flujo, local) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/obtenerNumeroSolicitud?flujo=${flujo}&local=${local}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerScore = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/obtenerScore?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerCuota = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/obtenerCuota?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerDIN = async (perfilCf, califBuro) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/getDin?segmento=${perfilCf}&perfil=${califBuro}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerCupoPreaprobado = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/getCupoPreaprobado?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerMontoSugerido = async (identificacion, idLocal) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerMontoSugerido?identificacion=${identificacion}&idLocal=${idLocal}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerDocumentosVisados = async (idSolicitud) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/getVisado/?idSolicitud=${idSolicitud}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerObservaciones = async (idSolicitud) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerVerificacion/?idSolicitud=${idSolicitud}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerDatosAprobacion = async (idSolicitud) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerDatosAprobacion/?idSolicitud=${idSolicitud}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerCabeceraSolicitud = async (idSolicitud) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerCabeceraSolicitud?idSolicitud=${idSolicitud}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerCabeceraCliente = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CLIENTE}cliente/ObtenerInfoClienteXIdCliente?idCliente=${idCliente}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const calcularNuevoDin = async (monto, idCliente, identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/calcularNuevoDin?monto=${monto}&idCliente=${idCliente}&identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerDocumento = async (idSolicitud) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerDocumento?idSolicitud=${idSolicitud}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerPagosCliente = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerPagosCliente?idCliente=${idCliente}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerInfoSolAprobada = async (idSolicitud) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerInfSolicAprobada?idSolicitud=${idSolicitud}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerAumentoCupo = async (idSolicitud) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerAumentoCupo?idSolicitud=${idSolicitud}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerSolicitiudGestionar = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerSolicitudGestionar?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const consultaSolicitudesUsuario = async (usuario) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ConsultaSolicitudesUsuario?usuario=${usuario}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const consultaTrackingSolicitud = async (identificacion, idGrupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ConsultaTrackingSolicitud?identificacion=${identificacion}&idGrupo=${idGrupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerFlujo = async (idFlujo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerFlujoXIdFlujo?idFlujo=${idFlujo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerFlujosxGrupo = async (idGrupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerFlujosXGrupo?idGrupo=${idGrupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerSolicitudDigital = async (identificacion, idGrupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerSolicitudDigital?identificacion=${identificacion}&idGrupo=${idGrupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerSolucitudesCliente = async (identificacion) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ListarSolicitudesCliente?identificacion=${identificacion}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

/* peticiones POST conecciones al back */

export const grabarSolicitud = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/grabarSolicitud`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
};

export const cambiarEstadoSolicitud = async (
  idSolicitud,
  idEstado,
  user,
  host
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/CambiarEstado?idSolicitud=${idSolicitud}&idEstado=${idEstado}&user=${user}&host=${host}`;
    http
      .post(url, null, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const grabarDocumento = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/grabarDocumento`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
};

export const grabarAumentoCupo = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/GrabarAumentoCupo`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
};

export const grabarEstadoFinanciero = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/grabarEstadoFinanciero`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
};

export const visarDocumento = async (idSolicitud, documento, user, host) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/visado?idSolicitud=${idSolicitud}&documento=${documento}&user=${user}&host=${host}`;
    http
      .post(url, null, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

// export const grabarDatosAprobacion = (data) => {
//   return new Promise((resolve, reject) => {
//     const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
//     const url = `${process.env.REACT_APP_API_CREDITO}solicitud/grabarDatosAprobacion`;
//     http
//      .post(url, data,{headers})
//       .then((response) => {
//         resolve(response.data);
//       })
//       .catch((error) => {
//         resolve(error);
//       });
//   });
// };

export const grabarDatosAprobacion = async (data) => {
  try {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/grabarDatosAprobacion`;
    let respuesta = await http.post(url, data, { headers });
    return respuesta.data;
  } catch (error) {
    return error;
  }
};

export const grabarMotivoNoAprobacion = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/grabarMotivoNoAprobacion`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
};

export const grabarVerificacion = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/GrabarVerificacion`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
};

export const anexarDocumentoCorreo = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/AnexarDocumentoSolicitud`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
};

export const archivarCorreoBase = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ArchivarCorreo`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
};

export const notificarMensajeBienvenida = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_NOTIFICAR}EnvioNotificacion/Notificar`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
};

/* Debito A Cuenta  */

//http://localhost:5013/solicitud/ObtenerDatosDebitoCuenta?idCliente=1340&idGrupo=3
export const consultarDatosDebitoCuenta = async (idCliente, idGrupo) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerDatosDebitoCuenta?idCliente=${idCliente}&idGrupo=${idGrupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5000/solicitud/ObtenerAutorizacionDebito?identificacion=1725786931&idGrupo=3
export const consultarDocumentoDebitoCuenta = async (
  identificacion,
  idGrupo
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerAutorizacionDebito?identificacion=${identificacion}&idGrupo=${idGrupo}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://localhost:5013/solicitud/grabarDatosDebitoCuenta
export const crearDebitoCuenta = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/grabarDatosDebitoCuenta`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
};
