import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import SiniestroDocumentos from "./SiniestroDocumentos/SiniestroDocumentos";

const DocumentosSiniestros = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <>
      <h2 className="titulosPrincipales-container">Siniestro - Documentos</h2>
      <SiniestroDocumentos />
    </>
  );
};

export default DocumentosSiniestros;
