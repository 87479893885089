import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import SeguroGestionar from "./GestionarSeguro/SeguroGestionar";

const GestionarSeguro = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Cancelación - Seguro</h2>

      <SeguroGestionar />
    </div>
  );
};

export default GestionarSeguro;
