import React, { useState } from "react";

import { useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { FormControlLabel, Switch, TextField, Typography } from "@mui/material";

import styled from "@emotion/styled";

import ModalDocumentDetail from "./ModalDocumentDetail";

import { LoadingButton } from "@mui/lab";
import {
  grabarAceptacion,
  notificarAceptacion,
} from "app/modules/app-evaluacion/services/evaluacionServices";
import { isValidEmail } from "@jumbo/utils";
import jsPDF from "jspdf";
import { descargarArchivoStatic } from "app/modules/app-configuracion/services/configuracionServices";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const PoliticsDetails = ({
  open,
  titulo,
  aceptadoPPR,
  aceptadoTyC,
  statesLegalDocuments,
  setOpenPoliticsDetails,
  clientInformation,
  setLoadButtonTYC,
}) => {
  const [openTyC, setOpenTyC] = useState(false);
  const [openPPR, setOpenPPR] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState("");

  const [statesDocuments, setStatesDocuments] = useState({
    TyC: statesLegalDocuments.stateTYC,
    PPR: statesLegalDocuments.statePPR,
    Buro: false,
  });

  const [contentTCU, setContentTCU] = useState("");
  const [contentPPR, setContentPPR] = useState("");

  const { authUser } = useSelector(({ auth }) => auth);

  const hanldeTyC = () => {
    /*  console.log("terminos y condiciones "); */
    setOpenTyC(true);
  };

  const hanldePPR = () => {
    /* console.log("politicas de privacidad"); */
    setOpenPPR(true);
  };

  const hanldeClosePoliticas = () => {
    /* console.log("politicas de privacidad"); */
    setOpenPoliticsDetails(false);
    setLoadButtonTYC(false);
  };

  const handleChange = (event) => {
    setStatesDocuments({
      ...statesDocuments,
      [event.target.name]: event.target.checked,
    });
  };

  const handleConfirmPolitics = () => {
    return !(
      statesDocuments.Buro &&
      statesDocuments.PPR &&
      statesDocuments.TyC &&
      isValid
    );
  };

  const handleAceptPolitics = async () => {
    //console.log(email);
    setLoading(true);

    const clientPoliticsValidation = { ...clientInformation };

    clientPoliticsValidation.tcu = true;
    clientPoliticsValidation.ppr = true;
    clientPoliticsValidation.email = email;

    console.log({ clientPoliticsValidation });

    try {
      const srvGrabarAceptacion = await grabarAceptacion(
        clientPoliticsValidation
      );
      console.log({ srvGrabarAceptacion });
      if (srvGrabarAceptacion.state) {
        setLoading(false);
        setOpenPoliticsDetails(false);
        setLoadButtonTYC(false);
      } else {
        alert(srvGrabarAceptacion.message);
      }
    } catch (e) {
      alert(e);
    }

    if (authUser.usuarioFlujos[0].idGrupo === 1) {
      const res = handleGeneratePdf();
    }

    if (authUser.usuarioFlujos[0].idGrupo === 3) {
      const res = handleGeneratePdfTventas();
    }
  };

  const handleChangeEmail = async (event) => {
    //console.log(contentTCU);
    //console.log(contentPPR);
    const val = event.target.value;

    if (!val) {
      setIsValid(false);
      //console.log("VACIO");
    }
    if (isValidEmail(val)) {
      setIsValid(true);
      setEmail(val);
      //console.log(email);
    } else {
      setIsValid(false);
      //console.log("NO valido");
    }
  };

  const handleGeneratePdfTventas = async () => {
    const srvObtenerPdf = await descargarArchivoStatic("TYC_PPR_TVENTAS.pdf");

    if (srvObtenerPdf.state) {
      const base64Document = srvObtenerPdf.data;

      const notificacion = {
        email: email,
        documentosBase64: base64Document,
      };
      try {
        const resp = await notificarAceptacion(notificacion);
        console.log(resp);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleGeneratePdf = async () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      compressPdf : true
    });
    doc.setFontSize(11);

    const ppr = contentTCU + "<br/><br/><br/><br/><br/><br/>" + contentPPR;
    const tcu = contentTCU;
    //doc.text(dev, 10, 10);
    await doc.html(ppr, {
      margin: [20, 20, 20, 25],
      x: 0,
      y: 0,
      width: 400, //target width in the PDF document
      windowWidth: 675, //window width in CSS pixels
    });

    var base64Document = doc.output("datauristring").split(",")[1];
    //console.log(base64Document);

    const notificacion = {
      email: email,
      documentosBase64: base64Document,
    };
    try {
      const resp = await notificarAceptacion(notificacion);
      console.log(resp);
    } catch (e) {
      console.log(e);
    }

    //doc.save("a4.pdf");
  };

  return (
    <>
      <Dialog
        /* aria-describedby="alert-dialog-slide-description" */
        /* style={{ height: "30%" }} */

        onClose={hanldeClosePoliticas}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ width: 1 }}
      >
        <DialogTitle
          id="customized-dialog-title"
          /* onClose={() => setOpen(false)} */
        >
          {titulo}
        </DialogTitle>
        <DialogContent dividers>
          <section>
            <div className="politicsDetail--container">
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={statesDocuments.TyC}
                    onChange={handleChange}
                    name="TyC"
                  />
                }
              />
              <p>
                Acepto los
                <strong className="politicsDetail--link" onClick={hanldeTyC}>
                  {` Términos y condiciones de uso.`}
                </strong>
              </p>
            </div>
            <div className="politicsDetail--container">
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={statesDocuments.PPR}
                    onChange={handleChange}
                    name="PPR"
                  />
                }
              />
              <p>
                Acepto los tratamientos de mis datos personales a Corporación
                Favorita conforme lo señalado en su
                <strong className="politicsDetail--link" onClick={hanldePPR}>
                  {` Políticas de Privacidad `}
                </strong>
                la cual conozco y acepto.
              </p>
            </div>
            <div className="politicsDetail--container">
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={statesDocuments.Buro}
                    onChange={handleChange}
                    name="Buro"
                  />
                }
              />
              <p>
              Autorizo a consultar referencias crediticias en el 
                <strong className="politicsDetail--link">
                  {` buró de crédito.`}
                </strong>
              </p>
            </div>
          </section>
          <section>
            <Typography variant="body1" fontWeight="bold">
              Ingrese su correo para recibir los documentos legales.(Requerido)
            </Typography>

            <TextField
              required
              sx={{ marginTop: 3 }}
              size="small"
              id="outlined-basic"
              label="Correo electronico"
              variant="outlined"
              onChange={(e) => handleChangeEmail(e)}
              error={isValid === false}
            />
          </section>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            loading={loading}
            autoFocus
            //disabled={handleConfirmPolitics()}
            variant="contained"
            onClick={handleAceptPolitics}
          >
            ACEPTAR
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {aceptadoTyC && (
        <ModalDocumentDetail
          open={openTyC}
          close={setOpenTyC}
          title={"TERMINOS Y CONDICIONES"}
          documentType="TCD"
          setContent={setContentTCU}
        />
      )}

      {aceptadoPPR && (
        <ModalDocumentDetail
          open={openPPR}
          close={setOpenPPR}
          title={"POLITICAS DE PRIVACIDAD"}
          documentType="PPR"
          setContent={setContentPPR}
        />
      )}
    </>
  );
};

export default PoliticsDetails;
