import React from "react";
import { useEffect } from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { green } from "@mui/material/colors";
import { red } from "@mui/material/colors";

const IconoComprobacionCambioEstado = ({ estado }) => {
  return (
    <div className="cambioEstado-icono-validacion">
      <h4>Validación</h4>
      {estado ? (
        <CheckCircleOutlineIcon sx={{ color: green[500] }} fontSize="large" />
      ) : (
        <HighlightOffIcon sx={{ color: red[500] }} fontSize="large" />
      )}
    </div>
  );
};

export default IconoComprobacionCambioEstado;
