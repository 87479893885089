import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";

import TaskIcon from "@mui/icons-material/Task";
import ModalReporteMensual from "../containers/ModalReporteMensual";

const BotonConsultaSiniestroReporteMensual = () => {
  const [reporteMensual, setReporteMensual] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  /*  const obtenerReporteSiniestosDiario = async () => {
    setLoading(true);

    try {
      const respuestaSiniestroMensual = await consultarSiniestroMensual();

      if (respuestaSiniestroMensual.state) {
        const respuestaSiniestroMensualAux = respuestaSiniestroMensual.data.map(
          (mensual, index) => {
            return {
              ...mensual,
              fechaIngreso: formatoFecha(mensual.fechaIngreso),
              ID: index,
            };
          }
        );

        setReporteMensual(respuestaSiniestroMensualAux);

        downloadExcel(
          respuestaSiniestroMensual.data,
          `REPMENSUAL_${fechasDocumentosSiniestro()}`
        );
        setLoading(false);
      } else {
        setReporteMensual([]);
        setLoading(false);
        alert("Error: No se ha podido gererar el reporte !!");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert("Error: No se ha podido gererar el reporte !!");
    }
  }; */

  const obtenerReporteSiniestosDiario = () => {
    setOpen(true);
  };

  return (
    <div className="tipoSiniestro-tipos">
      <LoadingButton
        size="large"
        loading={loading}
        variant="contained"
        loadingPosition="end"
        endIcon={<TaskIcon />}
        onClick={obtenerReporteSiniestosDiario}
      >
        Reporte Corporación
      </LoadingButton>

      <ModalReporteMensual
        open={open}
        titulo="Reporte Corporación"
        mensaje="Reporte Corporación"
        setOpen={setOpen}
      />
    </div>
  );
};

export default BotonConsultaSiniestroReporteMensual;
