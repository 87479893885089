import React, { useState } from "react";

import { formatoFecha } from "app/modules/shared/validations/validaciones";

import { LoadingButton } from "@mui/lab";

import TaskIcon from "@mui/icons-material/Task";
import downloadExcel from "app/utils/document/documentoDescargaExel";
import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";
import { consultarSiniestroAseguradora } from "../services/ReporteService";
import ModalReporteAseguradora from "../containers/ModalReporteAseguradora";

const BotonConsultaSiniestroAseguradora = () => {
  const [reporteAseguradora, setReporteAseguradora] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  /*  const obtenerReporteSiniestosAseguradora = async () => {
    setLoading(true);

    try {
      const respuestaSiniestrosAseguradora =
        await consultarSiniestroAseguradora();

      if (respuestaSiniestrosAseguradora.state) {
        const respuestaSiniestrosAseguradoraAux =
          respuestaSiniestrosAseguradora.data.map((aseguradora, index) => {
            return {
              ...aseguradora,
              fechaIngreso: formatoFecha(aseguradora.fechaIngreso),
              ID: index,
            };
          });

        setReporteAseguradora(respuestaSiniestrosAseguradoraAux);

        downloadExcel(
          respuestaSiniestrosAseguradora.data,
          `REPASEGURADORA_${fechasDocumentosSiniestro()}`
        );
        setLoading(false);
      } else {
        setReporteAseguradora([]);
        setLoading(false);
        alert("Error: No se ha podido gererar el reporte !!");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Error: No se ha podido gererar el reporte !!");
    }
  }; */

  const obtenerReporteSiniestosAseguradora = () => {
    setOpen(true);
  };

  return (
    <div className="tipoSiniestro-tipos">
      <LoadingButton
        size="large"
        loading={loading}
        variant="contained"
        loadingPosition="end"
        endIcon={<TaskIcon />}
        onClick={obtenerReporteSiniestosAseguradora}
      >
        Reporte Aseguradora
      </LoadingButton>

      <ModalReporteAseguradora
        open={open}
        titulo="Reporte Aseguradora"
        mensaje="Reporte Aseguradora"
        setOpen={setOpen}
      />
    </div>
  );
};

export default BotonConsultaSiniestroAseguradora;
