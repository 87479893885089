import React, { useState, useEffect, Fragment } from 'react';
import { Form, Field, FormSpy, label } from 'react-final-form';
import { makeStyles } from '@mui/styles';
import { OnChange } from 'react-final-form-listeners';
//components
import { SaveButton, CancelButton } from '../../../common/components/Buttons';
import ActionButton from '../../../common/components/TransactionalButtons/ActionButton';
import ProgressBar from '../../../common/components/ProgressBar';
import {
  maxLength,
  minLength,
  required,
  composeValidators,
  email,
} from '../../../common/components/RenderFields/validations';

import { useDispatch } from "react-redux";
import { onlyTextAndNumbers, onlyText, onlyNumber } from '../../../../utils/normalizers';
import { RenderSelectField, RenderTextField, RenderSwitch } from '../../../common/components/RenderFields';
import { emptyItem, checkDuplicates } from '../../../../utils/common';


import { getRol , findRol} from '../../../app-seguridad/services/index'
import { useGetOption } from '../../hooks/hookRoles';
import GenericGrid from '../../../common/components/GenericGrid';
import Paper from "@mui/material/Paper";
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const atLeastOneActive = value => {
  return value
    ? Array.isArray(value)
      ? value.some(item => item.isActive)
        ? undefined
        : "Debe tener al menos un rol activo"
      : undefined
    : "Debe tener al menos un rol activo";
};

const FormRol = ({ onSubmit, flagEdit, handleClose, rolCode }) => {
  // const { authUser, code } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const [rol, setRol] = useState({});
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [error, setError] = useState(null);
  const { options, setOptions, isLoadingOptions } = useGetOption();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setShowProgressBar(true);
        const coreResponse = await getRol(rolCode, process.env.REACT_APP_INSTITUCION);
   
        if (coreResponse.state) {

          var array = coreResponse.data;
          let i = 0;
          var datos = array.rolOpcions.map( function (doc) {
            return{...doc, id: i++}
          });
          var temp2 =  datos.filter((x) => x !== undefined)
          array.rolOpcions = temp2
          setRol(array);

        } else setRol({});
        setShowProgressBar(false);
      } catch (error) {
        setError(error);
        setShowProgressBar(false);
      }
    };

    if (flagEdit && options.length > 0) fetchData();
  }, [rolCode, flagEdit, options]);

console.log("roltttttt", rol)
console.log("opciones xxxxx", options)

  return (
    <Fragment>
      <Form
        initialValues={flagEdit ? rol : { activo: true, canal: "WEB", sistema: process.env.REACT_APP_INSTITUCION }}
        onSubmit={onSubmit}
        subscription={{ valid: true, submitting: true }}
        validate={(values) => {
          const errors = {};
        }}
        render={({ handleSubmit, submitting, valid }) => (
          <form onSubmit={handleSubmit} style={{ padding: '10px' }}>
            <div>
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <Field
                    name="codigo"
                    label={'Código'}
                    component={RenderTextField}
                    subscription={{ value: true, error: true, touched: true }}
                    parse={onlyTextAndNumbers}
                    validate={composeValidators(
                      required,
                      minLength(1),
                      maxLength(32),
                      async value => {
                        if (!flagEdit) {
                          let result = await findRol(value, process.env.REACT_APP_INSTITUCION);
  
                          if (result.state) {
                            return result.data
                              ? "Código de rol ya existe. Ingrese otro valor"
                              : undefined;
                          } else return undefined;
                        }
                      }
                    )}
                    disabled={flagEdit}
                  />
                </div>

                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <Field
                    name="sistema"
                    label={'Sistema'}
                    component={RenderTextField}
                    subscription={{ value: true, error: true, touched: true }}
                    parse={onlyText}
                    validate={required}
                    disabled={true}
                  />
                </div>

                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <Field
                    name="canal"
                    label={'Canal'}
                    component={RenderTextField}
                    subscription={{ value: true, error: true, touched: true }}
                    parse={onlyText}
                    validate={required}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <Field
                    name="descripcion"
                    label={'Descripción'}
                    component={RenderTextField}
                    subscription={{ value: true, error: true, touched: true }}
                    parse={onlyText}
                    validate={composeValidators(
                      required,
                      minLength(2),
                      maxLength(128))}
                    disabled={false}
                  />
                </div>

                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                  <Field
                    name="activo"
                    label={'Está Activo'}
                    disabled={!flagEdit}
                    component={RenderSwitch}
                    subscription={{ value: true, error: true, touched: true }}
                  />
                </div>


              </div>
              <br />
              <br />
              <div>
                <Paper elevation={0} >
                  <div>
                    <label>Opciones de Menu</label>
                    <br />
                    <br />
                    <Field
                      name={"rolOpcions"}
                      columns={[
                        {
                          name: "idMenu",
                          title: "Opción"
                        }
                      ]}
                      selectColumns={["idMenu"]}
                      component={GenericGrid}
                      catalogs={{
                        idMenu: options.map(item => {
                          return {
                            key: item.code,
                            value: item.code,
                            name: item.name
                          };
                        })
                      }}
                      emptyItem={{
                        idMenu: 0,
                      }}
                      
                      commitValidate={rows => {
                        const isValid = checkDuplicates(
                          rows.map(row => row.idMenu)
                        );

                        if (isValid)
                        return isValid;
                      }}

                      pageSize= {5}
                      errorMessage={"Debe tener al menos un rol activo"}
                    />
                  </div>
                </Paper>
              </div>
              <br />
              <div className={"float-left"}>
                <FormSpy subscription={{ valid: true, submitting: true }}>

                  {({ submitting, valid }) => (

                    <SaveButton type={"submit"} disabled={submitting || !valid} />
                  )}
                </FormSpy>
                <CancelButton onClick={handleClose} />
              </div>
            </div>
            {showProgressBar && isLoadingOptions ? <ProgressBar /> : ""}
          </form>
        )}
      />
    </Fragment>
  );
};

export default FormRol;
