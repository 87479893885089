import merge from "lodash.merge";
import { dataDate } from "./validations";

export const formatDate = (value) => {
  if (!value) return "";

  let date = {};

  if (typeof value === "string") date = new Date(value);
  else if (Number.isInteger(value)) date = convertTimestampToDate(value);
  else date = value;

  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return dataDate(day, month, year);
};

export const findCatalog = (nameCtlg, dataFindCatalogs) => {
  if (dataFindCatalogs.length === 0) {
    return [];
  }
  const filter = dataFindCatalogs.filter(
    (element) => element.code === nameCtlg
  );
  return filter[0] ? filter[0].catalogueDetail : [];
};

export const findGeographicLocation2 = (
  geographicLocationID1,
  dataFindGeographicLocation2
) => {
  if (dataFindGeographicLocation2.length === 0) {
    return [];
  }
  const filter = dataFindGeographicLocation2.filter(
    (element) => element.idGeographicLocation1 === geographicLocationID1
  );
  return filter.length > 0 ? filter : [];
};

export const updateItem = (array, action) => {
  return array.map((item, index) => {
    if (index !== action.index) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.item,
    };
  });
};

export const insertItem = (array, action) => {
  return [
    ...array.slice(0, action.index),
    action.item,
    ...array.slice(action.index),
  ];
};

export const removeItem = (array, action) => {
  return [...array.slice(0, action.index), ...array.slice(action.index + 1)];
};

export const getUnixTime = (date) => {
  return (date.getTime() / 1000) | 0;
};

export const convertTimestampToDate = (date) => {
  return date !== undefined && date !== null ? new Date(date * 1000) : date;
};

export const convertDateToTimestamp = (date) => {
  return date !== undefined && date !== null ? getUnixTime(date) : null;
};

export const convertToDate = (date) => {
  return date !== undefined && date !== null ? new Date(date) : null;
};

export const displayError = (error, messages) => {
  let msg = "";
  try {
    const err = JSON.parse(error.message);
    msg = messages.default[err.code];
    if (!msg) msg = err.message;
  } catch (er) {
    msg = error.message;
  }
  return msg;
};

export const displayMsg = (code, messages) => {
  return messages.default[code];
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const emptyItem = [{ code: "", description: "" }];
export const emptyItemNull = [{ code: null, description: null }];

export const convertProfileTransactions = (array) => {
  let map = {};
  map["-"] = { children: [] };
  for (let i = 0; i < array.length; i++) {
    let obj = array[i];
    obj.children = [];

    map[obj.idMenu] = obj;

    let parent = obj.idMenuOrigin || "-";
    if (!map[parent]) {
      map[parent] = {
        children: [],
      };
    }
    map[parent].children.push(obj);
  }

  return map["-"].children;
};

export const compareMenuOrder = (a, b) => {
  if (a.order > b.order) {
    return 1;
  }
  if (a.order < b.order) {
    return -1;
  }
  return 0;
};

export const checkDuplicates = (a) => {
  let counts = [];
  for (let i = 0; i <= a.length; i++) {
    if (counts[a[i]] === undefined) {
      counts[a[i]] = 1;
    } else {
      return true;
    }
  }
  return false;
};

export const commonStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
});

export const findScreenComponents = (privileges) => {
  let view = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1,
    window.location.href.length
  );
  const filter = privileges.filter((element) => element.view === view);
  return filter[0] ? filter[0].components : [];
};

export const findFieldProps = (name, screenComponents) => {
  const filter = screenComponents.filter(
    (element) => element.componentName === name
  );
  return filter[0]
    ? filter[0]
    : {
        componentName: "",
        disabled: false,
        hidden: false,
      };
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const isPlainObj = (o) =>
  Boolean(
    o &&
      o.constructor &&
      o.constructor.prototype &&
      o.constructor.prototype.hasOwnProperty("isPrototypeOf")
  );

export const flattenObj = (obj, keys = []) => {
  return Object.keys(obj).reduce((acc, key) => {
    return Object.assign(
      acc,
      isPlainObj(obj[key])
        ? flattenObj(obj[key], keys.concat(key))
        : { [keys.concat(key).join(".")]: obj[key] }
    );
  }, {});
};

export const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result.split(",")[1]);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const unflattenObj = (json) => {
  let unflatten: Object = {};

  for (let item in json) {
    let _this;
    let splittedKey = item.split(".");

    splittedKey.map(function (keysPart, i) {
      if (i == 0) {
        _this = unflatten;
      }

      if (splittedKey.length === 1) _this[item] = json[item];

      if (!_this[keysPart]) {
        if (splittedKey.length === i + 1) {
          _this[keysPart] = json[item];
        } else {
          _this[keysPart] = {};
          _this = _this[keysPart];
        }
      } else {
        _this = _this[keysPart];
      }
    });
  }

  return unflatten;
};

export const range = (start, end) => {
  let ans = [];
  for (let i = start; i <= end; i++) {
    ans.push(i);
  }
  return ans;
};

export const isDate = (d) => d instanceof Date;
export const isEmpty = (o) => Object.keys(o).length === 0;
export const isObject = (o) => o != null && typeof o === "object";
export const properObject = (o) =>
  isObject(o) && !o.hasOwnProperty ? { ...o } : o;

export const diff = (lhs, rhs) => {
  if (lhs === rhs) return {}; // equal return no diff

  if (Array.isArray(lhs) && Array.isArray(rhs)) return merge(lhs, rhs);

  if (!isObject(lhs) || !isObject(rhs)) return rhs; // return updated rhs

  const l = properObject(lhs);
  const r = properObject(rhs);

  const deletedValues = Object.keys(l).reduce((acc, key) => {
    return r.hasOwnProperty(key) ? acc : { ...acc, [key]: undefined };
  }, {});

  if (isDate(l) || isDate(r)) {
    if (l.valueOf() == r.valueOf()) return {};
    return r;
  }

  return Object.keys(r).reduce((acc, key) => {
    if (!l.hasOwnProperty(key)) return { ...acc, [key]: r[key] }; // return added r key

    const difference = diff(l[key], r[key]);

    if (isObject(difference) && isEmpty(difference) && !isDate(difference))
      return acc; // return no diff
    return { ...acc, [key]: difference }; // return updated key
  }, deletedValues);
};

export function log(message) {
  if (window.app.env.REACT_APP_ENVIRONMENT === "DEV") {
    //global.
  }
}

export const toBase64 = (fileBase64) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBase64);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
