import React, { useEffect, useState } from "react";

import FormPagos from "../components/FormPagos";
import HeaderInformacionCliente from "../../shared/containers/HeaderInformacionCliente";
import HeaderInformacionProducto from "../components/HeaderInformacionProducto";
import BotonSalirLimpiarStorage from "./BotonSalirLimpiarStorage";
import HeaderInformacionSiniestro from "./HeaderInformacionSiniestro";

import { consultaSiniestroXIdSiniestro } from "../Services/siniestrosService";
import FormBeneficiarioSiniestro from "../components/FormBeneficiarioSiniestro";

const DetallePagosSiniestro = ({ idCliente, idSiniestro, idContrato }) => {
  const [verificarEsActivoSiniestro, setVerificarEsActivoSiniestro] =
    useState(true);

  const [idPagoSiniestro, setIdPagoSiniestro] = useState(null);
  const [verificarFlujoVida, setVerificarFlujoVida] = useState(true);

  useEffect(() => {
    const verificarEsActivo = async () => {
      try {
        const respuestaSiniestroXId = await consultaSiniestroXIdSiniestro(
          idSiniestro
        );
        console.log(respuestaSiniestroXId);

        if (respuestaSiniestroXId.data.esActivo) {
          setVerificarEsActivoSiniestro(false);
        }
        if (respuestaSiniestroXId.data.idFlujo === 5) {
          setVerificarFlujoVida(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    verificarEsActivo();
  }, []);

  if (verificarFlujoVida) {
    return (
      <>
        <BotonSalirLimpiarStorage />
        <h1>Flujo no permite esta transacción.!!</h1>
      </>
    );
  }

  if (verificarEsActivoSiniestro) {
    return (
      <>
        <BotonSalirLimpiarStorage />
        <h1>El siniestro se encuentra inactivo</h1>
      </>
    );
  }

  return (
    <div>
      <BotonSalirLimpiarStorage />

      <HeaderInformacionCliente idCliente={idCliente} />

      <HeaderInformacionProducto idSiniestro={idSiniestro} />

      <HeaderInformacionSiniestro
        idCliente={idCliente}
        idSiniestro={idSiniestro}
      />

      <>
        <FormPagos
          idSiniestro={idSiniestro}
          setIdPagoSiniestro={setIdPagoSiniestro}
        />

        {idPagoSiniestro && (
          <FormBeneficiarioSiniestro idPagoSiniestro={idPagoSiniestro} />
        )}
      </>
    </div>
  );
};

export default DetallePagosSiniestro;
