import { useState, useEffect } from "react";
import { formatoFecha } from "app/modules/shared/validations/validaciones";
import { consultarQuejasPendientes } from "../services/gestionService";

export const useGetQuejasPendientes = () => {
  const [quejasPendientes, setQuejasPendientes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaQuejasPendientes = await consultarQuejasPendientes();
        /* console.log(respuestaQuejasPendientes); */

        if (respuestaQuejasPendientes.state) {
          const respuestaQuejasPendientesAux =
            respuestaQuejasPendientes.data.map((quejasPendientes, index) => {
              return {
                ...quejasPendientes,
                fechaIngreso: formatoFecha(quejasPendientes.fechaIngreso),
                ID: index,
              };
            });

          setQuejasPendientes(respuestaQuejasPendientesAux);
          setIsLoading(false);
        } else {
          setQuejasPendientes([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  return { isLoading, quejasPendientes };
};
