import http from "../../../../comunication/http-common";

//http://localhost:5015/Seguro/ObtenerSegurosXCliente?idCliente=172&idEstado=3
export const consultarSegurosCliente = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ObtenerSegurosXCliente?idCliente=${idCliente}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5000/Seguro/ObtenerCancelacionXContrato?idContrato=296463
export const consultarCancelacion = async (idContrato) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ObtenerCancelacionXContrato?idContrato=${idContrato}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.179:5000/Seguro/ObtenerSegurosCancelXCliente?idCliente=343231
export const consultarListaSegurosYCancelados = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ObtenerSegurosCancelXCliente?idCliente=${idCliente}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const consultaEstadoCancelacion = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ObtenerEstadoCancelacion`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://localhost:5015/Seguro/ObtenerCausaCancelacion?idPadre=1
export const consultaCausaCancelacion = async (idEstado) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ObtenerCausaCancelacion?idPadre=${idEstado}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://localhost:5015/Seguro/ObtenerMotivoCancelacion?idPadre=10
export const consultaMotivoCancelacion = async (idCausa) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ObtenerMotivoCancelacion?idPadre=${idCausa}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://10.10.0.79:5000/Seguro/ObtenerSegurosBienXCliente?idCliente=21839
export const consultaListaBienesAsegurados = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ObtenerSegurosBienXCliente?idCliente=${idCliente}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://10.10.0.79:5000/Seguro/ObtenerSegurosPago?idCliente=344
export const consultaListaSegurosRecurrencia = async (idCliente) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ObtenerSegurosPago?idCliente=${idCliente}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/Seguro/ObtenerPagos?idContrato=261767
export const consultaResumenPagos = async (idContrato) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ObtenerPagos?idContrato=${idContrato}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/Seguro/ObtenerBienAseguradoXId?idBienAsegurado=151385
export const consultarBienAsegurado = async (idBienAsegurado) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ObtenerBienAseguradoXId?idBienAsegurado=${idBienAsegurado}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const consultaEstado = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ObtenerEstadoCancelacion`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
        /*  console.log(response.data); */
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://localhost:5015/Seguro/ObtenerValorCancelacion?idContrato=276945&idCausa=13
export const consultaNotaCredito = async (idContrato, idCausa) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ObtenerValorCancelacion?idContrato=${idContrato}&idCausa=${idCausa}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
        /*  console.log(response.data); */
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const consultaSeguroidcliente = async (IdCliente) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/obtenerSP?idcliente=${IdCliente}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
        /*  console.log(response.data); */
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const consultaSeguroidContrato = async (IdContrato) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ObtenerContrato?idContrato=${IdContrato}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
        /*  console.log(response.data); */
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/Seguro/RegistrarPagoRecurrencia?contrato=227391
export const consultarMedioPago = async (numeroContrato) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/RegistrarPagoRecurrencia?contrato=${numeroContrato}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
        /*  console.log(response.data); */
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5000/Producto/ObtenerProductoPorID?idProducto=61
export const consultarProductoXID = async (idProducto) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Producto/ObtenerProductoPorID?idProducto=${idProducto}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
        /*  console.log(response.data); */
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:5015/Seguro/ProcesarCancelacion --> idEstado
export const crearCancelacionSeguro = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ProcesarCancelacion`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://10.10.0.179:5000/Seguro/GuardarCancelacion
export const actualizarCancelacionSeguro = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/GuardarCancelacion`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//http://10.10.0.79:500/Seguro/GuardarBienAsegurado
export const crearBienAsegurado = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/GuardarBienAsegurado`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://10.10.0.79:500/Seguro/ProcesarContrato
export const crearSeguroContrato = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/ProcesarContrato`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GrabarContratos = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/GrabarContratos`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
        /*  console.log(response.data); */
      })
      .catch((error) => {
        reject(error);
        /*  console.log(error); */
      });
  });
};

export const procesarArchivo = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/EjecutarJobs?nombreProceso=SubidaArchivo`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
        /*  console.log(response.data); */
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/* Pagos Recurrencia Service  */

//http://10.10.0.79:5000/Seguro/RegistrarPagoManual
export const crearPagoManualSeguro = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURO}Seguro/RegistrarPagoManual`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};



/* Pago Bancos Service  */
