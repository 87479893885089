import React, { useState } from "react";

import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import InformacionDevolucionProductos from "../../containers/InformacionDevolucionProductos";
import ListaDevolucionProductos from "../../containers/ListaDevolucionProductos";

const SolicitudDevolucionSeguro = () => {
  const [solicitud, setSolicitud] = useState(null);

  return (
    <div>
      <BotonGerericoSalir />

      <ListaDevolucionProductos setSolicitud={setSolicitud} />

      {solicitud && <InformacionDevolucionProductos solicitud={solicitud} />}
    </div>
  );
};

export default SolicitudDevolucionSeguro;
