import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import TransaccionesInformacionCliente from "./TransaccionesInformacionCliente/TransaccionesInformacionCliente";

const InformacionTransaccionesCliente = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <>
      <TransaccionesInformacionCliente />
    </>
  );
};

export default InformacionTransaccionesCliente;
