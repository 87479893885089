import React, { useEffect, useState } from "react";

import { Skeleton } from "@mui/material";

import {
  SCSTableData,
  SCSTableDataRow,
  SCSTableDataCell,
} from "app/modules/common/components/TableData/SCSTableData";

import {
  consultarConsumosCredito,
  consultarEstadoCredito,
} from "../services/transaccionalService";
import CabeceraCuentaCredito from "../components/CabeceraCuentaCredito";
import DetalleOpcionGenerica from "./DetalleOpcionGenerica";

const DetalleEstadoCredito = ({
  cabenceraTransaccional,
  datosBusqueda,
  alturaResolucion,
}) => {
  const [estadosCredito, setEstadosCredito] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaEstadosCredito = await consultarConsumosCredito(
          datosBusqueda.identificacion,
          datosBusqueda.idGrupo
        );

        /*  console.log(respuestaEstadosCredito); */

        if (respuestaEstadosCredito.state && respuestaEstadosCredito.data) {
          setEstadosCredito(respuestaEstadosCredito.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={alturaResolucion}
        sx={{ borderRadius: "32px" }}
      />
    );
  }

  if (estadosCredito.cuenta === null) {
    return (
      <DetalleOpcionGenerica mensaje={"No existe información del cliente."} />
    );
  }

  const columnsData = [
    { id: 1, name: "N° Transacción" },
    { id: 2, name: "Fecha Compra" },
    { id: 3, name: "Monto" },
    { id: 4, name: "Primer Vcto" },
    { id: 5, name: "Saldo" },
    { id: 6, name: "Plazo" },
    { id: 7, name: "Valor Cuota" },
    { id: 8, name: "N° Cuota" },
    { id: 9, name: "Fecha Fin" },
  ];

  return (
    <>
      <SCSTableData
        columnsData={columnsData}
        heightTable={alturaResolucion} /* size="small" */
      >
        {estadosCredito?.map((consumo, index) => (
          <SCSTableDataRow key={consumo.numeroTransaccion}>
            <SCSTableDataCell align="center">
              {consumo.numeroTransaccion}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.fechaCompra}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {`$ ${consumo.monto}`}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.fechaPrimerVcto}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {`$ ${consumo.saldo}`}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">{consumo.plazo}</SCSTableDataCell>
            <SCSTableDataCell align="center">
              {`$ ${consumo.valorCuota}`}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.numCuota}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.fechaFin}
            </SCSTableDataCell>
          </SCSTableDataRow>
        ))}
      </SCSTableData>
    </>
  );
};

export default DetalleEstadoCredito;
