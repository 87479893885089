import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { FormControl, Grid, Alert, MenuItem, TextField } from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  consultaCatalogosSinPadre,
  consultaUbiGeografica1,
} from "../../app-cliente/services/Catalogos/catalogosService";

import {
  consultarProductoXID,
  crearSeguroContrato,
} from "../services/SeguroService/seguroService";

import "../styles/main.css";
import {
  formatoFecha,
  validarFechaMayorMenor,
} from "app/modules/shared/validations/validaciones";
import { LoadingButton } from "@mui/lab";
import {
  obtenerFechaActual,
  obtenerFechaActualFormulario,
} from "app/modules/shared/commonValues/obtenerFecha";
import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";
import ModalAlertaNotificacionEstandar from "app/modules/app-servicios/components/ModalAlertaNotificacionEstandar";

const FormVentaProducto = ({ idCliente }) => {
  const [idProductoAuxiliar, setIdProductoAuxiliar] = useState(-1);
  const [open, setOpen] = useState(false);
  const [isGT, setIsGT] = useState(false);

  const [informacionProducto, setInformacionProducto] = useState({
    idProducto: "",
    tipoPago: "",
    idLocal: "",
    fechaInicio: "",
    cuotas: "",
    valor: "",
    esRecurrencia: true,
    minMeses: 0,
    maxMeses: 0,
    valorPorcentual: 0,

    /* datos bien aseguradp */
    idClasificacionArticulo: "",
    codigoBien: "",
    descripcionBien: "",
    marca: "",
    modelo: "",
    serie: "",
    valorBien: "",
    imei: "",
    esRegistro: "",
    siniestrado: "",
    perdidaTotal: "",
  });

  const [loading, setLoading] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaInformacionProducto = await consultarProductoXID(
          idProductoAuxiliar
        );
        /* console.log(respuestaInformacionProducto.data); */

        if (respuestaInformacionProducto.state) {
          const respuestaInicialValues = {
            idProducto: respuestaInformacionProducto.data.idProducto,
            tipoPago: respuestaInformacionProducto.data.pagoSeguro,
            idLocal: "",
            fechaInicio: "",
            cuotas: respuestaInformacionProducto.data.numCuotas,
            valorPorcentual: respuestaInformacionProducto.data.valor,
            valor: "",
            esRecurrencia: respuestaInformacionProducto.data.recurrencia,
            minMeses: 0,
            maxMeses: respuestaInformacionProducto.data.maxMeses,

            /* datos bien aseguradp */
            idClasificacionArticulo: "",
            codigoBien: "",
            descripcionBien: "",
            marca: "",
            modelo: "",
            serie: "",
            valorBien: "",
            imei: "",
            esRegistro: "",
            siniestrado: "",
          };

          setInformacionProducto(respuestaInicialValues);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [idProductoAuxiliar]);

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsNumeroCuotas = ({
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const rows = [];

    for (
      let valorInicial = 0;
      valorInicial <= informacionProducto.maxMeses;
      valorInicial++
    ) {
      rows.push({ codigo: valorInicial, descripcion: valorInicial });
    }
    /* console.log({ rows });

    console.log(informacionProducto.minMeses);
    console.log(informacionProducto.maxMeses); */

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {rows.map((cuotas, index) => (
            <MenuItem key={index} value={cuotas.codigo}>
              {cuotas.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsCatalogo = ({
    pais,
    especial,
    catalogos,
    codPadre,
    grupo,
    name,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      if (pais === "ECU") {
        const getData = async () => {
          const prov = await consultaUbiGeografica1(
            especial,
            catalogos,
            codPadre,
            grupo
          );

          setProvincias(prov);
        };
        getData();
      }
    }, [pais, codPadre]);

    return (
      <FormControl sx={{ minWidth: 150 }}>
        <InputLabel id={name}>Provincia</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {pais === "ECU" ? (
            provincias.map((provincia, index) => (
              <MenuItem key={index} value={provincia.codigo}>
                {provincia.descripcion}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">none</MenuItem>
          )}
        </Field>
      </FormControl>
    );
  };

  const validarRangoCuota = (cuota) => {
    /* console.log({ cuota });
    console.log(informacionProducto.minMeses);
    console.log(informacionProducto.maxMeses); */

    if (
      cuota >= informacionProducto.minMeses &&
      cuota <= informacionProducto.maxMeses
    ) {
      return false;
    } else {
      return true;
    }
  };

  const verificacionVentaProducto = (tipoProducto) => {
    /*  console.log({ tipoProducto }); */
    if (tipoProducto === 1) {
      setIsGT(true);
      return true;
    }
    setIsGT(false);
    return false;
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const DetalleContrato = {
      idContrato: 0,
      idCliente: Number(idCliente),
      idLocal: Number(valores.idLocal), //no se de donde sacar
      idProducto: Number(valores.idProducto),
      idEstado: 9,
      tipoPago: valores.tipoPago,
      fechaInicio: valores.fechaInicio, //fecha de hoy - quemada
      valor: Number(valores.valor), //traer info producto para ver si es recurrencia
      cuotas: valores.cuotas, //verificar info para cuotas por producto para sacar las cuotas
      caja: "000778", //000 778
      vendedor: "000778", //778 - codigo caja - 000 778
      esRegistro: true, // Quemado
      esRecurrencia: informacionProducto.esRecurrencia, //traer info producto para ver si es recurrencia
      esActivo: true,
    };

    /*   console.log("Tabla Informacion Seguro");
    console.log(DetalleContrato);
 */
    const DetalleBien = {
      idBienAsegurado: 0,
      idContrato: 0,
      idClasificacionArticulo: Number(valores.idClasificacionArticulo),
      codigoBien: valores.codigoBien.toUpperCase() || "",
      descripcionBien: valores.descripcionBien.toUpperCase() || "",
      marca: valores.marca || "",
      modelo: valores.modelo.toUpperCase() || "",
      serie: valores.serie.toUpperCase() || "",
      valorBien: Number(valores.valorBien),
      imei: valores.imei.toUpperCase() || "",

      esRegistro: true,
      siniestrado: false,
      perdidaTotal: false,
      esActivo: true,
    };

    /* console.log("Tabla Informacion Bien asegurado");
    console.log(DetalleBien); */

    /* esta informacion se envia  */
    /* const informacionCrearContrato = {
      contrato: { ...DetalleContrato },
      bienAsegurado: "",
    }; */

    let informacionCrearContrato;

    if (Number(valores.idProducto) === 1) {
      informacionCrearContrato = {
        contrato: { ...DetalleContrato },
        bienAsegurado: { ...DetalleBien },
      };
    } else {
      informacionCrearContrato = {
        contrato: { ...DetalleContrato },
        bienAsegurado: "",
      };
    }

    /* console.log({ informacionCrearContrato }); */
    try {
      const respuestaCreacionContrato = await crearSeguroContrato(
        informacionCrearContrato
      );

      console.log({ respuestaCreacionContrato });

      if (respuestaCreacionContrato.state) {
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
          setOpen(true);
        }, 3000);
      } else {
        setLoading(false);
        alert(`${respuestaCreacionContrato.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  const obtenerDatosProduto = (idProducto) => {
    setIdProductoAuxiliar(idProducto);
  };

  const validacionesSinBien = () => {
    if (!isGT) {
      return {
        idProducto: Yup.string().required("Producto requerido"),
        idLocal: Yup.string().required("Local requerido"),
        tipoPago: Yup.string().required("Tipo Pago requerido"),
        cuotas: Yup.string().required("Cuotas  requerido"),
      };
    } else {
      return {
        idProducto: Yup.string().required("Producto requerido"),
        idLocal: Yup.string().required("Local requerido"),
        tipoPago: Yup.string().required("Tipo Pago requerido"),
        cuotas: Yup.string().required("Cuotas  requerido"),
        idClasificacionArticulo: Yup.string().required(
          "Clasificacion Articulo  requerido"
        ),
        valorBien: Yup.string().required("Valor Bien  requerido"),
        marca: Yup.string().required("Marca requerido"),
        modelo: Yup.string().required("Modelo requerido"),
        serie: Yup.string().required("Serie requerido"),
        codigoBien: Yup.string().required("Código Bien requerido"),
        descripcionBien: Yup.string().required("Descripción Bien requerido"),
      };
    }
  };

  const calcularValorSeguro = (valorBien) => {
    const valorBienAux = `${valorBien === "" ? 0 : valorBien}`;

    if (Number(valorBienAux) <= 100) {
      return 15;
    } else {
      return (Number(valorBienAux) * 15) / 100;
    }
  };

  return (
    <>
      <Formik
        initialValues={informacionProducto}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};

          const fechaActual = new Date();

          if (
            !validarFechaMayorMenor(values.fechaInicio, fechaActual, "MENOR")
          ) {
            errors.fechaInicio = "Fecha no puede ser mayor a la actual";
          }

          return errors;
        }}
        validationSchema={
          Yup.object(validacionesSinBien())

          /* 
          { Yup.object({
            cuotas: Yup.string().required("Cuotas  requerido"),
          }))} */
        }
        onSubmit={(values, { resetForm }) => {
          configuracionDatosEnvio(values);
        }}
      >
        {({ values, handleChange, touched, errors, handleBlur }) => (
          <Form>
            <Accordion
              defaultExpanded="true"
              sx={{ boxShadow: 3, lineHeight: 0 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="accordion-summary"
                sx={{ margin: 0 }}
              >
                <h2 className="formulario-cabecera">Seguro</h2>
              </AccordionSummary>

              <AccordionDetails sx={{ marginTop: "2rem" }}>
                <Grid container spacing={1} rowSpacing={1}>
                  <Grid item md={12}>
                    <Grid container spacing={1}>
                      <Grid item md={12}>
                        <div className="cliente-informacion-seccion">
                          <SelectItemsCatalogoSinPadre
                            pais="ECU"
                            especial="true"
                            catalogos="PRODSEG"
                            grupo={1}
                            name="idProducto"
                            labelName="Producto"
                            componentSize="27%"
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                          />

                          {obtenerDatosProduto(values.idProducto)}

                          <SelectItemsCatalogoSinPadre
                            pais="ECU"
                            especial="true"
                            catalogos="PAGSEG"
                            grupo={1}
                            name="tipoPago"
                            labelName="Tipo Pago"
                            componentSize="27%"
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                          />

                          {/*  <FormControl
                            sx={{ width: "30%" }}
                            error={touched.tipoPago && Boolean(errors.tipoPago)}
                            helperText={touched.tipoPago && errors.tipoPago}
                            onBlur={handleBlur}
                          >
                            <InputLabel id="tipoPago ">Tipo Pago</InputLabel>
                            <Field
                              name="tipoPago"
                              label="tipoPago"
                              component={selectPersonalizarPropiedades}
                            >
                              <MenuItem value="PAGSEG04">
                                CREDITO DIRECTO
                              </MenuItem>
                            </Field>
                            <ErrorMessage
                              name="tipoPago"
                              component={() => (
                                <FormHelperText>
                                  {errors.tipoPago}
                                </FormHelperText>
                              )}
                            />
                          </FormControl> */}

                          <FormControl
                            sx={{ width: "32%" }}
                            error={touched.idLocal && Boolean(errors.idLocal)}
                            helperText={touched.idLocal && errors.idLocal}
                            onBlur={handleBlur}
                          >
                            <InputLabel id="idLocal ">Local</InputLabel>
                            <Field
                              name="idLocal"
                              label="Local"
                              component={selectPersonalizarPropiedades}
                            >
                              <MenuItem value="911">911- SERVIMAX</MenuItem>
                            </Field>
                            <ErrorMessage
                              name="idLocal"
                              component={() => (
                                <FormHelperText>
                                  {errors.idLocal}
                                </FormHelperText>
                              )}
                            />
                          </FormControl>
                        </div>

                        <div className="cliente-informacion-seccion">
                          <TextField
                            id="fechaInicio"
                            name="fechaInicio"
                            type="date"
                            label="Fecha de Compra"
                            sx={{ width: "20%" }}
                            onChange={handleChange}
                            size="small"
                            value={values.fechaInicio}
                            InputLabelProps={{ shrink: true }}
                            error={
                              touched.fechaInicio && Boolean(errors.fechaInicio)
                            }
                            helperText={
                              touched.fechaInicio && errors.fechaInicio
                            }
                            onBlur={handleBlur}
                          />

                          <TextField
                            disabled
                            id="valor"
                            name="valor"
                            label="Valor"
                            sx={{ width: "15%" }}
                            value={calcularValorSeguro(values.valorBien)}
                            onChange={handleChange}
                            size="small"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                          />

                          <TextField
                            disabled
                            sx={{ width: "15%" }}
                            id="cuotas"
                            name="cuotas"
                            type="number"
                            label="Cuotas"
                            value={values.cuotas}
                            size="small"
                            onChange={handleChange}
                            error={touched.cuotas && Boolean(errors.cuotas)}
                            helperText={touched.cuotas && errors.cuotas}
                            onBlur={handleBlur}
                          />

                          {/* <SelectItemsNumeroCuotas
                            name="cuotas"
                            labelName="cuotas"
                            componentSize="27%"
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                          /> */}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {verificacionVentaProducto(values.idProducto) && (
              <Accordion
                defaultExpanded="true"
                sx={{ boxShadow: 3, lineHeight: 0 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className="accordion-summary"
                  sx={{ margin: 0 }}
                >
                  <h2 className="formulario-cabecera">Producto</h2>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container spacing={1} rowSpacing={1}>
                    <Grid item md={12}>
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          <div className="cliente-informacion-seccion">
                            <SelectItemsCatalogoSinPadre
                              pais="ECU"
                              especial="true"
                              catalogos="CLAART"
                              name="idClasificacionArticulo"
                              labelName="Clasificación Artículo"
                              grupo={1}
                              componentSize="30%"
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />

                            <TextField
                              id="codigoBien"
                              name="codigoBien"
                              type="text"
                              label="Codigo Barras"
                              sx={{ width: "22%" }}
                              value={values.codigoBien}
                              onChange={handleChange}
                              size="small"
                              inputProps={{
                                style: {
                                  textTransform: "uppercase",
                                },
                              }}
                              error={
                                touched.codigoBien && Boolean(errors.codigoBien)
                              }
                              helperText={
                                touched.codigoBien && errors.codigoBien
                              }
                              onBlur={handleBlur}
                            />

                            <TextField
                              id="valorBien"
                              name="valorBien"
                              label="Valor Del Bien"
                              sx={{ width: "14%" }}
                              value={values.valorBien}
                              onChange={handleChange}
                              size="small"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              error={
                                touched.valorBien && Boolean(errors.valorBien)
                              }
                              helperText={touched.valorBien && errors.valorBien}
                              onBlur={handleBlur}
                            />

                            <TextField
                              id="serie"
                              name="serie"
                              type="text"
                              label="N° Serie"
                              sx={{ width: "27%" }}
                              value={values.serie}
                              onChange={handleChange}
                              size="small"
                              inputProps={{
                                style: {
                                  textTransform: "uppercase",
                                },
                              }}
                              error={touched.serie && Boolean(errors.serie)}
                              helperText={touched.serie && errors.serie}
                              onBlur={handleBlur}
                            />
                          </div>

                          <div className="cliente-informacion-seccion">
                            <SelectItemsCatalogoSinPadre
                              pais="ECU"
                              especial="false"
                              catalogos="MARCA"
                              name="marca"
                              labelName="Marca"
                              grupo={1}
                              componentSize="32%"
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />

                            <TextField
                              id="modelo"
                              name="modelo"
                              type="text"
                              label="Modelo"
                              sx={{ width: "31%" }}
                              value={values.modelo}
                              onChange={handleChange}
                              size="small"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              error={touched.modelo && Boolean(errors.modelo)}
                              helperText={touched.modelo && errors.modelo}
                              onBlur={handleBlur}
                            />

                            <TextField
                              id="imei"
                              name="imei"
                              type="text"
                              label="Imei"
                              sx={{ width: "30%" }}
                              value={values.imei}
                              onChange={handleChange}
                              size="small"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                            />

                            <TextField
                              id="descripcionBien"
                              name="descripcionBien"
                              type="text"
                              label="Descripción Articulo"
                              sx={{ width: "98%" }}
                              value={values.descripcionBien}
                              onChange={handleChange}
                              size="small"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              error={
                                touched.descripcionBien &&
                                Boolean(errors.descripcionBien)
                              }
                              helperText={
                                touched.descripcionBien &&
                                errors.descripcionBien
                              }
                              onBlur={handleBlur}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}

            {datosEnviadosCorrectamente && (
              <Alert severity="success" variant="filled">
                Los datos han sido guardados correctamente.
              </Alert>
            )}

            <div className="cliente-informacion-seccion">
              <LoadingButton
                sx={{ width: "35ch" }}
                type="submit"
                size="small"
                loading={loading}
                loadingPosition="end"
                variant="contained"
              >
                Guardar
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
      <ModalAlertaNotificacionEstandar
        open={open}
        titulo="Notificación Seguro"
        mensaje="Seguro generado con Exito !!"
      />
    </>
  );
};

export default FormVentaProducto;
