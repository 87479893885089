import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import {
  Alert,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";

import InputLabel from "@mui/material/InputLabel";

import Select from "@mui/material/Select";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import {
  consultaCatalogosSinPadre,
  consultaUbiGeografica1,
} from "../../app-cliente/services/Catalogos/catalogosService";

import { crearLocal, obtenerFormato } from "../services/configuracionServices";

import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const FormImformacionLocal = ({ idLocal, local }) => {
  const [idLocalAux, setIdLocalAux] = useState(idLocal);
  const [loading, setLoading] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  useEffect(() => {
    console.log({ local });
    console.log({ idLocal });
  }, []);

  const SelectItemsFormatoSinPadre = ({
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [formato, setFormato] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await obtenerFormato();

          if (state) {
            setFormato(data);
          } else {
            setFormato([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {formato.map((formato, index) => (
            <MenuItem key={index} value={formato.idFormato}>
              {formato.nombreFormato}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsCatalogo = ({
    pais,
    especial,
    catalogos,
    codPadre,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaUbiGeografica1(
            especial,
            catalogos,
            codPadre,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, [pais, codPadre]);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const informacionLocal = {
      idLocal: Number(valores.idLocal),
      idFormato: Number(valores.idFormato),
      idUbicGeografica2: Number(valores.idUbicGeografica2),
      nombreLocal: valores.nombreLocal.toUpperCase(),
      listaCorporacion: valores.listaCorporacion,
      region: valores.region.toUpperCase(),
      emails: valores.emails.toLowerCase(),
      emailNotificacion: valores.emailNotificacion.toLowerCase(),
      esActivo: valores.esActivo,
    };
    console.log("VALORES", informacionLocal);

    try {
      const respuestaLocal = await crearLocal(informacionLocal);

      if (respuestaLocal.state) {
        setDatosEnviadosCorrectamente(true);
        setIdLocalAux(respuestaLocal.data);

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
        }, 2000);
      } else {
        alert(`${respuestaLocal.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <>
      {idLocalAux < 0 && (
        <Alert
          color="warning"
          severity="warning"
          sx={{ padding: 1, margin: 1 }}
        >
          Local NO existe.!! Complete y guarde la información.
        </Alert>
      )}
      <Formik
        initialValues={{
          idLocal: local.idLocal ?? "",
          idFormato: local.idFormato ?? "",
          idUbicGeografica1: local.idUbicGeografica1 ?? "",
          idUbicGeografica2: local.idUbicGeografica2 ?? "",
          nombreLocal: local.nombreLocal ?? "",
          listaCorporacion: local.listaCorporacion ?? false,
          region: local.region ?? "",
          emails: local.emails ?? "",
          emailNotificacion: local.emailNotificacion ?? "",
          esActivo: local.esActivo ?? true,
        }}
        validationSchema={Yup.object({
          idLocal: Yup.string().required("Local requerido"),
          idFormato: Yup.string().required("Formato requerido"),
          idUbicGeografica1: Yup.string().required("Provincia requerido"),
          idUbicGeografica2: Yup.string().required("Ciudad requerido"),
          nombreLocal: Yup.string().required("Nombre Local requerido"),
          region: Yup.string().required("Región requerido"),
          emails: Yup.string().required("Email requerido"),
          emailNotificacion: Yup.string().required(
            "Email notificación requerido"
          ),
          esActivo: Yup.string().required("Estado requerido"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          // console.log(values);
          configuracionDatosEnvio(values);
        }}
      >
        {({ values, handleChange, touched, errors, handleBlur }) => (
          <Form>
            <div className="accordion-container">
              <div>
                <Accordion
                  defaultExpanded="true"
                  sx={{ boxShadow: 3, lineHeight: 0 }}
                >
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className="accordion-summary"
                    sx={{ margin: 0 }}
                  >
                    <h2 className="formulario-cabecera">Local</h2>
                  </AccordionSummary>
                  <AccordionDetails sx={{ marginTop: "2rem" }}>
                    <Grid container spacing={1} rowSpacing={1}>
                      <Grid item md={12}>
                        <Grid container spacing={1}>
                          <Grid item md={12}>
                            <div className="cliente-informacion-seccion">
                              <TextField
                                id="idLocal"
                                name="idLocal"
                                type="number"
                                label="Código Local"
                                sx={{ width: "15%" }}
                                value={values.idLocal}
                                onChange={handleChange}
                                size="small"
                                disabled={false}
                                error={
                                  touched.idLocal && Boolean(errors.idLocal)
                                }
                                helperText={touched.idLocal && errors.idLocal}
                                onBlur={handleBlur}
                              />

                              <SelectItemsFormatoSinPadre
                                name="idFormato"
                                labelName="Formato"
                                componentSize="25%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <SelectItemsCatalogo
                                pais="ECU"
                                especial="true"
                                catalogos="UG1"
                                codPadre={0}
                                grupo={1}
                                name="idUbicGeografica1"
                                labelName="Provincia"
                                componentSize="25%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <SelectItemsCatalogo
                                pais="ECU"
                                especial="true"
                                catalogos="UG2"
                                codPadre={values.idUbicGeografica1}
                                grupo={1}
                                name="idUbicGeografica2"
                                labelName="Canton"
                                componentSize="25%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />
                            </div>

                            <div className="cliente-informacion-seccion">
                              <TextField
                                id="nombreLocal"
                                name="nombreLocal"
                                type="text"
                                label="Nombre Local"
                                sx={{ width: "20%" }}
                                value={values.nombreLocal}
                                onChange={handleChange}
                                size="small"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                error={
                                  touched.nombreLocal &&
                                  Boolean(errors.nombreLocal)
                                }
                                helperText={
                                  touched.nombreLocal && errors.nombreLocal
                                }
                                onBlur={handleBlur}
                              />

                              <SelectItemsCatalogoSinPadre
                                pais="ECU"
                                especial="false"
                                catalogos="ZONA"
                                grupo={1}
                                name="region"
                                labelName="Region"
                                componentSize="20%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <TextField
                                id="emails"
                                name="emails"
                                type="text"
                                label="Email"
                                sx={{ width: "26%" }}
                                value={values.emails}
                                onChange={handleChange}
                                size="small"
                                error={touched.emails && Boolean(errors.emails)}
                                helperText={touched.emails && errors.emails}
                                onBlur={handleBlur}
                              />

                              <TextField
                                id="emailNotificacion"
                                name="emailNotificacion"
                                type="text"
                                label="Email Notificación"
                                sx={{ width: "27%" }}
                                value={values.emailNotificacion}
                                onChange={handleChange}
                                size="small"
                                error={
                                  touched.emailNotificacion &&
                                  Boolean(errors.emailNotificacion)
                                }
                                helperText={
                                  touched.emailNotificacion &&
                                  errors.emailNotificacion
                                }
                                onBlur={handleBlur}
                              />
                            </div>

                            <div className="cliente-informacion-seccion">
                              <FormGroup
                                error={
                                  touched.esActivo && Boolean(errors.esActivo)
                                }
                                helperText={touched.esActivo && errors.esActivo}
                                onBlur={handleBlur}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.esActivo}
                                      onChange={handleChange}
                                      name="esActivo"
                                    />
                                  }
                                  label="Activo"
                                />
                                <ErrorMessage
                                  name="esActivo"
                                  component={() => (
                                    <FormHelperText>
                                      {errors.esActivo}
                                    </FormHelperText>
                                  )}
                                />
                              </FormGroup>

                              <FormGroup
                                error={
                                  touched.listaCorporacion &&
                                  Boolean(errors.listaCorporacion)
                                }
                                helperText={
                                  touched.listaCorporacion &&
                                  errors.listaCorporacion
                                }
                                onBlur={handleBlur}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.listaCorporacion}
                                      onChange={handleChange}
                                      name="listaCorporacion"
                                    />
                                  }
                                  label="Lista Corporación"
                                />
                                <ErrorMessage
                                  name="listaCorporacion"
                                  component={() => (
                                    <FormHelperText>
                                      {errors.listaCorporacion}
                                    </FormHelperText>
                                  )}
                                />
                              </FormGroup>
                            </div>

                            <div className="confirmar-estados-servicios">
                              <LoadingButton
                                sx={{ width: "20%" }}
                                type="submit"
                                size="small"
                                loading={loading}
                                loadingPosition="end"
                                variant="contained"
                              >
                                Guardar
                              </LoadingButton>

                              {datosEnviadosCorrectamente && (
                                <AcceptServiceresponse />
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormImformacionLocal;
