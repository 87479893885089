import React, { memo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import "../../styles/main.css";

import {
  obtenerSolicitud,
  obtenerSolucitudesCliente,
} from "../../services/creditoServices";
import TrackingSolicitud from "../../components/TrackingSolicitud";
import BuscadorIdentificacion from "../../containers/BuscadorIdentificacion";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";

function TrackingSolicitudContent() {
  const navigate = useHistory();

  const [solicitud, setSolicitud] = useState({});
  const [solicitudes, setSolicitudes] = useState(null);
  const [mostrarInfo, setMostrarInfo] = useState(false);
  const [identificacionCliente, setIdentificacionCliente] = useState("");
  const [mostrarPdf, setMostrarPdf] = useState(false);

  const traerIdentificacion = (id) => async () => {
    setIdentificacionCliente(id);
    await getInformacion(id);
  };

  const getInformacion = async (identificacion) => {
    try {
      var resp = await obtenerSolucitudesCliente(identificacion);
      if (resp.state) {
        if (resp.data.length > 0) {
          setSolicitudes(resp.data);
        } else {
          setSolicitudes(null);
          alert("No existen solicitudes");
        }
      }
    } catch (e) {
      alert(e.message);
    }
  };

  const obtenerInfo = async (idSolicitud) => {
    try {
      var response = await obtenerSolicitud(idSolicitud);
      if (response.state) {
        console.log(response.data);
        if (response.data !== null) {
          setSolicitud(response.data);
          setMostrarInfo(true);
        } else alert("No existe solicitud.");
      }
    } catch (e) {
      alert(e.message);
    }
  };

  const handleSalirLimpiar = () => {
    setMostrarInfo(false);
    setSolicitudes(null);
    navigate.push("/credito/trackingSolicitud");
  };

  const rowSolicitudes = solicitudes;
  const flujos = {
    1: "CORPORACION FAVORITA",
    2: "CORPORACION FAVORITA",
    3: "CORPORACION FAVORITA",
    4: "CORPORACION FAVORITA",
    5: "TVENTAS",
  };

  return (
    <>
      <div className="closeButton-container">
        <IconButton
          aria-label="cerrar"
          size="large"
          onClick={() => {
            handleSalirLimpiar();
          }}
        >
          Cerrar
          <CloseIcon fontSize="large" />
        </IconButton>
      </div>
      <Grid container rowSpacing={4} spacing={5} justifyContent="center">
        <Grid item md={6} sm={6} xs={6} hidden={mostrarInfo}>
          <BuscadorIdentificacion traerIdentificacion={traerIdentificacion} />
        </Grid>
        {solicitudes && (
          <Grid item md={10} sm={10} xs={10} hidden={mostrarInfo}>
            <Box sx={{ height: 600, width: "100%" }}>
              <p>SOLICITUDES</p>
              <Table size="small">
                <TableHead sx={{ bgcolor: "text.disabled" }}>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {" "}
                      Identificacion
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Solicitud
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Origen
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Monto Solicitado
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Fecha
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Monto Aprobado
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      Accion
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowSolicitudes.map((row) => (
                    <TableRow>
                      <TableCell align="center">{row.identificacion}</TableCell>
                      <TableCell align="center">
                        {row.numeroSolicitud}
                      </TableCell>
                      <TableCell align="center">
                        {flujos[row.idFlujo]}
                      </TableCell>
                      <TableCell align="center">
                        {row.montoSolicitado}
                      </TableCell>
                      <TableCell align="center">
                        {row.fechaSolicitud.split("T")[0]}
                      </TableCell>
                      <TableCell align="center">{row.montoAprobado}</TableCell>
                      <TableCell align="center" sx={{ width: 300 }}>
                        <SCSGenericButton
                          titulo="Revisar"
                          onClick={() => {
                            obtenerInfo(row.idSolicitud);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Grid>
        )}

        {solicitud.idFlujo && (
          <Grid hidden={!mostrarInfo} item md={8} sm={8} xs={8}>
            <TrackingSolicitud solicitud={solicitud} />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default memo(TrackingSolicitudContent);
