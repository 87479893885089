import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { Formik, Form, ErrorMessage, Field } from "formik";

import * as Yup from "yup";

import { Alert, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { desestimarRequerimiento } from "../services/solicitudesService";

const FormDesestimarRequerimiento = ({ solicitud }) => {
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    console.log({ solicitud });
  }, []);

  const configuraionEnvioDatos = async (valores) => {
    setLoading(true);

    const informacionDesestimarRequerimiento = {
      idRequerimiento: solicitud.idRequerimiento,
      observacion: valores.detalle.toUpperCase(),
      esActivo: true,
    };

    console.log(informacionDesestimarRequerimiento);

    try {
      const respuestaDesestimar = await desestimarRequerimiento(
        informacionDesestimarRequerimiento
      );

      if (respuestaDesestimar.state) {
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
          history.push("/initialHome");
        }, 1000);
      } else {
        setLoading(false);
        alert(`${respuestaDesestimar.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <div className="accordion-container">
      <Formik
        initialValues={{
          detalle: "",
        }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        validationSchema={Yup.object({
          detalle: Yup.string().required("Detalle requerido"),
        })}
        onSubmit={(values, { resetForm }) => {
          configuraionEnvioDatos(values);
          setTimeout(() => {
            resetForm({ values: "" });
          }, 1000);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <div className="cliente-informacion-seccion">
              <TextField
                id="detalle"
                name="detalle"
                multiline
                type="text"
                label="Detalle"
                rows={2}
                sx={{ width: "97%" }}
                value={values.detalle}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={touched.detalle && Boolean(errors.detalle)}
                helperText={touched.detalle && errors.detalle}
                onBlur={handleBlur}
              />

              <LoadingButton
                sx={{ width: "20%" }}
                type="submit"
                size="small"
                loading={loading}
                loadingPosition="end"
                variant="contained"
              >
                GUARDAR
              </LoadingButton>

              {datosEnviadosCorrectamente && (
                <Alert severity="success" variant="filled">
                  Los datos han sido enviados correctamente.
                </Alert>
              )}
            </div>
          </Form>
        )}
      </Formik>

      {/*  <ModalAlertaNotificacionEstandar
        setOpen={setOpen}
        open={open}
        titulo="Solicitud Estado De Deuda"
        mensaje="La solicitud ha sido generada con exito y enviada al correo!!"
      /> */}
    </div>
  );
};

export default FormDesestimarRequerimiento;
