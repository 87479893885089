import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import ActualizarMedioPago from "./ActualizarMedioPago/ActualizarMedioPago";

const SeguroMedioPago = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Medio Pago - Seguro</h2>

      <ActualizarMedioPago />
    </div>
  );
};

export default SeguroMedioPago;
