import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { consultaCliente } from "app/modules/app-cliente/services/clienteService";

import StepInformacionCredito from "../../containers/stepInformacionCredito";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const FlujoPunta = () => {
  const history = useHistory();
  console.log(history.location.state.state.identificacion);
  // const [identificacion] = location;
  const identificacion = history.location.state.state.identificacion;
  // const identificacion = location.state.identificacion;
  const [idCliente, setIdCliente] = useState(null);
  const [cliente, setCliente] = useState(null);

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  useEffect(() => {
    console.log({ identificacion });
    const obtenerCliente = async () =>{
      try{
      const resp = await consultaCliente(identificacion);
      
      if (resp.state) {
        //console.log(resp.data);
        setIdCliente(resp.data.idCliente);
        setCliente(resp.data);
      } else {
        setCliente({});
        setIdCliente(-1);
        console.log(resp.message);
      }
    }
    catch(e){
      setCliente({});
      setIdCliente(-1);
      console.log(e);
    }

    } 
    obtenerCliente();   
  }, []);

  
  return (
    <div>
      {idCliente ? (
        <div className=" p-24 bg-[#ffffff]  h-full w-full ">
          {/* <h1>
            Solicitud de credito <br /> Cédula: {identificacion}
          </h1> */}
          <StepInformacionCredito
            identificacion={identificacion}
            idCli={idCliente}
            clienteConsultado={cliente}
          />
        </div>
      ) : (
        <>
        <SpinnerLoadSrx />
        </>
      )}
    </div>
  );
};

export default FlujoPunta;
