import React, { useState } from "react";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import InformacionPrecancelacion from "../../containers/InformacionPrecancelacion";
import ListaPrecancelacion from "../../containers/ListaPrecancelacion";

function SolicitudPrecancelacion() {
  const [solicitud, setSolicitud] = useState(null);
  return (
    <>
      <BotonGerericoSalir />

      <ListaPrecancelacion setSolicitud={setSolicitud} />

      {solicitud && <InformacionPrecancelacion solicitud={solicitud} />}
    </>
  );
}

export default SolicitudPrecancelacion;
