import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import SaveIcon from "@mui/icons-material/Save";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CheckIcon from "@mui/icons-material/Check";

import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";

import handleDownlPdf from "../../../utils/document/documentosService";
import descargaImage from "../../../utils/document/documentoPng";

import {
  consultarDocumentosSoporte,
  consultarRequerimiento,
  crearSoporteRequerimiento,
} from "../services/solicitudesService";
import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/shared/mask/stylesTables";
import VisualizadorDocumentos from "./VisualizadorDocumentos";

const DocumentosPrecancelacion = ({ documentos }) => {
  const [nombreDocumentoAux, setNombreDocumentoAux] = useState("");
  const [open, setOpen] = useState(false);

  console.log(documentos);

  const configuracionDatosEnvioDocumento = (valores) => {
    const nombreDocumento = `${valores.nombreArchivo}${valores.extension}`;

    console.log(nombreDocumento);

    setNombreDocumentoAux(nombreDocumento);
    console.log(valores);
    setOpen(true);
  };

  return (
    <div className="informacionPrecancelacion_documentos">
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 400 }}
          size="small"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Documento</StyledTableCell>

              <StyledTableCell align="center">Descargar</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentos.map((soporte, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {soporte.nombreArchivo}
                </StyledTableCell>

                <StyledTableCell align="center">
                  <Button
                    variant="outlined"
                    size="small"
                    /* sx={{ width: "70%" }} */
                    /*  startIcon={<SaveIcon />} */
                    onClick={() => configuracionDatosEnvioDocumento(soporte)}
                  >
                    Visualizar
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {nombreDocumentoAux && (
        <VisualizadorDocumentos
          setOpen={setOpen}
          open={open}
          nombreDocumentoAux={nombreDocumentoAux}
        />
      )}
    </div>
  );
};

export default DocumentosPrecancelacion;
