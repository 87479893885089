import React, { useEffect, useState } from "react";

import FormClienteEstadoFinanciero from "./FormClienteEstadoFinanciero";
import FormClienteActividadDependiente from "./FormClienteActividadDependiente";
import FormClienteActividadIndependiente from "./FormClienteActividadIndependiente";

import {
  consultaEstFinanciero,
  consultaClienteXidCliente,
} from "../services/clienteService";

import BotonSalirLimiarStorageCliente from "../containers/BotonSalirLimiarStorageCliente";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

import "../styles/main.css";

const FormClienteActividadEconomica = ({
  idCliente,
  cliente = {},
  setInfoClienteGrabada,
}) => {
  const [idClienteForm, setIdClienteForm] = useState(null);
  const [clienteInfo, setClienteInfo] = useState(null);
  const [actividadEconomica, setActividadEconomica] = useState(null);

  useEffect(() => {
    const getData = async () => {
      /*  console.log(idCliente); */

      setIdClienteForm(idCliente);

      let infoCliente;
      let infoEstadoFinanciero;

      try {
        const informacionCliente = await consultaClienteXidCliente(idCliente);

        /* console.log(infoCliente); */

        if (informacionCliente.state) {
          infoCliente = informacionCliente.data;
        } else {
          infoCliente = {};
        }

        const informacionEstadoFinanciero = await consultaEstFinanciero(
          idCliente
        );
        /* console.log(infoEstadoFinanciero); */

        if (informacionEstadoFinanciero.state) {
          infoEstadoFinanciero = informacionEstadoFinanciero.data;
        } else {
          infoEstadoFinanciero = {};
        }
      } catch (error) {
        console.log(error);
      }

      const res = {
        ...infoCliente,
        ...infoEstadoFinanciero,
      };

      /* console.log(res); */
      setClienteInfo(res);
    };

    getData();
    /* console.log(idClienteForm); */
  }, []);

  useEffect(() => {
    /* console.log(actividadEconomica); */
  }, [actividadEconomica]);

  return (
    <>
      <BotonSalirLimiarStorageCliente />
      {clienteInfo ? (
        <>
          <div className="accordion-container">
            <FormClienteEstadoFinanciero
              idCliente={idCliente}
              idSolicitud={-1}
              setActividadEconomica={setActividadEconomica}
              //setInfoClienteGrabada={setInfoClienteGrabada}
            />

            {!actividadEconomica && (
              <FormClienteActividadDependiente
                idCliente={idCliente}
                cliente={cliente}
                setInfoClienteGrabada={setInfoClienteGrabada}
              />
            )}

            {actividadEconomica && (
              <FormClienteActividadIndependiente
                idCliente={idCliente}
                setInfoClienteGrabada={setInfoClienteGrabada}
              />
            )}
          </div>
        </>
      ) : (
        <SpinnerLoadSrx />
      )}
    </>
  );
};

export default FormClienteActividadEconomica;
