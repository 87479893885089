import React from "react";

import { Card } from "@mui/material";
import { formatoFecha } from "app/modules/shared/validations/validaciones";

const CabeceraSolicitudCobranza = ({ informacionCobranza }) => {
  return (
    <div className="header-cobranza-container">
      <Card>
        <header className="header-cobranza-information">
          <section className="header-cobranza-titulo">
            {/*  <h2>Solicitud</h2> */}
          </section>

          <section className="header-cobranza-detalle">
            <div className="header-cobranza-detalle-filas">
              <h5> {`Nombre: ${informacionCobranza?.nombreCliente}`}</h5>
            </div>
            <div className="header-cobranza-detalle-filas">
              <h5>{`Contacto: ${informacionCobranza?.contacto}`}</h5>
            </div>
            <div className="header-cobranza-detalle-filas">
              <h5>
                {`Fecha Corte: ${formatoFecha(
                  informacionCobranza?.fechaCorte
                )}`}
              </h5>
            </div>
            <div className="header-cobranza-detalle-filas">
              <h5>
                {`Fecha Máxima: ${formatoFecha(
                  informacionCobranza?.fechaMaxima
                )}`}
              </h5>
            </div>

            <div className="header-cobranza-detalle-filas">
              <h5>{`Estados Vencidos: ${informacionCobranza?.estadosVencidos}`}</h5>
            </div>
            <div className="header-cobranza-detalle-filas">
              <h5>{`Valor vencidos: $ ${informacionCobranza?.valorVencido}`}</h5>
            </div>
            <div className="header-cobranza-detalle-filas">
              <h5>{`Saldo Anterior: $ ${informacionCobranza?.saldoAnterior}`}</h5>
            </div>
            <div className="header-cobranza-detalle-filas">
              <h5>{`Pagos Actual: $ ${informacionCobranza?.pagosActual}`}</h5>
            </div>

            <div className="header-cobranza-detalle-filas">
              <h5>{`Estado: ${informacionCobranza?.estadoCliente}`}</h5>
            </div>
            <div className="header-cobranza-detalle-filas">
              <h5>{`Valor a pagar: $ ${informacionCobranza?.valorApagar}`}</h5>
            </div>
            <div className="header-cobranza-detalle-filas">
              <h5>{`Interés Mora Vencido: ${informacionCobranza?.interesMoraVencido}`}</h5>
            </div>
            <div className="header-cobranza-detalle-filas">
              <h5>{`Desgravamen: ${
                informacionCobranza.seguroDesgravamen ? "SI" : "NO"
              }`}</h5>
            </div>
          </section>
        </header>
      </Card>
    </div>
  );
};

export default CabeceraSolicitudCobranza;

{
  /*  <div>
            <h5>valorApagar</h5>
            <h5>{informacionCobranza.valorApagar}</h5>
          </div>

          <div>
            <h5>valorVencido</h5>
            <h5>{informacionCobranza.valorVencido}</h5>
          </div>

          <div>
            <h5>Fecha Solicitud</h5>
            <h5>
              {informacionCobranza.fechaSolicitud
                ? formatoFecha(informacionCobranza.fechaSolicitud)
                : ""}
            </h5>
          </div>

          <div>
            <h5>Monto Solicitado</h5>
            <h5>
              {informacionCobranza.montoSolicitado
                ? informacionCobranza.montoSolicitado
                : ""}
            </h5>
          </div> */
}
