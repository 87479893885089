import React, { useState } from "react";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TaskIcon from "@mui/icons-material/Task";

import { Formik, Form } from "formik";
import { TextField } from "@mui/material";
import {
  consultarSabanaPolizas,
  consultarSiniestroAseguradora,
  consultarBloqueoColaborador,
} from "../services/ReporteService";
import downloadExcel from "app/utils/document/documentoDescargaExel";
import { formatoFecha } from "app/modules/shared/validations/validaciones";
import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";
import { LoadingButton } from "@mui/lab";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    /*  hight: 2000 */
    minWidth: 800,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    /* width: "50%", */
  },
  /* "& .MuiDialogActions-root": {
    with: 30,
  }, */
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalReporteConsultaBloqueoColaborador = ({ children, open, setOpen, titulo, mensaje }) => {
  const [reporteBloqueoColaborador, setReporteBloqueoColaborador] = useState([]);
  const [loading, setLoading] = useState(false);

  const obtenerReporteBloqueoColaborador = async (fechaInicio, fechaFin) => {
    setLoading(true);

    try {
      const respuestaBloqueoColaborador = await consultarBloqueoColaborador(
        fechaInicio,
        fechaFin
      );

      if (respuestaBloqueoColaborador.state) {
        //el aux sirve para
        const respuestaBloqueoColaboradorAux = respuestaBloqueoColaborador.data.map(
          (bloqueoColaborador, index) => {
            return {
              ...bloqueoColaborador,
              fechaBloqueoColaborador: formatoFecha(bloqueoColaborador.fechaBloqueoColaborador),
              ID: index,
            };
          }
        );

        setReporteBloqueoColaborador(respuestaBloqueoColaboradorAux);

        downloadExcel(
          respuestaBloqueoColaborador.data,
          `REPBLOQUEOCOLABORADOR__${fechaInicio}__${fechaFin}`
        );
        setLoading(false);
      } else {
        setReporteBloqueoColaborador([]);
        setLoading(false);
        alert("Error: No se ha podido gererar el reporte !!");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Error: No se ha podido gererar el reporte !!");
    }
  };

  const enviarDatosBusqueda = (valores) => {
    obtenerReporteBloqueoColaborador(valores.fechaInicio, valores.fechaFin);
  };

  return (
    <div>
      <BootstrapDialog
        /* aria-describedby="alert-dialog-slide-description" */
        /* style={{ height: "30%" }} */

        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpen(false)}
        >
          {titulo}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={{
              fechaInicio: "",
              fechaFin: "",
            }}
            onSubmit={(valores, { setSubmitting }) => {
              enviarDatosBusqueda(valores);
            }}
          >
            {({ values, handleChange }) => (
              <Form>
                <div className="buscador-gestion-opciones">
                  <TextField
                    id="fechaInicio"
                    name="fechaInicio"
                    type="date"
                    label="Fecha Inicio"
                    sx={{ width: "25%" }}
                    value={values.fechaInicio}
                    onChange={handleChange}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />

                  <TextField
                    id="fechaFin"
                    name="fechaFin"
                    type="date"
                    label="Fecha Fin"
                    sx={{ width: "25%" }}
                    value={values.fechaFin}
                    onChange={handleChange}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />

                  <LoadingButton
                    size="large"
                    type="submit"
                    loading={loading}
                    variant="contained"
                    loadingPosition="end"
                    endIcon={<TaskIcon />}
                  >
                    Generar
                  </LoadingButton>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ModalReporteConsultaBloqueoColaborador;
