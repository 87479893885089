import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import React, { useState } from "react";
import BuscadorPorIdentificador from "../../../app-cliente/containers/BuscadorPorIdentificador";
import FormPrestadorMedico from "../../components/FormPrestadorMedico";

const GestionPrestadoresMedicos = () => {
  const [rucPresradorMedico, setRucPresradorMedico] = useState(null);
  const [isRUC, setIsRUC] = useState(false);

  const traerIdCliente = (RUC) => () => {
    console.log(RUC);
    setRucPresradorMedico(RUC);
    setIsRUC(true);
  };

  return (
    <div>
     

      {!isRUC && <BuscadorPorIdentificador traerIdCliente={traerIdCliente} />}

      {isRUC && <FormPrestadorMedico NRUC={rucPresradorMedico} />}
    </div>
  );
};

export default GestionPrestadoresMedicos;
