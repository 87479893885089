import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  FormHelperText,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Select,
} from "@mui/material";

import { consultaCatalogosSinPadre } from "../services/Catalogos/catalogosService";

import { MaskCelulares } from "app/modules/shared/mask/mascarasInputs";

import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { StyleInputs } from "../styles/themes";

import SkeletonForm from "app/modules/common/components/Skeleton/SkeletonForm";
import {
  validarCelular,
  validarFalsaData,
} from "app/modules/shared/validations/validaciones";

import {
  consultaRefPersonal,
  crearClienteRefePersonales,
} from "../services/clienteService";

import "../styles/main.css";
import { ERROR_VALIDACION_CONTACTO } from "app/modules/shared/constants/constantsMessanges";

const FormReferenciasCliente = ({ idCliente, setInfoClienteGrabada }) => {
  const [referenciasXCliente, setReferenciasXCliente] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [idReferenciaPersonalAux, setIdReferenciaPersonalAux] = useState(0);
  const [idReferenciaFamiliarAux, setIdReferenciaFamiliarAux] = useState(0);

  const [loading, setLoading] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const informacionRefPersonal = await consultaRefPersonal(idCliente);

        /* console.log({ informacionRefPersonal }); */

        if (informacionRefPersonal.state) {
          arrayModificadoReferencias(informacionRefPersonal.data);
        } else {
          setReferenciasXCliente([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  if (isLoading) {
    return <SkeletonForm />;
  }

  function arrayModificadoReferencias(referenciasCliente) {
    if (referenciasCliente.length === 0) {
      setReferenciasXCliente({
        idCliente1: idCliente,
        idReferenciaPersona1: 0,
        primerNombre1: "",
        segundoNombre1: "",
        primerApellido1: "",
        segundoApellido1: "",
        parentesco1: "",
        celular1: "",

        idCliente2: idCliente,
        idReferenciaPersona2: 0,
        primerNombre2: "",
        segundoNombre2: "",
        primerApellido2: "",
        segundoApellido2: "",
        parentesco2: "",
        celular2: "",
      });
      setIsLoading(false);
    } else if (referenciasCliente.length === 1) {
      setIdReferenciaPersonalAux(referenciasCliente[0].idReferenciaPersonal);
      console.log(referenciasCliente[0].idReferenciaPersonal);
      setReferenciasXCliente({
        idCliente1: referenciasCliente[0].idCliente || "",
        idReferenciaPersonal1: idReferenciaPersonalAux,
        primerNombre1: referenciasCliente[0].primerNombre || "",
        segundoNombre1: referenciasCliente[0].segundoNombre || "",
        primerApellido1: referenciasCliente[0].primerApellido || "",
        segundoApellido1: referenciasCliente[0].segundoApellido || "",
        parentesco1: referenciasCliente[0].parentesco || "",
        celular1: referenciasCliente[0].celular || "",

        idCliente2: idCliente,
        idReferenciaPersonal2: 0,
        primerNombre2: "",
        segundoNombre2: "",
        primerApellido2: "",
        segundoApellido2: "",
        parentesco2: "",
        celular2: "",
      });
      setIsLoading(false);
    } else {
      setIdReferenciaPersonalAux(referenciasCliente[0].idReferenciaPersonal);
      setIdReferenciaFamiliarAux(referenciasCliente[1].idReferenciaPersonal);
      setReferenciasXCliente({
        idCliente1: referenciasCliente[0].idCliente || "",
        idReferenciaPersonal1: idReferenciaPersonalAux,
        primerNombre1: referenciasCliente[0].primerNombre || "",
        segundoNombre1: referenciasCliente[0].segundoNombre || "",
        primerApellido1: referenciasCliente[0].primerApellido || "",
        segundoApellido1: referenciasCliente[0].segundoApellido || "",
        parentesco1: referenciasCliente[0].parentesco || "",
        celular1: referenciasCliente[0].celular || "",

        idCliente2: referenciasCliente[1].idCliente || "",
        idReferenciaPersonal2: idReferenciaFamiliarAux,
        primerNombre2: referenciasCliente[1].primerNombre || "",
        segundoNombre2: referenciasCliente[1].segundoNombre || "",
        primerApellido2: referenciasCliente[1].primerApellido || "",
        segundoApellido2: referenciasCliente[1].segundoApellido || "",
        parentesco2: referenciasCliente[1].parentesco || "",
        celular2: referenciasCliente[1].celular || "",
      });
      setIsLoading(false);
    }
  }

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={StyleInputs(componentSize.pcWidth, componentSize.phoneWidth)}
        //sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const configuracionDatosEnvio2 = async (valores) => {
    setLoading(true);

    const arrayReferenciasCliente = [
      {
        idCliente: Number(valores.idCliente1),
        /* idReferenciaPersonal: Number(valores.idReferenciaPersona1), */
        idReferenciaPersonal: Number(idReferenciaPersonalAux),
        primerNombre: valores?.primerNombre1?.toUpperCase() ?? "",
        segundoNombre: valores?.segundoNombre1?.toUpperCase() ?? "",
        primerApellido: valores?.primerApellido1?.toUpperCase() ?? "",
        segundoApellido: valores?.segundoApellido1?.toUpperCase() ?? "",
        parentesco: valores.parentesco1,
        celular: valores.celular1,
      },
      {
        idCliente: Number(valores.idCliente2),
        /* idReferenciaPersonal: Number(valores.idReferenciaPersona2), */
        idReferenciaPersonal: Number(idReferenciaFamiliarAux),
        primerNombre: valores?.primerNombre2?.toUpperCase() ?? "",
        segundoNombre: valores?.segundoNombre2?.toUpperCase() ?? "",
        primerApellido: valores?.primerApellido2?.toUpperCase() ?? "",
        segundoApellido: valores?.segundoApellido2?.toUpperCase() ?? "",
        parentesco: valores.parentesco2,
        celular: valores.celular2,
      },
    ];

    /* console.log(arrayReferenciasCliente); */

    try {
      const arrayPromesasReferencias = arrayReferenciasCliente?.map(
        (referencias) => {
          return crearClienteRefePersonales(referencias);
        }
      );

      const respuestaReferenciasCliente = await Promise.all(
        arrayPromesasReferencias
      );

      /* console.log(respuestaReferenciasCliente); */

      if (
        respuestaReferenciasCliente.every(
          (respuestaRef) => respuestaRef.state === true
        )
      ) {
        if (setInfoClienteGrabada) {
          setInfoClienteGrabada((args) => ({
            ...args,
            referencias: true,
          }));
        }
        setIdReferenciaPersonalAux(respuestaReferenciasCliente[0].data);
        setIdReferenciaFamiliarAux(respuestaReferenciasCliente[1].data);
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={referenciasXCliente}
      enableReinitialize={true}
      validate={async (values) => {
        const errors = {};

        /* errores referencia familiar */
        if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.primerNombre1)) {
          errors.primerNombre1 =
            "El nombre solo puede contener caracteres sin espacios";
        }
        if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.segundoNombre1)) {
          errors.segundoNombre1 =
            "El nombre solo puede contener caracteres sin espacios";
        }
        if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.primerApellido1)) {
          errors.primerApellido1 =
            "El apellido solo puede contener caracteres sin espacios";
        }
        if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.segundoApellido1)) {
          errors.segundoApellido1 =
            "El apellido solo puede contener caracteres sin espacios";
        }
        if (await validarFalsaData(values.celular1, "CEL")) {
          errors.celular1 = ERROR_VALIDACION_CONTACTO;
        }

        if (!/^[0-9]{0,11}$/.test(values.celular1)) {
          errors.celular1 = "Solo se aceptan números";
        }
        if (validarCelular(values.celular1)) {
          errors.celular1 = "Primer digito debería ser '09'";
        }

        /* errores referencia personal */
        if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.primerNombre2)) {
          errors.primerNombre2 =
            "El nombre solo puede contener caracteres sin espacios";
        }
        if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.segundoNombre2)) {
          errors.segundoNombre2 =
            "El nombre solo puede contener caracteres sin espacios";
        }
        if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.primerApellido2)) {
          errors.primerApellido2 =
            "El apellido solo puede contener caracteres sin espacios";
        }
        if (!/^[a-zA-ZÑñ" "]{0,16}$/.test(values.segundoApellido2)) {
          errors.segundoApellido2 =
            "El apellido solo puede contener caracteres sin espacios";
        }
        if (await validarFalsaData(values.celular2, "CEL")) {
          errors.celular2 = ERROR_VALIDACION_CONTACTO;
        }

        if (!/^[0-9]{0,11}$/.test(values.celular2)) {
          errors.celular2 = "Solo se aceptan números";
        }
        if (validarCelular(values.celular2)) {
          errors.celular2 = "Primer digito debería ser '09'";
        }

        return errors;
      }}
      validationSchema={Yup.object({
        primerNombre1: Yup.string()
          .max(15, "Debería tener máximo 15 caracteres")
          .min(3, "Debería tener al menos 3 caracteres")
          .required("Primer Nombre requerido"),
        segundoNombre1: Yup.string()
          .max(15, "Debería tener máximo 15 caracteres")
          .min(3, "Debería tener al menos 3 caracteres"),
        primerApellido1: Yup.string()
          .max(15, "Debería tener máximo 15 caracteres")
          .min(3, "Debería tener al menos 3 caracteres")
          .required("Primer Apellido requerido"),
        segundoApellido1: Yup.string()
          .max(15, "Debería tener máximo 15 caracteres")
          .min(3, "Debería tener al menos 3 caracteres"),
        parentesco1: Yup.string().required("Parentesco requerido"),
        celular1: Yup.string()
          .min(10, "Debería tener 10 números")
          .max(10, "Debería tener 10 números")
          .required("Celular requerido"),

        primerNombre2: Yup.string()
          .max(15, "Debería tener máximo 15 caracteres")
          .min(3, "Debería tener al menos 3 caracteres")
          .required("Primer Nombre requerido"),
        segundoNombre2: Yup.string()
          .max(15, "Debería tener máximo 15 caracteres")
          .min(3, "Debería tener al menos 3 caracteres"),
        primerApellido2: Yup.string()
          .max(15, "Debería tener máximo 15 caracteres")
          .min(3, "Debería tener al menos 3 caracteres")
          .required("Primer Apellido requerido"),
        segundoApellido2: Yup.string()
          .max(15, "Debería tener máximo 15 caracteres")
          .min(3, "Debería tener al menos 3 caracteres"),
        parentesco2: Yup.string().required("Parentesco requerido"),
        celular2: Yup.string()
          .min(10, "Debería tener 10 números")
          .max(10, "Debería tener 10 números")
          .required("Celular requerido"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        configuracionDatosEnvio2(values);
      }}
    >
      {({ values, handleChange, touched, errors, handleBlur }) => (
        <Form>
          {/* Referencia Personal */}
          <SCSAccordion tituloCabecera={`Referencia Familiar`}>
            <div className="cliente-informacion-seccion">
              <TextField
                id="primerApellido1"
                name="primerApellido1"
                type="text"
                label="Primer Apellido"
                sx={StyleInputs(18, 45)}
                value={values.primerApellido1}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={
                  touched.primerApellido1 && Boolean(errors.primerApellido1)
                }
                helperText={touched.primerApellido1 && errors.primerApellido1}
                onBlur={handleBlur}
              />

              <TextField
                id="segundoApellido1"
                name="segundoApellido1"
                type="text"
                label="Segundo Apellido"
                sx={StyleInputs(18, 45)}
                //sx={{ width: "18%" }}
                value={values.segundoApellido1}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={
                  touched.segundoApellido1 && Boolean(errors.segundoApellido1)
                }
                helperText={touched.segundoApellido1 && errors.segundoApellido1}
                onBlur={handleBlur}
              />

              <TextField
                id="primerNombre1"
                name="primerNombre1"
                type="text"
                label="Primer Nombre"
                sx={StyleInputs(18, 45)}
                value={values.primerNombre1}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={touched.primerNombre1 && Boolean(errors.primerNombre1)}
                helperText={touched.primerNombre1 && errors.primerNombre1}
                onBlur={handleBlur}
              />

              <TextField
                id="segundoNombre1"
                name="segundoNombre1"
                type="text"
                label="Segundo Nombre"
                sx={StyleInputs(18, 45)}
                value={values.segundoNombre1}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={touched.segundoNombre1 && Boolean(errors.segundoNombre1)}
                helperText={touched.segundoNombre1 && errors.segundoNombre1}
                onBlur={handleBlur}
              />

              <SelectItemsCatalogoSinPadre
                pais="ECU"
                especial="false"
                catalogos="PARENTESCO"
                grupo={1}
                name="parentesco1"
                labelName="Parentesco"
                componentSize={{ pcWidth: 18, phoneWidth: 65 }}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              />
            </div>

            <div className="cliente-informacion-seccion">
              <FormControl
                sx={StyleInputs(15, 45)}
                size="small"
                variant="outlined"
                error={touched.celular1 && Boolean(errors.celular1)}
                helperText={touched.celular1 && errors.celular1}
                onBlur={handleBlur}
              >
                <InputLabel htmlFor="celular1">Celular</InputLabel>
                <OutlinedInput
                  value={values.celular1}
                  onChange={handleChange}
                  name="celular1"
                  id="celular1"
                  inputComponent={MaskCelulares}
                  label="celular1"
                />
                <ErrorMessage
                  name="celular1"
                  component={() => (
                    <FormHelperText>{errors.celular1}</FormHelperText>
                  )}
                />
              </FormControl>
            </div>
          </SCSAccordion>

          {/* Referencia Personal */}
          <SCSAccordion tituloCabecera={`Referencia Personal`}>
            <div className="cliente-informacion-seccion">
              <TextField
                id="primerApellido2"
                name="primerApellido2"
                type="text"
                label="Primer Apellido"
                sx={StyleInputs(18, 45)}
                //sx={{ width: "18%" }}
                value={values.primerApellido2}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={
                  touched.primerApellido2 && Boolean(errors.primerApellido2)
                }
                helperText={touched.primerApellido2 && errors.primerApellido2}
                onBlur={handleBlur}
              />

              <TextField
                id="segundoApellido2"
                name="segundoApellido2"
                type="text"
                label="Segundo Apellido"
                sx={StyleInputs(18, 45)}
                //sx={{ width: "18%" }}
                value={values.segundoApellido2}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={
                  touched.segundoApellido2 && Boolean(errors.segundoApellido2)
                }
                helperText={touched.segundoApellido2 && errors.segundoApellido2}
                onBlur={handleBlur}
              />

              <TextField
                id="primerNombre2"
                name="primerNombre2"
                type="text"
                label="Primer Nombre"
                sx={StyleInputs(18, 45)}
                value={values.primerNombre2}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={touched.primerNombre2 && Boolean(errors.primerNombre2)}
                helperText={touched.primerNombre2 && errors.primerNombre2}
                onBlur={handleBlur}
              />

              <TextField
                id="segundoNombre2"
                name="segundoNombre2"
                type="text"
                label="Segundo Nombre"
                sx={StyleInputs(18, 45)}
                value={values.segundoNombre2}
                onChange={handleChange}
                size="small"
                inputProps={{
                  style: { textTransform: "uppercase" },
                }}
                error={touched.segundoNombre2 && Boolean(errors.segundoNombre2)}
                helperText={touched.segundoNombre2 && errors.segundoNombre2}
                onBlur={handleBlur}
              />

              <SelectItemsCatalogoSinPadre
                pais="ECU"
                especial="false"
                catalogos="PARENTESCO"
                grupo={1}
                name="parentesco2"
                labelName="Parentesco"
                componentSize={{ pcWidth: 18, phoneWidth: 65 }}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              />
            </div>

            <div className="cliente-informacion-seccion">
              <FormControl
                sx={StyleInputs(15, 45)}
                size="small"
                variant="outlined"
                error={touched.celular2 && Boolean(errors.celular2)}
                helperText={touched.celular2 && errors.celular2}
                onBlur={handleBlur}
              >
                <InputLabel htmlFor="celular2">Celular</InputLabel>
                <OutlinedInput
                  value={values.celular2}
                  onChange={handleChange}
                  name="celular2"
                  id="celular2"
                  inputComponent={MaskCelulares}
                  label="celular2"
                />
                <ErrorMessage
                  name="celular2"
                  component={() => (
                    <FormHelperText>{errors.celular2}</FormHelperText>
                  )}
                />
              </FormControl>
            </div>

            <div className="confirmar-estados-servicios">
              <SCSLoadingButton loading={loading} titulo="GUARDAR" />

              {datosEnviadosCorrectamente && <AcceptServiceresponse />}
            </div>
          </SCSAccordion>
        </Form>
      )}
    </Formik>
  );
};

export default FormReferenciasCliente;
