import React from "react";
import { Alert } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { green } from "@mui/material/colors";

const AcceptServiceresponse = () => {
  return (
    <>
      <CheckCircleOutlineIcon sx={{ color: green[500] }} fontSize="large" />
    </>
  );
};

export default AcceptServiceresponse;
