import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

import ValoresPagarTransaccional from "./ValoresPagarTransaccional/ValoresPagarTransaccional";

const TransaccionalValoresPagar = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <h2>Transaccional - Valores Por Pagar</h2>
      <ValoresPagarTransaccional />
    </div>
  );
};

export default TransaccionalValoresPagar;
