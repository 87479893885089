import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";

import TaskIcon from "@mui/icons-material/Task";
import downloadExcel from "app/utils/document/documentoDescargaExel";
import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";
import { consultarSolicitudXZona } from "../services/ReporteService";
import { formatoFecha } from "app/modules/shared/validations/validaciones";
import ModalReporteZona from "../containers/ModalReporteZona";

const BotonConsultaSolicitudReporteZona = ({ setIsReport }) => {
  const [reporteDiario, setReporteDiario] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  /*   const obtenerReporteSolicitudZona = async () => {
    setLoading(true);

    try {
      const respuestaSolicitudesZona = await consultarSolicitudXZona();

      if (respuestaSolicitudesZona.state) {
        //el aux sirve para
        const respuestaSolicitudesZonaAux = respuestaSolicitudesZona.data.map(
          (solicitudZona, index) => {
            return {
              ...solicitudZona,
              fechaSolicitud: formatoFecha(solicitudZona.fechaSolicitud),
              ID: index,
            };
          }
        );

        setReporteDiario(respuestaSolicitudesZonaAux);

        downloadExcel(
          respuestaSolicitudesZona.data,
          `REPSOLICITUDZONA_${fechasDocumentosSiniestro()}`
        );
        setLoading(false);
      } else {
        setReporteDiario([]);
        setLoading(false);
        alert("Error: No se ha podido gererar el reporte !!");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Error: No se ha podido gererar el reporte !!");
    }
  }; */

  const obtenerReporteSolicitudZona = () => {
    setOpen(true);
  };

  return (
    <div className="tipoSiniestro-tipos">
      <LoadingButton
        size="large"
        loading={loading}
        variant="contained"
        loadingPosition="end"
        endIcon={<TaskIcon />}
        onClick={obtenerReporteSolicitudZona}
      >
        Reporte Solicitud Por Zona
      </LoadingButton>

      <ModalReporteZona
        open={open}
        titulo="Reporte Solicitud Por Zona"
        mensaje="Reporte Solicitud Por Zona"
        setOpen={setOpen}
      />
    </div>
  );
};

export default BotonConsultaSolicitudReporteZona;
