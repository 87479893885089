
import React from 'react';
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUT_NAMES} from "../../../layouts/layouts";
import AnexarPdfContent from './AnexarPdf/AnexarPdfContent';


const CrediwebDocsPage = () => {
  const {setActiveLayout} = useJumboApp();

  React.useEffect(() => {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
      <div>
        <h2>Anexar Documentos de Solicitudes Credimax</h2>
        <AnexarPdfContent/>
      </div>
  );
};

export default CrediwebDocsPage;