import React, { useEffect, useState } from "react";

import { Skeleton } from "@mui/material";

import { formatoFecha } from "app/modules/shared/validations/validaciones";
import { consultarListaTransaccionalXCuenta } from "../services/transaccionalService";
import {
  SCSTableData,
  SCSTableDataCell,
  SCSTableDataRow,
} from "app/modules/common/components/TableData/SCSTableData";
import { SCSGenericButton } from "app/modules/common/components/Buttons/SCSButtons";
import VisualizarTablaAmortizacion from "./VisualizarTablaAmortizacion";
import { useResolutionScreen } from "../hooks/useResolutionScreen";

const ListaAutorizacionesCliente = ({ cabenceraTransaccional }) => {
  const [listaAutorizaciones, setListaAutorizaciones] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalAmortizacion, setModalAmortizacion] = useState(false);
  const [autorizacionPDF, setAutorizacionPDF] = useState("");

  const { alturaResolucion } = useResolutionScreen();

  useEffect(() => {
    const getData = async () => {
      try {
        const respuestaListaAutorizaciones =
          await consultarListaTransaccionalXCuenta(
            cabenceraTransaccional.idCuenta,
            10
          );

        /* console.log(respuestaListaAutorizaciones); */

        if (
          respuestaListaAutorizaciones.state &&
          respuestaListaAutorizaciones.data !== null
        ) {
          setListaAutorizaciones(respuestaListaAutorizaciones.data);
          setIsLoading(false);
        } else {
          setListaAutorizaciones([]);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getData();
  }, []);

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height="50vh"
        sx={{ borderRadius: "32px" }}
      />
    );
  }

  const handleAmortizacion = (autorizacion) => {
    setAutorizacionPDF(autorizacion);
    setModalAmortizacion(true);
  };

  const columnsData = [
    { id: 1, name: "N° Autorización" },
    { id: 2, name: "Local" },
    { id: 3, name: "Clase Consumo" },
    { id: 4, name: "Estado" },
    { id: 5, name: "Fecha Consumo" },
    { id: 6, name: "Valor Consumo" },
    { id: 7, name: "Plazo" },
    { id: 9, name: "Amortización" },
  ];

  return (
    <main style={{ marginTop: "2rem" }}>
      <SCSTableData
        columnsData={columnsData}
        heightTable={alturaResolucion - 75}
      >
        {listaAutorizaciones?.map((consumo, index) => (
          <SCSTableDataRow key={consumo.numeroAutorizacion}>
            <SCSTableDataCell align="center">
              {consumo.numeroAutorizacion}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">{consumo.local}</SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.claseConsumo}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {consumo.estadoAutorizacion}
            </SCSTableDataCell>

            <SCSTableDataCell align="center">
              {formatoFecha(consumo.fechaConsumo)}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">
              {`$ ${consumo.valorConsumo}`}
            </SCSTableDataCell>
            <SCSTableDataCell align="center">{consumo.plazo}</SCSTableDataCell>

            <SCSTableDataCell align="center">
              <>
                {consumo.amortizacion && (
                  <SCSGenericButton
                    onClick={() => handleAmortizacion(consumo.idAutorizacion)}
                    size={100}
                    sx={{ width: "100%" }}
                    titulo="VISUALIZAR"
                  />
                )}
              </>
            </SCSTableDataCell>
          </SCSTableDataRow>
        ))}
      </SCSTableData>

      <VisualizarTablaAmortizacion
        open={modalAmortizacion}
        setOpen={setModalAmortizacion}
        autorizacion={autorizacionPDF}
      />
    </main>
  );
};

export default ListaAutorizacionesCliente;
