
import React from 'react';
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUT_NAMES} from "../../../layouts/layouts";
import DevueltasContent from './GestionDevueltas/DevueltasContent';


const GestionDevueltasPage = () => {
  const {setActiveLayout} = useJumboApp();

  React.useEffect(() => {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
      <div>
        <h2>Gestion de Solicitudes Devueltas</h2>
        <DevueltasContent/>
      </div>
  );
};

export default GestionDevueltasPage;