import React, { useState } from "react";

import BuscadorColaboradorIdentificador from "../../components/BuscadorColaboradorIdentificador";
import TablaInformacionListaNegra from "../../containers/TablaInformacionListaNegra";
import IconoSemaforoListaNegra from "../../components/IconoSemaforoListaNegra";
import BotonGenericoSalir from "app/modules/shared/containers/BotonGenericoSalir";

const SolicitudListaNegraColaboradores = () => {
  const [identificacion, setIdentificacion] = useState({
    state: false,
    data: "",
  });
  return (
    <>
      <BotonGenericoSalir />
      <div className="listaNegra-container">
        <BuscadorColaboradorIdentificador
          setIdentificacion={setIdentificacion}
        />
      </div>
      {identificacion.state && (
        <TablaInformacionListaNegra identificacion={identificacion.data} />
      )}
    </>
  );
};

export default SolicitudListaNegraColaboradores;
