import React, { useRef, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { render } from "react-dom";
import SignatureCanvas from "react-signature-canvas";
import "./style.css";

import { registrarFirma } from "../../services/index";

import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import Div from "@jumbo/shared/Div";

import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../../../../redux/actions/Common";
import { Alert, Button, Stack } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

const validationSchema = yup.object({
  email: yup
    .string("Ingrese identificacion de cliente")
    .max(10, "Excede longitud máxima")
    .required("Cédula es requerida"),
});

const MasterFirma = ({ identificacion, setInfoRegistrada ,inhabilitar}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const sigRef = useRef();
  const [signature, setSignature] = useState(null);
  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  };
  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  };
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [alertaGuardar, setAlertaGuardar] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  // const downloadSignature = () => {
  //   const dlink = document.createElement("a")
  //   dlink.setAttribute("href", signature)
  //   dlink.setAttribute("download", "signature.png")
  //   dlink.click()
  // }
  const saveSignature = async () => {
    //debugger;
    var response = await registrarFirma(
      identificacion,
      "CI",
      signature.substring(22)
    );

    if (response.state) {
      setDatosEnviadosCorrectamente(true);
      if (setInfoRegistrada) {
        setInfoRegistrada((args) => ({
          ...args,
          firma: true,
        }));
      }

      setTimeout(() => {
        setDatosEnviadosCorrectamente(false);
      }, 3000);
      //setMensaje('Firma registrado con éxito');
      dispatch(fetchSuccess("Ingreso exitoso"));
    } else {
      setMensajeError(response.message);
      setAlertaGuardar(true);
      setTimeout(() => {
        setAlertaGuardar(false);
      }, 3000);
      //setMensaje('Error: ' + response.message)
    }

    //setOpenPopUpSuccess(true)

    //console.log(signature);
    //alert(signature);
  };

  useEffect(() => {
    //debugger;
    //console.log(signature);
  }, [signature]);

  return (
    <div>
      <Formik
        validateOnChange={true}
        initialValues={{
          email: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          //setSubmitting(true);
          //debugger;
          //setEmail(data.email);
          //setSend(true);
          //  setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form style={{ textAlign: "left" }} noValidate autoComplete="off" >
            <SignatureCanvas
              penColor="BLACK"
              canvasProps={{ className: "signature" }}
              ref={sigRef}
              onEnd={handleSignatureEnd}
              
            />

            {/* <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="short"
                                    sx={{mb: 3}}
                                    loading={isSubmitting }
                                >Guardar</LoadingButton>
                                <LoadingButton
                                 color="secondary"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="short"
                                    //sx={{mb: 3}}
                                   //s onClick={() => handleCancel()}
                                >Limpiar</LoadingButton> */}
          </Form>
        )}
      </Formik>
      <Stack justifyContent="center" direction="row" spacing={2}>
        <Button variant="contained" onClick={saveSignature} disabled={inhabilitar}>
          <SaveIcon />
          GUARDAR
        </Button>
        <Button variant="outlined" color="error" onClick={clearSignature} disabled={inhabilitar}>
          <CleaningServicesIcon />
          LIMPIAR
        </Button>
      </Stack>
      {datosEnviadosCorrectamente && (
        <Alert severity="success" variant="filled">
          Firma Digital Grabada con éxito.
        </Alert>
      )}
      {alertaGuardar && (
        <Alert severity="error" variant="filled">
          Error al grabar firma digital - {mensajeError}
        </Alert>
      )}
    </div>
  );
};

export default MasterFirma;
