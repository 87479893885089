import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { useHistory } from "react-router-dom";

import { formatoFecha } from "app/modules/shared/validations/validaciones";

import { consultarListaCobranzaXUsuario } from "../../services/cobranzaServices";
import BotonGenericoSalir from "app/modules/shared/containers/BotonGenericoSalir";

const GestionCobranza = () => {
  const [gestionSolicitud, setGestionSolicitud] = useState({
    state: false,
    data: {},
  });
  const [clienteProductos, setClienteProductos] = useState([]);

  const { idUsuario } = JSON.parse(sessionStorage.getItem("user"));
  const navigate = useHistory();

  useEffect(() => {
    const getSiniestros = async () => {
      try {
        const respuestaListaCobranzaXUsuario =
          await consultarListaCobranzaXUsuario(idUsuario);

        console.log(respuestaListaCobranzaXUsuario);

        if (
          respuestaListaCobranzaXUsuario.state &&
          respuestaListaCobranzaXUsuario.data
        ) {
          const resListaAux = respuestaListaCobranzaXUsuario.data.map(
            (cobro, index) => ({
              ...cobro,
              id: index,
            })
          );

          /*  console.log(resListaAux); */
          setClienteProductos(resListaAux);

          /*  setClienteInfo(cliente); */
        } else {
          setClienteProductos([]);
        }
      } catch (error) {
        console.log(error);
        alert(`${error.response.data.message}`);
      }
    };
    getSiniestros();
  }, []);

  const procesarSolicitudCobranza = (solicitud) => {
    setGestionSolicitud((state) => ({
      ...state,
      data: solicitud,
      state: true,
    }));

    const path = "/cobranza/gestionLlamada";

    navigate.push(path, {
      state: { informacionCobranza: solicitud },
    });
  };

  const renderGestionarButton = (params) => {
    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => procesarSolicitudCobranza(params.row)}
      >
        Gestionar
      </Button>
    );
  };

  const columns = [
    {
      field: "ID",
      hide: true,
      headerName: "ID",
      width: 80,
      headerClassName: "header-grid-solicitud",
    },
    {
      field: "nombreCliente",
      headerName: "Nombre Cliente",
      headerClassName: "header-grid-solicitud",
      width: 290,
      editable: false,
    },
    {
      field: "fechaCorte",
      headerName: "Fecha Corte",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
      valueFormatter: (params) => {
        return formatoFecha(params.value);
      },
    },
    {
      field: "estadosVencidos",
      headerName: "Estados Vencidos",
      headerClassName: "header-grid-solicitud",
      width: 150,
      editable: false,
    },
    {
      field: "valorVencido",
      headerName: "Valor Vencido",
      headerClassName: "header-grid-solicitud",
      width: 150,
      editable: false,
      valueFormatter: (params) => {
        return `${params.value} $`;
      },
    },
    {
      field: "gestionar",
      headerName: "Gestionar",
      headerClassName: "header-grid-solicitud",
      width: 100,
      renderCell: renderGestionarButton,
      disableClickEventBubbling: false,
    },
  ];

  const rows = clienteProductos;

  return (
    <>
      <BotonGenericoSalir />

      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          getRowId={(row) => row.id}
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>

      {/* <DetalleGestionCobranza informacionCobranza={gestionSolicitud.data} /> */}
    </>
  );
};

export default GestionCobranza;
