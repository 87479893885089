import React, { useState } from "react";

import BuscadorPorIdentificador from "app/modules/app-cliente/containers/BuscadorPorIdentificador";
import ListaProductosCliente from "../../containers/ListaProductosCliente";
import DetalleCrearCitaMedica from "../../containers/DetalleCrearCitaMedica";

import { consultaCliente } from "../../../app-cliente/services/clienteService";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";

const CitasCrearCita = () => {
  const [cliente, setCliente] = useState(null);
  const [idCliente, setIdCliente] = useState("");
  const [identificacionCliente, setIdentificacionCliente] = useState("");
  const [mostrarDetalle, setMostrarDetalle] = useState(true);
  const [idProductoAux, setIdProductoAux] = useState(null);

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  const buscarExistenciaCliente = async (identificador) => {
    try {
      const respuestaCliente = await consultaCliente(identificador);

      if (respuestaCliente.state) {
        setIdCliente(respuestaCliente.data.idCliente);
        setIdentificacionCliente(respuestaCliente.data.identificacion);
        setCliente(respuestaCliente.data);
        setMostrarDetalle(false);
      } else {
        setCliente(nuevoCliente);
        setIdCliente(nuevoIdCliente);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdCliente = (id) => () => {
    /*   console.log(id); */
    buscarExistenciaCliente(id);
  };

  if (mostrarDetalle) {
    return <BuscadorPorIdentificador traerIdCliente={traerIdCliente} />;
  }

  return (
    <div>
      {/* <DetalleSolucionQueja idCliente={idCliente} idQueja={idQuejaAux} /> */}

      <BotonGerericoSalir />

      {idProductoAux ? (
        <DetalleCrearCitaMedica
          idCliente={idCliente}
          idProductoAux={idProductoAux}
          identificacionCliente={identificacionCliente}
        />
      ) : (
        <ListaProductosCliente
          cliente={cliente}
          idCliente={idCliente}
          identificacionCliente={identificacionCliente}
          setIdProductoAux={setIdProductoAux}
          /*  setIdQuejaStorage={setIdQuejaStorage} */
        />
      )}
    </div>
  );
};

export default CitasCrearCita;
