export const diccionarioExtenciones = (extension) => {
  const extensionesPosibles = {
    jpeg: ".jpg",
    jpg: ".jpg",
    png: ".png",
    pdf: ".pdf",
    csv: ".csv",
  };

  if (!extensionesPosibles[extension]) {
    return ".pdf";
  }

  return extensionesPosibles[extension];
};

export const diccionarioParentesco = (codigoPrentesco) => {
  const codigoParentesco = {
    ABU: "ABUELO(A)",
    AMI: "AMIGO",
    CDO: "CONCUÑADO",
    CN: "CUÑADO(A)",
    COY: "CÓNYUGE",
    HER: "HERMANO(A)",
    HIJ: "HIJO(A)",
    MAD: "MADRE",
    MTR: "MADRASTRA",
    NIE: "NIETO/A",
    NUE: "NUERA",
    OT: "OTRO",
    PAD: "PADRE",
    PRI: "PRIMO(A)",
    PTR: "PADRASTRO",
    SOB: "SOBRINO(A)",
    SUE: "SUEGRO(A)",
    TIO: "TÍO(A)",
    TIT: "TITULAR",
    YER: "YERNO",
    EMP: "EMPRESA",
  };

  return codigoParentesco[codigoPrentesco];
};

export const detalleTipoRequerimiento = (idTipoRequerimiento) => {
  const detalleRequerimiento = {
    1: "CERTIFICADO",
    2: "CANCELACION SEGURO",
    3: "DEVOLUCION PRODUCTOS",
    4: "PRECANCELACION",
    17: "CAMBIO ESTADO",
  };
  return detalleRequerimiento[idTipoRequerimiento];
};

export const idSubTipoRequerimiento = (idSubTipoRequerimiento) => {
  const detalleRequerimiento = {
    5: "ESTADO DEUDA",
    6: "SIN DEUDA",
    7: "ESPECIAL",
    8: "PARCIAL",
    9: "TOTAL",
    10: "PARCIAL",
    11: "TOTAL",
  };

  return detalleRequerimiento[idSubTipoRequerimiento];
};

export const idEstados = (idEstados) => {
  const detalleRequerimiento = {
    ESTREQSOL: "SOLICITADO",
    ESTREQPRO: "EN PROCESO",
    ESTREQRES: "RESUELTO",
    ESTREQPTE: "PENDIENTE SIN DOCUMENTOS",
    ESTREQDES: "DESESTIMADO",
  };

  return detalleRequerimiento[idEstados];
};

export const documentoTipoRequerimiento = (idTipoRequerimiento) => {
  const detalleTipoRequerimiento = {
    1: "CRT_CREDITO_",
    2: "CRT_CNLSEG_",
    3: "CRT_DEVPRO_",
    4: "CRT_PRECNC_",
  };

  return detalleTipoRequerimiento[idTipoRequerimiento];
};

export const diccionarioTipoDireccion = (tipoDireccion) => {
  const tiposDireccion = {
    DIRDOM: "DOMICILIO",
    DIRLAB: "LABORAL",
    DIROTR: "OTROS",
    DIRGES: "GESTIÓN ",
  };

  return tiposDireccion[tipoDireccion];
};

export const diccionarioGrupo = (IdGrupo) => {
  const respuesta = {
    1: "CORPORACION FAVORITA C.A.",
    2: "GRUPO REY",
    3: "TVENTAS",
    4: "SERVIMAX SERVICIOS GLOGALES",
  };

  return respuesta[IdGrupo];
};

export const diccionarioCodigoGrupo = (idGrupo) => {
  const codigoGrupo = {
    1: "CORPFAV",
    2: "GRPREY",
    3: "TVTAS",
    4: "SERVIMAX",
  };

  return codigoGrupo[idGrupo];
};

export const TIPOS_NEGOCIACION = {
  PRECANCELACION_TOTAL: "PRETOT",
  PRECANCELACION_CONSUMO: "PREPAR",
  DEVOLUCION_TOTAL: "DEVTOT",
  DEVOLUCION_PARCIAL: "DEVPAR",
};
