import React, { useState } from "react";
import FormGestionPermisos from "../components/FormGestionPermisos";
import ListaPermisosUsuario from "./ListaPermisosUsuario";

const DetalleGestionPermisos = ({ data }) => {
  const [nuevoUsuario, setNuevoUsuario] = useState(false);

  return (
    <div className="gestionCobranza-permisos-container">
      <FormGestionPermisos
        usuario={data}
        setNuevoUsuario={setNuevoUsuario}
        nuevoUsuario={nuevoUsuario}
      />
      <ListaPermisosUsuario usuario={data} nuevoUsuario={nuevoUsuario} />
    </div>
  );
};

export default DetalleGestionPermisos;
