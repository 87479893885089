
import axios from "axios";
import http from "../../../comunication/http-common";

function generate_uuidv4() {
  var dt = new Date().getTime();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var rnd = Math.random() * 16; //random number in range 0 to 16
    rnd = (dt + rnd) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? rnd : (rnd & 0x3) | 0x8).toString(16);
  });
}

export const onLoginCli = async (cedula, password) => {

  const result = await axios.get("https://api.ipify.org");
  let ipAdress = result.data;

  const body = {
    Key: cedula,
    Password: password,
    Sistema: process.env.REACT_APP_SISTEMA,
    Estacion: ipAdress,
    Tienda :localStorage.getItem("idGrupoStorage")
  };

  const url =`${process.env.REACT_APP_API_AUTH}identity/sign-incli`;
  const coreResponse = await axios.post(url, body);

  return coreResponse.data;

};
export const SignUpCli = async (cedula,celular, dactilar) => {

  
  const result = await axios.get("https://api.ipify.org");
  let ipAdress = result.data;

  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    let user = process.env.REACT_APP_USUARIO_REGISTRO;
    let data = {
        Key: cedula,
      Imei: dactilar,
      Sistema: process.env.REACT_APP_SISTEMA,
      Telefono1: celular,
      Nombres:localStorage.getItem('nm'),
      Apellidos:localStorage.getItem('ap'),
      Email: localStorage.getItem('email'),
      Oficina: parseInt(localStorage.getItem("idGrupoStorage")),
      Token: `web_${localStorage.getItem('origen')}`,
      Rol: user.replace("{0}",   localStorage.getItem('origen')),
      Estacion : ipAdress

    };

    const url = `${process.env.REACT_APP_API_AUTH}identity/SignUpCli`;
    http
    .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
    });

};

export const SignUpCliExistente = async (cedula,celular, dactilar) => {

  
  const result = await axios.get("https://api.ipify.org");
  let ipAdress = result.data;

  //let ipAdress = generate_uuidv4();

  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    let user = process.env.REACT_APP_USUARIO_CLIENTE;
    let data = {
        Key: cedula,
      Imei: dactilar,
      Sistema: process.env.REACT_APP_SISTEMA,
      Telefono1: celular,
      Nombres:localStorage.getItem('nm'),
      Apellidos:localStorage.getItem('ap'),
      Email: localStorage.getItem('email'),
      Oficina: parseInt(localStorage.getItem("idGrupoStorage")),
      Token: `web_${localStorage.getItem('origen')}`,
      Rol: user.replace("{0}",   localStorage.getItem('origen')),
      Estacion : ipAdress

    };

    const url = `${process.env.REACT_APP_API_AUTH}identity/SignUpCli`;
    http
    .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
    });

};



export const onLogout = async (userCode) => {

  

  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    let data = {
      Key: userCode,
      Sistema: process.env.REACT_APP_SISTEMA,
    };

    const url = `${process.env.REACT_APP_API_AUTH}identity/signout`;
    http
    .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
    });


};

export const onValidarOtp = async (key,password) => {
  
 
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    debugger;
    let data = {
      Key: key,
    Sistema: process.env.REACT_APP_SISTEMA,
    Password: password,
    NewPassword: "",
    };

    const url = `${process.env.REACT_APP_API_AUTH}identity/validarotpcli`;
    http
    .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
    });


};
export const onActualizarOtp = async (email, cedula) => {
 

    return new Promise((resolve, reject) => {
      const headers = {
        Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
      };
      //debugger;
      let data = {
          Key: cedula,
          Sistema: process.env.REACT_APP_SISTEMA,
          Email: email
        };
      const url = `${process.env.REACT_APP_API_AUTH}identity/actualizarotpcli`;
      http
      .post(url, data, { headers })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });

};
export const onConsultarCI = async (cedula) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CLIENTE}cliente/ObtenerInfoDemograficaXIdentificacion?identificacion=${cedula}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

};

export const onEnviarOtpSms = async (nombre,clave,celular, cedula) => {
 
  
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    let data = {
      CodigoComunicacion: "GESOTP",
      EspacioComunicacion: "GENERAL",
      TipoComunicacion: "SMS",
      Variables: `${nombre}|${clave}`,
      Destino: `${celular}`,
      IdTransaccion: `${cedula}`
    };
    const url = `${process.env.REACT_APP_API_NOTIFICAR}EnvioNotificacion/Notificar`;
    http
    .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

};

export const obtenerRegistroCliente = async (identificacion, idGrupo) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_AUTH}identity/ObtenerRegistroCliente?identificacion=${identificacion}&idGrupo=${idGrupo}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error.response.data);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const obtenerDatosPreevaluacion = async (identificacion, usuario) => {
  return new Promise((resolve, reject) => {
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ObtenerDatosPreevaluacion?identificacion=${identificacion}&usuario=${usuario}`;
    http
      .get(url,{headers})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        /* console.log(error.response); */
        reject(error);

        /*  return { code: error.code, message: error.message }; */
      });
  });
};

export const grabarRegistroCLiente = async (data) => {
  try{
    const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
    const url = `${process.env.REACT_APP_API_AUTH}identity/GrabarRegistroCliente`;
    let respuesta = await http.post(url, data,{headers});
    return respuesta.data;
  }
  catch(error)
  {
    return(error);
  }
};