import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Zoom
} from "@mui/material";
// import { ThemeProvider } from '@mui/material/styles';
import FormRol from "../RolesContainers/rolesform"

class PopUp extends React.Component {
  render() {
    const {
      flagEdit,
      open,
      onClose,
      handlePopUpSubmit,
      showProgressBar,
      title,
      size,
      rolCode,
    } = this.props;

    return (
      // <ThemeProvider>
        <Dialog onClose={onClose} open={open} maxWidth={size} fullScreen={false}>
          <DialogTitle color={'primary'}>{title}</DialogTitle>
          <DialogContent>
          <FormRol
            onSubmit={handlePopUpSubmit}
            flagEdit={flagEdit}
            handleClose={onClose}
            rolCode={rolCode}
          />      
          </DialogContent>
        </Dialog>
      // </ThemeProvider>
    );
  }
}

export default PopUp;
