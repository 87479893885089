import React, { useEffect, useState } from "react";

import {
  Button,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";

import { formatoFechaEspacio } from "../../shared/validations/validaciones";

import { consultarBeneficiariosCliente } from "../services/citasMedicasService";
import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/shared/mask/stylesTables";

const TablaBeneficiariosCitasMedicas = ({
  identificacionCliente,
  setIdPacienteAux,
  setInformacionBeneficiario,
  beneficiariosCliente,
  setAddBeneficiario,
}) => {
  /* const [beneficiariosCliente, setBeneficiariosCliente] = useState([]); */

  /* useEffect(() => {
    const getData = async () => {
      try {
        const respuestaBeneficiarios = await consultarBeneficiariosCliente(
          identificacionCliente
        );

        if (
          respuestaBeneficiarios.state &&
          respuestaBeneficiarios.data.beneficiarios
        ) {
          setBeneficiariosCliente(respuestaBeneficiarios.data.beneficiarios);
          console.log(respuestaBeneficiarios);
        } else {
          setBeneficiariosCliente([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []); */

  useEffect(() => {}, [beneficiariosCliente]);

  const escogerPacientetBeneficiario = (beneficiario) => {
    /*  console.log(beneficiario.idBeneficiario); */
    setIdPacienteAux(beneficiario.idBeneficiario);
    setInformacionBeneficiario(beneficiario);
    setAddBeneficiario(true);
  };

  const diccionarioIdentificacion = (codigoIdentificacion) => {
    const cogidoIdentificacion = {
      CED: "CEDULA",
      PAS: "PASAPORTE",
      RUC: "RUC",
    };

    return cogidoIdentificacion[codigoIdentificacion];
  };

  const diccionarioParentesco = (codigoPrentesco) => {
    const codigoParentesco = {
      ABU: "ABUELO(A)",
      AMI: "AMIGO",
      CDO: "CONCUÑADO",
      CN: "CUÑADO(A)",
      COY: "CÓNYUGE",
      HER: "HERMANO(A)",
      HIJ: "HIJO(A)",
      MAD: "MADRE",
      MTR: "MADRASTRA",
      NIE: "NIETO/A",
      NUE: "NUERA",
      OT: "OTRO",
      PAD: "PADRE",
      PRI: "PRIMO(A)",
      PTR: "PADRASTRO",
      SOB: "SOBRINO(A)",
      SUE: "SUEGRO(A)",
      TIO: "TÍO(A)",
      TIT: "TITULAR",
      YER: "YERNO",
    };

    return codigoParentesco[codigoPrentesco];
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Tipo ID</StyledTableCell>
              <StyledTableCell>Identificación</StyledTableCell>
              <StyledTableCell>Nombre</StyledTableCell>
              <StyledTableCell>Parentesco</StyledTableCell>
              <StyledTableCell>Fecha Nacimiento</StyledTableCell>
              <StyledTableCell>Generar</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {beneficiariosCliente.map((beneficiario, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {diccionarioIdentificacion(beneficiario.idTipoDocumento)}
                </StyledTableCell>
                <StyledTableCell>{beneficiario.identificacion}</StyledTableCell>
                <StyledTableCell>{`${beneficiario.primerNombre} ${beneficiario.primerApellido} `}</StyledTableCell>
                <StyledTableCell>
                  {diccionarioParentesco(beneficiario.parentesco)}
                </StyledTableCell>
                <StyledTableCell>
                  {formatoFechaEspacio(beneficiario.fechaNacimiento)}
                </StyledTableCell>

                <StyledTableCell>
                  <Button
                    variant="contained"
                    onClick={() => escogerPacientetBeneficiario(beneficiario)}
                    endIcon={<SendIcon />}
                  >
                    Agendar
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TablaBeneficiariosCitasMedicas;
