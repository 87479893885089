import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { consultarClientesPorUsuario } from "app/modules/app-comunicaciones/services/comunicacionesService";
import DetalleCoversacionesPorUsuario from "./DetalleCoversacionesPorUsuario";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import ChatSideContext from "../context/ChatSideContext";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import SkeletonConversaciones from "../components/SkeletonConversaciones";

const DetalleSeccionConversacionesCall = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { informacionChatSide, setInformacionChatSide } =
    useContext(ChatSideContext);

  const [isLoading, setIsLoading] = useState(true);
  const [updateHubConversacion, setUpdateHubConversacion] = useState(false);
  const [updatedChat, setUpdatedChat] = useState(false);

  const [conversaccion, setConversaccion] = useState([]);
  const latestConversaccion = useRef(null);

  latestConversaccion.current = conversaccion;

  useEffect(() => {
    const getClientesPorUsuarios = async () => {
      try {
        const respuestaClientesXUsuario = await consultarClientesPorUsuario(
          authUser.idUsuario,
          !informacionChatSide.isCallCenter
        );

        /* console.log(respuestaClientesXUsuario); */

        if (respuestaClientesXUsuario.state) {
          const listaConversaciones = respuestaClientesXUsuario.data.map(
            (conversacion, index) => ({
              ...conversacion,
              isSelected: false,
            })
          );

          setConversaccion(listaConversaciones);
          setUpdateHubConversacion(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getClientesPorUsuarios();
  }, [updatedChat, updateHubConversacion, informacionChatSide.isCallCenter]);

  useEffect(() => {
    if (informacionChatSide?.connectionHub) {
      informacionChatSide?.connectionHub.on("NuevaConversacion", (message) => {
        const updatedChat = [...latestConversaccion.current];
        updatedChat.push(message);

        setConversaccion(updatedChat);
      });
    }
  }, [informacionChatSide?.connectionHub]);

  useEffect(() => {
    if (informacionChatSide?.connectionHub) {
      informacionChatSide?.connectionHub.on(
        "ActualizarConversacion",
        (message) => {
          setUpdateHubConversacion(message);
        }
      );
    }
  }, [informacionChatSide?.connectionHub]);

  if (isLoading) {
    return <SkeletonConversaciones />;
  }

  const handleUpdateChat = () => {
    console.log("updating conversaciones");

    setUpdatedChat(!updatedChat);
  };

  return (
    <div style={{ margin: "0.3rem" }}>
      <DetalleCoversacionesPorUsuario conversaccion={conversaccion} />

      <SCSLoadingButton
        titulo="Actualizar"
        onClick={handleUpdateChat}
        size={50}
      />
    </div>
  );
};

export default DetalleSeccionConversacionesCall;
