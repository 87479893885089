import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import EstadoFinancieroCliente from "./EstadoFinancieroCliente/EstadoFinancieroCliente";

const ClienteEstadoFinanciero = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <>
      <h2 className="titulosPrincipales-container">Cliente - Información Financiera</h2>
      <EstadoFinancieroCliente />
    </>
  );
};

export default ClienteEstadoFinanciero;
