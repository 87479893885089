import React, { useContext, useEffect } from "react";
import DetalleSeccionConversaciones from "./DetalleSeccionConversaciones";
import DetalleSeccionMensajeria from "./DetalleSeccionMensajeria";
import ChatSideContext from "../context/ChatSideContext";

const DetalleChatSideContainer = () => {
  const { informacionChatSide, setInformacionChatSide } =
    useContext(ChatSideContext);

  useEffect(() => {
    if (informacionChatSide?.connectionHub) {
      informacionChatSide?.connectionHub
        .start()
        .then((result) => {
          console.log("Connected!");
        })
        .catch((e) => console.log("connectionHub failed: ", e));
    }
  }, [informacionChatSide?.connectionHub]);

  return (
    <>
      {informacionChatSide.changeSection ? (
        <DetalleSeccionMensajeria />
      ) : (
        <DetalleSeccionConversaciones />
      )}
    </>
  );
};

export default DetalleChatSideContainer;
