import http from "../../comunication/http-common";
//const localIpAddress = require("local-ip-address")
import axios from "axios";

function generate_uuidv4() {
  var dt = new Date().getTime();
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var rnd = Math.random() * 16; //random number in range 0 to 16
    rnd = (dt + rnd) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? rnd : (rnd & 0x3) | 0x8).toString(16);
  });
}

export const onLogin = async (email, password) => {
  //let ipAdress = localIpAddress();
  //const result = await http.get('https://geolocation-db.com/json/')
  const result = await http.get("https://api.ipify.org");
  let ipAdress = result.data;

  debugger;
  //let ipAdress = generate_uuidv4();

  const body = {
    Key: email,
    Password: password,
    Email: "email",
    Sistema: process.env.REACT_APP_SISTEMA,
    Estacion: ipAdress,
  };

  const coreResponse = await http.post("Identity/sign-in", body);
  if (coreResponse.state) {
    http.defaults.headers.common["Authorization"] =
      "Bearer " + coreResponse.data.token.access_token;
  }
  return coreResponse.data;
};

export const onConnect = async (rol, user) => {
  const result = await http.get("https://api.ipify.org");
  let ipAdress = result.data;

  debugger;
  //let ipAdress = generate_uuidv4();

  const body = {
    Key: user.key,
    Password: "",
    Email: user.email,
    Sistema: process.env.REACT_APP_SISTEMA,
    Estacion: ipAdress,
    UsuarioRol: [
      {
        idRol: rol,
      },
    ],
  };
  const coreResponse = await http.post("Identity/connect", body);
  return coreResponse.data;
};

export const onChangePassword = async (payload) => {
  let body = {
    Key: payload.userCode,
    Sistema: process.env.REACT_APP_SISTEMA,
    Password: payload.password,
    NewPassword: payload.newPassword,
  };
  //const jwtToken = sessionStorage.getItem('jwtToken');
  //http.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken;
  let coreResponse = await http.post("Identity/changepassword", body);
  return coreResponse.data;
};

export const onLogout = async (userCode) => {
  let body = {
    Key: userCode,
    Sistema: process.env.REACT_APP_SISTEMA,
  };
  const jwtToken = sessionStorage.getItem("jwtToken");
  http.defaults.headers.common["Authorization"] = "Bearer " + jwtToken;
  let coreResponse = await http.post("Identity/signout", body);
  return coreResponse.data;
};

export const onForgotPassword = async (userCode) => {
  let body = {
    Key: userCode,
    Sistema: process.env.REACT_APP_SISTEMA,
  };
  let coreResponse = await http.post("Identity/forgetpassword", body);
  return coreResponse.data;
};

//******************************************************Usuarios*********************************************************
export const allUsers = async () => {
  const headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
  };
  const url =
    process.env.REACT_APP_API_AUTH +
    "Identity/obtenertodos?sistema=" +
    process.env.REACT_APP_SISTEMA;

  let coreResponse = await axios.get(url, { headers });
  return coreResponse.data;
};
export const addUser = async (user) => {
  debugger;
  const headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
  };
  const body = user;
  const url = process.env.REACT_APP_API_AUTH + "Identity/signup";

  let coreResponse = await axios.post(url, body, { headers });
  //  let coreResponse = await http.post(url, body);
  return coreResponse.data;
};

//////////////////////////////////////////////////////ROL/////////////////////////////////////////
export const addRol = async (entity) => {
  const headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
  };
  let body = entity;
  const url = process.env.REACT_APP_API_AUTH + "Rol/grabar";

  let coreResponse = await axios.post(url, body, { headers });
  return coreResponse.data;
};

export const updateRol = async (entity) => {
  const headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
  };
  let body = entity;
  let coreResponse = await axios.post("Rol/actualizar", body, { headers });
  return coreResponse.data;
};

export async function getRol(code, system) {
  const headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
  };
  let body = {};
  body.codigo = code;
  body.sistema = system;

  const url =
    process.env.REACT_APP_API_AUTH +
    "Rol/obtener?codigo=" +
    code +
    "&sistema=" +
    system;

  let coreResponse = await axios.get(url, { headers });
  return coreResponse.data;
}

export async function findRol(code, system) {
  const headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
  };
  let body = {};
  body.codigo = code;
  body.sistema = system;
  const url =
    process.env.REACT_APP_API_AUTH +
    "Rol/buscar?codigo=" +
    code +
    "&sistema=" +
    system;

  let coreResponse = await axios.get(url, { headers });
  return coreResponse.data;
}

export async function getAllRol(system) {
  const headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
  };
  const url = process.env.REACT_APP_API_AUTH;
  let coreResponse = await axios.get(
    url + "Rol/obtenertodos?sistema=" + system,
    { headers }
  );
  return coreResponse.data;
}

export async function getAllOptions() {
  const headers = {
    Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
  };
  const url = process.env.REACT_APP_API_AUTH;
  let coreResponse = await axios.get(url + "Rol/obteneropciones", { headers });
  return coreResponse.data;
}
