import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";

import TaskIcon from "@mui/icons-material/Task";
import downloadExcel from "app/utils/document/documentoDescargaExel";
import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";
import { consultarSolicitudXZona } from "../services/ReporteService";
import { formatoFecha } from "app/modules/shared/validations/validaciones";
import ModalReporteConsultaBloqueoColaborador from "../containers/ModalReporteConsultaBloqueoColaborador";

const BotonConsultaBloqueoColaborador = ({ setIsReport }) => {
  const [reporteDiario, setReporteDiario] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const obtenerReporteConsultaBloqueoColaborador = () => {
    setOpen(true);
  };

  return (
    <div className="tipoSiniestro-tipos">
      <LoadingButton
        size="large"
        loading={loading}
        variant="contained"
        loadingPosition="end"
        endIcon={<TaskIcon />}
        onClick={obtenerReporteConsultaBloqueoColaborador}
      >
        Rep Bloqueo Colaborador 
      </LoadingButton>

      <ModalReporteConsultaBloqueoColaborador
        open={open}
        titulo="Reporte Consulta Bloqueo Colaborador"
        mensaje="Reporte Consulta Bloqueo Colaborador"
        setOpen={setOpen}
      />
    </div>
  );
};

export default BotonConsultaBloqueoColaborador;
