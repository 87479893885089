import { obtenerDocumentoLegal } from "app/modules/app-cliente/services/clienteService";
import { useEffect, useState } from "react";


export const useGetPoliticDocument = (documentType) => {
  const [isLoading, setIsLoading] = useState(true);
  const [documentDetail, setDocumentDetail] = useState({});

  useEffect(() => {
    const getLegalDocument = async () => {
      try {
        const responseLegalDocument = await obtenerDocumentoLegal(documentType);

        console.log(responseLegalDocument);
        console.log(documentType);

        if (responseLegalDocument.state) {
          setIsLoading(false);
          setDocumentDetail(responseLegalDocument.data);
        } else {
          setIsLoading(false);
          console.log(responseLegalDocument.message);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    getLegalDocument();
  }, [documentType]);

  return { isLoading, documentDetail };
};
