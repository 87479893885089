import { useState, useEffect } from 'react';
import { allUsers } from '../index';

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoadingUsers, setLoadingUsers] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const coreResponse = await allUsers();

        if (coreResponse.state) {
          let i = 0;
          var newusers = coreResponse.data.map(function (items) {
            let j = 0;
            items.usuarioRol.map((itemUser) => {
              itemUser.id = j++;
              return i;
            });

            return { ...items, id: i++ };
          });

          setUsers(newusers);
        } else setUsers([]);
        setLoadingUsers(false);
      } catch (error) {
        setError(error);
        setLoadingUsers(false);
      }
    };
    fetchData();
  }, []);

  return {
    users,
    setUsers,
    isLoadingUsers,
    error,
  };
};

export default useUsers;
