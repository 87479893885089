import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";

import "./styles.css";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";

const useStylesCF = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  button: {
    backgroundColor: "#ffffff",
    color: "#e1251b",
    border: "2px solid #e1251b",
    borderRadius: "25px",
    fontWeight: "bold",
    padding: "9px 0px",

    "&:hover": {
      backgroundColor: "#e1251b",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonActive: {
    backgroundColor: "#e1251b",
    color: "#ffffff",
    border: "2px solid #e1251b",
    borderRadius: "25px",
    fontWeight: "bold",
    padding: "9px 0px",
    "&:hover": {
      backgroundColor: "#e1251b",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonLoading: {
    backgroundColor: "#f4f4f4",
    border: "2px solid rgba(239, 239, 239, 0.3)",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#757575",
    },
  },
}));

const useStylesREY = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  button: {
    backgroundColor: "#ffffff",
    color: "#e1251b",
    border: "2px solid #e1251b",
    borderRadius: "25px",
    fontWeight: "bold",
    padding: "9px 0px",

    "&:hover": {
      backgroundColor: "#e1251b",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonActive: {
    backgroundColor: "#e1251b",
    color: "#ffffff",
    border: "2px solid #e1251b",
    borderRadius: "25px",
    fontWeight: "bold",
    padding: "9px 0px",
    "&:hover": {
      backgroundColor: "#e1251b",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonLoading: {
    backgroundColor: "#f4f4f4",
    border: "2px solid rgba(239, 239, 239, 0.3)",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#757575",
    },
  },
}));

const useStylesTV = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  button: {
    backgroundColor: "#ffffff",
    color: "#ef732c",
    border: "2px solid #ef732c",
    borderRadius: "25px",
    fontWeight: "bold",
    padding: "9px 0px",

    "&:hover": {
      backgroundColor: "#ef732c",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonActive: {
    backgroundColor: "#ef732c",
    color: "#ffffff",
    border: "2px solid #ef732c",
    borderRadius: "25px",
    fontWeight: "bold",
    padding: "9px 0px",
    "&:hover": {
      backgroundColor: "#ef732c",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonLoading: {
    backgroundColor: "#f4f4f4",
    border: "2px solid rgba(239, 239, 239, 0.3)",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#757575",
    },
  },
}));

const useStylesSRX = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  button: {
    backgroundColor: "#ffffff",
    color: "#c73436",
    border: "2px solid #c73436",
    borderRadius: "25px",
    fontWeight: "bold",
    padding: "9px 0px",

    "&:hover": {
      backgroundColor: "#c73436",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonActive: {
    backgroundColor: "#c73436",
    color: "#ffffff",
    border: "2px solid #c73436",
    borderRadius: "25px",
    fontWeight: "bold",
    padding: "9px 0px",
    "&:hover": {
      backgroundColor: "#c73436",
      fontWeight: "bold",
      color: "#ffffff",
    },
  },
  buttonLoading: {
    backgroundColor: "#f4f4f4",
    border: "2px solid rgba(239, 239, 239, 0.3)",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#757575",
    },
  },
}));

/**
 * SCSLoadingButton
 * @param titulo
 * @param size
 * @param loading
 * @param onClick
 * @returns {string}
 */
export const SCSLoadingButton = (props) => {
  const [estilos, setEstilos] = useState(
    Number(localStorage.getItem("idGrupoStorage"))
  );

  const classesCF = useStylesCF();
  const classesREY = useStylesREY();
  const classesTV = useStylesTV();
  const classesSRX = useStylesSRX();

  return (
    <>
      {estilos === 1 ? (
        <LoadingButton
          {...props}
          className={`${classesCF.button} ${
            props.loading ? classesCF.buttonLoading : ""
          }`}
          classes={{
            loading: classesCF.buttonLoading,
            /* active: classes.buttonActive, */
          }}
          sx={{
            minWidth: props.size ? `${props.size}%` : "35%",
            minHeight: "5%",
          }}
          type={`${props.type ? props.type : "submit"}`}
          /* type="submit" */
          size="small"
          loading={props.loading}
          loadingPosition="end"
          variant="contained"
          disabled={props.disabled}
          endIcon={props.endIcon}
        >
          {props.titulo}{" "}
        </LoadingButton>
      ) : estilos === 2 ? (
        <LoadingButton
          {...props}
          className={`${classesREY.button} ${
            props.loading ? classesREY.buttonLoading : ""
          }`}
          classes={{
            loading: classesREY.buttonLoading,
            /* active: classes.buttonActive, */
          }}
          sx={{
            minWidth: props.size ? `${props.size}%` : "35%",
            minHeight: "5%",
          }}
          type={`${props.type ? props.type : "submit"}`}
          /* type="submit" */
          size="small"
          loading={props.loading}
          loadingPosition="end"
          variant="contained"
          disabled={props.disabled}
          endIcon={props.endIcon}
        >
          {props.titulo}{" "}
        </LoadingButton>
      ) : estilos === 3 ? (
        <LoadingButton
          {...props}
          className={`${classesTV.button} ${
            props.loading ? classesTV.buttonLoading : ""
          }`}
          classes={{
            loading: classesTV.buttonLoading,
            /* active: classes.buttonActive, */
          }}
          sx={{
            minWidth: props.size ? `${props.size}%` : "35%",
            minHeight: "5%",
          }}
          type={`${props.type ? props.type : "submit"}`}
          /* type="submit" */
          size="small"
          loading={props.loading}
          loadingPosition="end"
          variant="contained"
          disabled={props.disabled}
          endIcon={props.endIcon}
        >
          {props.titulo}{" "}
        </LoadingButton>
      ) : estilos === 4 ? (
        <LoadingButton
          {...props}
          className={`${classesSRX.button} ${
            props.loading ? classesSRX.buttonLoading : ""
          }`}
          classes={{
            loading: classesSRX.buttonLoading,
            /* active: classes.buttonActive, */
          }}
          sx={{
            minWidth: props.size ? `${props.size}%` : "35%",
            minHeight: "5%",
          }}
          type={`${props.type ? props.type : "submit"}`}
          /* type="submit" */
          size="small"
          loading={props.loading}
          loadingPosition="end"
          variant="contained"
          disabled={props.disabled}
          endIcon={props.endIcon}
        >
          {props.titulo}{" "}
        </LoadingButton>
      ) : (
        <LoadingButton
          {...props}
          className={`${classesCF.button} ${
            props.loading ? classesCF.buttonLoading : ""
          }`}
          classes={{
            loading: classesCF.buttonLoading,
            /* active: classes.buttonActive, */
          }}
          sx={{
            minWidth: props.size ? `${props.size}%` : "35%",
            minHeight: "5%",
          }}
          type={`${props.type ? props.type : "submit"}`}
          /* type="submit" */
          size="small"
          loading={props.loading}
          loadingPosition="end"
          variant="contained"
          disabled={props.disabled}
          endIcon={props.endIcon}
        >
          {props.titulo}{" "}
        </LoadingButton>
      )}
    </>
  );
};

/**
 * SCSLoadingButton
 * @param titulo
 * @param size
 * @param loading
 * @param onClick
 * @returns {string}
 */
export const SCSGenericButton = (props) => {
  const [estilos, setEstilos] = useState(
    Number(localStorage.getItem("idGrupoStorage"))
  );

  const classesCF = useStylesCF();
  const classesREY = useStylesREY();
  const classesTV = useStylesTV();
  const classesSRX = useStylesSRX();

  return (
    <>
      {estilos === 1 ? (
        <LoadingButton
          {...props}
          className={` ${
            props.active ? classesCF.buttonActive : classesCF.button
          }`}
          classes={{
            active: classesCF.buttonActive,
          }}
          sx={{
            minWidth: props.size ? `${props.size}%` : "35%",
            minHeight: "5%",
          }}
          size="small"
          variant="contained"
          onClick={props.onClick}
          loading={props.loading}
          loadingPosition="end"
          disabled={props.disabled}
          hidden={props.hidden}
          endIcon={props.endIcon}
        >
          {props.titulo}
        </LoadingButton>
      ) : estilos === 2 ? (
        <LoadingButton
          {...props}
          className={` ${
            props.active ? classesREY.buttonActive : classesREY.button
          }`}
          classes={{
            active: classesREY.buttonActive,
          }}
          sx={{
            minWidth: props.size ? `${props.size}%` : "35%",
            minHeight: "5%",
          }}
          size="small"
          variant="contained"
          onClick={props.onClick}
          loading={props.loading}
          loadingPosition="end"
          disabled={props.disabled}
          hidden={props.hidden}
          endIcon={props.endIcon}
        >
          {props.titulo}
        </LoadingButton>
      ) : estilos === 3 ? (
        <LoadingButton
          {...props}
          className={` ${
            props.active ? classesTV.buttonActive : classesTV.button
          }`}
          classes={{
            active: classesTV.buttonActive,
          }}
          sx={{
            minWidth: props.size ? `${props.size}%` : "35%",
            minHeight: "5%",
          }}
          size="small"
          variant="contained"
          onClick={props.onClick}
          loading={props.loading}
          loadingPosition="end"
          disabled={props.disabled}
          hidden={props.hidden}
          endIcon={props.endIcon}
        >
          {props.titulo}
        </LoadingButton>
      ) : estilos === 4 ? (
        <LoadingButton
          {...props}
          className={` ${
            props.active ? classesSRX.buttonActive : classesSRX.button
          }`}
          classes={{
            active: classesSRX.buttonActive,
          }}
          sx={{
            minWidth: props.size ? `${props.size}%` : "35%",
            minHeight: "5%",
          }}
          size="small"
          variant="contained"
          onClick={props.onClick}
          loading={props.loading}
          loadingPosition="end"
          disabled={props.disabled}
          hidden={props.hidden}
          endIcon={props.endIcon}
        >
          {props.titulo}
        </LoadingButton>
      ) : (
        <LoadingButton
          {...props}
          className={` ${
            props.active ? classesSRX.buttonActive : classesSRX.button
          }`}
          classes={{
            active: classesSRX.buttonActive,
          }}
          sx={{
            minWidth: props.size ? `${props.size}%` : "35%",
            minHeight: "5%",
          }}
          size="small"
          variant="contained"
          onClick={props.onClick}
          loading={props.loading}
          loadingPosition="end"
          disabled={props.disabled}
          hidden={props.hidden}
          endIcon={props.endIcon}
        >
          {props.titulo}
        </LoadingButton>
      )}
    </>
  );
};
