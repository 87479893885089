import React, { useState, useEffect } from "react";

import { Container, Grid } from "@mui/material";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { consultaCliente } from "app/modules/app-cliente/services/clienteService";
import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";
import TipoSiniestros from "../pages/TipoSiniestros/TipoSiniestros";

const DetalleCrearSiniestro = ({ datosBusqueda }) => {
  const [cliente, setCliente] = useState(null);
  const [idCliente, setIdCliente] = useState("");
  const [mostrarDetalle, setMostrarDetalle] = useState(true);

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  useEffect(() => {
    const buscarExistenciaCliente = async () => {
      try {
        const respuestaCliente = await consultaCliente(
          datosBusqueda.identificacion
        );

        /* console.log(respuestaCliente); */

        if (respuestaCliente.state) {
          setIdCliente(respuestaCliente.data.idCliente);

          setCliente(respuestaCliente.data);
          setMostrarDetalle(false);
        } else {
          setCliente(nuevoCliente);
          setIdCliente(nuevoIdCliente);
          setMostrarDetalle(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    buscarExistenciaCliente();
  }, []);

  if (mostrarDetalle) {
    return <SpinnerLoadSrx />;
  }

  return (
    <div>
      <Container maxWidth="mx-auto">
        <Grid container spacing={3}>
          {cliente && (
            <>
              <Grid item md={12} xs={12}>
                <Grid item md={12}>
                  <HeaderInformacionCliente idCliente={idCliente} />

                  <TipoSiniestros
                    cliente={cliente}
                    idCliente={idCliente}
                    identificador={datosBusqueda.identificacion}
                    datosBusqueda={datosBusqueda}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default DetalleCrearSiniestro;
