import React from "react";
import FormSiniestroHistorialSeguimiento from "../components/FormSiniestroHistorialSeguimiento";
import HeaderInformacionCliente from "../../shared/containers/HeaderInformacionCliente";
import HeaderInformacionProducto from "../components/HeaderInformacionProducto";
import BotonIngresarComentarios from "./BotonIngresarComentarios";
import BotonSalirLimpiarStorage from "./BotonSalirLimpiarStorage";
import HeaderInformacionSiniestro from "./HeaderInformacionSiniestro";

const DetalleSeguimientoSiniestro = ({
  idCliente,
  idSiniestro,
  idContrato,
}) => {
  return (
    <>
      <BotonSalirLimpiarStorage />

      <HeaderInformacionCliente idCliente={idCliente} />

      <HeaderInformacionProducto idSiniestro={idSiniestro} />

      <HeaderInformacionSiniestro
        idCliente={idCliente}
        idSiniestro={idSiniestro}
      />

      <FormSiniestroHistorialSeguimiento idSiniestro={idSiniestro} />

      <BotonIngresarComentarios idSiniestro={idSiniestro} />
    </>
  );
};

export default DetalleSeguimientoSiniestro;
