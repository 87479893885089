import React, { useState, useEffect } from "react";

import {
  Button,
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useGetPoliticDocument } from "../hooks/useGetPoliticDocument";



const ModalDocumentDetail = ({ open, close, title, documentType, setContent }) => {
  const { isLoading, documentDetail } = useGetPoliticDocument(documentType);

  if (isLoading) {
    return <h2> </h2>;
  }

  setContent(documentDetail);

  return (
    <Dialog
      aria-describedby="alert-dialog-slide-description"
      onClose={() => close(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{ width: 1 }}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => close(false)}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <TableRow>
          <TableCell>
            <div dangerouslySetInnerHTML={{ __html: documentDetail }} />
          </TableCell>
        </TableRow>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={() => close(false)}>
          ACEPTAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDocumentDetail;
