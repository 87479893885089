import React from "react";
import { withStyles } from "@mui/styles";
import { green, purple } from "@mui/material/colors";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Create";
import NewlIcon from "@mui/icons-material/AddBox";

export const SaveButton = (props) => {
  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      disabled={props.disabled}
      style={{ margin: "5px" }}
    >
      <SaveIcon /> Guardar
    </Button>
  );
};

export const CancelButton = (props) => {
  return (
    <Button
      color="secondary"
      onClick={props.onClick}
      disabled={props.disabled}
      style={{ margin: "5px" }}
    >
      <CancelIcon /> Cancelar
    </Button>
  );
};

export const EditButton = (props) => {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={props.onClick}
      disabled={props.disabled}
      style={{ margin: "5px" }}
    >
      <EditIcon /> Editar
    </Button>
  );
};

export const NewButton = (props) => {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={props.onClick}
      disabled={props.disabled}
      style={{ margin: "5px" }}
    >
      <NewlIcon /> Nuevo{" "}
    </Button>
  );
};

const CustomButton = ({ children, onClick, disabled, classes }) => {
  return (
    <Button
      className={classes.root}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      style={{ margin: "5px" }}
    >
      {children}
    </Button>
  );
};

export const GreenButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(CustomButton);
