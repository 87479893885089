import React, { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { consultarEstadoCredito } from "../services/transaccionalService";

const CabeceraCuentaCredito = ({ datosBusqueda }) => {
  const [cabeceraConsumo, setCabeceraConsumo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCabeceraEstado = async () => {
      try {
        const respuestaCabeceraConsumo = await consultarEstadoCredito(
          datosBusqueda.identificacion,
          datosBusqueda.idGrupo
        );

        console.log(respuestaCabeceraConsumo);

        if (respuestaCabeceraConsumo.state) {
          setCabeceraConsumo(respuestaCabeceraConsumo.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getCabeceraEstado();
  }, []);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  return (
    <Card sx={{ minHeight: "10vh", maxHeight: "auto", borderRadius: "25px" }}>
      <section className="header-cuenta-information">
        <div class="header-cuenta-detalle-header">
          <div class="item11">
            <h4>
              <strong>Identificación </strong>
            </h4>
          </div>
          <div class="item12">
            <h4>
              {cabeceraConsumo.identificacion
                ? `${cabeceraConsumo.identificacion}`
                : ""}
            </h4>
          </div>
          <div class="item13">
            <h4>
              <strong>Nombre </strong>
            </h4>
          </div>
          <div class="item14">
            <h4>
              {cabeceraConsumo.nombreCliente
                ? `${cabeceraConsumo.nombreCliente}`
                : ""}
            </h4>
          </div>
          <div class="item15">
            <h4>
              <strong>Grupo </strong>
            </h4>
          </div>
          <div class="item16">
            <h4>{cabeceraConsumo.grupo ? `${cabeceraConsumo.grupo}` : ""}</h4>
          </div>
        </div>

        <div class="header-cuenta-valores">
          {/*   <div class="item1">
            <h4>
              <strong>N° Tarjeta:</strong>
            </h4>
          </div> */}

          {/*       <div class="item1">
            <h4>
              {cabenceraTransaccional.numeroTarjeta
                ? `${cabenceraTransaccional.numeroTarjeta}`
                : ""}
            </h4>
          </div> */}

          <div class="item7">
            <h4>
              <strong>Fecha Emisión: </strong>
            </h4>
          </div>

          <div class="item8">
            <h4>
              {cabeceraConsumo?.fechaCorte
                ? ` ${cabeceraConsumo?.fechaCorte}`
                : ""}
            </h4>
          </div>

          <div class="item9">
            <h4>
              <strong>Fecha Máx. Pago: </strong>
            </h4>
          </div>

          <div class="item10">
            <h4>
              {cabeceraConsumo?.fechaPago
                ? ` ${cabeceraConsumo?.fechaPago}`
                : ""}
            </h4>
          </div>

          <div class="item11">
            <h4>
              <strong>Valor Estado: </strong>
            </h4>
          </div>

          <div class="item12">
            <h4>
              {cabeceraConsumo?.valorEstado
                ? ` $ ${cabeceraConsumo?.valorEstado}`
                : ""}
            </h4>
          </div>

          <div class="item13">
            <h4>
              <strong>Valor a Pagar: </strong>
            </h4>
          </div>

          <div class="item14">
            <h4>
              {cabeceraConsumo?.valorPagar
                ? ` $ ${cabeceraConsumo?.valorPagar}`
                : ""}
            </h4>
          </div>

          <div class="item15">
            <h4>
              <strong>Estado Cuenta: </strong>
            </h4>
          </div>

          <div class="item16">
            <h4>
              {cabeceraConsumo?.estadoCuenta
                ? `${cabeceraConsumo?.estadoCuenta}`
                : ""}
            </h4>
          </div>

          <div class="item21">
            <h4>
              <strong>Cartera: </strong>
            </h4>
          </div>

          <div class="item22">
            <h4>
              {cabeceraConsumo?.cartera ? `${cabeceraConsumo?.cartera}` : ""}
            </h4>
          </div>

          <div class="item17">
            <h4>
              <strong>Estados Vencidos: </strong>
            </h4>
          </div>
          <div class="item18">
            <h4>
              {cabeceraConsumo?.estadosVencidos ??
                ` ${cabeceraConsumo?.estadosVencidos}`}
            </h4>
          </div>

          <div class="item19">
            <h4>
              <strong>Día Corte: </strong>
            </h4>
          </div>

          <div class="item20">
            <h4>
              {cabeceraConsumo?.periodo ? ` ${cabeceraConsumo?.periodo}` : ""}
            </h4>
          </div>

          <div class="item2">
            <h4>
              <strong>Aprobado MG: </strong>
            </h4>
          </div>

          <div class="item2">
            <h4>
              {cabeceraConsumo?.aprobado
                ? ` $ ${cabeceraConsumo?.aprobado}`
                : "$ 0.0"}
            </h4>
          </div>

          <div class="item5">
            <h4>
              <strong>Disponible MG: </strong>
            </h4>
          </div>

          <div class="item6">
            <h4>
              {cabeceraConsumo?.disponible
                ? ` $ ${cabeceraConsumo?.disponible}`
                : "$ 0.0"}
            </h4>
          </div>

          {/*  <div class="item3">
            <h4>
              <strong>Utilizado MG: </strong>
            </h4>
          </div> 

          <div class="item4">
            <h4>
              {cabeceraConsumo?.utilizado
                ? ` $ ${cabeceraConsumo?.utilizado}`
                : "$ 0.0"}
            </h4>
          </div>
          */}

          {Number(cabeceraConsumo.aprobado2) !== 0.0 && (
            <>
              <div class="item23">
                <h4>
                  <strong>Aprobado Abastos: </strong>
                </h4>
              </div>
              <div class="item24">
                <h4>
                  {cabeceraConsumo?.aprobado2
                    ? `$ ${cabeceraConsumo?.aprobado2}`
                    : "$ 0.0"}
                </h4>
              </div>
            </>
          )}

          {Number(cabeceraConsumo.disponible2) !== 0.0 && (
            <>
              <div class="item27">
                <h4>
                  <strong>Disponible Abastos: </strong>
                </h4>
              </div>
              <div class="item28">
                <h4>
                  {cabeceraConsumo?.disponible2
                    ? `$ ${cabeceraConsumo?.disponible2}`
                    : "$ 0.0"}
                </h4>
              </div>
            </>
          )}

          {/* {Number(cabeceraConsumo.utilizado2) !== 0.0 && (
            <>
              <div class="item25">
                <h4>
                  <strong>Utilizado Abastos: </strong>
                </h4>
              </div>
              <div class="item26">
                <h4>
                  {cabeceraConsumo?.utilizado2
                    ? `$ ${cabeceraConsumo?.utilizado2}`
                    : "$ 0.0"}
                </h4>
              </div>
            </>
          )} */}
        </div>
      </section>
    </Card>
  );
};

export default CabeceraCuentaCredito;
