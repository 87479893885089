const LONGITUD_CELULAR = 10;
const LONGITUD_TELEFCONVENCIONAL = 8;

export const onlyTexto =  /^[a-z]+$/;

export const upper = (value) => value && value.toUpperCase();
export const lower = (value) => value && value.toLowerCase();
export const lessThan = (otherField) => (value, previousValue, allValues) =>
  parseFloat(value) < parseFloat(allValues[otherField]) ? value : previousValue;
export const greaterThan = (otherField) => (value, previousValue, allValues) =>
  parseFloat(value) > parseFloat(allValues[otherField]) ? value : previousValue;

export const onlyText = (value) => {
  if (value && /[^a-zA-ZÁÉÍÓÚáéíóúÑñ ]/i.test(value)) return value.toUpperCase().substr(0, value.length - 1);
  else return value.toUpperCase();
};

export const onlyNumber = (value) => value.replace(/[^\d]/g, '');

export const onlyTextAndNumbers = (value) => {
  if (value && /[^a-zA-ZÁÉÍÓÚáéíóúÑñ 0-9]/i.test(value)) return value.toUpperCase().substr(0, value.length - 1);
  else return value.toUpperCase();
};

export const mixedText = (value) => {
  if (value && /[^a-zA-ZÁÉÍÓÚáéíóúÑñ 0-9-#.&]/i.test(value)) return value.toUpperCase().substr(0, value.length - 1);
  else return value.toUpperCase();
};

export const cellPhone = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  return `${onlyNums.slice(0, LONGITUD_CELULAR)}`;
};

export const phone = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  return `${onlyNums.slice(0, LONGITUD_TELEFCONVENCIONAL)}`;
};

export const onlyDecimal = (value) => {
  if (!value) return '';
  value = value.replace(/[^0-9.]/g, '');

  const sections = value.split('.');

  if (sections[0] !== '0' && sections[0] !== '00') {
    sections[0] = sections[0].replace(/^0+/, '');
  } else {
    sections[0] = '0';
  }

  if (sections[1]) {
    return sections[0] + '.' + sections[1].slice(0, 2);
  } else if (value.indexOf('.') !== -1) {
    return sections[0] + '.';
  } else {
    return sections[0];
  }
};
