import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

import { FormControl, Grid, OutlinedInput, TextField } from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputLabel from "@mui/material/InputLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  formatoFecha,
  validarCelular,
} from "app/modules/shared/validations/validaciones";

import { MaskCelulares } from "app/modules/shared/mask/mascarasInputs";

import { LoadingButton } from "@mui/lab";
import { obtenerFechaActualFormulario } from "app/modules/shared/commonValues/obtenerFecha";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import {
  crearDatosRequerimiento,
  crearRequerimientoSolicitud,
} from "../services/solicitudesService";

const FormCambioEstado = ({ idCliente, idLocal, data }) => {
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const configuracionEnvioDatos = async (valores) => {
    setLoading(true);

    const informacionCambioEstado = {
      idRequerimiento: 0,
      idCliente: Number(idCliente),
      celular: valores.celular,
      email: valores.email,
      idTipoRequerimiento: 17,
      idSubTipoRequerimento: "",
      estado: "ESTREQSOL",

      idLocal: idLocal,
      fechaSolicitud: obtenerFechaActualFormulario(),
      fechaResuelto: "",
      esActivo: true,
    };

    console.log({ informacionCambioEstado });

    let idRequeriminetoAux;

    try {
      const respuestaSolicitud = await crearRequerimientoSolicitud(
        informacionCambioEstado
      );

      if (respuestaSolicitud.state) {
        setDatosEnviadosCorrectamente(true);

        idRequeriminetoAux = respuestaSolicitud.data;

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
        }, 3000);
      } else {
        alert(`${respuestaSolicitud.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(`${error.response.data.message}`);
    }

    const informacionDatosRequerimiento = {
      idDatosRequerimiento: 0,
      idRequerimiento: idRequeriminetoAux,

      fecha: valores.fecha,
      referencia: valores.codigoDactilar.toUpperCase(),

      detalle: "",

      factura: 0,
      valor: 0,
      operacion: 0,
      plazo: 0,

      esActivo: true,
    };

    console.log({ informacionDatosRequerimiento });

    try {
      const respuestaDatosRequeridos = await crearDatosRequerimiento(
        informacionDatosRequerimiento
      );

      console.log(respuestaDatosRequeridos);

      if (!respuestaDatosRequeridos.state) {
        alert(`${respuestaDatosRequeridos.message}`);
      } /* else {
        setLoading(false);
      } */
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="accordion-container">
      <Formik
        initialValues={{
          celular: "",
          email: "",

          fecha: "",
          codigoDactilar: "",
        }}
        validate={(values) => {
          const errors = {};

          if (validarCelular(values.celular)) {
            errors.celular = "Primer digito debería ser '09'";
          }

          return errors;
        }}
        validationSchema={Yup.object({
          celular: Yup.string()
            .max(10, "Debería tener 10 números")
            .min(10, "Debería tener 10 números")
            .required("Celular requerido"),
          email: Yup.string()
            .email("Email no tiene formato")
            .required("Email requerido"),
          fecha: Yup.string().required("Fecha Nacimiento requerido"),
          codigoDactilar: Yup.string().required("Código Dactilar requerido"),
        })}
        onSubmit={(valores, { resetForm }) => {
          configuracionEnvioDatos(valores);
          setTimeout(() => {
            resetForm({ values: "" });
          }, 2000);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <div className="accordion-container">
              <Accordion
                defaultExpanded="true"
                sx={{ boxShadow: 3, lineHeight: 0 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className="accordion-summary"
                  sx={{ margin: 0 }}
                >
                  <h2 className="formulario-cabecera">Cambio Estado</h2>
                </AccordionSummary>
                <AccordionDetails sx={{ marginTop: "2rem" }}>
                  <Grid container spacing={1} rowSpacing={1}>
                    <Grid item md={12}>
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          <div className="cliente-informacion-seccion">
                            <h4>{`ESTADO: ${data.estado}`}</h4>
                            <FormControl
                              sx={{ width: "15%" }}
                              size="small"
                              variant="outlined"
                              error={touched.celular && Boolean(errors.celular)}
                              helperText={touched.celular && errors.celular}
                              onBlur={handleBlur}
                            >
                              <InputLabel htmlFor="celular">Celular</InputLabel>
                              <OutlinedInput
                                value={values.celular}
                                onChange={handleChange}
                                name="celular"
                                id="celular"
                                inputComponent={MaskCelulares}
                                label="celular"
                              />
                              <ErrorMessage
                                name="celular"
                                component={() => (
                                  <FormHelperText>
                                    {errors.celular}
                                  </FormHelperText>
                                )}
                              />
                            </FormControl>

                            <TextField
                              id="email"
                              name="email"
                              type="text"
                              label="Email"
                              sx={{ width: "33%" }}
                              value={values.email}
                              onChange={handleChange}
                              size="small"
                              error={touched.email && Boolean(errors.email)}
                              helperText={touched.email && errors.email}
                              onBlur={handleBlur}
                            />

                            <TextField
                              id="codigoDactilar"
                              name="codigoDactilar"
                              type="text"
                              label="Código Dactilar"
                              sx={{ width: "18%" }}
                              value={values.codigoDactilar}
                              onChange={handleChange}
                              size="small"
                              inputProps={{
                                style: {
                                  textTransform: "uppercase",
                                },
                              }}
                              error={
                                touched.codigoDactilar &&
                                Boolean(errors.codigoDactilar)
                              }
                              helperText={
                                touched.codigoDactilar && errors.codigoDactilar
                              }
                              onBlur={handleBlur}
                            />

                            <TextField
                              id="fecha"
                              name="fecha"
                              type="date"
                              label="Fecha Expedición"
                              sx={{ width: "16%" }}
                              onChange={handleChange}
                              size="small"
                              value={formatoFecha(values.fecha)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={touched.fecha && Boolean(errors.fecha)}
                              helperText={touched.fecha && errors.fecha}
                              onBlur={handleBlur}
                            />
                          </div>
                        </Grid>
                      </Grid>

                      <div className="confirmar-estados-servicios">
                        <LoadingButton
                          sx={{ width: "20%" }}
                          type="submit"
                          size="small"
                          loading={loading}
                          loadingPosition="end"
                          variant="contained"
                          /* startIcon={<AttachMoneyIcon />} */
                        >
                          Guardar
                        </LoadingButton>

                        {datosEnviadosCorrectamente && (
                          <AcceptServiceresponse />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormCambioEstado;
