import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAuthUser, updateLoadUser, userSignInSuccess, hideAuthLoader, setAuthUserOut, updateCodeUser, showAuthLoader } from '../../../redux/actions/Auth';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions/Common';
import { history } from '../../../redux/store';
import { onLogin, onConnect, onChangePassword, onValidarOtp, onActualizarOtp , onLogout, onForgotPassword, 
 } from "../services/index"
import { useHistory } from 'react-router-dom';
export const useOnLogin = (email, password, send, setSend) => {
    const dispatch = useDispatch();
    const history = useHistory();
        useEffect(() => {
        const fetchData = async () => {
            dispatch(showAuthLoader());

            let data1 = await onLogin(email, password);

            if (data1.state) {

                sessionStorage.setItem('user', JSON.stringify(data1.data));
                sessionStorage.setItem('roles', JSON.stringify(data1.data.usuarioRol));
                let roles = data1.data.usuarioRol;
                if (roles.length === 0) {
                    dispatch(hideAuthLoader())
                    dispatch(fetchError("El Usuario no posee ningun perfil para ingresar al sistema"));
                }
                else if (roles.length === 1) {
                    let user = data1.data;
                    let rol = roles[0];
                    dispatch(hideAuthLoader())
                    dispatch(fetchStart());
                    let data = await onConnect(rol.idRol, user);
                    if (data.state) {
                     
                        let transactions = data.data.transactions;

                        sessionStorage.setItem('jwtToken', data.data.jwt.accessToken);
                        sessionStorage.setItem('menu', JSON.stringify(transactions));

                        const payload = {
                            userCode: user.key
                          };
                        onActualizarOtp(payload);// enviar otp

                        dispatch(hideAuthLoader())
                        dispatch(fetchSuccess("Ingreso exitoso"));
                        dispatch(setAuthUser(user));

                    } else {
                        dispatch(fetchError(data.message));
                    }


                } else {
                    dispatch(hideAuthLoader())
                    dispatch(userSignInSuccess(data1.data));
                    dispatch(fetchSuccess());
                }
            } else {
                setSend(false)
                if (data1.code === "SEC010") {
                    //obliga a cambiar de clave.
                    let session = {
                        userCode: email
                    };
                    dispatch(hideAuthLoader())
                    dispatch(updateCodeUser(session));
                    history.push("/password");
                }
                else {
                
                    dispatch(hideAuthLoader())
                    dispatch(fetchError(data1.message));
                }
            }
        }
        if (send)
            fetchData();
    }, [dispatch, send, setSend]);
};

export const useOnConnect = (rol, sendConnect, setSendConnect) => {
    const dispatch = useDispatch();
    const user = JSON.parse(sessionStorage.getItem('user'));

    useEffect(() => {

        const fetchData = async () => {

            dispatch(showAuthLoader());
            dispatch(fetchStart());
            let data = await onConnect(rol, user);
            if (data.state) {
                let transactions = data.data.transactions;
                sessionStorage.setItem('jwtToken', data.data.jwt.accessToken);
                sessionStorage.setItem('menu', JSON.stringify(transactions));
                dispatch(hideAuthLoader())
                dispatch(fetchSuccess("Ingreso exitoso"));
                dispatch(setAuthUser(user));
            } else {
                dispatch(hideAuthLoader())
                dispatch(fetchError(data.message));
            }
        }
        if (sendConnect)
            fetchData();
    }, [sendConnect, setSendConnect]);
};

export const useOnChangePassword = (payload, sendChangePassword, setSendChangePassword) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            dispatch(fetchStart());
         
            let data = await onChangePassword(payload);
          

            if (data.state) {
                 let msg = "Contraseña actualizada correctamente";
                dispatch(fetchSuccess(msg));
           
                let data1 = await onLogout(payload.userCode);

                if (data1.state) {
      
                    dispatch(fetchSuccess("Transacciòn OK"));
                    dispatch(setAuthUserOut(""));
                    dispatch(updateCodeUser(null));
                    sessionStorage.removeItem('jwtToken');
                    sessionStorage.removeItem('menu');
                    sessionStorage.removeItem('user');
                    sessionStorage.removeItem('roles');
                  
                    history.push("/");

                } else {
                    dispatch(fetchError(data.message));
                }
            } else {
                setSendChangePassword(false);
                dispatch(fetchError(data.message));

            }
        };
        if (sendChangePassword)
            fetchData();
    }, [sendChangePassword, setSendChangePassword]);
};
export const useOnChangePasswordExternal = (payload, sendChangePassword, setSendChangePassword) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {

            dispatch(fetchStart());
            let userCode = payload.userCode
            let data = await onChangePassword(payload);

            if (data.state) {
                let msg = "Contraseña actualizada correctamente";
                dispatch(fetchSuccess(msg));
                history.push("/signin");
               
            } else {
                setSendChangePassword(false);
                dispatch(fetchError(data.message));

            }
        };
        if (sendChangePassword)
            fetchData();
    }, [sendChangePassword, setSendChangePassword]);
};


export const useOnLogout = (userCode, sendLogout, setSendLogout) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            dispatch(fetchStart());
            let data = await onLogout(userCode);
            if (data.state) {
                dispatch(fetchSuccess());
                dispatch(setAuthUserOut(""));
                dispatch(updateCodeUser(null));
                sessionStorage.removeItem('jwtToken');
                sessionStorage.removeItem('menu');
                sessionStorage.removeItem('user');
                sessionStorage.removeItem('roles');

                localStorage.clear();
            } else {
                dispatch(fetchError(data.message));
            }
        };
        if (sendLogout)
            fetchData();
    }, [sendLogout, setSendLogout]);
};

export const useOnForgotPassword = (userCode, sendForgot, setSendForgot) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            dispatch(fetchStart());
            let data = await onForgotPassword(userCode);
            if (data.state) {
                let msg = "Contraseña generada correctamente"; //clave actualizada
                dispatch(fetchSuccess(msg));
                dispatch(updateCodeUser(null));
                //history.push("/signin");
            } else {
                dispatch(fetchError(data.message));
                dispatch(updateCodeUser(null));
            }
        };
        if (sendForgot)
            fetchData();
    }, [sendForgot, setSendForgot]);
};

export const useOnValidateOTP = (payload, sendValidateOtp, setSendValidateOtp) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            dispatch(fetchStart());
              
            let data = await onValidarOtp(payload);
            if (data.state) {
                 let msg = "Contraseña correcta";
                dispatch(fetchSuccess(msg));
                history.push("/initialHome");
            } else {
                setSendValidateOtp(false);
                dispatch(fetchError(data.message));
            }
        };
        if (sendValidateOtp)
            fetchData();
    }, [sendValidateOtp, setSendValidateOtp]);
};

export const useOnActualizarOTP = (payload, sendActualizarOtp, setSendActualizarOtp) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            dispatch(fetchStart());
              
            let data = await onActualizarOtp(payload);
            if (data.state) {
                 let msg = "Contraseña enviada";
                dispatch(fetchSuccess(msg));
               
            } else {
                setSendActualizarOtp(false);
                dispatch(fetchError(data.message));
            }
        };
        if (sendActualizarOtp)
            fetchData();
    }, [sendActualizarOtp, setSendActualizarOtp]);
};