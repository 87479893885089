import React, { useEffect, useState } from "react";

import {
  Card,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { LISTA_MENU } from "../constants/constants";

const useStylesMenu = makeStyles((theme) => ({
  itemNormal: {
    justifyContent: "center",
    borderRadius: "25px !important",
  },

  itemSelected: {
    justifyContent: "center",
    backgroundColor: "#fbdfdd !important",
    borderRadius: "25px !important",
  },
}));

const useStylesButtonColapse = makeStyles((theme) => ({
  buttonColapse: {
    position: "absolute",
    top: "-22px",
    left: "50%",
    transform: "translateX(-50%)",
    background: "white",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      background: "white",
    },
  },
}));

const DetalleTransaccionesCliente = ({
  isColapse,
  setDatosBusqueda,
  handleColapse,
  datosBusqueda,
  alturaResolucion,
}) => {
  const [menuOptions, setMenuOptions] = useState(LISTA_MENU);
  const styleMenu = useStylesMenu();
  const styleButtonColapse = useStylesButtonColapse();

  const handleListaAutorizaciones = (id) => {
    setDatosBusqueda((args) => ({
      ...args,
      idMovimiento: id + 1,
    }));

    setMenuOptions((args) =>
      args?.map((item) => {
        if (item.id === id + 1) {
          return { ...item, isSelected: true };
        }
        return { ...item, isSelected: false };
      })
    );
  };

  return (
    <div className="movimientoCliente-menu">
      {/* Imagen de la cabecera */}
      <header className="movimientoCliente-menu-header">
        {Number(datosBusqueda.idGrupo) === 1 ? (
          <img
            style={{ width: "auto", height: "10vh" }}
            src="/images/logo/logo-corpfav.png"
            alt="logo-copfav"
            loading="lazy"
          />
        ) : Number(datosBusqueda.idGrupo) === 2 ? (
          <img
            style={{ width: "auto", height: "11.5vh" }}
            src="/images/logo/logo-corpfav.png"
            alt="logo-copfav"
            loading="lazy"
          />
        ) : Number(datosBusqueda.idGrupo) === 3 ? (
          <img
            style={{ width: "auto", height: "11.5vh" }}
            src="/images/logo/logo-tvtas.png"
            alt="logo-copfav"
            loading="lazy"
          />
        ) : Number(datosBusqueda.idGrupo) === 4 ? (
          <img
            style={{ width: "auto", height: "11.5vh" }}
            src="/images/logo/logo-corpfav.png"
            alt="logo-copfav"
            loading="lazy"
          />
        ) : (
          <img
            style={{ width: "auto", height: "11.5vh" }}
            src="/images/logo/logo-corpfav.png"
            alt="logo-copfav"
            loading="lazy"
          />
        )}
      </header>

      {/* Menu de opciones de mov. */}
      <div className="movimientoCliente-menu-container">
        {isColapse ? (
          <IconButton
            className={styleButtonColapse.buttonColapse}
            aria-label="calapse-right"
            size="large"
            onClick={handleColapse}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        ) : (
          <IconButton
            className={styleButtonColapse.buttonColapse}
            aria-label="calapse-right"
            size="large"
            onClick={handleColapse}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        )}
        <Card
          sx={{
            minHeight: `${alturaResolucion}px`,
            maxHeight: `${alturaResolucion}px`,
            borderRadius: "25px",
          }}
        >
          <List
            sx={{
              padding: "0 1.5rem",
              marginTop: "1.5rem",
              overflow: "auto",
              maxHeight: `${alturaResolucion}px`,
              paddingBottom: "2.5rem",

              "@media (max-width: 1360px)": {
                padding: "0 0.5rem",
                marginTop: "1.5rem",
                overflow: "auto",
                maxHeight: `${alturaResolucion}px`,
                paddingBottom: "2.5rem",
              },
            }}
            className="movimientoCliente-menu-items"
          >
            {menuOptions.map((opcion, index) => (
              <>
                <ListItem
                  key={opcion.id}
                  disablePadding={false}
                  sx={{
                    "@media (max-width: 1360px)": {
                      padding: "0.3rem 0.3rem",
                    },
                  }}
                >
                  <ListItemButton
                    onClick={() => handleListaAutorizaciones(index)}
                    className={
                      opcion?.isSelected
                        ? styleMenu.itemSelected
                        : styleMenu.itemNormal
                    }
                    selected={opcion?.isSelected}
                  >
                    <ListItemIcon
                      sx={
                        isColapse && {
                          minWidth: 0,
                          paddingLeft: 0,
                          paddingRight: 0,
                        }
                      }
                    >
                      {opcion.iconMenu}
                    </ListItemIcon>

                    {!isColapse && <ListItemText primary={opcion.name} />}
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </Card>
      </div>
    </div>
  );
};

export default DetalleTransaccionesCliente;
