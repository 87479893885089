import React, { useState } from "react";
import DetalleValoresPorPagar from "../../containers/DetalleValoresPorPagar";
import { Container, Grid } from "@mui/material";
import BuscadorAutorizaciones from "../../components/BuscadorAutorizaciones";

const ValoresPagarTransaccional = () => {
  const [mostrarBuscador, setMostrarBuscador] = useState(true);
  const [datosBusqueda, setDatosBusqueda] = useState({
    identificacion: "",
  });

  if (mostrarBuscador) {
    return (
      <>
        <BuscadorAutorizaciones
          setDatosBusqueda={setDatosBusqueda}
          setMostrarBuscador={setMostrarBuscador}
          titulo="Consultar Valores Por Pagar"
        />
      </>
    );
  }

  return (
    <>
      <Container maxWidth="mx-auto">
        <Grid container spacing={3}>
          <Grid item md={12} sm={12} xs={12}>
            <DetalleValoresPorPagar datosBusqueda={datosBusqueda} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ValoresPagarTransaccional;
