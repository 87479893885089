import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

import Button from "@mui/material/Button";

import { formatoFecha } from "app/modules/shared/validations/validaciones";

import {
  consultarListaRequerimientos,
  crearCambioEstadoRequerimiento,
  crearCertificadoNotificacion,
} from "../services/solicitudesService";

import ModalAlertaNotificacionEstandar from "../components/ModalAlertaNotificacionEstandar";
import FormDatosEstadoCuenta from "../components/FormsDatosRequerimiento/FormDatosEstadoCuenta";
import handleDownlPdf from "app/utils/document/documentosService";
import { fechasDocumentosSiniestro } from "app/modules/shared/commonValues/obtenerFecha";
import ModalAlertDesestimar from "../components/ModalAlertDesestimar";
import FormDesestimarRequerimiento from "../components/FormDesestimarRequerimiento";

const ListaCertificadosCredito = ({ idCliente }) => {
  const [existenQuejas, setExistenQuejas] = useState(false);
  const [clienteProductos, setClienteProductos] = useState([]);
  const [informacionSolicitudAux, setInformacionSolicitudAux] = useState({});

  const [open, setOpen] = useState(false);
  const [openEstadoDeuda, setOpenEstadoDeuda] = useState(false);
  const [openSinDeuda, setOpenSinDeuda] = useState(false);
  const [openDesestimar, setOpenDesestimar] = useState(false);

  const [isUppdateList, setIsUppdateList] = useState(true);

  useEffect(() => {
    const getSiniestros = async () => {
      try {
        const respuestaListaRequerimientosCredito =
          await consultarListaRequerimientos(1);

        console.log(respuestaListaRequerimientosCredito);

        if (
          respuestaListaRequerimientosCredito.state &&
          respuestaListaRequerimientosCredito.data
        ) {
          setClienteProductos(respuestaListaRequerimientosCredito.data);
          setExistenQuejas(true);
          /*  setClienteInfo(cliente); */
        } else {
          setExistenQuejas(true);
          setClienteProductos([]);
        }
      } catch (error) {
        alert(`${error.response.data.message}`);
      }
    };
    getSiniestros();
  }, [isUppdateList]);

  const renderDetailsButton = (params) => {
    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => procesarSolicitidXtipo(params.row)}
      >
        Gestionar
      </Button>
    );
  };

  const renderDesestimarButton = (params) => {
    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => procesarDesestimar(params.row)}
      >
        Desestimar
      </Button>
    );
  };

  const columns = [
    {
      field: "idRequerimiento",
      hide: true,
      headerName: "ID",
      width: 80,
      headerClassName: "header-grid-solicitud",
    },
    {
      field: "identificacion",
      headerName: "Identificación",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
    },
    {
      field: "nombre",
      headerName: "Nombre",
      headerClassName: "header-grid-solicitud",
      width: 160,
      editable: false,
    },
    {
      field: "celular",
      headerName: "Celular",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "header-grid-solicitud",
      width: 185,
      editable: false,
    },
    {
      field: "requerimiento",
      headerName: "Requerimiento",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
    },
    {
      field: "fechaSolicitud",
      headerName: "Fecha Solicitud",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
      valueFormatter: (params) => {
        return formatoFecha(params.value);
      },
    },
    {
      field: "estado",
      headerName: "Estado",
      headerClassName: "header-grid-solicitud",
      width: 120,
      editable: false,
    },
    {
      field: "nombreLocal",
      headerName: "Nombre Local",
      headerClassName: "header-grid-solicitud",
      width: 130,
      editable: false,
    },
    {
      field: "revision",
      headerName: "Revisión",
      headerClassName: "header-grid-solicitud",
      width: 100,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
    },
    {
      field: "desestimar",
      headerName: "Desestimar",
      headerClassName: "header-grid-solicitud",
      width: 100,
      renderCell: renderDesestimarButton,
      disableClickEventBubbling: false,
    },
  ];

  const procesarCertificadoSinDeuda = async (solicitud) => {
    /* console.log(solicitud); */

    try {
      const respuestaCertificadoSinDeuda = await crearCertificadoNotificacion(
        "",
        solicitud.idRequerimiento
      );

      /* console.log(respuestaCertificadoSinDeuda); */

      if (respuestaCertificadoSinDeuda.state) {
        handleDownlPdf(
          respuestaCertificadoSinDeuda.data,
          `CRT_CREDITO_${
            solicitud.idRequerimiento
          }_${fechasDocumentosSiniestro()}`
        );

        setOpenSinDeuda(true);
      } else {
        alert(`${respuestaCertificadoSinDeuda.message}`);
      }
    } catch (error) {
      /* console.log(error); */
      alert(`${error.response.data.message}`);
    }
  };

  const procesarCertificadoEspecial = async (solicitud) => {
    try {
      const respuestaCertificadoEspecial = await crearCertificadoNotificacion(
        "",
        solicitud.idRequerimiento
      );

      /* console.log(respuestaCertificadoEspecial); */
      if (respuestaCertificadoEspecial.state) {
        /*  handleDownlPdf(
          respuestaCertificadoEspecial.data,
          `CRT_SINDEUDA_${
            solicitud.idRequerimiento
          }_${fechasDocumentosSiniestro()}`
        ); */
        setOpen(true);
      } else {
        alert(`${respuestaCertificadoEspecial.message}`);
      }
    } catch (error) {
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  const procesarEstadoDeuda = async (solicitud) => {
    try {
      const respuestaCambioEstado = await crearCambioEstadoRequerimiento(
        solicitud.idRequerimiento,
        "ESTREQPRO"
      );

      if (respuestaCambioEstado.state) {
        setOpenEstadoDeuda(true);
        setInformacionSolicitudAux(solicitud);
      } else {
        alert(`${respuestaCambioEstado.message}`);
      }
    } catch (error) {
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  const procesarSolicitidXtipo = async (solicitud) => {
    //Certificado Estado Deuda
    if (solicitud.idSubtipo === 5) {
      procesarEstadoDeuda(solicitud);
    }

    //Certificado Sin Deuda
    if (solicitud.idSubtipo === 6) {
      procesarCertificadoSinDeuda(solicitud);
    }

    //Certificado Especial
    if (solicitud.idSubtipo === 7) {
      procesarCertificadoEspecial(solicitud);
    }
  };

  const procesarDesestimar = async (solicitud) => {
    setInformacionSolicitudAux(solicitud);
    setOpenDesestimar(true);
  };

  const rows = clienteProductos;

  const stringMensaje = `Datos de la solicitud 
  | Nombre: ${informacionSolicitudAux.nombre} 
  | Identificación: ${informacionSolicitudAux.identificacion} 
  | Email: ${informacionSolicitudAux.email}
  | Fecha: ${informacionSolicitudAux.fechaSolicitud}`;

  return (
    <div>
      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          getRowId={(row) => row.idRequerimiento}
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>

      <ModalAlertaNotificacionEstandar
        setOpen={setOpenSinDeuda}
        open={openSinDeuda}
        titulo="Solicitud Sin Deuda"
        mensaje="La solicitud ha sido generada con exito!! revise su correo."
      />

      <ModalAlertaNotificacionEstandar
        setOpen={setOpen}
        open={open}
        titulo="Solicitud Especial Resulta"
        mensaje="La solicitud ha cambiado a estado resuelta."
      />

      <ModalAlertaNotificacionEstandar
        setOpen={setOpenEstadoDeuda}
        open={openEstadoDeuda}
        titulo="Solicitud Estado Deuda"
        mensaje={stringMensaje}
      >
        <FormDatosEstadoCuenta solicitud={informacionSolicitudAux} />
      </ModalAlertaNotificacionEstandar>

      <ModalAlertDesestimar
        setOpen={setOpenDesestimar}
        open={openDesestimar}
        titulo="Solicitud Estado Deuda"
        mensaje={stringMensaje}
        solicitud={informacionSolicitudAux}
      >
        <FormDesestimarRequerimiento solicitud={informacionSolicitudAux} />
      </ModalAlertDesestimar>
    </div>
  );
};

export default ListaCertificadosCredito;
