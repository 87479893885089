import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  FormControl,
  Grid,
  MenuItem,
  Alert,
  TextField,
  FormHelperText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  Select,
} from "@mui/material";

import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";

import {
  formatoFecha,
  validarFechaMayorMenor,
} from "app/modules/shared/validations/validaciones";
import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";

import { consultaCatalogosSinPadre } from "../../app-cliente/services/Catalogos/catalogosService";

import {
  consultaEstadoCancelacion,
  consultaCausaCancelacion,
  consultaMotivoCancelacion,
  consultaNotaCredito,
  consultarCancelacion,
  crearCancelacionSeguro,
  actualizarCancelacionSeguro,
} from "../services/SeguroService/seguroService";

import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import ModalAlertaNotificacionEstandar from "app/modules/app-servicios/components/ModalAlertaNotificacionEstandar";

const ESTADOS_SEGUROS = { CANCELADO: "CANCELADO", ANULADO: "ANULADO" };
const ESTADOS_SEGUROS_CODIGO = { CANCELADO: 2, ANULADO: 1 };

const FormCancelacion = ({ contrato, idContrato, setIsSaved }) => {
  const [informacionCancelacion, setInformacionCancelacion] = useState({});
  const [isloading, setIsloading] = useState(true);

  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [idCancelacionSeguro, setIdCancelacionSeguro] = useState(0);

  useEffect(() => {
    const getCancelacion = async () => {
      try {
        console.log({ idContrato });
        const respuestaCancelacion = await consultarCancelacion(idContrato);
        console.log({ respuestaCancelacion });

        if (respuestaCancelacion.state) {
          setInformacionCancelacion(respuestaCancelacion.data);
          setIdCancelacionSeguro(respuestaCancelacion.data.idCancelacion);
          setIsloading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (
      contrato.estado === ESTADOS_SEGUROS.CANCELADO ||
      contrato.estado === ESTADOS_SEGUROS.ANULADO
    ) {
      getCancelacion();
    } else {
      setIsloading(false);
    }
  }, []);

  if (isloading) {
    return <SpinnerLoadSrx />;
  }

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectEstadosCancelacion = ({
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [estadosCancelacion, setEstadosCancelacion] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaEstadoCancelacion();

          if (state) {
            setEstadosCancelacion(data);
          } else {
            setEstadosCancelacion([]);
          }
        } catch (error) {
          setEstadosCancelacion([]);
          console.log(error);
        }
      };
      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {estadosCancelacion.map((estados, index) => (
            <MenuItem key={index} value={estados.codigo}>
              {estados.descripcion}
            </MenuItem>
          ))}
        </Field>
        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectCausaCancelacion = ({
    estado,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [causaCancelacion, setcausaCancelacion] = useState([]);

    useEffect(() => {
      const getData = async () => {
        //ternaria se usa debido a que si se llama al servicio con estado ='' la peticion falla con estado=true y data={errors}
        const estadoAuxiliar = estado !== "" ? estado : 0;

        try {
          const { state, data } = await consultaCausaCancelacion(
            estadoAuxiliar
          );

          /* console.log({ state, data }); */
          if (state) {
            setcausaCancelacion(data);
          } else {
            setcausaCancelacion([]);
          }
        } catch (error) {
          setcausaCancelacion([]);
          console.log(error);
        }
      };
      getData();
    }, [estado]);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {causaCancelacion.map((causa, index) => (
            <MenuItem key={index} value={causa.codigo}>
              {causa.descripcion}
            </MenuItem>
          ))}
        </Field>
        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectMotivoCancelacion = ({
    causa,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [motivoCancelacion, setmotivoCancelacion] = useState([]);

    useEffect(() => {
      const getData = async () => {
        const causaAuxiliar = causa !== "" ? causa : 0;

        try {
          const { state, data } = await consultaMotivoCancelacion(
            causaAuxiliar
          );

          if (state) {
            setmotivoCancelacion(data);
          } else {
            setmotivoCancelacion([]);
          }
        } catch (error) {
          setmotivoCancelacion([]);
          console.log(error);
        }
      };
      getData();
    }, [causa]);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {motivoCancelacion.map((motivo, index) => (
            <MenuItem key={index} value={motivo.codigo}>
              {motivo.descripcion}
            </MenuItem>
          ))}
        </Field>
        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const ValorNotaDevolicion = ({ idCausa }) => {
    const [valorNotaCredito, setValorNotaCredito] = useState({});

    useEffect(() => {
      const getNotaCredito = async () => {
        try {
          const respuestaNotaCredito = await consultaNotaCredito(
            idContrato,
            idCausa
          );
          /* console.log({ respuestaNotaCredito }); */
          if (respuestaNotaCredito.state) {
            setValorNotaCredito(respuestaNotaCredito.data);
          } else {
            setValorNotaCredito({});
          }
        } catch (error) {
          console.log(error);
        }
      };
      getNotaCredito();
      /*  console.log(valorNotaCredito.valorSeguro); */
    }, [idCausa]);

    return (
      <>
        <h3>{`Valor Devolución: ${
          valorNotaCredito.valorSeguro ? valorNotaCredito.valorSeguro : 0
        } $`}</h3>
      </>
    );
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);
    const InformacionCancelacion = {
      idCancelacion: idCancelacionSeguro,
      idContrato: Number(idContrato),
      idCausa: Number(valores.idCausa),
      idMotivo: Number(valores.idMotivo),
      canal: valores.canal,
      notaCredito: Number(valores.notaCredito),
      observacion: valores.observacion.toUpperCase(),
      fechaCancelacion: valores.fechaCancelacion,
      esActivo: true,
    };

    const datosCancelacionSeguro = {
      IdEstado: Number(valores.estadoCancelacion),
      cancela: InformacionCancelacion,
    };

    console.log(datosCancelacionSeguro);

    if (
      contrato.estado === ESTADOS_SEGUROS.CANCELADO ||
      contrato.estado === ESTADOS_SEGUROS.ANULADO
    ) {
      actualizarCancelacion(datosCancelacionSeguro);
    } else {
      crearCancelacion(datosCancelacionSeguro);
    }
  };

  const actualizarCancelacion = async (datosCancelacionSeguro) => {
    console.log(datosCancelacionSeguro.cancela);

    try {
      const respuestaClienteInformacion = await actualizarCancelacionSeguro(
        datosCancelacionSeguro.cancela
      );

      console.log(respuestaClienteInformacion);

      if (respuestaClienteInformacion.state) {
        if (setIsSaved) {
          setIsSaved(true);
        }

        setIdCancelacionSeguro(respuestaClienteInformacion.data);
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
        }, 2000);
      } else {
        setLoading(false);
        alert(
          `No se ha podido cancelar el Seguro: ${respuestaClienteInformacion.message}`
        );
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(
        `No se ha podido cancelar el Seguro: ${error.response.data.message}`
      );
    }
  };

  const crearCancelacion = async (datosCancelacionSeguro) => {
    console.log({ datosCancelacionSeguro });

    try {
      const respuestaClienteInformacion = await crearCancelacionSeguro(
        datosCancelacionSeguro
      );

      console.log(respuestaClienteInformacion);

      if (respuestaClienteInformacion.state) {
        if (setIsSaved) {
          setIsSaved(true);
        }

        setIdCancelacionSeguro(respuestaClienteInformacion.data);
        setDatosEnviadosCorrectamente(true);
        setOpen(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
        }, 2000);
      } else {
        setLoading(false);
        alert(
          `No se ha podido cancelar el Seguro: ${respuestaClienteInformacion.message}`
        );
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(
        `No se ha podido cancelar el Seguro: ${error.response.data.message}`
      );
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          estadoCancelacion: `${
            contrato.idEstado === ESTADOS_SEGUROS_CODIGO.ANULADO ||
            contrato.idEstado === ESTADOS_SEGUROS_CODIGO.CANCELADO
              ? contrato.idEstado
              : ""
          }`,
          idCausa: informacionCancelacion.idCausa ?? "",
          idMotivo: informacionCancelacion.idMotivo ?? "",
          canal: informacionCancelacion.canal ?? "",
          notaCredito: informacionCancelacion.notaCredito ?? "",
          observacion: informacionCancelacion.observacion ?? "",
          fechaCancelacion:
            formatoFecha(informacionCancelacion.fechaCancelacion) ?? "",
        }}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};

          const fechaActual = new Date();

          if (
            !validarFechaMayorMenor(
              values.fechaCancelacion,
              fechaActual,
              "MENOR"
            )
          ) {
            errors.fechaCancelacion = "la fecha no puede ser mayor a la actual";
          }

          return errors;
        }}
        validationSchema={Yup.object({
          observacion: Yup.string().required("Observación requerida"),
          estadoCancelacion: Yup.string().required("Estado requerido"),
          idCausa: Yup.string().required("Causa requerida"),
          idMotivo: Yup.string().required("Motivo requerido"),
          canal: Yup.string().required("Canal requerido"),
          notaCredito: Yup.string().required("Nota Credito requerido"),
        })}
        onSubmit={(values) => {
          configuracionDatosEnvio(values);
        }}
      >
        {({ values, handleChange, touched, errors, handleBlur }) => (
          <Form>
            <div className="accordion-container">
              <div>
                <Accordion
                  defaultExpanded={true}
                  expanded={true}
                  sx={{ boxShadow: 3, lineHeight: 0 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className="accordion-summary"
                    sx={{ margin: 0 }}
                  >
                    <h2 className="formulario-cabecera">Cancelación Seguro</h2>
                  </AccordionSummary>

                  <AccordionDetails sx={{ marginTop: "2rem" }}>
                    <Grid container spacing={1} rowSpacing={1}>
                      <Grid item md={12}>
                        <Grid container spacing={1}>
                          <Grid item md={12}>
                            <div className="cliente-informacion-seccion">
                              <SelectEstadosCancelacion
                                name="estadoCancelacion"
                                labelName="Estado"
                                componentSize="18%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <SelectCausaCancelacion
                                estado={values.estadoCancelacion}
                                name="idCausa"
                                labelName="Causa"
                                componentSize="35%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <SelectMotivoCancelacion
                                causa={values.idCausa}
                                name="idMotivo"
                                labelName="Motivo"
                                componentSize="40%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />
                            </div>

                            <div className="cliente-informacion-seccion">
                              <SelectItemsCatalogoSinPadre
                                pais="ECU"
                                especial="false"
                                catalogos="CNLCANSEG"
                                grupo={1}
                                name="canal"
                                labelName="Canal"
                                componentSize="18%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <TextField
                                id="fechaCancelacion"
                                label="Fecha Cancelacion"
                                type="date"
                                sx={{ width: "15%" }}
                                size="small"
                                onChange={handleChange}
                                value={values.fechaCancelacion}
                                InputLabelProps={{ shrink: true }}
                                error={
                                  touched.fechaCancelacion &&
                                  Boolean(errors.fechaCancelacion)
                                }
                                helperText={
                                  touched.fechaCancelacion &&
                                  errors.fechaCancelacion
                                }
                                onBlur={handleBlur}
                              />

                              <TextField
                                id="notaCredito"
                                name="notaCredito"
                                label="Nota Credito"
                                sx={{ width: "30%" }}
                                value={values.notaCredito}
                                onChange={handleChange}
                                size="small"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                error={
                                  touched.notaCredito &&
                                  Boolean(errors.notaCredito)
                                }
                                helperText={
                                  touched.notaCredito && errors.notaCredito
                                }
                                onBlur={handleBlur}
                              />

                              <ValorNotaDevolicion idCausa={values.idCausa} />
                            </div>

                            <div className="cliente-informacion-seccion">
                              <TextField
                                id="observacion"
                                name="observacion"
                                label="Observación"
                                sx={{ width: "98%" }}
                                type="text"
                                value={values.observacion}
                                onChange={handleChange}
                                size="small"
                                inputProps={{
                                  style: {
                                    textTransform: "uppercase",
                                  },
                                }}
                                error={
                                  touched.observacion &&
                                  Boolean(errors.observacion)
                                }
                                helperText={
                                  touched.observacion && errors.observacion
                                }
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <div className="confirmar-estados-servicios">
                          <SCSLoadingButton
                            loading={loading}
                            titulo="GUARDAR"
                          />

                          {datosEnviadosCorrectamente && (
                            <AcceptServiceresponse />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ModalAlertaNotificacionEstandar
        setOpen={setOpen}
        open={open}
        titulo="Cancelación de Seguro"
        mensaje="Seguro cancelado con exito !!"
      />
    </>
  );
};

export default FormCancelacion;
