import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import ReferenciasCliente from "./ReferenciasCliente/ReferenciasCliente";

const ClienteReferencias = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <>
      <h2 className="titulosPrincipales-container">Cliente - Referencias</h2>
      <ReferenciasCliente />
    </>
  );
};

export default ClienteReferencias;
