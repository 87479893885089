import React from "react";
import { Button } from "@mui/material";
import DetalleGestionParametros from "../../containers/DetalleGestionParametros";
import { useState } from "react";
import BotonGenericoSalir from "app/modules/shared/containers/BotonGenericoSalir";

const GestionParametrosCobranza = () => {
  const [openModal, setOpenModal] = useState({ data: 0, state: false });

  const openPeriodo1 = () => {
    setOpenModal((state) => ({ ...state, data: 1, state: true }));
  };

  const openPeriodo2 = () => {
    setOpenModal((state) => ({ ...state, data: 2, state: true }));
  };

  return (
    <>
      <BotonGenericoSalir />
      <div className="parametrosCobranza-container">
        <Button
          variant="contained"
          color={
            openModal.state && openModal.data === 1 ? "success" : "primary"
          } //"primary"
          size=""
          sx={{ width: "27%" }}
          onClick={openPeriodo1}
        >
          Ciclo 15
        </Button>

        <Button
          variant="contained"
          color={
            openModal.state && openModal.data === 2 ? "success" : "primary"
          }
          size=""
          sx={{ width: "27%" }}
          onClick={openPeriodo2}
        >
          Ciclo 30
        </Button>
      </div>

      {openModal.state && <DetalleGestionParametros valores={openModal} />}
    </>
  );
};

export default GestionParametrosCobranza;
