import React, { useEffect, useState } from "react";
import { useFormikContext, Formik, Form, Field, useField } from "formik";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Alert,
  Button,
  Grid,
  Stack,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Popover,
  Snackbar,
} from "@mui/material";

import * as Yup from "yup";

import Accordion from "@mui/material/Accordion";
import InputLabel from "@mui/material/InputLabel";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Select from "@mui/material/Select";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  obtenerLocal,
  obtenerParametroGrupo,
  obtenerRegistroGrupo,
} from "../../app-configuracion/services/configuracionServices";
import {
  grabarDocumento,
  obtenerNumeroSolicitud,
  grabarSolicitud,
  verificarSolicitudExistente,
  obtenerSolicitudPreevaluacion,
  obtenerCupoPreaprobado,
  obtenerCabeceraCliente,
  obtenerFlujo,
  notificarMensajeBienvenida,
  obtenerFlujosxGrupo,
} from "../services/creditoServices";

import "../styles/main.css";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { LoadingButton } from "@mui/lab";
import {
  grabarPersonaCorporativo,
  consultaInfoDemografica,
  registrarPersonaCf,
  grabarDocumentoFirmadoCF,
  obtenerRegistroArchivo,
} from "app/modules/app-cliente/services/clienteService";
import {
  grabarAceptacion,
  obtenerAceptacion,
} from "app/modules/app-evaluacion/services/evaluacionServices";
import InfoPopoverButton from "app/modules/shared/components/InfoPopoverButton";
import BotonCerrar from "./BotonCerrar";
import {
  consultarColaborador,
  consultarFechaSistema,
} from "app/modules/siniestros/Services/siniestrosService";
import {
  SCSGenericButton,
  SCSLoadingButton,
} from "app/modules/common/components/Buttons/SCSButtons";
import ModalCalificacion from "./ModalCalificacion";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { useOnLogout } from "app/modules/app-auth/hook";
import InfoPopoverHover from "app/modules/shared/components/InfoPopoverHover";

const FormInfoSolicitud = ({
  idCli,
  cliente,
  identifCliente,
  idSolicitudChanger,
  setInfoGrabada,
  esRegistro,
}) => {
  const { authUser } = useSelector(({ auth }) => auth);

  const [solicitudActual, setSolicitudActual] = useState(null);
  const [docBase64, setDocBase64] = useState("");
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [alertaGuardar, setAlertaGuardar] = useState(false);
  const [alertaDocumento, setAlertaDocumento] = useState(false);
  const [alertaSubirDocumento, setAlertaSubirDocumento] = useState(false);
  const [displayButtonCargar, setDisplayButtonCargar] = useState(false);

  const [montoMinimo, setMontoMinimo] = useState();
  const [montoMaximo, setMontoMaximo] = useState();
  const [local, setLocal] = useState();
  const [montoPreaprobado, setMontoPreaprobado] = useState();
  const [loading, setLoading] = useState(false);
  const [mostrarEspera, setMostrarEspera] = useState(false);
  const [idSolicitudGrabada, setidSolicitudGrabada] = useState(null);

  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);

  const [seleccionadoSegDesravamen, setSeleccionadoSegDesgravamen] =
    useState(true);
  const [seleccionadoSegGarantia, setSeleccionadoSegGarantia] = useState(true);
  const [seleccionadoSegCompras, setSeleccionadoSegCompras] = useState(true);
  const [archivoFirmaFoto, setArchivoFirmaFoto] = useState(null);
  const [openCalificacion, setOpenCalificacion] = useState(false);
  const [openAlertaFirmaFoto, setOpenAlertaFirmaFoto] = useState(false);
  const [datosPersonaCorp, setDatosPersonaCorp] = useState(false);
  const [openImagenFinal, setOpenImagenFinal] = useState(false);
  const history = useHistory();
  const [sendLogout, setSendLogout] = useState(false);
  const [userCode, setUserCode] = useState(false);

  useOnLogout(userCode, sendLogout, setSendLogout);

  useEffect(() => {
    //console.log("REGISTRO",esRegistro);
    setUserCode(authUser.key);
    //console.log(authUser);
    const getLocal = async () => {
      const idLocal = authUser.oficina;
      try {
        const response = await obtenerLocal(idLocal);
        if (response.state) {
          if (response.data == null)
            console.log("Error al obtener local", response.message);
          else setLocal(response.data);
        } else {
          console.log("Error al obtener local", response.message);
        }
      } catch (_errors) {
        console.log(_errors);
      }
    };

    const getPreaprobado = async () => {
      try {
        const response = await obtenerCupoPreaprobado(identifCliente);
        if (response.state) {
          if (response.data > 0) {
            setMontoPreaprobado(response.data);
          } else {
            setMontoPreaprobado(0);
          }
        } else {
          console.log("Error al obtener preparobado", response.message);
        }
      } catch (_errors) {
        console.log(_errors);
      }
    };

    const getSolicitud = async () => {
      let infoSolicitud;
      try {
        const solicit = await obtenerSolicitudPreevaluacion(
          identifCliente,
          authUser.usuarioFlujos[0].idGrupo
        );
        if (solicit.state) {
          if (solicit.data == null) infoSolicitud = {};
          else {
            if (solicit.data.idEstado === 9) {
              infoSolicitud = solicit.data;
              setSeleccionadoSegDesgravamen(infoSolicitud.segDesgravamen);
              setidSolicitudGrabada(infoSolicitud.idSolicitud);
            } else infoSolicitud = {};
          }
        } else {
          console.log(solicit.message);
        }
      } catch (_errors) {
        console.log(_errors);
      }

      const res = {
        ...infoSolicitud,
      };

      setSolicitudActual(res);
    };

    const enviarPersonaCF = async () => {
      //const idLocal = authUser.oficina;
      const respInfoAceptacion = await obtenerAceptacion(identifCliente);
      if (respInfoAceptacion.state) {
        const infoAceptacion = respInfoAceptacion.data;
        console.log(infoAceptacion.idGrupo);
        if (infoAceptacion.idGrupo === 1) {
          try {
            console.log("Enviando datos CF");
            const response = await obtenerCabeceraCliente(idCli);
            setDatosPersonaCorp(response.data);
            //console.log(response.data);
            const responseinfDem = await consultaInfoDemografica(idCli);

            if (!respInfoAceptacion.data.enviaDatos) {
              const documentosFirmados = [];
              if (infoAceptacion.esCliente) {
                // Esta registrado en CF

                if (!infoAceptacion.documentosFirmados) {
                  if (infoAceptacion.ppr) documentosFirmados.push("PPR");
                  if (infoAceptacion.tcu) documentosFirmados.push("TCD");

                  const documentosEnviados = {
                    documentNumber: identifCliente,
                    typeDocumenteNumber: "CI",
                    typeDocumentLegal: documentosFirmados.toString(),
                    system: "SCS",
                    clientIp: "34.173.105.14",
                  };
                  console.log(documentosEnviados);

                  try {
                    const respSaveLegalDoc = await grabarDocumentoFirmadoCF(
                      documentosEnviados
                    );
                    if (respSaveLegalDoc.state) {
                      if (respSaveLegalDoc.data.response.id === 1) {
                        infoAceptacion.enviaDatos = true;
                        console.log(respSaveLegalDoc);
                      } else {
                        infoAceptacion.enviaDatos = false;
                        console.log(respSaveLegalDoc.data.response.message);
                      }
                    }
                  } catch (e) {
                    alert("Error al grabar archivos firmados TyC" + e);
                  }

                  console.log(documentosFirmados.toString());
                } else {
                  console.log("No firma nada");
                }
              } // No esta registrado en CF
              else {
                try {
                  const infoCabecera = response.data;
                  const infoDemografica = responseinfDem.data;

                  const personaCorporativo = {
                    tipoDocumento: cliente.tipoIdentificacion || "CI",
                    numeroDocumento: identifCliente,
                    primerNombre: infoCabecera.primerNombre,
                    segundoNombre: infoCabecera.segundoNombre,
                    primerApellido: infoCabecera.primerApellido,
                    segundoApellido: infoCabecera.segundoApellido,
                    genero: infoDemografica.genero,
                    userId: "USR1737940",
                    sistemaModificacion: "CRD", //CRD
                    origenDato: "CRD", //CRD
                    contacto: {
                      codigoCompania: 1,
                      codigoTipoContacto: "PCD",
                      numeroTelefonicoCelular: infoCabecera.celular,
                      numeroTelefonicoPrincipal: infoCabecera.telefono,
                      callePrincipalDireccionPrincipal:
                        infoCabecera.callePrincipal,
                      numeroDireccionPrincipal: infoCabecera.numeroCasa,
                      calleTransversalDireccionPrincipal:
                        infoCabecera.calleSecundaria,
                      emailPrincipal: infoCabecera.email,
                      codigoSistema: "CREDIMAX",
                      userId: "USR1737940",
                    },
                  };
                  console.log(personaCorporativo);

                  try {
                    //const respGrabarPerson = await registrarPersonaCf(personaCF);
                    const respGrabarPerson = await grabarPersonaCorporativo(
                      personaCorporativo
                    );

                    console.log(respGrabarPerson);
                    if (respGrabarPerson.data.codigoPersona) {
                      if (!infoAceptacion.documentosFirmados) {
                        if (infoAceptacion.ppr) documentosFirmados.push("PPR");
                        if (infoAceptacion.tcu) documentosFirmados.push("TCD");

                        const documentosEnviados = {
                          documentNumber: identifCliente,
                          typeDocumenteNumber: "CI",
                          typeDocumentLegal: documentosFirmados.toString(),
                          system: "SCS",
                          clientIp: "34.173.105.14",
                        };
                        console.log(documentosEnviados);
                        try {
                          const respSaveLegalDoc =
                            await grabarDocumentoFirmadoCF(documentosEnviados);
                          if (respSaveLegalDoc.state) {
                            if (respSaveLegalDoc.data.response.id === 1) {
                              infoAceptacion.enviaDatos = true;
                              console.log(respSaveLegalDoc);
                            } else {
                              infoAceptacion.enviaDatos = false;
                              console.log(
                                respSaveLegalDoc.data.response.message
                              );
                            }
                          }
                        } catch (e) {
                          infoAceptacion.enviaDatos = false;
                          alert("Error al grabar archivos firmados TyC" + e);
                        }
                      }
                    } else {
                      alert("Error a grabar persona CF");
                    }
                  } catch (e) {
                    alert("Error al resgistrar persona CF" + e);
                  }
                } catch (e) {
                  alert(e);
                }
              }

              const srvGrabarAceptacion = await grabarAceptacion(
                infoAceptacion
              );
              //console.log(srvGrabarAceptacion);
            } else {
              //console.log("No hace nada");
            }
          } catch (_errors) {
            console.log(_errors);
          }
        } else {
          //no hace nada porque no es CF
          console.log("no envia porque no es cf");
        }
      } else {
        //No hace nada
      }
    };

    enviarPersonaCF();
    getArchivosFirmaFoto();
    getLocal();
    getPreaprobado();
    getMontoMaximo();
    getSolicitud();
  }, []);

  const getArchivosFirmaFoto = async () => {
    try {
      const solicit = await obtenerRegistroArchivo(idCli);
      if (solicit.state) {
        setArchivoFirmaFoto(true);
        console.log("Si existe registro de firma/Foto");
      } else {
        console.log("No existe registro de firma/Foto");
        setArchivoFirmaFoto(false);
        setOpenAlertaFirmaFoto(true);
      }
    } catch (_errors) {
      console.log(_errors);
    }
  };

  const fileToBase64 = async (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const getMontoMaximo = async () => {
    obtenerParametroGrupo("MTOMAXCRE", authUser.usuarioFlujos[0].idGrupo)
      .then((response) => {
        if (response.state) {
          //console.log(response.data);
          setMontoMinimo(response.data.valorDesde);
          setMontoMaximo(response.data.valorHasta);
        } else {
          console.log("Error al obtener monto maximo ", response.message);
        }
      })
      .catch((_errors) => {
        console.log(_errors);
      });
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Stack direction="row" alignItems="center" spacing={2}>
        <Select
          className="select-solicitud-local"
          labelId={name}
          name={name}
          value={value}
          label={name}
          size="small"
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
          sx={{ width: 1 / 1 }}
        >
          {children}
        </Select>
      </Stack>
    );
  };

  const changeHandler = async (event) => {
    let doc = "";
    let docByte = [];
    if (event.target.files.length !== 0) {
      setSelectedFile(event.target.files[0]);
      await fileToBase64(event.target.files[0], (err, result) => {
        if (result) {
          doc = result.split(",");
          docByte = base64ToArrayBuffer(doc[1]);
          //console.log(doc[1]);
          setDocBase64(doc[1]);
        }
      });
      setIsSelected(true);
    } else {
      setSelectedFile(null);
    }
  };

  const base64ToArrayBuffer = (base64) => {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const cambioSelectGrav = (event) => {
    setSeleccionadoSegDesgravamen(event.target.checked);
  };
  const cambioSelectSegGarantia = (event) => {
    setSeleccionadoSegGarantia(event.target.checked);
  };
  const cambioSelectSegCompras = (event) => {
    setSeleccionadoSegCompras(event.target.checked);
  };

  const handleCloseEspera = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      //console.log(reason);
      setMostrarEspera(true);
    } else {
      setMostrarEspera(false);
    }
  };

  const handleCloseImagenFinal = async () => {
    if (esRegistro) {
      console.log("ES REGISTRO");
      console.log(authUser);
      let url = "";
      if (authUser.key.split(".")[2] === "cliente") {
        console.log(authUser.key.split[2]);
        console.log("Usuario Cliente");
        setOpenImagenFinal(false);
        const path = "/transaccional/movimientosCliente";
        history.push(path, { state: { identificacion: identifCliente } });
      } else {
        console.log(authUser.key.split(".")[2]);
        console.log("Usuario REGISTRO");
        try {
          const respGrupo = await obtenerRegistroGrupo(
            authUser.usuarioFlujos[0].idGrupo
          );
          if (respGrupo.state) {
            url = respGrupo.data.portal;
          }
        } catch (e) {
          console.log(e);
        }
        setOpenImagenFinal(false);
        setSendLogout(true);
        window.opener = null;
        window.open(url, "_self");
        window.close();
      }
    } else {
      console.log("NO REGISTRO");
      setOpenImagenFinal(false);
      const path = "/initialHome";
      history.push(path);
    }
  };

  const enviarInstructivo = async (tipo) => {
    try {
      const dataSms = {
        IdGrupo: authUser.usuarioFlujos[0].idGrupo,
        CodigoComunicacion: tipo,
        EspacioComunicacion: "SENDGRID",
        TipoComunicacion: "EMAIL",
        Variables: null,
        Destino: datosPersonaCorp.email,
        IdTransaccion: solicitudActual.idSolicitud || 0,
        EsMasivo: false,
        AdjuntoMensaje: null,
      };

      //console.log(dataSms);

      var respBienvenida = await notificarMensajeBienvenida(dataSms);
    } catch (e) {
      console.log(e.message);
    }
  };

  const configuracionDatosEnvio = async (values) => {
    //console.log(authUser);
    if (true) {
      setLoading(true);
      try {
        const numSolicitud = await verificarSolicitudExistente(
          identifCliente,
          authUser.usuarioFlujos[0].idFlujo
        );
        if (numSolicitud.state) {
          if (numSolicitud.data > 0) {
            setAlertaGuardar(true);
            setLoading(false);

            setTimeout(() => {
              setAlertaGuardar(false);
              setMostrarEspera(false);
            }, 2000);
          } else {
            setMostrarEspera(true);
            let idSolicitudAct = solicitudActual.idSolicitud || 0;

            //debugger;
            const respObtenerFlujo = await obtenerFlujo(
              authUser.usuarioFlujos[0].idFlujo
            );

            const esAutoservicio = eval(localStorage.getItem("autoservicio"));
            let localDef,
              codVendedor,
              prefijoFlujo,
              flujosGrupo,
              idFlujoSolicitud,
              userCreacion;
            if (respObtenerFlujo.data.tieneDefault) {
              localDef = respObtenerFlujo.data.idLocal;
              codVendedor = respObtenerFlujo.data.idVendedor;

              var respFlujosGrupo = await obtenerFlujosxGrupo(
                authUser.usuarioFlujos[0].idGrupo
              );
              if (respFlujosGrupo.state) {
                //console.log(respFlujosGrupo.data);
                flujosGrupo = respFlujosGrupo.data;
                let flujo;

                if (esAutoservicio) {
                  flujo = flujosGrupo.find((item) =>
                    item.prefijoFlujo.includes("AS")
                  );
                  userCreacion = localStorage.getItem("nombreMaquina");
                  localDef = parseInt(
                    userCreacion.substring(userCreacion.length - 4)
                  );
                } else {
                  flujo = flujosGrupo.find((item) =>
                    item.prefijoFlujo.includes("LP")
                  );
                  userCreacion = authUser.key;
                }
                //console.log(flujo);

                prefijoFlujo = flujo.prefijoFlujo;
                idFlujoSolicitud = flujo.idFlujo;
              } else {
                console.log(respFlujosGrupo.message);
              }
            } else {
              localDef = solicitudActual.idLocal || local.codigo;
              codVendedor = values.idVendedor;
              prefijoFlujo = respObtenerFlujo.data.prefijoFlujo;
              idFlujoSolicitud = respObtenerFlujo.data.idFlujo;
              userCreacion = authUser.key;
            }

            //let prefijoFlujo = localStorage.getItem("autoservicio")? ""
            const numSolicitud = await obtenerNumeroSolicitud(
              prefijoFlujo,
              local.codigo
            );
            //console.log(numSolicitud.data);
            const tipoCli = montoPreaprobado > 0 ? "CLTE02" : "CLTE01";

            //const today = new Date().toLocaleString("en-US");
            let today = null;
            const srvFecha = await consultarFechaSistema();
            if (srvFecha.state) {
              today = srvFecha.data.fechaHora;
            } else {
              today = new Date().toLocaleString("en-US");
            }
            const infoSolicitud = {
              idSolicitud: idSolicitudAct,
              idCliente: solicitudActual.idCliente || idCli,
              tipoIdentificacion: solicitudActual.tipoIdentificacion || "CI",
              identificacion: solicitudActual.identificacion || identifCliente,
              numeroSolicitud:
                solicitudActual.numeroSolicitud || numSolicitud.data,
              idLocal: localDef,
              idFlujo: solicitudActual.idFlujo || idFlujoSolicitud,
              idEstado: 2,
              idVendedor: codVendedor,
              codigoBarras: values.codigoBarras,
              montoSolicitado: values.montoSolicitado,
              tipoCliente: solicitudActual.tipoCliente || tipoCli,
              fechaCorte: values.fechaCorte,
              fechaSolicitud: today,
              esActivo: true,
              migracion: solicitudActual.migracion || false,
              segDesgravamen: seleccionadoSegDesravamen,
              segGarantia: seleccionadoSegGarantia,
              segCompras: false,
              fechaCreacion: solicitudActual.fechaCreacion || today,
              usuarioCreacion: solicitudActual.usuarioCreacion || authUser.key,
              estacionCreacion:
                solicitudActual.estacionCreacion || authUser.estacion,
              fechaModificacion: today,
              usuarioModificacion: authUser.key,
              estacionModificacion: authUser.estacion,
            };
            console.log(infoSolicitud);
            try {
              console.log("Envio de peticiones al POST");

              const respGrabarSolicitud = await grabarSolicitud(infoSolicitud);
              idSolicitudAct = respGrabarSolicitud;
              idSolicitudChanger(idSolicitudAct.data);
              setidSolicitudGrabada(idSolicitudAct.data);

              //console.log(idSolicitudAct.data);
              setDatosEnviadosCorrectamente(true);
              setMostrarEspera(false);
              setLoading(false);
              setOpenCalificacion(true);
              try {
                const dataSms = {
                  CodigoComunicacion: "MSJBNV",
                  EspacioComunicacion: "COMERCIAL",
                  TipoComunicacion: "SMS",
                  IdGrupo: authUser.usuarioFlujos[0].idGrupo,
                  Variables: datosPersonaCorp.primerNombre,
                  Destino: datosPersonaCorp.celular,
                  IdTransaccion: idSolicitudAct.data,
                };

                //console.log(dataSms);

                var respBienvenida = await notificarMensajeBienvenida(dataSms);
              } catch (e) {
                console.log(e.message);
              }

              if (setInfoGrabada) {
                setInfoGrabada((args) => ({
                  ...args,
                  solicitud: true,
                }));
              }

              setTimeout(() => {
                setDatosEnviadosCorrectamente(false);
              }, 1000);
            } catch (error) {
              setMostrarEspera(false);
              console.log(error);
            }

            if (selectedFile) {
              await enviarDocumento(idSolicitudAct.data);
            } else {
              setDatosEnviadosCorrectamente(true);
              setMostrarEspera(false);

              setTimeout(() => {
                setDatosEnviadosCorrectamente(false);
                setLoading(false);
                setDisplayButtonCargar(false);
              }, 3000);
            }
          }
        } else {
          console.log(numSolicitud.message);
        }
      } catch (err) {
        console.log(err.message);
      }
    } else {
      setAlertaDocumento(true);
      setLoading(false);
      setMostrarEspera(false);

      setTimeout(() => {
        setAlertaDocumento(false);
      }, 5000);
    }
    //setLoading(false);
  };

  const enviarDocumento = async (id) => {
    console.log(idSolicitudGrabada);
    setLoading(true);
    try {
      setMostrarEspera(true);
      let formatTwoDigits = (digit) => ("0" + digit).slice(-2);
      var tempDate = new Date();
      var date = `${tempDate.getFullYear()}${formatTwoDigits(
        tempDate.getMonth() + 1
      )}${formatTwoDigits(tempDate.getDate())}`;

      const documento = {
        idSolicitud: id, //id solicitud
        origen: "FL",
        imgDocumento: docBase64,
        nombreDocumento: `SLC_${id}_${date}.pdf`,
        usuarioCreacion: authUser.key,
        estacionCreacion: authUser.estacion,
        usuarioModificacion: authUser.key,

        estacionModificacion: authUser.estacion,
      };
      console.log(documento);

      try {
        console.log("Envio de documento a base");

        var resp = await grabarDocumento(documento);
        if (resp.state) {
          setDatosEnviadosCorrectamente(true);
          setMostrarEspera(false);

          setTimeout(() => {
            setDatosEnviadosCorrectamente(false);
            setLoading(false);
            setDisplayButtonCargar(false);
          }, 3000);
        } else {
          setAlertaSubirDocumento(true);
          setDisplayButtonCargar(true);
          setMostrarEspera(false);

          setTimeout(() => {
            setAlertaSubirDocumento(false);
          }, 3000);
        }
      } catch (error) {
        setMostrarEspera(false);
        console.log(error);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }

    setLoading(false);
  };

  const handleCloseRefreshArchivos = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlertaFirmaFoto(false);
  };
  if (!archivoFirmaFoto) {
    return (
      <>
        <Alert severity="error" marginTop={2}>
          <Typography>
            Para continuar debe completar la Seccion - Firma/Fotografia enviado
            mediante Link a su Celular Registrado.
          </Typography>
          <Typography>-Firma</Typography>
          <Typography>-Fotografia</Typography>
        </Alert>
        <Stack
          mt={2}
          direction={{ xs: "column", sm: "row" }}
          spacing={3}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Alert severity="info" marginTop={2}>
            <Typography>
              Si ya realizaste el proceso de captura de firma en tu celular, da
              click en el botón CONTINUAR para seguir el proceso.
            </Typography>
          </Alert>
          <SCSGenericButton
            titulo={"CONTINUAR"}
            size={25}
            onClick={getArchivosFirmaFoto}
          />
          <Snackbar
            open={openAlertaFirmaFoto}
            autoHideDuration={3000}
            onClose={handleCloseRefreshArchivos}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              variant="filled"
              onClose={handleCloseRefreshArchivos}
              severity="error"
              sx={{ width: "100%" }}
            >
              Firma y Foto incompletos
            </Alert>
          </Snackbar>
        </Stack>
      </>
    );
  }

  return (
    <>
      {solicitudActual ? (
        <>
          {/* <BotonCerrar /> */}
          <Formik
            initialValues={{
              fechaCorte: solicitudActual.fechaCorte || 1,
              codigoBarras: solicitudActual.codigoBarras || "",
              idVendedor: solicitudActual.idVendedor || "",
              montoSolicitado: solicitudActual.montoSolicitado || "",
            }}
            validationSchema={Yup.object({
              idVendedor: Yup.string().matches(
                /^[0-9]+$/,
                "Solo numeros enteros"
              ),
            })}
            onSubmit={(values, { setSubmitting }) => {
              //console.log(values);
              configuracionDatosEnvio(values);
            }}
          >
            {({ values, handleChange, touched, errors, handleBlur }) => (
              <Form>
                <div className="accordion-container">
                  {/* Informacion de solicitud */}
                  <div>
                    <SCSAccordion tituloCabecera="Información de Solicitud">
                      <Stack
                        marginTop={3}
                        direction={{ xs: "column", md: "row" }}
                        spacing={{ xs: 3, md: 2 }}
                        justifyContent="space-evenly"
                        alignItems="center"
                      >
                        {!esRegistro &&
                          (local ? (
                            <>
                              <Typography fontWeight="bold" textAlign="left">
                                LOCAL: {local.codigo} - {local.descripcion}
                              </Typography>
                            </>
                          ) : (
                            <SpinnerLoadSrx />
                          ))}
                        {!esRegistro && (
                          <TextField
                            required
                            id="idVendedor"
                            name="idVendedor"
                            type="number"
                            label="Codigo Vendedor"
                            sx={{ width: "15ch" }}
                            value={values.idVendedor}
                            onChange={handleChange}
                            size="small"
                            error={
                              touched.idVendedor && Boolean(errors.idVendedor)
                            }
                            helperText={touched.idVendedor && errors.idVendedor}
                          />
                        )}
                        <TextField
                          required
                          id="montoSolicitado"
                          name="montoSolicitado"
                          type="number"
                          label="Monto Solicitado"
                          sx={{ width: "20ch" }}
                          value={values.montoSolicitado}
                          onChange={handleChange}
                          size="small"
                          InputProps={{
                            inputProps: {
                              min: montoMinimo,
                              max: montoMaximo,
                            },
                          }}
                        />
                        <FormControl>
                          <InputLabel id="fechaCorte">
                            Fecha de Corte
                          </InputLabel>
                          <Field
                            name="fechaCorte"
                            label="Fecha de Corte"
                            component={selectPersonalizarPropiedades}
                          >
                            <MenuItem value={1}>
                              Corte 15 - Fecha de pago 16 al 30
                            </MenuItem>
                            <MenuItem value={2}>
                              Corte 30 - Fecha de pago 1 al 15
                            </MenuItem>
                          </Field>
                        </FormControl>
                      </Stack>
                      <Stack
                        marginTop={5}
                        marginBottom={3}
                        direction={{ xs: "column", md: "row" }}
                        spacing={{ xs: 3, md: 2 }}
                        justifyContent="space-evenly"
                        alignItems="center"
                      >
                        <Stack direction="row" alignItems="center">
                          <InfoPopoverHover nombre={"SD"} titulo={"Beneficios"}>
                            <Stack
                              alignItems={"center"}
                              justifyContent={"center"}
                              direction={"column"}
                              mb={2}
                            >
                              <Box
                                component="img"
                                src={`${process.env.REACT_APP_IMAGES_PATH}/pf-${authUser.usuarioFlujos[0].idGrupo}.png`}
                                sx={{
                                  justifyContent: "center",
                                  width: 700,
                                  height: 600,
                                }}
                              />
                              <SCSGenericButton
                                size={25}
                                titulo="Envíar Instructivo"
                                onClick={() => {
                                  //console.log("OK");
                                  enviarInstructivo("INFDESG");
                                }}
                                //  hidden={valueCli===5?true:false}
                                //disabled={!habilitarSiguiente}
                              />
                            </Stack>
                          </InfoPopoverHover>
                          <FormGroup required>
                            <FormControlLabel
                              name="desgravamen"
                              control={<Checkbox />}
                              label="ACEPTO VOLUNTARIAMENTE CONTRATAR MI PLAN DE COBERTURA DE VIDA Y DESGRAVAMEN"
                              labelPlacement="start"
                              checked={seleccionadoSegDesravamen}
                              onChange={cambioSelectGrav}
                            />
                          </FormGroup>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <InfoPopoverHover nombre={"GT"} titulo={"Beneficios"}>
                            <Stack
                              alignItems={"center"}
                              justifyContent={"center"}
                              direction={"column"}
                              mb={2}
                            >
                              <Box
                                component="img"
                                src={`${process.env.REACT_APP_IMAGES_PATH}/gt-${authUser.usuarioFlujos[0].idGrupo}.png`}
                                sx={{
                                  justifyContent: "center",
                                  width: 700,
                                  height: 600,
                                }}
                              />
                              <SCSGenericButton
                                size={25}
                                titulo="Envíar Instructivo"
                                onClick={() => {
                                  //console.log("OK");
                                  enviarInstructivo("INFGART");
                                }}
                                //  hidden={valueCli===5?true:false}
                                //disabled={!habilitarSiguiente}
                              />
                            </Stack>
                          </InfoPopoverHover>
                          {/* <InfoPopoverButton
                            nombre={"GT"}
                            texto={`<p>&nbsp;<strong>PROTECCI&Oacute;N PARA TUS EQUIPOS ELECTR&Oacute;NICOS </strong></p>
                            <p><strong>&iexcl;Si algo le llegara a pasar a tus equipos electr&oacute;nicos no te preocupes cuentas con protecci&oacute;n al 100%!</strong></p>
                            <p><strong>&iexcl;Esta es la mejor alternativa para que cuides tu inversi&oacute;n y tu deuda siempre est&eacute; protegida!</strong></p>
                            <p>Al tomar esta opci&oacute;n todos los equipos electr&oacute;nicos que compres con Cr&eacute;dito Directo,</p>
                            <p>contar&aacute;n desde el d&iacute;a de tu compra hasta por <strong>24 meses</strong> con protecci&oacute;n ante los siguientes eventos:</p>
                            <ul>
                            <li>Robo y/o asalto con violencia</li>
                            <li>Da&ntilde;os por variaci&oacute;n de voltaje</li>
                            <li>Da&ntilde;os por incendio y/o rayo</li>
                            <li>Da&ntilde;os Accidentales ocasionados por ca&iacute;das al suelo y/o golpes durante los 30 primeros d&iacute;as a partir de la fecha de compra.</li>
                            <li>Da&ntilde;os a consecuencia de eventos cubiertos por la garant&iacute;a original del fabricante.</li>
                            </ul>
                            <p>&nbsp;El valor de este beneficio es del <strong>15% del valor del art&iacute;culo</strong>, el cual podr&aacute;s pagar c&oacute;modamente en cuotitas por el plazo de tu cr&eacute;dito.</p>`}
                          /> */}
                          <FormGroup required>
                            <FormControlLabel
                              name="segGarantia"
                              control={<Checkbox />}
                              label="ACEPTO VOLUNTARIAMENTE CONTRATAR MI PROTECCIÓN DE EQUIPO ELECTRONICO "
                              labelPlacement="start"
                              checked={seleccionadoSegGarantia}
                              onChange={cambioSelectSegGarantia}
                            />
                          </FormGroup>
                        </Stack>
                        {/* <Stack direction="row" alignItems="center">
                          <InfoPopoverButton
                            nombre={"Asist"}
                            texto={"Texto de asistencia desde componente"}
                          />
                          <FormGroup required>
                            <FormControlLabel
                              name="segCompras"
                              control={<Checkbox />}
                              label="Deseo contratar mi asistencia de protección de compras"
                              labelPlacement="start"
                              checked={seleccionadoSegCompras}
                              onChange={cambioSelectSegCompras}
                            />
                          </FormGroup>
                        </Stack> */}
                      </Stack>
                    </SCSAccordion>
                  </div>
                  {/* Documento PDF */}
                  <div>
                    <SCSAccordion tituloCabecera="Documentacion Adicional">
                      <Grid container justifyContent="center">
                        <Grid item xl={12}>
                          <Grid
                            container
                            spacing={15}
                            rowSpacing={4}
                            justifyContent="center"
                          >
                            <Grid item xl={12}>
                              <Button variant="contained" component="label">
                                Seleccionar Archivo
                                <input
                                  hidden
                                  type="file"
                                  accept="application/pdf"
                                  name="documento"
                                  onChange={changeHandler}
                                />
                              </Button>
                            </Grid>
                            <Grid item lg={12}>
                              {isSelected ? (
                                <div>
                                  <Grid item lg={4} spacing={5}>
                                    <p>
                                      Nombre de archivo:{" "}
                                      {selectedFile ? selectedFile.name : ""}
                                    </p>
                                    <p>
                                      Tipo:{" "}
                                      {selectedFile ? selectedFile.type : ""}
                                    </p>
                                  </Grid>
                                </div>
                              ) : (
                                <p>Suba el documento digitalizado </p>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Stack
                        sx={{ marginTop: "1.5ch" }}
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        justifyContent="center"
                      >
                        <SCSLoadingButton
                          //sx={{ width: "25ch" }}
                          //type="submit"
                          size={25}
                          loading={loading}
                          loadingPosition="end"
                          variant="contained"
                          disabled={displayButtonCargar}
                          titulo={"Guardar"}
                        />
                        {displayButtonCargar ? (
                          <LoadingButton
                            sx={{ width: "25ch" }}
                            type="button"
                            size="small"
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            disabled={!displayButtonCargar}
                            onClick={() => {
                              enviarDocumento(idSolicitudGrabada);
                            }}
                          >
                            Subir documento
                          </LoadingButton>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </SCSAccordion>
                  </div>

                  {datosEnviadosCorrectamente && (
                    <Alert severity="success" variant="filled">
                      Los datos han sido guardados correctamente.
                    </Alert>
                  )}
                  {alertaGuardar && (
                    <Alert severity="error" variant="filled">
                      Ya existe una solicitud creada.
                    </Alert>
                  )}
                  {alertaDocumento && (
                    <Alert severity="error" variant="filled">
                      Debe subir documento digitalizado.
                    </Alert>
                  )}
                  {alertaSubirDocumento && (
                    <Alert severity="error" variant="filled">
                      Error al subir documento, intente de nuevo.
                    </Alert>
                  )}
                </div>
              </Form>
            )}
          </Formik>
          <Dialog
            onClose={handleCloseEspera}
            aria-labelledby="customized-dialog-title"
            open={mostrarEspera}
            fullWidth
          >
            <DialogTitle id="customized-dialog-title">
              Generando Solicitud
            </DialogTitle>
            <DialogContent dividers>
              <h3>
                Por favor espere mientras se graba la informacion de la
                Solicitud.
              </h3>
            </DialogContent>
            <DialogActions> </DialogActions>
          </Dialog>
          <ModalCalificacion
            idCliente={idCli}
            open={openCalificacion}
            setOpenCalificacion={setOpenCalificacion}
            setOpenImagenFinal={setOpenImagenFinal}
          />
          <Dialog
            open={openImagenFinal}
            onClose={handleCloseImagenFinal}
            fullWidth={true}
            maxWidth={"lg"}
          >
            <DialogContent>
              <Box
                component="img"
                src={`${process.env.REACT_APP_IMAGES_PATH}/solicitudFinal-${authUser.usuarioFlujos[0].idGrupo}.png`}
                sx={{
                  justifyContent: "center",
                  width: 941,
                  height: 529,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseImagenFinal}>Cerrar</Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <SpinnerLoadSrx />
      )}
    </>
  );
};

export default FormInfoSolicitud;
