import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { Grid, TextField } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import { crearPagoManualSeguro } from "../services/SeguroService/seguroService";

import { validarFechaMayorMenor } from "app/modules/shared/validations/validaciones";
import { LoadingButton } from "@mui/lab";

import { NumberFormatCustom } from "app/modules/shared/mask/mascaraInputDinero";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

import "../styles/main.css";
import ListaPagosSeguro from "../containers/ListaPagosSeguro";

const FormPagoRecurrencia = ({ contrato }) => {
  const [listaCuotas, setListaCuotas] = useState({
    stateService: false,
    lista: [],
  });

  const [valorSeguro, setValorSeguro] = useState(contrato.valor);

  const [loading, setLoading] = useState(false);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  useEffect(() => {
    console.log({ contrato });
  }, []);

  const verificarValorCancelar = (valorFormulario) => {
    console.log(valorFormulario);

    if (Number(valorFormulario) === 0) {
      return true;
    }

    if (Number(valorFormulario) % Number(valorSeguro) === 0) {
      return false;
    } else {
      return true;
    }
  };

  const configuracionDatosEnvio = async (valores) => {
    setLoading(true);

    const { key, estacion } = JSON.parse(sessionStorage.getItem("user"));

    const informacionPagos = {
      idContrato: contrato.idContrato,
      fechaPago: valores.fechaPago,
      valorPagado: Number(valores.valorPagado),
      usuario: key,
      estacion: estacion,
    };

    try {
      const respuestaPagoManual = await crearPagoManualSeguro(informacionPagos);

      console.log({ respuestaPagoManual });

      if (respuestaPagoManual.state) {
        setDatosEnviadosCorrectamente(true);
        setLoading(false);

        setListaCuotas((state) => ({
          ...state,
          stateService: true,
          lista: respuestaPagoManual.data,
        }));

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
        }, 3000);
      } else {
        setLoading(false);
        alert(`${respuestaPagoManual.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <>
      <Formik
        initialValues={{ fechaPago: "", valorPagado: "" }}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};

          const fechaActual = new Date();

          if (!validarFechaMayorMenor(values.fechaPago, fechaActual, "MENOR")) {
            errors.fechaPago = "Fecha no puede ser mayor a la actual";
          }

          if (verificarValorCancelar(values.valorPagado)) {
            errors.valorPagado = "Valor no  cuotas completas";
          }

          return errors;
        }}
        validationSchema={Yup.object({
          fechaPago: Yup.string().required("Fecha de Pago  requerido"),
          valorPagado: Yup.string().required("Valor Pagado  requerido"),
        })}
        onSubmit={(values, { resetForm }) => {
          configuracionDatosEnvio(values);
          setTimeout(() => {
            resetForm({ values: "" });
          }, 2000);
        }}
      >
        {({ values, handleChange, touched, errors, handleBlur }) => (
          <Form>
            <Accordion
              defaultExpanded="true"
              sx={{ boxShadow: 3, lineHeight: 0 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="accordion-summary"
                sx={{ margin: 0 }}
              >
                <h2 className="formulario-cabecera">Pago Recurrencia</h2>
              </AccordionSummary>

              <AccordionDetails sx={{ marginTop: "2rem" }}>
                <Grid container spacing={1} rowSpacing={1}>
                  <Grid item md={12}>
                    <Grid container spacing={1}>
                      <Grid item md={12}>
                        <div className="cliente-informacion-seccion">
                          <TextField
                            id="fechaPago"
                            name="fechaPago"
                            type="date"
                            label="Fecha de Pago"
                            sx={{ width: "20%" }}
                            onChange={handleChange}
                            size="small"
                            value={values.fechaPago}
                            InputLabelProps={{ shrink: true }}
                            error={
                              touched.fechaPago && Boolean(errors.fechaPago)
                            }
                            helperText={touched.fechaPago && errors.fechaPago}
                            onBlur={handleBlur}
                          />

                          <TextField
                            id="valorPagado"
                            name="valorPagado"
                            label="Valor Pagado"
                            sx={{ width: "21%" }}
                            value={values.valorPagado}
                            onChange={handleChange}
                            size="small"
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                            error={
                              touched.valorPagado && Boolean(errors.valorPagado)
                            }
                            helperText={
                              touched.valorPagado && errors.valorPagado
                            }
                            onBlur={handleBlur}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <div className="confirmar-estados-servicios">
                      <LoadingButton
                        sx={{ width: "20%" }}
                        type="submit"
                        size="small"
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        startIcon={<AttachMoneyIcon />}
                      >
                        Pagar
                      </LoadingButton>

                      {datosEnviadosCorrectamente && <AcceptServiceresponse />}
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Form>
        )}
      </Formik>

      {listaCuotas.stateService && (
        <ListaPagosSeguro listaCuotas={listaCuotas.lista} />
      )}
    </>
  );
};

export default FormPagoRecurrencia;
