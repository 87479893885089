import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { consultaContacto } from "app/modules/app-cliente/services/clienteService";
import { Button, Toolbar } from "@mui/material";
import { consultarLlamadaUsuario } from "../services/cobranzaServices";
import BackdroLlamadaUsuario from "./BackdroLlamadaUsuario";

const ListaContactosClientes = ({
  idCliente,
  setEstadoContacto,
  contacto,
  informacionCobranza,
}) => {
  const [clienteContacto, setClienteContacto] = useState([]);
  const [isLoadingLlamada, setIsLoadingLlamada] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getContactoCliente = async () => {
      let contactoClienteDomicilio;
      let contactoClienteLabolal;
      let contactoClienteOtros;
      let contactoClienteCobranza;

      try {
        const respuestaInformacionCliente = await consultaContacto(
          idCliente,
          "DIRDOM"
        );

        /* console.log(respuestaInformacionCliente); */

        if (respuestaInformacionCliente.state) {
          /* setClienteContacto(respuestaInformacionCliente.data); */
          contactoClienteDomicilio = respuestaInformacionCliente.data;
        } else {
          /* setClienteContacto({}); */
          contactoClienteDomicilio = {
            idContacto: 0,
            idCliente: idCliente,
            tipoDireccion: "DIRDOM",
            esPersonal: "",
            telefono: "",
            telefono2: "",
            celular: "",
            email: "",
            extension: "",
            esActivo: "",
          };
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }

      try {
        const respuestaInformacionCliente = await consultaContacto(
          idCliente,
          "DIRLAB"
        );

        /* console.log(respuestaInformacionCliente); */

        if (respuestaInformacionCliente.state) {
          /* setClienteContacto(respuestaInformacionCliente.data); */
          contactoClienteLabolal = respuestaInformacionCliente.data;
        } else {
          contactoClienteLabolal = {
            idContacto: 0,
            idCliente: idCliente,
            tipoDireccion: "DIRLAB",
            esPersonal: "",
            telefono: "",
            telefono2: "",
            celular: "",
            email: "",
            extension: "",
            esActivo: "",
          };
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }

      try {
        const respuestaInformacionCliente = await consultaContacto(
          idCliente,
          "DIROTR"
        );

        /* console.log(respuestaInformacionCliente); */

        if (respuestaInformacionCliente.state) {
          /* setClienteContacto(respuestaInformacionCliente.data); */
          contactoClienteOtros = respuestaInformacionCliente.data;
        } else {
          contactoClienteOtros = {
            idContacto: 0,
            idCliente: idCliente,
            tipoDireccion: "DIROTR",
            esPersonal: "",
            telefono: "",
            telefono2: "",
            celular: "",
            email: "",
            extension: "",
            esActivo: "",
          };
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }

      try {
        const respuestaInformacionCliente = await consultaContacto(
          idCliente,
          "DIRGES"
        );

        /* console.log(respuestaInformacionCliente); */

        if (respuestaInformacionCliente.state) {
          /* setClienteContacto(respuestaInformacionCliente.data); */
          contactoClienteCobranza = respuestaInformacionCliente.data;
        } else {
          contactoClienteCobranza = {
            idContacto: 0,
            idCliente: idCliente,
            tipoDireccion: "DIRGES",
            esPersonal: "",
            telefono: "",
            telefono2: "",
            celular: "",
            email: "",
            extension: "",
            esActivo: "",
          };
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }

      const listaAux = [
        contactoClienteDomicilio,
        contactoClienteLabolal,
        contactoClienteOtros,
        contactoClienteCobranza,
      ];

      /* console.log(listaAux); */

      setClienteContacto(listaAux);
    };

    getContactoCliente();
  }, [contacto.state, idCliente]);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const diccionarioTipoDireccion = (tipoDireccion) => {
    const tiposDireccion = {
      DIRDOM: "DOMICILIO",
      DIRLAB: "LABORAL",
      DIROTR: "OTROS",
      DIRGES: "GESTIÓN ",
    };

    return tiposDireccion[tipoDireccion];
  };

  const BotonEditarContacto = ({ contacto }) => {
    return (
      <>
        <Button
          variant="contained"
          color="success"
          type="button"
          onClick={() => {
            setEstadoContacto((args) => ({
              ...args,
              state: true,
              contacto: contacto,
            }));
          }}
          size="small"
        >
          Editar
        </Button>
      </>
    );
  };

  const gestionarLlamadaUsuario = async (telefono) => {
    if (!telefono || telefono === "") return;

    setIsLoadingLlamada(true);

    try {
      const respuestaLlamadaUsuario = await consultarLlamadaUsuario(
        informacionCobranza.idUsuario,
        telefono
      );

      console.log({ respuestaLlamadaUsuario });

      if (respuestaLlamadaUsuario.state) {
        setIsLoadingLlamada(false);
      } else {
        setIsLoadingLlamada(false);
      }
    } catch (error) {
      setIsLoadingLlamada(false);
      console.log(error);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Toolbar sx={{ height: 30 }}>
          {/* <Typography variant="h4">Lista Contactos</Typography> */}

          <h4>Lista Contactos</h4>
        </Toolbar>

        <Table
          size="small"
          sx={{ minWidth: 650, padding: 5 }} /*  aria-label="simple table" */
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Tipo Dirección</TableCell>
              <TableCell align="center">Teléfono</TableCell>
              <TableCell align="center">Celular</TableCell>
              <TableCell align="center">Celular 2</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clienteContacto.map((contacto, index) => (
              <TableRow
                key={index}
                /*  sx={{ "&:last-child td, &:last-child th": { border: 1 } }} */
              >
                <TableCell component="th" scope="row">
                  {diccionarioTipoDireccion(contacto.tipoDireccion)}
                </TableCell>
                <TableCell
                  className="gestionllamada-contacto"
                  onClick={() => gestionarLlamadaUsuario(contacto.telefono)}
                >
                  {contacto.telefono}
                </TableCell>
                <TableCell
                  align="center"
                  className="gestionllamada-contacto"
                  onClick={() => gestionarLlamadaUsuario(contacto.celular)}
                >
                  {contacto.celular}
                </TableCell>
                <TableCell
                  align="center"
                  className="gestionllamada-contacto"
                  onClick={() => gestionarLlamadaUsuario(contacto.telefono2)}
                >
                  {contacto.telefono2}
                </TableCell>
                <TableCell align="center">{contacto.email}</TableCell>
                <TableCell align="center">
                  <BotonEditarContacto contacto={contacto} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <BackdroLlamadaUsuario isLoadingLlamada={isLoadingLlamada} />
    </>
  );
};

export default ListaContactosClientes;
