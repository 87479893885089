import React from 'react';
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUT_NAMES} from "../../../layouts/layouts";

import MasterRol from "../Roles/RolesContainers/MasterRoles"

const Roles = () => {
  const {setActiveLayout} = useJumboApp();

  React.useEffect(() => {
      setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);

  return (
    <div>
      <h2>Administrador de Perfil</h2>
      <MasterRol/>
    </div>
  );
};

export default Roles;
