import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import HeaderInformacionCliente from "../../shared/containers/HeaderInformacionCliente";

import FormInformacionCliente from "../../app-cliente/components/FormInformacionCliente";
import FormSiniestro from "../components/FormSiniestro";
import FormProducto from "../components/FormProducto";
import FormSiniestroNotificacion from "../components/FormSiniestroNotificacion";
import BotonSalirLimpiarStorage from "./BotonSalirLimpiarStorage";
import FormInformacionAdicional from "../components/FormInformacionAdicional";
import HeaderInformacionProductoContrato from "./HeaderInformacionProductoContrato";

const SiniestroProductoFlujo = () => {
  const history = useHistory();

  const idContrato = history.location.state.state.contrato;
  const idCliente = history.location.state.state.cliente;
  const idGrupoSiniestro = history.location.state.state.idGrupoSiniestro;

  const [idClienteInformacion, setIdClienteInformacion] = useState(null);
  const [idFlujo, setIdFlujo] = useState(-1);
  const [idSiniestro, setIdSiniestro] = useState(-1);
  const [idProductoAux, setIdProductoAux] = useState(null);

  return (
    <>
      <BotonSalirLimpiarStorage />
      <HeaderInformacionCliente idCliente={idCliente} />
      <HeaderInformacionProductoContrato idContrato={idContrato} />

      <div className="accordion-container">
        <FormInformacionCliente
          idCliente={idCliente}
          setIdClienteInformacion={setIdClienteInformacion}
        />

        <FormSiniestro
          idCliente={idCliente}
          idContrato={idContrato}
          setIdFlujo={setIdFlujo}
          setIdSiniestro={setIdSiniestro}
          idGrupo={idGrupoSiniestro}
        />

        {idFlujo > 0 && idSiniestro > 0 && (
          <>
            {idFlujo === 5 || idFlujo === 6 ? (
              <FormInformacionAdicional
                idCliente={idCliente}
                idFlujo={idFlujo}
                idSiniestro={idSiniestro}
                setIdProductoAux={setIdProductoAux}
              />
            ) : (
              <FormProducto
                idSiniestro={idSiniestro}
                setIdProductoAux={setIdProductoAux}
              />
            )}

            {idProductoAux && (
              <FormSiniestroNotificacion
                idSiniestro={idSiniestro}
                idCliente={idCliente}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SiniestroProductoFlujo;
