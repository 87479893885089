import React, { useEffect, useState } from "react";

import FormClienteReferencias from "../../components/FormClienteReferencias";
import BuscadorPorIdentificador from "../../containers/BuscadorPorIdentificador";
import useClienteLocalStorage from "../../hook/useClienteLocalStorage";

import { consultaCliente } from "../../services/clienteService";

const ReferenciasCliente = () => {
  const [cliente, setCliente] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [mostrarDetalle, setMostrarDetalle] = useState(false);
  const [idClienteStorage, setIdClienteStorage] = useClienteLocalStorage(
    "idClienteStorage",
    null
  );

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  useEffect(() => {
    if (idClienteStorage) {
      setIdCliente(idClienteStorage);
    }
  }, []);

  const buscarExistenciaCliente = async (identificador) => {
    try {
      const respuestaCliente = await consultaCliente(identificador);

      if (respuestaCliente.state) {
        setIdCliente(respuestaCliente.data.idCliente);
        setIdClienteStorage(respuestaCliente.data.idCliente);
        setCliente(respuestaCliente.data);
      } else {
        setCliente(nuevoCliente);
        setIdCliente(nuevoIdCliente);
        setMostrarDetalle(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdCliente = (id) => () => {
    /*   console.log(id); */
    buscarExistenciaCliente(id);
  };

  if (mostrarDetalle) {
    return <h1>Cliente NO existe. Primero cree el Cliente.!!</h1>;
  }

  return (
    <>
      {idCliente ? (
        <FormClienteReferencias idCliente={idCliente} cliente={cliente} />
      ) : (
        <div className="gestion-siniestros-container">
          <BuscadorPorIdentificador traerIdCliente={traerIdCliente} />
        </div>
      )}
    </>
  );
};

export default ReferenciasCliente;
