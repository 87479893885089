import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAuthUser,   hideAuthLoader } from '../../../redux/actions/Auth';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions/Common';
import { onLogin, onConnect,  onActualizarOtp  } from "../../app-auth/services/index"

export const  useOnLogin = (send, setSend) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            let email = process.env.REACT_APP_USUARIO_CLIENTE;
            email =  email.replace("{0}",   localStorage.getItem('origen'));
            
            let password = process.env.REACT_APP_PASSWORD_CLIENTE;
            let data1 = await onLogin(email, password);
            if (data1.state) {

                sessionStorage.setItem('user', JSON.stringify(data1.data));
                sessionStorage.setItem('roles', JSON.stringify(data1.data.usuarioRol));
                let roles = data1.data.usuarioRol;
                // if (roles.length === 0) {
                //     dispatch(hideAuthLoader())
                //     dispatch(fetchError("El Usuario no posee ningun perfil para ingresar al sistema"));
                // }
                // else if (roles.length === 1) {
                    let user = data1.data;
                    let rol = roles[0];
                    dispatch(hideAuthLoader())
                    dispatch(fetchStart());
                    let data = await onConnect(rol.idRol, user);
                    if (data.state) {
                        let transactions = data.data.transactions;
                        sessionStorage.setItem('jwtToken', data.data.jwt.accessToken);
                        sessionStorage.setItem('menu', JSON.stringify(transactions));
                        const payload = {
                            userCode: user.key
                          };
                        onActualizarOtp(payload);// enviar otp

                        dispatch(hideAuthLoader())
                        dispatch(fetchSuccess("Ingreso exitoso"));
                        dispatch(setAuthUser(user));

                    } else {
                        dispatch(fetchError(data.message));
                    }
            } 
            else
            {
                dispatch(fetchError(data1.message));
               
                

            }
        }
        if (send)
        fetchData();
}, [dispatch, send, setSend]);
};


export const    useOnLoginRegistro = (send, setSend) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            //debugger;
            let email = process.env.REACT_APP_USUARIO_REGISTRO;
            email =  email.replace("{0}",   localStorage.getItem('origen'));
        
            let password = process.env.REACT_APP_PASSWORD_REGISTRO;
            let data1 = await onLogin(email, password);
            if (data1.state) {

                sessionStorage.setItem('user', JSON.stringify(data1.data));
                sessionStorage.setItem('roles', JSON.stringify(data1.data.usuarioRol));
                let roles = data1.data.usuarioRol;
                // if (roles.length === 0) {
                //     dispatch(hideAuthLoader())
                //     dispatch(fetchError("El Usuario no posee ningun perfil para ingresar al sistema"));
                // }
                // else if (roles.length === 1) {
                    let user = data1.data;
                    let rol = roles[0];
                    dispatch(hideAuthLoader())
                    dispatch(fetchStart());
                    let data = await onConnect(rol.idRol, user);
                    if (data.state) {
                        let transactions = data.data.transactions;
                        sessionStorage.setItem('jwtToken', data.data.jwt.accessToken);
                        sessionStorage.setItem('menu', JSON.stringify(transactions));
                        const payload = {
                            userCode: user.key
                          };
                        onActualizarOtp(payload);// enviar otp

                        dispatch(hideAuthLoader())
                        dispatch(fetchSuccess("Ingreso exitoso"));
                        dispatch(setAuthUser(user));

                    } else {
                        dispatch(fetchError(data.message));
                    }
            } 
            else
            {
                dispatch(fetchError(data1.message));
               
                

            }
        }
        if (send)
        fetchData();
}, [dispatch, send, setSend]);
};