import React, { useEffect, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  FormHelperText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  Select,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { formatoFecha } from "../../../shared/validations/validaciones";

import {
  consultaSiniestroXIdSiniestro,
  consultaCabeceraProducto,
  consultarDetalleFlujo,
  consultarProductoFlujo,
  consultarColaborador,
  crearSiniestroCrear,
} from "../../Services/siniestrosService";

import { consultaCatalogosSinPadre } from "../../../app-cliente/services/Catalogos/catalogosService";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import SCSAccordion from "app/modules/common/components/Accordions/SCSAccordion";
import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";
import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

const FormSiniestroGestion = ({ idSiniestro, idGrupo }) => {
  const [detalleFlujo, setDetalleFlujo] = useState(null);
  const [nombreFlujo, setNombreFlujo] = useState(null);

  const [datosEntregaArticulo, setdatosEntregaArticulo] = useState(null);
  const [idProducto, setIdProducto] = useState(null);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [existeColaborador, setExisteColaborador] = useState(false);
  const [fechaCompraProducto, setFechaCompraProducto] = useState("");
  const [valorProducto, setValorProducto] = useState("");

  useEffect(() => {
    const getData = async () => {
      let respuestaSiniestro;
      try {
        respuestaSiniestro = await consultaSiniestroXIdSiniestro(idSiniestro);

        if (respuestaSiniestro.state) {
          /* console.log(respuestaSiniestro); */
          setDetalleFlujo(respuestaSiniestro.data);
        } else {
          setDetalleFlujo({});
          respuestaSiniestro = {};
        }

        let respuestaCabeceraProducto;

        respuestaCabeceraProducto = await consultaCabeceraProducto(
          respuestaSiniestro.data.idSiniestro
        );

        if (respuestaCabeceraProducto.state) {
          /*  console.log(respuestaCabeceraProducto); */
          /*     setFechaCompraProducto(respuestaCabeceraProducto.fechaInicio);
          setValorProducto(respuestaCabeceraProducto.valor); */
          setIdProducto(respuestaCabeceraProducto.data.idProducto);
        } else {
          setIdProducto(2);
        }

        /* aqui otra llamada a consultar producto consultarDetalleProducto */

        const respuestaNombreFlujo = await consultarDetalleFlujo(
          respuestaSiniestro.data.idFlujo
        );
        /*   console.log(respuestaNombreFlujo); */
        setNombreFlujo(respuestaNombreFlujo.data.nombreFlujo);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const BuscarColaborador = ({ idVendedor }) => {
    const [colaborador, setColaborador] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const vendedorAuxiliar = idVendedor !== "" ? idVendedor : 0;

      const getData = async () => {
        try {
          const { state, data } = await consultarColaborador(vendedorAuxiliar);

          /* console.log(data); */
          if (state) {
            if (!data.codigo) {
              setExisteColaborador(true);
            } else {
              setExisteColaborador(false);
              setIsLoading(false);
              setColaborador(data);
            }
          }
        } catch (error) {
          setExisteColaborador(true);
          setIsLoading(true);
          console.log(error);
        }
        /* console.log(colaborador); */
      };
      getData();
    }, [idVendedor]);

    if (isLoading) {
      return <h3 className="siniestro-colaborador">Buscar Colaborador !!</h3>;
    }
    return (
      <>
        <h3 className="siniestro-colaborador">
          {colaborador.descripcion ?? colaborador.descripcion}
        </h3>
      </>
    );
  };

  const SelectItemsCobertura = ({
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [coberturas, setCoberturas] = useState([]);
    useEffect(() => {
      /*  console.log(idProducto); */
      const getData = async () => {
        try {
          const { state, data } = await consultarProductoFlujo(idProducto);

          if (state) {
            setCoberturas(data);
          } else {
            setCoberturas([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, [idProducto]);

    return (
      <FormControl
        sx={{ minWidth: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {coberturas.map((cobertura, index) => (
            <MenuItem key={index} value={cobertura.codigoDetalle}>
              {cobertura.descripcion}
            </MenuItem>
          ))}
        </Field>
        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const VerificarTipoFlujo = () => {
    return (
      <>
        <h3>{`FLUJO:  ${nombreFlujo}`}</h3>
      </>
    );
  };

  const SelectItemsXBit = ({ name, labelName }) => {
    return (
      <FormControl sx={{ minWidth: 150 }}>
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          <MenuItem value={1}>Si</MenuItem>
          <MenuItem value={0}>No</MenuItem>
        </Field>
      </FormControl>
    );
  };

  const validarTipoEntrega = (tipoEntrega) => {
    if (tipoEntrega === "ENTRLOC") {
      return true;
    }
    return false;
  };

  const validarEntregaArticulo = (entregaArticulo) => {
    /*  console.log(entregaArticulo); */
    if (entregaArticulo) {
      /*   console.log("entreda si"); */
      setdatosEntregaArticulo(false);

      return true;
    }
    setdatosEntregaArticulo(true);
    /*  console.log("entrega no"); */
    return false;
  };

  const validarFechaMayorMenor = (fechaInicio, fechaFin, parametro) => {
    const formatoFechaComprobar = new Date(fechaInicio);
    const formatoFechaActual = new Date(fechaFin);

    /*   if (parametro === "MAYOR") {
        console.log("entra a mayor");
        if (formatoFecha1 > formatoFecha2) {
          console.log("la fecha de inicio es mayor que la final");
        }
        console.log("la fecha de inicio es mayor que la final");
      } */

    if (parametro === "MENOR") {
      console.log("entra a menor");
      if (formatoFechaComprobar <= formatoFechaActual) {
        console.log("la fecha a comprobar es menor a la fecha actual");
        return true;
      }
      console.log("la fecha a comprobar es mayor a la fecha actual");
      return false;
    }
    return false;
  };

  const validarFechaRango = (fechaInicio, fechaFin) => {
    const formatoFechaComprobar = new Date(fechaInicio);
    const formatoFechaActual = new Date(fechaFin);

    const difference = Math.abs(formatoFechaActual - formatoFechaComprobar);
    const days = difference / (1000 * 3600 * 24);

    if (days <= 5) {
      return true;
    }
    return false;
  };

  const validarcoberturaCaidas = (coberturaSiniestro) => {
    console.log(coberturaSiniestro);

    const dias = 30;

    const fechaActual = new Date();
    const fechaDeCompraProducto = new Date(fechaCompraProducto);

    const difference = Math.abs(fechaActual - fechaDeCompraProducto);
    const days = difference / (1000 * 3600 * 24);

    console.log(days);

    if (days >= dias) {
      return true;
    }
    return false;
  };

  const validarCoberturaMenorACien = (coberturaSiniestro) => {
    const valorBines = Number(valorProducto);

    if (valorBines <= 100) {
      return false;
    }
    return true;
  };

  const configuracionDatosEnvio = async (valores) => {
    /*  console.log(valores); */
    setLoading(true);

    const valoresAux = {
      ...valores,
    };

    /*  console.log(valoresAux);
     */
    if (datosEntregaArticulo) {
      valoresAux.condicionesArticulo = "";
      valoresAux.fechaEntrega = "";
    }

    /* console.log(valoresAux); */

    const informacionSiniestro = {
      idCliente: detalleFlujo.idCliente,
      idSiniestro: detalleFlujo.idSiniestro,
      idFlujo: detalleFlujo.idFlujo,
      idContrato: detalleFlujo.idContrato,
      idEstado: detalleFlujo.idEstado,
      idSubEstado: detalleFlujo.idSubEstado,
      idLocal: Number(valoresAux.idLocal),
      idVendedor: Number(valoresAux.idVendedor),
      numeroSiniestro: detalleFlujo.numeroSiniestro,
      coberturaSiniestro: valoresAux.coberturaSiniestro,
      tipoEntrega: valoresAux.tipoEntrega,
      fechaNotificacion: detalleFlujo.fechaNotificacion,
      detalleSiniestro: valoresAux.detalleSiniestro.toUpperCase(),
      fechaSiniestro: valoresAux.fechaSiniestro,
      entregaArticulo: valoresAux.entregaArticulo,
      inmediato: valoresAux.inmediato,

      esActivo: true,

      fechaEntrega: valoresAux.fechaEntrega,
      condicionesArticulo: valoresAux.condicionesArticulo.toUpperCase(),
    };

    /* console.log(informacionSiniestro); */

    try {
      const respuestaSiniestro = await crearSiniestroCrear(
        informacionSiniestro
      );

      /* console.log(respuestaSiniestro); */

      if (respuestaSiniestro.state) {
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setLoading(false);
          setDatosEnviadosCorrectamente(false);
        }, 1000);
      } else {
        alert(`${respuestaSiniestro.data.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <>
      {detalleFlujo ? (
        <>
          <Formik
            initialValues={{
              /* informacion siniestro */
              numeroSiniestro: detalleFlujo.numeroSiniestro || "",
              coberturaSiniestro: detalleFlujo.coberturaSiniestro || "",
              fechaSiniestro: detalleFlujo.fechaSiniestro || "",

              idLocal: detalleFlujo.idLocal || "",
              idVendedor: detalleFlujo.idVendedor || "",
              detalleSiniestro: detalleFlujo.detalleSiniestro || "",
              tipoEntrega: detalleFlujo.tipoEntrega || "",
              entregaArticulo: detalleFlujo.entregaArticulo || false,

              fechaEntrega: detalleFlujo.fechaEntrega || "",
              condicionesArticulo: detalleFlujo.condicionesArticulo || "",
              inmediato: detalleFlujo.inmediato || false,
            }}
            validationSchema={Yup.object({
              coberturaSiniestro: Yup.string().required(
                "Cobertura Siniestro requerido"
              ),
              fechaSiniestro: Yup.string().required(
                "Fecha Siniestro requerido"
              ),
              idLocal: Yup.string().required("Local requerido"),
            })}
            validate={(values) => {
              const errors = {};

              if (existeColaborador) {
                errors.idVendedor = "No existe el colaborador";
              }

              if (values.coberturaSiniestro === "COB038") {
                if (validarcoberturaCaidas(values.coberturaSiniestro)) {
                  errors.coberturaSiniestro =
                    "No es posible aplicar la cobertura";
                }
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              configuracionDatosEnvio(values);
            }}
          >
            {({ values, handleChange, touched, errors, handleBlur }) => (
              <Form>
                <SCSAccordion tituloCabecera="Información Siniestro">
                  <Grid container spacing={1} rowSpacing={1}>
                    <Grid item md={12}>
                      <Grid container spacing={1} rowSpacing={1}>
                        <Grid item md={12}>
                          <div className="cliente-informacion-seccion">
                            <TextField
                              disabled
                              id="numeroSiniestro"
                              name="numeroSiniestro"
                              type="text"
                              label="Código Siniestro"
                              sx={{ width: "15%" }}
                              value={values.numeroSiniestro}
                              onChange={handleChange}
                              size="small"
                            />

                            <SelectItemsCobertura
                              name="coberturaSiniestro"
                              labelName="Cobertura Siniestro"
                              componentSize="29%"
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />

                            <TextField
                              disabled
                              id="fechaSiniestro"
                              label="Fecha Siniestro"
                              type="date"
                              size="small"
                              sx={{ width: "15%" }}
                              onChange={handleChange}
                              value={formatoFecha(values.fechaSiniestro)}
                              InputLabelProps={{ shrink: true }}
                              error={
                                touched.fechaSiniestro &&
                                Boolean(errors.fechaSiniestro)
                              }
                              helperText={
                                touched.fechaSiniestro && errors.fechaSiniestro
                              }
                              onBlur={handleBlur}
                            />
                            <SelectItemsCatalogoSinPadre
                              pais="ECU"
                              especial="true"
                              catalogos="LOCAL"
                              grupo={idGrupo}
                              name="idLocal"
                              labelName="Local"
                              componentSize="28%"
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />
                          </div>

                          <div className="cliente-informacion-seccion">
                            <TextField
                              id="idVendedor"
                              name="idVendedor"
                              type="text"
                              label="Colaborador"
                              sx={{ width: "15%" }}
                              value={values.idVendedor}
                              onChange={handleChange}
                              size="small"
                              error={
                                touched.idVendedor && Boolean(errors.idVendedor)
                              }
                              helperText={
                                touched.idVendedor && errors.idVendedor
                              }
                              onBlur={handleBlur}
                            />

                            <div className="siniestro-informacion-estatica">
                              <BuscarColaborador
                                idVendedor={values.idVendedor}
                              />

                              <VerificarTipoFlujo />

                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.inmediato}
                                      onChange={handleChange}
                                      name="inmediato"
                                    />
                                  }
                                  label="Inmediato"
                                />
                              </FormGroup>
                            </div>
                          </div>

                          <div className="cliente-informacion-seccion">
                            <TextField
                              id="detalleSiniestro"
                              name="detalleSiniestro"
                              type="text"
                              label="Detalle Siniestro"
                              sx={{ width: "75%" }}
                              value={values.detalleSiniestro}
                              onChange={handleChange}
                              size="small"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                            />

                            <SelectItemsCatalogoSinPadre
                              pais="ECU"
                              especial="false"
                              catalogos="TIPOENTR"
                              grupo={1}
                              name="tipoEntrega"
                              labelName="Tipo Entrega"
                              componentSize="20%"
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />
                          </div>

                          {validarTipoEntrega(values.tipoEntrega) && (
                            <>
                              <div className="cliente-informacion-seccion">
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.entregaArticulo}
                                        onChange={handleChange}
                                        name="entregaArticulo"
                                      />
                                    }
                                    label="Entrega Articulo"
                                  />
                                </FormGroup>

                                {validarEntregaArticulo(
                                  values.entregaArticulo
                                ) && (
                                  <>
                                    <TextField
                                      id="fechaEntrega"
                                      label="Fecha Entrega"
                                      type="date"
                                      size="small"
                                      sx={{ width: "30%" }}
                                      onChange={handleChange}
                                      value={formatoFecha(values.fechaEntrega)}
                                      InputLabelProps={{ shrink: true }}
                                    />

                                    <TextField
                                      id="condicionesArticulo"
                                      name="condicionesArticulo"
                                      type="text"
                                      label="Condiciones Entrega"
                                      sx={{ width: "100%" }}
                                      value={values.condicionesArticulo}
                                      onChange={handleChange}
                                      size="small"
                                      inputProps={{
                                        style: {
                                          textTransform: "uppercase",
                                        },
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </Grid>
                      </Grid>

                      <div className="confirmar-estados-servicios">
                        <SCSLoadingButton loading={loading} titulo="GUARDAR" />

                        {datosEnviadosCorrectamente && (
                          <AcceptServiceresponse />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </SCSAccordion>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <SpinnerLoadSrx />
      )}
    </>
  );
};

export default FormSiniestroGestion;
