import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Grid,
} from "@mui/material";
import { Card } from "@mui/material";

import   "./styles.css";

const Home = () => {
    return (
      <div className="header-client-container">
      <Card>
          <header className="header-client-information">
          <div>
            <h2 className="headerclientcontainer">Siniestro</h2>
          </div>
          <div>
            <h4>Identificacion</h4>
            <h4>
              0905731527
            </h4>
          </div>
          <div>
            <h4>Nombre</h4>
            <h4>
              CARRILLO ROMERO MIRIAN CECILIA
            </h4>
          </div>

          <div>
            <h4>Fecha Nacimiento</h4>
            <h4>2025-10-03</h4>
          </div>

          <div>
            <h4>Telefono</h4>
            <h4>022823670 </h4>
          </div>
          <div>
            <h4>Celular</h4>
            <h4>0991969255</h4>
          </div>
          <div>
            <h4>Tipo Cliente</h4>
            <h4>
              PREAPROBADO
            </h4>
          </div>
          </header>
      </Card>
    </div>
    );
};

export default Home;