import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import shadows from "@mui/material/styles/shadows";
import { alpha } from "@mui/material/styles";
import { auth } from "@jumbo/services/auth/firebase/firebase";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { useAuthSignInWithEmailAndPassword } from "@react-query-firebase/auth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router-dom";
import Div from "@jumbo/shared/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";
import { ASSET_AVATARS, ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { useState } from "react";
import HouseIcon from "@mui/icons-material/House";
import { consultarGrupoGenerico } from "app/modules/app-configuracion/services/configuracionServices";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Terminos = () => {
  //const origen = localStorage.getItem("origen");
  //localStorage.setItem('origen', origen);

  const [origen, setOrigen] = useState(localStorage.getItem("origen"));

  const history = useHistory();
  const { setActiveLayout } = useJumboApp();
  const [color, setColor] = useState("");
  const [url, setUrl] = useState("");

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);

    switch (origen) {
      case "tvtas":
        setColor("#FF9201");
        setUrl("https://www.tventas.com/content/55-creditventas");
        break;
      case "corpfav":
        setColor("#e1251b");
        setUrl("https://www.creditofavorito.com/");
        break;
      default:
        setColor("#e1251b");
        setUrl("https://www.creditofavorito.com/");
        break;
    }
    const obtenerGrupo = async () => {
      const resp = await consultarGrupoGenerico(origen, 1);
      if (resp.state) {
        localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
      } else {
        console.log(resp.message);
      }
    };
    obtenerGrupo();
  }, []);

  // const obtenerGrupo = async () => {
  //   const resp = await consultarGrupoGenerico(origen, 1);
  //   if (resp.state) {
  //     localStorage.setItem("idGrupoStorage", resp.data[0].codigo);
  //   } else {
  //     console.log(resp.message);
  //   }
  // };

  const mutation = useAuthSignInWithEmailAndPassword(auth, {
    onError(error) {
      console.log(error);
    },
    onSuccess(data) {
      history.push("/");
    },
  });

  const onSignIn = (email, password) => {
    history.push("/auth-pages/otp");
    //mutation.mutate({email, password});
  };

  return (
    <Div
      sx={{
        flex: 1,
        marginTop: 20,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(4),
      }}
    >
      <Box
        component="img"
        src={`${process.env.REACT_APP_IMAGES_PATH}/logo-${origen}.png`}
        sx={{
          justifyContent: "center",
          width: 350,
          height: 140,
        }}
      />
      <Card sx={{ maxWidth: "100%", width: 360, mb: 4 }}>
        <Div sx={{ position: "relative", height: "200px" }}>
          <CardMedia
            component="img"
            height="200"
            sx={{ backgroundColor: color }}
          />
          <Div
            sx={{
              flex: 1,
              inset: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              p: (theme) => theme.spacing(3),
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                color: "common.white",
                fontSize: "1.5rem",
                mb: 0,
              }}
            >
              Terminos y condiciones
            </Typography>
          </Div>
        </Div>
        <CardContent sx={{ pt: 0 }}>
          <Formik
            validateOnChange={true}
            initialValues={{
              email: "demo@example.com",
              password: "123456",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              onSignIn(data.email, data.password);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Avatar
                  alt="Remy Sharp"
                  src={getAssetPath(`${ASSET_AVATARS}/avatar5.jpg`)}
                  sx={{
                    width: 56,
                    height: 56,
                    marginLeft: "auto",
                    boxShadow: shadows[3],
                    transform: "translateY(-50%)",
                  }}
                />
                {mutation.isError && <p>{mutation.error.message}</p>}
                <Typography textAlign={"center"} variant={"body1"} mb={1}>
                  Para aceptar términos y condiciones, Poíticas de privaciodad,
                  por favor ingrese su identificación
                </Typography>
                <Div sx={{ mb: 3, mt: 1 }}>
                  <JumboTextField fullWidth name="email" label="Email" />
                </Div>

                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting || mutation.isLoading}
                >
                  Continuar
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
      <Button
        size="lg"
        variant="outlined"
        startIcon={<HouseIcon />}
        onClick={() => {
          window.open(url, "_self");
        }}
      >
        <Typography> Regresar a la pagina Principal</Typography>
      </Button>
    </Div>
  );
};

export default Terminos;
