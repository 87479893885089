import React, { useState, useEffect } from "react";
import { consultarValorPrecancelar } from "../services/solicitudesService";

export const useValorPrecancelar = ({ identificacion }) => {
  const [valorPrecancelar, setValorPrecancelar] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
       /*  console.log(identificacion); */
        const respuestaValorPrecancelar = await consultarValorPrecancelar(
          identificacion
        );

        /* console.log(respuestaValorPrecancelar); */

        if (respuestaValorPrecancelar.state) {
          setValorPrecancelar(respuestaValorPrecancelar.data);
          setIsLoading(false);
        } else {
          setValorPrecancelar(0);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    getData();
  }, [identificacion]);

  return { isLoading, valorPrecancelar };
};
