import React from "react";
import { Divider, Skeleton } from "@mui/material";

const SkeletonConversaciones = () => {
  return (
    <div style={{ padding: "0 1rem", margin: "1rem 0 0 0" }}>
      <Skeleton
        variant="rectangular"
        width="100%"
        height="3vh"
        sx={{ borderRadius: "24px", margin: "0.5rem 0" }}
      />

      <Divider />
      <Skeleton
        variant="rectangular"
        width="100%"
        height="3vh"
        sx={{ borderRadius: "24px", margin: "0.5rem 0" }}
      />
      <Divider />

      <Skeleton
        variant="rectangular"
        width="100%"
        height="3vh"
        sx={{ borderRadius: "24px", margin: "0.5rem 0" }}
      />
      <Divider />
      <Skeleton
        variant="rectangular"
        width="100%"
        height="3vh"
        sx={{ borderRadius: "24px", margin: "0.5rem 0" }}
      />
      <Divider />
      <Skeleton
        variant="rectangular"
        width="100%"
        height="3vh"
        sx={{ borderRadius: "24px", margin: "0.5rem 0" }}
      />
      <Divider />
    </div>
  );
};

export default SkeletonConversaciones;
