import  http  from '../../../comunication/http-commonsession'
import axios from "axios";

export const allUsers = async () => {
  const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
  const url =`${process.env.REACT_APP_API_SEGURIDAD}Identity/obtenertodos?sistema=${process.env.REACT_APP_SISTEMA}`;

  let coreResponse = await axios.get(url,headers);
  return coreResponse.data;
};
export const addUser = async (user) => {
  const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
  const body = user;
  const url =`${process.env.REACT_APP_API_SEGURIDAD}identity/signup`;
  let coreResponse = await axios.post(url, body,{headers});
  return coreResponse.data;
};

export const addRol = async (entity) => {
  const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
  let body = entity
  const url =`${process.env.REACT_APP_API_SEGURIDAD}rol/grabar`;
  let coreResponse = await axios.post(url, body,{headers})
  return coreResponse.data;
};

export const updateRol = async (entity) => {
  const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
  let body = entity
  const url =`${process.env.REACT_APP_API_SEGURIDAD}rol/actualizar`;
  let coreResponse = await axios.post(url, body,{headers})
  return coreResponse.data;
};

export async function getRol(code, system) {
  const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
  let body = {};
  body.codigo = code;
  body.sistema = system;

  const url =`${process.env.REACT_APP_API_SEGURIDAD}rol/obtener?codigo=${code}&sistema=${system}`;
  
  let coreResponse = await axios.get(url,{headers});
  return coreResponse.data; 
}

export async function findRol(code, system) {
  const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
  let body = {};
  body.codigo = code;
  body.sistema = system;

  const url =`${process.env.REACT_APP_API_SEGURIDAD}rol/buscar?codigo=${code}&sistema=${system}`;
  
  let coreResponse = await axios.get(url,{headers});

  return coreResponse.data; 
}

export async function getAllRol(system) {
  const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };

  const url =`${process.env.REACT_APP_API_SEGURIDAD}rol/obtenertodos?sistema=${system}`;
 
  let coreResponse = await axios.get(url,{headers})
  return coreResponse.data;
}

export async function getAllOptions() {
  const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
  let body = {};
  const url =`${process.env.REACT_APP_API_SEGURIDAD}rol/obteneropciones`;
 
  let coreResponse = await axios.get(url, body,{headers})
  return coreResponse.data;
}

export async function registrarFirma(identificacion, tipoDcto, firma) {
  debugger;
  const headers = { 'Authorization': 'Bearer '+ sessionStorage.getItem('jwtToken') };
  let body = { TipoIdentificacion: tipoDcto,
    Identificacion: identificacion,
    Foto:firma};

  const url =`${process.env.REACT_APP_API_CLIENTE}firma/registrarfirma`;
 
  let coreResponse = await axios.post(url, body,{headers})
  return coreResponse.data;

}
