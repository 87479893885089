import React, { useEffect, useState } from "react";

import BuscadorPorIdentificador from "app/modules/app-cliente/containers/BuscadorPorIdentificador";
import HeaderInformacionCliente from "app/modules/shared/containers/HeaderInformacionCliente";

import { consultaCliente } from "../../../app-cliente/services/clienteService";

import StepVentaSeguros from "../../containers/StepVentaSeguros";
import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import FormVentaProducto from "../../components/FormVentaProducto";

const SeguroVender = () => {
  const [cliente, setCliente] = useState(null);
  const [idCliente, setIdCliente] = useState("");
  const [mostrarDetalle, setMostrarDetalle] = useState(true);
  const [identificacion, setIdentificacion] = useState("");

  const nuevoCliente = {};
  const nuevoIdCliente = -1;

  const buscarExistenciaCliente = async (identificador) => {
    try {
      const respuestaCliente = await consultaCliente(identificador);

      if (respuestaCliente.state) {
        setIdCliente(respuestaCliente.data.idCliente);

        setCliente(respuestaCliente.data);
        setMostrarDetalle(false);
      } else {
        setCliente(nuevoCliente);
        setIdCliente(nuevoIdCliente);
        setMostrarDetalle(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const traerIdCliente = (id) => () => {
    /*   console.log(id); */
    buscarExistenciaCliente(id);
    setIdentificacion(id);
  };

  if (idCliente < 0) {
    return (
      <>
        <BotonGerericoSalir />
        <h2>No existe información del cliente!!</h2>
      </>
    );
  }

  if (mostrarDetalle) {
    return <BuscadorPorIdentificador traerIdCliente={traerIdCliente} />;
  }

  return (
    <div>
      <BotonGerericoSalir />
      {idCliente && (
        <>
          <HeaderInformacionCliente idCliente={idCliente} />
          <FormVentaProducto idCliente={idCliente} />
        </>
      )}
    </div>
  );
};

export default SeguroVender;
