import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

import { FormControl, Grid, OutlinedInput, TextField } from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { LoadingButton } from "@mui/lab";

import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";

import {
  MaskCelulares,
  MaskTelefonos2,
} from "app/modules/shared/mask/mascarasInputs";

import {
  consultaContacto,
  crearClienteContacto,
} from "app/modules/app-cliente/services/clienteService";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";

const FormTipoContacto = ({ contacto, setEstadoContacto }) => {
  const [idContactoAux, setIdContactoAux] = useState(contacto.idContacto);
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [loading, setLoading] = useState(false);

  console.log(contacto);

  const configuraionEnvioDatos = async (valores) => {
    setLoading(false);

    const informacionContacto = {
      idCliente: Number(contacto.idCliente),
      idContacto: idContactoAux,
      celular: valores.celular,
      email: valores.email,
      telefono: valores.telefono,
      telefono2: valores.telefono2,
      extension: valores.extension,
      tipoDireccion: contacto.tipoDireccion,
      esPersonal: true,
      esActivo: true,
    };

    console.log(informacionContacto);

    try {
      const respuestaClienteContacto = await crearClienteContacto(
        informacionContacto
      );
      /*  console.log(respuestaClienteContacto); */

      if (respuestaClienteContacto.state) {
        setIdContactoAux(respuestaClienteContacto.data);
        setDatosEnviadosCorrectamente(true);

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
          setEstadoContacto((args) => ({
            ...args,
            state: false,
            contacto: informacionContacto,
          }));
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const diccionarioTipoDireccion = (tipoDireccion) => {
    const tiposDireccion = {
      DIRDOM: "DOMICILIO",
      DIRLAB: "LABORAL",
      DIROTR: "OTROS",
      DIRGES: "GESTIÓN ",
    };

    return tiposDireccion[tipoDireccion];
  };

  return (
    <Formik
      initialValues={{
        telefono: contacto.telefono ?? "",
        telefono2: contacto.telefono2 ?? "",
        extension: contacto.extension ?? "",
        celular: contacto.celular ?? "",
        email: contacto.email ?? "",
      }}
      validationSchema={Yup.object({
        telefono: Yup.string()
          .min(9, "Debería tener 9 números")
          .max(9, "Debería tener 9 números"),

        celular: Yup.string()
          .min(10, "Debería tener 10 números")
          .max(10, "Debería tener 10 números")
          .required("Celular requerido"),
        /* telefono: Yup.string().min(7, "Debería tener 7 números"), */
        telefono2: Yup.string()
          .min(10, "Debería tener 10 números")
          .max(10, "Debería tener 10 números"),

        email: Yup.string().email("Email no tiene formato"),
      })}
      enableReinitialize={true}
      onSubmit={(valores, { resetForm }) => {
        configuraionEnvioDatos(valores);
      }}
    >
      {({ values, handleChange, touched, errors, handleBlur }) => (
        <Form>
          <div className="accordion-container">
            <div>
              <Accordion
                defaultExpanded="true"
                sx={{ boxShadow: 3, lineHeight: 0 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  className="accordion-summary"
                  sx={{ margin: 0 }}
                >
                  <div className="formulario-cabecera">
                    {`CONTACTO ${diccionarioTipoDireccion(
                      contacto.tipoDireccion
                    )}`}
                  </div>
                </AccordionSummary>

                <AccordionDetails sx={{ marginTop: "2rem" }}>
                  <Grid container spacing={1} rowSpacing={1}>
                    <Grid item md={12}>
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          <Grid container spacing={3}>
                            <Grid item md={12}>
                              <div className="cliente-informacion-seccion">
                                <FormControl
                                  sx={{ width: "15%" }}
                                  size="small"
                                  variant="outlined"
                                  error={
                                    touched.telefono && Boolean(errors.telefono)
                                  }
                                  helperText={
                                    touched.telefono && errors.telefono
                                  }
                                  onBlur={handleBlur}
                                >
                                  <InputLabel htmlFor="telefono">
                                    Télefono
                                  </InputLabel>
                                  <OutlinedInput
                                    value={values.telefono}
                                    onChange={handleChange}
                                    name="telefono"
                                    id="telefono"
                                    inputComponent={MaskTelefonos2}
                                    /* label es el tama;o que maneja el label */
                                    label="telefono"
                                  />
                                  <ErrorMessage
                                    name="telefono"
                                    component={() => (
                                      <FormHelperText>
                                        {errors.telefono}
                                      </FormHelperText>
                                    )}
                                  />
                                </FormControl>

                                <TextField
                                  id="extension"
                                  name="extension"
                                  type="text"
                                  label="Extensión"
                                  inputProps={{ min: 0 }}
                                  sx={{ width: "8%" }}
                                  value={values.extension}
                                  onChange={handleChange}
                                  size="small"
                                  error={
                                    touched.extension &&
                                    Boolean(errors.extension)
                                  }
                                  helperText={
                                    touched.extension && errors.extension
                                  }
                                  onBlur={handleBlur}
                                />

                                <FormControl
                                  sx={{ width: "15%" }}
                                  size="small"
                                  variant="outlined"
                                  error={
                                    touched.celular && Boolean(errors.celular)
                                  }
                                  helperText={touched.celular && errors.celular}
                                  onBlur={handleBlur}
                                >
                                  <InputLabel htmlFor="celular">
                                    Celular
                                  </InputLabel>
                                  <OutlinedInput
                                    value={values.celular}
                                    onChange={handleChange}
                                    name="celular"
                                    id="celular"
                                    inputComponent={MaskCelulares}
                                    label="celular"
                                  />
                                  <ErrorMessage
                                    name="celular"
                                    component={() => (
                                      <FormHelperText>
                                        {errors.celular}
                                      </FormHelperText>
                                    )}
                                  />
                                </FormControl>

                                <FormControl
                                  sx={{ width: "15%" }}
                                  size="small"
                                  variant="outlined"
                                  error={
                                    touched.telefono2 &&
                                    Boolean(errors.telefono2)
                                  }
                                  helperText={
                                    touched.telefono2 && errors.telefono2
                                  }
                                  onBlur={handleBlur}
                                >
                                  <InputLabel htmlFor="telefono2">
                                    Celular 2
                                  </InputLabel>
                                  <OutlinedInput
                                    value={values.telefono2}
                                    onChange={handleChange}
                                    name="telefono2"
                                    id="telefono2"
                                    inputComponent={MaskCelulares}
                                    label="telefono2"
                                  />
                                  <ErrorMessage
                                    name="telefono2"
                                    component={() => (
                                      <FormHelperText>
                                        {errors.telefono2}
                                      </FormHelperText>
                                    )}
                                  />
                                </FormControl>

                                <TextField
                                  id="email"
                                  name="email"
                                  type="text"
                                  label="Email"
                                  sx={{ width: "33%" }}
                                  value={values.email}
                                  onChange={handleChange}
                                  size="small"
                                  error={touched.email && Boolean(errors.email)}
                                  helperText={touched.email && errors.email}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <div className="confirmar-estados-servicios">
                        <LoadingButton
                          sx={{ width: "20%" }}
                          type="submit"
                          size="small"
                          loading={loading}
                          loadingPosition="end"
                          variant="contained"
                          /* startIcon={<AttachMoneyIcon />} */
                        >
                          Guardar
                        </LoadingButton>

                        {datosEnviadosCorrectamente && (
                          <AcceptServiceresponse />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormTipoContacto;
