import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../../layouts/layouts";

import SiniestroProductoFlujo from "../../container/SiniestroProductoFlujo";

const SiniestroConSeguro = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <>
      <h2>Siniestro - Con Seguro</h2>
      <SiniestroProductoFlujo />
    </>
  );
};

export default SiniestroConSeguro;
