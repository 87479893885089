import React, { useEffect, useState } from "react";

import FormGestionCobranza from "../components/FormGestionCobranza";
import CabeceraSolicitudCobranza from "../components/CabeceraSolicitudCobranza";
import ListaContactosClientes from "../components/ListaContactosClientes";
import FormTipoContacto from "../components/FormTipoContacto";
import ListaCobranzaHistorico from "../components/ListaCobranzaHistorico";
import ListaContactoReferencia from "../components/ListaContactoReferencia";
import { consultarLlamadaUsuario } from "../services/cobranzaServices";
import { Backdrop, CircularProgress } from "@mui/material";
import { useHistory } from "react-router";

const DetalleGestionCobranza = () => {
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const informacionCobranza = history.location.state.state.informacionCobranza;

  const [estadoContacto, setEstadoContacto] = useState({
    state: false,
    contacto: {},
  });
  /*   const [cobranzaAux, setCobranzaAux] = useState(
    informacionCobranza.idCobranza
  ); */

  console.log({ informacionCobranza });

  /*   useEffect(() => {
    console.log(cobranzaAux);

    setCobranzaAux(informacionCobranza.idCobranza);
  }, [cobranzaAux]); */

  useEffect(() => {
    console.log({ informacionCobranza });

    const getLlamadaUsuario = async () => {
      setIsLoading(true);
      try {
        const respuestaLlamadaUsuario = await consultarLlamadaUsuario(
          informacionCobranza.idUsuario,
          informacionCobranza.contacto
        );

        console.log({ respuestaLlamadaUsuario });

        if (respuestaLlamadaUsuario.state) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    getLlamadaUsuario();
  }, [informacionCobranza.idCobranza]);

  return (
    <>
      <CabeceraSolicitudCobranza informacionCobranza={informacionCobranza} />

      <div className="gestionCobranza-container">
        <FormGestionCobranza infoCobranza={informacionCobranza} />

        <ListaContactosClientes
          idCliente={informacionCobranza.idCliente}
          contacto={estadoContacto}
          setEstadoContacto={setEstadoContacto}
          informacionCobranza={informacionCobranza}
        />

        {estadoContacto.state && (
          <FormTipoContacto
            contacto={estadoContacto.contacto}
            setEstadoContacto={setEstadoContacto}
          />
        )}

        <ListaContactoReferencia informacionCobranza={informacionCobranza} />

        <ListaCobranzaHistorico idCliente={informacionCobranza.idCliente} />

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          /*  onClick={handleClose} */
        >
          <div className="pasarela-pagos-loading">
            <CircularProgress
              color="inherit"
              className="pasarela-pagos-loading-spiner"
            />
            <h2 className="pasarela-pagos-loading-mensaje">Llamando....</h2>
          </div>
        </Backdrop>
      </div>
    </>
  );
};

export default DetalleGestionCobranza;
