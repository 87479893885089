import React from "react";

import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const TableCuotaComun = ({ datos, columnas, sizeWidth }) => {
  const rows = datos.map((detalle, index) => ({ ...detalle, id: index }));

  console.log(rows);
  console.log(columnas);
  return (
    <>
      <Box
        sx={{
          height:380,
          maxWidth: { sizeWidth },
        }}
      >
        <DataGrid
          getRowId={(row) => row.id}
          rows={rows}
          columns={columnas}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>
    </>
  );
};

export default TableCuotaComun;
