import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router";
// AUTH CLIENTE
import LoginCli from "../../app/modules/app-authcli/login1/index";
import ValidarCedula from "../../app/modules/app-authcli/validarcedula/index";
import OtpCli from "../../app/modules/app-authcli/otp/index";
import OtpRegistro from "../../app/modules/app-authcli/otpRegistro/index";
import Welcome from "../modules/app-authcli/welcome1/index";
import Datos from "../modules/app-authcli/datos/index";
import Terminos from "../modules/app-authcli/terminos/index";

import Login from "../../app/modules/app-auth/login/index";
import Otp from "../../app/modules/app-auth/otp/index";
import Signup from "../modules/app-auth/signup/index";
import ForgotPasswordPage from "../modules/app-auth/forgot-password/index";
import ChangePasswordPage from "../modules/app-auth/change-password/index";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Profile from "../modules/app-seguridad/Profile/profile";
import ChangePassword from "../modules/app-seguridad/Profile/changePassword";

//SEGURIDAD
import SessionTimeout from "../session/SessionTimeout";
import Home from "../modules/home/index";

//SEGURIDAD
import Users from "../modules/app-seguridad/Users/Users";
import Roles from "../modules/app-seguridad/Roles/roles";
import Firma from "../modules/app-seguridad/Firma/firma";

//CREDITO
import SolicitudesList from "../modules/app-credito/pages/SolicitudesList";
import RevisionAnalista from "../modules/app-credito/pages/RevisionAnalista";
import Preevaluacion from "app/modules/app-credito/pages/Preevaluacion";
import FlujoPuntaPage from "app/modules/app-credito/pages/FlujoPuntaPage";
import DigitacionPage from "app/modules/app-credito/pages/DigitacionPage";
import DigitacionSolicitudPage from "app/modules/app-credito/pages/DigitacionSolicitudPage";
import AumentoCupoPage from "app/modules/app-credito/pages/AumentoCupoPage";
import FlujoAumentoCupo from "app/modules/app-credito/pages/FlujoAumentoCupo";
import ConsulmaxPage from "app/modules/app-credito/pages/ConsulmaxPage";
import GestionDevueltasPage from "app/modules/app-credito/pages/GestionDevueltasPage";
import CrediwebDocsPage from "app/modules/app-credito/pages/CrediwebDocsPage";
import AnexarPdfPage from "app/modules/app-credito/pages/AnexarPdfPage";
import GestionSolicitudPage from "app/modules/app-credito/pages/GestionSolicitudPage";
import ConsultarSolicitudesPage from "app/modules/app-credito/pages/ConsultarSolicitudesPage";
import TrackingSolicitudPage from "app/modules/app-credito/pages/TrackingSolicitudPage";

//CLIENET
import ClienteInformacion from "app/modules/app-cliente/pages/ClienteInformacion";
import ClienteEstadoFinanciero from "app/modules/app-cliente/pages/ClienteEstadoFinanciero";
import ClienteReferencias from "app/modules/app-cliente/pages/ClienteReferencias";

/* SINIESTROS */
import CrearSiniestros from "app/modules/siniestros/pages/CrearSiniestros";
import DetalleGestionSiniestro from "app/modules/siniestros/pages/DetalleGestionSiniestro";
import TrasladoSiniestro from "app/modules/siniestros/pages/TrasladoSiniestro";
import InformeTecnicoSiniestro from "app/modules/siniestros/pages/InformeTecnicoSiniestro";
import DocumentosSiniestros from "app/modules/siniestros/pages/DocumentosSiniestros";
import ResolucionSiniestros from "app/modules/siniestros/pages/ResolucionSiniestros";
import PagosSiniestro from "app/modules/siniestros/pages/PagosSiniestro";
import SeguimientoSiniestro from "app/modules/siniestros/pages/SeguimientoSiniestro";
import ComentariosSiniestro from "app/modules/siniestros/pages/ComentariosSiniestro";
import CerrarSiniestro from "app/modules/siniestros/pages/CerrarSiniestro";
import CrearProductoCliente from "app/modules/siniestros/pages/CrearProductoCliente/CrearProductoCliente";
import SiniestroConSeguro from "app/modules/siniestros/pages/SiniestroConSeguro/SiniestroConSeguro";

/* CITAS MEDICAS */
import PrestadoresMedicosGestion from "app/modules/app-citasMedicas/pages/PrestadoresMedicosGestion";
import GestionCitaMedica from "app/modules/app-citasMedicas/pages/GestionCitaMedica";

/* QUEJAS */
import CrearQuejas from "app/modules/app-quejas/Pages/CrearQueja";
import QuejasReportes from "app/modules/app-quejas/Pages/QuejasReportes";
import GestionQuejas from "app/modules/app-quejas/Pages/GestionQuejas";
import CerrarQueja from "app/modules/app-quejas/Pages/CerrarQueja";
import SeguimientoQueja from "app/modules/app-quejas/Pages/SeguimientoQueja";
import CrearCitaCitasMedicas from "app/modules/app-citasMedicas/pages/CrearCitaCitasMedicas";
import PendientesQuejas from "app/modules/app-quejas/Pages/PendientesQuejas";

/* SEGUROS */
import VenderSeguro from "app/modules/app-seguro/pages/VenderSeguro";
import GestionarSeguro from "app/modules/app-seguro/pages/GestionarSeguro";
import InitialPage from "app/modules/app-pageHome/pages/InitialPage";
import ClienteConsultar from "app/modules/app-cliente/pages/ClienteConsultar";
import ActualizarPagos from "app/modules/app-actualizarPagos/pages/ActualizarPagos";
import SeguroActualizar from "app/modules/app-seguro/pages/SeguroActualizar";
import SeguroMedioPago from "app/modules/app-seguro/pages/SeguroMedioPago";
import PagoRecurrenciasSeguro from "app/modules/app-seguro/pages/PagoRecurrenciasSeguro";
import ConsultarCuotaSeguro from "app/modules/app-seguro/pages/ConsultarCuotaSeguro";

/* SOLICITUD */
import CreditoSolicitud from "app/modules/app-servicios/pages/CreditoSolicitud";
import CancelarSeguroSolicitud from "app/modules/app-servicios/pages/CancelarSeguroSolicitud";
import DevolucionSeguroSolicitud from "app/modules/app-servicios/pages/DevolucionSeguroSolicitud";
import PrecancelacionSolicitud from "app/modules/app-servicios/pages/PrecancelacionSolicitud";
import RequerimientosClienteSolicitud from "app/modules/app-servicios/pages/RequerimientosClienteSolicitud";
import ServiciosSolicitud from "app/modules/app-servicios/pages/ServiciosSolicitud";
import CambioEstadoSolicitud from "app/modules/app-servicios/pages/CambioEstadoSolicitud";
import ListaNegraColaboradoresSolicitud from "app/modules/app-servicios/pages/ListaNegraColaboradoresSolicitud";

/* REPORTES */
import SiniestrosReportes from "app/modules/app-reportes/pages/SiniestrosReportes";
import SolicitudReportes from "app/modules/app-reportes/pages/SolicitudReportes";
import RequerimientoReportes from "app/modules/app-reportes/pages/RequerimientoReportes";

/* MANTENEDORES */
import LocalesCatalogo from "app/modules/app-configuracion/pages/LocalInformacion";
import ColaboradoresCatalogo from "app/modules/app-configuracion/pages/ColaboradorInformacion";

import { useOnLogout } from "../modules/app-auth/hook/index";
import SegurosReportePolisas from "app/modules/app-reportes/pages/SegurosReportePolisas";
// import DesbloqueoUsuario from "app/modules/app-seguridad/DebloqueoUsuarios/Pages/DesbloqueoUsuario";
import DesbloqueoUsuario from "app/modules/app-seguridad/DebloqueoUsuarios/Pages/DesbloqueoUsuario";
import AsignarLocalUsuario from "app/modules/app-seguridad/AsignarLocalUsuario/Pages/AsignarLocalUsuario";
import FlujoUsuario from "app/modules/app-seguridad/FlujoUsuario/Pages/FlujoUsuario";

/* COBRANZAS */
import CobranzaGestion from "app/modules/app-cobranzas/pages/CobranzaGestion";
import CobranzaGestionParametros from "app/modules/app-cobranzas/pages/CobranzaGestionParametros";
import CobranzaGestionUsuarios from "app/modules/app-cobranzas/pages/CobranzaGestionUsuarios";
import DetalleGestionCobranza from "app/modules/app-cobranzas/containers/DetalleGestionCobranza";

/*TRANSACCIONAL */
import AutorizacionesListado from "app/modules/app-transaccional/pages/AutorizacionesListado";
import TransaccionalValoresPagar from "app/modules/app-transaccional/pages/TransaccionalValoresPagar";
import InformacionTransaccionesCliente from "app/modules/app-transaccional/pages/InformacionTransaccionesCliente";

/* import DesbloqueoUsuario from "app/modules/app-seguridad/DebloqueoUsuarios/Pages/DesbloqueoUsuario"; */

const Routes = () => {
  const { authUser, code } = useSelector(({ auth }) => auth);
  const [sendLogout, setSendLogout] = useState(false);

  // para cerrra la session
  var data = JSON.parse(sessionStorage.getItem("user"));
  const userCode = data ? data.key : "";
  useOnLogout(userCode, sendLogout, setSendLogout);
  // fin pra cerrar la session
  console.log(userCode);

  const location = useLocation();
  let lista = sessionStorage.getItem("menu");
  //let init = localStorage.getItem("init");

  const [init, setInit] = useState(localStorage.getItem("init"));
  //debugger;
  if (location.pathname === "" || location.pathname === "/") {
    if (init !== null) return <Redirect to={init} />;
    else return <Redirect to={"/signincli"} />;

    //return <Redirect to={"/signincli"} />;
    // } else if (authUser && location.pathname === "/signincli") {
    //   location.pathname = "/otp";
  } else if (
    authUser === null &&
    code === null &&
    location.pathname !== "/signincli"
  ) {
    switch (location.pathname) {
      case "/auth-pages/welcome":
        location.pathname = "/auth-pages/welcome";
        break;
      case "/auth-pages/otp":
        location.pathname = "/auth-pages/otp";
        break;
      case "/auth-pages/otpregistro":
        location.pathname = "/auth-pages/otpregistro";
        break;
      case "/auth-pages/datos":
        location.pathname = "/auth-pages/datos";
        break;
      case "/auth-pages/terminos":
        location.pathname = "/auth-pages/terminos";
        break;
      case "/auth-pages/validarcedula":
        location.pathname = "/auth-pages/validarcedula";
        break;
      default:
        location.pathname = "/signincli";
        break;
    }
  }

  if (
    lista != null &&
    location.pathname != undefined &&
    location.pathname != "/otp" &&
    location.pathname != "/signincli" &&
    location.pathname != "/home-page" &&
    location.pathname != "/initialHome" &&
    location.pathname != "/auth-pages/otp" &&
    location.pathname != "/auth-pages/otpregistro" &&
    location.pathname != "/auth-pages/welcome" &&
    location.pathname != "/auth-pages/datos" &&
    location.pathname != "/auth-pages/validarcedula" &&
    location.pathname != "/credito/flujop" &&
    location.pathname != "/transaccional/movimientosCliente" &&
    location.pathname != "//auth-pages/terminos"
  ) {
    if (lista.search(location.pathname) < 0) {
      location.pathname = "/signincli";
      alert("Por favor vuelve a iniciar Sesion.");
    }
  }

  const handleClick = () => {
    setSendLogout(true);
  };

  return (
    <React.Fragment>
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/signin" component={Login} />
        <Route path="/otp" component={Otp} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/password" component={ChangePasswordPage} />
        <Route path="/app/profile-user" component={Profile} />
        <Route path="/app/change-password" component={ChangePassword} />

        {/* //AUTH CLIENTE */}
        <Route path="/signincli" component={LoginCli} />
        <Route path="/auth-pages/otp" component={OtpCli} />
        <Route path="/auth-pages/otpregistro" component={OtpRegistro} />
        <Route path="/auth-pages/welcome" component={Welcome} />
        <Route path="/auth-pages/datos" component={Datos} />
        <Route path="/auth-pages/terminos" component={Terminos} />
        <Route path="/auth-pages/validarcedula" component={ValidarCedula} />

        {/* //SEGURIDAD */}
        <Route path="/perfil" component={Roles} />
        <Route
          path="/usuario/desbloqueoUsuarios"
          component={DesbloqueoUsuario}
        />
        <Route
          path="/usuario/asignarLocalUsuario"
          component={AsignarLocalUsuario}
        />
        <Route path="/usuario/flujoUsuario" component={FlujoUsuario} />
        <Route path="/usuario" component={Users} />
        {/* <Route path="/firma" component={Firma} /> */}

        {/* //CREDITO */}
        <Route path="/credito/preevaluacion" component={Preevaluacion} />
        <Route path="/credito/flujop" component={FlujoPuntaPage} />
        <Route path="/credito/solicitudes" component={SolicitudesList} />
        <Route path="/credito/RevisionAnalista" component={RevisionAnalista} />
        <Route path="/credito/digitacion" component={DigitacionPage} />
        <Route
          path="/credito/digitacionSolicitud"
          component={DigitacionSolicitudPage}
        />
        <Route path="/credito/aumentoCupo" component={AumentoCupoPage} />
        <Route path="/credito/aumentoCupoForm" component={FlujoAumentoCupo} />
        <Route path="/credito/consulmax" component={ConsulmaxPage} />
        <Route path="/credito/devueltas" component={GestionDevueltasPage} />
        <Route
          path="/credito/documentosCrediweb"
          component={CrediwebDocsPage}
        />
        <Route path="/credito/anexarSolicitud" component={AnexarPdfPage} />
        <Route
          path="/credito/gestionSolicitud"
          component={GestionSolicitudPage}
        />
        <Route
          path="/credito/consultaSolicitudes"
          component={ConsultarSolicitudesPage}
        />
        <Route
          path="/credito/trackingSolicitud"
          component={TrackingSolicitudPage}
        />

        {/* PAGINA INICIAL */}

        <Route path="/initialHome" component={InitialPage} />

        {/* CLIENTE */}
        <Route path="/cliente/consultar" component={ClienteConsultar} />
        {/* ruta de crear no se usa en la ultima version */}
        <Route path="/cliente/crear" component={SolicitudesList} />
        <Route
          path="/cliente/administrar/informacion"
          component={ClienteInformacion}
        />
        <Route
          path="/cliente/administrar/financiero"
          component={ClienteEstadoFinanciero}
        />
        <Route
          path="/cliente/administrar/referencias"
          component={ClienteReferencias}
        />

        {/* SINIESTRO */}
        <Route
          path="/siniestros/crear/tiposSiniestro/sinSeguro"
          component={CrearProductoCliente}
        />
        <Route
          /* path="/siniestros/crear/tiposSiniestro/conSeguro/:idContrato/cliente/:idCliente" */
          path="/siniestros/crear/tiposSiniestro/conSeguro"
          component={SiniestroConSeguro}
        />
        {/* crea un nuevo siniestro */}
        <Route path="/siniestros/crear" component={CrearSiniestros} />
        <Route
          path="/siniestros/gestion/siniestro"
          component={DetalleGestionSiniestro}
        />
        <Route
          path="/siniestros/gestion/traslado"
          component={TrasladoSiniestro}
        />
        <Route
          path="/siniestros/gestion/informeTecnico"
          component={InformeTecnicoSiniestro}
        />
        <Route
          path="/siniestros/gestion/documentos"
          component={DocumentosSiniestros}
        />
        <Route
          path="/siniestros/gestion/resolucion"
          component={ResolucionSiniestros}
        />
        <Route path="/siniestros/gestion/pagos" component={PagosSiniestro} />
        <Route
          path="/siniestros/gestion/seguimiento"
          component={SeguimientoSiniestro}
        />
        <Route
          path="/siniestros/gestion/comentarios"
          component={ComentariosSiniestro}
        />
        <Route
          path="/siniestros/gestion/cerrarSiniestro"
          component={CerrarSiniestro}
        />

        {/* GESTION-QUEJAS */}
        <Route path="/gestion/quejas/crear" component={CrearQuejas} />
        <Route path="/gestion/quejas/reporte" component={QuejasReportes} />
        <Route path="/gestion/quejas/solucion" component={GestionQuejas} />
        <Route path="/gestion/quejas/cerrar" component={CerrarQueja} />
        <Route
          path="/gestion/quejas/seguimiento"
          component={SeguimientoQueja}
        />
        <Route path="/gestion/quejas/pendientes" component={PendientesQuejas} />

        {/* GESTION-CITAS MEDICAS */}
        <Route
          path="/gestion/prestadoresMedicos"
          component={PrestadoresMedicosGestion}
        />
        <Route path="/gestion/citas/crear" component={CrearCitaCitasMedicas} />
        <Route path="/gestion/citas/gestionar" component={GestionCitaMedica} />

        {/* SEGUROS  */}
        <Route path="/seguro/vender" component={VenderSeguro} />
        <Route path="/seguro/gestionar" component={GestionarSeguro} />

        <Route path="/seguro/actualizarBien" component={SeguroActualizar} />
        <Route path="/seguro/actualizarMedioPago" component={SeguroMedioPago} />
        <Route
          path="/seguro/pagoRecurrencia"
          component={PagoRecurrenciasSeguro}
        />
        <Route path="/seguro/consultarCuota" component={ConsultarCuotaSeguro} />

        {/* SERVICIOS */}
        <Route
          path="/gestion/servicios/solicitud"
          component={ServiciosSolicitud}
        />

        <Route
          path="/gestion/servicios/certificado/credito"
          component={CreditoSolicitud}
        />
        <Route
          path="/gestion/servicios/certificado/seguro"
          component={CancelarSeguroSolicitud}
        />
        <Route
          path="/gestion/servicios/certificado/devolucion"
          component={DevolucionSeguroSolicitud}
        />
        <Route
          path="/gestion/servicios/certificado/precancelacion"
          component={PrecancelacionSolicitud}
        />
        <Route
          path="/gestion/servicios/certificado/requerimientosCliente"
          component={RequerimientosClienteSolicitud}
        />
        <Route
          path="/gestion/servicios/certificado/cambioEstado"
          component={CambioEstadoSolicitud}
        />
        <Route
          path="/gestion/servicios/listaNegraColaboradores"
          component={ListaNegraColaboradoresSolicitud}
        />

        {/* PAGOS */}
        <Route path="/pagos/actualizarPagos" component={ActualizarPagos} />

        {/* REPORTES */}
        <Route
          path="/reportes/siniestros/reportesSiniestro"
          component={SiniestrosReportes}
        />

        <Route
          path="/reportes/soicitud/reportesFabrica"
          component={SolicitudReportes}
        />

        <Route
          path="/reportes/seguros/reportesSeguro"
          component={SegurosReportePolisas}
        />

        <Route
          path="/reportes/gestion/reportesGestion"
          component={RequerimientoReportes}
        />

        {/* TRANSACCIONAL */}
        <Route
          path="/transaccional/listaAutorizaciones"
          component={AutorizacionesListado}
        />

        <Route
          path="/transaccional/valoresPorPagar"
          component={TransaccionalValoresPagar}
        />
        <Route
          path="/transaccional/movimientosCliente"
          component={InformacionTransaccionesCliente}
        />

        {/* MANTENEDORES */}
        <Route path="/catalogo/listarLocales" component={LocalesCatalogo} />

        <Route
          path="/catalogo/listarColaboradores"
          component={ColaboradoresCatalogo}
        />

        {/* COBRANZAS */}
        <Route path="/cobranza/gestionCobranza" component={CobranzaGestion} />
        <Route
          path="/cobranza/parametrosCobranza"
          component={CobranzaGestionParametros}
        />
        <Route
          path="/cobranza/gestionUsuario"
          component={CobranzaGestionUsuarios}
        />

        <Route
          path="/cobranza/gestionLlamada"
          component={DetalleGestionCobranza}
        />
      </Switch>
      <SessionTimeout
        isAuthenticated={authUser === null ? false : true}
        logOut={handleClick}
      />
    </React.Fragment>
  );
};

export default Routes;
