import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useHistory } from "react-router-dom";

import {
  Card,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { consultarGrupo } from "../services/transaccionalService";

import {
  SCSGenericButton,
  SCSLoadingButton,
} from "app/modules/common/components/Buttons/SCSButtons";

import "../styles/main.css";
import { StylesCardBuscador } from "app/modules/common/styles/themes";

const BuscadorMovimientosServimax = ({ setDatosBusqueda, titulo }) => {
  const navigate = useHistory();

  const redirectHomePage = () => {
    navigate.push("/initialHome");
  };

  const enviarDatosBusqueda = (valores) => {
    /* console.log(valores); */
    setDatosBusqueda((args) => ({
      ...args,
      identificacion: valores.identificacion,
      mostrarBuscador: false,
      idGrupo: Number(valores.grupo),
    }));
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const SelectItemsGrupo = ({
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultarGrupo("", 4);

          if (state && data) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}%` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
        required
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  return (
    <div div className="buscador-movimientos-container">
      <Card sx={StylesCardBuscador()}>
        <header className="buscador-movimientos-header">
          <div className="buscador-movimientos-titulo">
            <h2>{titulo}</h2>
          </div>

          <Formik
            initialValues={{
              identificacion: "",
              grupo: "",
            }}
            validationSchema={Yup.object({
              identificacion: Yup.string().required("Identificación requerida"),
              grupo: Yup.string().required("Grupo requerido"),
            })}
            onSubmit={(valores, { setSubmitting }) => {
              enviarDatosBusqueda(valores);
            }}
          >
            {({ values, handleChange, touched, errors, handleBlur }) => (
              <Form>
                <div className="buscador-movimientos-opciones">
                  <div className="buscador-movimientos-opciones__inputs">
                    <TextField
                      required
                      id="identificacion"
                      name="identificacion"
                      type="text"
                      label="Identificación"
                      sx={{ width: "100%" }}
                      value={values.identificacion}
                      onChange={handleChange}
                      size="small"
                      error={
                        touched.identificacion && Boolean(errors.identificacion)
                      }
                      helperText={
                        touched.identificacion && errors.identificacion
                      }
                      onBlur={handleBlur}
                    />

                    <SelectItemsGrupo
                      name="grupo"
                      labelName="Grupo"
                      componentSize="100"
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                  </div>

                  <div className="buscador-movimientos-opciones__buttons">
                    <SCSLoadingButton size={45} titulo="Buscar" />

                    <SCSGenericButton
                      size={45}
                      titulo="Cancelar"
                      onClick={redirectHomePage}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </header>
      </Card>
    </div>
  );
};

export default BuscadorMovimientosServimax;
