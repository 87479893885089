import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router";

import {
  Alert,
  Button,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MaskCelulares } from "app/modules/shared/mask/mascarasInputs";

import {
  formatoFecha,
  validarCelular,
} from "../../shared/validations/validaciones";

import { obtenerFechaActualFormulario } from "app/modules/shared/commonValues/obtenerFecha";
import {
  crearDatosRequerimiento,
  crearRequerimientoSolicitud,
} from "../services/solicitudesService";

const FormCancelacionSeguros = ({ idCliente, contrato, idLocal }) => {
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const [initialSeguro, setInitialSeguro] = useState({
    celular: "",
    email: "",
    nombreProducto: "",
    numeroContrato: "",
    fechaInicio: "",
  });

  const history = useHistory();

  useEffect(() => {
    console.log(contrato);
    setInitialSeguro({
      celular: "",
      email: "",
      nombreProducto: contrato.producto ?? "",
      numeroContrato: contrato.numContrato ?? "",
      fechaInicio: contrato.fechaInicio ?? "",
    });

    console.log({ initialSeguro });
  }, [contrato]);

  const configuraionEnvioDatos = async (valores, resetForm) => {
    setLoading(true);

    const informacionCancelacionSeguro = {
      idRequerimiento: 0,
      idCliente: Number(idCliente),
      celular: valores.celular,
      email: valores.email,
      idTipoRequerimiento: 2,
      estado: "ESTREQSOL",
      idLocal: idLocal,
      fechaSolicitud: obtenerFechaActualFormulario(),
      fechaResuelto: "",
      esActivo: true,
    };

    console.log(informacionCancelacionSeguro);

    let idRequeriminetoAux;

    try {
      const respuestaSolicitud = await crearRequerimientoSolicitud(
        informacionCancelacionSeguro
      );

      console.log(respuestaSolicitud);

      if (respuestaSolicitud.state) {
        setDatosEnviadosCorrectamente(true);
        idRequeriminetoAux = respuestaSolicitud.data;

        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
        }, 3000);
      } else {
        setLoading(false);
        alert(`${respuestaSolicitud.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }

    const informacionDatosRequerimiento = {
      idDatosRequerimiento: 0,
      idRequerimiento: Number(idRequeriminetoAux),
      referencia: Number(contrato.idContrato),
      fecha: contrato.fechaInicio ?? "",
      factura: 0,
      operacion: 0,
      plazo: 0,
      detalle: "",
      esActivo: true,
    };

    console.log(informacionDatosRequerimiento);

    try {
      const respuestaDatosRequeridos = await crearDatosRequerimiento(
        informacionDatosRequerimiento
      );

      console.log(respuestaDatosRequeridos);

      if (!respuestaDatosRequeridos.state) {
        alert(`${respuestaDatosRequeridos.message}`);
      } else {
        setTimeout(() => {
          history.push("/initialHome");
        }, 3000);

        /* console.log(respuestaDatosRequeridos.data); */
      }
    } catch (error) {
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <>
      <div className="accordion-container">
        <div>
          <Formik
            initialValues={initialSeguro}
            enableReinitialize={true}
            validate={(values) => {
              const errors = {};

              if (validarCelular(values.celular)) {
                errors.celular = "Primer digito debería ser '09'";
              }

              return errors;
            }}
            validationSchema={Yup.object({
              celular: Yup.string()
                .max(10, "Debería tener 10 números")
                .min(10, "Debería tener 10 números")
                .required("Celular requerido"),
              email: Yup.string()
                .email("Email no tiene formato")
                .required("Email requerido"),
            })}
            onSubmit={(valores, { resetForm }) => {
              configuraionEnvioDatos(valores, resetForm);

              /* setTimeout(() => {
                resetForm({ values: "" });
              }, 5000);
 */
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <div className="accordion-container">
                  <div>
                    <Accordion
                      defaultExpanded="true"
                      sx={{ boxShadow: 3, lineHeight: 0 }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="accordion-summary"
                        sx={{ margin: 0 }}
                      >
                        <h2 className="formulario-cabecera">
                          Certificado Cancelación Seguro
                        </h2>
                      </AccordionSummary>
                      <AccordionDetails sx={{ marginTop: "2rem" }}>
                        <Grid container spacing={1} rowSpacing={1}>
                          <Grid item md={12}>
                            <Grid container spacing={1}>
                              <Grid item md={12}>
                                <div className="cliente-informacion-seccion">
                                  <FormControl
                                    sx={{ width: "15%" }}
                                    size="small"
                                    variant="outlined"
                                    error={
                                      touched.celular && Boolean(errors.celular)
                                    }
                                    helperText={
                                      touched.celular && errors.celular
                                    }
                                    onBlur={handleBlur}
                                  >
                                    <InputLabel htmlFor="celular">
                                      Celular
                                    </InputLabel>
                                    <OutlinedInput
                                      value={values.celular}
                                      onChange={handleChange}
                                      name="celular"
                                      id="celular"
                                      inputComponent={MaskCelulares}
                                      label="celular"
                                    />
                                    <ErrorMessage
                                      name="celular"
                                      component={() => (
                                        <FormHelperText>
                                          {errors.celular}
                                        </FormHelperText>
                                      )}
                                    />
                                  </FormControl>
                                  <TextField
                                    id="email"
                                    name="email"
                                    type="text"
                                    label="Email"
                                    sx={{ width: "33%" }}
                                    value={values.email}
                                    onChange={handleChange}
                                    size="small"
                                    error={
                                      touched.email && Boolean(errors.email)
                                    }
                                    helperText={touched.email && errors.email}
                                    onBlur={handleBlur}
                                  />
                                  <TextField
                                    disabled
                                    id="nombreProducto"
                                    name="nombreProducto"
                                    type="text"
                                    label="Nombre Producto"
                                    sx={{ width: "25%" }}
                                    value={values.nombreProducto}
                                    onChange={handleChange}
                                    size="small"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                    error={
                                      touched.nombreProducto &&
                                      Boolean(errors.nombreProducto)
                                    }
                                    helperText={
                                      touched.nombreProducto &&
                                      errors.nombreProducto
                                    }
                                    onBlur={handleBlur}
                                  />
                                  <TextField
                                    disabled
                                    id="numeroContrato"
                                    name="numeroContrato"
                                    type="text"
                                    label="Número Contrato"
                                    sx={{ width: "20%" }}
                                    value={values.numeroContrato}
                                    onChange={handleChange}
                                    size="small"
                                    inputProps={{
                                      style: {
                                        textTransform: "uppercase",
                                      },
                                    }}
                                    error={
                                      touched.numeroContrato &&
                                      Boolean(errors.numeroContrato)
                                    }
                                    helperText={
                                      touched.numeroContrato &&
                                      errors.numeroContrato
                                    }
                                    onBlur={handleBlur}
                                  />
                                  <TextField
                                    disabled
                                    id="fechaInicio"
                                    name="fechaInicio"
                                    type="date"
                                    label="Fecha Inicio"
                                    sx={{ width: "16%" }}
                                    onChange={handleChange}
                                    size="small"
                                    value={formatoFecha(values.fechaInicio)}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    error={
                                      touched.fechaInicio &&
                                      Boolean(errors.fechaInicio)
                                    }
                                    helperText={
                                      touched.fechaInicio && errors.fechaInicio
                                    }
                                    onBlur={handleBlur}
                                  />
                                </div>

                                {datosEnviadosCorrectamente && (
                                  <Alert severity="success" variant="filled">
                                    Los datos han sido enviados correctamente.
                                  </Alert>
                                )}

                                <div className="cliente-informacion-seccion">
                                  <LoadingButton
                                    sx={{ width: "25ch" }}
                                    type="submit"
                                    size="small"
                                    loading={loading}
                                    loadingPosition="end"
                                    variant="contained"
                                  >
                                    Enviar
                                  </LoadingButton>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default FormCancelacionSeguros;
