import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

import {
  Alert,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";

import FormHelperText from "@mui/material/FormHelperText";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  consultaCatalogosSinPadre,
  consultaUbiGeografica1,
} from "../../app-cliente/services/Catalogos/catalogosService";

import { crearQueja } from "../services/gestionService";

import {
  crearClienteContacto,
  consultaContacto,
} from "../../app-cliente/services/clienteService";

import { formatoFecha } from "../../shared/validations/validaciones";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { LoadingButton } from "@mui/lab";
import {
  MaskCelulares,
  MaskTelefonos2,
} from "app/modules/shared/mask/mascarasInputs";
import ModalAlertaNotificacionEstandar from "app/modules/app-servicios/components/ModalAlertaNotificacionEstandar";

const FormQuejas = ({ idCliente }) => {
  const [clienteContacto, setClienteContacto] = useState({});
  const [datosEnviadosCorrectamente, setDatosEnviadosCorrectamente] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openQueja, setOpenQueja] = useState(false);
  const [idContactoAux, setIdContactoAux] = useState(0);

  useEffect(() => {
    const getDataContacto = async () => {
      try {
        const respuestaContactoCliente = await consultaContacto(
          idCliente,
          "DIRDOM"
        );

        /*  console.log({ respuestaContactoCliente }); */

        if (respuestaContactoCliente.state) {
          setClienteContacto(respuestaContactoCliente.data);
          setIdContactoAux(respuestaContactoCliente.data.idContacto);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setClienteContacto({});
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    getDataContacto();
  }, []);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const SelectItemsCatalogoSinPadre = ({
    pais,
    especial,
    catalogos,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaCatalogosSinPadre(
            especial,
            catalogos,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };

      getData();
    }, []);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const SelectItemsCatalogo = ({
    pais,
    especial,
    catalogos,
    codPadre,
    grupo,
    name,
    labelName,
    componentSize,
    handleBlur,
    touched,
    errors,
  }) => {
    const [provincias, setProvincias] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const { state, data } = await consultaUbiGeografica1(
            especial,
            catalogos,
            codPadre,
            grupo
          );

          if (state) {
            setProvincias(data);
          } else {
            setProvincias([]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, [pais, codPadre]);

    return (
      <FormControl
        sx={{ width: `${componentSize}` }}
        error={touched[name] && Boolean(errors[name])}
        helperText={touched[name] && errors[name]}
        onBlur={handleBlur}
      >
        <InputLabel id={name}>{labelName}</InputLabel>

        <Field
          name={name}
          label={name}
          component={selectPersonalizarPropiedades}
        >
          {provincias.map((provincia, index) => (
            <MenuItem key={index} value={provincia.codigo}>
              {provincia.descripcion}
            </MenuItem>
          ))}
        </Field>

        <ErrorMessage
          name={name}
          component={() => <FormHelperText>{errors[name]}</FormHelperText>}
        />
      </FormControl>
    );
  };

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const configuraionEnvioDatos = async (valores) => {
    setLoading(true);
    const informacionQueja = {
      idQueja: 0,
      idCliente: Number(idCliente),
      fechaQueja: valores.fechaQueja,
      canalIngreso: valores.canalIngreso,
      nombreReporta: valores.nombreReporta.toUpperCase(),
      tipoQueja: valores.tipoQueja,
      detalle: valores.detalle.toUpperCase(),
      idUbicGeografica2: Number(valores.idUbicGeografica2),
      prestador: valores.prestador.toUpperCase(),
      idProducto: Number(valores.idProducto),

      estado: "ESTQJA01",
      idTipoSolucion: Number(valores.idTipoSolucion),
      codigoAsigna: valores.codigoAsigna,
      fechaCierre: "",

      esActivo: true,
    };

    /* console.log(informacionQueja); */

    const informacionContacto = {
      idCliente: Number(idCliente),
      idContacto: idContactoAux,
      celular: valores.celular,
      email: valores.email,
      telefono: valores.telefono,
      telefono2: valores.telefono2,
      extension: valores.extension,
      tipoDireccion: "DIRDOM",
      esPersonal: true,
      esActivo: true,
    };
    try {
      const respuestaClienteContacto = await crearClienteContacto(
        informacionContacto
      );
      /* console.log(respuestaClienteContacto); */

      if (respuestaClienteContacto.state) {
        setIdContactoAux(respuestaClienteContacto.data);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const respuestaCrearQueja = await crearQueja(informacionQueja);

      if (respuestaCrearQueja.state) {
        setDatosEnviadosCorrectamente(true);
        setOpenQueja(true);
        setTimeout(() => {
          setDatosEnviadosCorrectamente(false);
          setLoading(false);
        }, 100);
      } else {
        setLoading(false);
        alert(`${respuestaCrearQueja.message}`);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(`${error.response.data.message}`);
    }
  };

  return (
    <div className="accordion-container">
      <Formik
        initialValues={{
          fechaQueja: "",
          canalIngreso: "",
          idUbicGeografica1: "",
          idUbicGeografica2: "",
          nombreReporta: "",
          idProducto: "",
          tipoQueja: "",
          prestador: "",
          detalle: "",
          telefono: clienteContacto.telefono ?? "",
          telefono2: clienteContacto.telefono2 ?? "",
          extension: clienteContacto.extension ?? "",
          celular: clienteContacto.celular ?? "",
          email: clienteContacto.email ?? "",
        }}
        validationSchema={Yup.object({
          fechaQueja: Yup.string().required("Fecha Queja requerida"),
          canalIngreso: Yup.string().required("Canal Ingreso requerido"),
          nombreReporta: Yup.string().required("Nombre Reporta requerido"),
          prestador: Yup.string().required("Prestador requerido"),
          tipoQueja: Yup.string().required("Tipo Queja requerido"),
          idProducto: Yup.string().required("Producto requerido"),
          idUbicGeografica1: Yup.string().required("Provincia requerido"),
          idUbicGeografica2: Yup.string().required("Cantón requerido"),
          detalle: Yup.string()
            .max(2030, "Tamaño maximo de caracteres sobrepasado")
            .required("Detalle requerido"),

          celular: Yup.string()
            .min(10, "Debería tener 10 números")
            .max(10, "Debería tener 10 números")
            .required("Celular requerido"),

          telefono: Yup.string()
            .min(9, "Debería tener 9 números")
            .max(9, "Debería tener 9 números"),
          telefono2: Yup.string()
            .min(10, "Debería tener 10 números")
            .max(10, "Debería tener 10 números"),
          extension: Yup.string().min(1, "Debería tener 1 números"),
          email: Yup.string()
            .email("Email no tiene formato")
            .required("Email requerida"),
        })}
        onSubmit={(valores, { resetForm }) => {
          configuraionEnvioDatos(valores);
        }}
      >
        {({ values, handleChange, touched, errors, handleBlur }) => (
          <Form>
            <div className="accordion-container">
              <div>
                <Accordion
                  defaultExpanded="true"
                  sx={{ boxShadow: 3, lineHeight: 0 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className="accordion-summary"
                    sx={{ margin: 0 }}
                  >
                    <h2 className="formulario-cabecera">Queja</h2>
                  </AccordionSummary>

                  <AccordionDetails sx={{ marginTop: "2rem" }}>
                    <Grid container spacing={1} rowSpacing={1}>
                      <Grid item md={12}>
                        <Grid container spacing={1}>
                          <Grid item md={12}>
                            <div className="cliente-informacion-seccion">
                              <TextField
                                id="fechaQueja"
                                name="fechaQueja"
                                type="date"
                                label="Fecha Queja"
                                sx={{ width: "15%" }}
                                onChange={handleChange}
                                size="small"
                                value={formatoFecha(values.fechaQueja)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={
                                  touched.fechaQueja &&
                                  Boolean(errors.fechaQueja)
                                }
                                helperText={
                                  touched.fechaQueja && errors.fechaQueja
                                }
                                onBlur={handleBlur}
                              />

                              <SelectItemsCatalogoSinPadre
                                pais="ECU"
                                especial="false"
                                catalogos="CANALQUEJA"
                                grupo={1}
                                name="canalIngreso"
                                labelName="Canal Ingreso"
                                componentSize="26%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <TextField
                                id="nombreReporta"
                                name="nombreReporta"
                                type="text"
                                label="Nombre de quien reporta"
                                sx={{ width: "25%" }}
                                value={values.nombreReporta}
                                onChange={handleChange}
                                size="small"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                error={
                                  touched.nombreReporta &&
                                  Boolean(errors.nombreReporta)
                                }
                                helperText={
                                  touched.nombreReporta && errors.nombreReporta
                                }
                                onBlur={handleBlur}
                              />

                              <TextField
                                id="prestador"
                                name="prestador"
                                type="text"
                                label="Prestador"
                                sx={{ width: "25%" }}
                                value={values.prestador}
                                onChange={handleChange}
                                size="small"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                error={
                                  touched.prestador && Boolean(errors.prestador)
                                }
                                helperText={
                                  touched.prestador && errors.prestador
                                }
                                onBlur={handleBlur}
                              />
                            </div>

                            <div className="cliente-informacion-seccion">
                              <SelectItemsCatalogoSinPadre
                                pais="ECU"
                                especial="false"
                                catalogos="TIPOQUEJA"
                                grupo={1}
                                name="tipoQueja"
                                labelName="Tipo Queja"
                                componentSize="28%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <SelectItemsCatalogoSinPadre
                                pais="ECU"
                                especial="true"
                                catalogos="PRODQJA"
                                grupo={1}
                                name="idProducto"
                                labelName="Producto"
                                componentSize="27%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <SelectItemsCatalogo
                                pais="ECU"
                                especial="true"
                                catalogos="UG1"
                                codPadre={0}
                                grupo={1}
                                name="idUbicGeografica1"
                                labelName="Provincia"
                                componentSize="18%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />

                              <SelectItemsCatalogo
                                pais="ECU"
                                especial="true"
                                catalogos="UG2"
                                codPadre={values.idUbicGeografica1}
                                grupo={1}
                                name="idUbicGeografica2"
                                labelName="Canton"
                                componentSize="18%"
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />
                            </div>

                            <div className="cliente-informacion-seccion">
                              <TextField
                                multiline
                                rows={3}
                                id="detalle"
                                name="detalle"
                                type="text"
                                label="Detalle"
                                sx={{ width: "96%" }}
                                value={values.detalle}
                                onChange={handleChange}
                                size="small"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                error={
                                  touched.detalle && Boolean(errors.detalle)
                                }
                                helperText={touched.detalle && errors.detalle}
                                onBlur={handleBlur}
                              />
                            </div>
                          </Grid>

                          <Grid item md={12}>
                            <Grid container spacing={1} rowSpacing={1}>
                              <Grid item md={12}>
                                <h3>Contacto</h3>
                              </Grid>

                              <Grid item md={12}>
                                <Grid container spacing={3}>
                                  <Grid item md={12}>
                                    <div className="cliente-informacion-seccion">
                                      <FormControl
                                        sx={{ width: "15%" }}
                                        size="small"
                                        variant="outlined"
                                        error={
                                          touched.telefono &&
                                          Boolean(errors.telefono)
                                        }
                                        helperText={
                                          touched.telefono && errors.telefono
                                        }
                                        onBlur={handleBlur}
                                      >
                                        <InputLabel htmlFor="telefono">
                                          Télefono
                                        </InputLabel>
                                        <OutlinedInput
                                          value={values.telefono}
                                          onChange={handleChange}
                                          name="telefono"
                                          id="telefono"
                                          inputComponent={MaskTelefonos2}
                                          /* label es el tama;o que maneja el label */
                                          label="telefono"
                                        />
                                        <ErrorMessage
                                          name="telefono"
                                          component={() => (
                                            <FormHelperText>
                                              {errors.telefono}
                                            </FormHelperText>
                                          )}
                                        />
                                      </FormControl>

                                      <TextField
                                        id="extension"
                                        name="extension"
                                        type="text"
                                        label="Extensión"
                                        inputProps={{ min: 0 }}
                                        sx={{ width: "8%" }}
                                        value={values.extension}
                                        onChange={handleChange}
                                        size="small"
                                        error={
                                          touched.extension &&
                                          Boolean(errors.extension)
                                        }
                                        helperText={
                                          touched.extension && errors.extension
                                        }
                                        onBlur={handleBlur}
                                      />

                                      <FormControl
                                        sx={{ width: "15%" }}
                                        size="small"
                                        variant="outlined"
                                        error={
                                          touched.celular &&
                                          Boolean(errors.celular)
                                        }
                                        helperText={
                                          touched.celular && errors.celular
                                        }
                                        onBlur={handleBlur}
                                      >
                                        <InputLabel htmlFor="celular">
                                          Celular
                                        </InputLabel>
                                        <OutlinedInput
                                          value={values.celular}
                                          onChange={handleChange}
                                          name="celular"
                                          id="celular"
                                          inputComponent={MaskCelulares}
                                          label="celular"
                                        />
                                        <ErrorMessage
                                          name="celular"
                                          component={() => (
                                            <FormHelperText>
                                              {errors.celular}
                                            </FormHelperText>
                                          )}
                                        />
                                      </FormControl>

                                      <FormControl
                                        sx={{ width: "15%" }}
                                        size="small"
                                        variant="outlined"
                                        error={
                                          touched.telefono2 &&
                                          Boolean(errors.telefono2)
                                        }
                                        helperText={
                                          touched.telefono2 && errors.telefono2
                                        }
                                        onBlur={handleBlur}
                                      >
                                        <InputLabel htmlFor="telefono2">
                                          Celular 2
                                        </InputLabel>
                                        <OutlinedInput
                                          value={values.telefono2}
                                          onChange={handleChange}
                                          name="telefono2"
                                          id="telefono2"
                                          inputComponent={MaskCelulares}
                                          label="telefono2"
                                        />
                                        <ErrorMessage
                                          name="telefono2"
                                          component={() => (
                                            <FormHelperText>
                                              {errors.telefono2}
                                            </FormHelperText>
                                          )}
                                        />
                                      </FormControl>

                                      <TextField
                                        id="email"
                                        name="email"
                                        type="text"
                                        label="Email"
                                        sx={{ width: "33%" }}
                                        value={values.email}
                                        onChange={handleChange}
                                        size="small"
                                        error={
                                          touched.email && Boolean(errors.email)
                                        }
                                        helperText={
                                          touched.email && errors.email
                                        }
                                        onBlur={handleBlur}
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {datosEnviadosCorrectamente && (
                          <Alert severity="success" variant="filled">
                            Los datos han sido guardados correctamente.
                          </Alert>
                        )}
                        <div className="cliente-informacion-seccion">
                          <LoadingButton
                            sx={{ width: "25ch" }}
                            type="submit"
                            size="small"
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                          >
                            Guardar
                          </LoadingButton>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ModalAlertaNotificacionEstandar
        setOpen={setOpenQueja}
        open={openQueja}
        titulo="Queja Creada"
        mensaje="Su queja ha sido creada con exito !!."
      />
    </div>
  );
};

export default FormQuejas;
