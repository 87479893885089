import React, { useEffect, useState } from "react";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import { consultarPreguntasPredeterminadas } from "../services/comunicacionesService";

import { SCSLoadingButton } from "app/modules/common/components/Buttons/SCSButtons";

import AcceptServiceresponse from "app/modules/shared/components/AcceptServiceresponse";
import SpinnerLoadSrx from "app/modules/shared/components/SpinnerLoadSrx";
import { StyleInputs } from "app/modules/common/styles/themes";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: 600,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalPreguntasSugeridas = ({
  open,
  setOpen,
  updatePreguntas,
  crearNuevaConversaccionHub,
  loading,
  datosEnviadosCorrectamente,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [listaPreguntas, setListaPreguntas] = useState([]);

  useEffect(() => {
    const getPreguntas = async () => {
      try {
        const respuestaPreguntasSugeridas =
          await consultarPreguntasPredeterminadas();

        if (respuestaPreguntasSugeridas.state) {
          const preguntasAux = respuestaPreguntasSugeridas.data.map(
            (pregunta) => {
              return { ...pregunta, checkedPregunta: false };
            }
          );

          /*console.log({ preguntasAux }); */
          setListaPreguntas(preguntasAux);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    if (open) {
      getPreguntas(open);
    } else {
      setIsLoading(false);
    }
  }, [updatePreguntas]);

  if (isLoading) {
    return <SpinnerLoadSrx />;
  }

  const selectPersonalizarPropiedades = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        labelId={name}
        name={name}
        value={value}
        label={name}
        autoWidth
        size="small"
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  /* const handleChange = (event) => {
    setListaPreguntas((preguntas) =>
      preguntas.map((pregunta) => {
        if (Number(pregunta.idTipoPregunta) === Number(event.target.name)) {
          return { ...pregunta, checkedPregunta: !pregunta.checkedPregunta };
        }
        return { ...pregunta };
      })
    );
  }; */

  const datosCrearConversacion = (valores) => {
    crearNuevaConversaccionHub(valores, listaPreguntas);
  };

  return (
    <BootstrapDialog
      onClose={() => setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => setOpen(false)}
      >
        <h3>Tipo de Conversación</h3>
      </BootstrapDialogTitle>

      <Formik
        initialValues={{
          opcionConversacion: "",
        }}
        validationSchema={Yup.object({
          opcionConversacion: Yup.string().required(
            "Tipo Conversación requerido"
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          datosCrearConversacion(values);
        }}
      >
        {({ values, handleChange, touched, errors, handleBlur }) => (
          <Form>
            <DialogContent dividers>
              <FormControl
                sx={StyleInputs(80, 80)}
                error={
                  touched.opcionConversacion &&
                  Boolean(errors.opcionConversacion)
                }
                helperText={
                  touched.opcionConversacion && errors.opcionConversacion
                }
                onBlur={handleBlur}
              >
                <InputLabel id="opcionConversacion">
                  Tipo Conversación
                </InputLabel>

                <Field
                  name="opcionConversacion"
                  label="opcionConversacion"
                  component={selectPersonalizarPropiedades}
                >
                  {listaPreguntas.map((provincia, index) => (
                    <MenuItem key={index} value={provincia.idTipoPregunta}>
                      {provincia.tipoPregunta}
                    </MenuItem>
                  ))}
                </Field>

                <ErrorMessage
                  name="opcionConversacion"
                  component={() => (
                    <FormHelperText>{errors.opcionConversacion}</FormHelperText>
                  )}
                />
              </FormControl>
            </DialogContent>

            <DialogActions>
              <SCSLoadingButton titulo="OK" loading={loading} />

              {datosEnviadosCorrectamente && <AcceptServiceresponse />}
            </DialogActions>
          </Form>
        )}
      </Formik>
    </BootstrapDialog>
  );
};

export default ModalPreguntasSugeridas;
