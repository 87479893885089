import React, { useState } from "react";

import BotonGerericoSalir from "app/modules/shared/containers/BotonGerericoSalir";
import BotonConsultaSiniestroReporteDiario from "../../components/BotonConsultaSiniestroReporteDiario";
import HeaderReporteSiniestroDiario from "../../containers/HeaderReporteSiniestroDiario";
import BotonConsultaSiniestroReporteMensual from "../../components/BotonConsultaSiniestroReporteMensual";
import BotonConsultaSiniestroAseguradora from "../../components/BotonConsultaSiniestroAseguradora";

const ReportesSiniestros = () => {
  return (
    <>
      <BotonGerericoSalir />
      <div className="header-reporte-detalle">
        <HeaderReporteSiniestroDiario />
      </div>

      <div className="reportes-container">
        <BotonConsultaSiniestroReporteDiario />

        <BotonConsultaSiniestroReporteMensual />

        <BotonConsultaSiniestroAseguradora />
      </div>
    </>
  );
};

export default ReportesSiniestros;
