import http from "../../../../comunication/http-common";

//http://localhost:4002/identity/ObtenerUsuarioXkey?usuario=eduardo.puga
export const obtenerUsuario = async (usuario) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURIDAD}identity/ObtenerUsuarioXkey?key=${usuario}`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//http://localhost:5013/solicitud/ListarFlujo
export const listarFlujo = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CREDITO}solicitud/ListarFlujo`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

//http://localhost:5012/catalogo/ObtenerGrupo
export const listarGrupo = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_CONFIGURACION}catalogo/ObtenerGrupo`;
    http
      .get(url, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

//http://localhost:4002/identity/GrabarUsuarioFlujo
export const crearUsuarioFlujo = (data) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: "Bearer " + sessionStorage.getItem("jwtToken"),
    };
    const url = `${process.env.REACT_APP_API_SEGURIDAD}identity/GrabarUsuarioFlujo`;
    http
      .post(url, data, { headers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
