import React from "react";
import { useSelector } from "react-redux";
import ProgressBar from "../../../common/components/ProgressBar";
import Paper from '@mui/material/Paper';
//import { makeStyles, useTheme } from '@material-ui/core/styles';
const moment = require("moment");

// const useStyles = makeStyles(theme => ({  
//     paper: {
//         width: "50%",
// 		margin: theme.spacing(2),
// 		padding: theme.spacing(2),
// 	},
//   userTitle: {
//     color: theme.palette.sidebar.textDarkColor,
//     marginBottom: 8,
//   }
// }));

const ProfileCard = () => {
	//const classes = useStyles();
	const { authUser } = useSelector((state) => state.auth);
console.log("authUser",authUser)
	if (!authUser) return <ProgressBar />;

	return (

		// <Paper className={classes.paper} elevation={3}></Paper>
		<Paper  elevation={3} >
			<div className="jr-wel-ema" style={{ margin: "40px", padding: "15px" }}>
				<h1 >
					{"Bienvenido"} {authUser.nombres}{" "}{ authUser.apellidos}
				</h1>
				<p className="jr-fs-sm text-uppercase">{"Información"}</p>
				<ul className="list-unstyled">
					<li className="mb-1">
						<i className="zmdi zmdi-account zmdi-hc-fw zmdi-hc-2x mr-2" />
                        <span>Identificación: </span>
						<span>{authUser.identificacion}</span>
					</li>
                    <li className="mb-1">
						<i className="zmdi zmdi-account zmdi-hc-fw zmdi-hc-2x mr-2" />
                        <span>Correo electrónico: </span>
						<span>{authUser.email}</span>
					</li>
                    <li className="mb-1">
						<i className="zmdi zmdi-account zmdi-hc-fw zmdi-hc-2x mr-2" />
                        <span>Celular: </span>
						<span>{authUser.telefono1}</span>
					</li>
                    <li className="mb-1">
						<i className="zmdi zmdi-account zmdi-hc-fw zmdi-hc-2x mr-2" />
                        <span>Teléfono: </span>
						<span>{authUser.telefono2}</span>
					</li>
					{/* <li className="mb-1">
						<i className="zmdi zmdi-pin-account zmdi-hc-fw zmdi-hc-2x mr-2" />
						<span>{authUser.profileName}</span>
					</li>
					<li className="mb-1">
						<i className="zmdi zmdi-store zmdi-hc-fw zmdi-hc-2x mr-2" />
						<span>{authUser.office}</span>
					</li> */}
					{/* <li className="mb-1">
						<i className="zmdi zmdi-accounts zmdi-hc-fw zmdi-hc-2x mr-2" />
						<span>{authUser.institution}</span>
					</li> */}
					<li className="mb-1">
						<i className="zmdi zmdi-calendar zmdi-hc-fw zmdi-hc-2x mr-2" />
                        <span>Ultima conexión: </span>
						<span>
							{moment(new Date(authUser.fechaUltimoAcceso))
								.utc()
								.format("DD/MM/YYYY")}
						</span>
					</li>
				</ul>
			</div>
		</Paper>
	);
};

export default ProfileCard;
