import FlujoPunta from "./CreditoForms/FlujoPunta";

import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "../../../layouts/layouts";

const FlujoPuntaPage = () => {
  const { setActiveLayout } = useJumboApp();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);
  return (
    <div>
      <FlujoPunta />
    </div>
  );
};

export default FlujoPuntaPage;
