import React from "react";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@mui/styles";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import addYears from "date-fns/addYears";
import es from "date-fns/locale/es";
registerLocale("es", es);
setDefaultLocale("es-GB");

const useStyles = makeStyles({
  label: {
    "&$erroredLabel": {
      color: "#fec03e",
    },
  },
  focusedLabel: {},
  erroredLabel: {},
  underline: {
    "&$error:after": {
      borderBottomColor: "#fec03e",
    },
  },
  error: {},
});

const DateControlGrid = ({
  value,
  onChange,
  onFocus,
  hintText,
  width,
  height,
  error,
  disabled,
  dateFormat,
  maxDate,
  ...other
}) => {
  const classes = useStyles();

  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      placeholderText={hintText}
      dateFormat="yyyy/MM/dd"
      disabled={disabled}
      locale="es"
      onFocus={onFocus}    
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      {...other}
      customInput={
        <TextField
          inputlabelprops={{
            classes: {
              root: classes.label,
              focused: classes.focusedLabel,
              error: classes.erroredLabel,
            },
          }}
          inputprops={{
            classes: {
              root: classes.underline,
              error: classes.error,
            },
          }}
          fullWidth
          error={error}
        />
      }
    />
  );
};

export default DateControlGrid;
