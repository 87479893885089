import React from "react";
import FormCertificadoCredito from "../components/FormCertificadoCredito";

const CertificadoCredito = ({ idCliente, idLocal }) => {
  return (
    <>
      <FormCertificadoCredito idCliente={idCliente} idLocal={idLocal} />
    </>
  );
};

export default CertificadoCredito;
